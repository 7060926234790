import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

export const MOVE_AFTER_MUTATION = gqlTagPolaris`
mutation jira_polaris_moveAfter(
    $listId: ID!,
    $refId: ID!,
    $items: [ID!],
 ) {
  polaris {
    ranking {
      makeAfter(
        listId: $listId
        refId: $refId
        items: $items
      ) {
        changed {
          id
          rank
        }
        added {
          id
          rank
        }
        deleted {
          id
          rank
        }
      }
    }
  }
}`;

type MoveAfterArgs = {
	listId: string;
	items: string[];
	refId: string;
};

export const createMoveAfter =
	(client: PolarisApolloClient): (({ listId, items, refId }: MoveAfterArgs) => Promise<void>) =>
	({ listId, items, refId }) =>
		client
			.mutate({
				mutation: MOVE_AFTER_MUTATION,
				variables: {
					listId,
					items,
					refId,
				},
				errorPolicy: 'all',
			})
			.then((result) => {
				if (result.errors !== undefined) {
					throw new Error(
						`ranking-service.move-after-error:${result.errors.map((e) => e.message).join(', ')}`,
					);
				}
			});

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';

import { TEST_ID_PREFIX, WrappedStatusLozenge } from '../../common/ui/status-lozenge/index.tsx';

import StatusField from '../main.tsx';
import messages from '../status-view/messages.tsx';
import type { Props } from './types.tsx';

// NOTE: This component is an attempt on creating a lightweight version of the status view
// in its "lozenge" version. It is meant to be used in long lists of items in the hopes of
// render performance enhancements since the full version of the status field is only
// effectively rendered in case the user clicks the lozenge.
export const StatusViewLozenge = (props: Props) => {
	const [hasOpenedOnce, setHasOpenedOnce] = useState(false);
	const onFirstClick = useCallback(() => setHasOpenedOnce(true), []);

	const statusFieldWrapperRef = useRef<unknown>(null);
	const { formatMessage } = useIntl();

	const { initialValue, isEditable } = props;

	// NOTE: This hack ensures the status field's dropdown is displayed in an open state
	// when mounted. Since there's no means of getting a reference to the underlying
	// DOM element, unfortunately the only option is to query the element by its test id
	// and click it as soon as it's rendered for the first time.
	// This effect should be completely removed once PopupSelect supports a prop that defines
	// the initial state of the dropdown (most likely defaultMenuIsOpen). For more info:
	// https://ecosystem.atlassian.net/servicedesk/customer/portal/24/DS-7521
	useEffect(() => {
		if (hasOpenedOnce) {
			const sel = `[data-testid^="${TEST_ID_PREFIX}"]`;
			setTimeout(() =>
				statusFieldWrapperRef.current
					// @ts-expect-error - Property 'querySelector' does not exist on type '{}'.
					?.querySelector(sel)
					?.click(),
			);
		}
	}, [hasOpenedOnce]);

	if (hasOpenedOnce) {
		return (
			// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
			<div ref={statusFieldWrapperRef}>
				<StatusField {...props} />
			</div>
		);
	}

	return (
		// NOTE: Disabling accessibility-related errors as WrappedStatusLozenge does not offer an
		// onClick prop. Besides, the actual target is the underlying button - the event bubbles up
		// and is ultimately handled here. The div itself is not interactive.
		<div
			onClick={onFirstClick}
			role="presentation"
			onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && onFirstClick()}
		>
			<WrappedStatusLozenge
				ariaLabel={formatMessage(messages.changeStatus)}
				hasHoverState
				isEditable={isEditable}
				isOpen={false}
				status={initialValue}
			/>
		</div>
	);
};

export default StatusViewLozenge;

import React from 'react';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useDescription,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { isEmpty } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { DescriptionContent } from '../content/index.tsx';

export const DescriptionField = ({ isSmartLink }: { isSmartLink?: boolean }) => {
	const selectedIssue = useSelectedIssue();
	const description = useDescription(selectedIssue);
	const isSharedView = useIsSharedView();

	if (isSharedView && (!description || isEmpty(description))) {
		return null;
	}

	return <DescriptionContent description={description} isSmartLink={isSmartLink} />;
};

import React from 'react';
import { Box, Flex, Pressable, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { RoundedButtonProps } from './types.tsx';

export const RoundedButton = ({
	isSelected,
	testId,
	onClick,
	iconBefore,
	children,
}: RoundedButtonProps) => {
	return (
		<Pressable
			testId={testId}
			xcss={[
				buttonBaseStyles,
				isSelected ? buttonSelectedStyles : buttonInteractionStyles,
				iconBefore ? buttonWithIconBeforeStyles : undefined,
			]}
			onClick={onClick}
			tabIndex={onClick ? 0 : -1}
		>
			<Inline alignBlock="center" space="space.050">
				{iconBefore && <Flex>{iconBefore}</Flex>}
				<Box>{children}</Box>
			</Inline>
		</Pressable>
	);
};

const buttonBaseStyles = xcss({
	background: token('color.background.neutral'),
	font: 'font.body',
	color: 'color.text',
	fontWeight: '500',
	height: '32px',
	paddingInline: 'space.150',
	borderRadius: '36px',
});

const buttonWithIconBeforeStyles = xcss({
	paddingLeft: 'space.100',
});

const buttonSelectedStyles = xcss({
	background: token('color.background.selected'),
	color: 'color.text.selected',
});

const buttonInteractionStyles = xcss({
	':hover': {
		background: token('color.background.neutral.hovered'),
		color: 'color.text',
	},
	':active': {
		background: token('color.background.neutral.pressed'),
	},
});

import React, { type ComponentType, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import { Y300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type { FieldDecoratorProps } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/types.tsx';
import { useCallbacks } from '../../../../controllers/selectors/callback-hooks.tsx';
import { useOuterSpacing } from '../../../../controllers/selectors/config-hooks.tsx';
import { useDecorations } from '../../../../controllers/selectors/decoration-hooks.tsx';
import { DecoratorRatingItem } from './item/index.tsx';
import { getDeduplicatedRatingDecorations } from './utils.tsx';

type DecoratorItemsProps = {
	FieldDecorator: ComponentType<FieldDecoratorProps>;
	decorationConfigHeader?: React.JSX.Element;
	readonly?: boolean;
};

export const DecoratorRatingItems = ({
	FieldDecorator,
	decorationConfigHeader,
	readonly = false,
}: DecoratorItemsProps) => {
	const { onMultipleValueDecorationsCreated } = useCallbacks();
	const outerSpacing = useOuterSpacing();
	const sortedDecorations = useDecorations();

	const [openItem, setOpenItem] = useState<unknown>(undefined);

	useEffect(() => {
		if (sortedDecorations.length === 0) {
			const decorations = [];

			for (let i = 1; i <= 5; i += 1) {
				decorations.push({
					backgroundColor: Y300,
					emoji: undefined,
					highlightContainer: false,
					rules: [
						{ value: `${i}`, operator: ValueRuleOperator.GTE },
						{ value: `${i}`, operator: ValueRuleOperator.LTE },
					],
				});
			}

			onMultipleValueDecorationsCreated && onMultipleValueDecorationsCreated(decorations);
		}
	}, [onMultipleValueDecorationsCreated, sortedDecorations.length]);

	return (
		<>
			{decorationConfigHeader}
			<ItemsContainer>
				<DecoratorRatingWrapper outerSpacing={outerSpacing}>
					{getDeduplicatedRatingDecorations(sortedDecorations).map((decoration) => (
						<DecoratorRatingItem
							key={decoration.localDecorationId}
							FieldDecorator={FieldDecorator}
							decoration={decoration}
							isOpen={openItem === decoration.localDecorationId}
							onOpenChanged={(isOpen) =>
								isOpen ? setOpenItem(decoration.localDecorationId) : setOpenItem(undefined)
							}
							readonly={readonly}
						/>
					))}
				</DecoratorRatingWrapper>
			</ItemsContainer>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemsContainer = styled.div({
	overflow: 'visible',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DecoratorRatingWrapper = styled.div<{ outerSpacing: string }>({
	paddingBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) => `0 -${props.outerSpacing}`,
});

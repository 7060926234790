import React, { memo } from 'react';
import type { RowRendererProps } from '../../common/types/react-base-table/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../constants.tsx';
import { useRowPinnedBottomComponent } from '../../controllers/selectors/components-hooks.tsx';

const DefaultRowPinnedBottomComponent = () => null;

export const RowPinnedBottom = memo<RowRendererProps>((props: RowRendererProps) => {
	const ConsumerRowPinnedBottomComponent = useRowPinnedBottomComponent();

	if (ConsumerRowPinnedBottomComponent !== undefined) {
		return (
			<ConsumerRowPinnedBottomComponent
				{...props}
				groupId={props.rowData.groupId === NO_VALUE_GROUP_ID ? undefined : props.rowData.groupId}
				DefaultComponent={DefaultRowPinnedBottomComponent}
			/>
		);
	}

	return <DefaultRowPinnedBottomComponent />;
});

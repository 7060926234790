import React from 'react';
import { IconButton, LinkButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useIssueTypeAvatarId,
	useIssueTypeIdsForProject,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { makeAvatarUrlFromId } from '../utils/make-avatar-url-from-id.tsx';
import { messages } from './messages.tsx';

type IssueTypeOptionProps = {
	issueTypeId: IssueTypeId;
	isSelected: boolean;
	onClick: (issueType: IssueTypeFieldValue) => void;
};

const IssueTypeOption = ({ issueTypeId, onClick, isSelected }: IssueTypeOptionProps) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const name = useIssueTypeName({ issueTypeId });
	const iconUrl = makeAvatarUrlFromId(avatarId ?? '', 'default');

	return (
		<DropdownItem
			onClick={() => name && onClick({ id: issueTypeId, name, iconUrl })}
			isSelected={isSelected}
			elemBefore={<img alt={name} src={iconUrl} />}
		>
			{name}
		</DropdownItem>
	);
};

type EditableIssueTypeProps = {
	issueTypeId: IssueTypeId;
	onChange?: (issueType: IssueTypeFieldValue) => void;
	readonly?: boolean;
};

export const EditableIssueType = ({ issueTypeId, onChange, readonly }: EditableIssueTypeProps) => {
	const { formatMessage } = useIntl();
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const name = useIssueTypeName({ issueTypeId });
	const iconUrl = makeAvatarUrlFromId(avatarId ?? '', 'default');
	const container = useEnvironmentContainer();
	const issueTypeIds = useIssueTypeIdsForProject({ projectId: container?.id });

	if (container?.type !== PolarisEnvironmentContainerTypes.PROJECT || readonly) {
		return <img src={iconUrl} alt={name} />;
	}

	const handleOptionClick = (newIssueType: IssueTypeFieldValue) => {
		if (issueTypeId === newIssueType.id) {
			return;
		}

		onChange && onChange(newIssueType);
	};

	return (
		<DropdownMenu<HTMLButtonElement>
			trigger={({ triggerRef, ...props }) => (
				<IconButton
					{...props}
					ref={triggerRef}
					label={formatMessage(messages.changeIssueTypeLabel)}
					appearance="subtle"
					spacing="compact"
					icon={(iconProps) => <img {...iconProps} src={iconUrl} alt={name} />}
				/>
			)}
		>
			<DropdownItemGroup title={formatMessage(messages.changeIssueTypeLabel)}>
				{issueTypeIds.map((id) => (
					<IssueTypeOption
						key={id}
						issueTypeId={id}
						isSelected={id === issueTypeId}
						onClick={handleOptionClick}
					/>
				))}

				<Box
					xcss={editIssueTypesButtonStyles}
					padding="space.100"
					paddingInlineStart="space.200"
					paddingBlockStart="space.200"
				>
					<LinkButton
						href={`/jira/polaris/projects/${container.projectKey}/ideas/settings/types`}
						appearance="default"
					>
						{formatMessage(messages.editIssueTypesLabel)}
					</LinkButton>
				</Box>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

const editIssueTypesButtonStyles = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	width: '200px',
	marginBlockStart: 'space.100',
});

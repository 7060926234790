import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ClickableBannerProps, Spacing, Type } from './types.tsx';

export const ClickableBanner = ({
	icon,
	message,
	actionLabel,
	onClick,
	type = 'information',
	spacing = 'default',
	iconAlignment = 'start',
}: ClickableBannerProps) => {
	const handleClick = useCallback(() => {
		onClick?.();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.activeElement instanceof HTMLElement) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.activeElement.blur();
		}
	}, [onClick]);

	const hasAction = onClick !== undefined;

	return (
		<Container
			aria-label={actionLabel}
			onClick={handleClick}
			role={hasAction ? 'button' : undefined}
			tabIndex={hasAction ? 0 : undefined}
			hasAction={hasAction}
			spacing={spacing}
			type={type}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleClick();
				}
			}}
		>
			<Flex alignItems={iconAlignment} xcss={iconAlignment === 'start' && iconStartAlignmentStyles}>
				{icon}
			</Flex>
			<Inline grow="fill" alignBlock="center">
				{message}
			</Inline>
			{hasAction && (
				<Box xcss={shortcutIconContainerStyles} data-component-selector="shortcut-icon-lQi8">
					<ShortcutIcon size="small" label="" />
				</Box>
			)}
		</Container>
	);
};

const iconStartAlignmentStyles = xcss({
	lineHeight: '14px',
});

const containerBackgrounds = {
	warning: {
		default: token('color.background.warning'),
		hovered: token('color.background.warning.hovered'),
		active: token('color.background.warning.pressed'),
	},
	information: {
		default: token('color.background.information'),
		hovered: token('color.background.information.hovered'),
		active: token('color.background.information.pressed'),
	},
};

const containerPaddingInline = {
	default: token('space.300'),
	medium: token('space.200'),
	compact: token('space.100'),
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	hasAction: boolean;
	spacing: Spacing;
	type: Type;
}>({
	display: 'flex',
	gap: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ hasAction }) => hasAction && 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ type }) => containerBackgrounds[type].default,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingInline: ({ spacing }) => containerPaddingInline[spacing],
	paddingBlock: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'&:hover, &:focus': ({ hasAction }) => ({
		...(hasAction && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			background: ({ type }) => containerBackgrounds[type].hovered,
			'[data-component-selector="shortcut-icon-lQi8"]': {
				color: token('color.icon.selected'),
			},
		}),
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'&:active': ({ hasAction }) => ({
		...(hasAction && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			background: ({ type }) => containerBackgrounds[type].active,
			'[data-component-selector="shortcut-icon-lQi8"]': {
				color: token('color.icon.selected'),
			},
		}),
	}),
});

const shortcutIconContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import messages from './messages.tsx';

type FieldValueComponentProps = {
	groupIdentity: string;
	fieldKey?: FieldKey;
};

const ReactionsColumnHeader = ({ groupIdentity }: FieldValueComponentProps) => {
	const emoji = useEmoji(groupIdentity);
	return emoji ? (
		<Flex justifyContent="center" alignItems="center" xcss={emojiWrapperStyles}>
			<Emoji emoji={emoji} fitToHeight={16} showTooltip />
		</Flex>
	) : null;
};

const EmptyReactionsColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyColumnHeader)}</Box>;
};

export const reactionsFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: ReactionsColumnHeader,
	EmptyComponent: EmptyReactionsColumnHeader,
} as const;

const emojiWrapperStyles = xcss({
	width: '28px',
	height: '28px',
});

import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	useIssueKey,
	useIssueType,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type InternalProps = {
	issueTypeIconUrl: string | undefined;
	issueKey: IssueKey | undefined;
};

export const KeyInternal = memo<InternalProps>(({ issueTypeIconUrl, issueKey }: InternalProps) => {
	if (issueKey === undefined || issueTypeIconUrl === undefined) {
		return null;
	}
	const singleIssueViewUrl = `/browse/${issueKey}`;
	return (
		<Container>
			<Avatar src={issueTypeIconUrl} size="xsmall" />
			<KeyContainer>
				<a href={singleIssueViewUrl}>{issueKey}</a>
			</KeyContainer>
		</Container>
	);
});

type Props = {
	localIssueId: LocalIssueId;
};

export const Key = ({ localIssueId }: Props) => {
	const issueKey = useIssueKey(localIssueId);
	const issueType = useIssueType(localIssueId);

	return <KeyInternal issueKey={issueKey} issueTypeIconUrl={issueType?.iconUrl} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:hover, a:active, a:visited, a:focus': {
		color: 'inherit',
		borderRadius: '3px',
		padding: token('space.050', '4px'),
		boxShadow: 0,
		border: 0,
	},
});

import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { type State, type Props, InsightsActionModes } from '../types.tsx';

export const enableCopyInsightsMode =
	() =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			activeAction: { mode: InsightsActionModes.COPY },
		});
	};

export const disableCopyInsightsMode =
	() =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			activeAction: undefined,
		});
	};

export const copyInsights =
	(destinationIssueId: string, destinationIssueKey: string) =>
	async (
		{ setState, getState }: StoreActionApi<State>,
		{
			issueId,
			projectId,
			insightsRemote,
			onCopyInsightsFailed,
			issuesRemote,
			createAnalyticsEvent,
		}: Props,
	) => {
		setState({
			activeAction: { mode: InsightsActionModes.COPY_IN_PROGRESS },
		});

		try {
			const { selectedInsights } = getState();

			const issue = await issuesRemote.fetchIssue({
				issueIdOrKey: destinationIssueKey,
			});

			const destination = {
				projectId: issue.fields.project.id,
				issueId: destinationIssueId ? String(destinationIssueId) : undefined,
			};

			await insightsRemote.copyInsights?.({
				source: {
					projectId,
					issueId: issueId ? String(issueId) : undefined,
				},
				destination,
				insights: Object.keys(selectedInsights),
			});
			setState({
				activeAction: {
					mode: InsightsActionModes.COPY_SUCCESS,
					meta: {
						projectKey: issue.fields.project.key,
						issueKey: destinationIssueKey,
					},
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'insight created', {
				creationType: 'copy',
				destinationProjectId: destination.projectId,
				destinationIssueId: destination.issueId,
				copiedInsights: Object.keys(selectedInsights),
			});
		} catch (err) {
			setState({
				activeAction: {
					mode: InsightsActionModes.COPY,
				},
			});

			const error = err instanceof Error ? err : new Error('Unknown error');
			onCopyInsightsFailed(error);
		}
	};

import type { JsonLd } from 'json-ld-types';
import { CardClient as Client } from '@atlaskit/link-provider';
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../types.tsx';
import { resolveUrl } from './resolve-url.tsx';

type onResolveFn = (url: string, data: JsonLd.Response<JsonLd.Data.BaseData> | undefined) => void;

class JPDSmartCardClient extends Client {
	onResolve: onResolveFn;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(props: { onResolve: onResolveFn; envKey?: any }) {
		const { onResolve, envKey } = props;
		super(envKey);
		this.onResolve = onResolve;
	}

	prefetchData(url: string): ReturnType<Client['prefetchData']> {
		return super.prefetchData(url).then((data) => {
			data && this.onResolve(url, data);
			return data;
		});
	}

	fetchData(url: string): ReturnType<Client['fetchData']> {
		return super.fetchData(url).then((data) => {
			data && this.onResolve(url, data);
			return data;
		});
	}
}

export const initSmartCardClient =
	(): Action<State, void> =>
	async ({ setState, dispatch }) => {
		const client = new JPDSmartCardClient({
			onResolve: (url, data) => dispatch(resolveUrl(url, data)),
		});
		setState({
			client,
		});
	};

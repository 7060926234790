import { CONFLICT } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueType } from '../types.tsx';
import { AssociationHardLimitError } from './errors.tsx';

type ProjectErrors = Record<string, string>;
type FieldAssociationResponse = { errors: ProjectErrors } | undefined;

export class PartialAssociationError extends Error {
	private readonly projectsErrors: ProjectErrors;

	constructor(message: string, projectsErrors: ProjectErrors) {
		super(message);
		this.projectsErrors = projectsErrors;
	}

	getProjectsErrors(): ProjectErrors {
		return this.projectsErrors;
	}
}

const getProjectsErrors = (res: FieldAssociationResponse): ProjectErrors | undefined => {
	const projectsErrors = res?.errors || {};

	if (Object.keys(projectsErrors).length === 0) {
		return undefined;
	}

	return projectsErrors;
};

const getHardLimitsErrors = (res: FieldAssociationResponse): ProjectErrors | undefined => {
	const projectsErrors = res?.errors || {};
	const hardLimitErrors = Object.keys(projectsErrors)
		.filter((key) => projectsErrors[key].includes('Cannot associate more than'))
		.reduce((obj, key) => Object.assign(obj, { [key]: projectsErrors[key] }), {});

	if (Object.keys(hardLimitErrors).length === 0) {
		return undefined;
	}

	return hardLimitErrors;
};

export const associateGlobalField = async (
	fieldKey: string,
	issueTypes: Array<IssueType>,
): Promise<void> =>
	performPostRequest<FieldAssociationResponse>(
		`/rest/polaris/global-fields/${fieldKey}/associate`,
		{
			body: JSON.stringify({ issueTypes }),
		},
	)
		.catch((error: FetchError) => {
			if (error instanceof FetchError && error.statusCode === CONFLICT) {
				throw new AssociationHardLimitError(
					'global fields association failed due to hard limits for one project',
				);
			}

			throw error;
		})
		.then((res) => {
			const hardLimitErrors = getHardLimitsErrors(res);
			if (hardLimitErrors) {
				throw new AssociationHardLimitError(
					'global fields association failed due to hard limits for projects',
					hardLimitErrors,
				);
			}

			const projectsErrors = getProjectsErrors(res);
			if (projectsErrors) {
				throw new PartialAssociationError(
					'global fields association failed for some projects',
					projectsErrors,
				);
			}
		});

export const associateGlobalFields = async (
	fieldKeys: string[],
	issueType: IssueType,
): Promise<void> =>
	performPostRequest('/rest/polaris/global-fields/associate', {
		body: JSON.stringify({
			fieldKeys,
			projectId: issueType.projectId,
			issueTypeId: issueType.issueTypeId,
		}),
	}).catch((error: FetchError) => {
		if (error instanceof FetchError && error.statusCode === CONFLICT) {
			throw new AssociationHardLimitError('global fields association failed due to hard limits');
		}

		throw error;
	});

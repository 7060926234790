import { createHook, createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

type Actions = typeof actions;

const ConcurrentUpdateStore = createStore<State, Actions>({
	initialState: {
		hasConcurrentUpdate: false,
		latestUpdate: undefined,
		isLoadingUpdate: false,
	},
	actions,
});

export const useConcurrentUpdate = createHook(ConcurrentUpdateStore);

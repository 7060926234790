import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { FetchResponse } from './types.tsx';

const getViewCommentsUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/public/views/${viewId}/comments`;

export const getViewComments = async (viewId: string): Promise<FetchResponse> => {
	if (viewId === undefined) {
		return Promise.reject(new Error('viewId must be supplied, cannot load comments'));
	}
	const comments = await fetchJson(getViewCommentsUrl(viewId));
	return {
		comments,
	};
};

import React, { type ReactElement, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useIntervalDateDecoration } from '../../../controllers/field/selectors/decoration/hooks.tsx';
import { useFieldOfType } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useAllExternalReferenceEntities } from '../../../controllers/issue/selectors/external-reference-hooks.tsx';
import { useExternalReferenceValue } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useOpenRightSidebarOnField } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { DecoratedDate } from '../../decoration/date/index.tsx';
import { Hint } from './hint/index.tsx';

type InnerProps = {
	issueId: LocalIssueId;
	renderFallback: ReactElement;
	fieldKey: FieldKey;
	atlasProjectFieldKey: FieldKey;
};

const InternalAtlasProjectTargetDate = ({
	issueId,
	fieldKey,
	atlasProjectFieldKey,
	renderFallback,
}: InnerProps) => {
	const { locale } = useTenantContext();
	const allExternalReferenceEntities = useAllExternalReferenceEntities();
	const externalReferenceValue = useExternalReferenceValue(atlasProjectFieldKey, issueId);
	const [random, setRandom] = useState(0);
	const openFieldConfig = useOpenRightSidebarOnField();

	const onConfiguration = useCallback(() => {
		setRandom(Date.now());
		openFieldConfig(fieldKey);
	}, [fieldKey, openFieldConfig]);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const date = allExternalReferenceEntities[externalReferenceValue as string]?.dueDate?.dateRange;

	const decoration = useIntervalDateDecoration(fieldKey, date || undefined, locale);

	if (
		typeof externalReferenceValue !== 'string' ||
		!date?.start ||
		!date?.end ||
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		allExternalReferenceEntities[externalReferenceValue as string]?.state?.value === 'pending'
	) {
		return renderFallback;
	}

	return (
		<Container key={random}>
			<PolarisTooltip
				hideTooltipOnMouseDown
				content={<Hint fieldKey={fieldKey} issueId={issueId} onConfiguration={onConfiguration} />}
			>
				<Container>
					<DecoratedDate datetime={renderDateString(date)} decoration={decoration} />
					{iconForPolarisFieldType(FIELD_TYPES.ATLAS_PROJECT)}
				</Container>
			</PolarisTooltip>
		</Container>
	);
};

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	renderFallback: ReactElement;
};

export const AtlasProjectTargetDate = ({ issueId, fieldKey, renderFallback }: Props) => {
	const atlasProjectField = useFieldOfType(FIELD_TYPES.ATLAS_PROJECT);
	if (!atlasProjectField) {
		return null;
	}
	return (
		<InternalAtlasProjectTargetDate
			fieldKey={fieldKey}
			atlasProjectFieldKey={atlasProjectField.key}
			issueId={issueId}
			renderFallback={renderFallback}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

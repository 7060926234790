import React, { useCallback } from 'react';
import UserPicker, { type UserPickerProps } from '@atlaskit/user-picker';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { User } from '../../../../../common/types/user/index.tsx';
import { getUsers } from '../../../../../services/jira/user-picker/index.tsx';
import messages from './messages.tsx';

type UsersFieldProps = {
	isEditable: boolean;
	value: User[] | undefined;
	fieldKey: string;
	onUpdate: (inputValue: User[]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache: Record<string, any> = {};

export const UsersField = ({
	value,
	fieldKey,
	isEditable,
	onUpdate,
	...fieldProps
}: UsersFieldProps) => {
	const { formatMessage } = useIntl();

	const onChange = useCallback<NonNullable<UserPickerProps['onChange']>>(
		(changedValue) => {
			if (!changedValue) {
				onUpdate([]);
			} else {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onUpdate(changedValue as User[]);
			}
		},
		[onUpdate],
	);

	return (
		<UserPicker
			{...fieldProps}
			isDisabled={!isEditable}
			value={value}
			placeholder={formatMessage(messages.placeholder)}
			fieldId={fieldKey}
			isMulti
			menuPosition="fixed"
			styles={{
				menuPortal: (base) => ({ ...base, zIndex: layers.modal }),

				menu: (base) => ({ ...base, zIndex: layers.modal }),
			}}
			loadOptions={(query) => {
				if (cache[query || ''] !== undefined) {
					return Promise.resolve(cache[query || '']);
				}
				return getUsers(query).then((response) => {
					cache[query || ''] = response;
					return response;
				});
			}}
			onChange={onChange}
			ariaLabel={formatMessage(messages.placeholder)}
		/>
	);
};

import React from 'react';
import { ViewIdMappingSynchroniser } from './legacy-utils/index.tsx';
import { type ExternalProps, InnerViewContainer } from './main.tsx';

export const ViewContainer = ({ children, ...rest }: ExternalProps) => {
	return (
		<InnerViewContainer {...rest}>
			<ViewIdMappingSynchroniser />
			{children}
		</InnerViewContainer>
	);
};

import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useGetIntervalFieldsRollup } from '../../../../controllers/issue/selectors/fields-rollup-hooks.tsx';
import { Wrapper } from '../../styled.tsx';
import type { Props } from '../../types.tsx';
import { messages } from '../messages.tsx';

export const IntervalFieldGroupedRange = ({ fieldKey, groupId, groupFieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const val = useGetIntervalFieldsRollup(fieldKey, groupFieldKey, groupId);

	const emptyMessage = formatMessage(messages.empty);

	const minMessage = formatMessage(messages.rangeVal, {
		val: `${val?.min ?? emptyMessage}`,
	});

	const maxMessage = formatMessage(messages.rangeVal, {
		val: `${val?.max ?? emptyMessage}`,
	});

	return (
		<Wrapper>
			{minMessage} - {maxMessage}
		</Wrapper>
	);
};

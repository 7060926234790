import { createStateHook } from '@atlassian/react-sweet-state';
import { OnboardingStore } from '../index.tsx';
import {
	getIsCreateIdeaSpotlightShown,
	getIsCreateInsightSpotlightShown,
	getIsCreateDeliveryTicketSpotlightShown,
	getIsJoinCommunityFlagShown,
} from './spotlights.tsx';

export const useIsCreateIdeaSpotlightShown = createStateHook(OnboardingStore, {
	selector: getIsCreateIdeaSpotlightShown,
});

export const useIsCreateInsightSpotlightShown = createStateHook(OnboardingStore, {
	selector: getIsCreateInsightSpotlightShown,
});

export const useIsCreateDeliveryTicketSpotlightShown = createStateHook(OnboardingStore, {
	selector: getIsCreateDeliveryTicketSpotlightShown,
});
export const useIsJoinCommunityFlagShown = createStateHook(OnboardingStore, {
	selector: getIsJoinCommunityFlagShown,
});

import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { N400 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import messages from './messages.tsx';

export const FilteredColumnPanel = () => {
	const { formatMessage } = useIntl();
	const canManageCurrentView = useCanManageCurrentView();

	if (!canManageCurrentView) {
		return null;
	}

	return (
		<EmptyColumnPlaceholderContainer>
			<EmptyColumnPlaceholderContent>
				{formatMessage(messages.filteredColumnIndicator)}
			</EmptyColumnPlaceholderContent>
		</EmptyColumnPlaceholderContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyColumnPlaceholderContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: N400,
	alignItems: 'center',
	marginRight: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyColumnPlaceholderContent = styled.div({
	display: 'flex',
	width: '187px',
	alignItems: 'center',
	textAlign: 'center',
	justifyContent: 'center',
	marginTop: token('space.250', '20px'),
});

import type { BaseTableApi, RowData } from '../../common/types/react-base-table/index.tsx';
import type { RowVirtualisationState } from '../../controllers/types.tsx';
import type { ListApi, ScrollToRowAlign } from '../../types.tsx';

export type CreateListApiOptions = {
	baseTableApi: BaseTableApi;
	rawRowsDataRef: React.MutableRefObject<RowData[]>;
	rowsRenderedRef: React.MutableRefObject<RowVirtualisationState>;
	isGrouped?: boolean;
};

export function createListApi({
	baseTableApi,
	rawRowsDataRef,
	rowsRenderedRef,
	isGrouped = false,
}: CreateListApiOptions): ListApi {
	const ensureRowVisibleByIndex: ListApi['ensureRowVisibleByIndex'] = (
		_rowIndex,
		{ preferNextRowPinnedBottomVisibility, preferRowStickyVisibility } = {
			preferNextRowPinnedBottomVisibility: false,
			preferRowStickyVisibility: false,
		},
	) => {
		// 'empty-row' has `index: -1` which means that `startIndex` and `stopIndex`
		// returned by `react-base-table` are increased by 1 comparing
		// with what we store inside `rawRowsData[number].index`
		// @see https://stash.atlassian.com/projects/atlassian/repos/atlassian-frontend-monorepo/browse/jira/src/packages/polaris/lib/list/src/controllers/common/items/index.tsx?at=271f0acafea475353393bb01f3a8610986fd1f0b#6-13
		//
		// To make everything a bit easier,
		// we treat any index here as an index of the `rawRowsData` array
		// thererfore we increase `rowIndex` by 1
		const rowIndex = _rowIndex + 1;
		const { startIndex, stopIndex } = rowsRenderedRef.current;

		// In case when group rows are not rendered,
		// UI always renders one row
		//   1. [empty row] OR [sticky header]
		//
		//
		// In case when group rows are rendered,
		// UI always renders two rows
		//   1. [empty row] OR [sticky header]
		//   2. [group (sticky) row]
		//
		// As example, please take a look at a naive illustration
		// of what UI renders in the browser
		//
		// ---------------START SCROLLING AREA---------------
		// [startIndex][empty row] [index: -1]
		//             [group row] [index: 0]
		//             [row      ] [index: 1]
		//             [row      ] [index: 2]
		// [stopIndex] [row      ] [index: 3]
		// ---------------STOP SCROLLING AREA---------------
		//             [group row] [index: 4]
		//             [row      ] [index: 5] ◂ scrollTo
		//             [row      ] [index: 6]
		//
		// If we want to scroll to the row with [index: 5],
		// we really need to scroll to the row with [index: 3]
		// because the UI will render things like below
		//
		// ---------------START SCROLLING AREA---------------
		// [startIndex][sticky header] [row] [index: 3]
		//             [group row          ] [index: 4]
		//             [row                ] [index: 5]
		//             [row                ] [index: 6]
		// [stopIndex] [row                ] [index: 7]
		// ---------------STOP SCROLLING AREA---------------
		//             [row                ] [index: 8]
		//             [row                ] [index: 9]

		const diff = isGrouped ? 2 : 1;

		if (preferRowStickyVisibility && rowIndex <= startIndex + diff) {
			const prevRowIndex = rowIndex - diff;
			const prevRow = rawRowsDataRef.current[prevRowIndex];
			if (prevRow) {
				baseTableApi.scrollToRow(prevRowIndex, 'start');
				return;
			}
		}

		if (preferNextRowPinnedBottomVisibility && rowIndex >= stopIndex) {
			const nextRowIndex = rowIndex + 1;
			const nextRow = rawRowsDataRef.current[nextRowIndex];
			if (nextRow && nextRow.type === 'ITEM_PINNED_BOTTOM') {
				baseTableApi.scrollToRow(nextRowIndex, 'end');
				return;
			}
		}

		let align: Extract<ScrollToRowAlign, 'start' | 'end'> = 'start';

		if (rowIndex > startIndex && rowIndex < stopIndex) {
			//     --------------
			//     8 ◂ startIndex
			//     9
			//     |
			//     x ◂ scrollTo
			//     |
			//     29
			//     30 ◂ stopIndex
			//     --------------
			//
			// The row is visible and scrolling is not required
			return;
		}

		if (rowIndex <= startIndex) {
			//     x ◂ scrollTo
			//     |
			//     --------------
			//     8 ◂ startIndex
			//     9
			//     |
			//     |
			//     29
			//     30 ◂ stopIndex
			//     --------------
			align = 'start';
		} else if (rowIndex >= stopIndex) {
			//     --------------
			//     8 ◂ startIndex
			//     9
			//     |
			//     |
			//     29
			//     30 ◂ stopIndex
			//     --------------
			//     |
			//     x ◂ scrollTo
			align = 'end';
		}

		baseTableApi.scrollToRow(rowIndex, align);
	};

	const getRowIndexById: ListApi['getRowIndexById'] = (id) => {
		const maybeRowData = rawRowsDataRef.current.find((rowData) => rowData.id === id);
		if (!maybeRowData) {
			return -1;
		}

		return maybeRowData.index;
	};

	const scrollToRow: ListApi['scrollToRow'] = (rowIndex, align) => {
		baseTableApi.scrollToRow(rowIndex, align);
	};

	return {
		ensureRowVisibleByIndex,
		getRowIndexById,
		scrollToRow,
	};
}

import type { ApolloQueryResult } from 'apollo-client';
import gqlTagPolaris from 'graphql-tag';
import { AnyAri } from '@atlassian/ari/any-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_GetProjectMetadataQuery } from './__generated_apollo__/jira_polaris_GetProjectMetadataQuery';

export const PROJECT_METADATA_QUERY = gqlTagPolaris`
query jira_polaris_GetProjectMetadataQuery($ari: ID!) {
    polarisProject(id: $ari, skipRefresh: true) {
      id
      onboarded
    }
  }
`;

const assertNoError = (result: ApolloQueryResult<jira_polaris_GetProjectMetadataQuery>) => {
	if (result.errors !== undefined && result.errors !== null) {
		throw new Error(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			`project-onboarded.fetch-error:${result.errors.map((e: any) => e.message).join(', ')}`,
		);
	}
};

export const fetchProjectMetadata = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
): Promise<{ onboarded: boolean }> => {
	const projectAri = AnyAri.create({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});
	return client
		.query<jira_polaris_GetProjectMetadataQuery>({
			query: PROJECT_METADATA_QUERY,
			variables: {
				ari: projectAri,
			},
		})
		.then((result) => {
			assertNoError(result);
			if (
				!result.data ||
				result.data.polarisProject === undefined ||
				result.data.polarisProject === null
			) {
				throw new Error('project-onboarded.fetch-error: no data or no data node');
			}

			return { onboarded: result.data.polarisProject.onboarded };
		});
};

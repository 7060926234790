import React, { useCallback, type ReactNode } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { SnippetAction } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { isConfigurePropertiesAction } from '../../../common/utils/snippet/index.tsx';
import { messages } from './messages.tsx';

type DropdownActionsProps = {
	actions: SnippetAction[];
	additionalDropdownItems?: ReactNode;
	onConfigureProperties?: () => void;
	onOpenChange?: (isOpen: boolean) => void;
};

export const DropdownActions = ({
	actions,
	additionalDropdownItems,
	onConfigureProperties,
	onOpenChange,
}: DropdownActionsProps) => {
	const { formatMessage } = useIntl();
	const handleOpenChange = useCallback(
		({ isOpen }: { isOpen: boolean }) => {
			onOpenChange && onOpenChange(isOpen);
		},
		[onOpenChange],
	);
	const validActions = (actions || [])
		.filter(isConfigurePropertiesAction)
		.filter(() => !!onConfigureProperties)
		.map(() => ({
			onClick: onConfigureProperties,
			name: formatMessage(messages.configurePropertiesAction),
		}));
	if (
		!validActions.length &&
		(additionalDropdownItems === undefined ||
			additionalDropdownItems === null ||
			typeof additionalDropdownItems !== 'object')
	) {
		return null;
	}
	return (
		<Box xcss={menuWrapperStyles}>
			<DropdownMenu
				trigger={({ triggerRef, ...triggerProps }) => (
					<Button
						{...triggerProps}
						appearance="subtle"
						spacing="none"
						iconBefore={<MoreIcon size="medium" label="more" />}
						ref={triggerRef}
					/>
				)}
				placement="bottom-end"
				onOpenChange={handleOpenChange}
			>
				<DropdownItemGroup>
					{validActions.map((validAction, index) => (
						<DropdownItem key={index} onClick={onConfigureProperties}>
							{formatMessage(messages.configurePropertiesAction)}
						</DropdownItem>
					))}
					{additionalDropdownItems}
				</DropdownItemGroup>
			</DropdownMenu>
		</Box>
	);
};

const menuWrapperStyles = xcss({
	marginRight: 'space.050',
});

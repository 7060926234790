import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { useIntl } from '@atlassian/jira-intl';
import { useRightSidebarActions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4 } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type FieldSettingsProps = {
	fieldKey: string;
	isFirstRow: boolean;
};

export const FieldSettings = ({ fieldKey, isFirstRow }: FieldSettingsProps) => {
	const { formatMessage } = useIntl();
	const [, { openOnField }] = useRightSidebarActions();

	const onClickHandler = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'fieldSettings');
			openOnField(fieldKey, undefined, 'projectFieldsPage');
		},
		[fieldKey, openOnField],
	);

	const button = (
		<IconButton
			appearance="subtle"
			icon={SettingsIcon}
			label={formatMessage(messages.settings)}
			onClick={onClickHandler}
			testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.settings"
		/>
	);

	if (isFirstRow) {
		return <SpotlightTarget name={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4}>{button}</SpotlightTarget>;
	}

	return button;
};

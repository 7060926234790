export const JIRA_LONG_RUNNING_TASK_STATUS = {
	RUNNING: 'RUNNING',
	ENQUEUED: 'ENQUEUED',
	FAILED: 'FAILED',
	DEAD: 'DEAD',
	COMPLETE: 'COMPLETE',
	CANCELLED: 'CANCELLED',
	CANCEL_REQUESTED: 'CANCEL_REQUESTED',
};

export const JIRA_LONG_RUNNING_TASK_FAILURE_STATUSES = [
	JIRA_LONG_RUNNING_TASK_STATUS.CANCELLED,
	JIRA_LONG_RUNNING_TASK_STATUS.FAILED,
	JIRA_LONG_RUNNING_TASK_STATUS.DEAD,
];

import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

const UPDATE_VIEW_LAST_VIEWED_TIMESTAMP = gqlTagPolaris`
mutation jira_polaris_UpdateViewLastViewedTimestamp($viewAri: ID!, $timestamp: String!) {
    updatePolarisViewLastViewedTimestamp(viewId: $viewAri, timestampInput: $timestamp) {
        success
        errors {
            message
        }
    }
}
`;

export type ViewLastViewedTimestampUpdater = (arg1: {
	viewId: string; // Ari
	lastViewed: string;
}) => Promise<void>;

export const updateViewLastViewedPolarisApi = async (
	apolloClient: PolarisApolloClient,
	viewAri: string,
	lastViewed: string,
): Promise<void> => {
	const result = await apolloClient.mutate({
		mutation: UPDATE_VIEW_LAST_VIEWED_TIMESTAMP,
		variables: {
			viewAri,
			timestamp: lastViewed,
		},
		errorPolicy: 'all',
	});

	if (result.errors !== undefined) {
		throw new Error(
			`polaris.view-update-timestamp.error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
};

import { useEffect, useState } from 'react';
import { useOpenRightSidebarOnFilterRemoveTmpFieldKey } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarFilterTmpFieldKey } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { useAllowedFiltersFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type UseTmpFiltersKeysStateArgs = {
	canAddFilters?: boolean;
	fieldsToSkip?: Field[];
	currentActiveFields?: Field[];
};

export const useTmpFiltersKeysState = ({
	canAddFilters = true,
	fieldsToSkip = [],
	currentActiveFields = [],
}: UseTmpFiltersKeysStateArgs) => {
	const [tmpFieldKey] = useRightSidebarFilterTmpFieldKey();
	const openRightSidebarOnFilterRemoveTmpFieldKey = useOpenRightSidebarOnFilterRemoveTmpFieldKey();
	const allowedFiltersFields = useAllowedFiltersFields();

	const [tmpFiltersKeys, setTmpFiltersKeys] = useState<FieldKey[]>(() => {
		const currentActiveFieldsKeys = currentActiveFields.map((field) => field.key);

		if (!tmpFieldKey || !canAddFilters) {
			return currentActiveFieldsKeys;
		}

		const isSkippedField = fieldsToSkip.some((field) => field.key === tmpFieldKey);
		if (isSkippedField) {
			return currentActiveFieldsKeys;
		}

		const notAvailableFieldKey = !allowedFiltersFields.some((field) => field.key === tmpFieldKey);
		if (notAvailableFieldKey) {
			return currentActiveFieldsKeys;
		}

		const set = new Set(currentActiveFieldsKeys);
		set.add(tmpFieldKey);
		return [...set];
	});

	useEffect(() => {
		openRightSidebarOnFilterRemoveTmpFieldKey();
	}, [openRightSidebarOnFilterRemoveTmpFieldKey]);

	return {
		tmpFiltersKeys,
		setTmpFiltersKeys,
		tmpFieldKey,
	};
};

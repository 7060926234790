import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIssuesStatusesById } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useAllStatusesById } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const StatusLozenge = ({
	categoryName,
	statusName,
}: {
	categoryName: string;
	statusName: string;
}) => {
	switch (categoryName) {
		case 'In Progress':
			return <Lozenge appearance="inprogress">{statusName}</Lozenge>;
		case 'Done':
			return <Lozenge appearance="success">{statusName}</Lozenge>;
		case 'To Do':
		default:
			return <Lozenge>{statusName}</Lozenge>;
	}
};

const StatusFieldValue = ({ groupIdentity }: FieldValueComponentProps) => {
	const statiById = useAllStatusesById();
	const issuesStatusesById = useIssuesStatusesById();
	const isCollectionView = useIsCollectionView();
	const status = isCollectionView ? issuesStatusesById[groupIdentity] : statiById[groupIdentity];

	if (status === undefined) return null;

	return (
		<Box xcss={containerStyles}>
			<StatusLozenge categoryName={status.statusCategory.name} statusName={status.name} />
		</Box>
	);
};

export const statusFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: StatusFieldValue,
	EmptyComponent: undefined,
} as const;

const containerStyles = xcss({
	paddingBlock: 'space.025',
	paddingInlineStart: 'space.050',
	maxWidth: '100%',
	cursor: 'pointer',
});

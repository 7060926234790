import React from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji/element';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { ViewItemIcon } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { ExpandableSection } from '@atlassian/jira-polaris-lib-expandable-section/src/ui/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { getViewLink } from '../../../../common/utils/view-link/index.tsx';
import {
	useViewEmoji,
	useViewIdsConfiguredByField,
	useViewKind,
	useViewSlug,
	useViewTitle,
} from '../../../../controllers/views/selectors/view-hooks.tsx';
import { messages } from './messages.tsx';

const ViewItem = ({ viewId }: { viewId?: string }) => {
	const projectKey = useProjectKeyUnsafe();
	const title = useViewTitle(viewId);
	const viewKind = useViewKind(viewId);
	const emojiId = useViewEmoji(viewId);
	const emoji = useEmoji(emojiId);
	const viewSlug = useViewSlug(viewId);
	const href = viewSlug ? getViewLink(projectKey, viewSlug) : '/';

	return (
		<LinkItem target="_blank" href={href}>
			<IconWrapper>
				{emoji ? <Emoji emoji={emoji} fitToHeight={18} /> : <ViewItemIcon viewKind={viewKind} />}
			</IconWrapper>
			<ViewTitle>{title}</ViewTitle>
		</LinkItem>
	);
};

export const ViewsConfiguredByField = ({ fieldKey }: { fieldKey: FieldKey }) => {
	const { formatMessage } = useIntl();
	const viewIdsConfiguredByField = useViewIdsConfiguredByField(fieldKey);
	const isFieldUsed = !!viewIdsConfiguredByField.length;

	if (!isFieldUsed) {
		return null;
	}

	return (
		<Box paddingBlockStart="space.200">
			<ExpandableSection
				title={formatMessage(messages.affectedViews)}
				backgroundColor="color.background.warning"
			>
				<ViewItemsWrapper>
					{viewIdsConfiguredByField.map((id) => (
						<ViewItem key={id} viewId={id} />
					))}
				</ViewItemsWrapper>
			</ExpandableSection>
		</Box>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkItem = styled(Link)({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.100', '8px'),
	width: 'fit-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: token('space.300', '24px'),
	width: token('space.300', '24px'),
	marginRight: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewTitle = styled.div({
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	maxWidth: 500,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewItemsWrapper = styled.div({
	maxHeight: 320,
	overflowY: 'auto',
});

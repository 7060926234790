import React, { useCallback, useState } from 'react';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type ExpandableSectionProps = {
	label: React.ReactNode;
	renderContent: (props: { collapse: () => void }) => React.ReactNode;
	expanded?: boolean;
	fixed?: boolean;
};

export const ExpandableSection = ({
	label,
	renderContent,
	expanded = false,
	fixed = false,
}: ExpandableSectionProps) => {
	const { formatMessage } = useIntl();
	const [isExpanded, setIsExpanded] = useState(expanded);

	const controls = isExpanded ? (
		<ChevronUpIcon label={formatMessage(messages.collapse)} />
	) : (
		<ChevronDownIcon label={formatMessage(messages.expand)} />
	);

	const collapse = useCallback(() => {
		setIsExpanded(false);
	}, []);

	return (
		<Box xcss={expandableSectionStyles} as="li">
			<Pressable
				isDisabled={fixed === true}
				xcss={[pressableHeaderStyles, fixed && normalCursorStyles]}
				backgroundColor="color.background.neutral.subtle"
				onClick={() => {
					setIsExpanded(!isExpanded);
				}}
			>
				{label}
				{fixed === false && controls}
			</Pressable>
			{isExpanded && <Box xcss={sectionContentStyles}>{renderContent({ collapse })}</Box>}
		</Box>
	);
};

const normalCursorStyles = xcss({
	cursor: 'auto',
});

const sectionContentStyles = xcss({
	marginInline: 'space.150',
	paddingBlock: 'space.0',
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
});

const pressableHeaderStyles = xcss({
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 'space.150',
	width: '100%',
	paddingInline: 'space.150',
	paddingBlock: 'space.150',
});

const expandableSectionStyles = xcss({
	borderWidth: 'border.width',
	borderColor: 'color.border',
	borderRadius: 'border.radius',
	borderStyle: 'solid',
	listStyle: 'none outside none',
});

import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import PublishFeaturePopup from './ui/index.tsx';

export const PublishFeatureDiscovery = () => {
	if (fg('jpd_publishing_feature_gate_improvement_gate')) {
		return (
			<ErrorBoundary
				id="jpd-publish-feature-discovery"
				packageName="jiraJpdPublishFeatureDiscovery"
			>
				<ReportErrors
					teamName="transmuters-growth"
					id="jpd-publish-feature-discovery"
					packageName="jiraJpdPublishFeatureDiscovery"
				>
					<PublishFeaturePopup />
				</ReportErrors>
			</ErrorBoundary>
		);
	}

	return null;
};

import React, { useEffect, useCallback } from 'react';
import InfoIcon from '@atlaskit/icon/core/information';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { GlobalFieldCopyValueTask } from '@atlassian/jira-polaris-domain-field/src/global-field/types.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import messages from './messages.tsx';

type NotificationConfig = {
	type: 'info' | 'success' | 'error';
	title: MessageDescriptor;
	description: MessageDescriptor;
	closeOnUnmount?: boolean;
};

type LocalizedNotification = { title: React.ReactNode; description: React.ReactNode };

const notificationConfigurationByStatus: Readonly<
	Record<GlobalFieldCopyValueTask['status'], NotificationConfig>
> = {
	INPROGRESS: {
		type: 'info',
		title: messages.taskInProgressTitle,
		description: messages.taskInProgressDescription,
		closeOnUnmount: true,
	},
	DONE: {
		type: 'success',
		title: messages.taskFinishedTitle,
		description: messages.taskFinishedDescription,
	},
	FAILED: {
		type: 'error',
		title: messages.taskFailedTitle,
		description: messages.taskFailedDescription,
	},
};

const useCopyValuesNotification = (task: GlobalFieldCopyValueTask) => {
	const { success, customFlag, error } = useNotifications();
	const { formatMessage } = useIntl();

	const notificationConfig = notificationConfigurationByStatus[task.status];

	const showNotification = useCallback(
		(
			notification: LocalizedNotification & {
				type: NotificationConfig['type'];
			},
		) => {
			if (notification.type === 'success') {
				return success(notification);
			}
			if (notification.type === 'error') {
				return error(notification);
			}
			return customFlag({
				...notification,
				icon: <InfoIcon label="Info" color={token('color.icon.accent.blue')} spacing="spacious" />,
			});
		},
		[success, customFlag, error],
	);

	useEffect(() => {
		let hideFlag: ReturnType<typeof showNotification> | undefined;

		// timeout here gives an in-progress notification some time to gracefully disappear before it's replaced with a success of a failed notification
		const timeoutId = setTimeout(() => {
			hideFlag = showNotification({
				title: formatMessage(notificationConfig.title, {
					projectFieldName: task.fromFieldLabel,
					globalFieldName: task.toFieldLabel,
					icon: () => <GlobalFieldMarker hideTooltip noMargins inline />,
					i: (text: React.ReactNode) => <i>{text}</i>,
				}),
				description: formatMessage(notificationConfig.description),
				type: notificationConfig.type,
			});
		}, 0);

		return () => {
			clearTimeout(timeoutId);

			if (notificationConfig.closeOnUnmount) {
				hideFlag?.();
			}
		};
	}, [showNotification, notificationConfig, formatMessage, task.fromFieldLabel, task.toFieldLabel]);
};

type CopyValuesTaskProgressProps = {
	task: GlobalFieldCopyValueTask;
};

const CopyValuesTaskProgress = ({ task }: CopyValuesTaskProgressProps) => {
	useCopyValuesNotification(task);

	return null;
};

type CopyValuesProgressProps = {
	tasks: GlobalFieldCopyValueTask[];
};

export const CopyValuesProgress = ({ tasks }: CopyValuesProgressProps) => {
	return (
		<>
			{tasks.map((task) => (
				<CopyValuesTaskProgress key={task.taskId} task={task} />
			))}
		</>
	);
};

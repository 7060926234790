/** @jsx jsx */
import React, { type PropsWithChildren } from 'react';
import { css, jsx } from '@compiled/react';
import { Anchor, Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import { useConnectedIssueDataByIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useIssueViewLayout } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { UnlinkButton } from './unlink/index.tsx';

type Props = {
	issueId: number;
};

export const IssueSummaryCellRenderer = ({ issueId }: Props) => {
	const connectedIssue = useConnectedIssueDataByIssueId(issueId.toString());

	if (!connectedIssue) {
		return null;
	}

	return (
		<Inline space="space.100" alignBlock="center" xcss={containerStyles}>
			<img
				width={16}
				height={16}
				src={connectedIssue.issueType.iconUrl}
				alt={connectedIssue.issueType.name}
			/>

			<div css={summaryStyles}>
				<Tooltip content={connectedIssue.summary}>
					<IssueLink issueKey={connectedIssue.issueKey}>{connectedIssue.summary}</IssueLink>
				</Tooltip>
			</div>

			<UnlinkButton issueId={issueId} />
		</Inline>
	);
};

const IssueLink = ({ issueKey, children }: PropsWithChildren<{ issueKey: string }>) => {
	const issueViewLayout = useIssueViewLayout();
	const { link, target, onClick } = useIssueLink({
		issueKey,
		shouldOpenInSidebar: issueViewLayout === 'sidebar',
		issueViewSection: 'connections',
	});

	return (
		<Anchor onClick={onClick} xcss={linkStyles} href={link} target={target}>
			{children}
		</Anchor>
	);
};

const containerStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	height: '100%',
});

const summaryStyles = css({
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> div': {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});

const linkStyles = xcss({
	color: 'color.text',
	textDecoration: 'none',

	':hover': {
		color: 'color.text',
	},
});

import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { ButtonGroup } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useHasReachedInsightsLimit } from '../../../../../controllers/insights/selectors/insights-hooks.tsx';
import { useErrorMessage } from '../../common/utils.tsx';
import { messages } from './messages.tsx';

type AddInsightButtonsProps = {
	isDisabled: boolean;
	onSave: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	onCancel: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	isUpdating?: boolean;
};

export const AddInsightButtons = ({
	onCancel,
	onSave,
	isDisabled,
	isUpdating,
}: AddInsightButtonsProps) => {
	const { formatMessage } = useIntl();
	return (
		<Container>
			<ButtonGroup>
				<Button
					id="polaris-insights.src.ui.add-insight.buttons.add-button"
					appearance="primary"
					isDisabled={isDisabled}
					onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'saveInsight');
						onSave(event, analyticsEvent);
					}}
				>
					{formatMessage(isUpdating ? messages.saveButton : messages.createButton)}
				</Button>
				<Button
					id="polaris-insights.src.ui.add-insight.buttons.cancel-button"
					appearance="subtle"
					onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'discardInsight');
						onCancel(event, analyticsEvent);
					}}
				>
					{formatMessage(messages.cancelButton)}
				</Button>
			</ButtonGroup>
		</Container>
	);
};

AddInsightButtons.defaultProps = {
	isDisabled: false,
};

type CreateInsightButtonProps = {
	isArchived: boolean;
	onEdit: () => void;
};

export const CreateInsightButton = ({ isArchived, onEdit }: CreateInsightButtonProps) => {
	const { formatMessage } = useIntl();
	const [limitType, limitValue] = useHasReachedInsightsLimit();
	const errorMessage = useErrorMessage({
		isArchived,
		hasReachedInsightsLimit: { limitType, limitValue },
	});

	const isDisabled = isArchived || !!limitType;

	return (
		<CreateEntityLimitTooltip message={isDisabled && errorMessage}>
			<Button
				testId="polaris-ideas.ui.insights.insights.add-insight.buttons.button"
				appearance="primary"
				isDisabled={isDisabled}
				spacing="default"
				onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
					fireCompoundAnalyticsEvent.IdeaView.InsightsTab.createInsightButtonClicked(
						analyticsEvent,
						true,
					);
					onEdit();
				}}
			>
				{formatMessage(messages.addInsightButton)}
			</Button>
		</CreateEntityLimitTooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	paddingTop: token('space.025', '2px'),
});

import React from 'react';
import { useGetCommentFieldsRollup } from '../../../controllers/issue/selectors/fields-rollup-hooks.tsx';
import { CommonFieldGrouped } from '../common/index.tsx';
import { messages } from '../messages.tsx';
import type { Props } from '../types.tsx';

export const CommentsFieldGrouped = ({
	groupId,
	groupFieldKey,
	rollupOperation,
}: Omit<Props, 'fieldKey'>) => {
	const val = useGetCommentFieldsRollup(groupFieldKey, groupId, rollupOperation);
	return (
		<CommonFieldGrouped
			rollupOperation={rollupOperation}
			val={val}
			message={messages.commentsCount}
		/>
	);
};

import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	convertLogicToRules,
	generateLocalDecorationId,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type { FieldPresentation } from '@atlassian/jira-polaris-domain-field/src/presentation/types.tsx';
import type { JiraField } from '../../../../services/types.tsx';

export const getPresentationType = ({ configuration }: JiraField): FieldPresentation | undefined =>
	configuration?.polarisPresentation ? { type: configuration.polarisPresentation } : undefined;

export const getOptions = ({ options, configuration }: JiraField) => {
	const weights = configuration?.optionWeights ?? [];
	return (
		options?.map(
			({ id, value }): FieldOption => ({
				id: `${id}`,
				jiraOptionId: `${id}`,
				value,
				weight: weights.find(({ optionId }) => optionId === id)?.weight ?? 1,
			}),
		) ?? undefined
	);
};

// This is a straight copy from ../../polaris-api/transform/utils
const nullToUndefined = (value?: string | null): string | undefined => {
	if (value !== null) {
		return value;
	}

	return undefined;
};

export const transformDecorations = ({ configuration }: JiraField): ValueDecoration[] => {
	const { valueDecorations } = configuration ?? {};
	if (valueDecorations != null) {
		return valueDecorations.map((decor) => ({
			localDecorationId: generateLocalDecorationId(),
			emoji: nullToUndefined(decor.emoji),
			backgroundColor: nullToUndefined(decor.backgroundColor),
			highlightContainer: decor.highlightContainer || false,
			rules: convertLogicToRules(decor.logic),
		}));
	}

	return [];
};

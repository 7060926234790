import React, { useCallback } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import { useSwitchedToProjectRankFlag } from './utils.tsx';

type CommitToProjectRankItemProps = {
	disabledButtonMessageWithCanEditIssues?: string;
	setSortModeOfCurrentViewToProjectRankAndCommitViewRank: () => void;
};

type SwitchToProjectRankItemProps = {
	disabledButtonMessage?: string;
	setSortModeOfCurrentViewToProjectRank: (clearIssueRanking?: boolean | undefined) => void;
};

type ViewRankContentProps = CommitToProjectRankItemProps & SwitchToProjectRankItemProps;

export const SwitchToProjectRankItem = ({
	disabledButtonMessage,
	setSortModeOfCurrentViewToProjectRank,
}: SwitchToProjectRankItemProps) => {
	const showSwitchedToProjectRankFlag = useSwitchedToProjectRankFlag();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickBackToProjectRank = useCallback(() => {
		setSortModeOfCurrentViewToProjectRank();
		showSwitchedToProjectRankFlag();
		fireCompoundAnalyticsEvent.SortChangeToProjectRankButtonClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent, setSortModeOfCurrentViewToProjectRank, showSwitchedToProjectRankFlag]);

	const { formatMessage } = useIntl();
	return (
		<Tooltip content={disabledButtonMessage}>
			<DropdownItem
				testId="polaris-component-view-sort-configuration.ui.advanced.view-rank.switch-to-project-rank"
				description={
					<Box xcss={descriptionStyles}>{formatMessage(messages.backToProjectRankDescription)}</Box>
				}
				onClick={onClickBackToProjectRank}
				isDisabled={!!disabledButtonMessage}
			>
				{formatMessage(messages.backToProjectRank)}
			</DropdownItem>
		</Tooltip>
	);
};

export const CommitToProjectRankItem = ({
	disabledButtonMessageWithCanEditIssues,
	setSortModeOfCurrentViewToProjectRankAndCommitViewRank,
}: CommitToProjectRankItemProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const showSwitchedToProjectRankFlag = useSwitchedToProjectRankFlag();

	const onClickBackToProjectRankWithCommit = useCallback(() => {
		setSortModeOfCurrentViewToProjectRankAndCommitViewRank();
		showSwitchedToProjectRankFlag();
		fireCompoundAnalyticsEvent.SortChangeToProjectRankAndCommitButtonClicked(
			createAnalyticsEvent({}),
		);
	}, [
		createAnalyticsEvent,
		setSortModeOfCurrentViewToProjectRankAndCommitViewRank,
		showSwitchedToProjectRankFlag,
	]);

	const { formatMessage } = useIntl();
	return (
		<Tooltip content={disabledButtonMessageWithCanEditIssues}>
			<DropdownItem
				testId="polaris-component-view-sort-configuration.ui.advanced.view-rank.convert-to-project-rank"
				description={
					<Box xcss={descriptionStyles}>
						{formatMessage(messages.backToProjectRankWithUpdateDescription)}
					</Box>
				}
				onClick={onClickBackToProjectRankWithCommit}
				isDisabled={!!disabledButtonMessageWithCanEditIssues}
			>
				{formatMessage(messages.backToProjectRankWithUpdate)}
			</DropdownItem>
		</Tooltip>
	);
};

export const ViewRankContent = ({
	disabledButtonMessage,
	disabledButtonMessageWithCanEditIssues,
	setSortModeOfCurrentViewToProjectRank,
	setSortModeOfCurrentViewToProjectRankAndCommitViewRank,
}: ViewRankContentProps) => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlockEnd="space.300">
			<Box paddingBlockStart="space.075" paddingBlockEnd="space.150">
				<Text>{formatMessage(messages.manualRankDescription)}</Text>
			</Box>
			<Box paddingBlockEnd="space.075">
				<Text size="UNSAFE_small">{formatMessage(messages.viewRankHeader)}</Text>
			</Box>
			<Box paddingBlockEnd="space.200">
				<Text size="UNSAFE_small">{formatMessage(messages.viewRankDescription)}</Text>
			</Box>
			<Flex alignItems="center">
				<DropdownMenu
					testId="polaris-component-view-sort-configuration.ui.advanced.view-rank.change-to-project-rank-mode-trigger"
					trigger={formatMessage(messages.changeToProjectRankModeTrigger)}
					placement="top"
				>
					<Box xcss={dropdownMenuWrapperStyles}>
						<DropdownItemGroup>
							<SwitchToProjectRankItem
								disabledButtonMessage={disabledButtonMessage}
								setSortModeOfCurrentViewToProjectRank={setSortModeOfCurrentViewToProjectRank}
							/>
							<CommitToProjectRankItem
								disabledButtonMessageWithCanEditIssues={disabledButtonMessageWithCanEditIssues}
								setSortModeOfCurrentViewToProjectRankAndCommitViewRank={
									setSortModeOfCurrentViewToProjectRankAndCommitViewRank
								}
							/>
						</DropdownItemGroup>
					</Box>
				</DropdownMenu>
				<Box paddingInlineStart="space.050">
					<Tooltip content={formatMessage(messages.changeToProjectRankModeTooltip)}>
						<EditorInfoIcon label="info" />
					</Tooltip>
				</Box>
			</Flex>
		</Box>
	);
};

const descriptionStyles = xcss({
	whiteSpace: 'normal',
});

const dropdownMenuWrapperStyles = xcss({
	maxWidth: '320px',
});

import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

const getUserFullEndpoint = (accountId = ''): string =>
	`/rest/api/3/user?accountId=${encodeURIComponent(accountId)}`;

export const getUser = (accountId = ''): Promise<User> => {
	if (!accountId) {
		return Promise.resolve({
			accountId: '',
			displayName: '',
			active: false,
			avatarUrls: {
				'16x16': '',
				'24x24': '',
				'32x32': '',
				'48x48': '',
			},
			emailAddress: '',
		});
	}

	return fetchJson(getUserFullEndpoint(accountId));
};

export const getUserName = async (userId: string) => {
	let userName;
	try {
		const user = await getUser(userId);
		userName = user?.displayName || undefined;
	} catch (error) {
		userName = undefined;
	}

	return userName;
};

import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import AtlasKitButton from '@atlaskit/button';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useTeamCentralBaseUrl } from '@atlassian/jira-tenant-context-controller/src/components/team-central-url/index.tsx';
import messages from './messages.tsx';

type SignInRequiredProps = {
	cloudId: string;
};

export const SignInRequired = ({ cloudId }: SignInRequiredProps) => {
	const { formatMessage } = useIntl();
	const [isOpened, setIsOpened] = useState(false);
	const icon = iconForPolarisFieldType(FIELD_TYPES.ATLAS_PROJECT);
	const teamCentralBaseUrl = useTeamCentralBaseUrl();
	const url = `${teamCentralBaseUrl}?cloudId=${cloudId}`;
	const onOpen = useCallback(
		(evt: SyntheticEvent) => {
			evt.preventDefault();
			evt.stopPropagation();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.open(url, '_blank')?.focus();
			setIsOpened(true);
		},
		[url],
	);
	const onRefresh = useCallback((evt: SyntheticEvent) => {
		evt.stopPropagation();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.reload();
	}, []);
	return (
		<Container>
			{isOpened ? (
				<Button onClick={onRefresh} appearance="subtle-link" spacing="none">
					{formatMessage(messages.refresh)}
				</Button>
			) : (
				<>
					{icon}
					<Button href={url} onClick={onOpen} appearance="subtle-link" spacing="none">
						{formatMessage(messages.atlasSignIn)}
					</Button>
				</>
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N100,
	height: '28px',
	padding: '0 3px',
	gap: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Button = styled(AtlasKitButton)({
	minWidth: 0,
});

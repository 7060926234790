import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fetchViewSets } from '../../../services/polaris-api/fetch-view-sets/index.tsx';

export const fetchAndTransformViewSets = (apolloClient: PolarisApolloClient, projectAri: Ari) =>
	fetchViewSets(apolloClient, projectAri).then((response) =>
		response.viewsets
			? {
					viewsets: [...response.viewsets].map((viewset) => ({
						...viewset,
						viewsets: [...viewset.viewsets],
					})),
				}
			: { viewsets: [] },
	);

import type {
	ItemId,
	TreeDestinationPosition,
	TreeObject,
	TreeSourcePosition,
	TreeItem,
} from '../types.tsx';

export const addChild = <TItem extends TreeItem>(
	tree: TreeObject<TItem>,
	parentId: ItemId,
	itemId: ItemId,
	index: number,
): TreeObject<TItem> => {
	const parent = tree.items[parentId];
	const newChildren = [...parent.children];
	newChildren.splice(index, 0, itemId);
	return mutateTree<TItem>(tree, parentId, {
		children: newChildren,
		hasChildren: true,
	});
};

export const removeChild = <TItem extends TreeItem>(
	tree: TreeObject<TItem>,
	parentId: ItemId,
	itemId: ItemId,
): TreeObject<TItem> => {
	const parent = tree.items[parentId];
	const newChildren = parent.children.filter((id) => id !== itemId);
	const hasChildren = newChildren.length > 0;
	if (newChildren.length !== parent.children.length) {
		return mutateTree<TItem>(tree, parentId, {
			children: newChildren,
			hasChildren,
			isExpanded: hasChildren && parent.isExpanded,
		});
	}
	return tree;
};

export const updateItemData = <TItem extends TreeItem>(
	tree: TreeObject<TItem>,
	itemId: ItemId,
	data: Partial<TItem>,
): TreeObject<TItem> =>
	mutateTree<TItem>(tree, itemId, {
		data: {
			...tree.items[itemId].data,
			...data,
		},
	});

export function moveItemOnTree<TItem extends TreeItem>(
	tree: TreeObject<TItem>,
	source: TreeSourcePosition,
	destination: TreeDestinationPosition,
): TreeObject<TItem> {
	const { items } = tree;
	const newItems = { ...items };

	// remove item from old location
	const sourceChildren = [...newItems[source.parentId].children];
	sourceChildren.splice(source.index, 1);
	newItems[source.parentId] = {
		...newItems[source.parentId],
		children: sourceChildren,
		hasChildren: Boolean(sourceChildren.length),
	};

	// put item in its new location
	const destinationParent = newItems[destination.parentId];
	const destinationChildren = [...destinationParent.children];
	const itemId = items[source.parentId].children[source.index];
	if (typeof destination.index === 'undefined') {
		/**
		 * When the destination index is undefined, we "combine" the items by appending the item to the end of destination.
		 * We ONLY do this if the destination has already loaded children OR is a leaf node.
		 * If the destination is an unexpanded item with children, the moved item will appear when the user expands.
		 */
		destinationChildren.push(itemId);
	} else {
		destinationChildren.splice(destination.index, 0, itemId);
	}
	newItems[destination.parentId] = {
		...newItems[destination.parentId],
		children: destinationChildren,
		hasChildren: true,
	};

	return {
		...tree,
		items: newItems,
	};
}

export const mutateTree = <TItem extends TreeItem>(
	tree: TreeObject<TItem>,
	itemId: ItemId,
	mutation: Partial<TreeItem>,
): TreeObject<TItem> => {
	const itemToChange = tree.items[itemId];
	if (!itemToChange) {
		// Item not found
		return tree;
	}
	// Returning a clone of the tree structure and overwriting the field coming in mutation
	return {
		// rootId should not change
		rootId: tree.rootId,
		items: {
			// copy all old items
			...tree.items,
			// overwriting only the item being changed
			[itemId]: {
				...itemToChange,
				...mutation,
			},
		},
	};
};

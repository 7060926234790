import React from 'react';
import { useHighlightedFields } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/decorations-hooks.tsx';
import { Highlighting as CommonHighlight } from '@atlassian/jira-polaris-common/src/ui/decoration/highlighting/index.tsx';
import { HighlightingTooltip } from '@atlassian/jira-polaris-common/src/ui/highlighting-tooltip/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type HighlightingProps = {
	issueId: LocalIssueId;
	isInteractive: boolean;
};

export const Highlighting = ({ issueId, isInteractive }: HighlightingProps) => {
	const highlightedFields = useHighlightedFields(issueId);
	return isInteractive ? (
		<HighlightingTooltip issueId={issueId} fields={highlightedFields} position="mouse">
			<CommonHighlight fields={highlightedFields} compact />
		</HighlightingTooltip>
	) : (
		<CommonHighlight fields={highlightedFields} compact />
	);
};

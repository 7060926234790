import type { ColumnId } from '../../common/types/index.tsx';
import {
	getFixedColumns,
	getHoveredColumn,
	getResizingColumn,
	isHoveredColumn,
} from './columns.tsx';
import { createHigherLevelHook, createSelectorHook } from './utils.tsx';

export const useFixedColumns = createSelectorHook(getFixedColumns);

export const useHoveredColumn = createSelectorHook(getHoveredColumn);

export const useIsHoveredColumn = createHigherLevelHook<boolean, [ColumnId]>(isHoveredColumn);

export const useResizingColumn = createSelectorHook(getResizingColumn);

import React, { useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIssueCreationEnabled,
	useSaveIssueCreationEnabled,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import { useProjectName } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useSharingActions } from '@atlassian/jira-polaris-common/src/controllers/sharing/main.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useIsBeta,
	useIsBetaEnforcePermissionChecksEnabledOnSite,
	useIsBetaSelfServeEnabledOnSite,
} from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { useBetaActions } from '@atlassian/jira-polaris-component-environment-tenant/src/index.tsx';
import { useHasNoProjectPermissions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import PolarisProjectAccessScreen from '@atlassian/jira-polaris-lib-project-access/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { BreadcrumbsProjectSettings } from '../breadcrumbs/index.tsx';

export const PolarisProjectAccess = () => {
	const { cloudName, isAdmin, isSiteAdmin, atlassianAccountId, locale, appEditions } =
		useTenantContext();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const projectMeta = useProjectMetadata({ projectId });
	const [projectNameOld] = useProjectName();
	const projectName = fg('polaris-refactor-skipprojectinfo') ? projectMeta?.name : projectNameOld;
	const issueCreationEnabled = useIssueCreationEnabled();
	const onIssueCreationChanged = useSaveIssueCreationEnabled();
	const { setProjectAccessLevel } = useSharingActions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const beta = useIsBeta();
	const isSelfServeEnabledOnSite = useIsBetaSelfServeEnabledOnSite();
	const isEnforcePermissionChecksEnabledOnSite = useIsBetaEnforcePermissionChecksEnabledOnSite();
	const { toggleEnforcePermissionChecks } = useBetaActions();
	const { generalActionFailedError } = useErrorHandlers();

	const { routeTo } = usePolarisRouter();

	const onRouteToFormSubmissionRequested = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'create-ideas',
		});
	}, [routeTo]);

	return (
		<PolarisProjectAccessScreen
			projectId={projectId}
			projectKey={projectKey}
			projectName={projectName || ''}
			cloudName={cloudName}
			isAdmin={isAdmin}
			isSiteAdmin={isSiteAdmin}
			hasProjectAccess={!hasNoProjectPermissions}
			isSelfServeEnabledOnSite={isSelfServeEnabledOnSite}
			issueCreationEnabled={issueCreationEnabled}
			onIssueCreationChanged={onIssueCreationChanged}
			onProjectAccessLevelChanged={setProjectAccessLevel}
			onRouteToFormSubmissionRequested={onRouteToFormSubmissionRequested}
			onToggleEnforcePermissionChecks={toggleEnforcePermissionChecks}
			accountId={atlassianAccountId}
			locale={locale}
			isBeta={beta}
			enforcePermissionChecks={isEnforcePermissionChecksEnabledOnSite}
			edition={appEditions.productDiscovery}
			onError={generalActionFailedError}
			breadcrumbs={<BreadcrumbsProjectSettings />}
		/>
	);
};

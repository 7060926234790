import isEqual from 'lodash/isEqual';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { AxisValue, Item, ItemId, MatrixEmptyState } from '../../../types.tsx';
import type { State, Props } from '../../types.tsx';

export const moveItems =
	(ids: ItemId[], xValue: AxisValue, yValue: AxisValue) =>
	(storeApi: StoreActionApi<State>, props: Props) => {
		props.onMoveItems && props.onMoveItems(ids, xValue, yValue);
	};

export const updateItemState =
	(items: Item[], emptyState: MatrixEmptyState) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const newItemIds = items.map(({ id }) => id);

		const newAxisValues = {
			x: {},
			y: {},
			z: {},
		};

		items.forEach(({ id, x, y, z }) => {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			newAxisValues.x[id] = x;
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			newAxisValues.y[id] = y;
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			newAxisValues.z[id] = z;
		});

		setState({
			emptyState,
			items: isEqual(getState().items, newItemIds) ? getState().items : newItemIds,
			axisValues: {
				x: isEqual(getState().axisValues.x, newAxisValues.x)
					? getState().axisValues.x
					: newAxisValues.x,
				y: isEqual(getState().axisValues.y, newAxisValues.y)
					? getState().axisValues.y
					: newAxisValues.y,
				z: isEqual(getState().axisValues.z, newAxisValues.z)
					? getState().axisValues.z
					: newAxisValues.z,
			},
		});
	};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleSpork: {
		id: 'polaris-lib-onboarding.create-delivery-ticket.title-spork',
		defaultMessage: 'Connect to Jira',
		description: 'Spotlight title for "Delivery" tab button',
	},
	description: {
		id: 'polaris-lib-onboarding.create-delivery-ticket.description',
		defaultMessage:
			'Once your ideas are defined and prioritized, engineering teams can start implementing them.',
		description: 'Spotlight description for "Delivery" tab button',
	},
	learnMoreText: {
		id: 'polaris-lib-onboarding.create-delivery-ticket.learn-more-text',
		defaultMessage: 'Learn how to create a Jira issue from Jira Product Discovery.',
		description: 'Accessible text fot "Learn more" button',
	},
	learnMoreTextIssueTermRefresh: {
		id: 'polaris-lib-onboarding.create-delivery-ticket.learn-more-text-issue-term-refresh',
		defaultMessage: 'Learn how to create a Jira work item from Jira Product Discovery.',
		description: 'Accessible text fot "Learn more" button',
	},
});

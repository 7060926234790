import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCanArchiveIdeas } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import messages from './messages.tsx';

export const Restore = () => {
	const { formatMessage } = useIntl();
	const localIssueId = useSelectedIssue();
	const { unarchiveIssues } = useIssueActions();
	const canArchiveIdeas = useCanArchiveIdeas();
	const unarchive = useCallback(() => {
		if (localIssueId === undefined) {
			return;
		}
		unarchiveIssues([localIssueId]);
	}, [localIssueId, unarchiveIssues]);

	if (!canArchiveIdeas) return null;

	return (
		<RestoreButton spacing="none" appearance="link" onClick={unarchive}>
			{formatMessage(messages.button)}
		</RestoreButton>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RestoreButton = styled(Button)({
	display: 'block',
	marginTop: token('space.200', '16px'),
	fontWeight: '500',
});

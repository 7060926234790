/* eslint-disable no-nested-ternary */
import {
	BOARD_COLUMN_CONTENT_TOP_PADDING,
	BOARD_COLUMN_GAP,
	BOARD_COLUMN_MIN_HEIGHT,
	BOARD_COLUMN_WIDTH,
	GROUP_HEADER_HEIGHT,
} from '../../../constants.tsx';
import type { Column, Group } from '../../../types/common.tsx';

/**
 * Finds the closest value to the given target in the given array with a binary search.
 */
export const binarySearch = (
	array: Array<number>,
	target: number,
	low = 0,
	high = array.length - 1,
): number => {
	if (target < array[low]) {
		return array[0];
	}
	if (target > array[high]) {
		return array[high];
	}

	const mid = Math.floor((high + low) / 2);

	return high - low < 2
		? target - array[low] < array[high] - target
			? array[low]
			: array[high]
		: target < array[mid]
			? binarySearch(array, target, low, mid)
			: target > array[mid]
				? binarySearch(array, target, mid, high)
				: array[mid];
};

export const getGroupHighestColumn = (group: Group) => {
	let maxHeight = 0;

	for (let i = 0; i < group.columns.length; i += 1) {
		if (group.columns[i].height > maxHeight) {
			maxHeight = group.columns[i].height;
		}
	}

	return maxHeight;
};

export const getGroupHighestColumnContent = (group: Group) => {
	let maxContentHeight = 0;

	for (let i = 0; i < group.columns.length; i += 1) {
		if (group.columns[i].contentHeight > maxContentHeight) {
			maxContentHeight = group.columns[i].contentHeight;
		}
	}

	return maxContentHeight;
};

export const getColumnContentHeight = (column: Column, updateCardOffsets = true) => {
	let total = BOARD_COLUMN_CONTENT_TOP_PADDING;
	const minHeight = BOARD_COLUMN_MIN_HEIGHT;

	for (let i = 0; i < column.cards.length; i += 1) {
		if (updateCardOffsets) {
			Object.assign(column.cards[i], { offset: total });
		}
		total += column.cards[i].height;
	}

	if (total < minHeight) {
		return minHeight;
	}

	return total;
};

export const getVisibleGroups = (
	groups: Group[],
	offsetY: number,
	containerHeight: number,
	isExporting = false,
) => {
	const visibleGroups = [];

	for (const group of groups) {
		const groupStart = group.offset + GROUP_HEADER_HEIGHT;
		const groupEnd = group.offset + group.height;
		const isInRange =
			isExporting ||
			(groupStart >= offsetY && groupStart < offsetY + containerHeight) ||
			(groupEnd <= offsetY + containerHeight && groupEnd > offsetY) ||
			(groupStart < offsetY && groupEnd > offsetY + containerHeight);

		if (isInRange) {
			visibleGroups.push(group);
		}
	}

	return visibleGroups;
};

export const getVisibleColumns = (
	group: Group,
	offsetX: number,
	containerWidth: number,
	isExporting = false,
) => {
	if (isExporting) {
		return group.columns;
	}

	const firstColumnIndex = Math.floor(offsetX / (BOARD_COLUMN_WIDTH + BOARD_COLUMN_GAP));
	const lastColumnIndex = Math.floor(
		(offsetX + containerWidth) / (BOARD_COLUMN_WIDTH + BOARD_COLUMN_GAP),
	);

	return group.columns.slice(firstColumnIndex, lastColumnIndex + 1);
};

export const getVisibleCards = (
	column: Column,
	adjustedOffsetY: number,
	containerHeight: number,
	columnCardCumulativeHeightsMap: Map<string, Array<number>>,
	isExporting = false,
) => {
	if (isExporting || column.cards.length === 0) {
		return column.cards;
	}

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const cardCumulativeHeights = columnCardCumulativeHeightsMap.get(column.uid)!;
	const firstCardCumulativeHeight = binarySearch(cardCumulativeHeights, adjustedOffsetY);

	let firstCardIndex = cardCumulativeHeights.indexOf(firstCardCumulativeHeight);
	if (firstCardCumulativeHeight < adjustedOffsetY) {
		firstCardIndex += 1;
	}

	const lastCardCumulativeHeight = binarySearch(
		cardCumulativeHeights,
		adjustedOffsetY + containerHeight,
	);

	let lastCardIndex = cardCumulativeHeights.indexOf(lastCardCumulativeHeight);
	if (lastCardCumulativeHeight < adjustedOffsetY + containerHeight) {
		lastCardIndex += 1;
	}

	return column.cards.slice(firstCardIndex, lastCardIndex + 1);
};

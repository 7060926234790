/**
 * Delay the execution of a function by a given amount of time,
 * and return a cleanup function that can be used to cancel the delay.
 */
export function delay({ fn, time }: { fn: () => void; time: number }): () => void {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	let timeoutId: number | null = window.setTimeout(() => {
		timeoutId = null;
		fn();
	}, time);

	return function cleanup() {
		if (timeoutId) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.clearTimeout(timeoutId);
			timeoutId = null;
		}
	};
}

import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapPromiseWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { createIdeaRest } from '../../services/jira/create-idea-rest/index.tsx';
import { deleteIssues } from '../../services/jira/delete-issues/index.tsx';
import { getAllIdeasForProject } from '../../services/jira/get-ideas-rest-bulk/index.tsx';
import { getIssue } from '../../services/jira/get-issue/index.tsx';
import { getIssuesWithJql } from '../../services/jira/get-issues-jql/index.tsx';
import { getJpdIssues, getJpdIssue } from '../../services/jira/get-jpd-issues/index.tsx';
import {
	updateIssueDescription,
	updateIssueField,
	updateIssueFields,
} from '../../services/jira/update-idea/index.tsx';
import {
	updateIssueFieldsBulk,
	getUpdateIssueFieldsBulkProgress,
} from '../../services/jira/update-ideas-bulk/index.tsx';
import { getIdeas } from '../../services/views-service/ideas/index.tsx';
import type {
	FetchIssueRequest,
	FetchResponse,
	IssueCrudRemote,
	RemoteJiraIssue,
} from './types.tsx';

export const createNotImplementedIssueCrudRemote = (): IssueCrudRemote => ({
	createIssue: notImplementedError('createIssue'),
	deleteIssues: notImplementedError('deleteIssues'),
	fetch: notImplementedError('fetch'),
	fetchIssue: notImplementedError('fetchIssue'),
	fetchIssueLegacy: notImplementedError('fetchIssueLegacy'),
	getUpdateIssueFieldsBulkProgress: notImplementedError('getUpdateIssueFieldsBulkProgress'),
	updateIssueDescription: notImplementedError('updateIssueDescription'),
	updateIssueField: notImplementedError('updateIssueField'),
	updateIssueFields: notImplementedError('updateIssueFields'),
	updateIssueFieldsBulk: notImplementedError('updateIssueFieldsBulk'),
});

export const createSharingIssueCrudRemote = (viewId: string): IssueCrudRemote => {
	const cache: { promise: Promise<FetchResponse> | undefined } = {
		promise: undefined,
	};

	const fetchCached = (): Promise<FetchResponse> => {
		if (cache.promise === undefined) {
			cache.promise = getIdeas(viewId).then((issues) => ({
				issues,
				total: issues.length,
			}));
		}

		return cache.promise;
	};

	return {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createIssue: () => Promise.resolve({} as RemoteJiraIssue),
		deleteIssues: () => Promise.resolve(),
		fetchIssue: ({ issueIdOrKey }: FetchIssueRequest) =>
			fetchCached().then((response) => {
				const issueFromResponse = response.issues.find(
					(issue) => issueIdOrKey === issue.id || issueIdOrKey === issue.key,
				);
				if (!issueFromResponse) {
					throw new Error('Issue not found in seach issues response');
				}
				return issueFromResponse;
			}),
		fetchIssueLegacy(props) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return this.fetchIssue({ ...props, fields: [] }) as Promise<RemoteJiraIssue>;
		},
		fetch: () =>
			fetchCached().catch((error) => {
				// we do not render an error screen/popup when the user does not have access (404, 401) in shared view
				// We perform other specific actions this case (404 screen / redirect)
				if (error instanceof FetchError && (error.statusCode === 404 || error.statusCode === 401)) {
					return {
						total: 0,
						issues: [],
					};
				}

				throw error;
			}),
		getUpdateIssueFieldsBulkProgress: () => Promise.resolve(null),
		updateIssueDescription: () => Promise.resolve(),
		updateIssueField: () => Promise.resolve(),
		updateIssueFields: () => Promise.resolve(),
		updateIssueFieldsBulk: () => Promise.resolve(null),
	};
};

export const createIssueCrudRemote = (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
	projectId: ProjectId,
): IssueCrudRemote => ({
	createIssue: ({
		rankField,
		issueTypeId,
		fieldsMap,
		isRankingEnabled,
		onCreated,
		anchorAfterIssueKey,
	}) =>
		createIdeaRest(
			projectId,
			rankField,
			issueTypeId,
			fieldsMap,
			isRankingEnabled,
			onCreated,
			anchorAfterIssueKey,
		),
	deleteIssues: ({ issueKeys, onFinish }) => deleteIssues({ jiraKeys: issueKeys, onFinish }),
	fetchIssue({ issueIdOrKey, fields = [] }: FetchIssueRequest) {
		return fg('jpd-use-polaris-issue-get-endpoint')
			? wrapPromiseWithExperience(
					getJpdIssue(projectId, issueIdOrKey, fields),
					experience.issues.loadIssueJpdApi,
					'fetchIssue',
				)
			: this.fetchIssueLegacy({ issueIdOrKey, fields });
	},
	fetchIssueLegacy: ({ issueIdOrKey, fields = [] }: FetchIssueRequest) =>
		wrapPromiseWithExperience(
			getIssue(issueIdOrKey, fields),
			experience.issues.loadIssueRest,
			'fetchIssueLegacy',
		),
	fetch: ({ fields, issueIdsOrKeys, onBatchLoaded, archivedFilter, startAt, maxResults }) => {
		if (issueIdsOrKeys) {
			return wrapPromiseWithExperience(
				getJpdIssues({ projectId, issueIdsOrKeys, fields }),
				experience.issues.loadIssuesJpdApi,
				'fetch',
			);
		}

		if (fg('jpd-use-polaris-issues-search-endpoint')) {
			return wrapPromiseWithExperience(
				getJpdIssues({ projectId, fields, archivedFilter, startAt, maxResults }),
				experience.issues.loadIssuesJpdApi,
				'fetch',
			);
		}

		return wrapPromiseWithExperience(
			getAllIdeasForProject({
				projectId,
				fields,
				onBatchLoaded,
			}),
			experience.issues.loadIssuesRestBatched,
			'fetchIssuesLegacy',
		);
	},
	getUpdateIssueFieldsBulkProgress: ({ taskId }) =>
		getUpdateIssueFieldsBulkProgress(apolloClient, cloudId, taskId),
	updateIssueDescription: ({ issueKey, description }) =>
		updateIssueDescription(issueKey, description),
	updateIssueField: ({ issueKey, fieldKey, value }) => updateIssueField(issueKey, fieldKey, value),
	updateIssueFields: ({ issueKey, update }) => updateIssueFields(issueKey, update),
	updateIssueFieldsBulk: ({ input }) => updateIssueFieldsBulk(apolloClient, cloudId, input),
});

export const createJqlIssueCrudRemote = (jql: string): IssueCrudRemote => {
	const cache: { promise: Promise<FetchResponse> | undefined } = {
		promise: undefined,
	};

	const fetchCached = (): Promise<FetchResponse> => {
		if (cache.promise === undefined) {
			cache.promise = getIssuesWithJql(jql).then(({ issues }) => ({
				issues,
				total: issues.length,
			}));
		}

		return cache.promise;
	};

	return {
		createIssue: notImplementedError('createIssue'),
		deleteIssues: notImplementedError('deleteIssues'),
		fetch: () =>
			fetchCached().catch((error) => {
				// we do not render an error screen/popup when the user does not have access (404, 401) in shared view
				// We perform other specific actions this case (404 screen / redirect)
				if (error instanceof FetchError && (error.statusCode === 404 || error.statusCode === 401)) {
					return {
						total: 0,
						issues: [],
					};
				}

				throw error;
			}),
		fetchIssue({ issueIdOrKey, fields }: FetchIssueRequest) {
			return this.fetchIssueLegacy({ issueIdOrKey, fields });
		},
		fetchIssueLegacy: ({ issueIdOrKey, fields = [] }: FetchIssueRequest) =>
			wrapPromiseWithExperience(
				getIssue(issueIdOrKey, fields),
				experience.issues.loadIssueRest,
				'fetchIssueLegacy',
			),
		getUpdateIssueFieldsBulkProgress: notImplementedError('getUpdateIssueFieldsBulkProgress'),
		updateIssueDescription: ({ issueKey, description }) =>
			updateIssueDescription(issueKey, description),
		updateIssueField: ({ issueKey, fieldKey, value }) =>
			updateIssueField(issueKey, fieldKey, value),
		updateIssueFields: ({ issueKey, update }) => updateIssueFields(issueKey, update),
		updateIssueFieldsBulk: notImplementedError('updateIssueFieldsBulk'),
	};
};

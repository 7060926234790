import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { DRAGGABLE_ITEM_TYPE } from '../../common/constants/index.tsx';
import { getItemIds } from '../../common/utils/items/index.tsx';
import { useMatrixActions } from '../../controllers/index.tsx';
import {
	useXAxisValueMapper,
	useYAxisValueMapper,
	useIsXAxisDiscrete,
	useIsXAxisLocked,
	useIsYAxisDiscrete,
	useIsYAxisLocked,
	useXAxis,
	useXAxisValueCount,
	useYAxis,
	useYAxisValueCount,
} from '../../controllers/selectors/axis-hooks.tsx';
import { DnDTooltip } from './tooltip/index.tsx';

export type Position = {
	x: number;
	y: number;
	width?: number;
	height?: number;
};

const calculatePercentage = (
	// @ts-expect-error - TS7006 - Parameter 'initialPc' implicitly has an 'any' type.
	initialPc,
	fullPx: number,
	diffPx: undefined | number,
	offsetPc: number,
	isLocked: undefined | boolean,
) => {
	if (diffPx === undefined) {
		return undefined;
	}

	if (isLocked === true) {
		return initialPc;
	}

	const initialPx = (initialPc / 100) * fullPx;
	const currentTopPx = initialPx + diffPx;
	const currentTopPc = (currentTopPx / fullPx) * 100;

	return Math.min(100 - offsetPc, Math.max(offsetPc, currentTopPc));
};

type SnapFunction = (initialPc: number, width: number, diffPx: number | undefined) => number;

const createSnapFunction = (
	isAxisDiscrete: boolean,
	valueCount?: number,
	isLocked?: boolean,
): SnapFunction | undefined => {
	if (valueCount === undefined) {
		return undefined;
	}
	const offsetPercentage = isAxisDiscrete ? 50 / valueCount : 0;
	return (initialPc, width, diffPx) =>
		calculatePercentage(initialPc, width, diffPx, offsetPercentage, isLocked);
};

const useXAxisSnapFunction = () => {
	const isLocked = useIsXAxisLocked();
	const discreteAxisCountX = useXAxisValueCount();
	const isXAxisDiscrete = useIsXAxisDiscrete();
	return createSnapFunction(isXAxisDiscrete, discreteAxisCountX, isLocked);
};

const useYAxisSnapFunction = () => {
	const isLocked = useIsYAxisLocked();
	const discreteAxisCountX = useYAxisValueCount();
	const isYAxisDiscrete = useIsYAxisDiscrete();
	return createSnapFunction(isYAxisDiscrete, discreteAxisCountX, isLocked);
};

const useDragValue = ({
	draggablePositionDelta,
	containerSize,
	onSnapXAxis,
	onSnapYAxis,
}: {
	draggablePositionDelta: Position | null;
	containerSize: Position | null;
	onSnapXAxis: SnapFunction;
	onSnapYAxis: SnapFunction;
}) => {
	if (!draggablePositionDelta || !containerSize) {
		return undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const left = onSnapXAxis(0, containerSize.width!, draggablePositionDelta.x);
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const top = onSnapYAxis(0, containerSize.height!, draggablePositionDelta.y);

	return {
		top,
		left,
	};
};

type DragLayerProps = {
	onSnapXAxis: SnapFunction;
	onSnapYAxis: SnapFunction;
};

export const DragLayer = ({ onSnapXAxis, onSnapYAxis }: DragLayerProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [, { moveItems }] = useMatrixActions();

	const xAxisValueMapper = useXAxisValueMapper();
	const yAxisValueMapper = useYAxisValueMapper();

	// Ids of items that are being dragged
	const [clusterIds, setClusterIds] = useState<string[]>([]);
	// Dimensions of a dropable container
	const [containerSize, setContainerSize] = useState<Position | null>(null);
	// Difference between initial and current position of a dragged item
	const [draggablePositionDelta, setDraggablePositionDelta] = useState<Position | null>(null);

	const dragValue = useDragValue({
		draggablePositionDelta,
		containerSize,
		onSnapXAxis,
		onSnapYAxis,
	});

	const itemIds = getItemIds({ ids: clusterIds, type: DRAGGABLE_ITEM_TYPE });

	useEffect(() => {
		if (!ref.current) return;
		// Set initial size and position of a dropable container
		const { x, y, width, height } = ref.current.getBoundingClientRect();
		setContainerSize({ x, y, width, height });
	}, [ref.current?.clientWidth]);

	useEffect(() => {
		if (!ref.current) return undefined;

		const cleanupDragAndDrop = combine(
			dropTargetForElements({
				element: ref.current,
				canDrop({ source }) {
					return source.data.type === DRAGGABLE_ITEM_TYPE;
				},
				onDrag: ({ location }) => {
					setDraggablePositionDelta({
						x: location.current.input.clientX - (containerSize?.x ?? 0),
						y: location.current.input.clientY - (containerSize?.y ?? 0),
					});
				},
				onDrop: () => {
					if (dragValue && xAxisValueMapper && yAxisValueMapper) {
						moveItems(
							itemIds,
							xAxisValueMapper(dragValue.left),
							yAxisValueMapper(100 - dragValue.top),
						);
					}

					setClusterIds([]);
					setDraggablePositionDelta(null);
				},
				onDragEnter: ({ source }) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					setClusterIds(source.data.itemIds as string[]);
				},
				onDragLeave: () => {
					setClusterIds([]);
					setDraggablePositionDelta(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop();
		};
	}, [
		containerSize?.x,
		containerSize?.y,
		dragValue,
		itemIds,
		moveItems,
		xAxisValueMapper,
		yAxisValueMapper,
	]);

	return (
		<Container ref={ref} isDragging={!!clusterIds.length}>
			{dragValue && (
				<DnDTooltip
					itemIds={itemIds}
					leftPercentage={dragValue.left}
					topPercentage={dragValue.top}
				/>
			)}
		</Container>
	);
};

export const MatrixDragLayer = () => {
	const xAxis = useXAxis();
	const yAxis = useYAxis();
	const snapXAxis = useXAxisSnapFunction();
	const snapYAxis = useYAxisSnapFunction();

	if (!xAxis || !yAxis || !snapXAxis || !snapYAxis) {
		return null;
	}

	return <DragLayer onSnapXAxis={snapXAxis} onSnapYAxis={snapYAxis} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isDragging: boolean }>({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: ({ isDragging }) => (isDragging ? 100 : 0),
});

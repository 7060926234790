import { createIssueHook, createHigherLevelIssueHook } from '../../utils.tsx';
import {
	createGetLinkedIssueDataByIssueId,
	getExternalIssueDataMapByJiraId,
	getExternalIssueProjects,
	getExternalIssues,
} from './index.tsx';

export const useExternalIssueProjects = createIssueHook(getExternalIssueProjects);

export const useLinkedIssueDataByLinkedIssueId = createHigherLevelIssueHook(
	createGetLinkedIssueDataByIssueId,
);

export const useExternalIssueDataByJiraId = createIssueHook(getExternalIssueDataMapByJiraId);
export const useExternalIssues = createIssueHook(getExternalIssues);

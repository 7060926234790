import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import type { OptionType } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useOpenRightSidebarOnField } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import { FieldSelect } from '@atlassian/jira-polaris-common/src/ui/common/field-select/index.tsx';
import { FieldSearchableDropdown } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/index.tsx';
import type { SelectProps } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/types.tsx';
import { useCanCreateFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { messages } from './messages.tsx';

type FieldSelectionProps = {
	testId?: string;
	fieldKey?: FieldKey;
	disabledFieldKey?: FieldKey;
	setDateField(fieldKey: FieldKey | undefined): void;
	message: string;
	noOptionsMessage: SelectProps['noOptionsMessage'];
};

export const FieldSelection = ({
	testId,
	fieldKey,
	setDateField,
	message,
	disabledFieldKey,
	noOptionsMessage,
}: FieldSelectionProps) => {
	const [currentFieldKey, setFieldKey] = useState(fieldKey);
	const { formatMessage } = useIntl();

	const selectedField = useField(currentFieldKey);
	const fields = useFieldsForViewControls();
	const canCreateField = useCanCreateFields();
	const openFieldConfig = useOpenRightSidebarOnField();
	const [sidebarShowing] = useRightSidebarShowing();

	const onChangeField = useCallback(
		(newFieldKey: FieldKey | undefined) => {
			if (newFieldKey === undefined || newFieldKey === currentFieldKey) {
				return;
			}
			setFieldKey(newFieldKey);
			setDateField(newFieldKey);
			fireCompoundAnalyticsEvent.TimelineFieldKeyChanged();
		},
		[currentFieldKey, setDateField],
	);

	const fieldList = useMemo(
		() =>
			Object.keys(fields)
				.map((key) => fields[key])
				.filter((fieldItem) => fieldItem.type === FIELD_TYPES.INTERVAL)
				.sort((a, b) => a.label.localeCompare(b.label)),
		[fields],
	);

	const fieldOptions = fieldList.map((option) => ({
		...option,
		disabled: option.key === disabledFieldKey,
		selected: option.key === currentFieldKey,
		hasWeightType: option.configuration?.optionWeightType !== undefined,
	}));

	const onConfigureClick = useCallback(() => {
		if (fieldKey) {
			openFieldConfig(fieldKey, sidebarShowing);
		}
	}, [openFieldConfig, fieldKey, sidebarShowing]);

	const removeEmpty = (obj: OptionType) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newOption: any = {};
		Object.keys(obj).forEach((key) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			if (obj[key] === Object(obj[key])) newOption[key as keyof any] = removeEmpty(obj[key]);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			else if (obj[key] !== undefined) newOption[key as keyof any] = obj[key];
		});
		return newOption;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const validateOptions = (options: any) =>
		options.map((option: OptionType) => removeEmpty(option));

	return (
		<FieldSelectContainer data-testid={testId}>
			<FieldName>{message}</FieldName>
			<Flex gap="space.100" justifyContent="space-between">
				<Box xcss={fieldSelectWrapperStyles}>
					<FieldSelect
						SearchableComponent={FieldSearchableDropdown}
						selectedField={selectedField}
						onChange={onChangeField}
						fieldOptions={validateOptions(fieldOptions)}
						canCreateField={canCreateField}
						onFieldCreate={onChangeField}
						createGlobalFieldLabel={formatMessage(messages.createGlobalFieldButton)}
						noOptionsMessage={noOptionsMessage}
					/>
				</Box>
				{selectedField && canCreateField && (
					<Box xcss={settingsIconWrapperStyles}>
						<ButtonWithTooltip
							onClick={onConfigureClick}
							iconBefore={<SettingsIcon label={formatMessage(messages.configureFieldTooltip)} />}
							tooltipProps={{ content: formatMessage(messages.configureFieldTooltip) }}
						/>
					</Box>
				)}
			</Flex>
		</FieldSelectContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldSelectContainer = styled.div({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldName = styled.div({
	fontWeight: token('font.weight.semibold'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
});

const settingsIconWrapperStyles = xcss({
	width: '32px',
	minWidth: '32px',
	height: '32px',
});

const fieldSelectWrapperStyles = xcss({
	width: '100%',
});

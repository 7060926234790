import React from 'react';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { ProjectField } from '@atlassian/jira-polaris-common/src/ui/fields/project/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type ProjectFieldProps = {
	localIssueId: LocalIssueId;
};

export const ProjectFieldCell = ({ localIssueId }: ProjectFieldProps) => {
	const project = useProjectForIssue(localIssueId);
	const projectMeta = useProjectMetadata({ projectId: project?.id });

	return <ProjectField project={projectMeta} />;
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionHeader: {
		id: 'polaris-ideas.idea-view.attachments.section-header',
		defaultMessage: 'Attachments {count, select, 0 {} other {({count})}}',
		description: 'Attachments section header with attachment count.',
	},
	deleteConfirmDialogHeader: {
		id: 'polaris-ideas.idea-view.attachments.delete-confirm-dialog-header',
		defaultMessage: 'Delete this attachment?',
		description: 'Confirmation dialog header for deleting an attachment',
	},
	deleteConfirmDialogContent: {
		id: 'polaris-ideas.idea-view.attachments.delete-confirm-dialog-content',
		defaultMessage: "Once you delete, it's gone for good.",
		description: 'Confirmation dialog content for deleting an attachment',
	},
	deleteConfirmDialogConfirm: {
		id: 'polaris-ideas.idea-view.attachments.delete-confirm-dialog-confirm',
		defaultMessage: 'OK',
		description: 'Confirmation dialog approve button',
	},
	deleteConfirmDialogCancel: {
		id: 'polaris-ideas.idea-view.attachments.delete-confirm-dialog-cancel',
		defaultMessage: 'Cancel',
		description: 'Confirmation dialog cancel button',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	infoMessage: {
		id: 'polaris-common.field-info-marker.delivery-data-restricted.info-message',
		defaultMessage:
			'We could only calculate the delivery data partially, because you’ve hit the issues limit.',
		description:
			'Message shown to the customer when we did not load all linked delivery tickets. In this case some data can only be computed partially',
	},
	infoMessageIssueTermRefresh: {
		id: 'polaris-common.field-info-marker.delivery-data-restricted.info-message-issue-term-refresh',
		defaultMessage:
			'We could only calculate the delivery data partially because you’ve hit the work item limit.',
		description:
			'Message shown to the customer when we did not load all linked delivery tickets. In this case some data can only be computed partially',
	},
});

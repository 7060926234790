import React, { type ReactNode, useState } from 'react';
import { styled } from '@compiled/react';
import isFunction from 'lodash/isFunction';
import { Manager, Popper, Reference } from '@atlaskit/popper';
import Portal from '@atlaskit/portal';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

const POPPER_MODIFIERS = [
	{
		name: 'flip',
		options: {
			altAxis: false,
			mainAxis: false,
		},
	},
	{
		name: 'eventListeners',
		options: {
			resize: false,
			scroll: true,
		},
	},
];

type Props = {
	children: ReactNode;
	zIndex?: number;
};

/**
 * Wrapper to display element content in a popper outside of the table DOM
 */
export const PopperElement = ({ children, zIndex = layers.layer }: Props) => {
	const [elementRef, setElementRef] = useState<HTMLElement | null>(null);

	return (
		<Manager>
			<Reference>
				{({ ref }) => (
					<ElementReference
						ref={(innerRef) => {
							if (isFunction(ref)) {
								ref(innerRef);
							}
							setElementRef(innerRef);
						}}
					/>
				)}
			</Reference>
			<Popper
				placement="top-start"
				offset={[0, 0]}
				modifiers={POPPER_MODIFIERS}
				strategy="absolute"
			>
				{({ ref, style }) => {
					const styleProps = { style };
					const width = elementRef?.getBoundingClientRect().width || 0;
					const height = elementRef?.getBoundingClientRect().height || 0;

					return (
						<Portal zIndex={zIndex}>
							<PositionedElementOverlay ref={ref} {...styleProps} $width={width} $height={height}>
								{children}
							</PositionedElementOverlay>
						</Portal>
					);
				}}
			</Popper>
		</Manager>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PositionedElementOverlay = styled.div<{
	$width: string | number;
	$height: string | number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ $width }) => `${$width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ $height }) => `${$height}px`,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ $height }) => `-${$height}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElementReference = styled.div({
	height: '100%',
	width: '100%',
});

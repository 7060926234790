import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { FetchResponse } from './types.tsx';

const getRankingListUrl = (viewId: string) =>
	`/gateway/api/jpd/public/views/${viewId}/ranking/lists`;

export const getRankingList = (viewId: string): Promise<FetchResponse> => {
	if (viewId === undefined) {
		return Promise.reject(new Error('viewId must be supplied, cannot load fields'));
	}
	return fetchJson(getRankingListUrl(viewId));
};

import React from 'react';
import { styled } from '@compiled/react';
import Image from '@atlaskit/image';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCanAddAndEditOwnViewComments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import CommentsImgDark from './assets/comments-dark.svg';
import CommentsImg from './assets/comments.svg';
import messages from './messages.tsx';

export const ViewEmptyComments = () => {
	const { formatMessage } = useIntl();
	const canAddAndEditOwnViewComments = useCanAddAndEditOwnViewComments();

	return (
		<Box xcss={wrapperStyles}>
			<Container>
				<ImageContainer>
					<Image src={CommentsImg} srcDark={CommentsImgDark} alt="Comments" />
				</ImageContainer>
				<Content>
					{canAddAndEditOwnViewComments ? (
						<div>{formatMessage(messages.description)}</div>
					) : (
						<div>{formatMessage(messages.descriptionReadOnly)}</div>
					)}
				</Content>
			</Container>
		</Box>
	);
};

const wrapperStyles = xcss({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	marginTop: token('space.300', '24px'),
	flexWrap: 'wrap',
	gap: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	flex: 1,
	width: '460px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	width: '110px',
	height: '88px',
});

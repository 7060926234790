import React from 'react';
import { useIssueAnalitycsAttributes } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { DragItemWrapperProps } from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';

export const ExternalDragItemWrapper = (props: DragItemWrapperProps) => {
	const analyticsData = useIssueAnalitycsAttributes(props.id);
	return (
		<ContextualAnalyticsData attributes={analyticsData}>{props.children}</ContextualAnalyticsData>
	);
};

import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { filterOutTeamFieldIfDisabled } from '@atlassian/jira-polaris-domain-field/src/global-field/utils.tsx';
import type { State } from '../types.tsx';

const getGlobalFieldsMap = (state: State) =>
	pickBy(state.globalFields, filterOutTeamFieldIfDisabled);
export const getGlobalFields = createSelector(getGlobalFieldsMap, (fieldsMap) =>
	Object.values(fieldsMap),
);

export const getGlobalField = (fieldKey: string) =>
	createSelector(getGlobalFieldsMap, (fieldsMap) => fieldsMap[fieldKey]);
export const getGlobalFieldData = (fieldKey: string) =>
	createSelector(getGlobalField(fieldKey), (field) =>
		field
			? {
					field,
					valueDecorations: field.valueDecorations,
				}
			: undefined,
	);

export const getAreGlobalFieldsLoading = (state: State) => state.meta.loading;
export const getIsGlobalFieldsLoadingFailed = (state: State) => state.meta.error !== undefined;

export const getGlobalFieldFormula = (fieldKey: FieldKey) =>
	createSelector(getGlobalField(fieldKey), (field) => {
		if (field?.type !== FIELD_TYPES.FORMULA) {
			return undefined;
		}
		return field.formula;
	});

const getCopyValuesTasksMap = (state: State) => state.copyValuesTasks;
export const getCopyValuesTasks = createSelector(getCopyValuesTasksMap, (tasksMap) =>
	Object.values(tasksMap),
);

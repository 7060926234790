import React from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectName } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { useCurrentPolarisRouteSection } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useProjectKeyUnsafe,
	useProjectId,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import messages from './messages.tsx';

export const BreadcrumbsProjectSettings = () => {
	const { formatMessage } = useIntl();
	const section = useCurrentPolarisRouteSection();
	const projectId = useProjectId();
	const projectMeta = useProjectMetadata({ projectId });
	const [projectNameOld] = useProjectName();
	const projectName = fg('polaris-refactor-skipprojectinfo') ? projectMeta?.name : projectNameOld;
	const projectKey = useProjectKeyUnsafe();

	return (
		<Breadcrumbs>
			<BreadcrumbsItem
				testId="polaris-ideas.ui.settings.breadcrumbs.breadcrumbs-item-project"
				href="/jira/projects"
				text={formatMessage(messages.projectsTitle)}
			/>
			<BreadcrumbsItem
				testId="polaris-ideas.ui.settings.breadcrumbs.breadcrumbs-item-project-name"
				href={`/jira/polaris/projects/${projectKey}/ideas/view`}
				text={projectName || ''}
			/>
			<BreadcrumbsItem
				testId="polaris-ideas.ui.settings.breadcrumbs.breadcrumbs-item-project-settings"
				href={`/jira/polaris/projects/${projectKey}/ideas/${section}`}
				text={formatMessage(messages.projectSettings)}
			/>
		</Breadcrumbs>
	);
};

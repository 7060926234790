import React, { useState, useCallback, useEffect, type ComponentType, useMemo } from 'react';
import { styled } from '@compiled/react';
import { type ButtonItemProps, Section, ButtonItem } from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { N100, N1000 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type { FieldDecoratorProps } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/types.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks.tsx';
import { useOuterSpacing } from '../../../../../controllers/selectors/config-hooks.tsx';
import { messages } from './messages.tsx';

type BaseDecoratorItemProps = {
	decoration: ValueDecoration;
	FieldDecorator: ComponentType<FieldDecoratorProps>;
	readonly?: boolean;
};

type DecoratorItemProps = {
	isOpen: boolean;
} & BaseDecoratorItemProps & {
		onOpenChanged: (open: boolean) => void;
	};

export const DecoratorRatingItem = ({
	onOpenChanged,
	isOpen,
	decoration,
	FieldDecorator,
	readonly = false,
}: DecoratorItemProps) => {
	const { onValueDecorationUpdated, onValueDecorationDeleted } = useCallbacks();
	const outerSpacing = useOuterSpacing();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const rules = useMemo(
		() =>
			isDecorationWithLogic(decoration)
				? [] // TODO: Handle Decoration Logic
				: decoration.rules,
		[decoration],
	);

	const [currentColor, setCurrentColor] = useState(decoration.backgroundColor);
	const [currentHighlighted, setCurrentHighlighted] = useState(!!decoration.highlightContainer);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration.emoji);
	const [currentRules, setCurrentRules] = useState(rules);
	const currentRatingValue = Number.parseInt(rules[0].value, 10);

	useEffect(() => {
		setCurrentRules(rules);
	}, [rules]);

	const onValueDecorationChanged = useCallback(
		({
			backgroundColor,
			emoji,
			highlightContainer,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: 'update',
				backgroundColor,
				emoji,
				highlightContainer,
			});

			onValueDecorationUpdated &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onValueDecorationUpdated({
					localDecorationId: decoration.localDecorationId,
					backgroundColor,
					emoji,
					highlightContainer,
					rules,
				} as ValueDecoration);
		},
		[createAnalyticsEvent, onValueDecorationUpdated, decoration.localDecorationId, rules],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentEmojiId, currentHighlighted, onValueDecorationChanged],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentColor, currentHighlighted, onValueDecorationChanged],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged],
	);

	const onClearFormatting = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'clearFormatting clicked', 'config-fields');

		setCurrentColor(undefined);
		setCurrentEmojiId(undefined);
		setCurrentHighlighted(false);

		onValueDecorationChanged({
			backgroundColor: undefined,
			emoji: undefined,
			highlightContainer: false,
		});
	}, [createAnalyticsEvent, onValueDecorationChanged]);

	const isClearFormattingDisabled =
		!currentHighlighted && currentColor === undefined && !currentEmojiId;

	const onUpdateRules = useCallback(() => {
		onValueDecorationUpdated &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onValueDecorationUpdated({
				backgroundColor: decoration.backgroundColor,
				highlightContainer: !!decoration.highlightContainer,
				emoji: decoration.emoji,
				localDecorationId: decoration.localDecorationId,
				rules: currentRules,
			} as ValueDecoration);
	}, [
		currentRules,
		decoration.backgroundColor,
		decoration.emoji,
		decoration.highlightContainer,
		decoration.localDecorationId,
		onValueDecorationUpdated,
	]);

	const onToggleEdit = (isEditActive: boolean) => {
		onOpenChanged(isEditActive);
		if (isEditActive === false) {
			onUpdateRules();
		}
	};

	// Styles for section items
	const cssFn: ButtonItemProps['cssFn'] = () => ({
		padding: `${token('space.075', '6px')} ${token('space.200', '16px')}`,
		backgroundColor: 'transparent',
		color: token('color.text', N1000),
		'&:hover': {
			color: token('color.text', N1000),
			backgroundColor: token('color.background.neutral.hovered', N100),
		},
	});

	const content = (
		<>
			<FireUiAnalytics
				actionSubject="fieldDecorationMenu"
				action="viewed"
				actionSubjectId="viewControls"
			/>
			<FieldDecorator
				isNameEditable={false}
				isHighlightingEditable={false}
				isEmojiEditable={false}
				isHighlighted={currentHighlighted}
				color={currentColor}
				emojiId={currentEmojiId}
				value=""
				onColorChanged={(hexCode) => {
					setCurrentColor(hexCode);
					onColorChanged(hexCode);
				}}
				onHighlightingChanged={(highlighted) => {
					setCurrentHighlighted(highlighted);
					onHighlightContainerChanged(highlighted);
				}}
				onEmojiSelected={(selectedEmoji) => {
					setCurrentEmojiId(selectedEmoji);
					onEmojiChanged(selectedEmoji);
				}}
				outerSpacing={outerSpacing}
			/>
		</>
	);

	return (
		<>
			<MenuOption
				menuComponent={
					<Section>
						<ButtonItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={() => {
								onToggleEdit(!isOpen);
							}}
						>
							{formatMessage(messages.editRule)}
						</ButtonItem>
						<ButtonItem
							isDisabled={isClearFormattingDisabled}
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={onClearFormatting}
						>
							{formatMessage(messages.clearFormatting)}
						</ButtonItem>
						<ButtonItem
							// @ts-expect-error - TS2322 - Type '{ children: string; hidden: true; cssFn: () => CSSFn; onClick: () => Promise<void>; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
							hidden
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={async () => {
								const { localDecorationId } = decoration;
								onValueDecorationDeleted && onValueDecorationDeleted({ localDecorationId });
							}}
						>
							{formatMessage(messages.deleteRule)}
						</ButtonItem>
					</Section>
				}
				isActive={isOpen}
				isDragEnabled={false}
				setOpenOption={() => onToggleEdit(false)}
				hideActionMenu={readonly}
				outerSpacing={outerSpacing}
			>
				<RatingContainer>
					<Rating mainColor={currentColor} value={currentRatingValue} />
				</RatingContainer>
			</MenuOption>
			{isOpen && content}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RatingContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
});

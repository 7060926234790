import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { PolarisAppPanel } from './app-panel/index.tsx';
import { usePresets } from './apps/index.tsx';
import type { AppPreset } from './apps/types.tsx';
import { PolarisAppsPageHeader } from './header/index.tsx';

type PresetProps = {
	isActionEnabled: boolean;
	preset: AppPreset;
};

const Preset = ({ preset, isActionEnabled }: PresetProps) => (
	<PolarisAppPanel
		isRecommended={preset.isRecommended}
		title={preset.title}
		description={preset.description}
		icon={preset.icon}
		Action={preset.Action}
		isActionEnabled={isActionEnabled}
	/>
);

Preset.defaultProps = {
	isActionEnabled: true,
};

type PolarisAppsListProps = {
	isActionEnabled: boolean;
	hiddenApps: string[];
};

export const PolarisAppsList = ({ hiddenApps, isActionEnabled }: PolarisAppsListProps) => {
	const presets = usePresets();

	const recommendedPresets = useMemo(
		() => presets.filter(({ isRecommended, key }) => isRecommended && !hiddenApps.includes(key)),
		[presets, hiddenApps],
	);
	const otherPresets = useMemo(
		() => presets.filter(({ isRecommended, key }) => !isRecommended && !hiddenApps.includes(key)),
		[presets, hiddenApps],
	);
	return (
		<MainContentArea>
			{recommendedPresets.map((preset: AppPreset) => (
				<Preset key={preset.key} preset={preset} isActionEnabled={isActionEnabled} />
			))}
			<AppColumns>
				{otherPresets.map((preset: AppPreset) => (
					<AppInColumn key={preset.key}>
						<Preset preset={preset} isActionEnabled={isActionEnabled} />
					</AppInColumn>
				))}
			</AppColumns>
		</MainContentArea>
	);
};

PolarisAppsList.defaultProps = {
	hiddenApps: [],
	isActionEnabled: true,
};

export const PolarisAppsOverview = () => (
	<AppPageWrapper>
		<PolarisAppsPageHeader />
		<PolarisAppsList />
	</AppPageWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppPageWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentArea = styled.div({
	marginTop: token('space.200', '16px'),
	maxWidth: '800px',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppColumns = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppInColumn = styled.div({
	maxWidth: '400px',
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	reviewMapping: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.review-mapping',
		defaultMessage: 'Review mapping',
		description: 'Label of the Review mapping button',
	},
	mapOptions: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.map-options',
		defaultMessage: 'Map options',
		description: 'Label of the Map options button',
	},
	optionsAutomappedHeader: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-automapped-header',
		defaultMessage:
			'{totalOptionsCount}/{totalOptionsCount} {totalOptionsCount, plural, =1 {option has} other {options have}} been automatically mapped.',
		description: 'Header of Options AutoMapped section',
	},
	optionsMappedHeader: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapped-header',
		defaultMessage:
			'{automappedOptionsCount}/{totalOptionsCount} {totalOptionsCount, plural, =1 {option has} other {options have}} been automatically mapped.',
		description: 'Header of Options Mapped section',
	},
	optionsManuallyMappedHeader: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-manually-mapped-header',
		defaultMessage:
			'{manuallyMappedOptionsCount}/{totalOptionsCount} {totalOptionsCount, plural, =1 {option has} other {options have}} been manually mapped.',
		description: 'Header of Options Mapped section',
	},
	optionsNotMappedHeader: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-not-mapped-header',
		defaultMessage:
			'{unmappedOptionsCount}/{totalOptionsCount} {totalOptionsCount, plural, =1 {option} other {options}} require mapping.',
		description: 'Header of Options Not Mapped section',
	},
	optionsNotMappedDescription: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-not-mapped-description',
		defaultMessage:
			"The project fields options don't match all global fields options. Please map options to proceed.",
		description: 'Description of Options Not Mapped section',
	},
});

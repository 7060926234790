import React from 'react';
import { ExternalReferencePropertyField as InnerField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference-property/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type ExternalReferenceFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
};

export const ExternalReferencePropertyField = ({
	localIssueId,
	fieldKey,
}: ExternalReferenceFieldProps) => <InnerField fieldKey={fieldKey} localIssueId={localIssueId} />;

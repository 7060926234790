import type { RowId } from '../../common/types/index.tsx';
import {
	createIsDraggingRow,
	getBaseTableApi,
	getClientWidthFunction,
	getColumnDraggingInfo,
	getDraggingRowId,
	getFixedColumnIndices,
	getInvisibleColumnPlaceholderWidthLeft,
	getInvisibleColumnPlaceholderWidthRight,
	getIsExporting,
	getIsFramedDisplayMode,
	getIsScrolledHorizontally,
	getIsScrolledVertically,
	getHighlightedRow,
	getScrollPositionLeft,
	getScrollPositionTop,
	getVisibleColumnIds,
	getVisibleColumnIndices,
	getWidth,
} from './ui.tsx';
import { createSelectorHook, createHigherLevelHook } from './utils.tsx';

export const useDraggingRowId = createSelectorHook(getDraggingRowId);
export const useIsDraggingRow = createHigherLevelHook<boolean, [RowId]>(createIsDraggingRow);

export const useScrollPositionLeft = createSelectorHook(getScrollPositionLeft);
export const useScrollPositionTop = createSelectorHook(getScrollPositionTop);
export const useIsScrolledHorizontally = createSelectorHook(getIsScrolledHorizontally);
export const useIsScrolledVertically = createSelectorHook(getIsScrolledVertically);
export const useWidth = createSelectorHook(getWidth);
export const useBaseTableApi = createSelectorHook(getBaseTableApi);
export const useClientWidthFunction = createSelectorHook(getClientWidthFunction);

export const useVisibleColumnIndices = createSelectorHook(getVisibleColumnIndices);
export const useFixedColumnIndices = createSelectorHook(getFixedColumnIndices);
export const useInvisibleColumnPlaceholderWidthLeft = createSelectorHook(
	getInvisibleColumnPlaceholderWidthLeft,
);
export const useInvisibleColumnPlaceholderWidthRight = createSelectorHook(
	getInvisibleColumnPlaceholderWidthRight,
);
export const useVisibleColumnIds = createSelectorHook(getVisibleColumnIds);

export const useIsExporting = createSelectorHook(getIsExporting);
export const useIsFramedDisplayMode = createSelectorHook(getIsFramedDisplayMode);

export const useColumnDraggingInfo = createSelectorHook(getColumnDraggingInfo);

export const useHighlightedRow = createSelectorHook(getHighlightedRow);

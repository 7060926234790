import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useStringListValue } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { DecoratedTag } from '../../../common/decoration/decoration/tag/index.tsx';
import { CompactValuesList } from '../../../fields/compact-values-list/index.tsx';
import { ObservableValuesList } from '../../../fields/observable-values-list/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type StringListFieldProps = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	cappedFieldsDisplay?: boolean;
	showLabel?: boolean;
};

export const StringListField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	cappedFieldsDisplay = false,
	showLabel = true,
}: StringListFieldProps) => {
	const values = useStringListValue(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<HTMLDivElement | null>(null);

	if (hideEmpty && (values === undefined || values.length === 0)) {
		return null;
	}

	if (isCompact) {
		return (
			<FieldValue>
				<CompactValuesList>
					{values?.map((option) => (
						<DecoratedTag key={option} fieldKey={fieldKey} id={option} value={option} compact />
					))}
				</CompactValuesList>
			</FieldValue>
		);
	}

	return (
		<>
			{showLabel && <FieldLabel>{label}</FieldLabel>}
			<StringListFieldValue ref={containerRef}>
				{
					// eslint-disable-next-line no-nested-ternary
					isSummary && values?.length === 0 ? (
						<EmptyField />
					) : values?.length === 1 ? (
						<DecoratedTag fieldKey={fieldKey} id={values[0]} value={values[0]} />
					) : (
						<MultiValueContainer>
							<OptionsContainerWrapper ref={listRef} cappedFieldsDisplay={cappedFieldsDisplay}>
								<ObservableValuesList isActive listRef={listRef} containerRef={containerRef}>
									{values?.map((value, index) => (
										<DecoratedTag
											key={`${value}-${index}`}
											fieldKey={fieldKey}
											id={value}
											value={value}
										/>
									))}
								</ObservableValuesList>
							</OptionsContainerWrapper>
						</MultiValueContainer>
					)
				}
			</StringListFieldValue>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '1',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionsContainerWrapper = styled.div<{ cappedFieldsDisplay: boolean }>({
	display: 'flex',
	flexWrap: 'wrap',
	width: 'max-content',
	alignItems: 'center',
	// selects all children after the 3rd one
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div:nth-of-type(1n+4)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ cappedFieldsDisplay }) => (cappedFieldsDisplay ? 'none' : undefined),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StringListFieldValue = styled(FieldValue)({
	display: 'flex',
	position: 'relative',
});

import React from 'react';
import keyBy from 'lodash/keyBy';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldOptionsWithAriResolved } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { DecoratedTag } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/tag/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { NoValueContainer } from '../../../common/board-header-column/styled.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

type EmptyColumnHeaderComponentProps = {
	isRow?: boolean;
};

const OptionsColumnHeader = ({ fieldKey, groupIdentity }: ColumnHeaderComponentProps) => {
	const options = useFieldOptionsWithAriResolved(fieldKey);
	const optionsByKey = keyBy(options, ({ id }) => id);
	const option = optionsByKey[groupIdentity];
	return option !== undefined ? (
		<DecoratedTag compact fieldKey={fieldKey} id={option.id} value={option.value} />
	) : null;
};

const EmptyOptionsColumnHeader = ({ isRow }: EmptyColumnHeaderComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<NoValueContainer isRow={isRow}>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>
	);
};

export const optionsColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: true,
	Component: OptionsColumnHeader,
	EmptyComponent: EmptyOptionsColumnHeader,
} as const;

/**
 * Copied from
 * @see src/packages/polaris/apps/ideas/src/common/utils/board.tsx
 *
 * Remove duplicated code when cleaning up feature flag
 * `polaris_board_lib_refactoring`
 */

import { EMPTY_VALUE_ID, VALUE_COLUMN_ID_PREFIX } from '../constants.tsx';
import type { IssuesByCell } from '../types/common.tsx';

export const getGroupIdentityForDroppableId = (droppableId?: string): string | undefined => {
	if (droppableId === EMPTY_VALUE_ID) {
		return undefined;
	}
	return droppableId !== undefined
		? droppableId.substring(VALUE_COLUMN_ID_PREFIX.length)
		: undefined;
};

export const getGroupIdentityPairForDroppableId = (droppableId?: string) => {
	if (droppableId !== undefined && droppableId.indexOf(VALUE_COLUMN_ID_PREFIX) === 0) {
		try {
			const result = JSON.parse(droppableId.substring(VALUE_COLUMN_ID_PREFIX.length));
			if (Array.isArray(result) && result.length === 2) {
				const [realRowId, realColumnId] = result;
				if (realRowId && realColumnId) {
					return [
						getGroupIdentityForDroppableId(realRowId),
						getGroupIdentityForDroppableId(realColumnId),
					];
				}
			}
		} catch (err: unknown) {
			// do nothing
		}
	}
	return [];
};

export const createGroupIdentityPair = (droppableId: string, verticalDroppableId: string): string =>
	`+${JSON.stringify([droppableId, verticalDroppableId])}`;

export const getIdsForGroupColumn = (
	idsByCell: IssuesByCell,
	groupIdentity?: string,
	verticalGroupByField?: string,
): string[] => {
	if (groupIdentity === undefined && verticalGroupByField !== undefined) {
		return idsByCell.xEmpty[verticalGroupByField] || [];
	}

	if (verticalGroupByField === undefined && groupIdentity !== undefined) {
		return idsByCell.yEmpty[groupIdentity] || [];
	}

	if (groupIdentity !== undefined && verticalGroupByField !== undefined) {
		return idsByCell.groups[groupIdentity]?.[verticalGroupByField] || [];
	}

	return idsByCell.empty || [];
};

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useAllExternalReferencePropertiesOnIssues } from '../../../../../../controllers/issue/selectors/external-reference-property-hooks.tsx';
import { ExternalReferencePropertyField } from '../../../../../fields/external-reference-property/main.tsx';
import messages from './messages.tsx';

type Props = {
	field: Field;
	groupIdentity: string | undefined;
};

export const optionNoValueOptionMessage = messages.noValueOption;

export const ExternalReferencePropertyOptionRenderer = ({ field, groupIdentity }: Props) => {
	const allExternalReferencePropertiesOnIssues = useAllExternalReferencePropertiesOnIssues();
	const { formatMessage } = useIntl();
	if (groupIdentity === undefined) {
		return <Box paddingInlineStart="space.100">{formatMessage(optionNoValueOptionMessage)}</Box>;
	}
	const property = allExternalReferencePropertiesOnIssues[groupIdentity];
	return (
		<Box xcss={containerStyles}>
			<ExternalReferencePropertyField fieldKey={field.key} value={property} />
		</Box>
	);
};

const containerStyles = xcss({
	whiteSpace: 'normal',
});

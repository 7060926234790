import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Select from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIssueLinkTypes } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';

type Props = {
	isAttachedToBody: boolean;
	onIssueLinkTypeSelected: (arg1: string, arg2: 'outward' | 'inward') => void;
};

type OptionType = {
	label: string;
	value: {
		direction: 'outward' | 'inward';
		id: string;
	};
};

export const IssueLinkTypePicker = ({ onIssueLinkTypeSelected, isAttachedToBody }: Props) => {
	const issueLinkTypes = useIssueLinkTypes();

	const options: Array<OptionType> = [];
	issueLinkTypes.forEach((issueLinkType) => {
		options.push({
			label: issueLinkType.inward,
			value: {
				id: issueLinkType.id,
				direction: 'outward',
			},
		});
		if (issueLinkType.inward !== issueLinkType.outward) {
			options.push({
				label: issueLinkType.outward,
				value: {
					id: issueLinkType.id,
					direction: 'inward',
				},
			});
		}
	});

	const onChange = useCallback(
		(item: OptionType | null) =>
			item && onIssueLinkTypeSelected(item.value.id, item.value.direction),
		[onIssueLinkTypeSelected],
	);

	return (
		<SelectWrapper>
			<Select
				spacing="compact"
				isClearable={false}
				defaultValue={options[0]}
				options={options}
				onChange={onChange}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={isAttachedToBody ? document.body : undefined}
				menuShouldScrollIntoView={false}
				styles={{
					// without properly set zIndex value dropdown with options is cut inside modal dialogs

					menuPortal: (base) => ({ ...base, zIndex: layers.modal }),
					menu: (base) => ({ ...base, zIndex: 99 }),
				}}
			/>
		</SelectWrapper>
	);
};

IssueLinkTypePicker.defaultProps = {
	isAttachedToBody: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div({
	width: '200px',
	maxWidth: '200px',
	display: 'inline-table',
});

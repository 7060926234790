import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { showingXAxisOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import { useCurrentViewXAxis } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { BaseFieldConfig } from '../base/index.tsx';
import { MatrixAxisFieldOptions } from '../matrix-axis-field-options/index.tsx';
import { messages } from './messages.tsx';

export const ConfigXAxis = () => {
	const { formatMessage } = useIntl();
	const xAxisField = useCurrentViewXAxis();
	const { setMatrixXAxis } = useViewActions();
	const currentField = useField(xAxisField);
	const fields = useFieldsForViewControls();

	const onChange = useCallback(
		(fieldKey: string | undefined) => {
			if (fieldKey === undefined || fieldKey === xAxisField) {
				return;
			}
			setMatrixXAxis(fieldKey);
		},
		[setMatrixXAxis, xAxisField],
	);

	return (
		<BaseFieldConfig
			helpText={formatMessage(messages.axisHelpText)}
			fields={fields}
			selectedField={xAxisField}
			backTo={showingXAxisOptions}
			onChange={onChange}
			dimension="x"
		>
			{currentField && <MatrixAxisFieldOptions field={currentField} dimension="x" />}
		</BaseFieldConfig>
	);
};

import React, { type PropsWithChildren, createContext, useContext } from 'react';

import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type ContextValue = ProjectId;

const Context = createContext<null | ContextValue>(null);

export const ProjectIdProvider = ({
	children,
	projectId,
}: PropsWithChildren<{ projectId: ProjectId }>) => {
	return <Context.Provider value={projectId}>{children}</Context.Provider>;
};

export const useProjectId = () => {
	const value = useContext(Context);

	if (value === null) {
		throw new Error('Missing ProjectIdProvider');
	}

	return value;
};

import { isInternalOauthClientId } from '@atlassian/jira-polaris-common/src/common/types/snippet/constants.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { PropertyType } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';

type InsightCreationMethod = 'manual' | 'integration';

export type PolarisInsightAnalyticsData = {
	containers: {
		insight: {
			id: string;
			type: 'insight';
		};
	};
	attributes: {
		insightHasLink: boolean;
		insightHasImpact: boolean;
		insightHasLabels: boolean;
		insightCreationMethod: InsightCreationMethod;
	};
};

export const getInsightAnalyticsData = (insight: Insight): PolarisInsightAnalyticsData => ({
	containers: {
		insight: {
			id: insight.id,
			type: 'insight' as const,
		},
	},
	attributes: {
		insightHasLink: insight.snippets.some((snippet) => snippet.url),
		insightHasImpact: insight.snippets.some((snippet) => snippet.properties?.impact),
		insightHasLabels: insight.snippets.some(
			(snippet) =>
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				((snippet.properties?.labels as PropertyType)?.value as string[])?.length > 0,
		),
		insightCreationMethod:
			insight.snippets.length === 0 ||
			insight.snippets.every(({ oauthClientId }) => isInternalOauthClientId(oauthClientId))
				? 'manual'
				: 'integration',
	},
});

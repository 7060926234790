import keyBy from 'lodash/keyBy';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import { asyncSwitchAction } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/actions/index.tsx';
import type { PolarisApiInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/get-insight/types.tsx';
import { transformInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/transform/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetInsightUpdatedTimestamp } from '../selectors/insights.tsx';
import type { Props, State } from '../types.tsx';
import { enrichPayload } from '../utils/enrich.tsx';
import { isOptimisticInsight } from '../utils/optimistic-updates.tsx';

export const refreshInsights =
	() =>
	async ({ setState }: StoreActionApi<State>, { insightsRemote, issueId, projectId }: Props) => {
		if (!issueId || !insightsRemote.fetch) {
			return;
		}
		insightsRemote.fetch({ issueId, projectId }).then((data) => {
			setState({
				insights: keyBy(data.insights, ({ id }) => id),
			});
		});
	};

export type RefreshInsightArguments = {
	insightId: string;
	lastExternalUpdate: string;
	insight?: PolarisApiInsight;
};

export const refreshInsight = asyncSwitchAction<
	State,
	Props,
	RefreshInsightArguments,
	Insight | undefined
>(
	({ insightId, insight, lastExternalUpdate }, { getState }, props) => {
		const { cloudId, insightsRemote } = props;

		if (isOptimisticInsight(insightId) || !insightsRemote.fetchInsight) {
			return Promise.resolve(undefined);
		}

		const insightAri = createAri({
			resourceOwner: 'jira',
			cloudId,
			resourceType: 'polaris-insight',
			resourceId: insightId,
		});

		const getUpdatedSelector = createGetInsightUpdatedTimestamp(insightAri);
		const updated = getUpdatedSelector(getState());

		if (updated === lastExternalUpdate) {
			// already on the newest data, bail and don't refresh
			return Promise.resolve(undefined);
		}

		if (insight) {
			const enrichedInsight = enrichPayload(insight, getState().insights);
			if (enrichedInsight) {
				return Promise.resolve(transformInsight(enrichedInsight));
			}
		}

		return insightsRemote.fetchInsight({
			id: insightId,
		});
	},
	(freshInsight, _, { getState, setState }) => {
		if (freshInsight) {
			setState({
				insights: {
					...getState().insights,
					[freshInsight.id]: freshInsight,
				},
			});
		}
	},
);

import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import IcecreamAccidentIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/icecream-accident/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { Container, Wrapper } from '../../../common/ui/container/styled.tsx';
import { messages } from './messages.tsx';

type PublishedViewEmptyStateProps = {
	permissionsLoaded: boolean;
	hasProjectPermissions: boolean;
};

export const PublishedViewEmptyState = ({
	permissionsLoaded,
	hasProjectPermissions,
}: PublishedViewEmptyStateProps) => {
	const { formatMessage } = useIntl();

	const description = (
		<FormattedMessage
			{...(hasProjectPermissions
				? messages.descriptionForCreators
				: messages.descriptionForStakeholders)}
			values={{
				a: (chunk) => (
					<Button
						appearance="link"
						href="https://confluence.atlassian.com/jpdkb/error-when-trying-to-publish-a-view-in-jira-product-discovery-1346052400.html"
						spacing="none"
						target="_blank"
					>
						{chunk}
					</Button>
				),
				br: <br />,
			}}
		/>
	);

	return (
		<Container>
			<ContentWrapper>
				<EmptyState
					testId="polaris-lib-view-empty-state.ui.views.publish-view.empty-state"
					header={formatMessage(messages.header)}
					description={permissionsLoaded && description}
					headingLevel={1}
					renderImage={IcecreamAccidentIllustrationWrapper}
					width="wide"
				/>
			</ContentWrapper>
		</Container>
	);
};

const IcecreamAccidentIllustrationWrapper = () => (
	<Flex xcss={imageWrapperStyles} justifyContent="center">
		<IcecreamAccidentIllustration alt="" width={306} role="presentation" />
	</Flex>
);

const imageWrapperStyles = xcss({
	paddingBlockEnd: 'space.400',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled(Wrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div': {
		maxWidth: '500px',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& h1': {
		marginBottom: token('space.100', '8px'),
	},
});

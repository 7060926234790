import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useDecorationForNumberValue,
	useDecorationsForField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { DecoratedNumber } from '@atlassian/jira-polaris-common/src/ui/decoration/number/index.tsx';
import { CheckboxField } from '@atlassian/jira-polaris-common/src/ui/fields/checkbox/index.tsx';
import { NumberField } from '@atlassian/jira-polaris-common/src/ui/fields/number/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { NoValueContainer } from '../../../../common/board-header-column/styled.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};
type EmptyColumnHeaderComponentProps = {
	fieldKey: FieldKey;
};

const NumberColumnHeader = ({ fieldKey, groupIdentity }: ColumnHeaderComponentProps) => {
	const decoration = useDecorationForNumberValue(fieldKey, Number(groupIdentity));
	const decorations = useDecorationsForField(fieldKey);
	const fieldType = useFieldType(fieldKey);
	if (fieldType === FIELD_TYPES.CHECKBOX) {
		return <CheckboxField value={Number(groupIdentity)} />;
	}
	if (fieldType === FIELD_TYPES.RATING) {
		return (
			<NumberField
				isCompact
				isEditable={false}
				value={Number(groupIdentity)}
				decorations={decorations}
				fieldType={fieldType}
				onUpdate={undefined}
			/>
		);
	}
	return <DecoratedNumber isCompact value={Number(groupIdentity)} decoration={decoration} />;
};

const EmptyNumberColumnHeader = ({ fieldKey }: EmptyColumnHeaderComponentProps) => {
	const { formatMessage } = useIntl();
	const decorations = useDecorationsForField(fieldKey);
	const fieldType = useFieldType(fieldKey);
	if (fieldType === FIELD_TYPES.RATING) {
		return (
			<NumberField
				isCompact
				isEditable={false}
				value={0}
				decorations={decorations}
				fieldType={fieldType}
				onUpdate={undefined}
			/>
		);
	}
	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const numberColumnHeaderConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: NumberColumnHeader,
	EmptyComponent: EmptyNumberColumnHeader,
} as const;

import memoizeOne from 'memoize-one';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum PermalinkType {
	ISSUE_COMMENTS = 'focusedCommentId',
	VIEW_COMMENTS = 'viewCommentId',
	INSIGHTS = 'focusedInsightId',
}

export const getPermalinkTypeAndId = memoizeOne((searchString: string) => {
	const params = Object.fromEntries(new URLSearchParams(searchString));
	if (params[PermalinkType.ISSUE_COMMENTS]) {
		return {
			permalinkType: PermalinkType.ISSUE_COMMENTS,
			permalinkId: params[PermalinkType.ISSUE_COMMENTS],
		};
	}
	if (params[PermalinkType.VIEW_COMMENTS]) {
		return {
			permalinkType: PermalinkType.VIEW_COMMENTS,
			permalinkId: params[PermalinkType.VIEW_COMMENTS],
		};
	}
	if (params[PermalinkType.INSIGHTS]) {
		return {
			permalinkType: PermalinkType.INSIGHTS,
			permalinkId: params[PermalinkType.INSIGHTS],
		};
	}
	return null;
});

export const getPermalinkStatus = (targetPermalinkType: string, searchString: string) => {
	const { permalinkId, permalinkType } = getPermalinkTypeAndId(searchString) || {};
	const hasPermalink = permalinkType === targetPermalinkType && permalinkId !== null;

	return {
		hasPermalink,
		permalinkId: hasPermalink ? permalinkId : '',
	};
};

export const getCopyUrl = memoizeOne(
	(permalinkType: string, resourceId: string, searchString: string) => {
		const searchParams = new URLSearchParams(searchString);
		searchParams.set(permalinkType, resourceId);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
	},
);

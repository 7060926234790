import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { NonBlockingSpotlight as NonBlockingSpotlightType } from './ui/spotlight/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyNonBlockingSpotlight = lazyForPaint<typeof NonBlockingSpotlightType>(
	() =>
		import(/* webpackChunkName: "async-polaris-lib-non-blocking-spotlight" */ './ui').then(
			({ NonBlockingSpotlight }) => NonBlockingSpotlight,
		),
	{ ssr: false },
);

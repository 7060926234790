import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useIsInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useSelectedIssue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { LabelsField } from '@atlassian/jira-polaris-common/src/ui/fields/labels/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldProps } from '../select/index.tsx';
import { useUpdateFieldValue } from '../utils.tsx';
import messages from './messages.tsx';

export const Labels = ({ fieldKey, onConfigRequested }: FieldProps) => {
	const { formatMessage } = useIntl();
	const ideaId = useSelectedIssue();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);
	const isIssuesInitialized = useIsInitialized();

	if (ideaId === undefined || field === undefined) {
		return null;
	}

	const isEditable = canEditIssues && field.editable && !isIdeaArchived;

	return (
		<Tooltip
			content={
				!isIssuesInitialized && isEditable ? formatMessage(messages.nonEditableTooltip) : undefined
			}
		>
			<LabelsField
				localIssueId={ideaId}
				fieldKey={fieldKey}
				isEditable={isIssuesInitialized && isEditable}
				onUpdate={updateFieldValue}
				placeholder={formatMessage(messages.noValue)}
				onConfigRequested={onConfigRequested}
			/>
		</Tooltip>
	);
};

import React, { useCallback } from 'react';
import DropdownMenu, {
	type DropdownMenuProps,
	DropdownItemGroup,
	DropdownItem,
} from '@atlaskit/dropdown-menu';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import BoardIcon from '@atlaskit/icon/core/migration/board';
import DeleteIcon from '@atlaskit/icon/core/migration/delete--trash';
import FilterIcon from '@atlaskit/icon/core/migration/filter';
import EditorSettingsIcon from '@atlaskit/icon/core/migration/settings--editor-settings';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { isGroupBySupportedFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/utils/is-group-by-supported.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { messages } from './messages.tsx';

type DropdownItemWithTooltipProps = {
	content: TooltipProps['content'];
	children: React.ReactNode;
};

const MaybeTooltip = ({ children, content }: DropdownItemWithTooltipProps) => {
	if (content) {
		return <Tooltip content={content}>{children}</Tooltip>;
	}

	return children;
};

export type CellDropdownMenuProps = {
	canCloneAsBoard: boolean;
	canEditFields: boolean;
	canManageCurrentView: boolean;
	fieldType: FieldType | undefined;
	isClearFilterVisible: boolean;
	isFieldRemovable: boolean;
	isFilterVisible: boolean;
	isOpen: boolean;
	isSharedView: boolean;
	sortField: SortField | undefined;
	trigger: DropdownMenuProps['trigger'];
	onClearFilter: () => void;
	onFilter: () => void;
	onManageField: () => void;
	onRemoveField: () => void;
	onRemoveSort: () => void;
	onSetAsSort: (asc: boolean) => void;
	onViewAsBoard: () => void;
	setIsOpen: (isOpen: boolean) => void;
};

export const CellDropdownMenu = ({
	canCloneAsBoard,
	canEditFields,
	canManageCurrentView,
	fieldType,
	isClearFilterVisible,
	isFieldRemovable,
	isFilterVisible,
	isOpen,
	isSharedView,
	sortField,
	trigger,
	onClearFilter,
	onFilter,
	onManageField,
	onRemoveField,
	onRemoveSort,
	onSetAsSort,
	onViewAsBoard,
	setIsOpen,
}: CellDropdownMenuProps) => {
	const { formatMessage } = useIntl();

	const getRemoveFieldDisabledTooltip = () => {
		if (!isFieldRemovable) {
			return formatMessage(messages.nonRemovableFromView);
		}

		return canManageCurrentView ? undefined : formatMessage(messages.noManageViewPermissions);
	};

	const handleOpenChange: NonNullable<DropdownMenuProps['onOpenChange']> = useCallback(
		(attrs) => {
			setIsOpen(attrs.isOpen);
		},
		[setIsOpen],
	);

	const isDisabledRemoveField = !isFieldRemovable || !canManageCurrentView;
	const isDisabledViewAsBoard = !canCloneAsBoard || !canManageCurrentView;

	return (
		<DropdownMenu
			isOpen={isOpen}
			onOpenChange={handleOpenChange}
			trigger={trigger}
			spacing="compact"
			placement="bottom-end"
			shouldFlip={false}
		>
			{!isSharedView && (
				<DropdownItemGroup>
					<DropdownItem
						onClick={onManageField}
						elemBefore={
							<EditorSettingsIcon label="" spacing="spacious" color={token('color.icon')} />
						}
					>
						{!canEditFields
							? formatMessage(messages.fieldSettings)
							: formatMessage(messages.editField)}
					</DropdownItem>
				</DropdownItemGroup>
			)}

			<DropdownItemGroup hasSeparator={!isSharedView}>
				{(!sortField || !sortField.asc) && (
					<DropdownItem
						onClick={() => onSetAsSort(true)}
						elemBefore={<ArrowUpIcon label="" spacing="spacious" color={token('color.icon')} />}
					>
						{formatMessage(messages.sortAscending)}
					</DropdownItem>
				)}
				{(!sortField || sortField.asc) && (
					<DropdownItem
						onClick={() => onSetAsSort(false)}
						elemBefore={<ArrowDownIcon label="" spacing="spacious" color={token('color.icon')} />}
					>
						{formatMessage(messages.sortDescending)}
					</DropdownItem>
				)}
				{sortField && (
					<DropdownItem
						onClick={onRemoveSort}
						elemBefore={<DeleteIcon label="" spacing="spacious" color={token('color.icon')} />}
					>
						{formatMessage(messages.clearSort)}
					</DropdownItem>
				)}
			</DropdownItemGroup>

			{(isFilterVisible || isClearFilterVisible) && (
				<DropdownItemGroup hasSeparator>
					{isFilterVisible && (
						<DropdownItem
							onClick={onFilter}
							elemBefore={<FilterIcon label="" spacing="spacious" color={token('color.icon')} />}
						>
							{formatMessage(messages.filter)}
						</DropdownItem>
					)}
					{isClearFilterVisible && (
						<DropdownItem
							onClick={onClearFilter}
							elemBefore={<DeleteIcon label="" spacing="spacious" color={token('color.icon')} />}
						>
							{formatMessage(messages.clearFilter)}
						</DropdownItem>
					)}
				</DropdownItemGroup>
			)}

			{!isSharedView && isGroupBySupportedFieldType(fieldType) && (
				<DropdownItemGroup hasSeparator>
					<MaybeTooltip
						content={canCloneAsBoard ? undefined : formatMessage(messages.viewAsBoardDisabled)}
					>
						<DropdownItem
							isDisabled={isDisabledViewAsBoard}
							onClick={onViewAsBoard}
							elemBefore={
								<BoardIcon
									label=""
									color={isDisabledViewAsBoard ? 'currentColor' : undefined}
									spacing="spacious"
								/>
							}
						>
							{formatMessage(messages.viewAsBoard)}
						</DropdownItem>
					</MaybeTooltip>
				</DropdownItemGroup>
			)}

			{!isSharedView && (
				<DropdownItemGroup hasSeparator>
					<MaybeTooltip content={getRemoveFieldDisabledTooltip()}>
						<DropdownItem
							isDisabled={isDisabledRemoveField}
							onClick={onRemoveField}
							elemBefore={
								<Flex
									alignItems="center"
									xcss={isVisualRefreshEnabled() ? crossNewUtilityIconStyles : undefined}
								>
									<CrossIcon
										label=""
										color={isDisabledRemoveField ? 'currentColor' : undefined}
										LEGACY_fallbackIcon={EditorCloseIcon}
									/>
								</Flex>
							}
						>
							{formatMessage(messages.removeFromView)}
						</DropdownItem>
					</MaybeTooltip>
				</DropdownItemGroup>
			)}
		</DropdownMenu>
	);
};

// Please look at following Confluence pages to understand the difference
// between icon sizes
// @see https://hello.atlassian.net/wiki/spaces/DST/pages/3748692796/New+ADS+iconography+-+Code+migration+guide#New-feature%3A-We-have-a-new%2C-smaller-icon-set
// @see https://hello.atlassian.net/wiki/spaces/DST/pages/3792652897/Iconography+-+Migration+cases
// @see https://atlassian.design/components/icon/icon-explorer
//
// New `utility` icons
// Container size: 12px
// Glyph size:     12px
//
// New `core` icons
// Container size: 16px
// Glyph size:     16px
//
// Current `core` icons (medium size / default)
// Container size: 24px
// Glyph size:     16px
//
// Current `editor` icons
// Container size: 24px
// Glyph size:     ~12px
//
// For New `Utility` icons to meet container size: 24px,
// we need to apply extra padding on both sides (6px * 2)
const crossNewUtilityIconStyles = xcss({
	paddingInlineStart: 'space.075',
	paddingInlineEnd: 'space.075',
});

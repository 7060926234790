/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { components, type ControlProps } from '@atlaskit/select';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const Control = <Option, IsMulti extends boolean = false>({
	children,
	options,
	isMulti,
	...rest
}: ControlProps<Option, IsMulti>) => {
	const { formatMessage } = useIntl();
	const firstOption = options[0];

	let optionsCount = 0;
	if (
		firstOption &&
		typeof firstOption === 'object' &&
		'options' in firstOption &&
		firstOption.options &&
		Array.isArray(firstOption.options)
	) {
		optionsCount = firstOption.options.length;
	}

	return (
		<>
			{isMulti ? (
				<components.Control {...rest} isMulti options={options} css={controlStyles}>
					{optionsCount > 0 && (
						<div css={tagWrapperStyles}>
							<Tag
								text={formatMessage(messages.optionsCount, {
									count: optionsCount,
								})}
								color="greyLight"
							/>
						</div>
					)}
					{children}
				</components.Control>
			) : (
				<components.Control {...rest} options={options} isMulti={false} css={controlStyles}>
					{children}
				</components.Control>
			)}
		</>
	);
};

const controlStyles = css({
	flexWrap: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingLeft: token('space.050', '4px'),
		paddingRight: token('space.050', '4px'),
	},
});

const tagWrapperStyles = css({
	color: token('color.text.subtle', N500),
	flexShrink: 1,
	minWidth: 0,
	overflow: 'hidden',
	paddingLeft: token('space.025', '2px'),
});

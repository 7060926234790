import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	descriptionPlaceholder: {
		id: 'polaris-common.create-idea-dialog.fields.document.adf.description-placeholder',
		defaultMessage: 'Add a description...',
		description: 'Placeholder for description when no description is yet set.',
	},
	templatesButton: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.copy-link',
		defaultMessage: 'Templates',
		description: 'Label for templates button',
	},
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { DocNode as ADF } from '@atlaskit/adf-schema';

const template: ADF = {
	type: 'doc',
	content: [
		{
			type: 'heading',
			attrs: {
				level: 1,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						id: '1f9ea',
						text: '🧪',
						shortName: ':test_tube:',
					},
				},
				{
					text: ' Experiment template',
					type: 'text',
				},
			],
		},
		{
			type: 'panel',
			attrs: {
				// @ts-expect-error -  Type '"info"' is not assignable to type 'PanelType'.
				panelType: 'info',
			},
			content: [
				{
					type: 'paragraph',
					content: [
						{
							text: 'This blueprint helps you capture and prioritise growth experiment ideas',
							type: 'text',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Problem',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'What problem are we solving? Why are we solving this problem?',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Hypothesis',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'If… then… because…',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Success criteria:',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Solution',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'emoji',
					attrs: {
						id: '1f5bc',
						text: '🖼️',
						shortName: ':frame_photo:',
					},
				},
				{
					text: ' add screenshots',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'table',
			attrs: {
				layout: 'default',
				width: 760.0,
				localId: 'c578cb90-7e35-415d-83cd-4cb81bbe956d',
			},
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colspan: 1,
								background:
									'var(--ds-background-accent-gray-subtlest, var(--ds-background-accent-gray-subtlest, #F1F2F4))',
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: 'Control',
											type: 'text',
											// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableHeader',
							attrs: {
								colspan: 1,
								background:
									'var(--ds-background-accent-gray-subtlest, var(--ds-background-accent-gray-subtlest, #F1F2F4))',
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: 'Variant',
											type: 'text',
											// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: ' ',
											type: 'text',
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: ' ',
											type: 'text',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: ' ',
											type: 'text',
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: ' ',
											type: 'text',
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Resources (add your own)',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
			],
		},
		{
			type: 'bulletList',
			content: [
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f4dd',
										text: '📝',
										shortName: ':pencil:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'PRD/spec',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f4f9',
										text: '📹',
										shortName: ':video_camera:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Loom',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Video',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f469-200d-1f3a8',
										text: '👩‍🎨',
										shortName: ':woman_artist:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Design file',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
			],
		},
	],
	version: 1,
};

export default template;

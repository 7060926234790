import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { User } from '../../../domain/types.tsx';
import { getUser as getUserRemote } from '../../../services/jira/get-user/index.tsx';
import type { State } from '../types.tsx';
import { transform } from '../utils/transformer.tsx';
import { updatePresence } from './presence.tsx';

export const getUser =
	(viewUUID: ViewUUID, currentUser: AccountId, id?: string) =>
	async ({ getState, dispatch }: StoreActionApi<State>): Promise<User> => {
		// the collab provider calls this function with an `undefined` id in some cases
		// in order to properly handle this, we need to do the following
		// 1. avoid calling getUser(undefined), as this can never return useful information
		//    the collab provider cannot deal with `undefined` responses or a rejected promise without
		//    an error in the console, so we return an empty object here
		if (id === undefined) {
			// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'User': userId, name, avatar, email
			return Promise.resolve({});
		}

		// anonymous user
		if (id === 'unidentified') {
			dispatch(updatePresence(viewUUID, {}));
			// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'User': userId, name, avatar, email
			return {};
		}

		if (currentUser == null) {
			// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'User': userId, name, avatar, email
			return {};
		}

		const { userCache } = getState();

		if (getState().userCache[id] === undefined) {
			userCache[id] = getUserRemote(id).then(transform);
		}

		return userCache[id].then((user) => {
			// 2. to avoid empty user objects when collab service has requested an `undefined` user
			//    for a session id, we need to make sure we update the user information in our internal
			//    state. fortunately even the `undefined` bug case contains the correct aaid, so we
			//    can properly update all the data internally
			dispatch(updatePresence(viewUUID, user));
			return user;
		});
	};

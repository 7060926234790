import type { Action } from '@atlassian/react-sweet-state';
import type { AtlasGoalsSearch } from '../../../../services/atlas/goals/types.tsx';
import type { Props, State } from '../../types.tsx';

export const addGoals =
	(goals: AtlasGoalsSearch): Action<State, Props> =>
	({ setState, getState }, { onUpdate }) => {
		setState({
			goals: {
				...getState().goals,
				items: goals.reduce(
					(result, goal) =>
						Object.assign(result, {
							[goal.id]: {
								...goal,
							},
						}),
					getState().goals.items,
				),
			},
		});
		onUpdate(getState().goals.items);
	};

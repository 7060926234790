import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';

type UpdateCollectionOptions<T> = {
	collection: T[];
	sourceItem: T;
	srcIdx: number;
	dstIdx: number;
};

export function updateCollection<T>({
	collection,
	sourceItem,
	srcIdx,
	dstIdx,
}: UpdateCollectionOptions<T>) {
	const nextCollection = [...collection];

	if (srcIdx === -1 && dstIdx === -1) {
		// It means that `source` item was removed from the collection
		// and we are trying to place it at the bottom of the list
		// thus exceeding collection's length therefore
		// we just `push` the item to collection
		nextCollection.push(sourceItem);
	} else if (srcIdx > -1) {
		nextCollection.splice(srcIdx, 1);
		nextCollection.splice(dstIdx, 0, sourceItem);
	} else if (dstIdx > -1) {
		nextCollection.splice(dstIdx, 0, sourceItem);
	}

	return nextCollection;
}

type UpdateCollectionAfterDndOptions<Item, ItemKey> = {
	srcId: ItemKey;
	dstId: ItemKey;
	edge: Edge;
	collection: Item[];
	sourceItem: Item;
	isSrcIdRepresentingIndex?: boolean;
	getSrcIdForCollectionItem?: (item: Item) => unknown;
};

const defaultGetSrcIdForCollectionItem = <T,>(x: T): T => x;

export function updateCollectionAfterDnd<T, K>({
	srcId,
	dstId,
	edge,
	collection,
	sourceItem,
	isSrcIdRepresentingIndex = false,
	getSrcIdForCollectionItem = defaultGetSrcIdForCollectionItem,
}: UpdateCollectionAfterDndOptions<T, K>): T[] {
	const srcIdx = isSrcIdRepresentingIndex
		? Number(srcId)
		: collection.findIndex((item) => getSrcIdForCollectionItem(item) === srcId);

	const dstIdx = isSrcIdRepresentingIndex
		? Number(dstId)
		: collection.findIndex((item) => getSrcIdForCollectionItem(item) === dstId);

	// Item can be removed while it's being dragged
	// so `srcIdx` may not exist anymore in `collection`
	if (srcIdx === -1) {
		const inRange = dstIdx + 1 < collection.length;
		const dstAdj = edge === 'bottom' && inRange ? 1 : 0;
		return updateCollection({
			srcIdx,
			dstIdx: edge === 'bottom' && !inRange ? -1 : dstIdx + dstAdj,
			collection,
			sourceItem,
		});
	}

	let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
	dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;
	return updateCollection({ collection, srcIdx, dstIdx: dstIdx + dstAdj, sourceItem });
}

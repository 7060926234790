import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import { createGlobalFieldRemote, createSharingGlobalFieldRemote } from './controllers/index.tsx';
import type { GlobalFieldRemote } from './controllers/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { View } from './services/polaris-api/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueType, JiraApiGlobalField } from './services/jira/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { PartialAssociationError } from './services/jira/associate-global-field';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { AssociationHardLimitError } from './services/jira/associate-global-field/errors';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { GlobalFieldRemote };

const { RemoteContextProvider, useRemoteFromContext } =
	createRemoteContext<GlobalFieldRemote>('global-field');

export const GlobalFieldRemoteProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const fieldRemote = useMemo(() => createGlobalFieldRemote(), []);

	return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
};

export const GlobalFieldSharingRemoteProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const fieldRemote = useMemo(() => createSharingGlobalFieldRemote(), []);

	return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
};

export const useGlobalFieldRemote = useRemoteFromContext;

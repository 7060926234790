import React from 'react';
import keyBy from 'lodash/keyBy';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldOptionsWithAriResolved } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { DecoratedTag } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/tag/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const OptionsFieldValue = ({ fieldKey, groupIdentity }: FieldValueComponentProps) => {
	const options = useFieldOptionsWithAriResolved(fieldKey);
	const optionsByKey = keyBy(options, ({ id }) => id);
	const option = optionsByKey[groupIdentity];
	return option !== undefined ? (
		<DecoratedTag fieldKey={fieldKey} id={option.id} value={option.value} />
	) : null;
};

const EmptyOptionsFieldValue = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyColumnHeader)}</Box>;
};

export const optionsFieldValueConfig = {
	isHideable: true,
	isDecoratable: true,
	Component: OptionsFieldValue,
	EmptyComponent: EmptyOptionsFieldValue,
};

import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import { flushSync } from 'react-dom';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import uuid from 'uuid';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import Select, { type SelectInstance } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useStateWithRef } from '../../../common/utils/react/hooks.tsx';
import { useFieldActions } from '../../../controllers/field/main.tsx';
import { useIsGlobalCustomField } from '../../../controllers/field/selectors/field-hooks.tsx';
import {
	useAreGlobalLabelsLoading,
	useIsGlobalLabelsLoadingFailed,
} from '../../../controllers/field/selectors/options-hooks.tsx';
import { useGroupOptions } from '../../../controllers/issue/selectors/grouping-hooks.tsx';
import { useStringListValue } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useDecoratableSelectCustomComponentsForLabels } from '../../common/decoration/decoration/select/index.tsx';
import { DecoratedTag } from '../../common/decoration/decoration/tag/index.tsx';
import { HAS_MORE_GLOBAL_LABELS_FLAG_ID } from '../../common/decoration/select/menu-list/index.tsx';
import messages from './messages.tsx';
import { stylesConfig, type SelectFieldValue } from './styled.tsx';
import { createLabelsGroups } from './utils.tsx';

type LabelsFieldProps = {
	isEditable: boolean;
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	placeholder?: string | undefined;
	menuPortalTarget?: HTMLElement;
	onUpdate: (inputValue: string[]) => void;
	onConfigRequested?: (optionId?: string) => void;
	testId?: string;
};

type ReadViewProps = {
	isEditable: boolean;
	fieldKey: FieldKey;
	value: SelectFieldValue[] | undefined;
	placeholder?: string | undefined;
};

type EditViewProps = {
	fieldKey: FieldKey;
	value: SelectFieldValue[] | undefined;
	menuPortalTarget?: HTMLElement;
	onUpdate: (inputValue: SelectFieldValue[]) => void;
	onAddNewOption: (value: string) => void;
	onCloseRequested: () => void;
	onConfigRequested?: (optionId?: string) => void;
};

const ReadView = ({ value, isEditable, fieldKey, placeholder }: ReadViewProps) => {
	if (value === undefined || value.length === 0) {
		return placeholder === undefined ? (
			<ReadValueContainer isEditable={isEditable} />
		) : (
			<PlaceholderContainer isEditable={isEditable}>{placeholder}</PlaceholderContainer>
		);
	}

	return (
		<MultiValueContainer isEditable={isEditable}>
			{value.map(({ id }) => (
				<DecoratedTag key={id} fieldKey={fieldKey} id={id} value={id} />
			))}
		</MultiValueContainer>
	);
};

const EditView = memo<EditViewProps>(
	({
		fieldKey,
		value,
		onUpdate,
		onCloseRequested,
		onConfigRequested,
		onAddNewOption,
		menuPortalTarget,
	}: EditViewProps) => {
		const { formatMessage } = useIntl();
		const groupOptions = useGroupOptions(fieldKey);
		const [isOpen, setIsOpen] = useState(true);
		const [inputValue, setInputValue] = useState('');
		const [selectMenuId] = useState(uuid());
		const selectRefNext = useRef<SelectInstance | null>(null);
		const containerRef = useRef<HTMLDivElement>(null);

		const isGlobalField = useIsGlobalCustomField(fieldKey);
		const areGlobalLabelsLoading = useAreGlobalLabelsLoading(fieldKey);
		const isGlobalLabelsLoadingFailed = useIsGlobalLabelsLoadingFailed(fieldKey);
		const { loadGlobalLabels } = useFieldActions();
		const { error } = useNotifications();

		useEffect(() => {
			if (isGlobalField) {
				loadGlobalLabels(fieldKey).catch(() => {
					error({
						title: formatMessage(messages.globalLabelsLoadingError),
					});
				});
			}
		}, [fieldKey, isGlobalField, loadGlobalLabels, error, formatMessage]);

		useCloseOnEscapePress({
			onClose: () => {
				setIsOpen(false);
			},
		});

		const optionsExternal = useMemo(() => {
			if (isGlobalField && (areGlobalLabelsLoading || isGlobalLabelsLoadingFailed)) {
				return [];
			}

			return groupOptions.options.map(({ groupIdentity }) => ({
				id: groupIdentity,
				value: groupIdentity,
			}));
		}, [groupOptions.options, isGlobalField, areGlobalLabelsLoading, isGlobalLabelsLoadingFailed]);

		const [options, setOptions] = useState(optionsExternal);

		const onChange = useCallback(
			(newValue: SelectFieldValue[] | undefined) => {
				if (newValue === null || newValue === undefined) {
					onUpdate([]);
				} else {
					onUpdate(newValue);
				}

				setInputValue('');
				defer(() => selectRefNext.current?.focus());
			},
			[onUpdate],
		);

		const handleAdd = useCallback(
			(newValue: string) => {
				if (newValue !== '') {
					onAddNewOption(newValue);
					setInputValue('');
					defer(() => selectRefNext.current?.focus());

					const splitValues = newValue.split(' ');
					const newOptions = [
						...options,
						...splitValues.map((part) => ({ id: part.trim(), value: part.trim() })),
					];
					const safeNewOptions = uniqBy(newOptions, ({ id }) => id);

					setOptions(safeNewOptions);
				}
				return Promise.resolve(newValue);
			},
			[onAddNewOption, options],
		);

		useEffect(() => {
			const handleClickAnywhere = (e: MouseEvent) => {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const menuElement = document.getElementById(selectMenuId);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const containerElement: any = containerRef.current;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const { target }: any = e;
				if (
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					!menuElement?.contains(target as Node) &&
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					!containerElement?.contains(target as Node) &&
					target?.getAttribute('role') !== 'img'
				) {
					onCloseRequested();
				}
			};

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousedown', handleClickAnywhere);
			return () => {
				// clean up

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('mousedown', handleClickAnywhere);
			};
		});

		const onConfig = useCallback(
			(optionId?: string) => {
				if (onConfigRequested) {
					onConfigRequested(optionId);
					setIsOpen(false);
				}
			},
			[onConfigRequested],
		);

		const optionsLimit = 30;

		const optionsGroupsUnfiltered = useMemo(
			() => createLabelsGroups(value || [], optionsExternal, true),
			[value, optionsExternal],
		);

		const components = useDecoratableSelectCustomComponentsForLabels({
			onAddOption: handleAdd,
			isMulti: true,
			fieldKey,
			onConfigRequested: onConfig,
			menuId: selectMenuId,
			hideOptionEditButtons: isGlobalField,
		});

		const optionsGroups = useMemo(() => {
			const [selectedOptions, otherOptions] = optionsGroupsUnfiltered;

			if (!isGlobalField || otherOptions.options.length <= optionsLimit) {
				return optionsGroupsUnfiltered;
			}

			const normalizedInputValue = inputValue.toLocaleLowerCase();

			const otherOptionsFiltered = normalizedInputValue
				? otherOptions.options.filter(({ id }) =>
						id.toLocaleLowerCase().includes(normalizedInputValue),
					)
				: otherOptions.options;

			const otherOptionsLimited = otherOptionsFiltered.slice(0, optionsLimit);

			if (otherOptionsFiltered.length > otherOptionsLimited.length) {
				otherOptionsLimited.push({
					id: HAS_MORE_GLOBAL_LABELS_FLAG_ID,
				});
			}

			return [
				selectedOptions,
				{
					options: otherOptionsLimited,
				},
			];
		}, [optionsGroupsUnfiltered, inputValue, isGlobalField]);

		const { createAnalyticsEvent } = useAnalyticsEvents();

		return (
			<div ref={containerRef}>
				<Select
					menuPortalTarget={menuPortalTarget}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onKeyDown={(event: any) => {
						// handle ENTER key
						if (event.key === 'Enter') {
							handleAdd(inputValue);
						}
					}}
					// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type '((string | ((instance: { components: Partial<SelectComponents<SelectFieldValue, true, GroupTypeBase<SelectFieldValue>>>; ... 23 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; } | null) => void) | RefObject<...>) & (((instance: any) => void) | RefObj...'.
					ref={selectRefNext}
					menuIsOpen={isOpen}
					isLoading={isGlobalField && areGlobalLabelsLoading}
					onFocus={() => {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'toggled',
								actionSubject: 'dropdown',
							}),
							'issueField',
						);
					}}
					onMenuOpen={() => setIsOpen(true)}
					// @ts-expect-error - TS2345 - Argument of type 'OptionsType<SelectFieldValue>' is not assignable to parameter of type 'SelectFieldValue[]'.
					onChange={onChange}
					isMulti
					hideSelectedOptions={false}
					isSearchable
					isClearable
					value={value}
					inputValue={inputValue}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onInputChange={(newInputValue: any) => setInputValue(newInputValue)}
					autoFocus
					options={optionsGroups}
					components={components}
					getOptionLabel={({ id }) => id}
					getOptionValue={({ id }) => id}
					placeholder={formatMessage(messages.placeholder)}
					enableAnimation={false}
					menuPlacement="auto"
					maxMenuHeight={fg('polaris_fix_fields_at_the_bottom_of_a_list_view') ? 300 : undefined}
					// if minMenuHeight is bigger than the actual menu height (whole menu), the menuPlacement "auto" will work - here it is set to the biggest possible number to make it always work
					minMenuHeight={
						fg('polaris_fix_fields_at_the_bottom_of_a_list_view')
							? Number.MAX_SAFE_INTEGER
							: undefined
					}
					styles={stylesConfig}
				/>
			</div>
		);
	},
);

const useValueAsSelectedFieldValue = (fieldKey: FieldKey, localIssueId: LocalIssueId) => {
	const value = useStringListValue(fieldKey, localIssueId);
	return useMemo(() => [...(value ?? [])]?.sort().map((val: string) => ({ id: val })), [value]);
};

export const LabelsField = memo<LabelsFieldProps>(
	({
		fieldKey,
		localIssueId,
		onUpdate,
		isEditable,
		placeholder,
		onConfigRequested,
		menuPortalTarget,
		testId,
	}: LabelsFieldProps) => {
		const value = useValueAsSelectedFieldValue(fieldKey, localIssueId);

		const [isActive, setActive] = useState(false);
		const [valueInternal, setValueInternal, valueInternalRef] = useStateWithRef<
			SelectFieldValue[] | undefined
		>(value);
		const [previousValue, setPreviousValue] = useState<SelectFieldValue[] | undefined>(value);

		const containerRef = useRef<HTMLDivElement>(null);

		const isInEditView = isEditable && isActive;

		// overwrite local state if the value changes from the outside (e.g. bento)
		useEffect(() => {
			if (
				!isShallowEqual(
					previousValue?.map(({ id }) => id),
					value?.map(({ id }) => id),
				)
			) {
				setPreviousValue(value);
				setValueInternal(value);
			}
		}, [previousValue, setValueInternal, value]);

		const commit = useCallback(
			(newValue: SelectFieldValue[] | undefined) => {
				const updateAsArray =
					newValue !== undefined ? newValue.map(({ id }) => ({ id })) : undefined;

				if (
					isShallowEqual(
						value?.map(({ id }) => id),
						newValue?.map(({ id }) => id),
					)
				) {
					return;
				}

				onUpdate(
					flattenDeep(
						(updateAsArray || []).map(({ id }) => id.split(' ').map((part) => part.trim())),
					),
				);
			},
			[onUpdate, value],
		);

		const closeAndCommit = useCallback(
			(newValue: SelectFieldValue[] | undefined) => {
				setActive(false);
				commit(newValue);
			},
			[commit],
		);

		const onCloseRequested = useCallback(() => {
			closeAndCommit(valueInternalRef.current);
		}, [closeAndCommit, valueInternalRef]);

		const onAddNewOption = useCallback(
			(newValue: string) => {
				if (newValue === '') {
					return;
				}

				let updateResult: SelectFieldValue[] = [];

				flushSync(() =>
					setValueInternal((oldVal) => {
						const splitValues = newValue.split(' ');
						const newValues = [
							...(oldVal || []),
							...splitValues.map((part) => ({ id: part.trim() })),
						];
						const safeNewValues = uniqBy(newValues, ({ id }) => id);

						if (safeNewValues === null || safeNewValues === undefined) {
							return [];
						}

						updateResult = safeNewValues;
						return safeNewValues;
					}),
				);

				commit(updateResult);
			},
			[commit, setValueInternal],
		);

		const handleReadViewClick = useCallback(() => {
			if (!isEditable) {
				return;
			}

			setActive(true);
		}, [isEditable]);

		return (
			<Container ref={containerRef} data-testid={testId}>
				{!isInEditView && (
					<ReadViewContainer onClick={handleReadViewClick} isEditable={isEditable}>
						<ReadView
							value={valueInternal}
							fieldKey={fieldKey}
							isEditable={isEditable}
							placeholder={placeholder}
						/>
					</ReadViewContainer>
				)}
				{isInEditView && (
					<EditView
						fieldKey={fieldKey}
						value={valueInternal}
						onUpdate={setValueInternal}
						onAddNewOption={onAddNewOption}
						onCloseRequested={() => onCloseRequested()}
						onConfigRequested={onConfigRequested}
						menuPortalTarget={menuPortalTarget}
					/>
				)}
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{ isEditable?: boolean }>({
	borderRadius: '4px',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isEditable }) =>
			isEditable && token('color.background.neutral.subtle.hovered', '#ebecf0'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadValueContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	lineHeight: '16px',
	padding: '9px 0',
	minHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	lineHeight: '16px',
	padding: `9px ${token('space.075', '6px')} 9px`,
	minHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	padding: `${token('space.050', '4px')} 0`,
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: 1,
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		flexShrink: 0,
	},
});

import React from 'react';

import Tooltip from '@atlaskit/tooltip';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useConnectionFieldIssueIds } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import { ConnectionsList } from '../../../connection/connection-list/index.tsx';
import { TooltipComponent } from '../../../connection/tooltip-component/index.tsx';

import { DetailedShort } from './detailed-short/index.tsx';
import { LabelItem } from './label-item/index.tsx';

type ConnectionFieldProps = {
	variant?: ViewLayoutType;
	renderDetailedAsCompact?: boolean;
	localIssueId: string;
	fieldKey: string;
	hideEmpty?: boolean;
};

export const ConnectionField = (props: ConnectionFieldProps) => {
	const {
		fieldKey,
		localIssueId,
		variant,
		renderDetailedAsCompact = false,
		hideEmpty = false,
	} = props;

	const linkedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);

	if (hideEmpty && (linkedIssueIds === undefined || linkedIssueIds.length === 0)) {
		return null;
	}

	switch (variant) {
		case ViewLayoutType.SUMMARY:
			return <LabelItem amount={linkedIssueIds.length} fieldKey={fieldKey} />;
		case ViewLayoutType.COMPACT:
			return (
				<Tooltip
					content={
						<ConnectionsList
							localIssueId={localIssueId}
							fieldKey={fieldKey}
							connectionItems={linkedIssueIds}
						/>
					}
					component={TooltipComponent}
					position="bottom-start"
					hideTooltipOnClick
				>
					<LabelItem showFieldLabel amount={linkedIssueIds.length} fieldKey={fieldKey} />
				</Tooltip>
			);

		case ViewLayoutType.DETAILED:
		default:
			return renderDetailedAsCompact ? (
				<DetailedShort
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					connectionItems={linkedIssueIds}
				/>
			) : (
				<ConnectionsList
					localIssueId={localIssueId}
					connectionItems={linkedIssueIds}
					fieldKey={fieldKey}
				/>
			);
	}
};

import gqlTagPolaris from 'graphql-tag';
import type { CustomErrorHandlerRespose } from '@atlassian/jira-apollo-polaris/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	AtlasGoalsByAriQuery,
	AtlasGoalsSearchQuery,
	AtlasGoalTqlQuery,
	AtlasGoalTql,
	AtlasGoalsByAri,
	AtlasGoalsSearch,
} from './types.tsx';

export const GoalFragment = gqlTagPolaris`
fragment AtlasGoalDetails on TownsquareGoal {
    id
    uuid
    name
    url
    owner {
      accountStatus
      name
    }
    iconData
    key
    archived
    state {
      label
      value
    }
    dueDate {
      dateRange {
        start
        end
      }
      confidence
      label
    }
  }
    `;

export const GoalWithUsedFieldsFragment = gqlTagPolaris`
    fragment AtlasGoalDetailsWithUsedFields on TownsquareGoal {
      id
      name
      url
      state {
        label
        value
      }
      dueDate {
        dateRange {
          start
          end
        }
        confidence
        label
      }
    }
    `;

const ATLAS_GOALS_BY_ARI_QUERY = gqlTagPolaris`
query jira_atlas_AtlasGoalsByAri($aris: [String!]) {
  townsquare {
    goalsByAri(aris: $aris) {
      ...AtlasGoalDetailsWithUsedFields
    }
  }
}
${GoalWithUsedFieldsFragment}
`;

const ATLAS_GOALS_SEARCH_QUERY = gqlTagPolaris`
query jira_atlas_AtlasGoalsSearch($cloudId: String!, $search: String) {
  townsquare {
    goalSearch(cloudId: $cloudId, q: $search, first: 10) {
      edges {
        node {
          ...AtlasGoalDetailsWithUsedFields
        }
      }
    }
  }
}
${GoalWithUsedFieldsFragment}
`;

const ATLAS_GOAL_TQL = gqlTagPolaris`
query jira_atlas_AtlasGoalTql($q: String!, $cloudId: String!) {
  townsquare {
   goalTql(q: $q, cloudId: $cloudId) @optIn(to: "Townsquare") {
      edges {
        node {
          ...AtlasGoalDetailsWithUsedFields
        }
      }
    }
  }
}
${GoalWithUsedFieldsFragment}
`;

const handleByAriResult = (result: { data: AtlasGoalsByAriQuery } | null) => {
	if (result?.data.townsquare?.goalsByAri === undefined) {
		throw new Error('polaris-ideas.atlas-goals-by-ari-error');
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (result.data.townsquare?.goalsByAri?.filter(Boolean) || []) as AtlasGoalsByAri;
};

const handleSearchResult = (result: { data: AtlasGoalsSearchQuery } | null) => {
	if (result?.data.townsquare?.goalSearch === undefined) {
		throw new Error('polaris-ideas.atlas-goals-search-error');
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (result.data.townsquare?.goalSearch?.edges?.map((edge) => edge?.node).filter(Boolean) ||
		[]) as AtlasGoalsSearch;
};

const handleByKeyResult = (result: { data: AtlasGoalTqlQuery } | null) => {
	if (
		result?.data.townsquare?.goalTql === undefined ||
		result?.data.townsquare?.goalTql === null ||
		!result.data.townsquare?.goalTql.edges
	) {
		throw new Error('polaris-ideas.atlas-goal-by-key-error');
	}

	if (!result.data.townsquare?.goalTql.edges[0]?.node) {
		return null;
	}

	return result.data.townsquare?.goalTql.edges[0].node;
};

export const getAtlasGoalsByAri = (
	client: PolarisApolloClient,
	aris: string[],
): Promise<AtlasGoalsByAri> =>
	client
		.query<AtlasGoalsByAriQuery>({
			query: ATLAS_GOALS_BY_ARI_QUERY,
			variables: {
				aris,
			},
			context: {
				errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
					graphQLErrors?.length === 1 && graphQLErrors[0].extensions?.statusCode === 401,
			},
			fetchPolicy: 'no-cache',
		})
		.then(handleByAriResult);

export const getAtlasGoalsSearch = (
	client: PolarisApolloClient,
	cloudId: string,
	search = '',
): Promise<AtlasGoalsSearch> =>
	client
		.query<AtlasGoalsSearchQuery>({
			query: ATLAS_GOALS_SEARCH_QUERY,
			variables: {
				cloudId,
				search,
			},
			context: {
				errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
					graphQLErrors?.length === 1 && graphQLErrors[0].extensions?.statusCode === 401,
			},
			fetchPolicy: 'no-cache',
		})
		.then(handleSearchResult);

export const getAtlasGoalByKey = (
	client: PolarisApolloClient,
	cloudId: string,
	key: string,
): Promise<AtlasGoalTql | null> =>
	client
		.query<AtlasGoalTqlQuery>({
			query: ATLAS_GOAL_TQL,
			variables: {
				q: `key = ${key}`,
				cloudId,
			},
			context: {
				errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
					graphQLErrors?.length === 1 &&
					[401, 404].includes(graphQLErrors[0].extensions?.statusCode),
			},
			fetchPolicy: 'no-cache',
		})
		.then(handleByKeyResult);

import React from 'react';
import { SUM_ROLLUP } from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import { useGetNumberFieldsRollup } from '../../../../controllers/issue/selectors/fields-rollup-hooks.tsx';
import { FieldGroupedSum } from '../../common/sum/index.tsx';
import type { Props } from '../../types.tsx';

export const NumberFieldGroupedSum = ({ fieldKey, groupId, groupFieldKey }: Props) => {
	const rollup = useGetNumberFieldsRollup(fieldKey, groupFieldKey, groupId);
	const val = rollup[SUM_ROLLUP];

	if (val === undefined) {
		return null;
	}

	return <FieldGroupedSum val={val} />;
};

import { useCallback, useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import type { GroupBase, OptionsOrGroups } from '@atlaskit/react-select';
import { createFilter } from '@atlaskit/select';
import { getSearchParams } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/utils.tsx';
import { useAllIssueTypes } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { makeAvatarUrlFromId } from '@atlassian/jira-polaris-component-issue-types/src/ui/utils/make-avatar-url-from-id.tsx';
import { useCanCreateAndEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useConnectionFieldIssueTypeIds } from '../../../controllers/field/selectors/field-hooks.tsx';
import {
	useAllIssueIdsMatchingConnectionFieldFilters,
	useConnectionFieldIssueIds,
} from '../../../controllers/issue/selectors/connection-hooks.tsx';
import {
	useLocalIssueIdToJiraIssueId,
	useLocalIssueIdsByJiraIssueId,
} from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIssueTypes,
	useKeys,
	useSummaries,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useExternalIssueDataByJiraId } from '../../../controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import type { ConnectionFieldOption } from './types.tsx';

export const useConnectionFieldOptions = (localIssueId: string, fieldKey: string) => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const localIssueIdByJiraIssueId = useLocalIssueIdsByJiraIssueId();
	const externalIssueData = useExternalIssueDataByJiraId();
	const linkedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);
	const allIssues = useAllIssueIdsMatchingConnectionFieldFilters(localIssueId, fieldKey);
	const issueTypes = useIssueTypes();
	const summaries = useSummaries();
	const keys = useKeys();

	const allOptions: ConnectionFieldOption[] = useMemo(
		() =>
			allIssues.map((id) => ({
				value: localIssueIdToJiraId[id],
				label: summaries[id],
				issueKey: keys[id],
				issueType: issueTypes[id],
			})),
		[allIssues, issueTypes, keys, localIssueIdToJiraId, summaries],
	);

	const selectedOptions: ConnectionFieldOption[] = linkedIssueIds.map(({ id }) => {
		const jiraId = parseInt(id, 10);
		const localId = localIssueIdByJiraIssueId[jiraId];
		return {
			value: id,
			issueKey: externalIssueData[jiraId]?.issueKey,
			label: summaries[localId] ?? externalIssueData[jiraId]?.summary,
			issueType: issueTypes[localId] ?? externalIssueData[jiraId]?.issueType,
		};
	});

	return [allOptions, selectedOptions] as const;
};

export const isGroup = (
	optionOrGroup: ConnectionFieldOption | GroupBase<ConnectionFieldOption>,
): optionOrGroup is GroupBase<ConnectionFieldOption> => 'options' in optionOrGroup;

export const filterOption = (option: ConnectionFieldOption, inputValue: string) => {
	const { searchString } = getSearchParams(inputValue);
	const filter = createFilter<ConnectionFieldOption>({
		stringify: ({ data: { issueKey }, label }) => `${issueKey} ${label}`,
	});
	return filter({ value: option.value, label: option.label, data: option }, searchString);
};

export const getTotalOptions = (
	options: OptionsOrGroups<ConnectionFieldOption, GroupBase<ConnectionFieldOption>>,
) => sumBy(options, (option) => (isGroup(option) ? option.options.length : 1));

export const useGetNewOptionData = (fieldKey: string) => {
	const connectionFieldIssueTypeIds = useConnectionFieldIssueTypeIds(fieldKey);
	const allIssueTypes = useAllIssueTypes();
	// For now we pick the first one that matches, until issue type picker is implemented
	const issueTypeId = connectionFieldIssueTypeIds.find((id) => allIssueTypes[id]?.id !== undefined);
	const issueType = issueTypeId ? allIssueTypes[issueTypeId] : undefined;
	const canCreateAndEditIssues = useCanCreateAndEditIssues();

	return useCallback(
		(label: string): ConnectionFieldOption | null => {
			if (!issueType || !canCreateAndEditIssues) {
				return null;
			}

			return {
				issueKey: '',
				issueType: {
					id: issueType.id,
					name: issueType.name,
					iconUrl: makeAvatarUrlFromId(issueType.avatarId, 'small'),
				},
				label,
				value: label,
				isInCreation: true,
			};
		},
		[issueType, canCreateAndEditIssues],
	);
};

import fetchUsers from '@atlassian/jira-common-rest/src/api/2/user/picker/index.tsx';
import type { User } from '../../../common/types/user/index.tsx';

export const getUsers = (query?: string | null): Promise<User[]> =>
	fetchUsers(
		query === null || query === undefined || query === '' ? '*' : query,
		'large',
		true,
	).then((users) =>
		users.map(({ accountId, displayName, avatarUrl, accountType }) => ({
			id: accountId,
			name: displayName || '',
			avatarUrl,
			accountType,
		})),
	);

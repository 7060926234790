import { useMemo } from 'react';
import { VIEW_KIND_BOARD } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import {
	useCurrentViewId,
	useViewAri,
	useViewFieldKeys,
	useViewFieldTypes,
	useViewFilterLength,
	useViewFilterTypes,
	useViewGroupBy,
	useViewGroupValues,
	useViewKind,
	useViewSortFieldTypes,
} from './view-hooks.tsx';

const EMPTY = Object.freeze({});

export type ViewAnalyticsAttributes = {
	[key: string]: number | string | string[] | undefined;
};

export const useViewAnalyticsAttributes = (id?: LocalViewId): ViewAnalyticsAttributes => {
	const viewId = useViewAri(id);
	const viewKind = useViewKind(id);
	const viewFilterCount = useViewFilterLength(id);
	const viewFilterTypes = useViewFilterTypes(id);
	const viewFieldTypes = useViewFieldTypes(id);
	const viewFieldKeys = useViewFieldKeys(id);
	const viewSortFieldTypes = useViewSortFieldTypes(id);
	const viewGroupBy = useViewGroupBy(id);
	const viewGroupValues = useViewGroupValues(id);

	return useMemo(() => {
		if (id === undefined) {
			return EMPTY;
		}

		const commonAttributes = {
			viewId,
			viewKind,
			viewFilterCount,
			viewFilterTypes,
			viewFieldTypes,
			viewFieldKeys,
			viewSortFieldTypes,
		};

		if (viewKind === VIEW_KIND_BOARD) {
			return {
				...commonAttributes,
				viewGroupByFieldType: viewGroupBy?.type !== undefined ? viewGroupBy?.type : 'unknown',
				viewGroupByValueCount: viewGroupValues?.length !== undefined ? viewGroupValues?.length : 0,
			};
		}
		return commonAttributes;
	}, [
		id,
		viewId,
		viewKind,
		viewFilterCount,
		viewFilterTypes,
		viewFieldTypes,
		viewFieldKeys,
		viewSortFieldTypes,
		viewGroupBy,
		viewGroupValues,
	]);
};

export const useCurrentViewAnalyticsAttributes = (): ViewAnalyticsAttributes => {
	const currentViewId = useCurrentViewId();
	return useViewAnalyticsAttributes(currentViewId);
};

import type { MessageDescriptorV2 } from '@atlassian/jira-intl';
import {
	createStartAfterNowValue,
	createStartBeforeNowValue,
	createEndBeforeNowValue,
	createEndAfterNowValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/index.tsx';
import {
	type IntervalFieldFilterValue,
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
	type IntervalFilterOperatorCode,
	type IntervalFieldFilterLegacyValue,
	type DistributeArray,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { isIntervalFieldFilterLegacyValue } from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import messages from './messages.tsx';
import {
	type UiFilter,
	INTERVAL_ENDED_VISUALIZATION,
	INTERVAL_ENDS_VISUALIZATION,
	INTERVAL_STARTED_VISUALIZATION,
	INTERVAL_STARTS_VISUALIZATION,
} from './types.tsx';

function createUiFilter({
	id,
	operator,
	valueFactory,
	label,
	activeLabel,
}: {
	id: string;
	operator: IntervalFilterOperatorCode;
	valueFactory: (parameter: number) => IntervalFieldFilterLegacyValue;
	label: MessageDescriptorV2;
	activeLabel: MessageDescriptorV2;
}): UiFilter {
	return {
		id,
		isChecked: (values: DistributeArray<IntervalFieldFilterValue[]>): boolean => {
			if (values.length === 1) {
				const value = values[0];
				if (isIntervalFieldFilterLegacyValue(value)) {
					return value.operator === operator;
				}
			}

			return false;
		},
		getValues: (
			parameter: number | undefined,
		): DistributeArray<IntervalFieldFilterValue[]> | undefined => [valueFactory(parameter || 0)],
		hasParameter: true,
		label,
		activeLabel,
	};
}

const intervalEndedRelativeFilter: UiFilter = createUiFilter({
	id: INTERVAL_ENDED_VISUALIZATION,
	operator: END_BEFORE_NOW,
	valueFactory: createEndBeforeNowValue,
	label: messages.intervalEndedRelativeFilterLabel,
	activeLabel: messages.intervalEndedRelativeFilterActiveLabel,
});

const intervalWillStartRelativeFilter: UiFilter = createUiFilter({
	id: INTERVAL_STARTS_VISUALIZATION,
	operator: START_AFTER_NOW,
	valueFactory: createStartAfterNowValue,
	label: messages.intervalWillStartRelativeFilterLabel,
	activeLabel: messages.intervalWillStartRelativeFilterActiveLabel,
});

const intervalStartedRelativeFilter: UiFilter = createUiFilter({
	id: INTERVAL_STARTED_VISUALIZATION,
	operator: START_BEFORE_NOW,
	valueFactory: createStartBeforeNowValue,
	label: messages.intervalStartedRelativeFilterLabel,
	activeLabel: messages.intervalStartedRelativeFilterActiveLabel,
});

const intervalWillEndRelativeFilter: UiFilter = createUiFilter({
	id: INTERVAL_ENDS_VISUALIZATION,
	operator: END_AFTER_NOW,
	valueFactory: createEndAfterNowValue,
	label: messages.intervalWillEndRelativeFilterLabel,
	activeLabel: messages.intervalWillEndRelativeFilterActiveLabel,
});

export const filters: UiFilter[] = [
	intervalStartedRelativeFilter,
	intervalEndedRelativeFilter,
	intervalWillStartRelativeFilter,
	intervalWillEndRelativeFilter,
];

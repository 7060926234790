import React from 'react';
import { PolarisIdeasCommonContext } from '@atlassian/jira-polaris-common/src/controllers/context/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { InsightsContainerInternal } from './main.tsx';
import { PolarisInsightsRealtimeHandler } from './realtime/index.tsx';
import type { PropsExternal } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useInsightsActions, useInsights, useIsInsightSelected } from './main';

export const InsightsContainer = (props: PropsExternal & { isSharedView: boolean }) => {
	const { children, isSharedView, ...rest } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<PolarisIdeasCommonContext.Consumer>
			{({ onUpdateInsight }) => (
				<InsightsContainerInternal
					{...rest}
					onUpdateInsight={onUpdateInsight}
					createAnalyticsEvent={createAnalyticsEvent}
				>
					{!isSharedView && <PolarisInsightsRealtimeHandler issueId={props.issueId} />}
					{children}
				</InsightsContainerInternal>
			)}
		</PolarisIdeasCommonContext.Consumer>
	);
};

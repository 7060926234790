import React from 'react';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { ReactionsField } from '@atlassian/jira-polaris-common/src/ui/fields/reactions/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type Props = {
	fieldKey: FieldKey;
};

export const Reactions = ({ fieldKey }: Props) => {
	const localIssueId = useSelectedIssue();
	const field = useField(fieldKey);
	if (field === undefined || localIssueId === undefined) {
		return null;
	}

	return <ReactionsField fieldKey={fieldKey} localIssueId={localIssueId} />;
};

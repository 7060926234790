import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { getMediaIds } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import type { State } from '../../types.tsx';

const EMPTY = Object.freeze([]);

const getAllComments = (state: State) => state.comments || EMPTY;

export const getAllMediaIds = createSelector(getAllComments, (comments) =>
	flatten(comments.map((comment) => getMediaIds(comment.body))),
);

export const getSortedComments = createSelector(getAllComments, (comments) =>
	sortBy(comments, ({ created }) => new Date(created)),
);

const getCommentsMap = createSelector(getAllComments, (comments) =>
	keyBy(comments, ({ id }) => id),
);

export const createGetComment = (id: string) =>
	createSelector(getCommentsMap, (commentsMap) => commentsMap[id]);

export const getSortedCommentIds = createSelector(getSortedComments, (comments) =>
	comments === undefined ? EMPTY : comments.map(({ id }) => id),
);

import React, { type ReactNode, memo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { ensureNonNullable } from '@atlassian/jira-polaris-lib-ts-utils/src/index.tsx';
import { EMPTY_VALUE_ID } from '../../../constants.tsx';
import type { ExtendedOption, Group, IssuesByGroupIdentity } from '../../../types/common.tsx';
import type { CustomComponents } from '../../../types/custom-components.tsx';
import type {
	CollapsedSwimlanes,
	ColumnsHighlightColors,
	IsCardDropDisabledForColumn,
	OnSwimlaneToggle,
} from '../../../types/ui.tsx';
import { createGroupIdentityPair } from '../../../utils/board.tsx';
import { Cell } from '../cell/main.tsx';
import { DraggableGroupStickyHeader } from './draggable-group-sticky-header/index.tsx';

type BodyRowProps = {
	collapsedSwimlanes: CollapsedSwimlanes;
	columnsHighlightColors: ColumnsHighlightColors;
	components: CustomComponents;
	draggableId: string;
	extendedOptions: ExtendedOption<unknown>[];
	groupIdentity?: string;
	group: Group;
	hideEmptyColumns: boolean;
	hideEmptyGroups: boolean;
	idsByColumn: IssuesByGroupIdentity;
	index: number;
	isFooterVisible: boolean;
	isLastGroup: boolean;
	isReadOnly: boolean;
	verticalFieldValue: unknown;
	isCardDropDisabledForColumn: IsCardDropDisabledForColumn;
	onSwimlaneToggle: OnSwimlaneToggle;
};

const BodyRow = memo((props: BodyRowProps) => {
	const {
		collapsedSwimlanes,
		columnsHighlightColors,
		components,
		draggableId,
		extendedOptions,
		groupIdentity,
		group,
		hideEmptyColumns,
		hideEmptyGroups,
		idsByColumn,
		index,
		isFooterVisible,
		isLastGroup,
		isReadOnly,
		verticalFieldValue,
		isCardDropDisabledForColumn,
		onSwimlaneToggle,
	} = props;
	const columnsCount = hideEmptyColumns ? group.columns.length : extendedOptions.length;

	const cardsCountInGroup = group.columns.reduce((sum, col) => sum + col.cards.length, 0);
	const isExpandedGroup = collapsedSwimlanes[String(groupIdentity)] === undefined;

	const isVisible = isExpandedGroup
		? cardsCountInGroup > 0
		: collapsedSwimlanes[String(groupIdentity)] === false;

	const isEmptyGroup = cardsCountInGroup === 0;
	const isHiddenGroup = hideEmptyGroups && isEmptyGroup;

	return (
		<Box xcss={[rowContainerStyles, isHiddenGroup && rowContainerHiddenStyles]}>
			<DraggableGroupStickyHeader
				columnsCount={columnsCount}
				components={components}
				draggableId={`row-${draggableId}`}
				groupIdentity={groupIdentity}
				index={index}
				isLastGroup={isLastGroup}
				isReadOnly={isReadOnly}
				isVisible={isVisible}
				localIssuesCount={cardsCountInGroup}
				onSwimlaneToggle={onSwimlaneToggle}
			/>
			{isVisible ? (
				<Box xcss={rowContentContainerStyles}>
					{extendedOptions.map((option, columnIndex) => {
						const columnInRow = group.columns.find(
							(column) => column.columnUid === option.groupIdentity || EMPTY_VALUE_ID,
						);
						const ideaIds = columnInRow?.cards.map((card) => card.uid) ?? [];
						const idsInColumn =
							(option.groupIdentity === undefined
								? idsByColumn.empty
								: idsByColumn.groups[option.groupIdentity]) ?? [];

						return (
							<Cell
								key={`${columnIndex}-${option.groupIdentity || ''}`}
								columnsHighlightColors={columnsHighlightColors}
								components={components}
								droppableId={createGroupIdentityPair(draggableId, option.droppableId)}
								fieldValue={option.value}
								groupIdentity={option.groupIdentity}
								hideEmptyColumns={hideEmptyColumns}
								ideaIds={ideaIds}
								index={columnIndex}
								isCardDropDisabled={isReadOnly || isCardDropDisabledForColumn(option.groupIdentity)}
								isEmptyColumn={idsInColumn.length === 0}
								isFooterVisible={isFooterVisible}
								isReadOnly={isReadOnly}
								verticalFieldValue={verticalFieldValue}
								verticalGroupIdentity={groupIdentity}
							/>
						);
					})}
				</Box>
			) : null}
		</Box>
	);
});

BodyRow.displayName = 'SwimlanesBodyRow';

export type SwimlanesBodyProps = {
	children: ReactNode;
	collapsedSwimlanes: Record<string, boolean>;
	columnsHighlightColors: ColumnsHighlightColors;
	components: CustomComponents;
	extendedOptions: ExtendedOption<unknown>[];
	extendedVerticalOptions: ExtendedOption<unknown>[];
	groups: Group[];
	hideEmptyGroups: boolean;
	hideEmptyColumns: boolean;
	idsByColumn: IssuesByGroupIdentity;
	isFooterVisible: boolean;
	isReadOnly: boolean;
	isCardDropDisabledForColumn: IsCardDropDisabledForColumn;
	onSwimlaneToggle: OnSwimlaneToggle;
};

export const SwimlanesBody = memo(
	({
		children,
		collapsedSwimlanes,
		columnsHighlightColors,
		components,
		extendedOptions,
		extendedVerticalOptions,
		groups,
		hideEmptyGroups,
		hideEmptyColumns,
		idsByColumn,
		isFooterVisible,
		isReadOnly,
		isCardDropDisabledForColumn,
		onSwimlaneToggle,
	}: SwimlanesBodyProps) => {
		return (
			<Box xcss={rowsContainerStyles}>
				{children}

				{extendedVerticalOptions.map((option, index) => {
					const group = ensureNonNullable(
						groups.find((g) => g.uid === (option.groupIdentity || EMPTY_VALUE_ID)),
					);

					return (
						<BodyRow
							key={option.droppableId}
							collapsedSwimlanes={collapsedSwimlanes}
							columnsHighlightColors={columnsHighlightColors}
							components={components}
							draggableId={option.droppableId}
							extendedOptions={extendedOptions}
							group={group}
							groupIdentity={option.groupIdentity}
							hideEmptyGroups={hideEmptyGroups}
							hideEmptyColumns={hideEmptyColumns}
							index={index}
							isFooterVisible={isFooterVisible}
							isLastGroup={index === extendedVerticalOptions.length - 1}
							isReadOnly={isReadOnly}
							verticalFieldValue={option.value}
							idsByColumn={idsByColumn}
							isCardDropDisabledForColumn={isCardDropDisabledForColumn}
							onSwimlaneToggle={onSwimlaneToggle}
						/>
					);
				})}
			</Box>
		);
	},
);

SwimlanesBody.displayName = 'SwimlanesBody';

const rowContainerStyles = xcss({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
});

const rowContainerHiddenStyles = xcss({
	display: 'none',
});

const rowContentContainerStyles = xcss({
	width: '100%',
	display: 'flex',
});

const rowsContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 'space.200',
});

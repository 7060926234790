import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { Section } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAvailableGlobalFieldsByKey } from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { ExpressionTypeIn } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/expression-type-in/index.tsx';
import { Rollup } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/rollup/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { isWeightedScoreFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/weighted-score/index.tsx';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import {
	useFieldsArray,
	useIsGlobalCustomField,
	useVisibleFieldsByKey,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useSnippetProviders } from '../../../../../controllers/field/selectors/snippet-providers-hooks.tsx';
import { useSortedSnippetLabels } from '../../../../../controllers/issue/selectors/properties/insights/hooks.tsx';
import { dotStartingDecimalRegex } from '../../common/constants.tsx';
import messages from './messages.tsx';

type FieldContentProps = {
	fieldKey: FieldKey;
	formula?: DynamicFieldFormula;
	readonly: boolean;
	isPreview?: boolean;
	isFieldTooltip?: boolean;
	onUpdateCalculatedField?: (formula: DynamicFieldFormula) => Promise<void>;
};

// When making changes here, please also keep in mind the FormulaContent component for Global Fields
// Bug fixes and updates might need to be made in both places
export const FormulaContent = memo<FieldContentProps>(
	({
		fieldKey,
		formula,
		onUpdateCalculatedField,
		readonly,
		isPreview,
		isFieldTooltip,
	}: FieldContentProps) => {
		const { formatMessage } = useIntl();
		const [fields] = useFieldsArray();
		const availableGlobalFieldsByKey = useAvailableGlobalFieldsByKey();
		const [visibleFieldsByKey] = useVisibleFieldsByKey();
		const { throttledFetchSnippetProviders } = useFieldActions();
		const [snippetProviders] = useSnippetProviders();
		const sortedSnippetLabels = useSortedSnippetLabels();
		const isGlobalFieldInUse = useIsGlobalCustomField(fieldKey);
		const isAvailableGlobalField = Boolean(availableGlobalFieldsByKey[fieldKey]);
		const isGlobalField = fg('polaris_global_formula_preview_input_fix')
			? isGlobalFieldInUse || isAvailableGlobalField
			: isGlobalFieldInUse;

		const handleFormulaChange = useCallback(
			(newFormula: DynamicFieldFormula) => {
				// @ts-expect-error - TS2339 - Property 'expression' does not exist on type 'DynamicFieldFormula.parameters' which is a Union type.
				if (newFormula?.parameters?.expression?.match(dotStartingDecimalRegex)) {
					return;
				}

				if (onUpdateCalculatedField !== undefined) {
					onUpdateCalculatedField(newFormula);
				}
			},
			[onUpdateCalculatedField],
		);
		if (formula === undefined) {
			return null;
		}
		if (formula.template === 'expr') {
			return (
				<RollupContainer>
					<ExpressionTypeIn
						fields={fields}
						onChange={handleFormulaChange}
						thisFieldKey={fieldKey}
						initFormula={formula}
						readonly={readonly}
						isPreview={isPreview}
						onThrottledFetchSnippetProviders={throttledFetchSnippetProviders}
					/>
				</RollupContainer>
			);
		}
		if (
			![
				'num_data_points',
				'num_linked_issues',
				'num_issue_comments',
				'play_agg',
				'linked_issues',
				'external-reference-property',
			].includes(formula.template)
		) {
			return (
				<RollupContainer>
					<SectionFormulaType $lessMargin={readonly}>
						<RollupContainerHeader>{formatMessage(messages.formulaHeader)}</RollupContainerHeader>
						<p>
							{isWeightedScoreFormula(formula)
								? formatMessage(messages.weightedScoreFormula)
								: formatMessage(messages.rollupFormula)}
						</p>
					</SectionFormulaType>
					<Rollup
						onChange={handleFormulaChange}
						fieldsByKey={visibleFieldsByKey}
						availableGlobalFieldsByKey={availableGlobalFieldsByKey}
						thisFieldKey={fieldKey}
						initFormula={formula}
						readonly={readonly}
						snippetProviders={snippetProviders}
						onThrottledFetchSnippetProviders={throttledFetchSnippetProviders}
						sortedSnippetLabels={sortedSnippetLabels}
						displayGlobalFieldIcons
						isGlobalField={fg('polaris_missing_global_formula_dependency_notice') && isGlobalField}
						isFieldTooltip={
							fg('polaris_missing_global_formula_dependency_notice') && isFieldTooltip
						}
						isPreview={fg('polaris_global_formula_preview_input_fix') && isPreview}
					/>
				</RollupContainer>
			);
		}
		return null;
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RollupContainer = styled.div({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RollupContainerHeader = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N100,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& + p': {
		marginTop: token('space.050'),
	},
});

type SectionFormulaPropsType = {
	$lessMargin?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionFormulaType = styled(Section)<SectionFormulaPropsType>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ $lessMargin }) => ($lessMargin ? token('space.100') : token('space.200')),
});

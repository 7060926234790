import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { State } from '../../types.tsx';

export const filterByScope = (field: Field, scope: State['filters']['scope']) => {
	if (scope === undefined) {
		return true;
	}

	if (scope === 'global') {
		return field.global;
	}

	return !field.global;
};

export const filterBySearch = (field: Field, searchQuery: State['filters']['search']) => {
	const normalizedSearchQuery = searchQuery?.toLocaleLowerCase().trim();

	if (!normalizedSearchQuery) {
		return true;
	}

	return field.label.toLocaleLowerCase().includes(normalizedSearchQuery);
};

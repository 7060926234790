import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useIsWeightedField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useSingleSelect } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { DecoratedTag } from '../../../common/decoration/decoration/tag/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	iconOnly?: boolean;
};

export const SingleSelectField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty,
	iconOnly,
}: Props) => {
	const label = useFieldLabel(fieldKey);
	const option = useSingleSelect(fieldKey, issueId)[0];
	const isWeightTagVisible = useIsWeightedField(fieldKey);

	if (hideEmpty && option === undefined) {
		return null;
	}

	if (isCompact) {
		return (
			<CompactSelectFieldValue>
				{option !== undefined && (
					<>
						{isWeightTagVisible && <WeightTag options={[option]} />}
						{isWeightTagVisible && iconOnly ? null : (
							<DecoratedTag
								key={option.id}
								fieldKey={fieldKey}
								id={option.id}
								value={option.value}
								compact={isCompact}
								iconOnly={iconOnly}
							/>
						)}
					</>
				)}
			</CompactSelectFieldValue>
		);
	}

	return (
		<>
			<FieldLabel>{label}</FieldLabel>
			<SelectFieldValue>
				{option !== undefined ? (
					<>
						{isWeightTagVisible && (
							<Box xcss={weightTagContainerStyles}>
								<WeightTag options={[option]} />
							</Box>
						)}
						<DecoratedTag
							key={option.id}
							fieldKey={fieldKey}
							id={option.id}
							value={option.value}
							compact={isCompact}
						/>
					</>
				) : (
					isSummary && <EmptyField />
				)}
			</SelectFieldValue>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectFieldValue = styled(FieldValue)({
	display: 'flex',
	gap: '4px',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactSelectFieldValue = styled(FieldValue)({
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
});

const weightTagContainerStyles = xcss({
	marginLeft: 'space.050',
});

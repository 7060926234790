/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Stack, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import { token } from '@atlaskit/tokens';
import { useConfiguredConnectionFieldsKeys } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useIsIssueMatchingConnectionFieldFilter,
	useSelectedIssuesConnectionFieldIssuesCount,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import {
	useSelectedIssue,
	useSelectedIssueIssueType,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useCanConnectIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { CollapsibleGroup } from './collapsible-group/index.tsx';
import { MAX_VISIBLE_ROWS_COUNT, ROW_HEIGHT } from './constants.tsx';
import { BORDER_COLOR, ROW_BORDER_WIDTH } from './content/constants.tsx';
import { Content } from './content/index.tsx';
import { Header } from './header/index.tsx';
import { useLoadIssuesAndInitialize } from './utils.tsx';

type Props = {
	fieldKey: string;
};

export const ConnectionSection = ({ fieldKey }: Props) => {
	const localIssueId = useSelectedIssue();
	const container = useEnvironmentContainer();
	const selectedIssueIssueType = useSelectedIssueIssueType();
	const collapsibleId =
		`jpd.connections.${container?.id ?? 'unknown-container-id'}.${selectedIssueIssueType?.name || 'unknown-issue-type-name'}.${fieldKey}`.toLowerCase();

	const issuesCount = useSelectedIssuesConnectionFieldIssuesCount(fieldKey);
	const isMatchingConnectionFieldFilter = useIsIssueMatchingConnectionFieldFilter(
		localIssueId,
		fieldKey,
	);
	const canConnectIssues = useCanConnectIssues();
	const bodyHeight =
		(Math.min(issuesCount, MAX_VISIBLE_ROWS_COUNT) + (canConnectIssues ? 2 : 1)) * ROW_HEIGHT; // 1 for header, 1 for footer

	// don't allow connecting issues of the same type
	if (isMatchingConnectionFieldFilter) {
		return null;
	}

	return (
		<CollapsibleGroup
			id={collapsibleId}
			title={<Header fieldKey={fieldKey} issuesCount={issuesCount} />}
		>
			<div
				css={containerStyles}
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
				style={{ height: bodyHeight }}
			>
				<Content fieldKey={fieldKey} />
			</div>
		</CollapsibleGroup>
	);
};

export const ConnectionsList = () => {
	const connectionFieldsKeys = useConfiguredConnectionFieldsKeys();
	const isIssuesInitialized = useLoadIssuesAndInitialize();

	if (!isIssuesInitialized) {
		return (
			<Stack space="space.250" xcss={listContainerStyles}>
				<Skeleton height={28} width="100%" borderRadius={4} />
				<Skeleton height={28} width="100%" borderRadius={4} />
			</Stack>
		);
	}

	return (
		<Stack space="space.250" xcss={listContainerStyles}>
			{connectionFieldsKeys.map((connectionFieldKey) => (
				<ConnectionSection key={connectionFieldKey} fieldKey={connectionFieldKey} />
			))}
		</Stack>
	);
};

const containerStyles = css({
	overflow: 'hidden',
	borderRadius: token('border.radius.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	border: `${ROW_BORDER_WIDTH}px solid ${BORDER_COLOR}`,
});

const listContainerStyles = xcss({
	paddingTop: 'space.100',
});

import React, { useState, useCallback, type ComponentType } from 'react';
import { type ButtonItemProps, Section, ButtonItem } from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ValueDecorationRules,
	ValueDecoration,
	ValueRule,
	LocalDecorationId,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldDecoratorProps } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/types.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks.tsx';
import { useOuterSpacing } from '../../../../../controllers/selectors/config-hooks.tsx';
import {
	useIsGlobalCustomField,
	useFieldType,
} from '../../../../../controllers/selectors/field-hooks.tsx';
import { NumberDecorationRangeInput } from './input/index.tsx';
import { messages } from './messages.tsx';
import { NumberDecorationRangeTag } from './tag/index.tsx';

export const SLIDER_FIELD_MIN_VALUE = 0;
export const SLIDER_FIELD_MAX_VALUE = 100;

type BaseDecoratorItemProps = {
	decoration: ValueDecoration;
	FieldDecorator: ComponentType<FieldDecoratorProps>;
	readonly?: boolean;
};

type DecoratorItemProps = {
	isOpen: boolean;
} & BaseDecoratorItemProps & {
		onOpenChanged: (open: boolean) => void;
	};

export const DecoratorNumberItem = ({
	onOpenChanged,
	isOpen,
	decoration,
	FieldDecorator,
	readonly = false,
}: DecoratorItemProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const fieldType = useFieldType();
	const isGlobalCustomField = useIsGlobalCustomField();
	const [currentColor, setCurrentColor] = useState(decoration.backgroundColor);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration.emoji);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const initialRulesState = (decoration as ValueDecorationRules).rules;
	const [currentRules, setCurrentRules] = useState(initialRulesState);

	const { onValueDecorationUpdated, onValueDecorationDeleted } = useCallbacks();
	const outerSpacing = useOuterSpacing();

	const onValueDecorationChanged = useCallback(
		async ({
			backgroundColor,
			emoji,
			highlightContainer,
			rules,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
			rules: ValueRule[];
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: 'update',
				backgroundColor,
				emoji,
				highlightContainer,
				isGlobalCustomField,
			});

			if (onValueDecorationUpdated !== undefined) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				await onValueDecorationUpdated({
					localDecorationId: decoration.localDecorationId,
					backgroundColor,
					emoji,
					highlightContainer,
					rules,
				} as ValueDecoration);
			}
		},
		[
			createAnalyticsEvent,
			decoration.localDecorationId,
			isGlobalCustomField,
			onValueDecorationUpdated,
		],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			setCurrentColor(backgroundColor);

			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: !!decoration.highlightContainer,
				rules: currentRules,
			});
		},
		[
			createAnalyticsEvent,
			currentEmojiId,
			decoration.highlightContainer,
			onValueDecorationChanged,
			currentRules,
		],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			setCurrentEmojiId(emoji);

			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: !!decoration.highlightContainer,
				rules: currentRules,
			});
		},
		[
			createAnalyticsEvent,
			currentColor,
			decoration.highlightContainer,
			onValueDecorationChanged,
			currentRules,
		],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
				rules: currentRules,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged, currentRules],
	);

	const onRulesChanged = useCallback(
		(newRules: ValueRule[]) => {
			setCurrentRules(newRules);

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer: !!decoration.highlightContainer,
				rules: newRules,
			});
		},
		[currentColor, currentEmojiId, decoration.highlightContainer, onValueDecorationChanged],
	);

	const onDecorationDeleted = useCallback(
		(localDecorationId: LocalDecorationId) => {
			onValueDecorationDeleted && onValueDecorationDeleted({ localDecorationId });
		},
		[onValueDecorationDeleted],
	);

	const onToggleEdit = (isEditActive: boolean) => {
		onOpenChanged(isEditActive);
	};

	// Styles for section items
	const cssFn: ButtonItemProps['cssFn'] = () => ({
		padding: `${token('space.075', '6px')} ${token('space.200', '16px')}`,
		backgroundColor: 'transparent',
		color: token('color.text', colors.N800),
		'&:hover': {
			color: token('color.text', colors.N800),
			backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20),
		},
	});

	const isSlider = fieldType === FIELD_TYPES.SLIDER;
	const minLimit = isSlider ? SLIDER_FIELD_MIN_VALUE : undefined;
	const maxLimit = isSlider ? SLIDER_FIELD_MAX_VALUE : undefined;

	const content = (
		<>
			<FireUiAnalytics
				actionSubject="fieldDecorationMenu"
				action="viewed"
				actionSubjectId="viewControls"
			/>
			<FieldDecorator
				isNameEditable
				isHighlighted={!!decoration.highlightContainer}
				color={currentColor}
				emojiId={currentEmojiId}
				inputNode={
					<NumberDecorationRangeInput
						rules={currentRules}
						onRulesChanged={onRulesChanged}
						minLimit={minLimit}
						maxLimit={maxLimit}
					/>
				}
				value=""
				onColorChanged={onColorChanged}
				onHighlightingChanged={onHighlightContainerChanged}
				onEmojiSelected={onEmojiChanged}
				outerSpacing={outerSpacing}
			/>
		</>
	);

	return (
		<>
			<MenuOption
				menuComponent={
					<Section>
						<ButtonItem
							testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.edit"
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={() => {
								onToggleEdit(!isOpen);
							}}
						>
							{formatMessage(messages.editRule)}
						</ButtonItem>
						<ButtonItem
							testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.delete"
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={() => onDecorationDeleted(decoration.localDecorationId)}
						>
							{formatMessage(messages.deleteRule)}
						</ButtonItem>
					</Section>
				}
				isActive={isOpen}
				isDragEnabled={false}
				setOpenOption={() => onToggleEdit(false)}
				hideActionMenu={readonly}
				outerSpacing={outerSpacing}
			>
				<NumberDecorationRangeTag
					rules={currentRules}
					color={currentColor}
					emojiId={currentEmojiId}
				/>
			</MenuOption>
			{isOpen && content}
		</>
	);
};

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { components, type ControlProps } from '@atlaskit/react-select';
import Tag from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import type { ConnectionFieldOption } from '../../types.tsx';
import { isGroup } from '../../utils.tsx';
import messages from './messages.tsx';

export const ConnectionFieldControl = ({
	children,
	options,
	...rest
}: ControlProps<ConnectionFieldOption, true>) => {
	const { formatMessage } = useIntl();
	const firstOption = options[0];
	const optionsCount = isGroup(firstOption) ? firstOption.options.length : options.length;

	return (
		<components.Control {...rest} options={options}>
			{optionsCount > 0 && (
				<Box onClick={rest.clearValue} xcss={tagWrapperStyles}>
					<Tag
						text={formatMessage(messages.optionsCount, {
							count: optionsCount,
						})}
						removeButtonLabel={formatMessage(messages.removeButtonLabel)}
						color="greyLight"
					/>
				</Box>
			)}
			{children}
		</components.Control>
	);
};

const tagWrapperStyles = xcss({
	color: 'color.text.subtle',
	flexShrink: 1,
	minWidth: '0px',
	overflow: 'hidden',
	paddingLeft: 'space.025',
});

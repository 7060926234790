import { createSelector } from 'reselect';
import type { AxisValue, ItemId } from '../../types.tsx';
import type { HoveredItem, State } from '../types.tsx';

export const getHoveredItems = (state: State): HoveredItem | undefined => state.hoveredItems;
export const getHoveredItemsArea = (state: State): string | undefined => state.hoveredItems?.area;
export const getHoveredItemIdsMap = createSelector(getHoveredItems, (hoveredItems) =>
	hoveredItems?.items.reduce(
		(result, item) =>
			Object.assign(result, {
				[item]: true,
			}),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as { [key: ItemId]: boolean },
	),
);
export const getHighlightedItem = (state: State): ItemId | undefined => state.highlightedItem;
export const getSelectedItems = (state: State): ItemId[] => state.selectedItems;
export const getDragX = (state: State): AxisValue | undefined => state.dragValue?.x;
export const getDragY = (state: State): AxisValue | undefined => state.dragValue?.y;
export const getDragValue = (state: State) => state.dragValue;

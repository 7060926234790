import gqlTagPolaris from 'graphql-tag';
import type { ViewDescription } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewNotFoundError } from '../../../common/errors/view-not-found-error.tsx';
import type { jira_polaris_GetViewDescriptionQuery as GetViewDescriptionQuery } from './__generated_apollo__/jira_polaris_GetViewDescriptionQuery';

export const GET_VIEW_DESCRIPTION_QUERY = gqlTagPolaris`
query jira_polaris_GetViewDescriptionQuery($view: ID!) {
    polarisView(id: $view) {
        id
        description
    }
  }

`;

export type DescriptionStream = {
	subject: string;
	description: ViewDescription;
};

export const fetchViewDescription = async (
	apolloClient: PolarisApolloClient,
	viewId: string,
): Promise<DescriptionStream> => {
	const result = await apolloClient.query<GetViewDescriptionQuery>({
		query: GET_VIEW_DESCRIPTION_QUERY,
		variables: {
			view: viewId,
		},
		fetchPolicy: 'network-only',
	});

	const { data, errors } = result;
	if (errors !== undefined) {
		throw new Error(`description-stream.fetch-error:${errors.map((e) => e.message).join(', ')}`);
	}

	if (!data || data.polarisView === undefined || data.polarisView === null) {
		throw new ViewNotFoundError('view-description.fetch-error: no data or no data node');
	} else {
		return {
			subject: viewId,
			description: data.polarisView.description,
		};
	}
};

import React, { useState, useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';
import { IconButton } from '@atlaskit/button/new';
import DownloadButton from '@atlaskit/icon/glyph/download';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import {
	OutsideClickAlerter,
	isFocusOutsideJFE,
} from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ExportDialogContent } from './export-dialog-content/index.tsx';
import messages from './messages.tsx';

export const ExportDialog = () => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();

	const handleTogglePopup = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		if (!dialogOpen) {
			fireUIAnalytics(analyticsEvent, 'exportModal');
		}
		setDialogOpen(!dialogOpen);
	};

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (isFocusOutsideJFE(event?.target)) {
				return;
			}
			!isLoading && setDialogOpen(false);
		},
		[isLoading, setDialogOpen],
	);

	const getTriggerComponent = (triggerProps: TriggerProps) => (
		<IconButton
			id="pendo.export-modal.open-button"
			testId="polaris-ideas.ui.view-header.export-dialog.button"
			{...triggerProps}
			label={formatMessage(messages.exportButtonLabel)}
			appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
			isSelected={dialogOpen}
			onClick={handleTogglePopup}
			icon={DownloadButton}
			isTooltipDisabled={false}
		/>
	);

	return (
		<>
			{isLoading && <Blanket isTinted />}
			<OutsideClickAlerter onClickOutside={handleClickOutside}>
				{(outsideClickAlerterProps) => (
					<div {...outsideClickAlerterProps}>
						<Popup
							isOpen={dialogOpen}
							placement="bottom-end"
							content={() => (
								<ContextualAnalyticsData sourceName="export" sourceType={MODAL}>
									<ExportDialogContent isLoading={isLoading} onExporting={setIsLoading} />
								</ContextualAnalyticsData>
							)}
							zIndex={501} // zIndex of exporting blanked is 500
							trigger={getTriggerComponent}
						/>
					</div>
				)}
			</OutsideClickAlerter>
		</>
	);
};

import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';

import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { IssueSelect } from '../common/issue-select/main.tsx';
import type { IssueOption } from '../common/issue-select/types.tsx';
import { useDefaultOptions, useExcludedProjectTypes } from '../common/issue-select/utils.tsx';
import { messages } from './messages.tsx';
import { useExcludedIssueIds } from './utils.tsx';

type Props = {
	isDisabled: boolean;
	onIssueSelected: (arg1: IssueOption | undefined) => void;
	onCreateStart: () => void;
	onCreateEnd: () => void;
};

export const CreatableIdeaSelect = ({
	isDisabled,
	onIssueSelected,
	onCreateStart,
	onCreateEnd,
}: Props) => {
	const { formatMessage } = useIntl();
	const defaultOptions = useDefaultOptions();
	const excludedProjectTypes = useExcludedProjectTypes();
	const excludedIssueIds = useExcludedIssueIds();
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;

	return (
		<IssueSelect
			isAttachedToBody
			placeholder={formatMessage(messages.issueSelectPlaceholder)}
			isDisabled={isDisabled}
			autoFocus
			projectId={fg('jpd_extracted_issue_type_store') ? projectId : undefined}
			excludedProjectTypes={excludedProjectTypes}
			defaultOptions={defaultOptions}
			hideArchived={!fg('jpd-disable-hiding-archived-ideas-in-issue-picker')}
			excludedIssueIds={excludedIssueIds}
			onIssueSelected={onIssueSelected}
			createOption={{
				onCreateStart,
				onCreateEnd,
			}}
		/>
	);
};

import React, { useCallback, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { BLUR } from '@atlassian/jira-polaris-lib-create-issue-input/src/common/constants.tsx';
import type {
	CreateMetadata,
	CreatePayload,
} from '@atlassian/jira-polaris-lib-create-issue-input/src/common/types.tsx';
import { CreateIssueInput } from '@atlassian/jira-polaris-lib-create-issue-input/src/ui/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCurrentViewFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import type { FieldValueFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useIssueTypesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import {
	ProjectIdProvider,
	useProjectId,
} from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/project-id-provider.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR } from '../../constants.tsx';
import messages from './messages.tsx';

type Props = {
	enforcedIssueTypeId?: IssueTypeId;
	onCreate: (issueTypeId: IssueTypeId, summary: string, createdByBlur: boolean) => void;
	onCancel: () => void;
};

const shouldPreventCreationOnBlur = (element: Element | null) =>
	element?.getAttribute('data-component-selector') === ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR;

const useFirstAvailableIssueTypeId = (): IssueTypeId | undefined => {
	const currentViewFilters = useCurrentViewFilter();
	const projectId = useProjectId();
	const projectIssueTypes = useIssueTypesForProject({ projectId });

	const issueTypeFilter = currentViewFilters.find(
		(filter): filter is FieldValueFilter =>
			filter.type === 'FIELD' &&
			filter.field === FIELD_TYPES.ISSUE_TYPE &&
			filter.values.length > 0,
	);

	return issueTypeFilter?.values[0].stringValue ?? projectIssueTypes[0]?.id;
};

const IssueCreateInput = ({ enforcedIssueTypeId, onCreate, onCancel }: Props) => {
	const { formatMessage } = useIntl();

	const firstAvailableIssueTypeId = useFirstAvailableIssueTypeId();

	const issueTypeId = enforcedIssueTypeId ?? firstAvailableIssueTypeId;

	const handleCreate = useCallback(
		({ summary }: CreatePayload, meta: CreateMetadata) => {
			if (issueTypeId === undefined) {
				throw new Error('Attempt to create a new issue without issueTypeId');
			}
			onCreate(issueTypeId, summary, meta.createdBy === BLUR);
		},
		[issueTypeId, onCreate],
	);

	useEffect(() => {
		experience.listView.createIdeaInlineClickResponse.success();
	}, []);

	return (
		<div data-testid="polaris-ideas.ui.view-content.idea-list.row.inline-issue-create.div">
			<CreateIssueInput
				placeholder={formatMessage(messages.placeholderNonFinal)}
				onCancel={onCancel}
				onCreate={handleCreate}
				validationMessageType="icon"
				preventAutoFocusScroll
				shouldPreventCreationOnBlur={shouldPreventCreationOnBlur}
			/>
		</div>
	);
};

export const InlineIssueCreate = (props: Props) => {
	const container = useEnvironmentContainer();

	if (container?.type !== PolarisEnvironmentContainerTypes.PROJECT) {
		return null;
	}

	return (
		<ProjectIdProvider projectId={container.projectId}>
			<IssueCreateInput {...props} />
		</ProjectIdProvider>
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	absoluteDatesStart: {
		id: 'polaris-common.filters.filter-component.interval.button-label.absolute-dates-start',
		defaultMessage: 'Start date is {startDate}',
		description: 'Text displayed in the Select when only Start date is defined',
	},
	absoluteDatesEnd: {
		id: 'polaris-common.filters.filter-component.interval.button-label.absolute-dates-end',
		defaultMessage: 'End date is {endDate}',
		description: 'Text displayed in the Select when only End date is defined',
	},
	absoluteDatesStartEnd: {
		id: 'polaris-common.filters.filter-component.interval.button-label.absolute-dates-start-end',
		defaultMessage: 'Start date is {startDate} & End date is {endDate}',
		description: 'Text displayed in the Select when both Start date & End date are defined',
	},
	rollingDatesCurrent: {
		id: 'polaris-common.filters.filter-component.interval.button-label.rolling-dates-current',
		defaultMessage: '{intervalDateType} is this {period}',
		description:
			'Text displayed for currently applied filter when Rolling Dates Start date & End date are defined',
	},
	rollingDatesPastNext: {
		id: 'polaris-common.filters.filter-component.interval.button-label.rolling-dates-past-next',
		defaultMessage: '{intervalDateType} is within {time} {count} {period}',
		description:
			'Text displayed for currently applied filter when Rolling Dates Start date & End date are defined',
	},
	intervalDateTypeStartDate: {
		id: 'polaris-common.filters.filter-component.interval.button-label.interval-date-type-start-date',
		defaultMessage: 'Start date',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	intervalDateTypeEndDate: {
		id: 'polaris-common.filters.filter-component.interval.button-label.interval-date-type-end-date',
		defaultMessage: 'End date',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	timeCurrent: {
		id: 'polaris-common.filters.filter-component.interval.button-label.time-current',
		defaultMessage: 'current',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	timePast: {
		id: 'polaris-common.filters.filter-component.interval.button-label.time-past',
		defaultMessage: 'past',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	timeNext: {
		id: 'polaris-common.filters.filter-component.interval.button-label.time-next',
		defaultMessage: 'next',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	periodDay: {
		id: 'polaris-common.filters.filter-component.interval.button-label.period-day',
		defaultMessage: '{count, plural, =0 {days} one {day} other {days}}',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	periodWeek: {
		id: 'polaris-common.filters.filter-component.interval.button-label.period-week',
		defaultMessage: '{count, plural, =0 {weeks} one {week} other {weeks}}',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	periodMonth: {
		id: 'polaris-common.filters.filter-component.interval.button-label.period-month',
		defaultMessage: '{count, plural, =0 {months} one {month} other {months}}',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	periodYear: {
		id: 'polaris-common.filters.filter-component.interval.button-label.period-year',
		defaultMessage: '{count, plural, =0 {years} one {year} other {years}}',
		description: 'Text displayed for currently applied filter when Rolling Dates are used',
	},
	isEmpty: {
		id: 'polaris-common.filters.filter-component.interval.button-label.is-empty',
		defaultMessage: 'is empty',
		description: 'Text displayed for currently applied filter',
	},
	isNotEmpty: {
		id: 'polaris-common.filters.filter-component.interval.button-label.is-not-empty',
		defaultMessage: 'is not empty',
		description: 'Text displayed for currently applied filter',
	},
});

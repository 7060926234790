import React, { type ChangeEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Checkbox } from '@atlaskit/checkbox';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useInsightsActions,
	useIsInsightSelected,
} from '../../../../../controllers/insights/main.tsx';
import { useInsight } from '../../../../../controllers/insights/selectors/insights-hooks.tsx';

type SelectionProps = {
	isDisabled?: boolean;
	insightId: string;
};

export const Selection = ({ insightId, isDisabled = false }: SelectionProps) => {
	const insight = useInsight(insightId);

	const isSelected = useIsInsightSelected(insight?.id);
	const { toogleInsightSeletion } = useInsightsActions();
	const { id } = insight || {};

	const handleToogleInsightsSelection = useCallback(
		(event: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (id) {
				fireUIAnalytics(analyticsEvent, 'insight', {
					checkboxChecked: event.target.checked,
				});
				toogleInsightSeletion(id);
			}
		},
		[id, toogleInsightSeletion],
	);

	if (insight === undefined) {
		return null;
	}

	return (
		<div data-testid="polaris-ideas.ui.insights.insights.insight.selection.selection">
			{insight.account?.accountId && (
				<SelectionWrapper onClick={(e) => e.stopPropagation()}>
					<Checkbox
						testId="polaris-ideas.ui.insights.insights.insight.selection.checkbox"
						isChecked={isSelected}
						isDisabled={isDisabled}
						onChange={handleToogleInsightsSelection}
						onClick={(e) => e.stopPropagation()}
					/>
				</SelectionWrapper>
			)}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectionWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '10px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '-1px',
	width: '24px',
});

import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { amplitudeIcon } from '../../../../common/ui/apps/index.tsx';
import { UnfurlDialog } from '../../unfurl-dialog/index.tsx';
import type { AppPreset } from '../types.tsx';
import messages from './messages.tsx';

const UnfurlAction = () => {
	const { formatMessage } = useIntl();
	return (
		<UnfurlDialog
			title={formatMessage(messages.unfurlDialogTitle)}
			placeholder={formatMessage(messages.unfurlDialogPlaceholder)}
			successMessage={formatMessage(messages.unfurlDialogSuccess)}
		/>
	);
};

export const useAmplitudePreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: 'amplitude',
			title: formatMessage(messages.titleInsights),
			description: formatMessage(messages.descriptionInsights),
			icon: amplitudeIcon,
			isRecommended: false,
			Action: UnfurlAction,
		}),
		[formatMessage],
	);
};

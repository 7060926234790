import React from 'react';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { ProjectField as ProjectFieldComponent } from '@atlassian/jira-polaris-common/src/ui/fields/project/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';

export const ProjectField = () => {
	const localIssueId = useSelectedIssue();
	const project = useProjectForIssue(localIssueId);
	const projectMeta = useProjectMetadata({ projectId: project?.id });

	return <ProjectFieldComponent project={projectMeta} />;
};

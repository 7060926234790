import { useCallback, useEffect } from 'react';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isNotFoundError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

export function createUseUserProperty<T>(key: string) {
	let userPropertyPromise: Promise<T | undefined>;

	return () => {
		const accountId = useAccountId();

		useEffect(() => {
			if (!accountId || userPropertyPromise !== undefined) {
				return;
			}

			userPropertyPromise = getUserProperty<T>(accountId, key).catch((error) => {
				if (!isNotFoundError(error)) {
					fireErrorAnalytics(
						createErrorAnalytics(`polaris.error.user-property.${key}.fetch`, error),
					);
				}

				return undefined;
			});
		}, [accountId]);

		const setProperty = useCallback(
			(value: T) => {
				if (!accountId) {
					return;
				}

				return setUserProperties(accountId, key, JSON.stringify(value))
					.then(() => {
						userPropertyPromise = Promise.resolve(value);
					})
					.catch((error) => {
						fireErrorAnalytics(
							createErrorAnalytics(`polaris.error.user-property.${key}.set`, error),
						);
					});
			},
			[accountId],
		);

		const getProperty = useCallback(() => {
			if (userPropertyPromise === undefined) {
				return Promise.resolve(undefined);
			}

			return userPropertyPromise;
		}, []);

		return [getProperty, setProperty] as const;
	};
}

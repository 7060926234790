import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	SingleSelectField,
	MultiSelectField,
} from '@atlassian/jira-polaris-common/src/ui/fields/select/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	OptionProperty,
	LocalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import messages from '../datetime/messages.tsx';

export type FieldProps = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	menuPortalTarget?: HTMLElement;
	onConfigRequested: (optionId?: string) => void;
};

export const SingleSelect = ({
	fieldKey,
	localIssueId,
	onConfigRequested,
	menuPortalTarget,
}: FieldProps) => {
	const { formatMessage } = useIntl();
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();
	const field = useField(fieldKey);

	const onUpdate = useCallback(
		(newValue?: OptionProperty | OptionProperty[]) => {
			updateFieldValue({
				fieldKey,
				localIssueIds: [localIssueId],
				newValue,
			});
		},
		[fieldKey, localIssueId, updateFieldValue],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<SingleSelectField
			isActive
			isEditable={canEditIssues && field.editable}
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			onUpdate={onUpdate}
			onConfigRequested={onConfigRequested}
			placeholder={formatMessage(messages.noValue)}
			menuPortalTarget={menuPortalTarget}
		/>
	);
};

export const MultiSelect = ({
	fieldKey,
	localIssueId,
	onConfigRequested,
	menuPortalTarget,
}: FieldProps) => {
	const { formatMessage } = useIntl();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	const onUpdate = useCallback(
		(newValue?: OptionProperty | OptionProperty[]) => {
			updateFieldValue({
				fieldKey,
				localIssueIds: [localIssueId],
				newValue,
			});
		},
		[fieldKey, localIssueId, updateFieldValue],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<MultiSelectField
			isActive
			isEditable={canEditIssues && field.editable}
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			onUpdate={onUpdate}
			onConfigRequested={onConfigRequested}
			placeholder={formatMessage(messages.noValue)}
			menuPortalTarget={menuPortalTarget}
		/>
	);
};

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useProjectForIssue } from '../../../../controllers/issue/selectors/properties/project/hooks.tsx';
import { ProjectField as CommonProjectField } from '../../../fields/project/index.tsx';

type ProjectFieldProps = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	hideEmpty?: boolean;
	isCompact?: boolean;
};

export const ProjectField = ({
	issueId,
	fieldKey,
	hideEmpty = false,
	isCompact = false,
}: ProjectFieldProps) => {
	const project = useProjectForIssue(issueId);

	const projectId = project?.id;

	const projectMeta = useProjectMetadata({ projectId });
	const label = useFieldLabel(fieldKey);

	if (projectMeta === undefined && hideEmpty) {
		return null;
	}

	if (isCompact) {
		return (
			<Box xcss={[compactProjectFieldValueCss]}>
				<CommonProjectField project={projectMeta} />
			</Box>
		);
	}

	return (
		<>
			<Box xcss={[fieldLabelCss]}>{label}</Box>
			<Box xcss={[projectFieldValueCss]}>
				<CommonProjectField project={projectMeta} />
			</Box>
		</>
	);
};

const fieldLabelCss = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	color: 'color.text.subtlest',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const projectFieldValueCss = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	color: 'color.text',
	lineHeight: '14px',
	minWidth: '0',
	maxWidth: '100%',
	display: 'flex',
	gap: 'space.050',
	alignItems: 'center',
});

const compactProjectFieldValueCss = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	color: 'color.text',
	lineHeight: '14px',
	minWidth: '0',
	maxWidth: '100%',
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
});

import React from 'react';
import { useFieldPlay } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { PlayField as CommonPlayField } from '@atlassian/jira-polaris-common/src/ui/fields/play/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type PlayFieldProps = {
	appearance: 'list' | 'board';
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const PlayField = ({ appearance, localIssueId, fieldKey }: PlayFieldProps) => {
	const fieldPlay = useFieldPlay(fieldKey);

	return fieldPlay !== undefined ? (
		<CommonPlayField localIssueId={localIssueId} appearance={appearance} playId={fieldPlay.id} />
	) : null;
};

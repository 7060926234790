import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.board-view.title',
		defaultMessage: 'Board view uses global fields',
		description: 'Title of the section message for roadmap board view fields onboarding',
	},
	body: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.board-view.body',
		defaultMessage: 'Create global fields to show ideas in the board view.',
		description: 'Body of the section message for roadmap board view fields onboarding',
	},
});

import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import trim from 'lodash/trim';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_FIELD_LENGTH } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { CustomTooltip } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/tooltip/index.tsx';
import { PopperElement } from '../../../../../utils/popper-element/index.tsx';
import { messages } from './messages.tsx';
import type { Props } from './types.tsx';

export const ShortTextInput = ({ value, placeholder, onCancel, onConfirm }: Props) => {
	const { formatMessage } = useIntl();

	const initialValue = value !== undefined && typeof value === 'string' ? value : undefined;

	const onConfirmInput = useCallback(
		(newValue: string) => {
			const valueForUpdate = trim(newValue) === '' ? undefined : newValue;

			if (valueForUpdate !== undefined) {
				onConfirm(valueForUpdate);
				onCancel();
			}
		},
		[onCancel, onConfirm],
	);

	const linkFieldValidation = useCallback(
		(val?: string) => {
			if (val !== undefined && val.length > MAX_FIELD_LENGTH) {
				return formatMessage(messages.shortTextFieldTypeError);
			}
			return undefined;
		},
		[formatMessage],
	);

	return (
		<PopperElement>
			<Box xcss={inlineEditableTextFieldContainerStyles}>
				<InlineEdit
					defaultValue={initialValue}
					isEditing
					validate={linkFieldValidation}
					editView={({ errorMessage, ...fieldProps }) => (
						<Textfield
							{...fieldProps}
							testId="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.edit.short-text.inline-editable-textfield"
							autoFocus
							placeholder={placeholder || ''}
							elemAfterInput={
								fieldProps.isInvalid && (
									<Tooltip component={CustomTooltip} content={errorMessage} position="right">
										<ErrorIconContainer>
											<ErrorIcon
												color={token('color.icon.danger')}
												label={formatMessage(messages.errorIcon)}
												LEGACY_primaryColor={colors.R400}
												spacing="spacious"
											/>
										</ErrorIconContainer>
									</Tooltip>
								)
							}
						/>
					)}
					readView={() => initialValue}
					onConfirm={onConfirmInput}
					onCancel={onCancel}
				/>
			</Box>
		</PopperElement>
	);
};

const inlineEditableTextFieldContainerStyles = xcss({
	width: '100%',
	marginTop: 'space.negative.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconContainer = styled.div({
	cursor: 'pointer',
	marginBottom: token('space.negative.025', '-2px'),
	marginRight: token('space.025', '2px'),
});

import React, { type PropsWithChildren } from 'react';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { xcss, Box, Anchor, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLink } from '../../../common/utils/issue-link/index.tsx';
import messages from './messages.tsx';

type props = {
	restItemsAmount: number;
	issueKey: string;
};

export const ExtraItemsSummary = ({ issueKey, restItemsAmount }: props) => {
	const { formatMessage } = useIntl();
	return (
		<LinkItem issueKey={issueKey}>
			<Inline grow="fill" alignBlock="center" space="space.200">
				<ChevronRightIcon label={formatMessage(messages.seeMore)} />

				<Box xcss={labelStyles}> {formatMessage(messages.more, { restItemsAmount })}</Box>
			</Inline>
		</LinkItem>
	);
};

const LinkItem = ({ issueKey, children }: PropsWithChildren<{ issueKey: string }>) => {
	const { link, target, onClick } = useIssueLink({
		issueKey,
		shouldOpenInSidebar: true,
		issueViewSection: 'connections',
	});

	return (
		<Anchor onClick={onClick} xcss={linkStyles} href={link} target={target}>
			{children}
		</Anchor>
	);
};

const labelStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: 'font.body',
	color: 'color.text',
});

const linkStyles = xcss({
	font: 'font.body',
	color: 'color.text',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'center',
	// Block padding here needs to be in sync with the LIST_ITEM_HEIGHT constant in src/packages/polaris/apps/common/src/ui/connection/connection-list/constants.tsx
	paddingBlock: 'space.100',
	paddingRight: 'space.200',
	paddingLeft: 'space.150',
	borderTop: `1px solid ${token('color.border')}`,

	':hover': {
		color: 'color.text',
	},
});

import React, { useCallback } from 'react';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getIssueLink } from '../../../common/utils/issue-link/index.tsx';
import type { TargetIssueMerged } from '../../../controllers/merge-ideas-dialog/types.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import { useArchiveViewId, useViewSlug } from '../../../controllers/views/selectors/view-hooks.tsx';
import { ViewSectionTypeViews } from '../../../controllers/views/types.tsx';
import messages from './messages.tsx';

export const useMergeNotifications = () => {
	const { success, errorWithRefresh } = useNotifications();
	const { formatMessage } = useIntl();
	const { routeTo } = usePolarisRouter();

	const archiveViewId = useArchiveViewId();
	const slug = useViewSlug(archiveViewId);

	const onSuccess = useCallback(
		(targetIssue: TargetIssueMerged) => {
			success({
				title: formatMessage(messages.notificationTitle),
				description: (
					<>
						<FireUiAnalytics
							action="viewed"
							actionSubject="flag"
							actionSubjectId="confirmIdeasMerged"
						/>
						{formatMessage(messages.notificationDescriptionPrefix)}
						<a href={getIssueLink(targetIssue.projectKey, targetIssue.key)} target="_blank">
							{targetIssue.summary}
						</a>
						{formatMessage(messages.notificationDescriptionSuffix)}
					</>
				),
				actions: [
					{
						content: formatMessage(messages.notificationViewArchiveButton),
						onClick: () => {
							routeTo({
								section: ViewSectionTypeViews,
								resource: slug,
							});
						},
					},
				],
			});
		},
		[formatMessage, routeTo, slug, success],
	);

	const onFailure = useCallback(
		(error: Error) => {
			errorWithRefresh({
				title: formatMessage(messages.notificationErrorTitle),
				description: formatMessage(messages.notificationErrorDescription, {
					error,
				}),
			});
		},
		[errorWithRefresh, formatMessage],
	);

	return { onSuccess, onFailure };
};

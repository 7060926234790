import React, { useState, useEffect } from 'react';
import Avatar from '@atlaskit/avatar';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useAllUserInformationOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/users-hooks.tsx';
import type { UserInformation } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/users.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const UserFieldValue = ({ groupIdentity }: FieldValueComponentProps) => {
	const issueUserInfos = useAllUserInformationOnIssues();
	const [userInfo, setUserInfo] = useState<UserInformation[0]>();

	useEffect(() => {
		const userByGroupIdentity = issueUserInfos[groupIdentity];
		if (userByGroupIdentity !== undefined) {
			setUserInfo(userByGroupIdentity);
		}
	}, [groupIdentity, issueUserInfos]);

	const avatarUrl = userInfo?.avatarUrls?.['24x24'] ?? '';
	const displayName = userInfo?.displayName ?? '';

	return (
		<Flex xcss={containerStyles}>
			<Avatar
				testId="polaris-ideas.ui.view-controls.common.field-values.users.avatar"
				appearance="circle"
				src={avatarUrl}
				size="small"
			/>
			<Box paddingInlineStart="space.100">{displayName}</Box>
		</Flex>
	);
};

const EmptyUserFieldValue = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={containerStyles}>
			<Avatar
				testId="polaris-ideas.ui.view-controls.common.field-values.users.avatar"
				appearance="circle"
				size="small"
			/>
			<Box paddingInlineStart="space.100">{formatMessage(messages.emptyFieldValue)}</Box>
		</Flex>
	);
};

export const userFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: UserFieldValue,
	EmptyComponent: EmptyUserFieldValue,
} as const;

const containerStyles = xcss({
	alignItems: 'center',
	maxWidth: 'fit-content',
	paddingBlock: 'space.025',
	paddingInlineStart: 'space.025',
});

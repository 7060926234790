import type { Action } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

export const setIsExportingViewImage =
	(isExportingViewImage: boolean): Action<State> =>
	({ setState }) => {
		setState({
			isExportingViewImage,
		});
	};

export const setIsReadyToExportViewImage =
	(isReadyToExportViewImage: boolean): Action<State> =>
	({ setState }) => {
		setState({
			isReadyToExportViewImage,
		});
	};

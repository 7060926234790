import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { FULL_SNIPPET_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/snippet/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { RefreshSnippet, RefreshSyncSnippet, RefreshSnippetSync } from './types.tsx';

const SYNC_REFRESH_SNIPPET_QUERY = gqlTagPolaris`
mutation jira_polaris_RefreshSnippetSynchronous($input: RefreshPolarisSnippetsInput!) {
    refreshPolarisSnippets(input: $input) {
        success
        errors {
            message
        }
        node {
            refreshedSnippets {
                ...Snippet
            }
        } 
    }
}

${FULL_SNIPPET_FRAGMENT}
`;

const ASYNC_REFRESH_SNIPPET_QUERY = gqlTagPolaris`
mutation jira_polaris_RefreshSnippet($input: RefreshPolarisSnippetsInput!) {
    refreshPolarisSnippets(input: $input) {
        success
        errors {
            message
        }
    }
}
`;

// @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
const handleSynchronousRefreshResult = (result): RefreshSyncSnippet[] => {
	if (result.errors !== undefined) {
		throw new Error(
			`polaris-insights.refresh-snippet-error:${result.errors
				// @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
				.map((e) => e.message)
				.join(', ')}`,
		);
	}

	if (
		!result.data ||
		!result.data.refreshPolarisSnippets ||
		!result.data.refreshPolarisSnippets.node ||
		!result.data.refreshPolarisSnippets.node.refreshedSnippets
	) {
		throw new Error('polaris-insights.sync-refresh-snippet-error');
	}

	return [...result.data.refreshPolarisSnippets.node.refreshedSnippets];
};

const getSyncRefreshVariables = (cloudId: string, projectId: string, snippetId: string) => ({
	variables: {
		input: {
			project: createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'project',
				resourceId: projectId,
			}),
			subject: snippetId,
			synchronous: true,
		},
	},
});

export const refreshSnippetMutation = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	snippetId: string,
): Promise<RefreshSyncSnippet[]> =>
	client
		.mutate<RefreshSnippetSync>({
			mutation: SYNC_REFRESH_SNIPPET_QUERY,
			...getSyncRefreshVariables(cloudId, projectId, snippetId),
		})
		.then(handleSynchronousRefreshResult);

export const refreshAllSnippetsMutation = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
) =>
	client
		.mutate<RefreshSnippet>({
			mutation: ASYNC_REFRESH_SNIPPET_QUERY,
			variables: {
				input: {
					project: createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'project',
						resourceId: projectId,
					}),
				},
			},
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-insights.refresh-snippets-error:${result.errors
						.map((e) => e.message)
						.join(', ')}`,
				);
			}
		});

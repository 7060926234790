import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enterFullScreen: {
		id: 'polaris-component-fullscreen-button.enter-full-screen',
		defaultMessage: 'Enter full screen',
		description: 'Tooltip content for enter full screen button',
	},
	exitFullScreen: {
		id: 'polaris-component-fullscreen-button.exit-full-screen',
		defaultMessage: 'Exit full screen',
		description: 'Tooltip content for exit full screen button',
	},
});

import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { N0, N200, N500, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type CardProps = {
	title: string;
	footer: string;
	children: ReactNode;
	onClick: () => void;
};

export const Card = ({ title, footer, children, onClick }: CardProps) => (
	<Wrapper
		data-testid="polaris-ideas.ui.insights.insights.play-insight.card.wrapper"
		onClick={onClick}
	>
		<TitleWrapper>{title}</TitleWrapper>
		<ContentWrapper>{children}</ContentWrapper>
		<FooterWrapper>{footer}</FooterWrapper>
	</Wrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
	justifyContent: 'space-between',
	padding: token('space.100', '8px'),
	maxWidth: '128px',
	backgroundColor: token('elevation.surface.raised', N0),
	boxShadow: token(
		'elevation.shadow.raised',
		'0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: '3px',
	'&:hover': {
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.h2({
	color: token('color.text.subtle', N500),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.h6({
	color: token('color.text', N800),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '20px',
	lineHeight: '24px',
	margin: `${token('space.075', '6px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.025', '2px'),
	color: token('color.text.subtlest', N200),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
});

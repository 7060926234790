import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { UpdateCommentResponse } from '../types.tsx';
import { getIssueApiUrlForComment } from '../utils.tsx';

export const updateCommentInJira = (
	issueIdOrKey: string,
	commentId: string,
	comment: ADF,
): Promise<UpdateCommentResponse> =>
	performPutRequest(getIssueApiUrlForComment(issueIdOrKey, commentId), {
		body: JSON.stringify({ body: comment }),
	});

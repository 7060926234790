import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export type UserValue = {
	accountId: string;
	avatarUrls: {
		[key: string]: string;
	};
	displayName: string;
};

type SelectOption = {
	id: string;
};

export type Value =
	| string
	| number
	| string[]
	| null
	| undefined
	| UserValue
	| UserValue[]
	| SelectOption
	| SelectOption[];

const getIssueApiUrl = (issueKey: string): string => `/rest/api/2/issue/${issueKey}`;

const getIssueApiV3Url = (issueKey: string): string => `/rest/api/3/issue/${issueKey}`;

export const updateIssueField = (issueKey: string, fieldKey: string, value: unknown) =>
	performPutRequest(getIssueApiUrl(issueKey), {
		body: JSON.stringify({
			fields: {
				[fieldKey]: value,
			},
		}),
	});

export const updateIssueFields = (
	issueKey: string,
	fields: {
		[fieldKey: string]: unknown;
	},
) =>
	performPutRequest(getIssueApiUrl(issueKey), {
		body: JSON.stringify({
			fields,
		}),
	});

export const updateIssueDescription = (issueKey: string, value: unknown) =>
	performPutRequest(`${getIssueApiV3Url(issueKey)}`, {
		body: JSON.stringify({
			fields: {
				description: value,
			},
		}),
	});

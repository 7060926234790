import React, { type SyntheticEvent } from 'react';
import type { ButtonProps } from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	onCreate: () => void;
} & ButtonProps;

export const CreateFieldButton = ({ onCreate, ...rest }: Props) => {
	const { formatMessage } = useIntl();
	const canEditFields = useCanEditFields();

	return (
		<ButtonWithTooltip
			{...rest}
			testId="polaris-common.ui.common.create-field.button"
			isDisabled={!canEditFields}
			onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'createNewField');
				onCreate();
			}}
			tooltipProps={{
				content: !canEditFields ? formatMessage(messages.noPermissions) : null,
			}}
			shouldFitContainer
		>
			{formatMessage(messages.createField)}
		</ButtonWithTooltip>
	);
};

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useCurrentViewGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { assertNonNullable } from '../utils/types.tsx';
import { Header } from './main.tsx';

export const ColumnHeader: BoardProps['components']['ColumnHeader'] = ({
	groupIdentity,
	isReadOnly,
	cardsCount,
}) => {
	const groupByField = useCurrentViewGroupBy();
	assertNonNullable(groupByField);

	return (
		<Header
			isReadOnly={isReadOnly}
			fieldKey={groupByField.key}
			groupIdentity={groupIdentity}
			elemAfter={cardsCount ? <Box xcss={issueCountContainerStyles}>{cardsCount}</Box> : null}
		/>
	);
};

const issueCountContainerStyles = xcss({
	marginLeft: 'space.150',
	color: 'color.text.disabled',
});

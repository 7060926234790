import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ownerHeading: {
		id: 'polaris-component-collection-about-sidebar.collection-details.owner',
		defaultMessage: 'Owner',
		description: 'Heading for the owner section in roadmap about sidebar',
	},
	projectsHeadingCollection: {
		id: 'polaris-component-collection-about-sidebar.projects-heading-collection',
		defaultMessage: 'Projects used in this roadmap',
		description: 'Heading for the projects section in roadmap about sidebar',
	},
	projectsHeadingView: {
		id: 'polaris-component-collection-about-sidebar.projects-heading-view',
		defaultMessage: 'Projects in this view',
		description: 'Heading for the projects section in view about sidebar',
	},
	filtersHeading: {
		id: 'polaris-component-collection-details.filters-heading',
		defaultMessage: 'Filters applied to this roadmap',
		description: 'Heading for the filters section in roadmap about sidebar',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unknownOption: {
		id: 'polaris-common.filters.filter-component.options.unknown-option',
		defaultMessage: 'Unknown',
		description:
			'Unknown filter value option selected. Can happen if a value is no longer present in Jira',
	},
});

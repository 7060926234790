import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import { useCanEditCollection } from '@atlassian/jira-polaris-component-collections/src/controllers/combined-selectors.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { createHook } from '@atlassian/react-sweet-state';
import { PermissionsStore, usePermissions, usePermissionActions } from '../main.tsx';

const COLLECTION_VIEW_FORBIDDEN_PERMISSION_KEYS = ['ADMINISTER_PROJECTS'];

export const useIsEmbeddedView = createHook(PermissionsStore, {
	selector: (state) => state.meta.loadingProps?.isEmbeddedView || false,
});

export const useIsSharedView = createHook(PermissionsStore, {
	selector: (state) => state.meta.loadingProps?.isSharedView || false,
});

export const useIsCollectionView = createHook(PermissionsStore, {
	selector: (state) => state.meta.loadingProps?.isCollectionView || false,
});

export const usePermissionHook = createHook(PermissionsStore, {
	selector: (state, { permissionKey }: { permissionKey: string }) => {
		const permissions = state.isPreviewContributorMode
			? state.contributorPermissions
			: state.permissions;
		return permissions.some((p) => p.key === permissionKey && p.havePermission === true);
	},
});

export const createPermissionHook =
	(permissionKey: string, availableInSharedView = false) =>
	() => {
		const [val] = usePermissionHook({ permissionKey });
		const [sEmbeddedView] = useIsEmbeddedView();
		const [isSharedView] = useIsSharedView();

		const [isCollectionView] = useIsCollectionView();

		// in a collection view, certain permissions are not available.
		if (isCollectionView && COLLECTION_VIEW_FORBIDDEN_PERMISSION_KEYS.includes(permissionKey)) {
			return [false];
		}

		if (isSharedView && !availableInSharedView) {
			return [false];
		}

		return [!sEmbeddedView && val];
	};

const useIsInContributorPreviewMode = createHook(PermissionsStore, {
	selector: (state) => state.isPreviewContributorMode,
});

export const useIsSiteAdmin = () => {
	const { isSiteAdmin } = useTenantContext();
	const [isInContributorPreviewMode] = useIsInContributorPreviewMode();
	return isSiteAdmin && !isInContributorPreviewMode;
};

export const useIsAdmin = () => {
	const { isAdmin } = useTenantContext() ?? {};
	const [isInContributorPreviewMode] = useIsInContributorPreviewMode();
	return isAdmin && !isInContributorPreviewMode;
};

export const useIsLoadingPermissions = createHook(PermissionsStore, {
	selector: (state) => state.meta.loading,
});

export const useIsLoadedPermissions = createHook(PermissionsStore, {
	selector: (state) => state.meta.loaded,
});

const useProjectPermissionsLength = createHook(PermissionsStore, {
	selector: (state) => state.permissions.length,
});

export const useIsProjectAdmin = createPermissionHook('ADMINISTER_PROJECTS');

export const useCanCreateIssues = createPermissionHook('CREATE_ISSUES');
export const useCanEditIssues = createPermissionHook('EDIT_ISSUES');
export const useCanDeleteIssues = createPermissionHook('DELETE_ISSUES');
export const useCanMoveIssues = createPermissionHook('MOVE_ISSUES');
export const useCanAddComments = createPermissionHook('ADD_COMMENTS', true);
export const useHasEditOwnCommentsPermission = createPermissionHook('EDIT_OWN_COMMENTS', true);
export const useHasEditAllCommentsPermission = createPermissionHook('EDIT_ALL_COMMENTS');
export const useCanModifyReporter = createPermissionHook('MODIFY_REPORTER');

export const useCanManageWatchers = createPermissionHook('MANAGE_WATCHERS');
export const useCanViewWatchers = createPermissionHook('VIEW_VOTERS_AND_WATCHERS');

export const useCanAddAttachments = createPermissionHook('CREATE_ATTACHMENTS');
export const useCanDeleteOwnAttachments = createPermissionHook('DELETE_OWN_ATTACHMENTS');
export const useCanDeleteAllAttachments = createPermissionHook('DELETE_ALL_ATTACHMENTS');
export const useCanMakeBulkChanges = createPermissionHook('BULK_CHANGE');

const useHasLinkIssuesPermission = createPermissionHook('LINK_ISSUES');
const useCanScheduleIssues = createPermissionHook('SCHEDULE_ISSUES');
const useHasManageDeliveryPermission = createPermissionHook('MANAGE_DISCOVERY_DELIVERY');
const useHasMergeIdeasPermission = createPermissionHook('MERGE_DISCOVERY_ISSUES');
const useHasArchiveIdeasPermission = createPermissionHook('ARCHIVE_DISCOVERY_ISSUES');
const useHasVotePermission = createPermissionHook('DISCOVERY_VOTE');
const useHasCreateInsightsPermission = createPermissionHook('CREATE_DISCOVERY_INSIGHTS');
const useHasManageInsightsPermission = createPermissionHook('MANAGE_DISCOVERY_INSIGHTS');

const useHasManageViewsPermission = createPermissionHook('MANAGE_DISCOVERY_VIEWS');

const useHasPublishDiscoveryViewsPermission = createPermissionHook('PUBLISH_DISCOVERY_VIEWS');
const useHasPublishPublicDiscoveryViewsPermission = createPermissionHook(
	'PUBLISH_PUBLIC_DISCOVERY_VIEWS',
);

const useHasExportViewsPermission = createPermissionHook('SHARE_DISCOVERY_VIEWS');
const useHasCreateViewCommentsPermission = createPermissionHook('CREATE_DISCOVERY_VIEW_COMMENTS');
const useHasManageViewCommentsPermission = createPermissionHook('MANAGE_DISCOVERY_VIEW_COMMENTS');
const useHasManageIdeaTemplatesPermission = createPermissionHook('MANAGE_DISCOVERY_IDEA_TEMPLATES');
const useHasPinIssueViewFieldsPermission = createPermissionHook('PIN_DISCOVERY_ISSUE_VIEW_FIELDS');

export const useCanCreateAndEditIssues = (): boolean => {
	const [canCreateIssues] = useCanCreateIssues();
	const [canEditIssues] = useCanEditIssues();
	return canCreateIssues && canEditIssues;
};

export const useCanLinkIssues = (): boolean => {
	const [hasLinkIssuesPermission] = useHasLinkIssuesPermission();
	const [canEditIssues] = useCanEditIssues();
	return hasLinkIssuesPermission && canEditIssues;
};

export const useCanConnectIssues = (): boolean => {
	const canLinkIssues = useCanLinkIssues();
	return canLinkIssues;
};

export const useCanPinIssueViewFields = (): boolean => {
	const [hasPinIsssueViewFieldsPermission] = useHasPinIssueViewFieldsPermission();
	const [isCollectionView] = useIsCollectionView();
	return !isCollectionView && hasPinIsssueViewFieldsPermission;
};

export const useCanEditOwnComments = (): boolean => {
	const [hasEditOwnCommentsPermission] = useHasEditOwnCommentsPermission();
	return hasEditOwnCommentsPermission;
};

export const useCanEditAllComments = (): boolean => {
	const [hasEditAllCommentsPermission] = useHasEditAllCommentsPermission();
	return hasEditAllCommentsPermission;
};

export const useCanAddAndEditOwnViewComments = (): boolean => {
	const [hasCreateViewComments] = useHasCreateViewCommentsPermission();
	return hasCreateViewComments;
};

export const useCanEditAllViewComments = (): boolean => {
	const [hasManageViewCommentsPermission] = useHasManageViewCommentsPermission();
	return hasManageViewCommentsPermission;
};

export const useCanRankIdeas = (): boolean => {
	const [canEditIssues] = useCanEditIssues();
	const [canScheduleIssues] = useCanScheduleIssues();
	return canEditIssues && canScheduleIssues;
};

export const useCanEditFields = (): boolean => {
	const [isProjectAdmin] = useIsProjectAdmin();

	const [isCollectionView] = useIsCollectionView();
	const collectionUUID = useEnvironmentContainer()?.id ?? '';
	const canEditCollection = useCanEditCollection({
		collectionUUID,
		forceLoading: isCollectionView,
	});

	return isCollectionView && fg('jpd-aurora-roadmap-fields-onboarding')
		? canEditCollection
		: isProjectAdmin;
};

export const useCanCreateFields = (): boolean => {
	const canEditFields = useCanEditFields();
	const [isCollectionView] = useIsCollectionView();
	return canEditFields && !isCollectionView;
};

export const useCanManageGlobalFields = (): boolean => {
	const isAdmin = useIsAdmin();
	return isAdmin;
};

export const useCanImportIssues = (): boolean => {
	const [canEditIssues] = useCanEditIssues();
	const [canMakeBulkChanges] = useCanMakeBulkChanges();
	return canEditIssues && canMakeBulkChanges;
};

export const useCanManageIdeaTemplates = (): boolean => {
	const [hasManageDescriptionTemplatesPermission] = useHasManageIdeaTemplatesPermission();
	return hasManageDescriptionTemplatesPermission;
};

export const useCanAddPlayContributions = (): boolean => {
	const [hasVotePermissions] = useHasVotePermission();
	return hasVotePermissions;
};

export const useManageViewPermissions = (viewId: string | undefined) => {
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();
	const [canManageViews] = useHasManageViewsPermission();
	const { loadViewPermissionsForCurrentUser } = usePermissionActions();
	const [state] = usePermissions();

	if (!isViewPermissionsEnabled) {
		return {
			canManageView: canManageViews,
			canManageViews,
			canSeeView: true,
			loadViewPermissionsForCurrentUser: noop,
		};
	}

	if (!viewId) {
		return {
			canManageView: false,
			canManageViews,
			canSeeView: true,
			loadViewPermissionsForCurrentUser,
		};
	}

	const permissionsForThisView = state.viewPermissions[viewId];

	if (permissionsForThisView?.loading) {
		return {
			canManageView: false,
			canManageViews,
			canSeeView: true,
			loadViewPermissionsForCurrentUser,
		};
	}

	return {
		canManageView: canManageViews && Boolean(permissionsForThisView?.data?.canEdit),
		canManageViews,
		canSeeView:
			permissionsForThisView?.loading ||
			permissionsForThisView?.data === undefined ||
			Boolean(permissionsForThisView?.data.canView),
		loadViewPermissionsForCurrentUser,
	};
};

export const useCanManageViews = (): boolean => {
	const [hasManageViewsPermission] = useHasManageViewsPermission();
	// temporarily give everyone manageViews permissions in a collection view
	// TODO
	const [isCollectionView] = useIsCollectionView();
	return hasManageViewsPermission || isCollectionView;
};

export const useCanMergeIdeas = (): boolean => {
	const [hasMergeIdeasPermission] = useHasMergeIdeasPermission();
	return hasMergeIdeasPermission;
};

export const useCanArchiveIdeas = (): boolean => {
	const [hasArchiveIdeasPermission] = useHasArchiveIdeasPermission();
	return hasArchiveIdeasPermission;
};

export const useCanPublishDiscoveryViews = (): boolean =>
	useHasPublishDiscoveryViewsPermission()[0];

export const useCanPublishPublicDiscoveryViews = (): boolean =>
	useHasPublishPublicDiscoveryViewsPermission()[0];

export const useCanExportViews = (): boolean => {
	const [hasExportViewsPermission] = useHasExportViewsPermission();
	const collectionId = useEnvironmentContainer()?.id ?? '';
	const [isCollectionView] = useIsCollectionView();

	const canEditCollection = useCanEditCollection({
		collectionUUID: collectionId,
		forceLoading: isCollectionView,
	});

	return (isCollectionView && canEditCollection) || hasExportViewsPermission;
};

export const useCanManageInsights = (): boolean => {
	const [hasManageInsightsPermission] = useHasManageInsightsPermission();
	return hasManageInsightsPermission;
};

export const useCanCreateInsights = (): boolean => {
	const [hasCreateInsightsPermission] = useHasCreateInsightsPermission();
	return hasCreateInsightsPermission;
};

export const useCanManageDeliveryTickets = (): boolean => {
	const [hasLinkIssuesPermission] = useHasLinkIssuesPermission();
	const [hasManageDeliveryPermission] = useHasManageDeliveryPermission();
	return hasManageDeliveryPermission && hasLinkIssuesPermission;
};

export const useHasNoProjectPermissions = (): [boolean] => {
	const [isCollectionView] = useIsCollectionView();
	const [projectPermissionsLength] = useProjectPermissionsLength();
	return [projectPermissionsLength === 0 && !isCollectionView];
};

export const useCanEditGroupsRollup = (): boolean => {
	const [canEditIssues] = useCanEditIssues();
	const [isCollectionView] = useIsCollectionView();

	// TODO: should be only allowed for view owner in case of a collection view
	return isCollectionView || canEditIssues;
};

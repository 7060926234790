import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { AccountId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const storage = createLocalStorageProvider('polaris');

const crateStorageKey = (issueKey: IssueKey, currentUserAccountId: null | AccountId) =>
	`description.draft.${issueKey}:${currentUserAccountId}`;

export const saveDescriptionDraft =
	(draft: ADF, currentUserAccountId: AccountId | null) =>
	(actionApi: StoreActionApi<State>, { issueKey }: Props) => {
		if (!issueKey) {
			return;
		}
		if (draft.content.length > 0) {
			storage.set(crateStorageKey(issueKey, currentUserAccountId), draft);
		} else {
			storage.remove(crateStorageKey(issueKey, currentUserAccountId));
		}
	};

export const loadDescriptionDraft =
	(currentUserAccountId: AccountId | null) =>
	(actionApi: StoreActionApi<State>, { issueKey }: Props) =>
		issueKey ? storage.get(crateStorageKey(issueKey, currentUserAccountId)) : undefined;

export const clearDescriptionDraft =
	(currentUserAccountId: AccountId | null) =>
	(actionApi: StoreActionApi<State>, { issueKey }: Props) => {
		issueKey ? storage.remove(crateStorageKey(issueKey, currentUserAccountId)) : undefined;
	};

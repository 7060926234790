const hasOpenLayersAbove = () =>
	Boolean(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.querySelector('[role="dialog"]') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.querySelector('[data-role="droplistContent"]') || // Editor droplist, e.g. text styles
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.querySelector('[role="menu"]') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.querySelector('[data-placement]') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.querySelector('.media-viewer-popup') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.getElementById('emoji-picker') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.getElementById('watchers-popup') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.getElementById('date-picker') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.getElementById('field-explanation'),
	);

const elementTypeExceptions = ['input', 'textarea'];
const roleExceptions = ['textbox'];

const eventTargetBlocksEsc = (event: KeyboardEvent) => {
	const targetIsValid = event.target instanceof Element;
	if (!targetIsValid) {
		return false;
	}

	const localName = event.target?.localName;
	const role = event.target?.getAttribute('role') ?? '';

	return (
		(localName && elementTypeExceptions.includes(localName)) ||
		(role && roleExceptions.includes(role))
	);
};

export const ignoreEscapePress = (event: KeyboardEvent) =>
	hasOpenLayersAbove() || eventTargetBlocksEsc(event);

import React from 'react';
import { useGetUserFieldsRollup } from '../../../controllers/issue/selectors/fields-rollup-hooks.tsx';
import { CommonFieldGrouped } from '../common/index.tsx';
import { messages } from '../messages.tsx';
import type { Props } from '../types.tsx';

export const UserFieldGrouped = ({ fieldKey, groupId, groupFieldKey, rollupOperation }: Props) => {
	const val = useGetUserFieldsRollup(fieldKey, groupFieldKey, groupId, rollupOperation);

	return (
		<CommonFieldGrouped rollupOperation={rollupOperation} val={val} message={messages.usersCount} />
	);
};

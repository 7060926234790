import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { NotificationSettingsServiceState, NotificationsContainerProps } from './types.tsx';

export type NotificationSettingsActions = typeof actions;

export const initialState: NotificationSettingsServiceState = {
	data: {
		configuration: undefined,
		eventTypes: undefined,
		notificationTypes: undefined,
		projectRoles: undefined,
	},
	error: undefined,
	loading: false,
};

const Store = createStore<NotificationSettingsServiceState, NotificationSettingsActions>({
	name: 'ng-notification-settings',
	initialState,
	actions,
});

export const useNotificationSettingsService = createHook(Store);

const NotificationSettingsContainer = createContainer<
	NotificationSettingsServiceState,
	NotificationSettingsActions,
	NotificationsContainerProps
>(Store);

export default NotificationSettingsContainer;

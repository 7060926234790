import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchHint: {
		id: 'polaris-ideas.view-controls.config-filters.text.search-hint',
		defaultMessage: 'Search ideas',
		description: 'Search hint for the free text filter component',
	},
	searchIcon: {
		id: 'polaris-ideas.view-controls.config-filters.text.search-icon',
		defaultMessage: 'Search',
		description: 'Search icon description',
	},
});

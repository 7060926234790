import { useEffect } from 'react';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { usePresence } from '../../controllers/collab/index.tsx';
import { useActions, useViewLastSeen } from '../../controllers/last-seen/index.tsx';

export const OfflinePresenceSynchronizer = ({ viewUUID }: { viewUUID: ViewUUID }) => {
	const presence = usePresence(viewUUID);
	const previousPresence = usePrevious(presence);
	const { data: lastSeen } = useViewLastSeen(viewUUID);
	const { updateViewLastSeenStateInternal } = useActions();

	// update internal state with out-of-date presence data
	useEffect(() => {
		if (!lastSeen) return;
		if (previousPresence === undefined) return;

		const wentOffline = previousPresence
			.filter((collabUser) => !presence.some((u) => collabUser.clientId === u.clientId))
			.map((collabUser) =>
				collabUser.clientId == null
					? null
					: {
							timestamp: new Date(),
							aaid: collabUser.clientId,
							account: {
								picture: collabUser.avatar,
								accountId: collabUser.clientId,
								name: collabUser.name,
							},
						},
			)
			.filter(Boolean);

		if (wentOffline.length > 0) {
			const newLastViewed: Record<AccountId, Date> = {};
			Object.keys(lastSeen).forEach((key) => {
				if (!wentOffline.some((off) => off?.aaid === key)) {
					newLastViewed[key] = lastSeen[key];
				}
			});
			wentOffline.forEach((off) => {
				newLastViewed[off.aaid] = off.timestamp;
			});
			updateViewLastSeenStateInternal(viewUUID, newLastViewed);
		}
	}, [viewUUID, lastSeen, presence, previousPresence, updateViewLastSeenStateInternal]);

	return null;
};

import { createHook } from '@atlassian/react-sweet-state';
import type { Actions } from '../actions/index.tsx';
import { RightSidebarStore } from '../main.tsx';
import type { PreventClosing, Showing, State } from '../types.tsx';
import {
	getOption,
	getRightSidebarContextPlay,
	getRightSidebarFilterTmpFieldKey,
	getShowing,
	isIdeasBucketOpen,
	isRightSidebarOpen,
	isRightSidebarShowingSort,
	type IssuePlayContext,
} from './index.tsx';

const createStoreHook = <T,>(selector: (arg1: State) => T) =>
	createHook<State, Actions, T>(RightSidebarStore, {
		selector,
	});

export const usePreventClosingSidebar = createStoreHook<PreventClosing>(
	(state) => state.preventClosing,
);

export const useRightSidebarShowing = createStoreHook<Showing>(getShowing);

export const useRightSidebarOption = createStoreHook<string | null>(getOption);

export const useIsRightSidebarShowingSort = createStoreHook<boolean>(isRightSidebarShowingSort);

export const useRightSidebarFilterTmpFieldKey = createStoreHook<string | undefined>(
	getRightSidebarFilterTmpFieldKey,
);

export const useIsRightSidebarOpen = createStoreHook<boolean>(isRightSidebarOpen);

export const useRightSidebarContextPlay = createStoreHook<IssuePlayContext | undefined>(
	getRightSidebarContextPlay,
);

export const useIsIdeasBucketOpen = createStoreHook<boolean>(isIdeasBucketOpen);

import React, { useEffect, useRef, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box, Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { redirectToIdentity } from '@atlassian/jira-polaris-common/src/common/utils/identity/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import jpdLogoIcon from './assets/jpd-logo.svg';
import viewBackgroundIcon from './assets/view-back.svg';
import viewFrontIcon from './assets/view-front.png';
import messages from './messages.tsx';

// This component will never be used outside the context of shared/published views
export const SharedViewSignupHero = () => {
	const { formatMessage } = useIntl();
	const { environment, cloudName } = useTenantContext();

	const commonContainerRef = useRef<HTMLDivElement>(null);
	const innerContainerRef = useRef<HTMLDivElement>(null);

	const onAction = useCallback(() => redirectToIdentity(environment), [environment]);

	const onMouseMove = ({ clientX, clientY }: MouseEvent) => {
		if (commonContainerRef.current && innerContainerRef.current) {
			const rect = commonContainerRef.current.getBoundingClientRect();
			const x = clientX - rect.left;
			const y = clientY - rect.top;
			const rotateY = (x / rect.width) * 20 - 10;
			const rotateX = (y / rect.height) * -20 + 10;

			innerContainerRef.current.setAttribute(
				'style',
				`transform: rotateY(${rotateY}deg) rotateX(${rotateX}deg); transition: transform .1s linear`,
			);
		}
	};

	const onMouseLeave = () => {
		if (innerContainerRef.current) {
			innerContainerRef.current.setAttribute('style', '');
		}
	};

	const getOrganizationName = () => cloudName[0].toUpperCase() + cloudName.slice(1);

	useEffect(() => {
		const { current } = commonContainerRef;

		const innerContainerTimeout = setTimeout(() => {
			if (innerContainerRef.current) {
				innerContainerRef.current.style.transform = 'rotateX(0)';
			}
		}, 10);

		if (current) {
			current.onmousemove = onMouseMove;
			current.onmouseleave = onMouseLeave;
		}

		return () => {
			clearTimeout(innerContainerTimeout);

			if (current) {
				current.onmousemove = null;
				current.onmouseleave = null;
			}
		};
	}, []);

	return (
		<ViewContainer>
			<Stack>
				<Box ref={commonContainerRef}>
					<ImagesContainer ref={innerContainerRef}>
						<BackImage src={viewBackgroundIcon} alt="view-back-icon" />
						<FrontImage src={viewFrontIcon} alt="view-front-icon" />
					</ImagesContainer>
				</Box>
				<ContentWrapper>
					<EmptyState
						size="wide"
						imageUrl={jpdLogoIcon}
						imageWidth={165}
						headingLevel={1}
						header={formatMessage(messages.title, {
							organizationName: getOrganizationName(),
						})}
						description={formatMessage(messages.description)}
						primaryAction={
							<Button appearance="primary" onClick={onAction}>
								{formatMessage(messages.createAccount)}
							</Button>
						}
						tertiaryAction={
							<Button appearance="link" onClick={onAction}>
								{formatMessage(messages.logIn)}
							</Button>
						}
					/>
				</ContentWrapper>
			</Stack>
		</ViewContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImagesContainer = styled.div({
	display: 'grid',
	placeItems: 'center',
	transformStyle: 'preserve-3d',
	transition: 'transform .5s linear',
	perspective: '1000px',
	width: '100%',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackImage = styled.img({
	gridArea: '1/1/1/1',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FrontImage = styled.img({
	transform: `translateZ(${token('space.250', '20px')}) scale(0.96)`,
	gridArea: '1/1/1/1',
	maxWidth: '669px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewContainer = styled.div({
	display: 'flex',
	width: '100%',
	height: '100vh',
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[class*="Container"]': {
		display: 'flex',
		maxWidth: '100%',
		flexDirection: 'column',
		alignItems: 'center',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'p[class*="Description"]': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '16px',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[class*="ActionsContainer"]': {
		padding: 0,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[class*="ButtonGroup"]': {
		flexDirection: 'column-reverse',
		alignItems: 'center',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		button: {
			maxWidth: 'fit-content',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'h1[class*="EmptyStateHeader"]': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '29px',
		marginTop: token('space.negative.200', '-16px'),
	},
});

import { addGoals } from './add-goals/index.tsx';
import { addProjects } from './add-projects/index.tsx';
import { loadGoals } from './load-goals/index.tsx';
import { loadProjects } from './load-projects/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadGoals,
	loadProjects,
	addGoals,
	addProjects,
};

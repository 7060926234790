import { useCallback } from 'react';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { GLOBAL_FIELDS_CHANNEL } from '@atlassian/jira-polaris-lib-broadcasting/src/common/constants.tsx';
import type { FieldChange } from '@atlassian/jira-polaris-lib-broadcasting/src/common/types/index.tsx';
import { useBroadcastListener } from '@atlassian/jira-polaris-lib-broadcasting/src/index.tsx';
import { useIsEmbedded, useIsSharedView } from '../../environment/index.tsx';
import { useFieldActions } from '../../field/main.tsx';
import { useIdeaActions } from '../../idea/main.tsx';
import { useFieldsSidebarConfig } from '../../idea/selectors/hooks.tsx';
import { useUpdateDynamicField } from '../../issue/utils/update-dynamic-field.tsx';
import { useRightSidebarActions } from '../../right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '../../right-sidebar/selectors/hooks.tsx';
import { useViewActions } from '../../views/main.tsx';
import { useGlobalFieldsActions } from '../main.tsx';

export const useGlobalFieldsUpdateSync = () => {
	const { loadSelectedFields, deleteFieldLocally, setNewlyAddedGlobalFields } = useFieldActions();
	const { removeGlobalFieldLocally, reloadGlobalField } = useGlobalFieldsActions();
	const { setForceReloadViewOnNextUpdate } = useViewActions();
	const projectId = useProjectIdUnsafe();
	const [, { close: closeSidebar }] = useRightSidebarActions();
	const [currentShowing] = useRightSidebarShowing();
	const isSharedView = useIsSharedView();
	const isEmbedded = useIsEmbedded();
	const { mode: ideaViewSidebarMode, fieldKey: ideaViewSidebarFieldKey } = useFieldsSidebarConfig();
	const { setFieldSidebarMode } = useIdeaActions();
	const updateDynamicField = useUpdateDynamicField();

	const handleFieldRemoval = useCallback(
		(fieldKey: string) => {
			if (currentShowing.mode === 'FIELD' && currentShowing.fieldKey === fieldKey) {
				closeSidebar();
			}

			if (ideaViewSidebarMode === 'FIELD' && ideaViewSidebarFieldKey === fieldKey) {
				setFieldSidebarMode('VALUES');
			}

			deleteFieldLocally(fieldKey);
		},
		[
			closeSidebar,
			setFieldSidebarMode,
			currentShowing,
			ideaViewSidebarFieldKey,
			ideaViewSidebarMode,
			deleteFieldLocally,
		],
	);

	const handleGlobalFieldChangedEvent = useCallback(
		async (evt: FieldChange) => {
			if (isSharedView || isEmbedded) {
				return;
			}

			switch (evt.type) {
				case 'fieldCreated': {
					reloadGlobalField(evt.fieldKey);
					break;
				}
				case 'fieldAdded': {
					if (evt.projectId === projectId) {
						loadSelectedFields([evt.fieldKey, ...(evt.dependenciesFieldKeys ?? [])]);
						setForceReloadViewOnNextUpdate(true);
						setNewlyAddedGlobalFields([evt.fieldKey, ...(evt.dependenciesFieldKeys ?? [])]);
					}

					break;
				}
				case 'fieldRemoved': {
					if (evt.projectId === projectId) {
						handleFieldRemoval(evt.fieldKey);
					}

					break;
				}
				case 'fieldDeleted': {
					handleFieldRemoval(evt.fieldKey);
					removeGlobalFieldLocally(evt.fieldKey);

					break;
				}
				case 'fieldUpdated': {
					reloadGlobalField(evt.fieldKey);

					const [updatedField] = await loadSelectedFields([evt.fieldKey], true);

					if (updatedField?.type === FIELD_TYPES.FORMULA) {
						if (evt.dependenciesFieldKeys?.length) {
							await loadSelectedFields(evt.dependenciesFieldKeys);
						}

						// recalculating the updated formula
						updateDynamicField(updatedField);
					}

					break;
				}
				default: {
					break;
				}
			}
		},
		[
			projectId,
			isSharedView,
			isEmbedded,
			loadSelectedFields,
			setForceReloadViewOnNextUpdate,
			setNewlyAddedGlobalFields,
			handleFieldRemoval,
			removeGlobalFieldLocally,
			reloadGlobalField,
			updateDynamicField,
		],
	);

	useBroadcastListener(GLOBAL_FIELDS_CHANNEL, handleGlobalFieldChangedEvent);
};

import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { ObservableValuesList } from '../../../observable-values-list/index.tsx';
import type { UserTagsProps } from '../types.tsx';

export const HorizontalLayout = ({
	users,
	hiddenCountRef,
	setHiddenCountRefValue,
}: UserTagsProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	return (
		<Container ref={containerRef}>
			<ObservableValuesList
				isActive
				containerRef={containerRef}
				hiddenCountExternalRef={hiddenCountRef}
				setHiddenCountExternalRefValue={setHiddenCountRefValue}
				MoreTagWrapper={MoreTagWrapper}
			>
				{users.map((user) => (
					<Tag
						key={user.id}
						text={user.name}
						appearance="rounded"
						elemBefore={<Avatar src={user.avatarUrl} borderColor="transparent" size="xsmall" />}
					/>
				))}
			</ObservableValuesList>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	gap: token('space.050', '4px'),
	position: 'relative',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span': {
		margin: token('space.0', '0'),
		flexShrink: '0',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="more-tag-container-327a"]': {
		lineHeight: token('space.150', '12px'),
	},
});

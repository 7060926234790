import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useSafeIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useIssueLinkingEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useVisibleSelectedIssueIds } from '../../../../../../utils.tsx';
import { SendToJiraDialog } from '../../../../../../view-controls/send-to-backlog-modal/index.tsx';
import { messages } from './messages.tsx';

const ICON = iconForPolarisFieldType(FIELD_TYPES.DELIVERY_PROGRESS);

type InteractiveProps = {
	issueId: LocalIssueId;
	appearance: 'list' | 'board';
};

export const EmptyDeliveryPreview = () => <IconOnlyContainer>{ICON}</IconOnlyContainer>;

export const EmptyDeliveryInteractive = ({ appearance, issueId }: InteractiveProps) => {
	const { formatMessage } = useIntl();
	const spacing = appearance === 'board' ? 'compact' : 'default';
	const { openIssueView } = usePolarisRouter();
	const isIssueLinkingEnabled = useIssueLinkingEnabled();

	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const issueKey = useSafeIssueKey(issueId);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openIssueDeliverySection = useCallback(() => {
		if (!issueKey) return;
		openIssueView(issueKey, { layout: 'sidebar', section: 'deliver' });
	}, [issueKey, openIssueView]);

	const onButtonClick = (_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'fieldValue', {
			issueField: 'deliveryProgress',
		});

		if (visibleSelectedIssueIds.length > 1) {
			setIsModalOpen(true);
		} else {
			openIssueDeliverySection();
		}
	};

	return isIssueLinkingEnabled ? (
		<>
			<StyledButton
				spacing={spacing}
				onClick={onButtonClick}
				appearance="subtle"
				// @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'ReactChild | undefined'.
				iconBefore={ICON}
			>
				<PlaceholderLabel data-testid="polaris-ideas.ui.view-content.idea-list.cell.cells.linked-issues-field.empty.cta">
					{formatMessage(messages.placeholder)}
				</PlaceholderLabel>
			</StyledButton>
			<SendToJiraDialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLabel = styled.div({
	color: token('color.text.subtle', N200),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	fontWeight: token('font.weight.semibold'),
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		padding: `0 ${token('space.075', '6px')} 0 ${token('space.050', '4px')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconOnlyContainer = styled.div({
	opacity: 0.3,
	display: 'flex',
	alignItems: 'center',
	height: '100%',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	padding: `0 ${token('space.075', '6px')} 0 ${token('space.050', '4px')}`,
});

import {
	createNotImplementedJiraGlobalFieldCopyValuesRemote,
	createJiraGlobalFieldCopyValuesRemote,
} from './copy-values/index.tsx';
import {
	createJiraGlobalFieldCrudRemote,
	createNotImplementedJiraGlobalFieldCrudRemote,
} from './crud/index.tsx';
import {
	createJiraGlobalFieldOptionRemote,
	createNotImplementedJiraGlobalFieldOptionRemote,
} from './option/index.tsx';
import type { GlobalFieldRemote } from './types.tsx';
import {
	createJiraGlobalFieldUsageRemote,
	createNotImplementedJiraGlobalFieldUsageRemote,
} from './usage/index.tsx';

export const createGlobalFieldRemote = (): GlobalFieldRemote => ({
	...createJiraGlobalFieldCrudRemote(),
	...createJiraGlobalFieldOptionRemote(),
	...createJiraGlobalFieldUsageRemote(),
	...createJiraGlobalFieldCopyValuesRemote(),
});

export const createSharingGlobalFieldRemote = (): GlobalFieldRemote => ({
	...createNotImplementedJiraGlobalFieldCrudRemote(),
	...createNotImplementedJiraGlobalFieldOptionRemote(),
	...createNotImplementedJiraGlobalFieldUsageRemote(),
	...createNotImplementedJiraGlobalFieldCopyValuesRemote(),
});

import type { RowId, RowGroupId } from '../../common/types/index.tsx';
import {
	createHasAllRowsInGroupSelected,
	createHasRowsInGroupSelected,
	createIsHovered,
	createIsInlineRowActive,
	createIsSelected,
	getHoveredRow,
	getRowsRendered,
	getSelectedRows,
	getCurrentStickyGroupRow,
	hasAllRowsSelected,
	hasSelectedRows,
	isSelectionEnabled,
} from './rows.tsx';
import { createHigherLevelHook, createSelectorHook } from './utils.tsx';

export const useIsSelectionEnabled = createSelectorHook(isSelectionEnabled);
export const useIsSelected = createHigherLevelHook<boolean, [RowId]>(createIsSelected);
export const useSelectedRows = createSelectorHook(getSelectedRows);
export const useHoveredRow = createSelectorHook(getHoveredRow);
export const useHasSelectedRows = createSelectorHook(hasSelectedRows);
export const useHasAllRowsSelected = createSelectorHook(hasAllRowsSelected);

export const useIsRowHovered = createHigherLevelHook<boolean, [RowId]>(createIsHovered);

export const useHasAllRowsInGroupSelected = createHigherLevelHook<boolean, [RowGroupId]>(
	createHasAllRowsInGroupSelected,
);
export const useHasRowsInGroupSelected = createHigherLevelHook<boolean, [RowGroupId]>(
	createHasRowsInGroupSelected,
);

export const useIsInlineRowCreateAboveActive = createHigherLevelHook<boolean, [RowId]>(
	createIsInlineRowActive('top'),
);
export const useIsInlineRowCreateBelowActive = createHigherLevelHook<boolean, [RowId]>(
	createIsInlineRowActive('bottom'),
);

export const useRowsRendered = createSelectorHook(getRowsRendered);

export const useCurrentStickyGroupRow = createSelectorHook(getCurrentStickyGroupRow);

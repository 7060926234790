import React, { memo, useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useDeliveryTicketsByParent,
	useIsLoading,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useJiraIssueIdByLocalIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useLinkedProgress,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import { useIssueLinkingEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { deliveryIssueComparator } from '@atlassian/jira-polaris-domain-delivery/src/compare/index.tsx';
import type { DeliveryIssue } from '@atlassian/jira-polaris-domain-delivery/src/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { DeliverItem, type DeliveryItem } from './delivery-item/index.tsx';
import { DisabledIssueLinking } from './disabled-state/index.tsx';
import { EmptyDelivery } from './empty-state/index.tsx';
import messages from './messages.tsx';
import { NoDataHint } from './no-data/index.tsx';
import { ProgressSummary } from './progress-summary/index.tsx';
import { DeliverSkeleton } from './skeleton/index.tsx';
import { transformData } from './utils.tsx';

type DeliverComponentProps = {
	isCompact?: boolean;
	deliveryTickets: {
		[key: string]: DeliveryIssue[];
	};
};

const NO_PARENT_KEY = 'no-parent-key';

const DeliveryProgressRefresher = ({ localIssueId }: { localIssueId: LocalIssueId }) => {
	const issueId = useJiraIssueIdByLocalIssueId(localIssueId);
	const { refreshDeliveryProgressForSpecificIssue } = useIssueActions();
	const container = useEnvironmentContainer();

	// on mount, refresh the delivery progress for the selected issue if we're in a collection view
	useEffect(() => {
		if (container?.type === PolarisEnvironmentContainerTypes.COLLECTION) {
			refreshDeliveryProgressForSpecificIssue(issueId, container);
		}
	}, [container, issueId, refreshDeliveryProgressForSpecificIssue]);

	return null;
};

export const DeliverComponent = memo(({ deliveryTickets, isCompact }: DeliverComponentProps) => {
	const { formatMessage } = useIntl();
	const isLoading = useIsLoading();
	const isIssueLinkingEnabled = useIssueLinkingEnabled();
	const localIssueId = useSelectedIssue();
	const progress = useLinkedProgress(localIssueId);
	const data = useMemo(
		() => (deliveryTickets[NO_PARENT_KEY] || []).sort(deliveryIssueComparator).map(transformData),
		[deliveryTickets],
	);

	const hasProgressButNoTickets = useMemo(
		() => progress.total > 0 && data.length === 0,
		[data.length, progress.total],
	);

	if (isLoading) {
		return <DeliverSkeleton />;
	}

	if (!isIssueLinkingEnabled) {
		return <DisabledIssueLinking />;
	}

	if (data.length === 0 && !hasProgressButNoTickets) {
		return <EmptyDelivery />;
	}

	return (
		<Container>
			{localIssueId !== undefined && <DeliveryProgressRefresher localIssueId={localIssueId} />}
			<ProgressSummary isCompact={isCompact} />
			{hasProgressButNoTickets ? (
				<NoDataHint />
			) : (
				<Box xcss={progressItemsStyles}>
					<SectionHeading>{formatMessage(messages.sectionHeading)}</SectionHeading>
					<div>
						{data.map((ticket: DeliveryItem) => (
							<DeliverItem key={ticket.issueKey} item={ticket} />
						))}
					</div>
				</Box>
			)}
		</Container>
	);
});

export const Deliver = memo(({ isCompact = false }: { isCompact?: boolean }) => {
	const deliveryTicketsByParent = useDeliveryTicketsByParent();
	return <DeliverComponent deliveryTickets={deliveryTicketsByParent} isCompact={isCompact} />;
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flexDirection: 'row',
	marginTop: token('space.200', '16px'),
	width: '100%',
});

const progressItemsStyles = xcss({
	marginTop: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeading = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	lineHeight: '16px',
});

import { startOfDay, differenceInDays } from 'date-fns';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IntervalValue } from '@atlassian/jira-polaris-lib-date-time/src/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { createHook } from '@atlassian/react-sweet-state';
import {
	createHigherLevelFieldHook as createHigherLevelFieldHookMain,
	FieldStore,
} from '../../main.tsx';
import {
	createHigherLevelFieldHook,
	createHigherLevelFieldHook2,
	createHigherLevelFieldHook3,
} from '../utils.tsx';
import {
	createGetDecorationForValue,
	createGetDecorationForNumberValue,
	createGetValueDecorationPalette,
	createGetValueDecorations,
	getFieldValueDecorations,
	createGetSortedValueDecorations,
	createGetDecorationForIntervalDateValue,
	createGetValuesDecorationPalette,
} from './index.tsx';

export const useDecorationForValue = createHigherLevelFieldHook2<
	FieldKey,
	string | undefined,
	ValueDecoration | undefined
>(createGetDecorationForValue);

export const useDecorationForNumberValue = createHigherLevelFieldHook2<
	FieldKey,
	number | undefined,
	ValueDecoration | undefined
>(createGetDecorationForNumberValue);

export const useDateDecoration = (
	fieldKey: FieldKey,
	datetime?: string,
): ValueDecoration | undefined => {
	const { locale } = useTenantContext();
	const given =
		datetime !== undefined
			? startOfDay(new Date(formatWithLocale(new Date(datetime), 'dd MMMM yyyy HH:mm', locale)))
			: 0;

	const current =
		datetime !== undefined
			? startOfDay(new Date(formatWithLocale(new Date(), 'dd MMMM yyyy HH:mm', locale)))
			: 0;

	const diffDays = datetime !== undefined ? differenceInDays(given, current) : undefined;

	const decoration = useDecorationForNumberValue(fieldKey, diffDays);

	return decoration;
};

export const useIntervalDateDecoration = createHigherLevelFieldHook3<
	FieldKey,
	IntervalValue | undefined,
	Locale,
	ValueDecoration | undefined
>(createGetDecorationForIntervalDateValue);

export const useDecorationsForField = createHigherLevelFieldHook<
	FieldKey,
	ValueDecoration[] | undefined
>(createGetValueDecorations);

export const useValueDecorationPalette = createHigherLevelFieldHookMain(
	createGetValueDecorationPalette,
);

export const useValuesDecorationPalette = createHigherLevelFieldHookMain(
	createGetValuesDecorationPalette,
);

export const useFieldValueDecorations = createHook(FieldStore, {
	selector: getFieldValueDecorations,
});

export const useSortedValueDecorations = createHigherLevelFieldHook<FieldKey, ValueDecoration[]>(
	createGetSortedValueDecorations,
);

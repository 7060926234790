import difference from 'lodash/difference';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { POLARIS_OAUTH_CLIENT_ID } from '@atlassian/jira-polaris-common/src/common/types/snippet/constants.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { Label } from '@atlassian/jira-polaris-domain-insight/src/label/types.tsx';
import type {
	Snippet,
	SnippetPropertiesObject,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import type { RemoteUpdateSnippet } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types.tsx';
import { getInsightAnalyticsData } from '../utils/analytics.tsx';
import { isOptimisticInsight } from '../utils/optimistic-updates.tsx';
import { signalInsightsUpdates } from '../utils/signal.tsx';

const applyPropertyUpdates = (
	originalProperties: SnippetPropertiesObject,
	setProperties?: SnippetPropertiesObject | null,
	deleteProperties?: ReadonlyArray<string> | null,
): SnippetPropertiesObject => {
	// it is *possible* to do these operations purely functionally, but it's tedious to get right (and
	// make Flow types happy), and it's not very efficient either.
	// So, instead... we just make a copy of the properties and side-effect it

	const copy = { ...originalProperties };

	if (deleteProperties) {
		deleteProperties.forEach((key) => {
			delete copy[key];
		});
	}

	if (setProperties) {
		Object.getOwnPropertyNames(setProperties).forEach((key) => {
			copy[key] = setProperties[key];
		});
	}

	return copy;
};

const applySnippetUpdatesByOauthClient = (snippets: Snippet[], updates: RemoteUpdateSnippet[]) =>
	// walk over the updates, computing a new set of snippets
	updates.reduce((current, update) => {
		const originalSnippet = current.filter(
			(s) => s.appInfo?.oauthClientId === update.oauthClientId,
		)[0];

		if (originalSnippet) {
			return current.map((s) =>
				s.id === originalSnippet.id
					? {
							...originalSnippet,
							properties: applyPropertyUpdates(
								originalSnippet.properties || {},
								update.setProperties,
								update.deleteProperties,
							),
						}
					: s,
			);
		}
		if (update.oauthClientId === null || update.oauthClientId === undefined) {
			// this should not happen because this function is only called with snippet updates
			// that are by oauth client id
			return [];
		}
		// synthesize a snippet to contain the props we need; note that this path is
		// normally only taken from JFE for "the" polaris app.  Also note that the "real"
		// snippet (complete with the correct ids) will be filled in once the round trip to the server
		// to update the insight is complete
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return [
			...snippets,
			{
				__typename: 'Snippet',
				id: 'my-fake-id',
				data: {},
				url: undefined,
				oauthClientId: update.oauthClientId,
				appInfo: {
					__typename: 'PolarisConnectApp',
					id: 'edfa6eff-e50d-48ed-98d9-3c052346d499',
					name: 'Polaris',
					avatarUrl: 'http://none',
					oauthClientId: update.oauthClientId,
				},
				properties: update.setProperties,
				refresh: undefined,
				updated: new Date().toISOString(),
			},
		] as Snippet[];
	}, snippets);
const applySnippetUpdatesById = (snippets: Snippet[], _updates: RemoteUpdateSnippet[]) =>
	// TODO we will need this later, but not yet; currently we are only
	// doing updates by oauth client id for the "polaris" client
	snippets;
const applySnippetUpdates = (snippets: Snippet[], updates: RemoteUpdateSnippet[]): Snippet[] =>
	applySnippetUpdatesByOauthClient(
		applySnippetUpdatesById(
			snippets,
			updates.filter((up) => Boolean(up.id)),
		),
		updates.filter((up) => Boolean(up.oauthClientId)),
	);

/**
 * compares the original and updated insights and optimistically updates the labels collection in the state
 * this is needed to correctly display the label suggestions across data points
 */
const updatePolarisLabels = (labels: Label[], original: Insight, remote: Insight): Label[] => {
	// helper function to extract the 'polaris' type snippets and the labels set on their properties
	const getLabels = (insight: Insight): string[] => {
		const polarisSnippet = insight.snippets.find(
			({ oauthClientId }) => oauthClientId === POLARIS_OAUTH_CLIENT_ID,
		);

		const labelsFromProperties = polarisSnippet?.properties?.labels;

		if (
			typeof labelsFromProperties === 'object' &&
			labelsFromProperties !== null &&
			!Array.isArray(labelsFromProperties) &&
			Array.isArray(labelsFromProperties.value)
		) {
			return labelsFromProperties.value;
		}

		return [];
	};

	const originalLabels = getLabels(original);
	const remoteLabels = getLabels(remote);

	const removedLabels = difference(originalLabels, remoteLabels);
	const addedLabels = difference(remoteLabels, originalLabels);

	// early exit if nothing has changed, keep object ref equality
	if (removedLabels.length === 0 && addedLabels.length === 0) {
		return labels;
	}

	const labelsByKey: {
		[key: string]: Label;
	} = keyBy(labels, ({ label }) => label);
	const countsByLabel: {
		[key: string]: number;
	} = mapValues(labelsByKey, ({ count }) => count);

	// increase counts for added labels
	addedLabels.forEach((label) => {
		if (!has(countsByLabel, label)) {
			countsByLabel[label] = 1;
		} else {
			countsByLabel[label] += 1;
		}
	});

	// decrease counts for removed labels
	removedLabels.forEach((label) => {
		if (has(countsByLabel, label)) {
			countsByLabel[label] -= 1;
		}
	});

	// re-transform into the expected label type and filter out
	// labels with count less than 1
	return map(countsByLabel, (count, label) => ({
		__typename: 'LabelUsage' as const,
		label,
		count,
	})).filter(({ count }) => count > 0);
};

export const updateInsight =
	(id: string, description: ADF | null | undefined, snippets?: RemoteUpdateSnippet[]) =>
	async ({ getState, setState }: StoreActionApi<State>, containerProps: Props) => {
		const { insightsRemote, onUpdateFailed, createAnalyticsEvent } = containerProps;
		if (!insightsRemote.updateInsight) {
			return;
		}

		const { insights } = getState();

		const originalInsight = insights[id];

		if (!originalInsight) {
			return;
		}

		if (isOptimisticInsight(id)) {
			return;
		}

		const updatedInsight = {
			...originalInsight,
			description: description || null,
			snippets: snippets
				? applySnippetUpdates(originalInsight.snippets, snippets)
				: originalInsight.snippets,
		};

		const newState = {
			...getState(),
			insights: {
				...insights,
				[id]: updatedInsight,
			},
		};

		setState(newState);

		signalInsightsUpdates(newState, containerProps);

		try {
			const remoteInsight = await insightsRemote.updateInsight({
				id,
				description: description || null,
				snippets: snippets || [],
			});

			setState({
				insights: {
					...insights,
					[id]: remoteInsight,
				},
				labels: updatePolarisLabels(getState().labels, originalInsight, remoteInsight),
			});

			const { attributes, containers } = getInsightAnalyticsData(remoteInsight);
			fireTrackAnalytics(createAnalyticsEvent({ containers }), 'insight updated', attributes);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			onUpdateFailed(error);
		}
	};

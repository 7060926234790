import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { addAsLink } from './add-as-link/index.tsx';
import { handleAuthFailure } from './auth/handle-failure.tsx';
import { handleAuthSuccess } from './auth/handle-success.tsx';
import { unfurl } from './unfurl/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	unfurl,
	addAsLink,
	handleAuthSuccess,
	handleAuthFailure,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

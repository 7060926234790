import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	iconLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.removed-field-error.icon-label',
		defaultMessage: 'This field has global field dependencies that have been removed',
		description: 'Icon label for the removed global fields error icon in the preview tooltip',
	},
	removedGlobalFields: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.removed-field-error.removed-global-fields',
		defaultMessage:
			'{fieldsCount, plural, one {A field has been removed} other {Some fields have been removed}}',
		description: 'Trigger that opens the removed global fields error popup when clicked',
	},
	removedGlobalFieldsMissingFields: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.removed-field-error.removed-global-fields-missing-fields',
		defaultMessage:
			"{fieldsCount, plural, one {To make the formula work, {fields} has to be reinstated. If you didn't remove the field, contact your project admin.} other {To make the formula work, {fields} have to be reinstated. If you didn't remove the fields, contact your project admin.}}",
		description:
			'Popup content explaining that one or more of the global field inputs of this formula have been removed',
	},
});

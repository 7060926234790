import {
	isGroupReorderingEnabled,
	isColumnReorderingEnabled,
	isColumnResizingEnabled,
	isItemReorderingEnabled,
	isItemSelectionEnabled,
} from './operations.tsx';
import { createSelectorHook } from './utils.tsx';

export const useIsGroupReorderingEnabled = createSelectorHook(isGroupReorderingEnabled);
export const useIsColumnReorderingEnabled = createSelectorHook(isColumnReorderingEnabled);
export const useIsColumnResizingEnabled = createSelectorHook(isColumnResizingEnabled);
export const useIsItemReorderingEnabled = createSelectorHook(isItemReorderingEnabled);
export const useIsItemSelectionEnabled = createSelectorHook(isItemSelectionEnabled);

import React from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useFieldOptionDecoration } from '../../../../../../../../controllers/index.tsx';

type FieldOptionItemProps = {
	fieldKey: FieldKey;
	optionId: string;
	optionValue: string;
};

export const FieldOptionItem = ({ fieldKey, optionId, optionValue }: FieldOptionItemProps) => {
	const { emojiId, backgroundColor } = useFieldOptionDecoration(fieldKey, optionId);
	const emoji = useEmoji(emojiId);

	return <CommonDecoratedTag value={optionValue} emoji={emoji} mainColor={backgroundColor} />;
};

import gqlTagPolaris from 'graphql-tag';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewFragment } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/index.tsx';
import {
	AccessNotPermittedError,
	isAccessNotPermittedError,
} from '../../../common/errors/access-not-permitted-error.tsx';
import { ViewNotFoundError } from '../../../common/errors/view-not-found-error.tsx';
import type {
	jira_polaris_GetView as GetView,
	jira_polaris_GetView_polarisView as GetViewResponse,
	jira_polaris_GetViewVariables as GetViewVariables,
} from './__generated_apollo__/jira_polaris_GetView';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { GetViewResponse };

const GET_VIEW_QUERY = gqlTagPolaris`
query jira_polaris_GetView(
    $id: ID!,
    $isShareViewDialogEnabled: Boolean!,
    $skipDescription: Boolean!,
) {
    polarisView(id: $id) {
        ...EdgeView
    }
}
${ViewFragment}
`;

export const fetchView = async (
	client: PolarisApolloClient,
	viewId: Ari,
): Promise<GetViewResponse> => {
	experience.view.getView.start();

	try {
		const { data, errors } = await client.query<GetView, GetViewVariables>({
			query: GET_VIEW_QUERY,
			variables: {
				id: viewId,
				isShareViewDialogEnabled: true,
				skipDescription: false,
			},
			fetchPolicy: 'network-only',
		});

		if (errors !== undefined) {
			throw new Error(`project-config.get-view-error:${errors.map((e) => e.message).join(', ')}`);
		}

		if (!data || !data.polarisView) {
			throw new ViewNotFoundError('project-config.get-view-error:view-not-found');
		}

		experience.view.getView.success();

		return data.polarisView;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (isAccessNotPermittedError(error)) {
			experience.view.getView.abort(error);

			throw new AccessNotPermittedError(
				'project-config.get-view-error:view-access-not-permitted',
				error,
			);
		}

		if (
			isClientFetchError(error) ||
			error instanceof ViewNotFoundError ||
			isPermissionError(error)
		) {
			experience.view.getView.abort(error);
		} else {
			experience.view.getView.failure(error);
		}

		throw error;
	}
};

import React, { useMemo } from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Inline, Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	useActions,
	useIsIssueTypeDeletable,
	useIsIssueTypeEditable,
	useIssueTypeAvatarId,
	useIssueTypeDescription,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ExpandableSection } from './expandable-section.tsx';
import { IssueTypeAvatar } from './issue-type-avatar.tsx';
import { IssueTypeContent } from './issue-type-content.tsx';
import { messages } from './messages.tsx';
import { useProjectId } from './project-id-provider.tsx';

type Props = {
	issueTypeId: IssueTypeId;
};

const useIssueType = (issueTypeId: IssueTypeId) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const name = useIssueTypeName({ issueTypeId });
	const description = useIssueTypeDescription({ issueTypeId });

	return useMemo(() => {
		if (avatarId === undefined || name === undefined || description === undefined) {
			return null;
		}
		return { avatarId, name, description };
	}, [avatarId, name, description]);
};

export const IssueTypeSection = ({ issueTypeId }: Props) => {
	const issueType = useIssueType(issueTypeId);
	const isDeletable = useIsIssueTypeDeletable({ issueTypeId });
	const isEditable = useIsIssueTypeEditable({ issueTypeId });
	const projectId = useProjectId();
	const { formatMessage } = useIntl();

	const { updateIssueType, deleteIssueType } = useActions();

	if (issueType === null) {
		// TODO what to display here?
		// Is it even possible in practice to have issue type undefined when we already have its id?
		return null;
	}

	return (
		<ExpandableSection
			key={issueTypeId}
			label={
				<Inline spread="space-between" grow="fill" alignBlock="center">
					<Inline space="space.075" alignBlock="center">
						<IssueTypeAvatar avatarId={issueType.avatarId} size="xsmall" />
						<Text>{issueType.name}</Text>
					</Inline>
					{!isEditable && !isDeletable && (
						<Lozenge>{formatMessage(messages.requiredSystemTypeLabel)}</Lozenge>
					)}
				</Inline>
			}
			renderContent={() => (
				<IssueTypeContent
					issueTypeId={issueTypeId}
					isDeletable={isDeletable}
					isEditable={isEditable}
					initialData={issueType}
					onDataModified={(data) =>
						updateIssueType(issueTypeId, projectId, { ...issueType, ...data })
					}
					onDelete={() => deleteIssueType(issueTypeId, projectId)}
				/>
			)}
		/>
	);
};

import { useMemo } from 'react';
import {
	addMonths,
	addQuarters,
	differenceInCalendarMonths,
	differenceInCalendarQuarters,
	endOfMonth,
	endOfQuarter,
	parseISO,
	startOfMonth,
	startOfQuarter,
} from 'date-fns';
import { useIntl } from '@atlassian/jira-intl';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import {
	useCurrentViewEndDateField,
	useCurrentViewStartDateField,
	useCurrentViewTimelineIntervalEndDate,
	useCurrentViewTimelineIntervalStartDate,
	useCurrentViewTimelineMode,
} from '../../../../views/selectors/view-hooks.tsx';
import { useSelectedIssuesIntervalDateValues } from '../../../selectors/properties/hooks.tsx';
import { useSortedIssueIds } from '../../../selectors/sort-hooks.tsx';
import { messages } from './messages.tsx';
import type { TimelineDuration } from './types.tsx';

export const useTimelineDateFieldsKeys = () => {
	const startDate = useCurrentViewStartDateField();
	const endDate = useCurrentViewEndDateField();

	return useMemo(() => [startDate?.key, endDate?.key], [endDate?.key, startDate?.key]);
};

export const useTimelineDuration = (): TimelineDuration => {
	const mode: PolarisTimelineMode = useCurrentViewTimelineMode();
	const startTimestamp = useCurrentViewTimelineIntervalStartDate();
	const endTimestamp = useCurrentViewTimelineIntervalEndDate();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();

	if (!startDateFieldKey || !endDateFieldKey) {
		return {
			startDate: null,
			endDate: null,
		};
	}

	const defaultEndDate =
		mode === PolarisTimelineMode.MONTHS ? addMonths(new Date(), 5) : addQuarters(new Date(), 3);

	const startDate = startTimestamp ? parseISO(startTimestamp) : new Date();
	const endDate = endTimestamp ? parseISO(endTimestamp) : defaultEndDate;

	const startFn = mode === PolarisTimelineMode.MONTHS ? startOfMonth : startOfQuarter;
	const endFn = mode === PolarisTimelineMode.MONTHS ? endOfMonth : endOfQuarter;

	const timelineStartDate = startDate && startFn(startDate);
	const timelineEndDate = endDate && endFn(endDate);

	return {
		startDate: timelineStartDate,
		endDate: timelineEndDate,
	};
};

export const useTimelineItems = () => {
	const { formatMessage } = useIntl();
	const issueIds = useSortedIssueIds();
	const { startDate: timelineStartDate, endDate: timelineEndDate } = useTimelineDuration();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();
	const allStartDates = useSelectedIssuesIntervalDateValues(startDateFieldKey, issueIds);
	const allEndDates = useSelectedIssuesIntervalDateValues(endDateFieldKey, issueIds);

	return useMemo(() => {
		if (timelineStartDate === null || timelineEndDate === null) {
			return [];
		}

		return Object.entries(allStartDates)
			.filter(([id, startDateInterval]) => {
				const startDate = parseISO(startDateInterval?.value.start);
				const endDate = parseISO(allEndDates[id]?.value.end);

				return (
					allEndDates[id] !== undefined &&
					startDate <= endDate &&
					endDate > timelineStartDate &&
					startDate < timelineEndDate
				);
			})
			.map(([id, startDateInterval]) => ({
				id,
				startDateInterval: {
					label: formatMessage(messages.startDateLabel, {
						date: renderDateString(startDateInterval.value),
					}),
					value: startDateInterval.value,
					isExternal: startDateInterval.isExternal,
				},
				endDateInterval: {
					label: formatMessage(messages.endDateLabel, {
						date: renderDateString(allEndDates[id].value),
					}),
					value: allEndDates[id].value,
					isExternal: allEndDates[id].isExternal,
				},
			}));
	}, [allEndDates, allStartDates, formatMessage, timelineEndDate, timelineStartDate]);
};

export const useTimelineItemIds = (): string[] => {
	const issues = useTimelineItems();

	return useMemo(() => issues.map(({ id }) => id), [issues]);
};

export const useTimelineLength = (): number => {
	const { startDate, endDate } = useTimelineDuration();
	const mode = useCurrentViewTimelineMode();

	if (startDate === null || endDate === null) {
		return 0;
	}

	return mode === PolarisTimelineMode.MONTHS
		? differenceInCalendarMonths(endDate, startDate) + 1
		: differenceInCalendarQuarters(endDate, startDate) + 1;
};

import React, { useMemo } from 'react';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	createFieldRemote,
	createSharingFieldRemote,
	createJqlFieldRemote,
} from './controllers/index.tsx';
import type { FieldRemote } from './types.tsx';

const { RemoteContextProvider, useRemoteFromContext } = createRemoteContext<FieldRemote>('field');

export const createFieldRemoteProvider =
	(projectId: ProjectId): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const fieldRemote = useMemo(
			() => createFieldRemote(apolloClient, createAnalyticsEvent, cloudId, projectId),
			[cloudId, apolloClient, createAnalyticsEvent],
		);

		return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
	};

export const createSharingFieldRemoteProvider =
	(viewId: Ari): ChainableContextProvider =>
	({ children }) => {
		const fieldRemote = useMemo(() => createSharingFieldRemote(viewId), []);

		return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
	};

export const createJqlFieldRemoteProvider =
	(jql: string): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();
		const fieldRemote = useMemo(
			() => createJqlFieldRemote(jql, cloudId, apolloClient),
			[cloudId, apolloClient],
		);

		return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
	};

export const useFieldRemote = useRemoteFromContext;

import { createHook } from '../index.tsx';
import {
	getCanEditField,
	getNotEditableReason,
	getOuterSpacing,
	getProjectId,
	getProjectKey,
	getReadonly,
	getIsPreview,
	getIsDescriptionEditorAiEnabled,
} from './config.tsx';

export const useCanEditField = createHook(getCanEditField);
export const useNotEditableReason = createHook(getNotEditableReason);
export const useOuterSpacing = createHook(getOuterSpacing);
export const useProjectId = createHook(getProjectId);
export const useProjectKey = createHook(getProjectKey);
export const useReadonly = createHook(getReadonly);
export const useIsPreview = createHook(getIsPreview);
export const useIsDescriptionEditorAiEnabled = createHook(getIsDescriptionEditorAiEnabled);

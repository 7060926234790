import React from 'react';
import { SpotlightManager } from '@atlaskit/onboarding';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createStore } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { Props, State } from './types.tsx';

export const OnboardingFlowsStore = createStore<State, typeof actions, Props>({
	name: 'PolarisLibOnboardingFlowsStore',
	initialState: {
		containerProps: {
			accountId: undefined,
		},
		globalFields: {
			goToProjectSpotlightDismissed: undefined,
			tourDismissed: undefined,
			tourSpotlightStep: null,
		},
		projectFields: {
			exploreSectionMessageDismissed: undefined,
			seenPulsatingFieldsButton: undefined,
			tourDismissed: undefined,
			tourSpotlightStep: null,
			pageViewed: undefined,
		},
		collections: {
			tourDismissed: undefined,
			tourSpotlightStep: null,
		},
		crossProjectView: {
			tourDismissed: undefined,
			tourSpotlightStep: null,
		},
		timeline: {
			sectionMessageDismissed: undefined,
		},
		viewPermissions: {
			spotlightDismissed: undefined,
		},
	},
	actions,
});

export const {
	Container: OnboardingFlowsContainerInternal,
	useActions: useOnboardingFlowsActions,
	createHook,
	createHigherLevelHook,
} = createJpdContainer<Props, State, typeof actions>(OnboardingFlowsStore);

export const OnboardingFlowsContainer = ({
	accountId,
	children,
}: React.PropsWithChildren<{
	accountId: Props['accountId'];
}>) => (
	<SpotlightManager>
		<OnboardingFlowsContainerInternal accountId={accountId}>
			{children}
		</OnboardingFlowsContainerInternal>
	</SpotlightManager>
);

import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';

export const getSharingMarkersUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/public/views/${viewUUID}/markers`;

export const getCollectionMarkersUrl = (collectionUUID: string, viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/markers`;

export const getCollectionMarkersUrlWithId = (
	collectionUUID: string,
	viewUUID: string,
	markerUUID: string,
) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/markers/${markerUUID}`;

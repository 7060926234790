import React, { type ReactNode, Fragment, useCallback, useState, memo } from 'react';
import isEqual from 'lodash/isEqual';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import UserPicker, { type UserPickerProps, type Value } from '@atlaskit/user-picker';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { User } from '../../../../common/types/user/index.tsx';
import { getUsers } from '../../../../services/jira/user-picker/index.tsx';
import type { UsersFieldProps } from '../types.tsx';
import messages from './messages.tsx';

const cache: Record<string, User[]> = {};

export const UsersFieldEditable = memo(
	({
		value,
		fieldKey,
		onUpdate,
		users,
		portalElement,
		readView,
		onCancel,
		onEdit,
		editViewContainer: EditViewContainer = Fragment,
	}: UsersFieldProps & { users: User[]; readView: () => ReactNode }) => {
		const { formatMessage } = useIntl();
		const [currentValue, setCurrentValue] = useState<Value>(users);
		const onChange = useCallback<NonNullable<UserPickerProps['onChange']>>((changedValue) => {
			setCurrentValue(changedValue);
		}, []);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleConfirm = () => {
			if (!isEqual(users, currentValue)) {
				// @ts-expect-error - Value to not respect isMulti option
				onUpdate(currentValue);
			}
			onCancel?.();
		};

		const useEditView: InlineEditProps<User[]>['editView'] = (fieldProps) => (
			<EditViewContainer>
				<UserPicker
					{...fieldProps}
					value={currentValue}
					placeholder={formatMessage(messages.placeholder)}
					fieldId={fieldKey}
					open
					autoFocus
					isMulti
					appearance="compact"
					menuPortalTarget={portalElement}
					onFocus={() => {
						fireUIAnalytics(
							createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
							'issueField',
						);
						onEdit?.();
					}}
					styles={{
						menuPortal: (base) => ({ ...base, zIndex: layers.modal }),

						menu: (base) => ({ ...base, zIndex: layers.modal }),
					}}
					loadOptions={(query) => {
						if (cache[query || ''] !== undefined) {
							return Promise.resolve(cache[query || '']);
						}
						return getUsers(query).then((response) => {
							cache[query || ''] = response;
							return response;
						});
					}}
					onChange={onChange}
				/>
			</EditViewContainer>
		);

		return (
			<Box xcss={containerStyles}>
				<InlineEdit
					hideActionButtons
					defaultValue={value}
					readViewFitContainerWidth
					readView={readView}
					editView={useEditView}
					onConfirm={handleConfirm}
				/>
			</Box>
		);
	},
);

const containerStyles = xcss({
	marginTop: 'space.negative.100',
});

import React from 'react';
import memoizeOne from 'memoize-one';
import { PAGE_MARGIN_X } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { RIGHT_SIDEBAR_WIDTH_CSS_VARIABLE } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/utils/sidebar-width-css-variable.tsx';
import { useFullscreenQueryParameter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	ROW_BORDER_WIDTH,
	TABLE_VERTICAL_SCROLLBAR_SIZE_CSS_VARIABLE,
} from '@atlassian/jira-polaris-lib-list/src/ui/constants.tsx';
import {
	RowContainerSticky,
	type RowContainerStickyProps,
} from '@atlassian/jira-polaris-lib-list/src/ui/row-container-sticky/index.tsx';

type RowStickyProps = Omit<RowContainerStickyProps, 'containerStickyWidth'>;

export const RowSticky = ({ children, containerStickyClassName }: RowStickyProps) => {
	const fullscreen = useFullscreenQueryParameter();

	return (
		<RowContainerSticky
			containerStickyClassName={containerStickyClassName}
			containerStickyWidth={calcContainerStickyWidth(fullscreen)}
		>
			{children}
		</RowContainerSticky>
	);
};

const calcContainerStickyWidth = memoizeOne(
	(fullscreen: boolean): string =>
		`calc(100vw - ${PAGE_MARGIN_X}px - ${ROW_BORDER_WIDTH * 2}px - var(${TABLE_VERTICAL_SCROLLBAR_SIZE_CSS_VARIABLE}, 0px) - ${fullscreen ? '0px' : 'var(--leftSidebarWidth, 0px)'} - var(${RIGHT_SIDEBAR_WIDTH_CSS_VARIABLE}, 0px))`,
);

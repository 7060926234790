import React from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { TypesAccordion } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/types-accordion.tsx';
import { BreadcrumbsProjectSettings } from '@atlassian/jira-polaris-ideas/src/ui/settings/breadcrumbs/index.tsx';
import messages from './messages.tsx';

export const ProjectTypesScreen = () => {
	const { formatMessage } = useIntl();
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return (
			<Stack xcss={stackStyles}>
				<PageHeader
					breadcrumbs={<BreadcrumbsProjectSettings />}
					bottomBar={<>{formatMessage(messages.pageDescription)}</>}
				>
					{formatMessage(messages.pageHeader)}
				</PageHeader>

				<TypesAccordion projectId={container.id} />
			</Stack>
		);
	}

	// TODO what to do when we are not in the project container?
	return null;
};

const stackStyles = xcss({
	width: '500px',
});

import React, { memo, type PropsWithChildren } from 'react';
import { OnboardingSweetStateContainer } from '../controllers/index.tsx';
import { useShouldUserSeeVideoAndCommunityFlag } from '../controllers/selectors/should-see-onboarding-hooks.tsx';
import type { Props as OnboardingSweetStateContainerProps } from '../controllers/types.tsx';
import { LazyHelpPanelVideoTutorialPulse } from './help-panel-video-tutorial-pulse/async.tsx';
import { JoinCommunityFlag } from './join-community/index.tsx';

type Props = PropsWithChildren<OnboardingSweetStateContainerProps>;

export const OnboardingContainerInternal = memo(() => {
	const shouldUserSeeVideoAndCommunityFlag = useShouldUserSeeVideoAndCommunityFlag();

	if (!shouldUserSeeVideoAndCommunityFlag) {
		return null;
	}

	return (
		<>
			<LazyHelpPanelVideoTutorialPulse />
			<JoinCommunityFlag />
		</>
	);
});

export const OnboardingContainer = memo(({ children, ...props }: Props) => (
	<OnboardingSweetStateContainer {...props} scope="onboarding-singleton">
		{children}
		<OnboardingContainerInternal />
	</OnboardingSweetStateContainer>
));

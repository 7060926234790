/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@compiled/react';
import { CLASS_ROW } from '../../common/ui/constants/index.tsx';
import { ROW_BORDER_RADIUS, ROW_BORDER_WIDTH, borderColor } from '../constants.tsx';

export type RowContainerStickyProps = {
	children: React.ReactNode;
	containerStickyClassName?: string;
	containerStickyWidth?: string;
};

export const RowContainerSticky = ({
	children,
	containerStickyClassName,
	containerStickyWidth,
}: RowContainerStickyProps) => (
	<div css={containerStyles}>
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={containerStickyClassName}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ width: containerStickyWidth }}
			css={containerStickyStyles}
		>
			{children}
		</div>
	</div>
);

const containerStyles = css({
	position: 'relative',
	display: 'flex',
	width: '100%',
	height: '100%',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	[`.${CLASS_ROW}.row--pinned-bottom &`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		borderBottomLeftRadius: `${ROW_BORDER_RADIUS}px`,
	},
});

const containerStickyStyles = css({
	overflow: 'hidden',
	position: 'sticky',
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderLeft: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	[`.${CLASS_ROW}.row--pinned-bottom &`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		borderBottomLeftRadius: `${ROW_BORDER_RADIUS}px`,
	},
});

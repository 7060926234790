import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { TOOLTIP_WIDTH } from './constants.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-unsafe-style-overrides -- To migrate as part of go/ui-styling-standard
export const TooltipComponent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	backgroundColor: token('elevation.surface', N0),
	transition: 'box-shadow 0.3s ease, background 0.3s ease',
	borderRadius: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: TOOLTIP_WIDTH,
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0px 8px 12px 0px ${colors.N40A},
		${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        0px 0px 1px 0px ${colors.N60A}`,
	),
});

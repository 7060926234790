import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { type FieldListField, HANDLED_FIELD_TYPES } from './types.tsx';

/**
 * Filters out fields not handled by the FieldList component.
 * This is needed for the card height calculation because some fields are
 * not handled by the FieldList component, and we don't want to render it in this
 * case otherwise it will show some unnecessary gap on the cards because the element of the
 * FieldsList component will be in the DOM, without any children inside.
 */
export const getFieldListFilteredFields = (
	fields: Field[],
	isCompact: boolean,
): FieldListField[] => {
	const fieldTypesHiddenInCompact = [
		FIELD_TYPES.DELIVERY_STATUS,
		FIELD_TYPES.DELIVERY_PROGRESS,
		FIELD_TYPES.SHORT_TEXT,
	];

	return fields
		.filter((field): field is FieldListField => HANDLED_FIELD_TYPES.some((t) => t === field.type))
		.filter((field) => !isCompact || !fieldTypesHiddenInCompact.some((t) => t === field.type));
};

import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

export const SharingSettingsOpeningSource = 'SHARING_SETTINGS';
export type RightSidebarOpeningSource = typeof SharingSettingsOpeningSource;

export const RightSidebarShowingNothing = 'NOTHING' as const;
export const RightSidebarShowingFieldList = 'FIELD_LIST' as const;
export const RightSidebarShowingField = 'FIELD' as const;
export const RightSidebarShowingCreateField = 'CREATE_FIELD' as const;
export const RightSidebarShowingFilter = 'FILTER' as const;
export const RightSidebarShowingPlay = 'PLAY' as const;
export const RightSidebarShowingSort = 'SORT' as const;
export const RightSidebarShowingGroupByOptions = 'GROUP_BY_OPTIONS' as const;
export const RightSidebarShowingVerticalGroupByOptions = 'VERTICAL_GROUP_BY_OPTIONS' as const;
export const RightSidebarShowingXAxisOptions = 'MATRIX_X_AXIS_OPTIONS' as const;
export const RightSidebarShowingYAxisOptions = 'MATRIX_Y_AXIS_OPTIONS' as const;
export const RightSidebarShowingZAxisOptions = 'MATRIX_Z_AXIS_OPTIONS' as const;
export const RightSidebarShowingIdeaTemplates = 'IDEA_TEMPLATES' as const;
export const RightSidebarShowingTimelineConfig = 'TIMELINE_CONFIG' as const;
export const RightSidebarShowingIdeasBucket = 'IDEAS_BUCKET' as const;
export const RightSidebarShowingGlobalFields = 'GLOBAL_FIELDS' as const;
export const RightSidebarShowingViewInfo = 'VIEW_INFO' as const;

export type SidebarOrigin = 'projectFieldsPage' | 'fieldsList';

export type Showing =
	| {
			mode: typeof RightSidebarShowingNothing;
	  }
	| {
			mode: typeof RightSidebarShowingFieldList;
	  }
	| {
			mode: typeof RightSidebarShowingField;
			fieldKey: FieldKey;
			backTo?: Showing;
			origin?: SidebarOrigin;
	  }
	| {
			mode: typeof RightSidebarShowingCreateField;
			backTo?: Showing | RightSidebarOpeningSource;
			defaultFieldName?: string;
			onCreate?: (fieldKey: FieldKey) => void;
			origin?: SidebarOrigin;
	  }
	| {
			mode: typeof RightSidebarShowingFilter;
			tmpFieldKey?: FieldKey;
	  }
	| {
			mode: typeof RightSidebarShowingSort;
	  }
	| {
			mode: typeof RightSidebarShowingGroupByOptions;
	  }
	| {
			mode: typeof RightSidebarShowingVerticalGroupByOptions;
	  }
	| {
			mode: typeof RightSidebarShowingXAxisOptions;
	  }
	| {
			mode: typeof RightSidebarShowingYAxisOptions;
	  }
	| {
			mode: typeof RightSidebarShowingZAxisOptions;
	  }
	| {
			mode: typeof RightSidebarShowingPlay;
			localIssueId: LocalIssueId;
			playId: Ari;
	  }
	| {
			mode: typeof RightSidebarShowingIdeaTemplates;
			onApplyTemplate: (template: ADF | undefined) => void;
	  }
	| {
			mode: typeof RightSidebarShowingTimelineConfig;
	  }
	| {
			mode: typeof RightSidebarShowingIdeasBucket;
	  }
	| {
			mode: typeof RightSidebarShowingGlobalFields;
	  }
	| { mode: typeof RightSidebarShowingViewInfo };

export type PreventClosing = 'all' | 'onEsc' | false;

export type State = {
	showing: Showing;
	preventClosing: PreventClosing;
	option: string | null;
};

export const showingNothing: Showing = {
	mode: RightSidebarShowingNothing,
};

export const showingFieldList: Showing = {
	mode: RightSidebarShowingFieldList,
};

export const showingCreateField = (
	origin?: SidebarOrigin,
	backTo?: Showing | RightSidebarOpeningSource,
	defaultFieldName?: string,
	onCreate?: (fieldKey: FieldKey) => void,
): Showing => ({
	mode: RightSidebarShowingCreateField,
	backTo,
	defaultFieldName,
	onCreate,
	origin,
});

export const showingIdeaTemplates = (
	onApplyTemplate: (template: ADF | undefined) => void,
): Showing => ({
	mode: RightSidebarShowingIdeaTemplates,
	onApplyTemplate,
});

export const showingField = (
	fieldKey: FieldKey,
	backTo?: Showing,
	origin?: SidebarOrigin,
): Showing => ({
	mode: RightSidebarShowingField,
	fieldKey,
	backTo,
	origin,
});

export const showingFilter = (tmpFieldKey?: FieldKey): Showing => ({
	mode: RightSidebarShowingFilter,
	tmpFieldKey,
});

export const showingPlay = (localIssueId: LocalIssueId, playId: Ari): Showing => ({
	mode: RightSidebarShowingPlay,
	localIssueId,
	playId,
});

export const showingSort: Showing = {
	mode: RightSidebarShowingSort,
};

export const showingGroupByOptions: Showing = {
	mode: RightSidebarShowingGroupByOptions,
};

export const showingVerticalGroupByOptions: Showing = {
	mode: RightSidebarShowingVerticalGroupByOptions,
};

export const showingXAxisOptions: Showing = {
	mode: RightSidebarShowingXAxisOptions,
};

export const showingYAxisOptions: Showing = {
	mode: RightSidebarShowingYAxisOptions,
};

export const showingZAxisOptions: Showing = {
	mode: RightSidebarShowingZAxisOptions,
};

export const showingTimelineConfig: Showing = {
	mode: RightSidebarShowingTimelineConfig,
};

export const showingIdeasBucket: Showing = {
	mode: RightSidebarShowingIdeasBucket,
};

export const showingGlobalFields: Showing = {
	mode: RightSidebarShowingGlobalFields,
};

export const showingViewInfo: Showing = {
	mode: RightSidebarShowingViewInfo,
};

import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import { getAriConfig, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	PolarisPlay,
	PolarisPlayContribution,
} from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { LoadingMeta, State } from '../types.tsx';

const EMPTY_PLAY_IDS_TO_ARIS: Record<string, string> = Object.freeze({});
const EMPTY_PLAY_CONTRIBUTIONS: Record<
	string,
	Record<string, PolarisPlayContribution[]>
> = Object.freeze({});

export const getPlays = (state: State): PolarisPlay[] | null => state.project.plays;

export const getPlayContributionsByIssueId = createSelector(getPlays, (plays) => {
	if (plays === null) {
		return EMPTY_PLAY_CONTRIBUTIONS;
	}

	return plays.reduce<Record<string, Record<string, PolarisPlayContribution[]>>>((acc, play) => {
		const { contributions } = play;
		const playId = getAriConfig(play.id).resourceId;
		acc[playId] = groupBy(
			contributions || [],
			(contribution) => getAriConfig(contribution.subject).resourceId,
		);
		return acc;
	}, {});
});

export const getPlayIdsToAris = createSelector(getPlays, (plays) => {
	if (plays === null) {
		return EMPTY_PLAY_IDS_TO_ARIS;
	}

	return plays.reduce<Record<string, string>>((acc, play) => {
		const playId = getAriConfig(play.id).resourceId;
		acc[playId] = play.id;
		return acc;
	}, {});
});

/**
 * Returns play by id
 */
export const getPlayById = (playId?: Ari) =>
	createSelector(getPlays, (plays) => plays?.find(({ id }) => id === playId));

export const getPlayCommentsLoadingMeta = (state: State): LoadingMeta => state.meta.playComments;

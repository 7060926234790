import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-view-access.access-screen.no-editors-section.title',
		defaultMessage: 'This view has no editors',
		description: 'Title of a notification we show when the view has no editors',
	},
	content: {
		id: 'polaris-component-view-access.access-screen.no-editors-section.content',
		defaultMessage:
			'Reach out to your Jira admins to change the access for this view. Alternatively duplicate this view to edit as needed.',
		description: 'Content of a notification we show when the view has no editors',
	},
	duplicateViewAction: {
		id: 'polaris-component-view-access.access-screen.no-editors-section.duplicate-view-action',
		defaultMessage: 'Duplicate view',
		description:
			'Action button for cloning the current view that appears if the view has no editors',
	},
});

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraApiGlobalField } from '../types.tsx';

export const searchGlobalFields = async ({
	fieldTypes,
	query,
}: {
	fieldTypes?: string[];
	query?: string;
}): Promise<JiraApiGlobalField[]> =>
	fetchJson<JiraApiGlobalField[]>('/rest/polaris/global-fields/search', {
		method: 'POST',
		body: JSON.stringify({
			query,
			fieldTypes,
		}),
	});

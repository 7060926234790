import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	shortTextFieldTypeError: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.link-field.edit.short-text.short-text-field-type-error',
		defaultMessage: 'Short text fields are limited to 255 characters',
		description: 'Error message for too long short text field values',
	},
	errorIcon: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.link-field.edit.short-text.error-icon',
		defaultMessage: 'Error',
		description: 'Error icon description',
	},
});

import React, { useEffect, useState } from 'react';
import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/new';
import Form from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { get as getAvatars } from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/rest.tsx';
import type { AvatarCollections } from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { IssueTypeFormFields } from './issue-type-form-fields.tsx';
import { messages } from './messages.tsx';
import { useProjectId } from './project-id-provider.tsx';
import type { FormData } from './types.tsx';

type Props = {
	onIssueTypeReady: (data: FormData) => Promise<void>;
	onCancel: () => void;
};

// TODO replace with something more reasonable
const useFirstAvailableAvatar = () => {
	const projectId = useProjectId();
	const [avatarCollections, setAvatarCollections] = useState<null | AvatarCollections>(null);

	useEffect(() => {
		getAvatars(Number(projectId)).then(setAvatarCollections);
	}, [projectId]);

	return avatarCollections?.system[0].id ?? null;
};

export const IssueTypeCreationForm = ({ onIssueTypeReady, onCancel }: Props) => {
	const { formatMessage } = useIntl();

	const avatarId = useFirstAvailableAvatar();

	if (avatarId === null) {
		return null;
	}

	const initialData: FormData = {
		name: '',
		description: '',
		avatarId,
	};

	return (
		<Form<FormData>
			onSubmit={(data) => {
				return onIssueTypeReady({
					name: data.name.trim(),
					description: data.description.trim(),
					avatarId: data.avatarId,
				});
			}}
		>
			{({ formProps, submitting }) => (
				<form {...formProps}>
					<Box xcss={formBodyStyles}>
						<IssueTypeFormFields defaultValues={initialData} isEditable />
					</Box>

					<Box xcss={formFooterStyles}>
						<ButtonGroup>
							<Button appearance="subtle" onClick={onCancel}>
								{formatMessage(messages.cancel)}
							</Button>
							<Button type="submit" appearance="primary" isLoading={submitting}>
								{formatMessage(messages.create)}
							</Button>
						</ButtonGroup>
					</Box>
				</form>
			)}
		</Form>
	);
};

const formBodyStyles = xcss({
	paddingBlockEnd: 'space.150',
});

const formFooterStyles = xcss({
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
	paddingBlock: 'space.150',
	display: 'flex',
	justifyContent: 'end',
});

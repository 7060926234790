import type { MouseEvent } from 'react';
import { di } from 'react-magnetic-di';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { IssueViewSection } from '../../types/issue/index.tsx';
import { shouldOpenInNewTab } from '../events/index.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';

export const getIssueLink = (
	projectKey?: string,
	issueKey?: string,
	isSharedView?: boolean,
): string | undefined => {
	di(window);
	if (!issueKey) return undefined;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (isSharedView) return `${window.location.pathname}?selectedIssue=${issueKey}`;

	return projectKey
		? `/jira/polaris/projects/${projectKey}/ideas?selectedIssue=${issueKey}`
		: undefined;
};

export const getDirectIssueLink = (issueKey?: string, isSharedView?: boolean) => {
	di(window);
	return isSharedView
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.origin}${window.location.pathname}?selectedIssue=${issueKey}`
		: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.origin}/browse/${issueKey}`;
};

type UseIssueLinkConfig = {
	issueKey: string;
	shouldOpenInSidebar?: boolean;
	issueViewSection?: IssueViewSection;
};

export const useIssueLink = ({
	issueKey,
	shouldOpenInSidebar = false,
	issueViewSection,
}: UseIssueLinkConfig) => {
	di(window);
	const { openIssueView } = usePolarisRouter();
	const container = useEnvironmentContainer();
	const currentProjectKey =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectKey : undefined;

	const projectKey = issueKey.split('-')[0];
	const isSameProject = currentProjectKey && projectKey === currentProjectKey;
	const link = isSameProject
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.pathname}?selectedIssue=${issueKey}${shouldOpenInSidebar ? '&issueViewLayout=sidebar' : ''}${issueViewSection ? `&issueViewSection=${issueViewSection}` : ''}`
		: `/browse/${issueKey}`;

	// prevents closing the issue view when clicking on the link and opens another issue in the sidebar
	const onClick = (e: MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();

		if (!isSameProject || shouldOpenInNewTab(e)) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(link, '_blank');
			return;
		}

		openIssueView(issueKey, {
			layout: shouldOpenInSidebar ? 'sidebar' : undefined,
			section: issueViewSection,
		});
	};

	return {
		target: isSameProject ? undefined : ('_blank' as const),
		link,
		onClick,
	};
};

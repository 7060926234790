import React from 'react';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useGetIdeaPlayTotalBudget,
	useGetIdeaPlayTotalContributors,
	useGetIdeaPlayUserContribution,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/plays/hooks.tsx';
import { useOpenRightSidebarOnPlay } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useCurrentUserAccountId } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import { CommonPlayField } from '@atlassian/jira-polaris-common/src/ui/fields/play/legacy/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type Props = {
	fieldKey: FieldKey;
};

type PlayFieldProps = {
	localIssueId: LocalIssueId;
	playId: Ari;
	fieldKey: FieldKey;
};

const PlayField = (props: PlayFieldProps) => {
	const { localIssueId, playId, fieldKey } = props;
	const isIdeaArchived = useIsSelectedIssueArchived();

	const openRightSidebarOnPlay = useOpenRightSidebarOnPlay();

	const totalBudget = useGetIdeaPlayTotalBudget(playId, localIssueId);
	const totalContributors = useGetIdeaPlayTotalContributors(playId, localIssueId);

	const currentUserAccountId = useCurrentUserAccountId();
	const currentUserContribution = useGetIdeaPlayUserContribution(
		playId,
		localIssueId,
		currentUserAccountId,
	);

	return (
		<CommonPlayField
			isEditable={!isIdeaArchived}
			isCurrentUserContributed={!!currentUserContribution}
			totalContributors={totalContributors}
			showPlaceholderLabel="always"
			appearance="list"
			value={totalBudget}
			localIssueId={localIssueId}
			playId={playId}
			fieldKey={fieldKey}
			onClick={openRightSidebarOnPlay}
		/>
	);
};

export const Play = ({ fieldKey }: Props) => {
	const field = useField(fieldKey);
	const localIssueId = useSelectedIssue();

	if (field?.play?.id === undefined || localIssueId === undefined) {
		return null;
	}

	// TODO  POL-2296 use existing PlayField from common
	return <PlayField localIssueId={localIssueId} playId={field.play.id} fieldKey={field.key} />;
};

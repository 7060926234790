import React, { useMemo, useCallback, useEffect } from 'react';
import has from 'lodash/has';
import uniq from 'lodash/uniq';
import { useWrappedRealtimeHandlerWithQueue } from '@atlassian/jira-polaris-lib-realtime-queue/src/index.tsx';
import {
	type RealtimeContainerType,
	APP_ID_PREFIX,
} from '@atlassian/jira-polaris-lib-realtime/src/common/types.tsx';
import { useRealtimeChannels } from '@atlassian/jira-polaris-lib-realtime/src/common/utils.tsx';
import Realtime from '@atlassian/jira-realtime/src/main.tsx';
import {
	INSIGHT_CREATED_EVENT,
	INSIGHT_DELETED_EVENT,
	INSIGHT_UPDATED_EVENT,
	ISSUE_EVENTS_BUNDLED,
	PLAY_CONTRIBUTION_CREATED_EVENT,
	PLAY_CONTRIBUTION_DELETED_EVENT,
	PLAY_CONTRIBUTION_UPDATED_EVENT,
	PLAY_UPDATED_EVENT,
	POLARIS_SUBSCRIPTION_EVENTS,
} from '../../common/types/realtime/constants.tsx';
import type { IdeaRealtimeEvent } from '../../common/types/realtime/types.tsx';
import type { Props } from './types.tsx';

const checkPolarisPayload = (
	{ payload }: IdeaRealtimeEvent,
	containerId: string | number | undefined,
	containerType: RealtimeContainerType,
) => {
	// Batched polaris event
	if (Array.isArray(payload)) {
		return true;
	}

	if (containerType === 'PROJECT' && has(payload, 'projectId')) {
		return String(containerId) === String(payload.projectId);
	}
	if (containerType === 'ISSUE' && payload && 'issueId' in payload && has(payload, 'issueId')) {
		return String(containerId) === String(payload.issueId);
	}

	return false;
};

const usePolarisRealtimeEventGuard = (
	handler: (event: IdeaRealtimeEvent) => void,
	containerId: string | number | undefined,
	containerType: RealtimeContainerType,
) =>
	useCallback(
		(event: IdeaRealtimeEvent) => {
			if (checkPolarisPayload(event, containerId, containerType)) {
				handler(event);
			}
		},
		[containerId, containerType, handler],
	);

export const PolarisRealtime = ({
	appId,
	config,
	containerType,
	containerId,
	shouldSkipEvent = () => false,
	onInsightCreated,
	onInsightDeleted,
	onInsightUpdated,
	onPlayContributionCreated,
	onPlayContributionDeleted,
	onPlayContributionUpdated,
	onPlayUpdated,
	onJoin,
	additionalChannels,
	additionalEvents,
	onNonPolarisEvent,
}: Props) => {
	const polarisChannels = useRealtimeChannels(containerId, containerType);

	const channels = useMemo(
		() => uniq([...polarisChannels, ...(additionalChannels || [])]),
		[additionalChannels, polarisChannels],
	);

	const events = useMemo(
		() => uniq([...POLARIS_SUBSCRIPTION_EVENTS, ...(additionalEvents || [])]),
		[additionalEvents],
	);

	const onReceive = useCallback(
		(event: IdeaRealtimeEvent) => {
			const isBundledEvent = Array.isArray(event.payload);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			const unifiedEvent = isBundledEvent ? (event.payload as any) : event;

			switch (event.type) {
				case INSIGHT_CREATED_EVENT:
					onInsightCreated(unifiedEvent);
					break;
				case INSIGHT_UPDATED_EVENT:
					onInsightUpdated(unifiedEvent);
					break;
				case INSIGHT_DELETED_EVENT:
					onInsightDeleted(unifiedEvent);
					break;
				case PLAY_CONTRIBUTION_CREATED_EVENT:
					onPlayContributionCreated?.(unifiedEvent);
					break;
				case PLAY_CONTRIBUTION_UPDATED_EVENT:
					onPlayContributionUpdated?.(unifiedEvent);
					break;
				case PLAY_CONTRIBUTION_DELETED_EVENT:
					onPlayContributionDeleted?.(unifiedEvent);
					break;
				case PLAY_UPDATED_EVENT:
					onPlayUpdated?.(unifiedEvent);
					break;
				case ISSUE_EVENTS_BUNDLED:
					onNonPolarisEvent?.(unifiedEvent);
					break;
				default:
					onNonPolarisEvent?.(unifiedEvent);
					break;
			}
		},
		[
			onInsightCreated,
			onInsightDeleted,
			onInsightUpdated,
			onNonPolarisEvent,
			onPlayContributionCreated,
			onPlayContributionDeleted,
			onPlayContributionUpdated,
			onPlayUpdated,
		],
	);

	// Filter out events that are not related to polaris
	const onReceivePolarisEvent = usePolarisRealtimeEventGuard(onReceive, containerId, containerType);

	// Process real time invents with queue
	const [onReceiveWithQueue, clearEvents] = useWrappedRealtimeHandlerWithQueue(
		onReceivePolarisEvent,
		config,
		shouldSkipEvent,
	);

	const onJoinCallback = useCallback(() => {
		if (onJoin !== undefined) {
			onJoin();
		}
	}, [onJoin]);

	useEffect(() => () => clearEvents(), [clearEvents]);

	return (
		<Realtime
			appId={`${APP_ID_PREFIX}${appId}`}
			channels={channels}
			events={events}
			onReceive={onReceiveWithQueue}
			onJoin={onJoinCallback}
		/>
	);
};

import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { Pulse } from '@atlassian/jira-polaris-lib-non-blocking-spotlight/src/common/ui/pulse/index.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { checkIfActivatedMoreThanFourDays } from '../../../common/utils.tsx';
import { useOnboardingFlowsActions } from '../../../controllers/index.tsx';
import {
	useExploreSectionMessageDismissed,
	useProjectFieldsPageViewed,
	useSeenPulsatingFieldsButton,
} from '../../../controllers/selectors/project-fields-hooks.tsx';

type Props = React.PropsWithChildren<{
	productUsageStartTimestamp?: string;
	pulse: boolean;
}>;

export const FieldsButtonWrapper = ({ children, productUsageStartTimestamp, pulse }: Props) => {
	const projectFieldsPageViewed = useProjectFieldsPageViewed();
	const seenPulsatingFieldsButton = useSeenPulsatingFieldsButton();
	const exploreSectionMessageDismissed = useExploreSectionMessageDismissed();
	const {
		loadSeenPulsatingFieldsButton,
		loadExploreSectionMessageDismissed,
		loadProjectFieldsPageViewed,
		setSeenPulsatingFieldsButton,
	} = useOnboardingFlowsActions();

	const isActiveMoreThanFourDays = productUsageStartTimestamp
		? checkIfActivatedMoreThanFourDays(productUsageStartTimestamp)
		: false;

	useEffect(() => {
		if (pulse) {
			loadSeenPulsatingFieldsButton()?.then(() => {
				loadExploreSectionMessageDismissed();
				loadProjectFieldsPageViewed();
			});
		}
	}, [
		pulse,
		loadSeenPulsatingFieldsButton,
		loadExploreSectionMessageDismissed,
		loadProjectFieldsPageViewed,
	]);

	if (
		!pulse ||
		!isActiveMoreThanFourDays ||
		// If the section message is dismissed, we don't want the button to pulsate
		exploreSectionMessageDismissed !== false ||
		projectFieldsPageViewed !== false ||
		seenPulsatingFieldsButton !== false
	) {
		return <>{children}</>;
	}

	return (
		<>
			<FireScreenAnalytics attributes={{ isFieldsButtonPulsating: true }} />
			<StyledPulse pulse radius={3} onClick={() => setSeenPulsatingFieldsButton()}>
				{children}
			</StyledPulse>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPulse = styled(Pulse)({
	zIndex: 'initial',
});

import React from 'react';
import keyBy from 'lodash/keyBy';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldOptionsWithAriResolved } from '../../../../../../controllers/issue/selectors/fields-hooks.tsx';
import { DecoratedTag } from '../../../../../common/decoration/decoration/tag/index.tsx';
import messages from './messages.tsx';

type Props = {
	field: Field;
	groupIdentity: string | undefined;
};

export const optionNoValueOptionMessage = messages.noValueOption;

export const OptionOptionRenderer = ({ field, groupIdentity }: Props) => {
	const options = useFieldOptionsWithAriResolved(field.key);
	const { formatMessage } = useIntl();

	if (groupIdentity === undefined) {
		return <Box paddingInlineStart="space.100">{formatMessage(optionNoValueOptionMessage)}</Box>;
	}

	const optionsByKey = keyBy(options, ({ id }) => id);
	const option = optionsByKey[groupIdentity];
	return option !== undefined ? (
		<DecoratedTag fieldKey={field.key} id={groupIdentity} value={option.value} />
	) : null;
};

import React, { type ChangeEvent } from 'react';
import Textfield from '@atlaskit/textfield';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldType } from '../../../../controllers/field/selectors/field-hooks.tsx';
import type { FieldComponentProps } from '../types.tsx';

type TextFieldProps = FieldComponentProps & {
	fieldKey: FieldKey;
};

export const TextField = (props: TextFieldProps) => {
	const { onUpdate, fieldKey, isEditable, isPreview, ...fieldProps } = props;

	const fieldType = useFieldType(fieldKey);

	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		onUpdate(event.target.value);
	};

	return (
		<Textfield
			{...fieldProps}
			isDisabled={!isEditable}
			onChange={onChangeHandler}
			autoFocus={!isPreview && fieldType === FIELD_TYPES.SUMMARY}
			width={fieldType === FIELD_TYPES.HYPERLINK ? 350 : '100%'}
			placeholder={fieldType === FIELD_TYPES.HYPERLINK ? 'https://' : undefined}
		/>
	);
};

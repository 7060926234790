import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Inline, Box, Text, xcss } from '@atlaskit/primitives';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import { FieldConfiguration } from '@atlassian/jira-polaris-component-field-configuration/src/main.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useFieldValueDecorationForField } from '../../../../controllers/index.tsx';

type GlobalFieldOptionProps = {
	field: Field;
};

export const GlobalFieldOption = ({ field }: GlobalFieldOptionProps) => {
	const { label, type, emoji: emojiId } = field;
	const emoji = useEmoji(emojiId);
	const icon = iconForPolarisFieldType(type);
	const valueDecorations = useFieldValueDecorationForField(field.key);

	return (
		<Tooltip
			component={TooltipPrimitive}
			position="left-start"
			content={() => (
				<Box xcss={previewTooltipStyles}>
					<FieldConfiguration
						fieldData={{ field, valueDecorations }}
						isPreview
						readonly
						canEditField
					/>
				</Box>
			)}
		>
			{(tooltipProps) => (
				<Box
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...tooltipProps}
				>
					<Inline xcss={fieldStyles} space="space.100">
						<Box>{emoji ? <Emoji emoji={emoji} fitToHeight={16} /> : icon}</Box>
						<Text>{label}</Text>
					</Inline>
				</Box>
			)}
		</Tooltip>
	);
};

const fieldStyles = xcss({
	paddingInline: 'space.100',
	paddingBlock: 'space.075',
});

const previewTooltipStyles = xcss({
	maxWidth: '300px',
	marginRight: 'space.075',
	borderRadius: 'border.radius',
	color: 'color.text',
	backgroundColor: 'elevation.surface',
	boxShadow: 'elevation.shadow.overflow',
	padding: 'space.200',
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	linkIssueSelectHelperText: {
		id: 'polaris-common.link-issue-select.link-issue-select-helper-text',
		defaultMessage:
			"You can't search issues with the {status} status. Copy-paste the URL to link an issue instead.",
		description: 'Link issue select helper text',
	},
	linkIssueSelectHelperTextIssueTermRefresh: {
		id: 'polaris-common.link-issue-select.link-issue-select-helper-text-issue-term-refresh',
		defaultMessage:
			"You can't search work items with the {status} status. Copy-paste the URL to link a work item instead.",
		description: 'Link issue select helper text',
	},
	doneStatusLabel: {
		id: 'polaris-common.link-issue-select.done-status-label',
		defaultMessage: 'Done',
		description: 'Done status label for link issue select',
	},
});

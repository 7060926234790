import React, { useMemo, useState, useCallback } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { useUnfurlActions, useUnfurlProps, useUnfurlState } from '../../index.tsx';
import { AuthenticateApiKeyButton } from './apikey/index.tsx';
import { AuthorizeAppLinksButton } from './applinks/index.tsx';
import { messages } from './messages.tsx';
import { AuthenticateOauth2Button } from './oauth2/index.tsx';

export const useAuthentication = () => {
	const { auth } = useUnfurlState();
	const { url, externalErrorType } = useUnfurlProps();
	const [triggerOnMount, setTriggerOnMount] = useState(false);
	const { handleAuthSuccess, handleAuthFailure, unfurl } = useUnfurlActions();
	const { formatMessage } = useIntl();

	const handleGenericAuthentication = useCallback(() => {
		setTriggerOnMount(true);
		unfurl();
	}, [unfurl]);

	const authenticationButton = useMemo(() => {
		if (url === undefined) {
			return null;
		}

		if (auth && auth.isForbidden && auth.authorizationUrl !== undefined) {
			return (
				<AuthorizeAppLinksButton
					triggerOnMount={triggerOnMount}
					authorizeUrl={auth.authorizationUrl}
					url={url}
					onSuccess={handleAuthSuccess}
					onFailure={handleAuthFailure}
				/>
			);
		}
		if (auth && auth.authUrl !== undefined && auth.authType === 'OAUTH2') {
			return (
				<AuthenticateOauth2Button
					triggerOnMount={triggerOnMount}
					authUrl={auth.authUrl}
					url={url}
					text={auth.isForbidden ? formatMessage(messages.restrictedLink) : undefined}
					onSuccess={handleAuthSuccess}
					onFailure={handleAuthFailure}
				/>
			);
		}
		if (auth && auth.authType === 'API_KEY') {
			return (
				<AuthenticateApiKeyButton
					text={auth.isForbidden ? formatMessage(messages.restrictedLink) : undefined}
					triggerOnMount={triggerOnMount}
					authHint={auth.authHint}
					onSuccess={handleAuthSuccess}
				/>
			);
		}

		if (externalErrorType === 'NEED_AUTH' && !triggerOnMount) {
			// no specifics know, but we know the external state is in need auth - show a trigger button
			return (
				<Button
					testId="polaris-common.controllers.unfurl.utils.authentication.button"
					onClick={handleGenericAuthentication}
				>
					{formatMessage(messages.authenticateButton)}
				</Button>
			);
		}

		return null;
	}, [
		auth,
		externalErrorType,
		formatMessage,
		handleAuthFailure,
		handleAuthSuccess,
		handleGenericAuthentication,
		triggerOnMount,
		url,
	]);

	return useMemo(
		() => ({
			AuthenticationButton: authenticationButton,
			requiresAuthentication: authenticationButton !== null,
		}),
		[authenticationButton],
	);
};

import values from 'lodash/values';
import { createAri, toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { State, Props } from '../types.tsx';

export const signalInsightsUpdates = (
	{ insights }: State,
	{ cloudId, issueId, onUpdateInsight }: Props,
) => {
	if (onUpdateInsight === undefined || issueId === undefined) {
		return;
	}

	const containerAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId,
	});

	onUpdateInsight({
		jiraIssueId: issueId,
		// @ts-expect-error - account: jira_polaris_ReadInsights_polarisInsights_account | null  is not assignable to type 'PolarisInsight[]'.
		insights: values(insights)
			.map(({ id, snippets, updated, created, account }) => {
				const insightAri = toAri(id);

				if (insightAri === undefined) {
					return undefined;
				}

				return {
					container: containerAri,
					id: insightAri,
					updated,
					created,
					account,
					snippets: snippets.filter(Boolean),
				};
			})
			.filter(Boolean),
	});
};

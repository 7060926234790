import React, { useState, useCallback } from 'react';
import noop from 'lodash/noop';
import { AtlassianTeamsField } from '@atlassian/jira-issue-create-commons/src/ui/atlassian-teams-field/index.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';

type Props = {
	fieldKey: string;
	onChange: (value: string | undefined) => void;
	isDisabled?: boolean;
};

export const TeamField = ({ fieldKey, onChange, isDisabled }: Props) => {
	const [value, setValue] = useState<TeamValue>(undefined);

	const handleChange = useCallback(
		(newValue: TeamValue) => {
			onChange(newValue?.id);
			setValue(newValue);
		},
		[onChange],
	);

	return (
		<AtlassianTeamsField
			fieldId={fieldKey}
			fieldName={fieldKey}
			fieldProps={{
				value,
				onBlur: noop,
				onFocus: noop,
				onChange: handleChange,
				isInvalid: false,
				isDisabled: Boolean(isDisabled),
			}}
			isDropdownMenuFixedAndLayered
		/>
	);
};

import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from '../../messages.tsx';
import { Wrapper } from '../../styled.tsx';

export const FieldGroupedMedian = ({ val }: { val: number }) => {
	const { formatMessage } = useIntl();

	return <Wrapper>{formatMessage(messages.medianVal, { val })}</Wrapper>;
};

import React, { type MouseEvent as ReactMouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Pressable, xcss } from '@atlaskit/primitives';

type LinkPressableProps = {
	onClick: (
		event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	label: string;
	testId?: string;
};

export const LinkPressable = ({ onClick, label, testId = '' }: LinkPressableProps) => (
	<Pressable
		backgroundColor="color.background.neutral.subtle"
		testId={testId}
		xcss={linkPressableStyles}
		onClick={onClick}
	>
		{label}
	</Pressable>
);

const linkPressableStyles = xcss({
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	color: 'color.link',
	padding: 'space.0',
	':hover': {
		textDecoration: 'underline',
	},
});

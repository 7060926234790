import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Icon from '@atlaskit/icon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../messages.tsx';
import { PinFilled } from './assets/pin-filled.tsx';
import { PinUnfilled } from './assets/pin-unfilled.tsx';

type FieldPinProps = {
	isPinned?: boolean;
	onClick: () => void;
};

export const FieldPin = memo<FieldPinProps>(({ isPinned = false, onClick }: FieldPinProps) => {
	const { formatMessage } = useIntl();
	const tooltipMessage = isPinned
		? formatMessage(messages.unpinField)
		: formatMessage(messages.pinField);
	return (
		<Tooltip content={tooltipMessage}>
			<FieldPinButton isPinned={isPinned} onClick={onClick}>
				<Icon label={tooltipMessage} glyph={isPinned ? PinFilled : PinUnfilled} />
			</FieldPinButton>
		</Tooltip>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldPinButton = styled.button<{ isPinned: boolean }>({
	marginLeft: token('space.025', '2px'),
	flexShrink: 0,
	background: 'none',
	border: 'none',
	padding: 0,
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: ({ isPinned }) => (isPinned ? colors.Y300 : token('color.text.subtlest', colors.N80)),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: ({ isPinned }) => (isPinned ? colors.Y200 : token('color.text', colors.N800)),
	},
});

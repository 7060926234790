import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	restrictionsLabel: {
		id: 'polaris-component-view-access.restrictions-label',
		defaultMessage: 'Restrictions',
		description: 'Text displayed in the modal header for changing view permissions',
	},
	roadmapRestrictionsLabel: {
		id: 'polaris-component-view-access.roadmap-restrictions-label',
		defaultMessage: 'Roadmap restrictions',
		description: 'Text displayed in the modal header for changing view permissions for a roadmap',
	},
	modalCloseButtonLabel: {
		id: 'polaris-component-view-access.modal-close-button-label',
		defaultMessage: 'Close modal',
		description: 'Label of the close modal icon button',
	},
});

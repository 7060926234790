import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentViewKind } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_BOARD,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { useBoardIdeaCount } from '../../../view-content/idea-board/utils/idea-count.tsx';
import { useListIdeaCount } from '../../../view-content/idea-list/utils/idea-count.tsx';
import { useMatrixIdeaCount } from '../../../view-content/idea-matrix/utils/items.tsx';
import { useTimelineIdeaCount } from '../../../view-content/idea-timeline/utils/hooks.tsx';
import messages from './messages.tsx';

const BoardIdeaCount = () => {
	const boardIdeaCount = useBoardIdeaCount();
	const { formatMessage } = useIntl();

	return <>{formatMessage(messages.ideaCount, { ideaCount: boardIdeaCount })}</>;
};

const ListIdeaCount = () => {
	const listIdeaCount = useListIdeaCount();
	const { formatMessage } = useIntl();

	return <>{formatMessage(messages.ideaCount, { ideaCount: listIdeaCount })}</>;
};

const MatrixIdeaCount = () => {
	const matrixIdeaCount = useMatrixIdeaCount();
	const { formatMessage } = useIntl();

	return <>{formatMessage(messages.ideaCount, { ideaCount: matrixIdeaCount })}</>;
};

const TimelineIdeaCount = () => {
	const timelineIdeaCount = useTimelineIdeaCount();
	const { formatMessage } = useIntl();

	return <>{formatMessage(messages.ideaCount, { ideaCount: timelineIdeaCount })}</>;
};

export const ViewIdeaCount = () => {
	const viewKind = useCurrentViewKind();

	if (viewKind === VIEW_KIND_BOARD) {
		return <BoardIdeaCount />;
	}
	if (viewKind === VIEW_KIND_MATRIX) {
		return <MatrixIdeaCount />;
	}
	if (viewKind === VIEW_KIND_TIMELINE) {
		return <TimelineIdeaCount />;
	}

	return <ListIdeaCount />;
};

import { useMemo } from 'react';
import type { MediaProvider } from '@atlaskit/editor-common/provider-factory';
import type { Auth, MediaClientConfig } from '@atlaskit/media-core';
import { createContainer, createStore, createHook } from '@atlassian/react-sweet-state';
import { initMedia } from './actions/init-media.tsx';
import type { State, Props } from './types.tsx';

const actions = {
	initMedia,
} as const;

type Actions = typeof actions;

const initialState: State = {
	meta: null,
	files: {},
	mediaIds: {},
	mediaReadPermissions: null,
	loading: false,
	error: null,
};

export const MediaStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisMediaStore',
});

export const useMedia = createHook(MediaStore);

export const useMediaClientConfig = () => {
	const [{ mediaReadPermissions }] = useMedia();
	const mediaClientConfig: MediaClientConfig = useMemo(
		() => ({
			authProvider: (): Promise<Auth> => {
				if (
					!mediaReadPermissions?.clientId ||
					!mediaReadPermissions.tokensWithFiles ||
					!mediaReadPermissions.tokensWithFiles[0] ||
					!mediaReadPermissions.tokensWithFiles[0].token ||
					!mediaReadPermissions.endpointUrl
				) {
					return Promise.reject(new Error('mediaContext is undefined'));
				}
				const auth = {
					clientId: mediaReadPermissions.clientId,
					token: mediaReadPermissions.tokensWithFiles[0].token,
					baseUrl: mediaReadPermissions.endpointUrl,
				};
				return Promise.resolve(auth);
			},
		}),
		[mediaReadPermissions],
	);
	return mediaClientConfig;
};

export const useViewMediaProvider = (): MediaProvider | undefined => {
	const mediaClientConfig = useMediaClientConfig();
	const mediaProvider = useMemo(
		() => ({
			viewMediaClientConfig: mediaClientConfig,
		}),
		[mediaClientConfig],
	);
	return mediaProvider;
};

export const useMediaFile = (fileId: string) => {
	const [{ files, loading, error }] = useMedia();
	return {
		file: files[fileId],
		loading,
		error,
	};
};

export const useAreMediaIdsKnown = (mediaIds: string[]) => {
	const [{ mediaIds: knownMediaIds }] = useMedia();
	return useMemo(
		() => mediaIds.every((mediaId) => knownMediaIds[mediaId]),
		[knownMediaIds, mediaIds],
	);
};

export const MediaContainer = createContainer<State, Actions, Props>(MediaStore, {
	onInit:
		() =>
		({ dispatch }) =>
			dispatch(initMedia()),
	onUpdate:
		() =>
		({ dispatch }) =>
			dispatch(initMedia()),
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.matrix-view.title',
		defaultMessage: 'Matrix view uses global fields',
		description: 'Title of the section message for roadmap matrix view fields onboarding',
	},
	body: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.matrix-view.body',
		defaultMessage:
			'Create global fields such as Impact and Effort and add them to your projects and ideas to display ideas in a roadmap matrix view.',
		description: 'Body of the section message for roadmap matrix view fields onboarding',
	},
});

import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { ErrorMessage, Field } from '@atlaskit/form';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { isEmpty as isEmptyAdf } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async.tsx';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import {
	prepareForRender,
	prepareForSave,
} from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import type { AkRendererProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/types.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '../../../../common/constants.tsx';
import { MAX_CONTENT_SIZE } from '../../constants.tsx';
import messages from './messages.tsx';

type Props = {
	isSaving?: boolean;
	isEditMode: boolean;
	isNewContribution: boolean;
	isCommentsLoading: boolean;
	commentBody: ADF;
	onChange: (_: boolean) => void;
	onSave: (value: ADF) => void;
	onDiscard: () => void;
};

type PlayContributionInputReadViewProps = {
	value: ADF;
} & AkRendererProps;

const PlayContributionInputReadView = ({ value, ...props }: PlayContributionInputReadViewProps) => {
	const safeCommentBody = useMemo(() => prepareForRender(value), [value]);

	return (
		<ReadViewContentContainer data-testid="polaris-common.ui.plays.add-contribution.input-editor.read-view-content-container">
			<AkRenderer
				{...props}
				appearance="comment"
				document={safeCommentBody}
				analyticsEventSeverityTracking={{
					enabled: true,
					severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
					severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
				}}
				unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
				useSpecBasedValidator
				allowAltTextOnImages
				eventHandlers={{
					smartCard: { onClick: onLinkClick },
					link: { onClick: onLinkClick },
				}}
				featureFlags={{
					codeBidiWarnings: true,
					'code-bidi-warnings': true,
					'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
				}}
				smartLinks={smartLinksDefault}
				UNSTABLE_allowTableResizing={expVal(
					'platform_editor_support_table_in_comment_jira',
					'isEnabled',
					false,
				)}
				UNSTABLE_allowTableAlignment={expVal(
					'platform_editor_support_table_in_comment_jira',
					'isEnabled',
					false,
				)}
			/>
		</ReadViewContentContainer>
	);
};

export const PlayContributionInputEditorComponent = memo<Props>(
	({
		onSave,
		onDiscard,
		onChange,
		commentBody,
		isCommentsLoading,
		isEditMode,
		isSaving,
		isNewContribution,
	}: Props) => {
		const { formatMessage } = useIntl();
		const [isEmpty, setIsEmpty] = useState(isEmptyAdf(commentBody));
		const [showValidation, setShowValidation] = useState(false);
		const isInvalid = isEmpty && showValidation;
		const isEditorAiEnabled = useEditorAiEnabled();

		useEffect(() => {
			setIsEmpty(isEmptyAdf(commentBody));
		}, [commentBody]);

		const handleSave = useCallback(
			(value: ADF) => {
				if (isEmpty) {
					setShowValidation(true);
					return;
				}
				onSave(value);
			},
			[isEmpty, onSave],
		);

		const handleChange = useCallback(
			(value?: ADF) => {
				setIsEmpty(isEmptyAdf(prepareForSave(value)));
				if (isEmpty) {
					setShowValidation(true);
				}
				onChange(true);
			},
			[isEmpty, onChange],
		);

		return (
			<WaitForAdfConsumerProps>
				{({ akRendererProps, akEditorProps }) =>
					isEditMode || isNewContribution ? (
						<Field name="comment" label={formatMessage(messages.label)} isRequired>
							{() => (
								<>
									<EditViewContentContainer
										data-testid="polaris-common.ui.plays.add-contribution.input-editor.edit-view-content-container"
										isNewContribution={isNewContribution}
										onBlur={() => setShowValidation(true)}
									>
										<EditorBoundary
											fallback={
												<PlayContributionInputReadView value={commentBody} {...akRendererProps} />
											}
										>
											<Editor
												{...akEditorProps}
												withDebouncedOnChange={false}
												shouldFocus
												maxContentSize={MAX_CONTENT_SIZE}
												onChange={handleChange}
												onSave={handleSave}
												onCancel={onDiscard}
												disabled={isSaving}
												defaultValue={commentBody}
												placeholder={formatMessage(messages.addContributionPlaceholder)}
												invalid={isInvalid}
												isAiEnabled={isEditorAiEnabled}
												// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
												popupsMountPoint={document.body ?? undefined}
												useStickyToolbar
											/>
										</EditorBoundary>
									</EditViewContentContainer>
									{isInvalid && (
										<ErrorMessage>{formatMessage(messages.emptyEditorErrorMessage)}</ErrorMessage>
									)}
								</>
							)}
						</Field>
					) : (
						<>
							{isCommentsLoading ? (
								<AdfSkeleton />
							) : (
								<PlayContributionInputReadView value={commentBody} {...akRendererProps} />
							)}
						</>
					)
				}
			</WaitForAdfConsumerProps>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContentContainer = styled.div<{
	isNewContribution: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.ProseMirror': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			minHeight: ({ isNewContribution }) => (isNewContribution ? '100px' : 'auto'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContentContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-10px',
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.100', '8px')}`,
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderRadius: '3px',
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sortAscending: {
		id: 'polaris-ideas.idea-view.connections.content.header.sort-ascending',
		defaultMessage: 'Sort ascending',
		description: 'Button label for sorting by column.',
	},
	sortDescending: {
		id: 'polaris-ideas.idea-view.connections.content.header.sort-descending',
		defaultMessage: 'Sort descending',
		description: 'Button label for sorting by column.',
	},
	clearSort: {
		id: 'polaris-ideas.idea-view.connections.content.header.clear-sort',
		defaultMessage: 'Clear sort',
		description: 'Button label for clearing the sorting by column.',
	},
	removeField: {
		id: 'polaris-ideas.idea-view.connections.content.header.remove-field',
		defaultMessage: 'Remove field',
		description: 'Button label for hiding removing field.',
	},
});

import type { IssueTypeFieldValue } from './types.tsx';

const isProperShape = (
	v: object,
): v is { id: unknown; name: unknown; iconUrl: unknown; avatarId: unknown } => {
	return Object.hasOwn(v, 'id') && Object.hasOwn(v, 'name') && Object.hasOwn(v, 'iconUrl');
};

export const isIssueTypeFieldValue = (v: unknown): v is IssueTypeFieldValue => {
	return (
		typeof v === 'object' &&
		v !== null &&
		isProperShape(v) &&
		typeof v.id === 'string' &&
		typeof v.name === 'string' &&
		typeof v.iconUrl === 'string'
	);
};

import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { GO_TO_PROJECT_SPOTLIGHT_DISMISSED } from '../../../constants.tsx';
import type { Props, State } from '../../../types.tsx';

export const loadGoToProjectSpotlightDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().globalFields.goToProjectSpotlightDismissed === 'boolean') {
			return;
		}

		try {
			const goToProjectSpotlightDismissed = await getUserProperty<boolean>(
				accountId,
				GO_TO_PROJECT_SPOTLIGHT_DISMISSED,
			);

			const state = getState();

			setState({
				...state,
				globalFields: {
					...state.globalFields,
					goToProjectSpotlightDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && error.statusCode === 404) {
				const state = getState();

				setState({
					...state,
					globalFields: {
						...state.globalFields,
						goToProjectSpotlightDismissed: false,
					},
				});

				return;
			}

			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.global-fields.go-to-project-spotlight',
				`Failed to get user property with key: ${GO_TO_PROJECT_SPOTLIGHT_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};

export const setGoToProjectSpotlightDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			globalFields: {
				...state.globalFields,
				goToProjectSpotlightDismissed: true,
			},
		});

		try {
			await setUserProperties(accountId, GO_TO_PROJECT_SPOTLIGHT_DISMISSED, 'true');
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.global-fields.go-to-project-spotlight',
				`Failed to set user property with key: ${GO_TO_PROJECT_SPOTLIGHT_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import { getViewUUID } from '../utils/index.tsx';

export const fetchAllCollectionViews = async (collectionUUID: string): Promise<ViewResponse[]> => {
	const { views = [] } = await fetchJson<{ views: ViewResponse[] }>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}?expand=views`,
	);

	return views;
};

export const fetchCollectionView = async (
	collectionUUID: string,
	viewId: string,
): Promise<ViewResponse> => {
	const views = await fetchAllCollectionViews(collectionUUID);

	const viewUUID = getViewUUID(viewId);
	const view = views.find(({ uuid }) => uuid === viewUUID);

	if (!view) {
		throw new Error(`cannot fetch view with ARI: ${viewId}. Not found.`);
	}

	return view;
};

import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueDecoration,
	FieldValueDecorations,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { wrapWithDeepEqualityCheck } from '../../../common/utils/reselect/index.tsx';
import {
	getAppliedDateDecoration,
	getAppliedIntervalDecoration,
	getAppliedNumberDecoration,
} from '../../field/decoration/index.tsx';
import type { HighlightedField, Props, State } from '../types.tsx';
import { intervalStringMapping } from '../utils/field-mapping/interval/index.tsx';
import { stringMapping } from '../utils/field-mapping/string/index.tsx';
import {
	getFieldMappings,
	getNumberFieldMappings,
	getStringFieldMappings,
	type FieldMappings,
	getAllFieldsByKey,
} from './fields.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_DECORATIONS: Record<string, any> = {};

const getDecorations = (state: State, props: Props | undefined) =>
	props?.decorations || EMPTY_DECORATIONS;

const appliedDecorationForDateString = (
	state: State,
	props: undefined | Props,
	field: Field,
	valueDecoration: ValueDecoration,
	localIssueId: LocalIssueId,
): ValueDecoration | undefined => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	const fieldMapping = stringMapping(props?.issuesRemote!, field);
	const value = fieldMapping.valueAccessor(state, props, localIssueId);
	if (value !== undefined) {
		return getAppliedDateDecoration(
			[valueDecoration],
			value,
			state.containerProps?.locale || 'en_US',
		);
	}
	return undefined;
};

const appliedDecorationForIntervalString = (
	state: State,
	props: undefined | Props,
	field: Field,
	allFields: Field[],
	valueDecoration: ValueDecoration,
	localIssueId: LocalIssueId,
): ValueDecoration | undefined => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	const fieldMapping = intervalStringMapping(props?.issuesRemote!, field, allFields);
	const value = fieldMapping.valueAccessor(state, props, localIssueId);
	if (value !== undefined) {
		try {
			const interval = JSON.parse(value);

			return getAppliedIntervalDecoration(
				[valueDecoration],
				{
					start: interval.start,
					end: interval.end,
				},
				state.containerProps?.locale || 'en_US',
			);
		} catch (err) {
			return undefined;
		}
	}
	return undefined;
};

export const getHighlightedFields = (
	fieldValueDecorations: FieldValueDecorations,
	allFieldMappings: FieldMappings<unknown>,
	numberFieldMappings: FieldMappings<number>,
	stringFieldMappings: FieldMappings<string>,
	fieldsByKey: Record<FieldKey, Field>,
	state: State,
	props: Props | undefined,
	localIssueId: LocalIssueId,
): HighlightedField[] => {
	const highlighted: HighlightedField[] = [];

	Object.keys(fieldValueDecorations).forEach((fieldKey) => {
		const field = fieldsByKey[fieldKey];
		if (!field) return;

		const valueDecorations = fieldValueDecorations[fieldKey];
		valueDecorations.forEach((valueDecoration) => {
			if (!valueDecoration.highlightContainer) return;

			// insights, created and updated fields are not supported
			if (field.type === FIELD_TYPES.INSIGHTS) return;
			if (field.type === FIELD_TYPES.CREATED) return;
			if (field.type === FIELD_TYPES.UPDATED) return;

			if (
				field.type === FIELD_TYPES.NUMBER ||
				field.type === FIELD_TYPES.SLIDER ||
				field.type === FIELD_TYPES.FORMULA ||
				field.type === FIELD_TYPES.LINKED_ISSUES
			) {
				// numeric field/value
				const fieldMapping = numberFieldMappings[fieldKey];
				if (fieldMapping !== undefined) {
					const value = fieldMapping.valueAccessor(state, props, localIssueId);
					const appliedDecoration = getAppliedNumberDecoration([valueDecoration], value);
					if (appliedDecoration) {
						highlighted.push({
							fieldKey,
							appliedDecoration,
							label: field.label,
						});
					}
				}
			} else if (field.type === FIELD_TYPES.DATE) {
				const appliedDecoration = appliedDecorationForDateString(
					state,
					props,
					field,
					valueDecoration,
					localIssueId,
				);
				if (appliedDecoration) {
					highlighted.push({
						fieldKey,
						appliedDecoration,
						label: field.label,
					});
				}
			} else if (field.type === FIELD_TYPES.INTERVAL) {
				const appliedDecoration = appliedDecorationForIntervalString(
					state,
					props,
					field,
					Object.values(fieldsByKey),
					valueDecoration,
					localIssueId,
				);
				if (appliedDecoration) {
					highlighted.push({
						fieldKey,
						appliedDecoration,
						label: field.label,
					});
				}
			} else {
				const values = allFieldMappings[fieldKey]
					.getGroupIdentities(state, props, localIssueId)
					.map((groupIdentity) => groupIdentity.groupIdentity);

				const matches = isDecorationWithLogic(valueDecoration)
					? false // TODO: Handle Decoration Logic
					: valueDecoration.rules.some((rule) => {
							if (rule.operator === ValueRuleOperator.EQ) {
								return values.indexOf(rule.value) !== -1;
							}
							return false;
						});
				if (matches) {
					highlighted.push({
						fieldKey,
						appliedDecoration: valueDecoration,
						label: field.label,
					});
				}
			}
		});
	});
	return highlighted;
};

export const getHighlightedDecorations = (
	fieldValueDecorations: FieldValueDecorations,
	allFieldMappings: FieldMappings<unknown>,
	numberFieldMappings: FieldMappings<number>,
	stringFieldMappings: FieldMappings<string>,
	fieldsByKey: Record<FieldKey, Field>,
	state: State,
	props: Props | undefined,
	localIssueId: LocalIssueId,
): ValueDecoration[] =>
	getHighlightedFields(
		fieldValueDecorations,
		allFieldMappings,
		numberFieldMappings,
		stringFieldMappings,
		fieldsByKey,
		state,
		props,
		localIssueId,
	).map((highlights) => highlights.appliedDecoration);

export const createGetHighlightedFields = (localIssueId: LocalIssueId) =>
	wrapWithDeepEqualityCheck(
		createSelector(
			getDecorations,
			getFieldMappings,
			getNumberFieldMappings,
			getStringFieldMappings,
			getAllFieldsByKey,
			(state) => state,
			(_, props) => props,
			(
				fieldValueDecorations,
				allFieldMappings,
				numberFieldMappings,
				stringFieldMappings,
				fieldsByKey,
				state,
				props,
			) =>
				getHighlightedFields(
					fieldValueDecorations,
					allFieldMappings,
					numberFieldMappings,
					stringFieldMappings,
					fieldsByKey,
					state,
					props,
					localIssueId,
				),
		),
	);

export const createGetHighlightedDecorations = (localIssueId: LocalIssueId) =>
	createSelector(
		getDecorations,
		getFieldMappings,
		getNumberFieldMappings,
		getStringFieldMappings,
		getAllFieldsByKey,
		(state) => state,
		(_, props) => props,
		(
			fieldValueDecorations,
			allFieldMappings,
			numberFieldMappings,
			stringFieldMappings,
			fieldsByKey,
			state,
			props,
		) =>
			getHighlightedDecorations(
				fieldValueDecorations,
				allFieldMappings,
				numberFieldMappings,
				stringFieldMappings,
				fieldsByKey,
				state,
				props,
				localIssueId,
			),
	);

export const createGetHighlightedDecorationsForList = (localIssueIds: LocalIssueId[]) =>
	createSelector(
		getDecorations,
		getFieldMappings,
		getNumberFieldMappings,
		getStringFieldMappings,
		getAllFieldsByKey,
		(state) => state,
		(_, props) => props,
		(
			fieldValueDecorations,
			allFieldMappings,
			numberFieldMappings,
			stringFieldMappings,
			fieldsByKey,
			state,
			props,
		) =>
			flatten(
				localIssueIds.map((localIssueId) =>
					getHighlightedDecorations(
						fieldValueDecorations,
						allFieldMappings,
						numberFieldMappings,
						stringFieldMappings,
						fieldsByKey,
						state,
						props,
						localIssueId,
					),
				),
			),
	);

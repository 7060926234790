import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import type { JiraSearchApiIssue } from '../types.tsx';
import { fetchLinkedIssuesStoryPointField } from './utils.tsx';

export const getLinkedIssuesStoryPoints = async (
	issueKeys: string[],
	storyPointsCustomFieldId?: string,
): Promise<JiraSearchApiIssue[]> => {
	if (isEmpty(issueKeys)) {
		return Promise.resolve([]);
	}
	const jql = `key IN (${join(issueKeys, ',')})`;
	return fetchLinkedIssuesStoryPointField(jql, storyPointsCustomFieldId);
};

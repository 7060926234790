import type { JsonLd } from 'json-ld-types';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../types.tsx';

export const resolveUrl =
	(url: string, data: JsonLd.Response<JsonLd.Data.BaseData> | undefined) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const { client } = getState();

		if (!client || !data) {
			return;
		}

		if (data.meta.access !== 'granted') {
			return;
		}

		setState({
			states: {
				...getState().states,
				[url]: data,
			},
		});
	};

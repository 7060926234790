import type { Action } from '@atlassian/react-sweet-state';
import { getLatestUpdateForField } from '../../../services/get-latest-update-for-field/index.tsx';
import type { State } from '../../types.tsx';

export const loadLatestUpdate =
	(issueKey: string): Action<State> =>
	async ({ setState }) => {
		setState({ latestUpdate: undefined, isLoadingUpdate: true });
		try {
			const response = await getLatestUpdateForField(issueKey, 'description');
			setState({
				latestUpdate: {
					authorId: response.author.accountId,
					authorName: response.author.displayName,
					date: response.created,
				},
				isLoadingUpdate: false,
			});
		} catch {
			setState({
				latestUpdate: undefined,
				isLoadingUpdate: false,
			});
		}
	};

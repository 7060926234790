import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useSelectedIssue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	SingleSelectField,
	MultiSelectField,
} from '@atlassian/jira-polaris-common/src/ui/fields/select/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from '../datetime/messages.tsx';
import { useUpdateFieldValue } from '../utils.tsx';

export type FieldProps = {
	fieldKey: FieldKey;
	onConfigRequested: (optionId?: string) => void;
};

export const SingleSelect = ({ fieldKey, onConfigRequested }: FieldProps) => {
	const { formatMessage } = useIntl();
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const ideaId = useSelectedIssue();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);
	const field = useField(fieldKey);

	if (ideaId === undefined || field === undefined) {
		return null;
	}

	return (
		<SingleSelectField
			isActive
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			localIssueId={ideaId}
			fieldKey={fieldKey}
			onUpdate={updateFieldValue}
			onConfigRequested={onConfigRequested}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};

export const MultiSelect = ({ fieldKey, onConfigRequested }: FieldProps) => {
	const { formatMessage } = useIntl();
	const ideaId = useSelectedIssue();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (ideaId === undefined || field === undefined) {
		return null;
	}

	return (
		<MultiSelectField
			isActive
			isMultiline
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			localIssueId={ideaId}
			fieldKey={fieldKey}
			onUpdate={updateFieldValue}
			onConfigRequested={onConfigRequested}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};

import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { GlobalFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldConfiguration } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { JiraApiGlobalField } from '../types.tsx';

export const createGlobalField = (
	name: string,
	fieldType: GlobalFieldType,
	configuration?: FieldConfiguration,
): Promise<JiraApiGlobalField> =>
	performPostRequest<JiraApiGlobalField>('/rest/polaris/global-fields', {
		body: JSON.stringify({
			name,
			type: fieldType,
			configuration,
		}),
	});

import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { loadStatuses, loadStatusesForSharedView } from './load.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadStatuses,
	loadStatusesForSharedView,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

import React from 'react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project.tsx';
import {
	useIsCollectionView,
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useContainerAri } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PermissionsContainer } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	children: React.ReactNode;
	projectId: ProjectId;
};

export const WithPermissionsDataContainer = ({ children, projectId }: Props) => {
	const containerAri = useContainerAri();
	const isCollectionView = useIsCollectionView();
	const isEmbeddedView = useIsEmbedded();
	const isSharedView = useIsSharedView();

	return isSharedView ? (
		<>{children}</>
	) : (
		<PermissionsContainer
			containerAri={containerAri}
			scope={getWillShowNav4() ? getProjectContainerScope(projectId) : 'permissions-singleton'}
			isSharedView={isSharedView}
			isCollectionView={isCollectionView}
			isEmbeddedView={isEmbeddedView}
			onLoadStart={jpdProjectPageLoadPermissionsStart}
			onLoadEnd={jpdProjectPageLoadPermissionsEnd}
		>
			{children}
		</PermissionsContainer>
	);
};

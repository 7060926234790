import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { centerUnderPointer } from '@atlaskit/pragmatic-drag-and-drop/element/center-under-pointer';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { N20, N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { isSafari as isBrowserSafari } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { useIssueAnalitycsAttributes } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCurrentViewLayoutType } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IdeaCard } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { DRAGGABLE_ITEM_TYPE as MATRIX_DRAGGABLE_ITEM_TYPE } from '@atlassian/jira-polaris-lib-matrix/src/common/constants/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { preserveOffsetFromPointer } from '../../../../common/ui/dnd-v2/utils.tsx';

const isSafari = isBrowserSafari();

type Props = {
	id: string;
	dndType: string;
	isFocused?: boolean;
};

export const MARGIN_TOP = 2;
export const MARGIN_BOTTOM = 10;
export const MARGIN_HORIZONTAL = 16;

export const Card = ({ id, dndType, isFocused }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [isFocusedLocal, setIsFocusedLocal] = useState(isFocused);
	const [isDragging, setIsDragging] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsData = useIssueAnalitycsAttributes(id);
	const viewLayoutType = useCurrentViewLayoutType();
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!containerRef.current) return undefined;

		const cleanupDragAndDrop = combine(
			draggable({
				element: containerRef.current,
				onGenerateDragPreview: ({ nativeSetDragImage, source, location }) => {
					setCustomNativeDragPreview({
						getOffset:
							dndType === MATRIX_DRAGGABLE_ITEM_TYPE
								? centerUnderPointer
								: preserveOffsetFromPointer({
										sourceElement: source.element,
										input: location.current.input,
									}),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					return {
						id,
						itemIds: [id],
						type: dndType,
						isExternal: true,
					};
				},
				onDragStart() {
					setIsDragging(true);
				},
				onDrop() {
					setIsDragging(false);
					fireUIAnalytics(
						createAnalyticsEvent({ action: 'dragged', actionSubject: 'card' }),
						'idea',
						analyticsData || {},
					);
					sendPendoTrackEvent({
						actionSubjectAndAction: 'card dragged',
						actionSubjectId: 'idea',
						source: 'ideas bucket',
					});
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [analyticsData, createAnalyticsEvent, dndType, id, viewLayoutType]);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;

		if (isFocused) {
			timeoutId = setTimeout(() => {
				setIsFocusedLocal(false);
			}, 3000);
		} else {
			setIsFocusedLocal(false);
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [isFocused]);

	return (
		<>
			<CardContainer ref={containerRef} isDragging={isDragging}>
				<IdeaCard
					id={id}
					isDisabled
					isFocused={isFocusedLocal}
					viewLayoutType={ViewLayoutType.DETAILED}
					hoverBackgroundColor={token('elevation.surface.hovered', N20)}
					hasHoverState
				/>
			</CardContainer>
			{previewContainer
				? ReactDOM.createPortal(
						dndType === MATRIX_DRAGGABLE_ITEM_TYPE ? (
							<Circle />
						) : (
							<CardPreviewWrapper>
								<IdeaCard
									id={id}
									isDisabled
									viewLayoutType={ViewLayoutType.DETAILED}
									disableLazyRendering
									cappedFieldsDisplay={isSafari}
								/>
							</CardPreviewWrapper>
						),
						previewContainer,
					)
				: null}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContainer = styled.div<{ isDragging: boolean }>({
	margin: `${token('space.025', '2px')} ${token('space.200', '16px')} ${MARGIN_BOTTOM}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isDragging }) => isDragging && 0.3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ isDragging }) => isDragging && 'saturate(0)',
	},
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div({
	display: 'flex',
	width: '45px',
	height: '45px',
	borderRadius: '50%',
	backgroundColor: token('color.text.subtlest', N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardPreviewWrapper = styled.div({
	width: `calc(400px - ${MARGIN_HORIZONTAL * 2}px)`,
});

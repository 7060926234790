import React, { type SyntheticEvent, type ReactElement, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { N1000 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

type Props = {
	icon?: ReactElement;
	title?: string;
	onClick?: () => void;
	onClose: () => void;
	isSharedView?: boolean;
};

export const Header = ({ icon, title, onClick, onClose, isSharedView }: Props) => {
	const { formatMessage } = useIntl();

	const onCloseHandler = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'close');
			onClose();
		},
		[onClose],
	);

	if (isSharedView) {
		return (
			<SharedHeaderContainer>
				<Label>{title}</Label>
				<Button
					id="pendo.right-sidebar.close-button"
					testId="polaris-ideas.ui.right-sidebar.header.close"
					appearance="subtle-link"
					iconBefore={<CrossIcon label="" size="small" />}
					aria-label={formatMessage(messages.closeSidebar)}
					onClick={onCloseHandler}
				/>
			</SharedHeaderContainer>
		);
	}

	return (
		<HeaderContainer withIcon={!!icon}>
			{!onClick ? (
				<Text weight="medium" color="color.text.subtle">
					{title}
				</Text>
			) : (
				<Button
					appearance="subtle-link"
					iconBefore={icon}
					onClick={onClick}
					testId="polaris-ideas.ui.right-sidebar.header.back-button"
				>
					{title}
				</Button>
			)}
			<Button
				id="pendo.right-sidebar.close-button"
				testId="polaris-ideas.ui.right-sidebar.header.close"
				appearance="subtle-link"
				iconBefore={<CrossIcon label="" size="small" />}
				aria-label={formatMessage(messages.closeSidebar)}
				onClick={onCloseHandler}
			/>
		</HeaderContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div<{ withIcon?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ withIcon }) =>
		withIcon ? token('space.025', '2px') : token('space.200', '16px'),
	boxShadow: '0px 1px 1px rgba(9, 30, 66, 0.08)',
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.div({
	padding: `${token('space.150', '12px')} ${token('space.050', '4px')}`,
	font: token('font.heading.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', N1000),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SharedHeaderContainer = styled(HeaderContainer)({
	paddingLeft: token('space.200', '16px'),
	alignItems: 'center',
});

import React, { type PropsWithChildren } from 'react';
import { Emoji } from '@atlaskit/emoji';
import { xcss, Inline, Box, Anchor } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useIssueLink } from '../../../common/utils/issue-link/index.tsx';
import {
	useFieldTypeIcon,
	useFieldEmoji,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useConnectedIssueDataByIssueId } from '../../../controllers/issue/selectors/connection-hooks.tsx';

type ConnectionItemProps = {
	isLastChild?: boolean;
	fieldKey: FieldKey;
	issueId: string;
};

export const ConnectionItem = ({ isLastChild = false, issueId, fieldKey }: ConnectionItemProps) => {
	const connectedIssue = useConnectedIssueDataByIssueId(issueId);

	const icon = useFieldTypeIcon(fieldKey, undefined);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	if (connectedIssue === undefined) {
		return null;
	}

	return (
		<ConnectionItemLink issueKey={connectedIssue.issueKey} isLastChild={isLastChild}>
			<Inline grow="fill" alignBlock="center" space="space.150">
				<Inline alignInline="center">
					{emoji ? <Emoji emoji={emoji} fitToHeight={16} showTooltip /> : icon}
				</Inline>
				<Box xcss={labelStyles}>{connectedIssue.summary}</Box>
			</Inline>
		</ConnectionItemLink>
	);
};

const ConnectionItemLink = ({
	issueKey,
	children,
	isLastChild,
}: PropsWithChildren<{ issueKey: string; isLastChild: boolean }>) => {
	const { link, target, onClick } = useIssueLink({ issueKey, shouldOpenInSidebar: true });

	return (
		<Anchor
			onClick={onClick}
			xcss={[linkStyles, containerStyles, isLastChild && containerStylesLastChild]}
			href={link}
			target={target}
		>
			{children}
		</Anchor>
	);
};

const linkStyles = xcss({
	font: 'font.body',
	color: 'color.text',
	textDecoration: 'none',
	padding: 'space.0',
	display: 'flex',
	alignItems: 'center',

	':hover': {
		color: 'color.text',
	},
});

const labelStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: 'font.body',
	color: 'color.text',
});

const containerStyles = xcss({
	// Block padding here needs to be in sync with the LIST_ITEM_HEIGHT constant in src/packages/polaris/apps/common/src/ui/connection/connection-list/constants.tsx
	paddingBlock: 'space.100',
	paddingLeft: 'space.150',
	paddingRight: 'space.200',
	borderBottom: `1px solid ${token('color.border')}`,
});

const containerStylesLastChild = xcss({
	borderBottom: '0',
});

import React, { memo, useCallback, useMemo } from 'react';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsCollectionView } from '../../controllers/environment/index.tsx';
import { useCanManageCurrentView } from '../../controllers/views/selectors/view-hooks.tsx';
import { LISTS, TOGGLE_PERMISSIONS, type TogglePermissionType } from './constants.tsx';
import { DroppableHandler } from './droppable-handler/index.tsx';
import { List } from './list/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	availableFields?: Field[];
	selectedFields: Field[];
	unselectedFieldsAvailable?: boolean;
	hiddenFieldKeys?: FieldKey[];
	togglePermissionType: TogglePermissionType;
	onFieldClick: (field: FieldKey) => void;
	onFieldHide?: (field: FieldKey) => void;
	onFieldsSortLegacy?: (field: FieldKey, movedToKey: FieldKey) => void;
	onFieldsSortNext?: (updatedFields: Field[]) => void;
	onFieldToggle?: (field: FieldKey, isSelected: boolean) => void;
};

export const FieldsList = memo<Props>(
	({
		availableFields = [],
		selectedFields,
		unselectedFieldsAvailable,
		hiddenFieldKeys = [],
		togglePermissionType,
		onFieldClick,
		onFieldHide,
		onFieldToggle,
		onFieldsSortLegacy,
		onFieldsSortNext,
	}: Props) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const canManageCurrentView = useCanManageCurrentView();
		const isCollectionView = useIsCollectionView();

		const isSelectedFieldsListDragDisabled = !canManageCurrentView;

		const selectedFieldKeys = useMemo(() => selectedFields.map(({ key }) => key), [selectedFields]);

		const onFieldToggleLocal = useCallback(
			(fieldKey: FieldKey, isChecked: boolean) => {
				onFieldToggle && onFieldToggle(fieldKey, isChecked);
			},
			[onFieldToggle],
		);

		const onFieldsLegacyUpdate = useCallback(
			(field: FieldKey, movedToKey: FieldKey) => {
				onFieldsSortLegacy?.(field, movedToKey);

				fireUIAnalytics(
					createAnalyticsEvent({ action: 'dragged', actionSubject: 'listItem' }),
					'issueField',
				);
			},
			[createAnalyticsEvent, onFieldsSortLegacy],
		);

		const onFieldsNextUpdate = useCallback(
			(updatedFields: Field[]) => {
				onFieldsSortNext?.(updatedFields);

				fireUIAnalytics(
					createAnalyticsEvent({ action: 'dragged', actionSubject: 'listItem' }),
					'issueField',
				);
			},
			[createAnalyticsEvent, onFieldsSortNext],
		);

		return (
			<DroppableHandler
				fields={selectedFields}
				onFieldsLegacyUpdate={onFieldsLegacyUpdate}
				onFieldsNextUpdate={fg('polaris_improved_dnd_handling') ? onFieldsNextUpdate : undefined}
			>
				<Stack xcss={configFieldsContainerStyles}>
					<List
						listId={LISTS.SELECTED}
						isDragDisabled={isSelectedFieldsListDragDisabled}
						fields={selectedFields}
						hiddenFieldKeys={hiddenFieldKeys}
						selectedFieldKeys={selectedFieldKeys}
						title={
							togglePermissionType === TOGGLE_PERMISSIONS.PIN_FIELDS
								? formatMessage(messages.pinnedFields)
								: formatMessage(messages.viewFields)
						}
						togglePermissionType={togglePermissionType}
						onFieldClick={onFieldClick}
						onFieldToggle={onFieldToggleLocal}
						onFieldHide={onFieldHide}
					/>
					<Box xcss={dividerStyles} />
					<List
						isExpandable
						isDragDisabled
						listId={LISTS.AVAILABLE}
						fields={availableFields}
						unselectedFieldsAvailable={unselectedFieldsAvailable}
						title={
							isCollectionView
								? formatMessage(messages.otherFieldsRoadmaps)
								: formatMessage(messages.otherFields)
						}
						helpText={
							togglePermissionType === TOGGLE_PERMISSIONS.PIN_FIELDS
								? formatMessage(messages.pinnedOtherFieldsHelpText)
								: formatMessage(messages.otherFieldsHelpText)
						}
						togglePermissionType={togglePermissionType}
						onFieldClick={onFieldClick}
						onFieldToggle={onFieldToggleLocal}
						onFieldHide={onFieldHide}
					/>
				</Stack>
			</DroppableHandler>
		);
	},
);

const configFieldsContainerStyles = xcss({
	height: '100%',
});

const dividerStyles = xcss({
	height: '1px',
	marginBlockStart: 'space.075',
	marginInline: 'space.200',
	background: token('color.border'),
	flexShrink: 0,
});

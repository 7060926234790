import React from 'react';
import Badge from '@atlaskit/badge';
import { Text, Inline } from '@atlaskit/primitives';
import { components, type GroupHeadingProps } from '@atlaskit/react-select';
import type { ConnectionFieldOption } from '../../types.tsx';

export const ConnectionFieldGroupHeading = <IsMulti extends boolean>(
	props: GroupHeadingProps<ConnectionFieldOption, IsMulti>,
) => {
	const {
		data: { options, label },
	} = props;

	if (!label) {
		return null;
	}

	return (
		<components.GroupHeading {...props}>
			<Inline alignBlock="center" space="space.100">
				<Text color="color.text.subtlest">{label}</Text>
				<Badge>{options.length}</Badge>
			</Inline>
		</components.GroupHeading>
	);
};

import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { RankFieldOptionRequest } from './types.tsx';

export const rankFieldOptions = ({
	projectId,
	issueTypeId,
	fieldKey,
	rankedOptionIds,
}: RankFieldOptionRequest): Promise<void> =>
	performPutRequest(`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options`, {
		body: JSON.stringify({
			issueTypeId: +issueTypeId,
			optionIds: rankedOptionIds,
		}),
	});

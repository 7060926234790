import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { pendoIcon } from '../../../../common/ui/apps/index.tsx';
import { UnfurlDialog } from '../../unfurl-dialog/index.tsx';
import type { AppPreset } from '../types.tsx';
import messages from './messages.tsx';

const UnfurlAction = () => {
	const { formatMessage } = useIntl();
	return (
		<UnfurlDialog
			title={formatMessage(messages.unfurlDialogTitle)}
			placeholder={formatMessage(messages.unfurlDialogPlaceholder)}
			successMessage={formatMessage(messages.unfurlDialogSuccess)}
		/>
	);
};

export const usePendoPresets = (): AppPreset[] => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => [
			{
				key: 'pendo-insights',
				title: formatMessage(messages.titleInsights),
				description: formatMessage(messages.descriptionInsights),
				icon: pendoIcon,
				isRecommended: false,
				Action: UnfurlAction,
			},
			{
				key: 'pendo-feedback',
				title: formatMessage(messages.titleFeedback),
				description: formatMessage(messages.descriptionFeedback),
				icon: pendoIcon,
				isRecommended: false,
				Action: UnfurlAction,
			},
		],
		[formatMessage],
	);
};

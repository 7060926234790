import React from 'react';
import { SpotlightCard } from '@atlaskit/onboarding';
import { useIntl } from '@atlassian/jira-intl';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import { useExitPreviewMode } from '@atlassian/jira-polaris-common/src/controllers/preview-mode/index.tsx';
import messages from './messages.tsx';

export const ContributorPreviewModeBanner = () => {
	const { formatMessage } = useIntl();
	const exitPreviewMode = useExitPreviewMode();

	return (
		<JiraBottomRightCornerOutlet orderFromRight={1} id="jpd-preview-contributor-mode">
			<SpotlightCard
				actions={[{ text: formatMessage(messages.exitPreview), onClick: exitPreviewMode }]}
			>
				{formatMessage(messages.previewModeCaption)}
			</SpotlightCard>
		</JiraBottomRightCornerOutlet>
	);
};

import { useRef } from 'react';
import { useActions } from '../main.tsx';

type ViewAccessPermissionStoreInitializerProps = {
	hasManageViewsPermission?: boolean;
};

export const ViewAccessPermissionStoreInitializer = ({
	hasManageViewsPermission,
}: ViewAccessPermissionStoreInitializerProps) => {
	const { setHasManageViewsPermission } = useActions();
	const prevHasManageViewsPermissionRef = useRef<boolean | undefined>();

	if (prevHasManageViewsPermissionRef.current !== hasManageViewsPermission) {
		prevHasManageViewsPermissionRef.current = hasManageViewsPermission;
		setHasManageViewsPermission(hasManageViewsPermission);
	}

	return null;
};

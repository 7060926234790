import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldTypeIcon,
	useIsGlobalCustomField,
	useIsHidden,
	useIsRestricted,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { getFieldId } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { RestrictedFieldIndicator } from '@atlassian/jira-polaris-lib-restricted-field/src/ui/restricted-field-indicator/index.tsx';
import { messages } from './messages.tsx';

export type FieldNameProps = {
	fieldKey: string;
};

export const FieldName = ({ fieldKey }: FieldNameProps) => {
	const { formatMessage } = useIntl();
	const label = useFieldLabel(fieldKey);
	const emojiId = useFieldEmoji(fieldKey);
	const fieldEmoji = useEmoji(emojiId);
	const isHidden = useIsHidden(fieldKey);
	const isRestricted = useIsRestricted(fieldKey);
	const iconProps = {
		label: '',
		primaryColor: isHidden ? token('color.text.subtlest') : undefined,
	};
	const icon = useFieldTypeIcon(fieldKey, iconProps);
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);

	return (
		<Inline
			space="space.100"
			alignBlock="center"
			testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.name.cell"
		>
			<Box testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.name.icon">
				{fieldEmoji ? <Emoji emoji={fieldEmoji} fitToHeight={16} /> : icon}
			</Box>
			<Box
				testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.name"
				xcss={[boxStyles, isHidden && hiddenFieldStyles]}
			>
				{label}
			</Box>
			{isHidden && (
				<Tooltip content={formatMessage(messages.hiddenDescription)} position="mouse">
					{(tooltipProps) => (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Box {...tooltipProps}>
							<Lozenge testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.name.hidden-lozenge">
								{formatMessage(messages.hiddenLozenge)}
							</Lozenge>
						</Box>
					)}
				</Tooltip>
			)}
			{isGlobalCustomField && <GlobalFieldMarker noMargins />}
			{isRestricted && <RestrictedFieldIndicator fieldId={getFieldId(fieldKey)} />}
		</Inline>
	);
};

const boxStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const hiddenFieldStyles = xcss({
	color: 'color.text.subtlest',
});

import { useMemo } from 'react';
import has from 'lodash/has';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldsByKey } from '../../field/selectors/field-hooks.tsx';
import {
	getFields,
	getFieldsForViewControls,
	getAllFieldsForViewControls,
	createGetFieldForFieldKey,
} from './fields.tsx';
import { createViewHook, createHigherLevelViewHook } from './utils.tsx';
import { useCurrentViewFields } from './view-hooks.tsx';

export const useFields = createViewHook(getFields);
export const useFieldsForViewControls = createViewHook(getFieldsForViewControls);
/**
 * All fields, including those that are hidden
 * Use useFieldsForViewControls if you want to exclude hidden fields
 */
export const useAllFieldsForViewControls = createViewHook(getAllFieldsForViewControls);

export const useFieldForFieldKey = createHigherLevelViewHook<
	FieldKey | undefined,
	Field | undefined
>(createGetFieldForFieldKey);

export const useSortedSelectedFields = () => {
	const fields = useFieldsForViewControls();
	const selectedFieldsBase = useCurrentViewFields();
	const [allFields] = useFieldsByKey();

	const selectedFields = useMemo(
		() => selectedFieldsBase.filter(({ key }) => has(allFields, key)),
		[selectedFieldsBase, allFields],
	);

	return useMemo(() => {
		const newFields = Object.keys(fields)
			.filter((key) => !selectedFields.includes(fields[key]))
			.map((key) => fields[key]);
		newFields.sort((a, b) => a.label.localeCompare(b.label));
		return [...selectedFields, ...newFields];
	}, [fields, selectedFields]);
};

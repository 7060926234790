import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { normalizeAggregatedDeliveryProgressResponse } from '../../common/normalize-aggregated-delivery-progress-response/index.tsx';
import type {
	AggregatedDeliveryProgressResponse,
	FetchAggregatedDeliveryProgressRequest,
	DateFieldConfiguration,
} from '../types.tsx';

// @deprecated - use getAggregatedDeliveryProgress
export const getAggregatedDeliveryProgressBasedOnProjectId = async (
	jpdProjectId: string,
	calculationStrategy: string,
	calculationMode?: string,
	dateFieldConfigurations?: DateFieldConfiguration[],
): Promise<AggregatedDeliveryProgressResponse> => {
	const mode = calculationMode || 'issue_count';

	return fetchJson('/rest/polaris/delivery-data/data', {
		method: 'POST',
		body: JSON.stringify({
			projectId: jpdProjectId,
			type: calculationStrategy,
			calculationMode: mode,
			dateFieldConfigurations,
		}),
	});
};

export const getAggregatedDeliveryProgress = async (
	request: FetchAggregatedDeliveryProgressRequest,
) =>
	fetchJson('/rest/polaris/delivery-data/data', {
		method: 'POST',
		body: JSON.stringify({
			projectId: request.projectId,
			issueIds: request.issueIds,
			type: request.calculationStrategy,
			calculationMode: request.calculationMode,
			dateFieldConfigurations: request.dateFieldConfigurations,
			archived: request.archivedFilter,
		}),
	}).then(normalizeAggregatedDeliveryProgressResponse);

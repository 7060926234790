import React from 'react';
import { HeadingItem } from '@atlaskit/side-navigation';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const ScreenReaderMenuHeader = ({ title }: { title?: string }) => {
	const { formatMessage } = useIntl();

	const defaultTitle = formatMessage(messages.sideBarNavigationTitle);

	return (
		<ScreenReaderText>
			<HeadingItem headingLevel={2}>{title || defaultTitle}</HeadingItem>
		</ScreenReaderText>
	);
};

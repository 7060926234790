import { useMemo } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldType } from '../../../../../../controllers/field/selectors/field-hooks.tsx';
import { useFieldOptionsWithAriResolved } from '../../../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useGroupOptions } from '../../../../../../controllers/issue/selectors/grouping-hooks.tsx';
import { canModifyOptionName } from '../../utils/fields.tsx';

export const useOptionsAsIdNameTuples = (
	fieldKey: FieldKey,
): {
	id: string;
	value: string;
}[] => {
	const groupOptions = useGroupOptions(fieldKey);
	const fieldOptions = useFieldOptionsWithAriResolved(fieldKey);
	const fieldType = useFieldType(fieldKey);

	const isEditable = useMemo(() => canModifyOptionName(fieldType), [fieldType]);

	return useMemo(() => {
		if (isEditable) {
			return fieldOptions?.map(({ weight, ...rest }) => rest) ?? [];
		}
		return groupOptions.options.map(({ groupIdentity }) => ({
			id: groupIdentity,
			value: groupIdentity,
		}));
	}, [fieldOptions, groupOptions.options, isEditable]);
};

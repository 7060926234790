import {
	getDragValue,
	getHoveredItemIdsMap,
	getHoveredItemsArea,
	getHoveredItems,
	getSelectedItems,
	getHighlightedItem,
} from './ui.tsx';
import { createSelectorHook } from './utils.tsx';

export const useHoveredItems = createSelectorHook(getHoveredItems);
export const useHoveredItemsArea = createSelectorHook(getHoveredItemsArea);
export const useHoveredItemIdsMap = createSelectorHook(getHoveredItemIdsMap);
export const useHighlightedItem = createSelectorHook(getHighlightedItem);
export const useSelectedItems = createSelectorHook(getSelectedItems);
export const useDragValue = createSelectorHook(getDragValue);

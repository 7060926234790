import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const reloadGlobalField =
	(fieldKey: string): Action<State, Props> =>
	async ({ getState, setState }, { globalFieldRemote }) => {
		// no need to refetch the field if global fields were not loaded yet. we will load them on the first demand
		if (getState().meta.loading) {
			return;
		}

		const globalField = await globalFieldRemote.fetchGlobalField({ fieldKey });

		setState({
			globalFields: {
				...getState().globalFields,
				[fieldKey]: globalField,
			},
		});
	};

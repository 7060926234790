import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addButtonLabel: {
		id: 'polaris-component-view-access.access-screen.add-button-label',
		defaultMessage: 'Add',
		description:
			'Button that will add selected principals (users or groups) with selected view permission',
	},
	canEditLabel: {
		id: 'polaris-component-view-access.access-screen.can-edit-label',
		defaultMessage: 'Can edit',
		description: 'Option label for specifying viewer level permissions',
	},
	canViewLabel: {
		id: 'polaris-component-view-access.access-screen.can-view-label',
		defaultMessage: 'Can view',
		description: 'Option label for specifying editor level permissions',
	},
	addPrincipalsFailureMessage: {
		id: 'polaris-component-view-access.access-screen.add-principals-failure-message',
		defaultMessage: 'We were unable to add people',
		description: 'Error message that appears when adding principals fails',
	},
	addPrincipalsRetryAction: {
		id: 'polaris-component-view-access.access-screen.add-principals-retry-action',
		defaultMessage: 'Retry',
		description: 'Retry label to try to add principals again after a failure',
	},
	addPrincipalsCancelAction: {
		id: 'polaris-component-view-access.access-screen.add-principals-cancel-action',
		defaultMessage: 'Cancel',
		description: 'Cancel label to remove an error message about a failure to add principals',
	},
	modalDescription: {
		id: 'polaris-component-view-access.modal-description',
		defaultMessage:
			'You can manage who can see this view and change grouping, filtering, sorting, and the field display.',
		description: 'Modal description, displayed under the modal header',
	},
	modalDescriptionLinkText: {
		id: 'polaris-component-view-access.modal-description-link',
		defaultMessage: 'More on restricting a view.',
		description: 'Link inside the modal description',
	},
});

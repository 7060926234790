/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import Tooltip from '@atlaskit/tooltip';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import { useFieldLabel } from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { ConnectionsList } from '../../../../connection/connection-list/index.tsx';
import { TooltipComponent } from '../../../../connection/tooltip-component/index.tsx';
import { FieldLabel } from '../../field/styled.tsx';
import { LabelItem } from '../label-item/index.tsx';

type Props = {
	connectionItems: ConnectionFieldValue[];
	fieldKey: string;
	localIssueId: string;
};

export const DetailedShort = ({ fieldKey, connectionItems, localIssueId }: Props) => {
	const fieldLabel = useFieldLabel(fieldKey);

	return (
		<>
			<FieldLabel>{fieldLabel}</FieldLabel>

			<div css={boxStyles}>
				<Tooltip
					content={
						<ConnectionsList
							localIssueId={localIssueId}
							fieldKey={fieldKey}
							connectionItems={connectionItems}
						/>
					}
					component={TooltipComponent}
					position="bottom-start"
					hideTooltipOnClick
				>
					<LabelItem amount={connectionItems.length} fieldKey={fieldKey} />
				</Tooltip>
			</div>
		</>
	);
};

const boxStyles = css({
	justifySelf: 'flex-start',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '5px',
});

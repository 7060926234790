import React, { useState, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalTransition,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useFieldLabel } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useViewIdsConfiguredByField } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ViewsConfiguredByField } from '@atlassian/jira-polaris-common/src/ui/config/fields/views-configured-by-field/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';
import type { HideConfirmationDialogProps } from './types.tsx';

export const HideConfirmationDialog = ({
	isOpen,
	onClose,
	fieldKey,
}: HideConfirmationDialogProps) => {
	const { formatMessage } = useIntl();
	const [updatingHidden, setUpdatingHidden] = useState(false);
	const { updateSystemFieldHidden } = useFieldActions();
	const fieldLabel = useFieldLabel(fieldKey);
	const viewIdsConfiguredByField = useViewIdsConfiguredByField(fieldKey);
	const numOfAffectedViews = viewIdsConfiguredByField.length;

	const handleHideField = async (analyticEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticEvent, 'hideSystemField');
		setUpdatingHidden(true);
		try {
			await updateSystemFieldHidden(fieldKey, true);
			onClose();
		} finally {
			setUpdatingHidden(false);
		}
	};

	const getDescription = () => {
		if (numOfAffectedViews) {
			return (
				<>
					<Text>
						{formatMessage(messages.descriptionUsedField, {
							viewsCount: numOfAffectedViews,
							b: (text: React.ReactNode) => <Text weight="bold">{text}</Text>,
						})}
					</Text>
					<ViewsConfiguredByField fieldKey={fieldKey} />
				</>
			);
		}
		return <Text>{formatMessage(messages.descriptionUnusedField)}</Text>;
	};

	return (
		<ModalTransition>
			{isOpen && (
				<ModalDialog width="600px" onClose={() => onClose()}>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.title, { field: fieldLabel })}</ModalTitle>
					</ModalHeader>
					<ModalBody>{getDescription()}</ModalBody>
					<ModalFooter>
						<Button onClick={() => onClose()}>{formatMessage(messages.cancel)}</Button>
						<LoadingButton
							testId="polaris-ideas.ui.right-sidebar.field.hide-confirmation-dialog.hide"
							appearance="primary"
							isLoading={updatingHidden}
							onClick={(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
								handleHideField(analyticEvent);
							}}
						>
							{formatMessage(messages.hide)}
						</LoadingButton>
					</ModalFooter>
				</ModalDialog>
			)}
		</ModalTransition>
	);
};

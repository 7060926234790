import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	title: string;
	id: string;
	onClose: () => void;
	onDelete: (id: string) => void;
};

export const DeleteTemplateModal = ({ title, id, onClose, onDelete }: Props) => {
	const { formatMessage } = useIntl();

	const onDeleteTemplate = useCallback(() => {
		onDelete(id);
		onClose();
	}, [id, onClose, onDelete]);

	return (
		<ModalTransitionUniversal>
			<ShortcutScope>
				<ModalDialog onClose={onClose}>
					<ModalHeader>
						<ModalTitle appearance="danger">
							{formatMessage(messages.deleteTemplateTitle, { title })}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<p>{formatMessage(messages.deleteTemplateInfoMessage, { title })}</p>
					</ModalBody>
					<ModalFooter>
						<Button appearance="subtle" autoFocus={false} onClick={onClose}>
							{formatMessage(messages.cancelButton)}
						</Button>
						<Button appearance="danger" autoFocus onClick={onDeleteTemplate}>
							{formatMessage(messages.deleteButton)}
						</Button>
					</ModalFooter>
				</ModalDialog>
			</ShortcutScope>
		</ModalTransitionUniversal>
	);
};

import merge from 'lodash/merge';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { applyObservabilityHeaders } from '@atlassian/jira-fetch/src/utils/observability-headers.tsx';
import type { JiraFieldIdMap } from './types.tsx';
import { getFieldsMappingUrl } from './utils.tsx';

const traceIdHeader = 'Atl-Polaris-RequestId';

export type JiraFieldIdMapResponse = {
	retries: number;
	error?: Error;
	fieldMap: JiraFieldIdMap;
	traceId: string;
	serverTraceId?: string;
};

type TracedResponse<TResponse> = {
	response: TResponse;
	serverTraceId: string | undefined;
};

const defaultOptions: RequestInit = {
	credentials: 'same-origin',
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

const fetchWithTracing = <TResponse,>(
	url: string,
	options: RequestInit = {},
): Promise<TracedResponse<TResponse>> =>
	fetch(url, applyObservabilityHeaders(url, merge(defaultOptions, options))).then((response) => {
		const serverTraceId = response.headers.get(traceIdHeader) ?? undefined;
		if (!response.ok) {
			throw new FetchError(
				response.status,
				`Fetch call failed with status code: ${response.status}`,
				serverTraceId,
				response,
			);
		}

		return response.json().then((val: TResponse) => ({
			response: val,
			serverTraceId,
		}));
	});

const getPolarisFieldsIdMappingWithRetry = (
	projectIdOrKey: number | string,
	maxRetries: number,
	retryCount = 0,
): Promise<JiraFieldIdMapResponse> => {
	const { url, traceId } = getFieldsMappingUrl(projectIdOrKey, true);

	return fetchWithTracing<JiraFieldIdMap>(url, {
		headers: {
			[traceIdHeader]: traceId,
		},
	})
		.then(({ response, serverTraceId }) => ({
			fieldMap: response,
			retries: retryCount,
			traceId,
			serverTraceId,
		}))
		.catch(
			(err) =>
				new Promise<JiraFieldIdMapResponse>((resolve) => {
					// log error and retry count
					log.safeWarnWithoutCustomerData(
						'polaris.remote.field.src.services.jira.fetch',
						`getFieldsMappingUrl attempt [${retryCount}/${maxRetries}/${traceId}] failed: ${err}`,
						{
							traceId,
							serverTraceId: err.serverTraceId,
							retryCount,
							maxRetries,
						},
					);

					if (retryCount >= maxRetries) {
						log.safeWarnWithoutCustomerData(
							'polaris.remote.field.src.services.jira.fetch',
							'retry budget exhausted, returning empty field mapping',
						);
						return resolve({
							fieldMap: {},
							retries: retryCount,
							error: err,
							traceId,
							serverTraceId: err.serverTraceId,
						});
					}

					setTimeout(
						() =>
							getPolarisFieldsIdMappingWithRetry(projectIdOrKey, maxRetries, retryCount + 1).then(
								resolve,
							),
						1000,
					);
				}),
		);
};

export const getPolarisFieldsIdMapping = (
	projectIdOrKey: number | string,
): Promise<JiraFieldIdMapResponse> => {
	return getPolarisFieldsIdMappingWithRetry(projectIdOrKey, 2);
};

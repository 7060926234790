import type { DynamicFieldFormula } from '../types.tsx';
import { CONSTANT_TEMPLATE, type ConstantFormula } from './types.tsx';

export const constant = (value: number): ConstantFormula => ({
	template: CONSTANT_TEMPLATE,
	parameters: {
		value,
	},
});

export const isConstant = (formula: DynamicFieldFormula): boolean =>
	formula.template === CONSTANT_TEMPLATE;

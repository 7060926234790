import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { Wrapper } from '../../styled.tsx';

export const FieldGroupedCount = ({
	val,
	message,
}: {
	val: number;
	message?: MessageDescriptor;
}) => {
	const { formatMessage, formatNumber } = useIntl();
	const valFormatted = formatNumber(val);

	return <Wrapper>{message && formatMessage(message, { val: valFormatted })}</Wrapper>;
};

import { getInsightsWithErrors } from '../../services/polaris-api/get-insights/index.tsx';
import type { ProviderProps, RemoteInsightsWithErrorsFetcher } from '../../types.tsx';

export const fetchInsightsWithErrors =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteInsightsWithErrorsFetcher =>
	() =>
		getInsightsWithErrors(apolloClient, cloudId, projectId).then((insights) => [
			...insights.map((insight) => ({
				...insight,
				description: null,
				snippets: [
					...insight.snippets.map((snippet) => ({
						...snippet,
						properties: {
							...snippet.properties,
						},
					})),
				],
			})),
		]);

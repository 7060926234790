import React, { useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueStore } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { createGetIssuesForMerge } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useMergeIdeasDialogActions } from '@atlassian/jira-polaris-common/src/controllers/merge-ideas-dialog/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type MergeButtonProps = {
	isDropdownItem?: boolean;
};

export const MergeButton = ({ isDropdownItem = false }: MergeButtonProps) => {
	const { formatMessage } = useIntl();
	const localIssueId = useSelectedIssue();
	const { toggleMergeIdeasDialog } = useMergeIdeasDialogActions();
	const [issueStoreState] = useIssueStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent?: UIAnalyticsEvent) => {
			if (isDropdownItem) {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
					'issueOption',
					{
						selectedActionItem: 'merge',
					},
				);
			} else {
				// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
				fireUIAnalytics(analyticsEvent, 'merge');
			}

			if (localIssueId === undefined) {
				return;
			}
			const issuesForMerge = createGetIssuesForMerge(
				localIssueId !== undefined ? [localIssueId] : [],
			)(issueStoreState, issueStoreState.containerProps);
			toggleMergeIdeasDialog(issuesForMerge);
		},
		[toggleMergeIdeasDialog, localIssueId, issueStoreState, createAnalyticsEvent, isDropdownItem],
	);

	const Component = isDropdownItem ? DropdownItem : Button;

	return (
		<Component
			testId="polaris-ideas.ui.idea-view.collaboration-controls.merge.button"
			onClick={handleClick}
		>
			{formatMessage(messages.mergeButton)}
		</Component>
	);
};

import type { ContinuousAxisRange, StepConfig } from '../../types.tsx';

const DEFAULT_STEP_CONFIG = {
	maxStepCount: 10,
	minStepWidth: 50,
} as const;

export const calculatePrimeFactors = (number: number): number[] => {
	const factors = [1];
	let divisor = 2;
	let remaining = number;
	while (remaining >= 2) {
		if (remaining % divisor === 0) {
			factors.push(divisor);
			remaining /= divisor;
		} else {
			divisor += 1;
		}
	}
	return factors;
};

export const calculateMaxProduct = (sortedFactors: number[], limit: number): number => {
	const filteredFactors = sortedFactors.filter((it) => it < limit);
	let result = 1;
	const subsets = [[]];

	filteredFactors.forEach((el) => {
		const last = subsets.length - 1;
		for (let i = 0; i <= last; i += 1) {
			const next = [...subsets[i], el];
			const nextProduct = next.reduce((a, b) => a * b, 1);
			if (nextProduct <= limit) {
				result = Math.max(result, nextProduct);
				// @ts-expect-error - TS2345 - Argument of type 'number[]' is not assignable to parameter of type 'never[]'.
				subsets.push(next);
			}
		}
	});
	return result;
};

const calculateNumberOfIntervalsMultiplesOfFive = (
	maxValue: number,
	maxIntervalCount: number,
): number => {
	const maxFactors = calculatePrimeFactors(maxValue);
	const index5 = maxFactors.indexOf(5);
	if (index5 < 0) {
		return 2;
	}
	maxFactors.splice(index5, 1);
	return calculateMaxProduct(maxFactors, maxIntervalCount);
};

const calculateMaxIntervalCount = (currentWidth: number, stepConfig?: StepConfig): number => {
	const maxIntervalCountBasedOnWidth =
		currentWidth /
		(stepConfig?.minStepWidth !== undefined
			? stepConfig.minStepWidth
			: DEFAULT_STEP_CONFIG.minStepWidth);
	return Math.floor(
		Math.min(
			maxIntervalCountBasedOnWidth,
			stepConfig?.maxStepCount !== undefined
				? stepConfig.maxStepCount
				: DEFAULT_STEP_CONFIG.maxStepCount,
		),
	);
};

export const calculateIntervalCount = (
	{ min, max, stepConfig }: ContinuousAxisRange,
	currentWidth: number,
): number => {
	const maxIntervalCount = calculateMaxIntervalCount(currentWidth, stepConfig);
	if (maxIntervalCount === 0) {
		return 0;
	}
	if (maxIntervalCount >= max - min) {
		return max - min;
	}
	return calculateNumberOfIntervalsMultiplesOfFive(max, maxIntervalCount);
};

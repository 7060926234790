/** @jsx jsx */
import React, { useCallback, useMemo, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import EyeOpenIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { useListActions } from '../../../controllers/index.tsx';
import { useGoToRowLabelComponent } from '../../../controllers/selectors/components-hooks.tsx';
import {
	useRowConfiguration,
	useShouldRenderRowPinnedBottomForGroups,
	useShouldRenderRowPinnedBottomForNoGroups,
	useUpdatedRowProps,
} from '../../../controllers/selectors/items-hooks.tsx';
import { useIsGrouped } from '../../../controllers/selectors/rows-groups-hooks.tsx';
import { useRowsRendered } from '../../../controllers/selectors/rows-hooks.tsx';
import { useBaseTableApi } from '../../../controllers/selectors/ui-hooks.tsx';
import { DEFAULT_HEADER_HEIGHT } from '../../constants.tsx';
import { createListApi } from '../../utils/list-api.tsx';
import messages from './messages.tsx';

const MARGIN_VIEWPORT_EDGE = 20;
const MARGIN_VIEWPORT_EDGE_FOR_BULK_EDITING = 112;

type ButtonWithScrollToRowProps = {
	rowIndex: number | null;
	direction: -1 | 1;
	isBulkEditDialogShown: boolean;
	testId?: string;
};

const ButtonWithScrollToRow = ({
	rowIndex,
	isBulkEditDialogShown,
	direction,
	testId,
}: ButtonWithScrollToRowProps) => {
	const isGrouped = useIsGrouped();
	const rawRowsData = useRowConfiguration();
	const rowsRendered = useRowsRendered();
	const tableApi = useBaseTableApi();
	const GoToRowComponent = useGoToRowLabelComponent();
	const shouldRenderRowPinnedBottomForGroups = useShouldRenderRowPinnedBottomForGroups();
	const shouldRenderRowPinnedBottomForNoGroups = useShouldRenderRowPinnedBottomForNoGroups();

	const { formatMessage } = useIntl();
	const messageMoved = formatMessage(messages.ideaMoved);

	const rawRowsDataRef = useRef(rawRowsData);
	rawRowsDataRef.current = rawRowsData;

	const rowsRenderedRef = useRef(rowsRendered);
	rowsRenderedRef.current = rowsRendered;

	const handleClick = useCallback(() => {
		if (rowIndex === null || !tableApi) {
			return;
		}

		const listApi = createListApi({
			baseTableApi: tableApi,
			rawRowsDataRef,
			rowsRenderedRef,
			isGrouped,
		});

		listApi.ensureRowVisibleByIndex(rowIndex, {
			preferRowStickyVisibility: true,
			preferNextRowPinnedBottomVisibility: isGrouped
				? shouldRenderRowPinnedBottomForGroups
				: shouldRenderRowPinnedBottomForNoGroups,
		});
	}, [
		isGrouped,
		rowIndex,
		shouldRenderRowPinnedBottomForGroups,
		shouldRenderRowPinnedBottomForNoGroups,
		tableApi,
	]);

	const styles = useMemo(
		() =>
			direction === -1
				? { top: `${DEFAULT_HEADER_HEIGHT + MARGIN_VIEWPORT_EDGE}px` }
				: {
						bottom: `${isBulkEditDialogShown ? MARGIN_VIEWPORT_EDGE_FOR_BULK_EDITING : MARGIN_VIEWPORT_EDGE}px`,
					},
		[direction, isBulkEditDialogShown],
	);

	const Icon = direction === -1 ? ArrowUpIcon : ArrowDownIcon;

	return (
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
		<div css={buttonContainerStyles} style={styles}>
			<Button
				data-component-selector="rounded-button-573b"
				iconAfter={(iconProps) => <Icon {...iconProps} LEGACY_size="medium" />}
				appearance="primary"
				onClick={handleClick}
				testId={testId}
			>
				{GoToRowComponent ? <GoToRowComponent /> : messageMoved}
			</Button>
		</div>
	);
};

export type MovedRowOverlayProps = {
	isBulkEditDialogShown: boolean;
	testId?: string;
};

export const MovedRowOverlay = ({ isBulkEditDialogShown, testId }: MovedRowOverlayProps) => {
	const { startIndex, stopIndex } = useRowsRendered();
	const { id, idx, isMoved, isFiltered } = useUpdatedRowProps();
	const { showIdeaPreview, setUpdatedRows } = useListActions();

	const { formatMessage } = useIntl();
	const messageFiltered = formatMessage(messages.ideaFiltered);

	const handleClickFiltered = () => {
		id !== null && showIdeaPreview(id, []);
	};

	const handleOnClickOutside = () => {
		setUpdatedRows([]);
	};

	const direction = useMemo(() => {
		if (idx === null) {
			// Updated row is hidden
			return 0;
		}
		if (idx < startIndex) {
			return -1;
		}
		if (idx > stopIndex) {
			return 1;
		}

		return 0;
	}, [startIndex, stopIndex, idx]);

	if (direction === 0 || (!isFiltered && !isMoved)) {
		return null;
	}

	return (
		<OutsideClickAlerter onClickOutside={handleOnClickOutside}>
			{({ onMouseDownCapture, onTouchEndCapture }) => (
				<Box
					onMouseDownCapture={onMouseDownCapture}
					onTouchEndCapture={onTouchEndCapture}
					xcss={pointerEventsStyles}
				>
					{isFiltered && (
						<div css={[buttonContainerStyles, bottomButtonContainerStyles]}>
							<Button
								data-component-selector="rounded-button-573b"
								iconAfter={(iconProps) => <EyeOpenIcon {...iconProps} LEGACY_size="medium" />}
								appearance="primary"
								onClick={handleClickFiltered}
								testId={testId}
							>
								{messageFiltered}
							</Button>
						</div>
					)}
					{!isFiltered && isMoved && (
						<ButtonWithScrollToRow
							rowIndex={idx}
							direction={direction}
							isBulkEditDialogShown={isBulkEditDialogShown}
							testId={testId}
						/>
					)}
				</Box>
			)}
		</OutsideClickAlerter>
	);
};

const pointerEventsStyles = xcss({ pointerEvents: 'auto' });

const buttonContainerStyles = css({
	display: 'flex',
	position: 'absolute',
	left: '50%',
	transform: 'translate(-50%, 0)',
	zIndex: 3,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& [data-component-selector="rounded-button-573b"]': {
		borderRadius: token('space.200'),
		paddingLeft: token('space.200'),
		boxShadow: token('elevation.shadow.overlay'),
	},
});

const bottomButtonContainerStyles = css({
	bottom: `${MARGIN_VIEWPORT_EDGE}px`,
});

import { createSelector } from 'reselect';
import { type Axis, type AxisValue, CONTINUOUS, DISCRETE, type ItemId } from '../../types.tsx';
import {
	createGetAxisIndexForItem,
	createGetAxisIndexForValue,
	getXAxis,
	getXAxisValues,
	getYAxis,
	getYAxisValues,
	getZAxis,
	getZAxisValues,
	isYAxisReversed,
	isXAxisReversed,
} from './axis.tsx';

const getStepCount = (axis?: Axis): number => {
	if (axis === undefined) {
		return 0;
	}

	let stepCount = 0;
	if (axis.type === DISCRETE) {
		stepCount = axis.range.values.length;
	} else if (axis.type === CONTINUOUS) {
		stepCount = axis.range.max - axis.range.min;
	}

	return stepCount;
};

export const computeAxisScaleFactor = (axis?: Axis, availableSpace?: number): number => {
	if (availableSpace === undefined) {
		return 0;
	}

	const stepCount = getStepCount(axis);
	if (stepCount === 0) {
		return 0;
	}
	return availableSpace / stepCount;
};

export const computeAxisScaleOffset = (axis?: Axis, availableSpace?: number): number => {
	if (axis === undefined || availableSpace === undefined) {
		return 0;
	}
	if (axis.type === DISCRETE) {
		const factor = computeAxisScaleFactor(axis, availableSpace);
		return factor / 2;
	}
	if (axis.type === CONTINUOUS) {
		// no offset on continuous axis
		return 0;
	}

	return 0;
};

const getXAxisRelativeScaleFactor = createSelector(getXAxis, () => 100, computeAxisScaleFactor);

const getYAxisRelativeScaleFactor = createSelector(getYAxis, () => 100, computeAxisScaleFactor);

const getXAxisRelativeScaleOffset = createSelector(getXAxis, () => 100, computeAxisScaleOffset);

const getYAxisRelativeScaleOffset = createSelector(getYAxis, () => 100, computeAxisScaleOffset);

/**
 * Returns the pixel position on the X axis for an Item
 */
export const createGetRelativeXAxisPositionForItem = (item: ItemId) =>
	createSelector(
		createGetAxisIndexForItem(item, getXAxis, getXAxisValues),
		getXAxisRelativeScaleFactor,
		getXAxisRelativeScaleOffset,
		isXAxisReversed,
		(val, fact, offset, isReversed) => {
			const position = isReversed ? 100 - val * fact - offset : val * fact + offset;
			return position;
		},
	);

/**
 * Returns the pixel position on the Y axis for an Item
 */
export const createGetRelativeYAxisPositionForItem = (item: ItemId) =>
	createSelector(
		createGetAxisIndexForItem(item, getYAxis, getYAxisValues),
		getYAxisRelativeScaleFactor,
		getYAxisRelativeScaleOffset,
		isYAxisReversed,
		(val, fact, offset, isReversed) => {
			const position = isReversed ? val * fact + offset : 100 - val * fact - offset;
			return position;
		},
	);

/**
 * Returns the left/top positioning of an item
 */
export const createGetRelativeItemCenterPosition = (item: ItemId) =>
	createSelector(
		createGetRelativeXAxisPositionForItem(item),
		createGetRelativeYAxisPositionForItem(item),
		(left, top) => ({ left, top }),
	);

/**
 * Returns the left positioning of an axis-value
 */
export const createGetRelativeXAxisValueCenterPosition = (value?: AxisValue) =>
	createSelector(
		createGetAxisIndexForValue(value, getXAxis),
		getXAxisRelativeScaleFactor,
		getXAxisRelativeScaleOffset,
		(val, fact, offset) => val * fact + offset,
	);

/**
 * Returns the left positioning of an axis-value
 */
export const createGetRelativeYAxisValueCenterPosition = (value?: AxisValue) =>
	createSelector(
		createGetAxisIndexForValue(value, getYAxis),
		getYAxisRelativeScaleFactor,
		getYAxisRelativeScaleOffset,
		(val, fact, offset) => val * fact + offset,
	);

// ==============
// === Z axis ===
// ==============

const QUANTILE_Z_POSITION = [30, 45, 60, 75];

export const getZPosition = (itemAxisIndex: number, axis?: Axis) => {
	const stepCount = getStepCount(axis);
	if (stepCount === 0) {
		return 20;
	}
	const ratio = itemAxisIndex / stepCount;
	return QUANTILE_Z_POSITION[Math.max(0, Math.ceil(ratio * QUANTILE_Z_POSITION.length) - 1)];
};

export const createGetZAxisBucketedPositionForItems = (itemIds: ItemId[]) =>
	createSelector(
		createGetAxisIndexForItem(itemIds[0], getZAxis, getZAxisValues),
		getZAxis,
		getZPosition,
	);

import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	type IssuesByGroupIdentity,
	type IssuesByCell,
	type GroupOptions,
	createGetSortedGroupOptions,
	createGetGroupOptions,
	createGetLocalIssueIdsByGroupIdentity,
	createGetUnfilteredLocalIssueIdsByGroupIdentity,
	createGetLocalIssueIdsForGroupIdentity,
	createGetLocalIssueIdsByCell,
	createGetLocalIssueIdsForCell,
	createGetSearchableLocalIssueIds,
	createGetLocalIssueIdsCountForVerticalGroupIdentity,
	createGetUnfilteredLocalIssueIdsByCell,
} from './grouping.tsx';
import {
	createHigherLevelIssueHook,
	createHigherLevelIssueHook2,
	createHigherLevelIssueHook3,
	createHigherLevelIssueHook4,
} from './utils.tsx';

export const useSortedGroupOptions = createHigherLevelIssueHook<
	FieldKey | undefined,
	GroupOptions<unknown>
>(createGetSortedGroupOptions);

export const useGroupOptions = createHigherLevelIssueHook<FieldKey, GroupOptions<unknown>>(
	createGetGroupOptions,
);

export const useLocalIssueIdsByGroupIdentity = createHigherLevelIssueHook<
	FieldKey | undefined,
	IssuesByGroupIdentity
>(createGetLocalIssueIdsByGroupIdentity);

export const useUnfilteredLocalIssueIdsByGroupIdentity = createHigherLevelIssueHook<
	FieldKey,
	IssuesByGroupIdentity
>(createGetUnfilteredLocalIssueIdsByGroupIdentity);

export const useLocalIssueIdsForGroupIdentity = createHigherLevelIssueHook2<
	FieldKey,
	string | undefined,
	LocalIssueId[]
>(createGetLocalIssueIdsForGroupIdentity(createGetLocalIssueIdsByGroupIdentity));

export const useUnfilteredLocalIssueIdsForGroupIdentity = createHigherLevelIssueHook2<
	FieldKey,
	string | undefined,
	LocalIssueId[]
>(createGetLocalIssueIdsForGroupIdentity(createGetUnfilteredLocalIssueIdsByGroupIdentity));

export const useLocalIssueIdsForCell = createHigherLevelIssueHook4<
	FieldKey,
	string | undefined,
	FieldKey,
	string | undefined,
	LocalIssueId[]
>(createGetLocalIssueIdsForCell(createGetLocalIssueIdsByCell));

export const useLocalIssueIdsByCell = createHigherLevelIssueHook2<FieldKey, FieldKey, IssuesByCell>(
	createGetLocalIssueIdsByCell,
);

export const useUnfilteredLocalIssueIdsByCell = createHigherLevelIssueHook2<
	FieldKey,
	FieldKey,
	IssuesByCell
>(createGetUnfilteredLocalIssueIdsByCell);

export const useSearchableLocalIssueIds = createHigherLevelIssueHook4<
	FieldKey,
	string | undefined,
	FieldKey | undefined,
	string | undefined,
	LocalIssueId[]
>(createGetSearchableLocalIssueIds);

export const useLocalIssuesCountForVerticalGroupIdentity = createHigherLevelIssueHook3<
	FieldKey,
	FieldKey,
	string | undefined,
	number
>(createGetLocalIssueIdsCountForVerticalGroupIdentity(createGetLocalIssueIdsByCell));

import React from 'react';
import { useFeedbackModal } from '@atlassian/jira-polaris-common/src/controllers/feedback-modal/index.tsx';
import FeedbackModal from '@atlassian/jira-polaris-lib-feedback/src/ui/modal/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';

export const Feedback = () => {
	const [{ isFeedbackModalOpen }, { setIsFeedbackModalOpen }] = useFeedbackModal();
	const { success, error } = useNotifications();

	if (!isFeedbackModalOpen) {
		return null;
	}

	return (
		<FeedbackModal
			onCancel={() => setIsFeedbackModalOpen(false)}
			onSuccess={success}
			onError={error}
		/>
	);
};

import React, { useMemo } from 'react';
import LockLockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import LockUnlockedIcon from '@atlaskit/icon/core/migration/lock-unlocked--unlock-filled';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import { usePermissionsEditMode } from '../../../controllers/main.tsx';
import messages from './messages.tsx';
import type { OptionType } from './select-components/index.tsx';

export const useAccessLevelOptions = (): OptionType[] => {
	const { formatMessage } = useIntl();
	const editingMode = usePermissionsEditMode();

	return useMemo(
		() => [
			{
				label:
					editingMode === 'roadmap'
						? formatMessage(messages.roadmapOpenOptionVerboseLabel)
						: formatMessage(messages.openOptionVerboseLabel),
				value: VIEW_ACCESS_LEVELS.OPEN,
				icon: <LockUnlockedIcon label={formatMessage(messages.open)} color="currentColor" />,
			},
			{
				label:
					editingMode === 'roadmap'
						? formatMessage(messages.roadmapLimitedOptionVerboseLabel)
						: formatMessage(messages.limitedOptionVerboseLabel),
				value: VIEW_ACCESS_LEVELS.LIMITED,
				icon: <LockLockedIcon label={formatMessage(messages.limited)} color="currentColor" />,
			},
			{
				label:
					editingMode === 'roadmap'
						? formatMessage(messages.roadmapRestrictedOptionVerboseLabel)
						: formatMessage(messages.restrictedOptionVerboseLabel),
				value: VIEW_ACCESS_LEVELS.RESTRICTED,
				icon: (
					<LockLockedIcon
						label={formatMessage(messages.restricted)}
						color={token('color.icon.accent.red')}
					/>
				),
			},
		],
		[formatMessage, editingMode],
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldSortDescription: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.field-sort-description',
		defaultMessage: 'Ideas are sorted based on fields’ values',
		description: 'Indicator that ideas are sorted by fields',
	},
	changeToRankedModeTrigger: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.change-to-ranked-mode-trigger',
		defaultMessage: 'Convert sort to rank',
		description: 'caption on trigger button to change sort mode to a ranked sorting mode',
	},
	changeToRankedModeTooltip: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.change-to-ranked-mode-tooltip',
		defaultMessage: 'You will be able to make changes to the order of ideas manually',
		description: 'tooltip on button to change sort mode to a ranked sorting mode',
	},
	convertToViewRank: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.convert-to-view-rank',
		defaultMessage: 'Convert to view rank',
		description: 'caption to go back to view rank sort mode',
	},
	convertToViewRankDescription: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.convert-to-view-rank-description',
		defaultMessage:
			'The rank of ideas is specific to this view and isn’t shared with other views in this project',
		description: 'description to go back to project rank sort mode',
	},
	convertToProjectRank: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.convert-to-project-rank',
		defaultMessage: 'Convert to project rank',
		description:
			'caption to go back to project rank sort mode and update it with the current view rank',
	},
	convertToProjectRankDescription: {
		id: 'polaris-component-view-sort-configuration.advanced.field-sort.convert-to-project-rank-description',
		defaultMessage: 'The order of ideas in this view is shared across views in this project',
		description:
			'description to go back to project rank sort mode and update it with the current view rank',
	},
});

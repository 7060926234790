import { createSelector } from 'reselect';
import indexOf from 'lodash/indexOf';
import isNumber from 'lodash/isNumber';
import { type Axis, type AxisValue, CONTINUOUS, DISCRETE, type ItemId } from '../../types.tsx';
import type { AxisValueMap, State } from '../types.tsx';

export type AxisSelector = (arg1: State) => Axis | undefined;
export type AxisValuesSelector = (arg1: State) => AxisValueMap;

export const getXAxis = (state: State) => state.xAxis;
export const getXAxisValues = (state: State) => state.axisValues.x;
export const isXAxisDiscrete = (state: State) => state.xAxis?.type === DISCRETE;
export const isXAxisReversed = (state: State) =>
	state.xAxis?.type === CONTINUOUS && state.xAxis?.range.isReverseOrder;
export const getYAxis = (state: State) => state.yAxis;
export const getYAxisValues = (state: State) => state.axisValues.y;
export const isYAxisDiscrete = (state: State) => state.yAxis?.type === DISCRETE;
export const isYAxisReversed = (state: State) =>
	state.yAxis?.type === CONTINUOUS && state.yAxis?.range.isReverseOrder;
export const getZAxis = (state: State) => state.zAxis;
export const getZAxisValues = (state: State) => state.axisValues.z;
export const areXAndYAxisDiscrete = (state: State) =>
	isXAxisDiscrete(state) && isYAxisDiscrete(state);
export const isXAxisLocked = (state: State) => state.xAxisLocked;
export const isYAxisLocked = (state: State) => state.yAxisLocked;
export const isAxesDragDisabled = (state: State) => state.isAxesDragDisabled;

/**
 * Computes the index of a value on an axis. The index denotes the relative position on the axis,
 * not regarding any scaling on the container
 */
export const computeAxisValueIndex = (axis?: Axis, value?: AxisValue): number => {
	if (axis === undefined || value === undefined) {
		return 0;
	}

	if (axis.type === DISCRETE) {
		const index = indexOf(axis.range.values, value);
		return index !== -1 ? index : 0;
	}

	if (axis.type === CONTINUOUS && typeof isNumber(value)) {
		const normalisedMax = axis.range.max - axis.range.min;
		// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
		const normalisedValue = value - axis.range.min;
		return Math.max(0, Math.min(normalisedValue, normalisedMax));
	}

	return 0;
};

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
export const createGetAxisIndexForValue = (value?: AxisValue, axisSelector: AxisSelector) =>
	createSelector(axisSelector, (axis) => computeAxisValueIndex(axis, value));

const createGetAxisValueForItem = (itemId: ItemId, axisValuesSelector: AxisValuesSelector) =>
	createSelector(axisValuesSelector, (map) => map[itemId]);

export const createGetAxisIndexForItem = (
	itemId: ItemId,
	axisSelector: AxisSelector,
	axisValuesSelector: AxisValuesSelector,
) =>
	createSelector(
		axisSelector,
		createGetAxisValueForItem(itemId, axisValuesSelector),
		computeAxisValueIndex,
	);

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'polaris-component-view-visitors.visitors-list.heading',
		defaultMessage: 'Visitors',
		description: 'Visitor popup heading to show who visited the view or who is currently online',
	},
	anonymous: {
		id: 'polaris-component-view-visitors.visitors-list.anonymous',
		defaultMessage: 'Anonymous visitor',
		description: 'Anonymous visitors are users who visited the view without an Atlassian account',
	},
});

import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingField } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import type { RowRendererComponentProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';

export const Row = ({ DefaultComponent, ...rest }: RowRendererComponentProps) => {
	const { id, index } = rest.rowData;
	const [showing] = useRightSidebarShowing();
	const selectedFieldKey = showing.mode === RightSidebarShowingField ? showing.fieldKey : undefined;

	const fieldAnalyticsData = useMemo(
		() => ({
			issueFieldKey: selectedFieldKey,
		}),
		[selectedFieldKey],
	);

	const isSelected = selectedFieldKey === id;

	// No need to wrap the header row with analytics context
	if (index === -1) {
		return <DefaultComponent {...rest} />;
	}

	return (
		<ContextualAnalyticsData attributes={fieldAnalyticsData}>
			<Box xcss={[isSelected && selectedFieldStyles]} />
			<DefaultComponent {...rest} />
		</ContextualAnalyticsData>
	);
};

const selectedFieldStyles = xcss({
	borderLeft: '4px solid',
	borderColor: 'color.border.selected',
	position: 'absolute',
	height: '100%',
});

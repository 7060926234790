import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	defaultTemplatesTitle: {
		id: 'polaris-common.create-idea-dialog.fields.document.templates-popup.default-templates-title',
		defaultMessage: 'Default templates',
		description: 'Title for default templates section',
	},
	moreTemplatesButton: {
		id: 'polaris-common.create-idea-dialog.fields.document.templates-popup.more-templates-button',
		defaultMessage: 'More templates',
		description: 'Label for more templates button',
	},
});

import React from 'react';
import { styled } from '@compiled/react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FieldName } from '../../../common/ui/field/styled.tsx';
import { useDecorationForValue } from '../../../controllers/field/selectors/decoration/hooks.tsx';
import { useFieldLabel } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useMultiSelect } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { DecoratedTag } from '../../common/decoration/decoration/tag/index.tsx';
import { TooltipFieldWithLabelContainer } from '../styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
};

type OnlyHighlightedDecoratedTagProps = {
	fieldKey: FieldKey;
	id: string;
	value: string;
};

const OnlyHighlightedDecoratedTag = ({ fieldKey, id, value }: OnlyHighlightedDecoratedTagProps) => {
	const decoration = useDecorationForValue(fieldKey, id);
	if (!decoration?.highlightContainer) {
		return null;
	}

	return <DecoratedTag fieldKey={fieldKey} id={id} value={value} />;
};

export const MultiSelectField = ({ issueId, fieldKey }: Props) => {
	const values = useMultiSelect(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);

	if (values.length === 0) {
		return null;
	}

	return (
		<TooltipFieldWithLabelContainer key={fieldKey}>
			<LabelContainer>
				<FieldName>{label}</FieldName>
			</LabelContainer>
			<ValueContainer>
				{values.map((value) => (
					<OnlyHighlightedDecoratedTag
						key={value.id}
						fieldKey={fieldKey}
						id={value.id}
						value={value.value}
					/>
				))}
			</ValueContainer>
		</TooltipFieldWithLabelContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	alignSelf: 'center',
	minHeight: '26px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueContainer = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
	overflow: 'hidden',
});

import React, { memo, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useCurrentViewLayoutType } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IdeaCard } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main.tsx';
import type { ItemId } from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';

type Props = {
	isHovered: boolean;
	itemId: ItemId;
	onHeightChange?: () => void;
};

export const MatrixSidebarItem = memo(({ itemId, isHovered, onHeightChange }: Props) => {
	const viewLayoutType = useCurrentViewLayoutType();
	const isMountedRef = useRef(false);

	useEffect(() => {
		if (isMountedRef.current) {
			onHeightChange?.();
		} else {
			isMountedRef.current = true;
		}
	}, [onHeightChange, viewLayoutType]);

	return (
		<IdeaCardWrapper>
			<IdeaCard
				id={itemId}
				isHovered={isHovered}
				hasHoverState
				hoverBackgroundColor={token('elevation.surface.hovered', N20)}
				hideEmptyFields
				viewLayoutType={viewLayoutType}
			/>
		</IdeaCardWrapper>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaCardWrapper = styled.div({
	cursor: 'pointer',
	padding: `1px 1px ${token('space.100', '8px')}`,
	boxSizing: 'border-box',
});

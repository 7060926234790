import { ANTLRInputStream, BailErrorStrategy, CommonTokenStream } from 'antlr4ts';
import type { ParserRuleContext } from 'antlr4ts/ParserRuleContext';
import type { TerminalNode } from 'antlr4ts/tree/TerminalNode';
// @ts-expect-error - TS2306 - File '/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/polaris/expressions/src/common/utils/parser/antlr4-generated/ExprLexer.tsx' is not a module.
import { ExprLexer } from './antlr4-generated/ExprLexer.js';
import {
	type ExprsContext,
	type FactorContext,
	type LogicalContext,
	type PrimitiveContext,
	type SubexprContext,
	type TermContext,
	type NamedContext,
	AndContext,
	CallContext,
	DivideContext,
	EqualContext,
	CompleteExprContext,
	ExprParser,
	FieldRefContext,
	FloatContext,
	GreaterOrEqualContext,
	GreaterThanContext,
	IdentifierContext,
	IdentityFactorContext,
	IdentityLogicalContext,
	IdentityTermContext,
	InContext,
	IntegerContext,
	LessOrEqualContext,
	LessThanContext,
	MinusContext,
	NotEqualContext,
	NamedFieldContext,
	NamedIdentContext,
	OrContext,
	ParenContext,
	PlusContext,
	SomeExprListContext,
	StringContext,
	TimesContext,
	// @ts-expect-error - TS2306 - File '/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/polaris/expressions/src/common/utils/parser/antlr4-generated/ExprParser.tsx' is not a module.
} from './antlr4-generated/ExprParser.js';
// @ts-expect-error - TS2306 - File '/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/polaris/expressions/src/common/utils/parser/antlr4-generated/ExprVisitor.tsx' is not a module.
import type { ExprVisitor } from './antlr4-generated/ExprVisitor.js';
import type { ParseNode } from './types.tsx';

const fieldName = (tok: TerminalNode): string => {
	const { text } = tok.symbol;

	// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
	return text.slice(1, -1).trim();
};

const stringValue = (tok: TerminalNode): string => {
	const { text } = tok.symbol;
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
	return text.slice(1, -1).replace(/\\(["\\])/g, (match) => match[1]);
};

type NonFlattenableBinOpType = 'LT' | 'LE' | 'GT' | 'GE' | 'EQ' | 'NE' | 'in';

type FlattenableBinOpType = 'times' | 'plus' | 'divide' | 'minus' | 'and' | 'or';

type ExprList = {
	type: 'sequence';
	args: ParseNode[];
};

type Reduction = ParseNode | ExprList | undefined;

const comparison = (t: NonFlattenableBinOpType, l: Reduction, r: Reduction): Reduction => {
	if (l && r) {
		// this check is pointless, just to satisfy Flow; l/r will
		// never be a sequence in this context; for that matter, l/r
		// will never be void
		if (l.type === 'sequence' || r.type === 'sequence') {
			return undefined;
		}
		return {
			type: t,
			left: l,
			right: r,
		};
	}
	return undefined;
};

const flatten = (t: FlattenableBinOpType, l: Reduction, r: Reduction): Reduction => {
	if (l && r) {
		// this check is pointless, just to satisfy Flow; l/r will
		// never be a sequence in this context; for that matter, l/r
		// will never be void
		if (l.type === 'sequence' || r.type === 'sequence') {
			return undefined;
		}

		if (l.type === t) {
			// this is the flattening case.  Given (1+2)+3, turn it into 1+2+3
			return {
				type: t,
				args: [...l.args, r],
			};
		}
		return {
			type: t,
			args: [l, r],
		};
	}
	return undefined;
};

const namedToString = (ctx: NamedContext & ParserRuleContext): string => {
	if (ctx instanceof NamedIdentContext) {
		return ctx.IDENT().symbol.text;
	}
	if (ctx instanceof NamedFieldContext) {
		return fieldName(ctx.FIELD());
	}
	throw new Error('invalid named form');
};

class Visitor implements ExprVisitor<Reduction> {
	visitCompleteExpr = (ctx: CompleteExprContext): Reduction => {
		const { visitSubexpr } = this; // these shenanigans are to make Flow happy
		return visitSubexpr && visitSubexpr(ctx.subexpr());
	};

	visitSubexpr = (ctx: SubexprContext): Reduction => {
		const { visitLogical } = this; // these shenanigans are to make Flow happy
		return visitLogical && visitLogical(ctx.logical());
	};

	visitLogical = (ctx: LogicalContext & ParserRuleContext): Reduction => {
		const { visitTerm, visitLogical } = this;
		if (!visitTerm || !visitLogical) {
			// let Flow know what we already know... these methods are defined
			return undefined;
		}
		if (ctx instanceof IdentityLogicalContext) {
			return visitTerm(ctx.term());
		}
		if (ctx instanceof LessThanContext) {
			const left = visitTerm(ctx.term(0));
			const right = visitTerm(ctx.term(1));
			return comparison('LT', left, right);
		}
		if (ctx instanceof LessOrEqualContext) {
			const left = visitTerm(ctx.term(0));
			const right = visitTerm(ctx.term(1));
			return comparison('LE', left, right);
		}
		if (ctx instanceof GreaterThanContext) {
			const left = visitTerm(ctx.term(0));
			const right = visitTerm(ctx.term(1));
			return comparison('GT', left, right);
		}
		if (ctx instanceof GreaterOrEqualContext) {
			const left = visitTerm(ctx.term(0));
			const right = visitTerm(ctx.term(1));
			return comparison('GE', left, right);
		}
		if (ctx instanceof EqualContext) {
			const left = visitTerm(ctx.term(0));
			const right = visitTerm(ctx.term(1));
			return comparison('EQ', left, right);
		}
		if (ctx instanceof NotEqualContext) {
			const left = visitTerm(ctx.term(0));
			const right = visitTerm(ctx.term(1));
			return comparison('NE', left, right);
		}
		if (ctx instanceof AndContext) {
			const left = visitLogical(ctx.logical(0));
			const right = visitLogical(ctx.logical(1));
			return flatten('and', left, right);
		}
		if (ctx instanceof OrContext) {
			const left = visitLogical(ctx.logical(0));
			const right = visitLogical(ctx.logical(1));
			return flatten('or', left, right);
		}
		if (ctx instanceof InContext) {
			const left = stringValue(ctx.STRING());
			const right = namedToString(ctx.named());
			return comparison(
				'in',
				{
					type: 'literal-string',
					value: left,
				},
				{
					type: 'field',
					name: right,
				},
			);
		}
		throw new Error('unsupported logical?');
	};

	visitTerm = (ctx: TermContext | ParserRuleContext): Reduction => {
		const { visitTerm, visitFactor } = this;
		if (!visitTerm || !visitFactor) {
			// let Flow know what we already know... these methods are defined
			return undefined;
		}
		if (ctx instanceof IdentityTermContext) {
			return visitFactor(ctx.factor());
		}
		if (ctx instanceof PlusContext) {
			const left = visitTerm(ctx.term());
			const right = visitFactor(ctx.factor());
			return flatten('plus', left, right);
		}
		if (ctx instanceof MinusContext) {
			const left = visitTerm(ctx.term());
			const right = visitFactor(ctx.factor());
			return flatten('minus', left, right);
		}
		throw new Error('unsupported term?');
	};

	visitFactor = (ctx: FactorContext | ParserRuleContext): Reduction => {
		const { visitPrimitive, visitFactor } = this;
		if (!visitPrimitive || !visitFactor) {
			// let Flow know what we already know... these methods are defined
			return undefined;
		}

		if (ctx instanceof IdentityFactorContext) {
			return visitPrimitive(ctx.primitive());
		}
		if (ctx instanceof TimesContext) {
			const left = visitFactor(ctx.factor());
			const right = visitPrimitive(ctx.primitive());
			return flatten('times', left, right);
		}
		if (ctx instanceof DivideContext) {
			const left = visitFactor(ctx.factor());
			const right = visitPrimitive(ctx.primitive());
			return flatten('divide', left, right);
		}
		throw new Error('unsupported factor?');
	};

	visitPrimitive = (ctx: PrimitiveContext | ParserRuleContext): Reduction => {
		if (ctx instanceof IntegerContext) {
			return {
				type: 'literal-number',
				value: parseInt(ctx.INTEGER().symbol.text, 10),
			};
		}
		if (ctx instanceof FloatContext) {
			return {
				type: 'literal-number',
				value: parseFloat(ctx.FLOAT().symbol.text),
			};
		}
		if (ctx instanceof StringContext) {
			return {
				type: 'literal-string',
				value: stringValue(ctx.STRING()),
			};
		}
		if (ctx instanceof IdentifierContext) {
			return {
				type: 'field',
				name: ctx.IDENT().symbol.text,
			};
		}
		if (ctx instanceof FieldRefContext) {
			return {
				type: 'field',
				name: fieldName(ctx.FIELD()),
			};
		}
		const { visitSubexpr, visitExprs } = this;
		if (!visitSubexpr || !visitExprs) {
			// visitExpr will be defined, but Flow doesn't know that...
			return undefined;
		}
		if (ctx instanceof CallContext) {
			const args = visitExprs(ctx.exprs());
			if (args?.type === 'sequence') {
				// this should be the case; visitExprs returns a sequence
				return {
					type: 'call',
					func: ctx.IDENT().symbol.text,
					args: args.args,
				};
			}
			return undefined;
		}
		if (ctx instanceof ParenContext) {
			return visitSubexpr(ctx.subexpr());
		}

		throw new Error('unsupported primitive?');
	};

	visitExprs = (ctx: ExprsContext | ParserRuleContext): Reduction => {
		const arglist: ParseNode[] = [];

		if (ctx instanceof SomeExprListContext) {
			const { visitSubexpr } = this;
			if (visitSubexpr) {
				// @ts-expect-error - TS7006 - Parameter 'arg' implicitly has an 'any' type.
				ctx.subexpr().forEach((arg) => {
					const r = visitSubexpr(arg);
					if (r && r.type !== 'sequence') {
						arglist.push(r);
					}
				});
			}
		}

		return {
			type: 'sequence',
			args: arglist,
		};
	};
}

/*
 * this is useful for debugging the lexer, but otherwise not used:

export const scan = (input: string): string[] => {
    const chars = new ANTLRInputStream(input);
    const lexer = new ExprLexer(chars);

    const result: string[] = [];

    while (true) {
        const t = lexer.nextToken();
        if (t.type === ExprLexer.EOF) {
            break;
        }
        result.push(`token[${i}] is: "${t.text}"`);
    }
    return result;
};

*/

export const parse = (input: string): ParseNode => {
	const chars = new ANTLRInputStream(input);
	const lexer = new ExprLexer(chars);
	const tokens = new CommonTokenStream(lexer);
	const parser = new ExprParser(tokens);
	parser.errorHandler = new BailErrorStrategy();
	parser.buildParseTrees = true;
	const tree = parser.expr();
	const visitor: ExprVisitor<Reduction> = new Visitor();
	if (tree instanceof CompleteExprContext) {
		const result = tree.accept(visitor);
		if (!result || result.type === 'sequence') {
			throw new Error('invalid input');
		}
		return result;
	}
	throw new Error('invalid input');
};

import { createSelector } from 'reselect';
import findIndex from 'lodash/findIndex';
import findLast from 'lodash/findLast';
import type { RowId, RowGroupId } from '../../common/types/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../constants.tsx';
import type { State, InlineRowCreationPosition, RowVirtualisationState } from '../types.tsx';
import { getRowConfiguration, getGroupRowConfiguration } from './items.tsx';

export const isSelectionEnabled = (state: State): boolean => state.selectedRows !== undefined;

export const getSelectedRows = (state: State): RowId[] => state.selectedRows ?? [];

export const createIsSelected = (rowId: RowId) =>
	createSelector(getSelectedRows, (rows) => {
		if (!rows.length) {
			return false;
		}

		return rows.includes(rowId);
	});

const getGroupedIds = (state: State) => state.groupedIds;

export const createHasAllRowsInGroupSelected = (rowGroupId: RowGroupId) =>
	createSelector(getSelectedRows, getGroupedIds, (selectedRows, groupedIds) => {
		if (!selectedRows.length || groupedIds === undefined) {
			return false;
		}

		const groupItems =
			rowGroupId === NO_VALUE_GROUP_ID ? groupedIds.empty : groupedIds.groups[rowGroupId];

		if (groupItems !== undefined && groupItems.length > 0) {
			return groupItems.every((id) => selectedRows.includes(id));
		}
		return false;
	});

export const createHasRowsInGroupSelected = (rowGroupId: RowGroupId) =>
	createSelector(getSelectedRows, getGroupedIds, (selectedRows, groupedIds) => {
		if (!selectedRows.length || groupedIds === undefined) {
			return false;
		}

		const groupItems =
			rowGroupId === NO_VALUE_GROUP_ID ? groupedIds.empty : groupedIds.groups[rowGroupId];

		if (groupItems !== undefined && groupItems.length > 0) {
			return groupItems.findIndex((id) => selectedRows.includes(id)) !== -1;
		}
		return false;
	});

export const getHoveredRow = (state: State): RowId | undefined => state.hoveredRow;

export const createIsHovered = (rowId: RowId) =>
	createSelector(getHoveredRow, (hoveredRowId) => hoveredRowId === rowId);

export const hasSelectedRows = (state: State): boolean =>
	state.selectedRows !== undefined && state.selectedRows.length > 0;

export const hasAllRowsSelected = (state: State): boolean =>
	state.selectedRows !== undefined && state.selectedRows.length === state.rows.length;

export const createIsInlineRowActive = (position: InlineRowCreationPosition) => (rowId: RowId) =>
	createSelector(
		(state) => state.activeInlineRowCreation,
		getRowConfiguration,
		(activeInlineRowCreation, rows) => {
			if (activeInlineRowCreation === undefined) {
				return false;
			}

			const activeRowIndex = findIndex(rows, (row) => row.key === activeInlineRowCreation.rowId);
			const hookRowIndex = findIndex(rows, (row) => row.key === rowId);

			if (activeRowIndex === -1 || hookRowIndex === -1) {
				return false;
			}

			if (activeInlineRowCreation.position === 'top' && position === 'top') {
				return true;
			}

			if (position === 'bottom') {
				if (activeInlineRowCreation.position === 'bottom') {
					return hookRowIndex === activeRowIndex;
				}

				if (activeInlineRowCreation.position === 'top') {
					return hookRowIndex === activeRowIndex - 1;
				}
			}
			return false;
		},
	);

export const getRowsRendered = (state: State): RowVirtualisationState => state.rowsRendered;

const getIsGrouped = (state: State): boolean => (state.rowGroups || []).length > 0;

export const getCurrentStickyGroupRow = createSelector(
	getIsGrouped,
	getGroupRowConfiguration,
	getRowsRendered,
	(isGrouped, groupRows, { startIndex }) => {
		if (isGrouped) {
			return findLast(groupRows, ({ index }) => index <= startIndex);
		}
		return undefined;
	},
);

import React, { useMemo, type ReactNode } from 'react';
import uniq from 'lodash/uniq';
import { useIntl } from '@atlassian/jira-intl';
import {
	useHighlightedDecorations,
	useHighlightedDecorationsForList,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/decorations-hooks.tsx';
import { useLocalIssueIdToJiraIssueIdMapper } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIssueAnalitycsAttributes } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useCurrentViewXAxis,
	useCurrentViewYAxis,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { N300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type {
	ItemProps,
	ItemWrapperProps,
	ClusteredItemProps,
} from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';
import {
	DefaultBubbleComponent,
	DefaultBubbleClusterComponent,
} from '@atlassian/jira-polaris-lib-matrix/src/ui/bubble/index.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { BubbleTooltip } from '../tooltip/index.tsx';
import { useItems } from '../utils/items.tsx';
import { messages } from './messages.tsx';

type MatrixBubbleTooltipProps = {
	id: string;
	children: ReactNode;
};

const MatrixBubbleTooltip = ({ id, children }: MatrixBubbleTooltipProps) => {
	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();

	const { items } = useItems(selectedXAxisField, selectedYAxisField);
	const currentItem = items.find((item) => item.id === id);

	return (
		<PolarisTooltip
			position="top"
			delay={100}
			hideTooltipOnClick
			content={
				currentItem !== undefined ? (
					<BubbleTooltip itemIds={[id]} xValue={currentItem.x} yValue={currentItem.y} />
				) : null
			}
		>
			{children}
		</PolarisTooltip>
	);
};

export const MatrixBubble = ({ id, isHovered, isDragging, ...rest }: ItemProps) => {
	const decorations = useHighlightedDecorations(id);
	const highlights: string[] = decorations
		.map(({ highlightContainer, backgroundColor }) =>
			highlightContainer ? backgroundColor : undefined,
		)
		.filter(Boolean);

	const bubbleComponent = (
		<DefaultBubbleComponent
			id={id}
			color={N300}
			borderColor={highlights.length === 0 ? N300 : highlights}
			isHovered={isHovered}
			isDragging={isDragging}
			{...rest}
		/>
	);

	return isHovered && !isDragging ? (
		<MatrixBubbleTooltip id={id}>{bubbleComponent}</MatrixBubbleTooltip>
	) : (
		bubbleComponent
	);
};

type MatrixClusterBubbleTooltipProps = {
	items: string[];
	children: ReactNode;
};

const MatrixClusterBubbleTooltip = ({ items, children }: MatrixClusterBubbleTooltipProps) => {
	const { formatMessage } = useIntl();

	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();

	const { items: matrixItems } = useItems(selectedXAxisField, selectedYAxisField);
	const currentItem = matrixItems.find((item) => item.id === items[0]);

	return (
		<PolarisTooltip
			position="top"
			delay={100}
			hideTooltipOnClick
			content={
				currentItem !== undefined ? (
					<BubbleTooltip
						itemIds={items}
						tooltipTitle={formatMessage(messages.groupedIdeas, { count: items.length })}
						xValue={currentItem.x}
						yValue={currentItem.y}
					/>
				) : null
			}
		>
			{children}
		</PolarisTooltip>
	);
};

export const MatrixClusterBubble = ({
	items,
	isHovered,
	isDragging,
	...rest
}: ClusteredItemProps) => {
	const decorations = useHighlightedDecorationsForList(items);
	const highlights: string[] = uniq(
		decorations
			.map(({ highlightContainer, backgroundColor }) =>
				highlightContainer ? backgroundColor : undefined,
			)
			.filter(Boolean),
	);

	const bubbleComponent = (
		<DefaultBubbleClusterComponent
			items={items}
			color={N300}
			borderColor={highlights.length === 0 ? N300 : highlights}
			isHovered={isHovered}
			isDragging={isDragging}
			{...rest}
		/>
	);

	return isHovered && !isDragging ? (
		<MatrixClusterBubbleTooltip items={items}>{bubbleComponent}</MatrixClusterBubbleTooltip>
	) : (
		bubbleComponent
	);
};

export const MatrixBubbleWrapper = ({ itemIds, children }: ItemWrapperProps) => {
	const localIssueIdToJiraIssueIdMapper = useLocalIssueIdToJiraIssueIdMapper();
	const issueAnalitycsAttributes = useIssueAnalitycsAttributes(itemIds[0]);

	const attributes = useMemo(() => {
		if (itemIds.length > 1) {
			return {
				clusteredIssueIds: itemIds?.map(localIssueIdToJiraIssueIdMapper),
			};
		}

		return issueAnalitycsAttributes;
	}, [issueAnalitycsAttributes, itemIds, localIssueIdToJiraIssueIdMapper]);

	return <ContextualAnalyticsData attributes={attributes}>{children}</ContextualAnalyticsData>;
};

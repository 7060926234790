import type { State } from './types.tsx';

export const initialState: State = {
	jira: {
		issueLinkTypes: [],
		jpdIssueLinkTypes: {
			delivery: undefined,
			merge: undefined,
			dataPoint: undefined,
		},
		epicNameFieldKey: undefined,
		arj: {
			epicLinkCustomFieldId: undefined,
			parentCustomFieldId: undefined,
			storyPointsCustomFieldId: undefined,
			arjHierarchyConfiguration: undefined,
		},
		statusCategories: [],
	},
	features: {
		isBeta: false,
		isEditorAiEnabled: false,
		isSelfServeEnabledOnSite: false,
		canSelfServeBetaSiteCreator: false,
		isEnforcePermissionChecksEnabledOnSite: false,
	},
	meta: {
		errors: {},
	},
};

import React, { createContext, useReducer, useContext, useCallback } from 'react';
import type { Action, ProgressContext, ProgressProviderProps, State } from './types.tsx';

const INITIAL_STATE: State = {
	progress: null,
	error: false,
};

export const ProgressStateContext = createContext<ProgressContext | undefined>(undefined);

const progressReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'UPDATE_PROGRESS':
			return {
				...state,
				progress: action.payload,
			};
		case 'ERROR_PROGRESS':
			return {
				...state,
				error: action.payload,
			};
		default:
			return state;
	}
};

export const ProgressProvider = ({ children }: ProgressProviderProps) => {
	const value = useReducer(progressReducer, INITIAL_STATE);
	return <ProgressStateContext.Provider value={value}>{children}</ProgressStateContext.Provider>;
};

export const useProgressState = () => {
	const context = useContext(ProgressStateContext);
	if (context === undefined) {
		throw new Error('useProgressState must be used within a ProgressProvider');
	}
	return context;
};

export const useProgress = () => {
	const [state, dispatch] = useProgressState();
	const updateProgress = useCallback(
		(progress: number) => dispatch({ type: 'UPDATE_PROGRESS', payload: progress }),
		[dispatch],
	);
	const setError = useCallback(
		() => dispatch({ type: 'ERROR_PROGRESS', payload: true }),
		[dispatch],
	);
	return { progress: state.progress, error: state.error, updateProgress, setError };
};

import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { showingYAxisOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import { useCurrentViewYAxis } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { BaseFieldConfig } from '../base/index.tsx';
import { MatrixAxisFieldOptions } from '../matrix-axis-field-options/index.tsx';
import { messages } from './messages.tsx';

export const ConfigYAxis = () => {
	const { formatMessage } = useIntl();
	const yAxisField = useCurrentViewYAxis();
	const { setMatrixYAxis } = useViewActions();
	const currentField = useField(yAxisField);
	const fields = useFieldsForViewControls();

	const onChange = useCallback(
		(fieldKey: string | undefined) => {
			if (fieldKey === undefined || fieldKey === yAxisField) {
				return;
			}
			setMatrixYAxis(fieldKey);
		},
		[setMatrixYAxis, yAxisField],
	);

	return (
		<BaseFieldConfig
			helpText={formatMessage(messages.axisHelpText)}
			fields={fields}
			selectedField={yAxisField}
			backTo={showingYAxisOptions}
			onChange={onChange}
			dimension="y"
		>
			{currentField && <MatrixAxisFieldOptions field={currentField} dimension="y" />}
		</BaseFieldConfig>
	);
};

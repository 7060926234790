import React from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import { useIssueKey } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { ConnectionItem } from '../connection-item/index.tsx';
import { ExtraItemsSummary } from '../extra-items-summary/index.tsx';
import { MAX_ITEMS_AMOUNT } from './constants.tsx';

type ConnectionsListProps = {
	connectionItems: ConnectionFieldValue[];
	fieldKey: string;
	localIssueId: string;
};

export const ConnectionsList = (props: ConnectionsListProps) => {
	const { connectionItems, fieldKey, localIssueId } = props;
	const issueKey = useIssueKey(localIssueId);

	if (connectionItems.length === 0) {
		return null;
	}

	const itemsOverflowing = connectionItems.length - MAX_ITEMS_AMOUNT;
	const areItemsOverflowing = itemsOverflowing > 0;

	return (
		<Stack xcss={containerStyles}>
			{connectionItems.slice(0, MAX_ITEMS_AMOUNT).map((item, index, items) => (
				<ConnectionItem
					key={item.id}
					issueId={item.id}
					fieldKey={fieldKey}
					isLastChild={index === items.length - 1}
				/>
			))}
			{areItemsOverflowing && (
				<ExtraItemsSummary issueKey={issueKey} restItemsAmount={itemsOverflowing} />
			)}
		</Stack>
	);
};

const containerStyles = xcss({
	borderRadius: 'border.radius',
	border: `1px solid ${token('color.border')}`,
	width: '100%',
	backgroundColor: 'elevation.surface',
	gridColumn: '1/-1',
});

import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types.tsx';

export const setFormulaDataFieldsByKey =
	(fieldsByKey: FieldsByKey): Action<State, Props> =>
	({ getState, setState }) => {
		setState({
			formulaFieldData: {
				...getState().formulaFieldData,
				fieldsByKey,
			},
		});
	};

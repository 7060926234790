import { notImplementedError } from '../../common/utils.tsx';
import {
	fetchCollectionViewMarkers,
	createCollectionViewMarker,
	updateCollectionViewMarker,
	deleteCollectionViewMarker,
	fetchSharingViewMarkers,
} from '../../services/jpd-views-service/markers/index.tsx';
import {
	fetchViewMarkers,
	createViewMarker,
	updateViewMarker,
	deleteViewMarker,
} from '../../services/polaris-api/markers/index.tsx';
import type { ViewMarkersRemote } from './types.tsx';

export const createViewMarkersRemote = (): ViewMarkersRemote => ({
	fetchViewMarkers,
	updateViewMarker,
	createViewMarker,
	deleteViewMarker,
});

export const createNotImplementedViewMarkersRemote = (): ViewMarkersRemote => ({
	fetchViewMarkers: () => Promise.resolve([]),
	updateViewMarker: notImplementedError('updateViewMarker'),
	createViewMarker: notImplementedError('createViewMarker'),
	deleteViewMarker: notImplementedError('deleteViewMarker'),
});

export const createSharingViewMarkersRemote = (): ViewMarkersRemote => ({
	...createNotImplementedViewMarkersRemote(),
	fetchViewMarkers: fetchSharingViewMarkers,
});

export const createCollectionViewMarkersRemote = (collectionUUID: string): ViewMarkersRemote => ({
	fetchViewMarkers: fetchCollectionViewMarkers(collectionUUID),
	createViewMarker: createCollectionViewMarker(collectionUUID),
	updateViewMarker: updateCollectionViewMarker(collectionUUID),
	deleteViewMarker: deleteCollectionViewMarker(collectionUUID),
});

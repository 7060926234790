import React from 'react';
import { Inline } from '@atlaskit/primitives';

import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/issue-type-avatar.tsx';
import {
	useIssueTypeAvatarId,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';

import type { FieldValueComponentProps, FieldValueConfiguration } from '../types.tsx';

const Component = ({ groupIdentity }: FieldValueComponentProps) => {
	const issueTypeName = useIssueTypeName({ issueTypeId: groupIdentity });
	const avatarId = useIssueTypeAvatarId({ issueTypeId: groupIdentity });

	if (avatarId === undefined) {
		return null;
	}

	return (
		<Inline alignBlock="center" space="space.075">
			<IssueTypeAvatar avatarId={avatarId} size="xsmall" />
			{issueTypeName}
		</Inline>
	);
};

export const issueTypeFieldValueConfig: FieldValueConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
};

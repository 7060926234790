import { useCallback, useState } from 'react';
import type { DraggedCard } from '@atlassian/jira-polaris-lib-board/src/types/common.tsx';

export function useDraggedCard() {
	const [draggedCard, setDraggedCard] = useState<DraggedCard | undefined>();

	const handleCardDrop = useCallback(() => {
		setDraggedCard(undefined);
	}, []);

	return {
		draggedCard,
		onCardDrag: setDraggedCard,
		onCardDrop: handleCardDrop,
	};
}

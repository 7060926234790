import { useCallback } from 'react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import {
	useDroppableEvents,
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';

type DroppableHandlerProps = {
	children: React.ReactNode;
	sortFields: SortField[];
	onSortFieldsUpdate: (nextSortFields: SortField[]) => void;
};

const DroppableHandlerLegacy = ({
	children,
	sortFields,
	onSortFieldsUpdate,
}: DroppableHandlerProps) => {
	const handleSort = useCallback(
		({ srcIdx, dstIdx }: { srcIdx: number; dstIdx: number }) => {
			// outside the sortFieldsLocal list
			if (dstIdx < 0 || dstIdx >= sortFields.length + 1) {
				return;
			}

			const newSortFields = [...sortFields];
			const [removed] = newSortFields.splice(srcIdx, 1);
			newSortFields.splice(dstIdx, 0, removed);

			onSortFieldsUpdate(newSortFields);
		},
		[onSortFieldsUpdate, sortFields],
	);

	const onSort = useCallback(
		({ srcId, dstId, edge }: { srcId: string; dstId: string; edge: Edge }) => {
			const srcIdx = sortFields.findIndex(({ fieldKey }) => fieldKey === srcId);
			const dstIdx = sortFields.findIndex(({ fieldKey }) => fieldKey === dstId);

			let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
			dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;

			handleSort({ srcIdx, dstIdx: dstIdx + dstAdj });
		},
		[handleSort, sortFields],
	);

	useDroppableEvents({
		onSort,
	});

	return children;
};

const DroppableHandlerNext = ({
	children,
	sortFields,
	onSortFieldsUpdate,
}: DroppableHandlerProps) => {
	const handleSort: DroppableCollectionProps<SortField, FieldKey>['onSort'] = useCallback(
		({ updatedCollection }) => {
			onSortFieldsUpdate(updatedCollection);
		},
		[onSortFieldsUpdate],
	);

	useDroppableEventsCollectionUpdate({
		onSort: handleSort,
		collection: sortFields,
		getSrcIdForCollectionItem: (item) => item.fieldKey,
	});

	return children;
};

export const DroppableHandler = componentWithFG(
	'polaris_improved_dnd_handling',
	DroppableHandlerNext,
	DroppableHandlerLegacy,
);

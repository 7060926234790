import React, { useCallback, useState } from 'react';
import DropdownMenu, {
	type DropdownMenuProps,
	DropdownItemGroup,
	DropdownItem,
} from '@atlaskit/dropdown-menu';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import DeleteIcon from '@atlaskit/icon/core/migration/delete--trash';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useConnectionFieldIssueViewLayoutSort } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { HeaderTrigger } from '../../../../view-content/idea-list/header/cell/trigger/index.tsx';
import messages from './messages.tsx';

export type HeaderCellRendererProps = {
	cellFieldKey: FieldKey;
	connectionFieldKey: FieldKey;
};

export const HeaderCellRenderer = ({
	cellFieldKey,
	connectionFieldKey,
}: HeaderCellRendererProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const canEditFields = useCanEditFields();
	const issueViewLayoutSort = useConnectionFieldIssueViewLayoutSort(connectionFieldKey);
	const sortField: SortField | undefined =
		issueViewLayoutSort?.fieldKey === cellFieldKey
			? {
					fieldKey: issueViewLayoutSort.fieldKey,
					asc: issueViewLayoutSort.order === 'ASC',
				}
			: undefined;
	const { updateConnectionFieldIssueViewLayout } = useFieldActions();

	const handleOpenChange: NonNullable<DropdownMenuProps['onOpenChange']> = useCallback(
		(attrs) => {
			setIsOpen(attrs.isOpen);
		},
		[setIsOpen],
	);

	if (!canEditFields) {
		return (
			<Inline alignBlock="center" xcss={containerStyles}>
				<HeaderTrigger
					isLabelHidden={false}
					fieldKey={cellFieldKey}
					disabled
					disableTooltip={false}
					hasDescriptionTrigger={false}
					isFullWidth
					sortField={sortField}
				/>
			</Inline>
		);
	}

	return (
		<Inline alignBlock="center" xcss={containerStyles}>
			<DropdownMenu
				isOpen={isOpen}
				onOpenChange={handleOpenChange}
				trigger={({ triggerRef, ...triggerProps }) => (
					<HeaderTrigger
						{...triggerProps}
						ref={triggerRef}
						onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
						isLabelHidden={false}
						fieldKey={cellFieldKey}
						disabled={false}
						disableTooltip={isOpen}
						hasDescriptionTrigger={false}
						isFullWidth
						sortField={sortField}
					/>
				)}
				spacing="compact"
				placement="bottom-end"
				shouldFlip={false}
			>
				<DropdownItemGroup>
					{(!sortField || !sortField.asc) && (
						<DropdownItem
							onClick={() =>
								updateConnectionFieldIssueViewLayout(connectionFieldKey, {
									sort: {
										fieldKey: cellFieldKey,
										order: 'ASC',
									},
								})
							}
							elemBefore={<ArrowUpIcon label="" spacing="spacious" color={token('color.icon')} />}
						>
							{formatMessage(messages.sortAscending)}
						</DropdownItem>
					)}
					{(!sortField || sortField.asc) && (
						<DropdownItem
							onClick={() =>
								updateConnectionFieldIssueViewLayout(connectionFieldKey, {
									sort: {
										fieldKey: cellFieldKey,
										order: 'DESC',
									},
								})
							}
							elemBefore={<ArrowDownIcon label="" spacing="spacious" color={token('color.icon')} />}
						>
							{formatMessage(messages.sortDescending)}
						</DropdownItem>
					)}
					{sortField && (
						<DropdownItem
							onClick={() =>
								updateConnectionFieldIssueViewLayout(connectionFieldKey, { sort: null })
							}
							elemBefore={<DeleteIcon label="" spacing="spacious" color={token('color.icon')} />}
						>
							{formatMessage(messages.clearSort)}
						</DropdownItem>
					)}
				</DropdownItemGroup>

				{cellFieldKey !== SUMMARY_FIELDKEY && (
					<DropdownItemGroup hasSeparator>
						<DropdownItem
							onClick={() => {
								updateConnectionFieldIssueViewLayout(connectionFieldKey, {
									selectedFieldKey: null,
								});
							}}
							elemBefore={
								<Flex
									alignItems="center"
									xcss={isVisualRefreshEnabled() ? crossNewUtilityIconStyles : undefined}
								>
									<CrossIcon label="" LEGACY_fallbackIcon={EditorCloseIcon} />
								</Flex>
							}
						>
							{formatMessage(messages.removeField)}
						</DropdownItem>
					</DropdownItemGroup>
				)}
			</DropdownMenu>
		</Inline>
	);
};

const crossNewUtilityIconStyles = xcss({
	paddingInlineStart: 'space.075',
	paddingInlineEnd: 'space.075',
});

const containerStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.100',
	height: '100%',
});

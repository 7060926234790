import { performPutRequest, performPostRequest } from '@atlassian/jira-fetch/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';

export const updateViewLastViewedForSharing = async (viewId: string): Promise<void> => {
	performPutRequest(`${getJpdViewsServicePathPrefix()}/v0/views/${viewId}/last-viewed`);
};

export const updateViewLastViewedForCollections = async (
	collectionUUID: string,
	viewUUID: string,
	timestamp: string,
): Promise<void> => {
	performPutRequest(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/last-viewed`,
		{
			body: JSON.stringify({ timestamp }),
		},
	);
};

const getUpdateLastViewedUrl = (viewId: ViewUUID) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewId}/last-viewed`;

export const updateViewLastViewed = (viewId: ViewUUID) =>
	performPostRequest(getUpdateLastViewedUrl(viewId));

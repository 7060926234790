import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	feedbackWarning: {
		id: 'polaris-lib-feedback.modal.content.feedback-warning',
		defaultMessage:
			'Visit our {linkStart}community{linkEnd} to see our roadmap, best practices and FAQ.',
		description: 'This warning is displayed above feedback/support form',
	},
	categoryPickerLabel: {
		id: 'polaris-lib-feedback.modal.content.category-picker-label',
		defaultMessage: 'I want to...',
		description: 'Title of the category selection.',
	},
	allowContactingLabel: {
		id: 'polaris-lib-feedback.modal.content.allow-contacting-label',
		defaultMessage: 'Atlassian can contact me about this feedback',
		description: 'Label of the checkbox.',
	},
	helpTextAreaLabel: {
		id: 'polaris-lib-feedback.modal.content.help-text-area-label',
		defaultMessage: 'What can we help with?',
		description: 'Label of the text area, when the first option selected',
	},
	feedbackTextAreaLabel: {
		id: 'polaris-lib-feedback.modal.content.feedback-text-area-label',
		defaultMessage: "Let us know what's on your mind",
		description: 'Label of the text area, when the second option selected',
	},
	betaExtensionTextAreaLabel: {
		id: 'polaris-lib-feedback.modal.content.beta-extension-text-area-label',
		defaultMessage: 'Let us know your reason for requesting an extension',
		description: 'Label of the text area, when the third option selected',
	},
});

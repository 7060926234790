import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { INSIGHT_FRAGMENT_WITH_FULL_SNIPPET } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/insight/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { GetInsightFullQuery, PolarisApiInsight } from './types.tsx';

const GET_FULL_INSIGHT_QUERY = gqlTagPolaris`
  query jira_polaris_GetInsightFull($id: ID!) {
    polarisInsight(id: $id) {
      ...Insight
    }
  }
  ${INSIGHT_FRAGMENT_WITH_FULL_SNIPPET}
`;

export const getInsight = (
	client: PolarisApolloClient,
	cloudId: string,
	insightId: string,
): Promise<PolarisApiInsight | undefined> => {
	const insightAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'polaris-insight',
		resourceId: insightId,
	});

	return client
		.query<GetInsightFullQuery>({
			query: GET_FULL_INSIGHT_QUERY,
			variables: {
				id: insightAri,
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (!result.data || result.data.polarisInsight === undefined) {
				throw new Error('polaris-insight.fetch-error: no data or no data node');
			}

			return result.data.polarisInsight !== null ? result.data.polarisInsight : undefined;
		});
};

import React, { type SyntheticEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import { useIntl } from '@atlassian/jira-intl';
import { useSortingAwareMoveIssuesToTopAction } from '@atlassian/jira-polaris-common/src/controllers/idea-ranking/index.tsx';
import {
	useIsCurrentViewGroupedList,
	useIsManualIdeaRankingPossibleInCurrentView,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useVisibleSelectedIssueIds } from '../../../utils.tsx';
import { messages } from './messages.tsx';

export const SendToTopButton = () => {
	const { formatMessage } = useIntl();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const moveIssuesToTop = useSortingAwareMoveIssuesToTopAction();
	const isManualRankingPossible = useIsManualIdeaRankingPossibleInCurrentView();
	const isGroupedList = useIsCurrentViewGroupedList();

	if (isEmpty(visibleSelectedIssueIds) || isGroupedList) {
		return null;
	}

	return (
		<ButtonWithTooltip
			isDisabled={!isManualRankingPossible}
			onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'moveToTop');
				moveIssuesToTop(visibleSelectedIssueIds);
			}}
			tooltipProps={{
				content: !isManualRankingPossible ? formatMessage(messages.rankingDisabledTooltip) : null,
			}}
			appearance="subtle"
			iconBefore={<ArrowUpIcon label={formatMessage(messages.buttonLabel)} />}
		>
			{formatMessage(messages.buttonLabel)}
		</ButtonWithTooltip>
	);
};

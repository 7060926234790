import React, { type ReactNode } from 'react';
import Portal from '@atlaskit/portal';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { createContainer, createHook, type Action } from '@atlassian/react-sweet-state';

type ListPortalProviderProps = {
	children: ReactNode;
};

type State = {
	portalElement?: HTMLElement;
};

const actions = {
	setPortalElement:
		(portalElement: HTMLElement): Action<State> =>
		({ setState }) => {
			setState({ portalElement });
		},
} as const;

type Actions = typeof actions;

const ListPortalStore = createPolarisStore<State, Actions>({
	initialState: {
		portalElement: undefined,
	},
	actions,
	name: 'PolarisListPortalStore',
});

const usePortalElementHook = createHook(ListPortalStore, {
	selector: (state: State) => state.portalElement,
});

const useActions = createHook(ListPortalStore, {
	selector: null,
});

const ListPortalController = createContainer<State, Actions>(ListPortalStore);

const ListPortalHandler = ({ children }: ListPortalProviderProps) => {
	const [, { setPortalElement }] = useActions();

	return (
		<>
			<Portal zIndex={layers.layer}>
				{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any */}
				<div ref={setPortalElement as any} />
			</Portal>
			{children}
		</>
	);
};

export const ListPortalProvider = ({ children }: ListPortalProviderProps) => (
	<ListPortalController>
		<ListPortalHandler>{children}</ListPortalHandler>
	</ListPortalController>
);

export const useListPortalElement = (): HTMLElement | undefined => {
	const [portalElement] = usePortalElementHook();
	return portalElement;
};

import has from 'lodash/has';
import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { FULL_SNIPPET_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/snippet/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	PolarisInsight,
	PolarisInsightsQuery,
	GetSnippetsDataForIdeaQuery,
	PolarisInsightsWithSnippetData,
} from './types.tsx';

const GET_SNIPPETS_DATA_QUERY = gqlTagPolaris`
query jira_polaris_ReadSnippetsDataForIdea($project: ID!, $container: ID) {
    polarisInsights(project: $project, container: $container) {
        __typename
        id
        description
        snippets {
            __typename
            id
            data
        }
    }
}
`;

const GET_INSIGHTS_QUERY = gqlTagPolaris`
query jira_polaris_ReadInsightsForIdea($project: ID!, $container: ID) {
    polarisInsights(project: $project, container: $container) {
        __typename
        aaid
        id
        created
        updated
        container
        description
        account {
            accountId
            name
            picture
        }
        snippets {
            ...Snippet
        }
        play {
          id
        }
    }
}
${FULL_SNIPPET_FRAGMENT}
`;

export const getInsights = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	jiraIssueId: string,
): Promise<PolarisInsight[]> => {
	const containerAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: String(jiraIssueId),
	});

	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: String(projectId),
	});

	return client
		.query<PolarisInsightsQuery>({
			query: GET_INSIGHTS_QUERY,
			variables: {
				project: projectAri.toString(),
				container: containerAri.toString(),
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (!result.data || !has(result.data, 'polarisInsights')) {
				throw new Error('polaris-insights.fetch-error: no data or no data node');
			}

			return result.data.polarisInsights ? [...result.data.polarisInsights] : [];
		});
};

export const getInsightsWithSnippetData = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	jiraIssueId: string,
): Promise<PolarisInsightsWithSnippetData[]> => {
	const containerAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: String(jiraIssueId),
	});

	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: String(projectId),
	});

	return client
		.query<GetSnippetsDataForIdeaQuery>({
			query: GET_SNIPPETS_DATA_QUERY,
			variables: {
				project: projectAri.toString(),
				container: containerAri.toString(),
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (!result.data || !has(result.data, 'polarisInsights')) {
				throw new Error('polaris-insights-snippets-data.fetch-error: no data or no data node');
			}

			return result.data.polarisInsights ? [...result.data.polarisInsights] : [];
		});
};

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	spotlightTitle: {
		id: 'polaris-lib-onboarding-flows.view-permissions.spotlight.title',
		defaultMessage: 'View restrictions',
		description: 'Title for the view permissions lock spotlight',
	},
	spotlightContent: {
		id: 'polaris-lib-onboarding-flows.view-permissions.spotlight.content',
		defaultMessage: 'Control who can view and edit your project views.',
		description: 'Content for the view permissions lock spotlight',
	},
	spotlightCta: {
		id: 'polaris-lib-onboarding-flows.view-permissions.spotlight.cta',
		defaultMessage: 'Okay',
		description: 'CTA text for the view permissions lock spotlight',
	},
});

import { useMemo } from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useFilteredIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks.tsx';
import { useRankedIssueCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useFieldItemValues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/matrix-hooks.tsx';
import {
	useCurrentViewXAxis,
	useCurrentViewYAxis,
	useCurrentViewZAxis,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Item, MatrixEmptyState } from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';

const NO_WEIGHT_Z_VALUE = 75;

type ItemsReturnValue = {
	items: Item[];
	emptyState: MatrixEmptyState;
};

const filterItemForMatrix = (fieldType?: FieldType, value?: string | number): boolean => {
	if (typeof value === 'number' && fieldType === FIELD_TYPES.RATING) {
		return value > 0;
	}

	if (
		typeof value === 'number' &&
		(fieldType === FIELD_TYPES.NUMBER ||
			fieldType === FIELD_TYPES.SLIDER ||
			fieldType === FIELD_TYPES.INSIGHTS ||
			fieldType === FIELD_TYPES.FORMULA ||
			fieldType === FIELD_TYPES.LINKED_ISSUES)
	) {
		return value >= 0;
	}

	return value !== undefined;
};

const useItemsInternal = (issueIds: LocalIssueId[], x?: FieldKey, y?: FieldKey, z?: FieldKey) => {
	const xFieldType = useFieldType(x);
	const yFieldType = useFieldType(y);

	const xValueGroup = useFieldItemValues(x, issueIds, undefined);
	const yValueGroup = useFieldItemValues(y, issueIds, undefined);
	const zValueGroup = useFieldItemValues(z, issueIds, true);

	return useMemo(() => {
		if (xValueGroup === undefined || yValueGroup === undefined) {
			return [];
		}

		return issueIds
			.map((id) => {
				const xValue = xValueGroup[id];
				const yValue = yValueGroup[id];
				const zValue = z !== undefined ? zValueGroup[id] : NO_WEIGHT_Z_VALUE;
				return {
					id,
					x: xValue,
					y: yValue,
					z: zValue,
				};
			})
			.filter(
				(item) =>
					filterItemForMatrix(xFieldType, item.x) && filterItemForMatrix(yFieldType, item.y),
			);
	}, [xValueGroup, yValueGroup, issueIds, z, zValueGroup, xFieldType, yFieldType]);
};

export const useItems = (x?: FieldKey, y?: FieldKey, z?: FieldKey) => {
	const issueIds = useFilteredIssueIds();
	const totalIssueCount = useRankedIssueCount();
	const items = useItemsInternal(issueIds, x, y, z);

	return useMemo((): ItemsReturnValue => {
		let emptyState: MatrixEmptyState = 'NONE';
		if (items.length === 0 && issueIds.length > 0) {
			emptyState = 'AXIS';
		} else if (totalIssueCount > 0 && issueIds.length === 0) {
			emptyState = 'FILTERS';
		}

		return {
			// @ts-expect-error - Type 'string | number | undefined' is not assignable to type 'AxisValue'.
			items,
			emptyState,
		};
	}, [items, issueIds, totalIssueCount]);
};

export const useMatrixIdeaCount = () => {
	const issueIds = useFilteredIssueIds();
	const x = useCurrentViewXAxis();
	const y = useCurrentViewYAxis();
	const z = useCurrentViewZAxis();
	const items = useItemsInternal(issueIds, x, y, z);

	return items.length;
};

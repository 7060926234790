import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { createViewSet } from '../../services/polaris-api/create-view-set/index.tsx';
import { deleteViewSet } from '../../services/polaris-api/delete-view-set/index.tsx';
import { updateViewSet } from '../../services/polaris-api/update-view-set/index.tsx';
import { fetchAndTransformViewSets } from './fetch/index.tsx';
import type { ViewSetsRemote } from './types.tsx';

export const createPolarisApiViewSetsRemote = (
	apolloClient: PolarisApolloClient,
	projectAri: Ari,
): ViewSetsRemote => ({
	fetchViewSets: () => fetchAndTransformViewSets(apolloClient, projectAri),
	createViewSet: (props) => createViewSet(apolloClient, props),
	updateViewSet: (props) => updateViewSet(apolloClient, props),
	deleteViewSet: (viewSetId) => deleteViewSet(apolloClient, viewSetId),
});

export const createNotImplementedViewSetsRemote = (): ViewSetsRemote => ({
	fetchViewSets: notImplementedError('fetchViewSets'),
	createViewSet: notImplementedError('createViewSet'),
	deleteViewSet: notImplementedError('deleteViewSet'),
	updateViewSet: notImplementedError('updateViewSet'),
});

import React, { type ReactNode, useState, useEffect, useRef } from 'react';
import noop from 'lodash/noop';
import {
	extractClosestEdge,
	type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { createRaf } from '../create-request-animation-frame/index.tsx';
import { DropTarget } from './drop-target/index.tsx';

const isEdge = (dropEdge: unknown): dropEdge is Edge => dropEdge === 'left' || dropEdge === 'right';

export const DraggableColumn = ({
	children,
	isDragDisabled = false,
	isDraggingActive = false,
	id,
	onDndMove,
	onDndStart,
	onDndEnd,
	onDrop,
	onDndGeneratePreview,
}: {
	children?: ReactNode;
	isDragDisabled?: boolean;
	isDraggingActive?: boolean;
	id: string;
	onDndStart?: (offsetLeft: number) => void;
	onDndMove?: (pos: number) => void;
	onDndEnd?: () => void;
	onDrop?: (args: { sourceId: string; targetId: string; dropEdge: Edge }) => void;
	onDndGeneratePreview?: () => void;
}) => {
	const requestAnimationFrame = createRaf();
	const draggableRef = useRef<HTMLDivElement>(null);
	const [dragStatus, setDragStatus] = useState<'idle' | 'preview' | 'dragging'>('idle');

	useEffect(() => {
		const el = draggableRef.current;
		if (isDragDisabled || !el) {
			return noop;
		}

		return combine(
			draggable({
				element: el,
				getInitialData() {
					return { type: 'jpd-draggable-column', id };
				},
				onDrag({ location }) {
					const { current, initial } = location;
					requestAnimationFrame(() => {
						const deltaX = current.input.clientX - initial.input.clientX;
						onDndMove && onDndMove(deltaX);
					});
				},
				onGenerateDragPreview({ nativeSetDragImage }) {
					// Notifies the parent component that the drag is about to start
					onDndGeneratePreview && onDndGeneratePreview();

					setDragStatus('preview');
					disableNativeDragPreview({ nativeSetDragImage });
				},
				onDragStart(args) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const { offsetLeft } = args.source.element.offsetParent as HTMLElement;
					onDndStart && onDndStart(offsetLeft);

					setDragStatus('dragging');
				},
				onDrop({ source, location }) {
					onDndEnd && onDndEnd();

					if (location.current.dropTargets.length === 0) {
						return;
					}

					const sourceId = source.data.id;

					const target = location.current.dropTargets[0];
					const dropEdge = extractClosestEdge(target.data);
					const targetId = target.data.id;

					if (
						!isEdge(dropEdge) ||
						typeof sourceId !== 'string' ||
						typeof targetId !== 'string' ||
						sourceId === targetId
					) {
						return;
					}

					onDrop && onDrop({ sourceId, targetId, dropEdge });

					setDragStatus('idle');
				},
			}),
		);
	}, [
		isDragDisabled,
		id,
		requestAnimationFrame,
		onDndMove,
		onDndStart,
		onDndEnd,
		onDrop,
		onDndGeneratePreview,
	]);

	return (
		<Box
			ref={draggableRef}
			xcss={[!isDragDisabled && dragEnabledCss, dragStatus === 'dragging' && draggingCss]}
		>
			{children}
			<DropTarget columnId={id} isDraggingActive={isDraggingActive} />
		</Box>
	);
};

const draggingCss = xcss({
	background: token('color.background.disabled'),
	color: 'color.text.disabled',
	cursor: 'grabbing',
});

const dragEnabledCss = xcss({
	cursor: 'grab',
});

import gqlTagPolaris from 'graphql-tag';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisComment } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/comment/__generated_apollo__/PolarisComment.ts';
import { COMMENT_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/comment/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_UpdateViewComment as UpdateViewComment,
	jira_polaris_UpdateViewCommentVariables as UpdateViewCommentVariables,
} from './__generated_apollo__/jira_polaris_UpdateViewComment';

const UPDATE_VIEW_COMMENT_MUTATION = gqlTagPolaris`
mutation jira_polaris_UpdateViewComment(
    $commentId: ID!
    $content: JSON!
  ) {
      updatePolarisComment(
          input: {
              id: $commentId
              content: $content
          }
      ) {
          success
          errors {
              message
          }
          node {
              ...PolarisComment
          }
        }
    }

${COMMENT_FRAGMENT}`;

export const updateViewComment = async (
	apolloClient: PolarisApolloClient,
	commentId: Ari,
	update: ADF,
): Promise<PolarisComment> => {
	const result = await apolloClient.mutate<UpdateViewComment, UpdateViewCommentVariables>({
		mutation: UPDATE_VIEW_COMMENT_MUTATION,
		variables: {
			commentId,
			content: update,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`view-comment.update-view-comment-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
	const node = result.data?.updatePolarisComment?.node;
	if (!node) {
		throw new Error('view-comment.update-view-comment-error: no data or no data node');
	}

	return node;
};

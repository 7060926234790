import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, { type PositionType } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type WeightTagProps = {
	options: OptionProperty[];
	tooltipPosition?: PositionType;
};

export const WeightTag = (props: WeightTagProps) => {
	const { options = [], tooltipPosition = 'bottom' } = props;
	const { formatNumber } = useIntl();
	const totalWeight = useMemo(
		() => options.reduce((acc, { weight = 0 }) => acc + weight, 0),
		[options],
	);

	return (
		<Tooltip
			content={totalWeight < 1000 && totalWeight > -1000 ? undefined : formatNumber(totalWeight)}
			position={tooltipPosition}
		>
			<TagContainer>
				<PolarisIcon name={PolarisIconType.Scales} size="small" label="Weighting value" />
				<Tag data-testid="polaris-component-weight-tag.ui.tag">
					{formatNumber(totalWeight, {
						notation: 'compact',
						compactDisplay: 'short',
						maximumFractionDigits: 1,
					})}
				</Tag>
			</TagContainer>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	height: '22px',
	minWidth: '28px',
	gap: token('space.075', '6px'),
	padding: `0 ${token('space.025', '2px')}`,
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.disabled', N20A)}`,
	borderRadius: '3px',
	backgroundColor: token('color.background.neutral', N20A),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Tag = styled.div({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
});

import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { loadErrorEventAction, profileCardLegacyActionSubject } from '../../common/constants.tsx';
import {
	ProfileClientContainer,
	ProfileClientSubscriber,
} from '../../controllers/profileclient/index.tsx';
import type { ProfileCardWrapperProps } from './types.tsx';

const ProfileCardWrapper = (props: ProfileCardWrapperProps) => {
	const {
		TenantContextSubscriber: TenantContextSubscriberComponent,
		externalProfileClient,
		children,
		onLoadError,
	} = props;

	const cloudId = useCloudId();

	const renderProfileClientContainerWrapper = useCallback(
		(tenantContext?: TenantContext) => {
			if (tenantContext && tenantContext.atlassianAccountId) {
				return (
					<ProfileClientContainer
						fabricPfDirUrl={tenantContext.fabricPfDirUrl}
						baseUrl={tenantContext.baseUrl}
						profileClient={externalProfileClient || null}
						onLoadError={onLoadError}
						isGlobal
						cloudId={cloudId}
					>
						<ProfileClientSubscriber>
							{({ profileClient }) =>
								children(
									tenantContext.atlassianAccountId,
									profileClient,
									tenantContext.cloudId,
									tenantContext.baseUrl,
								)
							}
						</ProfileClientSubscriber>
					</ProfileClientContainer>
				);
			}

			// When there is no tenantContext, this will cause the child
			// component of <ProfileCard> to render without being wrapped by
			// a profile card and it will do nothing on hover
			return children('', null, '', '');
		},
		[children, externalProfileClient, onLoadError, cloudId],
	);

	if (TenantContextSubscriberComponent) {
		return (
			<TenantContextSubscriberComponent>
				{({ tenantContext }) => renderProfileClientContainerWrapper(tenantContext)}
			</TenantContextSubscriberComponent>
		);
	}

	// Here we conditionally call hooks which is frowned upon by React.
	// However this is safe as the value of TenantContextSubscriberComponent doesn't change dynamically
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const tenantContext = useTenantContext();

	return renderProfileClientContainerWrapper(tenantContext);
};

ProfileCardWrapper.defaultProps = {
	externalProfileClient: null,
	sourceName: null,
	createAnalyticsEvent: noop,
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default AnalyticsEventToProps(profileCardLegacyActionSubject, {
	onLoadError: loadErrorEventAction,
})(ProfileCardWrapper) as Flow.AbstractComponent<ProfileCardWrapperProps>;

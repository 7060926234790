import type { Action } from '@atlassian/react-sweet-state';
import type { ProjectFieldsTourSteps, State } from '../../../types.tsx';

export const setProjectFieldsTourSpotlightStep =
	(step: ProjectFieldsTourSteps | null): Action<State> =>
	({ getState, setState }) => {
		const state = getState();

		setState({
			...state,
			projectFields: {
				...state.projectFields,
				tourSpotlightStep: step,
			},
		});
	};

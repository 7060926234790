import React from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldKeys } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIsIssuesLoading } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useFieldKeyToAriMapAsync } from '@atlassian/jira-polaris-component-field-ari/src/controllers/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import messages from './messages.tsx';

type FieldsThatRequireAllIdeasWarningProps = {
	hasFormulaFields: boolean;
	hasLabelFields: boolean;
};

export const FieldsThatRequireAllIdeasWarning = (props: FieldsThatRequireAllIdeasWarningProps) => {
	const { hasFormulaFields, hasLabelFields } = props;
	const isIssuesLoading = useIsIssuesLoading();
	const { loadIssues, initialize } = useIssueActions();
	const [fieldKeys] = useFieldKeys();
	const fieldKeyToAriMap = useFieldKeyToAriMapAsync({ fieldKeys });
	const { formatMessage } = useIntl();
	const [canEditIssues] = useCanEditIssues();

	if ((!hasFormulaFields && !hasLabelFields) || (!hasFormulaFields && !canEditIssues)) {
		return null;
	}

	return (
		<Warning>
			<InfoIcon size="small" label="info" />
			<span>
				<WarningContent>
					{formatMessage(
						hasFormulaFields
							? messages.hiddenFormulaFieldsWarning
							: messages.nonEditableLabelFieldsWarning,
					)}
				</WarningContent>
				{isIssuesLoading ? (
					<Spinner size="small" delay={0} />
				) : (
					<LoadDataLink
						role="button"
						onClick={() => loadIssues().then(() => initialize(fieldKeyToAriMap))}
					>
						{formatMessage(messages.loadData)}
					</LoadDataLink>
				)}
			</span>
		</Warning>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Warning = styled.div({
	display: 'flex',
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	borderRadius: '8px',
	padding: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadDataLink = styled.a({
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningContent = styled.span({
	paddingRight: token('space.050', '4px'),
});

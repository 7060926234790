import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import {
	useTeamField,
	type UseTeamField,
} from '@atlassian/jira-issue-field-team/src/services/index.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const extractId = (teamId: string): string | undefined => teamId.split('/').pop();

// TODO: POL-12642 - initialValue should be a TeamValue, not a string
export const createUseTeamFieldOverride =
	({
		issueKey,
		fieldKey,
		canEditIssues,
		initialValue,
		updateFieldValue,
	}: {
		issueKey: string;
		fieldKey: string;
		canEditIssues: boolean;
		initialValue?: string;
		updateFieldValue: (value: string | undefined, jpdExperience?: JPDExperience) => void;
	}): UseTeamField =>
	(_args) => {
		const [{ value, error, fieldConfig }, { resetError }] = useTeamField({
			issueKey: toIssueKey(issueKey),
			fieldKey,
		});

		const valueOverride: TeamValue = {
			...value,
			// TODO: POL-12642 - pass initialValue to useTeamField when it's of type TeamValue
			id: initialValue ?? value?.id ?? '',
			name: initialValue ?? value?.name ?? '',
		};

		const fieldConfigOverride: FieldConfiguration<TeamValue> | null = !fieldConfig
			? null
			: {
					...fieldConfig,
					isEditable: canEditIssues,
				};

		return [
			{ value: valueOverride, error, fieldConfig: fieldConfigOverride },
			{
				saveValue: async (team) => {
					updateFieldValue(team?.id ? extractId(team.id) : undefined);
				},
				resetError,
			},
		];
	};

import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_CreatePolarisPlay as CreatePlay,
	jira_polaris_CreatePolarisPlayVariables as CreatePlayVariables,
} from './__generated_apollo__/jira_polaris_CreatePolarisPlay';
import type { CreatePlayFieldReturn } from './types.tsx';

const CREATE_PLAY_MUTATION = gqlTagPolaris`
  mutation jira_polaris_CreatePolarisPlay($input: CreatePolarisPlayInput!) {
    createPolarisPlay(input: $input) {
      success
      node {
        id
      }
    }
  }
`;

export const createPlay = async (
	apolloClient: PolarisApolloClient,
	input: CreatePlayVariables['input'],
): Promise<CreatePlayFieldReturn> => {
	const result = await apolloClient.mutate<CreatePlay, CreatePlayVariables>({
		mutation: CREATE_PLAY_MUTATION,
		variables: {
			input,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.create-play-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.createPolarisPlay?.node) {
		throw new Error('project-config.create-play-error: no data or no data node');
	}

	return {
		node: result.data.createPolarisPlay.node,
	};
};

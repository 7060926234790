import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { salesforceIcon } from '../../../../common/ui/apps/index.tsx';
import { UnfurlDialog } from '../../unfurl-dialog/index.tsx';
import type { AppPreset } from '../types.tsx';
import messages from './messages.tsx';

const UnfurlAction = () => {
	const { formatMessage } = useIntl();
	return (
		<UnfurlDialog
			title={formatMessage(messages.unfurlDialogTitle)}
			placeholder={formatMessage(messages.unfurlDialogPlaceholder)}
			successMessage={formatMessage(messages.unfurlDialogSuccess)}
		/>
	);
};

export const useSalesforcePreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: 'salesforce',
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: salesforceIcon,
			isRecommended: false,
			Action: UnfurlAction,
		}),
		[formatMessage],
	);
};

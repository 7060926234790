import React, { type ReactElement, type ReactNode } from 'react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

export const FixedFieldDescription = ({
	fieldKey,
	fallbackDescription,
}: {
	fieldKey: FieldKey;
	fallbackDescription?: ReactElement;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={fixedDescriptionStyles}>
			{messages[fieldKey]
				? formatMessage(messages[fieldKey], {
						br: <br />,
						statusLink: (text: ReactNode) => (
							<Button
								target="_blank"
								appearance="link"
								spacing="none"
								href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Pro-tip-reordering-statuses-in-the-status-field-dropdown/ba-p/2422657"
							>
								{text}
							</Button>
						),
					})
				: fallbackDescription ?? ''}
		</Box>
	);
};

const fixedDescriptionStyles = xcss({
	color: 'color.text',
});

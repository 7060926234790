import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expandButtonLabel: {
		id: 'polaris-ideas.idea-view.idea-view.expand-button-label',
		defaultMessage: 'Expand',
		description: 'Default title that gets shared when there is no issue summary.',
	},
	collapseButtonLabel: {
		id: 'polaris-ideas.idea-view.idea-view.collapse-button-label',
		defaultMessage: 'Collapse',
		description: 'Button label for the follow button',
	},
	closeButtonLabel: {
		id: 'polaris-ideas.idea-view.idea-view.close-button-label',
		defaultMessage: 'Close',
		description: 'Label on the icon button closing the sidebar with the idea view',
	},
});

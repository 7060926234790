import React, { Component } from 'react';
import urlParse from 'url-parse';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';

type Props = {
	src: string;
};

type State = {
	resizing: boolean;
};

type Event = {
	data: {
		fn: string;
	};
	origin: string;
};

/** The problem with Iframe is that we don't get a callback from them that indicates when they're ready.
 *  But the Iframe can resize itself through post messages which gives a very rough estimate of when it will be ready.
 *  So the solution is to listen to this resize message and set a timeout for when an app doesn't send resize.
 *  Note that the resizing will always occur before the app itself is ready so the loading bar always finishes early
 * */

// eslint-disable-next-line jira/react/no-class-components
export default class IframeSpaStatePageReady extends Component<Props, State> {
	state = { resizing: false };

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('message', this.onResizing);
		// @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
		this.timeoutId = setTimeout(this.onEstimatedIframeReady, 500);
	}

	shouldComponentUpdate() {
		return !this.state.resizing;
	}

	componentWillUnmount() {
		clearTimeout(this.timeoutId);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('message', this.onResizing);
	}

	// @ts-expect-error - TS2564 - Property 'timeoutId' has no initializer and is not definitely assigned in the constructor.
	timeoutId: number;

	onResizing = (ev: Event) => {
		if (
			ev.data.fn === 'resize' &&
			urlParse(ev.origin).hostname === urlParse(this.props.src).hostname
		) {
			this.onEstimatedIframeReady();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('message', this.onResizing);
			clearTimeout(this.timeoutId);
		}
	};

	onEstimatedIframeReady = () => {
		this.setState({ resizing: true });
	};

	render() {
		const { resizing } = this.state;
		return resizing ? <SpaStatePageReady /> : null;
	}
}

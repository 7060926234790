import type { Action } from '@atlassian/react-sweet-state';
import { getRemoteState } from '../../selectors/remote-state.tsx';
import type { Props, State } from '../../types.tsx';
import { saveRemoteState } from '../remote-state/index.tsx';

export const clickHelpVideoButton =
	(): Action<State, Props> =>
	({ getState, dispatch }) => {
		const remoteState = getRemoteState(getState());
		if (!remoteState) {
			return;
		}

		dispatch(
			saveRemoteState({
				...remoteState,
				isVideoButtonInHelpPanelClicked: true,
			}),
		);
	};

import React, { type ChangeEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import TextField from '@atlaskit/textfield';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { CheckboxNumberField } from '../../../../fields/number/checkbox/index.tsx';
import { RatingNumberField } from '../../../../fields/number/rating/index.tsx';
import { SliderField } from '../../../../fields/slider/index.tsx';
import type { FieldComponentProps } from '../../types.tsx';

type NumberFieldProps = FieldComponentProps & {
	value: number | undefined;
	decorations: ValueDecoration[] | undefined;
	fieldType: FieldType | undefined;
};

export const NumberField = ({
	decorations,
	fieldType,
	onUpdate,
	value,
	...props
}: NumberFieldProps) => {
	const onChangeHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			const valueForUpdate =
				newValue === '' || newValue === null || newValue === undefined
					? undefined
					: Number(newValue);
			onUpdate(valueForUpdate);
		},
		[onUpdate],
	);

	if (fieldType === FIELD_TYPES.CHECKBOX) {
		return <CheckboxNumberField value={value} onUpdate={onUpdate} isEditable={props.isEditable} />;
	}

	if (fieldType === FIELD_TYPES.RATING) {
		return (
			<RatingNumberField
				isEditable={props.isEditable}
				value={value}
				decorations={decorations}
				onUpdate={onUpdate}
			/>
		);
	}

	if (fieldType === FIELD_TYPES.SLIDER) {
		return (
			<SliderFieldContainer>
				<SliderField value={value} onChange={onUpdate} />
			</SliderFieldContainer>
		);
	}

	return (
		<TextField {...props} value={value} onChange={onChangeHandler} width={350} type="Number" />
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SliderFieldContainer = styled.div({
	width: '350px',
});

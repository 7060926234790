import uuid from 'uuid/v4';

type FieldsMappingUrl = {
	url: string;
	traceId: string;
};

export const getFieldsMappingUrl = (
	projectIdOrKey: number | string,
	appendTraceId = false,
): FieldsMappingUrl => {
	const requestId = uuid();
	const postfix = appendTraceId ? `?requestId=${requestId}` : '';

	return {
		url: `/gateway/api/polaris/rest/projects/${projectIdOrKey}/field-id-mapping${postfix}`,
		traceId: requestId,
	};
};

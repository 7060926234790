import React, { type SyntheticEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueStore } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { createGetIssuesForMerge } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids.tsx';
import { useMergeIdeasDialogActions } from '@atlassian/jira-polaris-common/src/controllers/merge-ideas-dialog/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useSelectedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useVisibleSelectedIssueIds } from '../../../utils.tsx';
import { messages } from './messages.tsx';

export const MergeIdeasButton = () => {
	const { formatMessage } = useIntl();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { clearSelection } = useViewActions();
	const selectedIssueIds = useSelectedIssueIds();
	const [issueStoreState] = useIssueStore();
	const { toggleMergeIdeasDialog } = useMergeIdeasDialogActions();

	if (isEmpty(visibleSelectedIssueIds)) {
		return null;
	}

	return (
		<Button
			id="polaris-ideas.ui.view-controls.merge-button"
			testId="polaris-ideas.ui.view-controls.manage-bar.merge-ideas.merge-ideas"
			onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'mergeIdeas');
				const issuesForMerge = createGetIssuesForMerge(visibleSelectedIssueIds)(
					issueStoreState,
					issueStoreState.containerProps,
				);
				toggleMergeIdeasDialog(issuesForMerge, () => clearSelection(Object.keys(selectedIssueIds)));
			}}
			appearance="subtle"
			iconBefore={
				<PolarisIcon
					label={formatMessage(messages.buttonLabel)}
					name={PolarisIconType.MergeIdeas}
				/>
			}
			{...(fg('jpd-trace-ufo-transition') ? { interactionName: 'jpd.merge-ideas-dialog' } : {})}
		>
			{formatMessage(messages.buttonLabel)}
		</Button>
	);
};

import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useUserByAccountId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { messages } from './messages.tsx';

type Props = {
	accountId: string;
	fieldKey: FieldKey;
	rotate: boolean;
};

export const UserFieldValue = ({ fieldKey, accountId, rotate }: Props) => {
	const { formatMessage } = useIntl();

	const userInfo = useUserByAccountId(fieldKey, accountId);
	const avatarUrl = userInfo && userInfo.avatarUrls ? userInfo.avatarUrls['24x24'] : '';
	const userName = userInfo && userInfo.displayName !== undefined ? userInfo.displayName : '';
	return (
		<Tooltip content={userName || formatMessage(messages.unassignedUser)}>
			<AvatarWrapper shouldRotate={rotate}>
				<Avatar src={avatarUrl} size="small" />
			</AvatarWrapper>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div<{ shouldRotate: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ shouldRotate }) => shouldRotate && { transform: 'rotate(90deg)' },
);

import type { RowDraggingInfo } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { createHook, createActionsHook, type StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from './types.tsx';

const initialState: RowDraggingInfo = Object.freeze({
	rowId: undefined,
	dropTarget: undefined,
	dropEdge: null,
});

const actions = {
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
	setRowDraggingInfo:
		(info: RowDraggingInfo) =>
		({ setState }: StoreActionApi<State>) => {
			setState(info);
		},
	resetDropHints:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState(initialState);
		},
} as const;

type Actions = typeof actions;

export const DndStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisDndStore',
});

export const useDnd = createHook(DndStore);

export const useDndActions = createActionsHook(DndStore);

import React, { type SyntheticEvent, useCallback, useEffect } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import { openAuthorizeWindow } from './utils.tsx';

type Props = {
	url: string;
	authorizeUrl: string;
	triggerOnMount: boolean;
	onSuccess: () => void;
	onFailure: (error: Error) => void;
};

export const AuthorizeAppLinksButton = ({
	url,
	authorizeUrl,
	onSuccess,
	onFailure,
	triggerOnMount,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleClick = useCallback(
		(evt: SyntheticEvent | undefined) => {
			evt?.preventDefault();
			if (!authorizeUrl || url === null) {
				return;
			}
			const handleSuccess = () => {
				onSuccess();
			};
			const handleFailure = (error: Error) => {
				onFailure(error);
			};
			openAuthorizeWindow(authorizeUrl, url, handleSuccess, handleFailure);
		},
		[authorizeUrl, onFailure, onSuccess, url],
	);

	useEffect(() => {
		if (triggerOnMount) {
			handleClick(undefined);
		}
	}, [handleClick, triggerOnMount]);

	return (
		<Button
			testId="polaris-common.controllers.unfurl.utils.authentication.applinks.button"
			onClick={handleClick}
		>
			{formatMessage(messages.authorizeButton)}
		</Button>
	);
};

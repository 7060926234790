import type { MentionDescription } from '@atlaskit/mention';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { ProjectMentionProvider } from '@atlassian/jira-mention-provider/src/services/project-mention-provider/index.tsx';
import type { AccountId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export class ProjectMentionProviderWithHighlight extends ProjectMentionProvider {
	constructor(
		projectKey: ProjectKey,
		loggedInAccountId: AccountId | null,
		projectType?: ProjectType | null,
	) {
		super('', projectKey, projectType);
		this.loggedInAccountId = loggedInAccountId;
	}

	loggedInAccountId: string | null;

	shouldHighlightMention = (mention: MentionDescription) => mention.id === this.loggedInAccountId;
}

const EXTERNAL_REFERENCE_PROVIDER_ATLAS_GOAL = 'atlas_goal';
const EXTERNAL_REFERENCE_PROVIDER_ATLAS_PROJECT = 'atlas_project';

type ExternalReferenceProvidersMap = {
	ATLAS_GOAL: typeof EXTERNAL_REFERENCE_PROVIDER_ATLAS_GOAL;
	ATLAS_PROJECT: typeof EXTERNAL_REFERENCE_PROVIDER_ATLAS_PROJECT;
};

export const EXTERNAL_REFERENCE_PROVIDERS: ExternalReferenceProvidersMap = {
	ATLAS_GOAL: EXTERNAL_REFERENCE_PROVIDER_ATLAS_GOAL,
	ATLAS_PROJECT: EXTERNAL_REFERENCE_PROVIDER_ATLAS_PROJECT,
};

export type ExternalReferenceProvider =
	ExternalReferenceProvidersMap[keyof ExternalReferenceProvidersMap];

import { type Ari, createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { ProjectId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const toProjectAri = (projectId: ProjectId, cloudId: CloudId): Ari =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

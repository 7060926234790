import React, { useCallback, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import Portal from '@atlaskit/portal';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import UserPicker, { type OptionData } from '@atlaskit/user-picker';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useIssuesRemote,
	useIssueWatchersMap,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import messages from './messages.tsx';

type UserPickerOption = OptionData & { user: User };

export const AddWatchers = () => {
	const { formatMessage } = useIntl();
	const { watchIssue } = useIdeaActions();
	const watchersMap = useIssueWatchersMap();
	const issueKey = useSelectedIssueKey();
	const [showUserPicker, setShowUserPicker] = useState<boolean>();
	const issuesRemote = useIssuesRemote();

	/**
	 * Because the PopUp component renders the select list
	 * inside it, it forces scrollbars within it,
	 * we will render the select in a portal above the PopUp
	 */
	const menuPortalTargetRef = useRef<HTMLDivElement>(null);

	const onNoOptionsMessage = useCallback(
		({ inputValue }: { inputValue: string }) =>
			inputValue ? formatMessage(messages.noUsersFound) : null,
		[formatMessage],
	);

	const handleChange = useCallback(
		({ user }: UserPickerOption) => {
			const { accountId, displayName, avatarUrls } = user;
			watchIssue({
				accountId,
				displayName,
				avatarUrls,
			});
		},
		[watchIssue],
	);

	const loadOptions = useCallback(
		async (query?: string): Promise<UserPickerOption[]> => {
			if (!query?.trim() || !issuesRemote) {
				return [];
			}

			const response = await issuesRemote.fetchAssignableWatchers({
				issueKey,
				query,
				maxResults: 10,
			});

			return response
				.filter((user) => !watchersMap.has(user.accountId))
				.map((user) => ({
					id: user.accountId,
					name: user.displayName,
					avatarUrl: user.avatarUrls['48x48'],
					user,
				}));
		},
		[issueKey, issuesRemote, watchersMap],
	);

	return (
		<Container>
			{showUserPicker ? (
				<UserPickerContainer>
					<UserPicker
						autoFocus
						subtle
						width={220}
						appearance="compact"
						fieldId="watchers"
						placeholder={formatMessage(messages.inputPlaceholder)}
						loadOptions={loadOptions}
						value={null}
						// @ts-expect-error Type '({ user }: OptionData & { user: User; }) => void' is not assignable to type 'OnChange'.
						onChange={handleChange}
						menuPortalTarget={menuPortalTargetRef.current ?? undefined}
						noOptionsMessage={onNoOptionsMessage}
					/>
				</UserPickerContainer>
			) : (
				<Button
					onClick={() => setShowUserPicker(true)}
					shouldFitContainer
					appearance="subtle"
					iconBefore={<AddIcon label="" size="small" />}
				>
					{formatMessage(messages.link)}
				</Button>
			)}

			<Portal zIndex={layers.modal + 10}>
				<div ref={menuPortalTargetRef} />
			</Portal>
		</Container>
	);
};

export default AddWatchers;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30)}`,
	padding: `${token('space.100', '8px')} 0 ${token('space.050', '4px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UserPickerContainer = styled.div({
	width: 220,
	margin: 'auto',
	padding: `0 ${token('space.100', '8px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .fabric-user-picker__placeholder': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginLeft: 30,
	},
});

import { createHook } from '../index.tsx';
import {
	getCrossProjectViewTourDismissed,
	getTourSpotlightStep,
	getCrossProjectListViewFieldsDismissed,
	getCrossProjectTimelineViewFieldsDismissed,
	getCrossProjectBoardViewFieldsDismissed,
	getCrossProjectMatrixViewFieldsDismissed,
} from './cross-project-view.tsx';

export const useCrossProjectViewTourDismissed = createHook(getCrossProjectViewTourDismissed);
export const useTourSpotlightStep = createHook(getTourSpotlightStep);

export const useCrossProjectListViewFieldsDismissed = createHook(
	getCrossProjectListViewFieldsDismissed,
);
export const useCrossProjectTimelineViewFieldsDismissed = createHook(
	getCrossProjectTimelineViewFieldsDismissed,
);
export const useCrossProjectBoardViewFieldsDismissed = createHook(
	getCrossProjectBoardViewFieldsDismissed,
);
export const useCrossProjectMatrixViewFieldsDismissed = createHook(
	getCrossProjectMatrixViewFieldsDismissed,
);

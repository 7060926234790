import React, { forwardRef } from 'react';
import type { BoardApi } from './types/api.tsx';
import { MODE_GROUPING, MODE_VERTICAL_GROUPING, type BoardProps } from './types/ui.tsx';
import { BoardWithGroupsWrapper } from './ui/board-with-groups/index.tsx';
import { BoardWithoutGroupsWrapper } from './ui/board-without-groups/index.tsx';

export const Board = forwardRef<BoardApi | undefined, BoardProps>((props, ref) => {
	switch (props.mode) {
		case MODE_VERTICAL_GROUPING:
			return <BoardWithGroupsWrapper {...props} ref={ref} />;
		case MODE_GROUPING:
			return <BoardWithoutGroupsWrapper {...props} />;
		default:
			throw new Error('Wrong `mode` prop used for Board component.');
	}
});

import React, { useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { InsightRealtimeEvent } from '@atlassian/jira-polaris-common/src/common/types/realtime/types.tsx';
import { PolarisRealtime } from '@atlassian/jira-polaris-common/src/controllers/realtime/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { RefreshInsightArguments } from '../actions/refresh-insights.tsx';
import { useInsightsActions } from '../main.tsx';

type Props = {
	issueId: IssueId | undefined;
};

export const EVENT_DEBOUNCE_WAIT_MS = 2000;

const useDebouncedRefresh = () => {
	const { refreshInsight } = useInsightsActions();

	return useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const debounceCache: Record<string, any> = {};

		return ({ insightId, insight, lastExternalUpdate }: RefreshInsightArguments) => {
			if (!has(debounceCache, insightId)) {
				debounceCache[insightId] = debounce(
					(updatedTime) =>
						refreshInsight({
							insightId,
							insight,
							lastExternalUpdate: updatedTime,
						}),
					EVENT_DEBOUNCE_WAIT_MS,
				);
			}

			return debounceCache[insightId](lastExternalUpdate);
		};
	}, [refreshInsight]);
};

const getInsightAri = (cloudId: string, insightId: number): string =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'polaris-insight',
		resourceId: String(insightId),
	});

const config = {
	bundleEvents: {},
	batchSizes: {},
};

const isEventRelatedToCurrentIssue = (event: InsightRealtimeEvent, issueId?: IssueId) =>
	String(event.payload.issueId) === String(issueId);

export const PolarisInsightsRealtimeHandler = ({ issueId }: Props) => {
	const { deleteInsight } = useInsightsActions();
	const refresh = useDebouncedRefresh();
	const cloudId = useCloudId();

	const refreshInsight = useCallback(
		(event: InsightRealtimeEvent) => {
			if (isEventRelatedToCurrentIssue(event, issueId)) {
				const { insightId, insight, updatedTime } = event.payload;
				refresh({
					insight,
					insightId: String(insightId),
					lastExternalUpdate: updatedTime,
				});
			}
		},
		[issueId, refresh],
	);

	const deleteInsightAri = useCallback(
		(event: InsightRealtimeEvent) => {
			if (isEventRelatedToCurrentIssue(event, issueId)) {
				deleteInsight(getInsightAri(cloudId, event.payload.insightId), {
					performSideEffects: false,
				});
			}
		},
		[cloudId, deleteInsight, issueId],
	);

	return (
		<PolarisRealtime
			appId="insights"
			config={config}
			containerType="ISSUE"
			containerId={issueId}
			onInsightCreated={refreshInsight}
			onInsightUpdated={refreshInsight}
			onInsightDeleted={deleteInsightAri}
		/>
	);
};

import { createSelector } from 'reselect';
import {
	getAppliedDecoration,
	getAppliedDecorations,
} from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { State } from '../types.tsx';

export const getDecorations = (state: State): ValueDecoration[] =>
	state.fieldData?.valueDecorations || [];

export const getDecorationForValue = (value?: string) =>
	createSelector(getDecorations, (decorations) => getAppliedDecoration(decorations, value));

export const getDecorationForValues = (values: string[]) =>
	createSelector(getDecorations, (decorations) => getAppliedDecorations(decorations, values));

export const getDecorationsComponents = (state: State) => state.decorationsComponents;

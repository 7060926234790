import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	canEditLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.can-edit-label',
		defaultMessage: 'Can edit',
		description: 'Option label for specifying viewer level permissions',
	},
	canViewLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.can-view-label',
		defaultMessage: 'Can view',
		description: 'Option label for specifying editor level permissions',
	},
	removeAccessLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.remove-access-label',
		defaultMessage: 'Remove',
		description: 'Menu button label for removing access for a specific profile or group',
	},
	youLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.you-label',
		defaultMessage: 'you',
		description:
			'"You" label that\'s added after the users name to indicate that this is the currently logged in user, eg John Doe (you)',
	},
	downgradeEditorTooltip: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.downgrade-editor-tooltip',
		defaultMessage: 'At least one user needs to be able to edit this view.',
		description:
			'Tooltip message that appears when you hover over disabled actions when this principal is the only one with the editor role',
	},
	errorTooltipMissingProjectAccess: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.error-tooltip-missing-project-access',
		defaultMessage:
			'This user cannot be granted access to this view, because they either do not have a Jira Product Discovery license or are not added to this project. Site admins can grant licenses, project admins can add users to the project and make them creators.',
		description:
			'Tooltip shown over an error icon describing what was the issue when setting a user role',
	},
	errorTooltipMissingManageViewsPermission: {
		id: 'polaris-component-view-access.access-screen.access-user-list.access-list-item.error-tooltip-missing-manage-views-permission',
		defaultMessage:
			'This user’s role is missing the "Manage views" permission and can’t be granted "Can edit" access to this view.',
		description:
			'Tooltip shown over an error icon describing what was the issue when setting a user role',
	},
});

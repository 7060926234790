export const MODE_GROUPING_ONLY_COLUMNS_FAKE_GROUP_NAME = 'MOCKED_GROUP';

export const EMPTY_VALUE_ID = 'empty';
export const VALUE_COLUMN_ID_PREFIX = '+';
export const COLUMN_DROPPABLE_ID = 'board';
export const ROW_DROPPABLE_ID = 'row';

export const COLUMN_CLASS_NAME = 'view-board-column';

export const BOARD_COLUMN_WIDTH = 348;
export const BOARD_COLUMN_PADDING = 10;
export const BOARD_COLUMN_GAP = 8;
export const BOARD_COLUMN_BORDER_WIDTH = 2;
export const BOARD_CARD_GAP = 8;

export const BOARD_COLUMN_MIN_HEIGHT = 100;
export const BOARD_COLUMN_TITLE_HEIGHT = 50;
export const BOARD_COLUMN_CONTENT_TOP_PADDING = 5;
export const BOARD_CARD_HORIZONTAL_MARGIN = 1;

export const FOOTER_HEIGHT = 32;

const GROUP_HEADER_INNER_HEIGHT = 40;
const GROUP_HEADER_VERTICAL_PADDING = 10;
export const GROUP_HEADER_HEIGHT = GROUP_HEADER_INNER_HEIGHT + GROUP_HEADER_VERTICAL_PADDING * 2;

export const SWIMLANE_HEADER_ID = 'polaris-ideas_ui_view-content_swimlane-header';
export const HEADER_HEIGHT = 36;

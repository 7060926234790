import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	backToViews: {
		id: 'polaris-ideas.sidebar.settings.back-to-views',
		defaultMessage: 'Back to views',
		description: 'Label for link back to JPD views away from the project settings pages',
	},
	details: {
		id: 'polaris-ideas.sidebar.settings.details',
		defaultMessage: 'Details',
		description: 'Project settings details link',
	},
	access: {
		id: 'polaris-ideas.sidebar.settings.access',
		defaultMessage: 'Access',
		description: 'Project settings access link',
	},
	types: {
		id: 'polaris-ideas.sidebar.settings.types',
		defaultMessage: 'Types',
		description: 'Project settings types link',
	},
	fields: {
		id: 'polaris-ideas.sidebar.settings.fields',
		defaultMessage: 'Fields',
		description: 'Project settings fields link',
	},
	notifications: {
		id: 'polaris-ideas.sidebar.settings.notifications',
		defaultMessage: 'Notifications',
		description: 'Project settings notifications link',
	},
	ideaCreation: {
		id: 'polaris-ideas.sidebar.settings.idea-creation',
		defaultMessage: 'Create ideas',
		description: 'Project settings idea creation link',
	},
	workflow: {
		id: 'polaris-ideas.sidebar.settings.workflow',
		defaultMessage: 'Workflow',
		description: 'Project settings workflow link',
	},
	automation: {
		id: 'polaris-ideas.sidebar.settings.automation',
		defaultMessage: 'Automation',
		description: 'Project settings automation link',
	},
	atlasIntegration: {
		id: 'polaris-ideas.sidebar.settings.atlas-integration',
		defaultMessage: 'Atlas integration',
		description: 'Project settings atlas integration link',
	},
	features: {
		id: 'polaris-ideas.sidebar.settings.features',
		defaultMessage: 'Features',
		description: 'Project settings features section',
	},
});

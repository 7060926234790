import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useExternalReferenceValue } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { ExternalReferencePropertyField as InnerField } from '../../../fields/external-reference-property/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type ExternalReferenceFieldProps = {
	issueId: LocalIssueId;
	fieldKey: string;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
};

export const ExternalReferencePropertyField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty,
}: ExternalReferenceFieldProps) => {
	const externalReferencesValue = useExternalReferenceValue(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);

	if (
		hideEmpty &&
		(externalReferencesValue === undefined ||
			(Array.isArray(externalReferencesValue) && externalReferencesValue.length === 0))
	) {
		return null;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>
				<FieldValueWrapper noMargin={isCompact || isSummary}>
					{externalReferencesValue !== undefined ? (
						<InnerField localIssueId={issueId} fieldKey={fieldKey} />
					) : (
						isSummary && <EmptyField />
					)}
				</FieldValueWrapper>
			</FieldValue>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldValueWrapper = styled.div<{ noMargin?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ noMargin }) => (noMargin ? 0 : token('space.050', '4px')),
});

import { createHigherLevelHook, createHook } from '../index.tsx';
import {
	getDecorationForValue,
	getDecorationForValues,
	getDecorations,
	getDecorationsComponents,
} from './decoration.tsx';

export const useDecorationForValue = createHigherLevelHook(getDecorationForValue);
export const useDecorationForValues = createHigherLevelHook(getDecorationForValues);
export const useDecorations = createHook(getDecorations);
export const useDecorationsComponents = createHook(getDecorationsComponents);

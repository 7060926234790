import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	explanation: {
		id: 'polaris-common.refresh-errors-dialog.content.explanation',
		defaultMessage:
			'We can’t refresh app data in some of your ideas. The output of your formula might need to be updated. Try authenticating again:',
		description: 'Explanation of what the refresh error dialog helps resolve',
	},
	allResolved: {
		id: 'polaris-common.refresh-errors-dialog.content.all-resolved',
		defaultMessage: 'All app data in your ideas has refreshed successfully.',
		description: 'Success message when all refresh errors have been resolved.',
	},
	editorSuccessIcon: {
		id: 'polaris-common.refresh-errors-dialog.content.editor-success-icon',
		defaultMessage: 'Success',
		description: 'Editor success icon description',
	},
});

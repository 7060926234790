import React, { useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { ResizeHandleProps } from './types.tsx';

export const ResizeHandle = ({ onResize, onResizeStop }: ResizeHandleProps) => {
	const requestAnimationFrameId = useRef<number | null>(null);
	const startX = useRef(0);
	const styleElement = useRef<HTMLStyleElement | null>(null);

	useEffect(
		() => () => {
			if (requestAnimationFrameId.current) {
				cancelAnimationFrame(requestAnimationFrameId.current);
			}
		},
		[],
	);

	const onPointerMove = useCallback(
		(e: PointerEvent) => {
			if (requestAnimationFrameId.current) {
				cancelAnimationFrame(requestAnimationFrameId.current);
			}

			requestAnimationFrameId.current = requestAnimationFrame(() => {
				const deltaX = startX.current - e.pageX;
				onResize(deltaX);
			});
		},
		[onResize],
	);

	const onPointerUp = useCallback(
		(e: PointerEvent) => {
			if (requestAnimationFrameId.current) {
				cancelAnimationFrame(requestAnimationFrameId.current);
			}

			const deltaX = startX.current - e.pageX;
			onResize(deltaX);
			onResizeStop(deltaX);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('pointermove', onPointerMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('pointerup', onPointerUp);
			const stylesheet = styleElement.current?.sheet;

			if (stylesheet) {
				stylesheet.deleteRule(1);
				stylesheet.deleteRule(0);
			}
		},
		[onResizeStop, onPointerMove, onResize],
	);

	const onPointerDown = useCallback(
		(e: PointerEvent) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointermove', onPointerMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointerup', onPointerUp);

			const stylesheet = styleElement.current?.sheet;
			if (stylesheet) {
				// Disable hover events during drag
				stylesheet.insertRule('#jira-frontend { pointer-events: none; }', 0);
				// Set the dragging cursor (higher than disabled pointer events) and disable text selection during drag
				stylesheet.insertRule('#jira { cursor: ew-resize; user-select: none; }', 1);
			}

			startX.current = e.pageX;
		},
		[onPointerMove, onPointerUp],
	);

	return (
		<>
			<ResizeHandleContainer
				role="separator"
				aria-orientation="vertical"
				// @ts-expect-error Type '(e: PointerEvent) => void' is not
				// assignable to type 'PointerEventHandler<HTMLDivElement>'.
				// Types of parameters 'e' and 'event' are incompatible. Type
				// 'PointerEvent<HTMLDivElement>' is missing the following
				// properties from type 'PointerEvent': getCoalescedEvents,
				// getPredictedEvents, offsetX, offsetY, and 16 more.ts(2322)
				onPointerDown={onPointerDown}
			/>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style ref={styleElement} />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleContainer = styled.div({
	width: '10px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'stretch',
	cursor: 'ew-resize',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: token('space.negative.050', '-4px'),
	zIndex: 6,
	'&::after': {
		content: '',
		width: '2px',
		transition: 'background-color 0.5s',
	},
	'&:hover, &:active': {
		'&::after': {
			backgroundColor: token('color.background.selected.hovered', B200),
		},
	},
});

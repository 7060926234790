import React, { useCallback, useRef } from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { Label } from '@atlaskit/form';
import { Stack } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const DATE_FORMAT = 'D/M/YYYY'; // 14/7/2024

const START_DATE_ID = 'interval-filter-absolute-dates-start-1hA6h7x';
const END_DATE_ID = 'interval-filter-absolute-dates-end-nt97HJl';

export type IntervalFilterContentAbsoluteDatesValue = {
	startDate?: string;
	endDate?: string;
};

export type IntervalFilterContentAbsoluteDatesProps = {
	value: IntervalFilterContentAbsoluteDatesValue;
	onChange: (value: IntervalFilterContentAbsoluteDatesValue) => void;
};

export const IntervalFilterContentAbsoluteDates = ({
	value,
	onChange,
}: IntervalFilterContentAbsoluteDatesProps) => {
	const { formatMessage } = useIntl();

	const { startDate, endDate } = value;
	const stateRef = useRef({
		startDate,
		endDate,
	});

	stateRef.current.startDate = startDate;
	stateRef.current.endDate = endDate;

	const handleChange = useCallback(() => {
		const changeSet = { ...stateRef.current };
		onChange(changeSet);
	}, [onChange]);

	const handleStartDateChange = useCallback(
		(nextStartDate: string) => {
			stateRef.current.startDate = nextStartDate;
			handleChange();
		},
		[handleChange],
	);

	const handleEndDateChange = useCallback(
		(nextEndDate: string) => {
			stateRef.current.endDate = nextEndDate;
			handleChange();
		},
		[handleChange],
	);

	return (
		<Stack space="space.150">
			<Stack space="space.100">
				<Label htmlFor={START_DATE_ID}>{formatMessage(messages.startDate)}</Label>
				<DatePicker
					id={START_DATE_ID}
					weekStartDay={1}
					placeholder={formatMessage(messages.bigBang)}
					value={startDate}
					onChange={handleStartDateChange}
					maxDate={endDate}
					dateFormat={DATE_FORMAT}
				/>
			</Stack>
			<Stack space="space.100">
				<Label htmlFor={END_DATE_ID}>{formatMessage(messages.endDate)}</Label>
				<DatePicker
					id={END_DATE_ID}
					weekStartDay={1}
					placeholder={formatMessage(messages.forever)}
					value={endDate}
					onChange={handleEndDateChange}
					minDate={startDate}
					dateFormat={DATE_FORMAT}
				/>
			</Stack>
		</Stack>
	);
};

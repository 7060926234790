import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noDescription: {
		id: 'polaris-common.field-tooltip.no-description',
		defaultMessage: 'No description',
		description: 'Show no description message in tooltip when fields have no description',
	},
	summaryCardFieldEnableNonFinal: {
		id: 'polaris-common.field-tooltip.summary-card-field-enable-non-final',
		defaultMessage: 'This field will show up on the idea card in the summary layout',
		description:
			'Label for button indicating that the field will show up on the idea card in the summary layout',
	},
	summaryCardFieldDisableNonFinal: {
		id: 'polaris-common.field-tooltip.summary-card-field-disable-non-final',
		defaultMessage: 'This field will be hidden from idea card in the summary layout',
		description:
			'Label for button indicating that the field will be hidden from the idea card in the summary layout',
	},
	deliveryProgressDescription: {
		id: 'polaris-common.field-tooltip.delivery-progress-description',
		defaultMessage:
			'An estimation of the progress of delivery tickets from Jira (epics, issues) that are linked to the idea. For more details open each idea and the "Delivery" section',
		description: 'Delivery progress field description',
	},
	deliveryProgressDescriptionIssueTermRefresh: {
		id: 'polaris-common.field-tooltip.delivery-progress-description-issue-term-refresh',
		defaultMessage:
			'An estimation of delivery work items from Jira such as epics that are linked to the idea. For more details open each idea and the “Delivery” section',
		description: 'Delivery progress field description',
	},
	deliveryStatusDescription: {
		id: 'polaris-common.field-tooltip.delivery-status-description',
		defaultMessage:
			'The status of delivery tickets from Jira (epics, issues) that are linked to the idea. For more details open each idea and the "Delivery" section',
		description: 'Delivery status field description',
	},
	deliveryStatusDescriptionIssueTermRefresh: {
		id: 'polaris-common.field-tooltip.delivery-status-description-issue-term-refresh',
		defaultMessage:
			'The status of delivery work items from Jira (epics, issues) that are linked to the idea. For more details open each idea and the “Delivery” section',
		description: 'Delivery status field description',
	},
});

import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { getStatusesForProject } from '../../services/jira/statuses/index.tsx';
import { jiraStatusesMock } from '../../services/jira/statuses/mocks.tsx';
import {
	getTransitionsForIssueType,
	transitionIssue,
} from '../../services/jira/transitions/index.tsx';
import { fetchSharingViewStatuses } from '../../services/views-service/statuses/index.tsx';
import type { IssueTypeStatusesResponse, IssueWorkflowRemote } from './types.tsx';

export const createNotImplementedIssueWorkflowRemote = (): IssueWorkflowRemote => ({
	transitionIssue: notImplementedError('transitionIssue'),
	fetchTransitionsForIssueType: notImplementedError('fetchTransitionsForIssueType'),
	fetchStatuses: notImplementedError('fetchStatuses'),
});

export const createIssueWorkflowRemote = (projectId: ProjectId): IssueWorkflowRemote => ({
	transitionIssue: ({ issueKey, transition }) => transitionIssue(issueKey, transition),
	fetchTransitionsForIssueType: ({ issueTypeId }) =>
		getTransitionsForIssueType(projectId, issueTypeId),
	fetchStatuses: () => getStatusesForProject(projectId),
});

export const createSharingIssueWorkflowRemote = (viewId: string): IssueWorkflowRemote => ({
	transitionIssue: notImplementedError('transitionIssue'),
	fetchTransitionsForIssueType: notImplementedError('fetchTransitionsForIssueType'),
	fetchStatuses: () => fetchSharingViewStatuses(viewId),
});

export const createJqlIssueWorkflowRemote = (): IssueWorkflowRemote => ({
	...createNotImplementedIssueWorkflowRemote(),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	fetchStatuses: () => Promise.resolve(jiraStatusesMock) as Promise<IssueTypeStatusesResponse>,
});

import React from 'react';
import { useTimelineItemIds } from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline/index.tsx';
import { useTimelineViewVisibleFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ExportViewDialog } from '@atlassian/jira-polaris-component-view-export/src/ui/export-view-dialog/index.tsx';
import type { ViewExportDialogProps } from '../types.tsx';

export const TimelineViewExportDialog = (props: ViewExportDialogProps) => {
	const visibleFields = useTimelineViewVisibleFields();
	const issueIds = useTimelineItemIds();

	return <ExportViewDialog {...props} issueIds={issueIds} visibleFields={visibleFields} />;
};

import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueTypeCreateSection } from './issue-type-create-section.tsx';
import { IssueTypeSections } from './issue-type-sections.tsx';
import { ProjectIdProvider } from './project-id-provider.tsx';

type Props = {
	projectId: ProjectId;
};

export const TypesAccordion = ({ projectId }: Props) => {
	return (
		<ProjectIdProvider projectId={projectId}>
			<Stack as="ul" xcss={noMarginNoPaddingStyles}>
				<IssueTypeSections />
				<IssueTypeCreateSection projectId={projectId} />
			</Stack>
		</ProjectIdProvider>
	);
};

const noMarginNoPaddingStyles = xcss({
	margin: 'space.0',
	padding: 'space.0',
});

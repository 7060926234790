import { useMemo } from 'react';
import { useValidTransitionStatusTargetIds } from '@atlassian/jira-polaris-common/src/controllers/issue/utils/transition-utils.tsx';
import { useCanModifyReporter } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	getGroupIdentityForDroppableId,
	type ExtendedOption,
} from '../../../../common/utils/board.tsx';

const getLocalIssueIdFromDraggableId = (
	sourceDroppableId?: string,
	draggableId?: string,
): LocalIssueId | undefined => {
	if (sourceDroppableId === undefined || draggableId === undefined) {
		return undefined;
	}
	return draggableId.substring(`${sourceDroppableId}-`.length);
};

export type UseValidTargetsProps = {
	field: Field;
	extendedOptions: ExtendedOption<unknown>[];
	draggedCard:
		| {
				droppableId: string;
				draggableId: string;
		  }
		| undefined;
};

/**
 * Helper hook to determine on which columns to disable the drop target.
 * used to disallow ranking within same column when sorting is engaged.
 */
const useValidTargets = (props: UseValidTargetsProps): string[] => {
	const { field, extendedOptions, draggedCard } = props;
	const localIssueId = getLocalIssueIdFromDraggableId(
		draggedCard?.droppableId,
		draggedCard?.draggableId,
	);
	const validStatusTransitions = useValidTransitionStatusTargetIds(localIssueId);

	if (field.type === FIELD_TYPES.STATUS) {
		// allow all valid target statuses + source column (for reordering without an actual status change)
		const sourceGroupIdentity = getGroupIdentityForDroppableId(draggedCard?.droppableId);
		if (sourceGroupIdentity === undefined) {
			return validStatusTransitions;
		}
		return [...validStatusTransitions, sourceGroupIdentity];
	}
	// no restrictions
	const identities: Array<string> = [];
	extendedOptions.forEach(({ groupIdentity }) => {
		if (groupIdentity !== undefined) {
			identities.push(groupIdentity);
		}
	});
	return identities;
};

export const useCardDropDisabled = (props: UseValidTargetsProps) => {
	const { field, extendedOptions, draggedCard } = props;
	const validTargets = useValidTargets({ field, extendedOptions, draggedCard });

	return useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const retVal: Record<string, any> = {};
		extendedOptions.forEach((option) => {
			const isValidTarget = validTargets.includes(option.groupIdentity ?? '');

			if (option.groupIdentity !== undefined) {
				retVal[option.groupIdentity] = !isValidTarget;
			}
		});
		return retVal;
	}, [extendedOptions, validTargets]);
};

export const useIsMoveBetweenGroupsDisabled = (groupByField: Field) => {
	const [hasModifyReporterPermission] = useCanModifyReporter();

	return (
		groupByField.type === FIELD_TYPES.CREATOR ||
		(groupByField.type === FIELD_TYPES.REPORTER && !hasModifyReporterPermission)
	);
};

/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { makeAvatarUrlFromId } from '@atlassian/jira-polaris-component-issue-types/src/ui/utils/make-avatar-url-from-id.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	Filter,
	IntervalFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	isArrayIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterLegacy,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsCollectionView } from '../../../controllers/environment/index.tsx';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import {
	useIssueIdField,
	useSortedDistinctIssueStatuses,
} from '../../../controllers/issue/selectors/fields-hooks.tsx';
import { useSortedGroupOptions } from '../../../controllers/issue/selectors/grouping-hooks.tsx';
import { useIssuesWithBasicProperties } from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import { useViewActions } from '../../../controllers/views/main.tsx';
import {
	useFieldFilter,
	useHasSharedViewNoValueFilter,
	useIntervalFilter,
	useNumericFilter,
} from '../../../controllers/views/selectors/filters-hooks.tsx';
import { useSortedStatusesAsList } from '../../../controllers/workflow/selectors/statuses-hooks.tsx';
import { AlignedShortLabelContext } from '../../issue-short-label/index.tsx';
import { CheckboxFilterComponent } from './checkbox/index.tsx';
import { DeliveryFilterComponent } from './delivery-tickets/index.tsx';
import { IntervalFilterComponent } from './interval/index.tsx';
import { messages } from './messages.tsx';
import { NumDataPointsFilterComponent } from './num-data-points/index.tsx';
import { NumericFilterComponent } from './numeric/index.tsx';
import { VariableOptionRenderer, useOptionsWithLabels } from './options/index.tsx';
import { BasicIssueOptionRenderer } from './options/renderers/basic-issue/index.tsx';
import { SelectFilterComponent } from './select/index.tsx';

type FilterOption = {
	groupIdentity: string | undefined;
	value?: unknown;
};

const useFilterOptions = (field: Field): FilterOption[] => {
	const fieldOptions = useSortedGroupOptions(field.key);
	const hasSharedViewNoValueFilter = useHasSharedViewNoValueFilter(field);
	const statusesFromProject = useSortedStatusesAsList(field);
	const distinctIssueStatusesSorted = useSortedDistinctIssueStatuses();
	const isCollectionView = useIsCollectionView();

	return useMemo(() => {
		if (field.type === FIELD_TYPES.STATUS) {
			const statuses = isCollectionView ? distinctIssueStatusesSorted : statusesFromProject;
			return statuses.map((status) => ({
				groupIdentity: status.id,
				value: status,
			}));
		}

		const nullableFieldOptions = (fieldOptions.options || []).map(
			({ groupIdentity, value }): FilterOption => ({
				groupIdentity,
				value,
			}),
		);

		if (!hasSharedViewNoValueFilter) {
			return nullableFieldOptions;
		}

		return [{ groupIdentity: undefined }, ...nullableFieldOptions];
	}, [
		field.type,
		fieldOptions.options,
		hasSharedViewNoValueFilter,
		isCollectionView,
		distinctIssueStatusesSorted,
		statusesFromProject,
	]);
};

type PolarisIdeaIssueIdFilterProps = {
	isLastFilter: boolean;
	field: Field;
	isDisabled?: boolean;
	isTemporary?: boolean;
	isUsingCustomColor?: boolean;
};

const PolarisIdeaIssueIdFilter = ({
	isLastFilter,
	field,
	isDisabled,
	isTemporary,
	isUsingCustomColor,
}: PolarisIdeaIssueIdFilterProps) => {
	const allIssues = useIssuesWithBasicProperties();
	const filter = useFieldFilter(field.key, { isTemporary });
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { updateFieldFilter } = useViewActions();
	const { formatMessage } = useIntl();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	const componentOptions = useMemo(
		() =>
			allIssues
				.filter((issue) => !issue.isArchived)
				.map((issue) => ({
					label: `${issue.key} ${issue.summary}`,
					previewLabel: issue.key,
					id: String(issue.id),
					OptionRenderComponent: () => <BasicIssueOptionRenderer basicIssue={issue} />,
				})),
		[allIssues],
	);

	return (
		<div
			css={[
				!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
				fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
			]}
		>
			<AlignedShortLabelContext>
				<SelectFilterComponent
					label={formatMessage(messages.excludeIdeas)}
					fieldTypeIcon={fieldTypeIcon}
					emoji={emoji}
					selected={
						filter.type === 'FIELD' ? filter.values.map(({ stringValue }) => stringValue) : []
					}
					isLastFilter={isLastFilter}
					onChange={(values) => {
						fireUIAnalytics(
							createAnalyticsEvent({}),
							'excludedIssuesFilter updated',
							'view-controls',
							{
								fieldKey: field.key,
								fieldType: field.type,
							},
						);
						experience.headerView.viewFilter.start();
						updateFieldFilter(
							{
								...filter,
								values: values.map((stringValue) => ({ stringValue })),
								isTemporary,
							},
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					}}
					options={componentOptions}
					isDisabled={isDisabled}
					isUsingCustomColor={isUsingCustomColor}
				/>
			</AlignedShortLabelContext>
		</div>
	);
};

type MultiOptionComponentProps = {
	isLastFilter: boolean;
	field: Field;
	filter: Filter;
	setFieldFilter: (arg1: (string | undefined)[]) => void;
	options: {
		groupIdentity: string | undefined;
		value?: unknown;
	}[];
	defaultOpen?: boolean;
	isDisabled?: boolean;
	isUsingCustomColor?: boolean;
};

const MultiOptionComponent = ({
	isLastFilter,
	defaultOpen,
	field,
	filter,
	setFieldFilter,
	options,
	isDisabled,
	isUsingCustomColor,
}: MultiOptionComponentProps) => {
	const optionsWithLabels = useOptionsWithLabels(field, options);

	const componentOptions = optionsWithLabels.map((option) => ({
		label: option.label,
		id: option.groupIdentity,
		OptionRenderComponent: () => (
			<VariableOptionRenderer
				field={field}
				groupIdentity={option.groupIdentity}
				value={option.value}
			/>
		),
	}));

	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<SelectFilterComponent
			label={field.label}
			fieldType={field.type}
			fieldTypeIcon={fieldTypeIcon}
			emoji={emoji}
			selected={filter.type === 'FIELD' ? filter.values.map(({ stringValue }) => stringValue) : []}
			onChange={setFieldFilter}
			options={componentOptions}
			isLastFilter={isLastFilter}
			defaultOpen={defaultOpen}
			isDisabled={isDisabled}
			isUsingCustomColor={isUsingCustomColor}
		/>
	);
};

type IssueTypeFilterProps = {
	field: Field;
	isLastFilter: boolean;
	defaultOpen?: boolean;
	isTemporary?: boolean;
	isDisabled?: boolean;
};

const IssueTypeFilter = ({
	field,
	isLastFilter,
	defaultOpen,
	isTemporary,
	isDisabled,
}: IssueTypeFilterProps) => {
	const { updateFieldFilter, clearFieldFilter } = useViewActions();

	const fieldFilter = useFieldFilter(field.key, { isTemporary });
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container?.projectId : '';
	const projectIssueTypes = useIssueTypesForProject({ projectId });

	const filterOptions = projectIssueTypes.map(({ avatarId, ...value }) => ({
		value: { ...value, iconUrl: makeAvatarUrlFromId(avatarId, 'small') },
		groupIdentity: value.id,
	}));

	return (
		<div
			css={[
				!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
				fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
			]}
		>
			<MultiOptionComponent
				field={field}
				filter={fieldFilter}
				isLastFilter={isLastFilter}
				defaultOpen={defaultOpen}
				setFieldFilter={(values) => {
					fireUIAnalytics(createAnalyticsEvent({}), 'issueTypeFilter updated', 'view-controls', {
						fieldKey: field.key,
						fieldType: field.type,
					});

					if (values.length) {
						experience.headerView.viewFilter.start();
						updateFieldFilter(
							{
								...fieldFilter,
								values: values.map((stringValue) => ({ stringValue })),
								isTemporary,
							},
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					} else {
						experience.headerView.viewFilter.start();
						clearFieldFilter(
							fieldFilter.field,
							isTemporary,
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					}
				}}
				options={filterOptions}
				isDisabled={isDisabled}
			/>
		</div>
	);
};

type FilterComponentProps = {
	field: Field;
	isLastFilter: boolean;
	defaultOpen?: boolean;
	isTemporary?: boolean;
	isDisabled?: boolean;
	isUsingCustomColor?: boolean;
};

export const FilterComponent = ({
	field,
	isLastFilter,
	defaultOpen,
	isTemporary,
	isDisabled = false,
	isUsingCustomColor = false,
}: FilterComponentProps) => {
	const filterOptions = useFilterOptions(field);
	const fieldFilter = useFieldFilter(field.key, { isTemporary });
	const numericFilter = useNumericFilter(field.key, { isTemporary });
	const intervalFilter = useIntervalFilter(field.key, { isTemporary });

	const {
		updateFieldFilter,
		updateNumericFilter,
		updateIntervalFilter,
		updateFilters,
		clearFieldFilter,
	} = useViewActions();
	const idField = useIssueIdField();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	switch (field.type) {
		case FIELD_TYPES.INSIGHTS:
			return (
				<div
					css={[
						!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
						fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
					]}
				>
					<NumDataPointsFilterComponent
						label={field.label}
						values={numericFilter.values}
						intervalValues={
							isArrayIntervalFieldFilterLegacyValue(intervalFilter.values)
								? intervalFilter.values
								: []
						}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'numericFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateFilters(
								[
									{
										...numericFilter,
										values,
										isTemporary,
									},
									{
										...intervalFilter,
										values: [],
										isTemporary,
									},
								],
								isTemporary,
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						onChangeIntervalValue={(values) => {
							if (!isIntervalFieldFilterLegacy(intervalFilter)) {
								throw new Error('Expected IntervalFieldFilterLegacy');
							}

							fireUIAnalytics(createAnalyticsEvent({}), 'intervalFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();

							updateFilters(
								[
									{
										...numericFilter,
										values: [],
										isTemporary,
									},
									{
										...intervalFilter,
										values,
										isTemporary,
									},
								],
								isTemporary,
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isUsingCustomColor={isUsingCustomColor}
					/>
				</div>
			);
		case FIELD_TYPES.CHECKBOX:
			return (
				<div
					css={[
						!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
						fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
					]}
				>
					<CheckboxFilterComponent
						label={field.label}
						values={numericFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'checkboxFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateNumericFilter(
								{
									...numericFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isUsingCustomColor={isUsingCustomColor}
					/>
				</div>
			);
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return (
				<div
					css={[
						!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
						fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
					]}
				>
					<NumericFilterComponent
						label={field.label}
						values={numericFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'numericFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateNumericFilter(
								{
									...numericFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isUsingCustomColor={isUsingCustomColor}
					/>
				</div>
			);
		case FIELD_TYPES.ISSUE_TYPE:
			// You can safely remove this whole if statement during FG cleanup
			if (!fg('jpd_issues_relationships')) {
				// Keep in mind that any of the above cases can make us land in this line
				// so we exclude the FG-guarded case and make it fall back to the return
				// outside switch block.
				if (field.type === FIELD_TYPES.ISSUE_TYPE) {
					break;
				}
			}
			return (
				<IssueTypeFilter
					field={field}
					isLastFilter={isLastFilter}
					defaultOpen={defaultOpen}
					isDisabled={isDisabled}
					isTemporary={isTemporary}
				/>
			);
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
		case FIELD_TYPES.STATUS:
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
		case FIELD_TYPES.REACTIONS:
		case FIELD_TYPES.PROJECT:
		case FIELD_TYPES.CONNECTION:
			return (
				<div
					css={[
						!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
						fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
					]}
				>
					<MultiOptionComponent
						field={field}
						filter={fieldFilter}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						setFieldFilter={(values) => {
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'multiOptionFilter updated',
								'view-controls',
								{
									fieldKey: field.key,
									fieldType: field.type,
								},
							);

							if (values.length) {
								experience.headerView.viewFilter.start();
								updateFieldFilter(
									{
										...fieldFilter,
										values: values.map((stringValue) => ({ stringValue })),
										isTemporary,
									},
									() => {
										experience.headerView.viewFilter.success();
									},
									(error?: Error) => {
										experience.headerView.viewFilter.failure(error);
									},
								);
							} else {
								experience.headerView.viewFilter.start();
								clearFieldFilter(
									fieldFilter.field,
									isTemporary,
									() => {
										experience.headerView.viewFilter.success();
									},
									(error?: Error) => {
										experience.headerView.viewFilter.failure(error);
									},
								);
							}
						}}
						options={filterOptions}
						isDisabled={isDisabled}
						isUsingCustomColor={isUsingCustomColor}
					/>
				</div>
			);
		case FIELD_TYPES.ISSUE_ID:
			return idField ? (
				<PolarisIdeaIssueIdFilter
					field={idField}
					isLastFilter={isLastFilter}
					isDisabled={isDisabled}
					isTemporary={isTemporary}
					isUsingCustomColor={isUsingCustomColor}
				/>
			) : null;
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.INTERVAL:
			return (
				<div
					css={[
						!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
						fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
					]}
				>
					<IntervalFilterComponent
						label={field.label}
						values={intervalFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						defaultOpen={defaultOpen}
						isLastFilter={isLastFilter}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'intervalFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();

							updateIntervalFilter(
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								{
									...intervalFilter,
									values,
									isTemporary,
								} as IntervalFieldFilter,
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isUsingCustomColor={isUsingCustomColor}
					/>
				</div>
			);
		case FIELD_TYPES.DELIVERY_PROGRESS:
		case FIELD_TYPES.DELIVERY_STATUS:
			return (
				<div
					css={[
						!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
						fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
					]}
				>
					<DeliveryFilterComponent
						label={field.label}
						values={numericFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						defaultOpen={defaultOpen}
						isLastFilter={isLastFilter}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'deliveryFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateNumericFilter(
								{
									...numericFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isUsingCustomColor={isUsingCustomColor}
					/>
				</div>
			);
		default:
			break;
	}

	// We do not return null in the default switch case to make
	// putting extra cases behind a feature gate simpler, eg.
	// case X:
	//   if (fieldType === X) {
	//     if (!fg(...)) {
	//       break; // to exit the switch, therfore fall back to the default return
	//     }
	//   }
	return null;
};

const filterComponentWrapperStyles = css({
	flexGrow: 1,
	margin: `${token('space.050')} 0`,
	minWidth: '0',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		width: '100%',
		textAlign: 'left',
	},
});

const filterComponentWrapperStylesNext = css({
	flexGrow: 1,
	margin: `${token('space.050')} 0`,
	minWidth: '0',
	maxWidth: '100%',
});

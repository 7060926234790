import { useMemo, useState, useEffect } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSortedDistinctIssueStatuses } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useActiveFiltersGroupValues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { useSortedStatusesAsList } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks.tsx';
import { canDeleteOption } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/utils/fields.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { evaluateNumericFilterValues } from '@atlassian/jira-polaris-domain-view/src/filter/index.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { makeAvatarUrlFromId } from '@atlassian/jira-polaris-component-issue-types/src/ui/utils/make-avatar-url-from-id.tsx';
import {
	EMPTY_VALUE_ID,
	VALUE_COLUMN_ID_PREFIX,
	type ExtendedOption,
} from '../../../../common/utils/board.tsx';

/**
 * This calculates the options used for columns. We look at the issue store for possible values but also hold
 * a component store to not "forget" values that no issue holds anymore due to drag operations.
 */
const useOptions = (fieldKey?: FieldKey) => {
	const { options } = useSortedGroupOptions(fieldKey);
	const fieldType = useFieldType(fieldKey);

	const [groupOptions, setGroupOptions] = useState(options);

	useEffect(() => {
		const newOptions = [...options];
		if (!isEqual(groupOptions, newOptions)) {
			setGroupOptions(newOptions);
		}
	}, [groupOptions, options]);

	const optionsByGroup = useMemo(
		() => keyBy(options, ({ groupIdentity }) => groupIdentity),
		[options],
	);

	const [optionsByGroupIdentity, setOptionsByGroupIdentity] = useState(optionsByGroup);

	useEffect(() => {
		const newOptions = { ...optionsByGroup };
		if (!isEqual(optionsByGroupIdentity, newOptions)) {
			setOptionsByGroupIdentity(newOptions);
		}
	}, [optionsByGroupIdentity, optionsByGroup]);

	const extendedOptions = useMemo(() => {
		if (fieldKey !== undefined) {
			return {
				[fieldKey]: options,
			};
		}
		return undefined;
	}, [fieldKey, options]);

	const [stateOptions, setExtendedOptions] = useState(extendedOptions);

	useEffect(() => {
		if (
			fieldKey !== undefined &&
			stateOptions &&
			(!stateOptions[fieldKey] || stateOptions[fieldKey].length < options.length)
		) {
			setExtendedOptions({ ...stateOptions, [fieldKey]: options });
		}
	}, [fieldKey, options, stateOptions]);

	return useMemo(() => {
		// don't cache for field types with deletable options!
		if (
			fieldKey !== undefined &&
			!canDeleteOption(fieldType) &&
			stateOptions &&
			stateOptions[fieldKey] !== undefined
		) {
			return stateOptions[fieldKey].map(({ groupIdentity, value }) => ({
				groupIdentity,
				value:
					optionsByGroupIdentity[groupIdentity] !== undefined
						? optionsByGroupIdentity[groupIdentity].value
						: value,
			}));
		}
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldKey, fieldType, stateOptions, groupOptions, optionsByGroupIdentity]);
};

export const useExtendedOptionsInNaturalOrder = (field?: Field) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : '';
	const projectIssueTypes = useIssueTypesForProject({ projectId });
	const usedOptions = useOptions(field && field.key);
	const { allowEmpty } = useSortedGroupOptions(field && field.key);
	const statusesFromProject = useSortedStatusesAsList(field);
	const distinctIssueStatusesSorted = useSortedDistinctIssueStatuses();
	const isCollectionView = useIsCollectionView();

	return useMemo(() => {
		if (fg('jpd_issues_relationships')) {
			if (field?.type === FIELD_TYPES.ISSUE_TYPE) {
				return projectIssueTypes.map((value) => ({
					groupIdentity: value.id,
					droppableId: `${VALUE_COLUMN_ID_PREFIX}${value.id}`,
					value: { ...value, iconUrl: makeAvatarUrlFromId(value.avatarId, 'medium') },
				}));
			}
		}

		if (field && field.type === FIELD_TYPES.STATUS) {
			const statuses = isCollectionView ? distinctIssueStatusesSorted : statusesFromProject;
			return statuses.map<ExtendedOption<unknown>>((status) => ({
				groupIdentity: status.id,
				droppableId: `${VALUE_COLUMN_ID_PREFIX}${status.id}`,
				value: status,
			}));
		}

		const actualOptions = usedOptions.map<ExtendedOption<unknown>>(({ groupIdentity, value }) => ({
			groupIdentity,
			droppableId: `${VALUE_COLUMN_ID_PREFIX}${groupIdentity}`,
			value,
		}));

		if (allowEmpty) {
			actualOptions.unshift({
				groupIdentity: undefined,
				value: undefined,
				droppableId: EMPTY_VALUE_ID,
			});
		}

		return actualOptions;
	}, [
		field,
		usedOptions,
		allowEmpty,
		projectIssueTypes,
		isCollectionView,
		distinctIssueStatusesSorted,
		statusesFromProject,
	]);
};

export const useRestrictedOptions = (field?: Field) => {
	const extendedOptionsInNaturalOrder = useExtendedOptionsInNaturalOrder(field);
	const restrictions = useActiveFiltersGroupValues();

	return useMemo(() => {
		const restrictionForGroupField = find(
			restrictions,
			(restriction) => field && restriction.field.key === field.key,
		);

		if (
			restrictionForGroupField === undefined ||
			// @ts-expect-error - TS2339 - Property 'groupValues' does not exist on type 'number | ActiveFilterWithGroupValues | ((...items: ActiveFilterWithGroupValues[]) => number) | (<U>(callbackfn: (value: ActiveFilterWithGroupValues, index: number, array: ActiveFilterWithGroupValues[]) => U, thisArg?: any) => U[]) | ... 31 more ... | ((target: number, start: number, end?: number | undefined) => Acti...'.
			restrictionForGroupField.groupValues.length === 0
		) {
			return extendedOptionsInNaturalOrder;
		}

		return filter<ExtendedOption<unknown>>(
			extendedOptionsInNaturalOrder,
			(option: ExtendedOption<unknown>) => {
				if (
					field &&
					(field.type === FIELD_TYPES.NUMBER ||
						field.type === FIELD_TYPES.SLIDER ||
						field.type === FIELD_TYPES.CHECKBOX ||
						field.type === FIELD_TYPES.RATING ||
						field.type === FIELD_TYPES.FORMULA ||
						field.type === FIELD_TYPES.INSIGHTS ||
						field.type === FIELD_TYPES.LINKED_ISSUES)
				) {
					return evaluateNumericFilterValues(
						// @ts-expect-error - TS2339 - Property 'groupValues' does not exist on type 'number | ActiveFilterWithGroupValues | ((...items: ActiveFilterWithGroupValues[]) => number) | (<U>(callbackfn: (value: ActiveFilterWithGroupValues, index: number, array: ActiveFilterWithGroupValues[]) => U, thisArg?: any) => U[]) | ... 31 more ... | ((target: number, start: number, end?: number | undefined) => Acti...'.
						restrictionForGroupField.groupValues,
						// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'number | void'.
						option.value,
					);
				}
				// @ts-expect-error - TS2339 - Property 'groupValues' does not exist on type 'number | ActiveFilterWithGroupValues | ((...items: ActiveFilterWithGroupValues[]) => number) | (<U>(callbackfn: (value: ActiveFilterWithGroupValues, index: number, array: ActiveFilterWithGroupValues[]) => U, thisArg?: any) => U[]) | ... 31 more ... | ((target: number, start: number, end?: number | undefined) => Acti...'.
				return restrictionForGroupField.groupValues.includes(option.groupIdentity);
			},
		);
	}, [extendedOptionsInNaturalOrder, field, restrictions]);
};

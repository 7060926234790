import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectLabel: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.project-info.project-label',
		defaultMessage: 'Project',
		description: 'Label for current project',
	},
	switchProjectBtn: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.project-info.switch-project-btn',
		defaultMessage: 'Switch project',
		description: 'Button caption for creating an issue in a different project',
	},
	importIssues: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.project-info.import-issues',
		defaultMessage: 'Import issues',
		description: 'Label for import issues button',
	},
	importIssuesIssueTermRefresh: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.project-info.import-issues-issue-term-refresh',
		defaultMessage: 'Import work items',
		description: 'Label for import issues button',
	},
});

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { type ErrorResponse, onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { fragmentCacheRedirect, fragmentLinkState } from 'apollo-link-state-fragment';
import type { ApolloClientId } from '@atlassian/jira-apollo-multiple-clients/src/common/types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';
import fragmentTypesData from './constants.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, jira/restricted/graphql-tag
export { default as gqlTagPolaris } from 'graphql-tag';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FetchResult } from 'apollo-link';

const POLARIS_APOLLO_CLIENT_ID: ApolloClientId = 'POLARIS';
const LOG_NAMESPACE = `apollo.${POLARIS_APOLLO_CLIENT_ID}.error.graphql`;
const NETWORK_ERRORS_COUNTER_KEY = 'networkErrorsCounter';

const retryLink = new RetryLink({
	delay: {
		initial: 5000,
		max: 5000,
	},
	attempts: {
		max: 2,
		retryIf: (networkError) => networkError && !networkError.statusCode,
	},
});

export type CustomErrorHandlerRespose = Omit<ErrorResponse, 'forward' | 'response'>;

const createErrorLink = () =>
	onError(({ graphQLErrors, networkError, operation }) => {
		const operationName = operation?.operationName || 'unknown';

		if (
			isFunction(operation?.getContext()?.errorHandler) &&
			operation?.getContext()?.errorHandler({
				graphQLErrors,
				networkError,
				operation,
			})
		) {
			// skip default error reporting if it was handled in custom way
			return;
		}

		if (graphQLErrors) {
			if (Array.isArray(graphQLErrors)) {
				graphQLErrors.map(({ message, locations, path, extensions }) =>
					log.safeErrorWithoutCustomerData(
						LOG_NAMESPACE,
						`[GraphQL error]: Operation: ${operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`,
						{
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							...Object.keys(extensions).reduce<Record<string, any>>(
								(result, key) => ({
									// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
									...result,
									[`extensions_${key}`]:
										typeof extensions[key] === 'string'
											? extensions[key]
											: JSON.stringify(extensions[key]),
								}),
								{},
							),
							message: JSON.stringify(message),
							path: JSON.stringify(path),
							locations: JSON.stringify(locations),
						},
					),
				);
			} else {
				log.safeErrorWithoutCustomerData(
					LOG_NAMESPACE,
					`[GraphQL error]: Operation: ${operationName}, Unexpected error message format`,
					{
						message: JSON.stringify(graphQLErrors),
					},
				);
			}
		}

		if (networkError) {
			const occuredNetworkErrorsBefore = get(
				operation.getContext(),
				[NETWORK_ERRORS_COUNTER_KEY],
				0,
			);
			log.safeErrorWithoutCustomerData(
				LOG_NAMESPACE,
				`[Network error]: Operation: ${operationName}, ${networkError}, attempt ${
					occuredNetworkErrorsBefore + 1
				}`,
			);
			operation.setContext({
				[NETWORK_ERRORS_COUNTER_KEY]: occuredNetworkErrorsBefore + 1,
			});
		}
	});

const createCache = () =>
	new InMemoryCache({
		cacheRedirects: {
			Query: {
				...fragmentCacheRedirect(),
			},
		},
		// for Apollo Client 2.6
		// See https://www.apollographql.com/docs/react/v2.6/data/fragments/#fragments-on-unions-and-interfaces
		//
		// note that Apollo Client 3.0 has a totally different way of doing things
		fragmentMatcher: new IntrospectionFragmentMatcher({
			introspectionQueryResultData: fragmentTypesData,
		}),
	});

const clientCreator = () => {
	const cache = createCache();
	const uri = '/gateway/api/graphql';
	const headers = {
		'X-ExperimentalApi': ['polaris-v0', 'Townsquare'],
	};
	return new ApolloClient({
		name: POLARIS_APOLLO_CLIENT_ID,
		// @ts-expect-error - TS2345 - Argument of type '{ name: string; addTypename: boolean; link: ApolloLink; cache: InMemoryCache; connectToDevTools: boolean; }' is not assignable to parameter of type 'ApolloClientOptions<NormalizedCacheObject>'.
		addTypename: true,
		link: ApolloLink.from([
			retryLink,
			createErrorLink(),
			UFOLoggerLink,
			fragmentLinkState(cache),
			new HttpLink({
				uri,
				credentials: 'same-origin',
				headers,
			}),
		]),
		cache,
		connectToDevTools: process.env.NODE_ENV === 'development',
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	clientId: POLARIS_APOLLO_CLIENT_ID,
	clientCreator,
};

import React, {
	useMemo,
	type ReactNode,
	createContext,
	useContext,
	useState,
	useCallback,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	ASSIGNEE_FIELDKEY,
	KEY_FIELDKEY,
	STATUS_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useUser, useStatus } from '../../controllers/issue/selectors/properties/hooks.tsx';
import {
	useCurrentViewVisibleIssueActionFields,
	useCurrentViewVisibleFields,
	useCurrentViewLayoutType,
} from '../../controllers/views/selectors/view-hooks.tsx';
import { isActionField } from '../../controllers/views/selectors/view/index.tsx';
import {
	LIST_ITEM_HEIGHT,
	MAX_ITEMS_AMOUNT as MAX_CONNECTION_LIST_ITEMS_AMOUNT,
	LIST_ITEM_BORDER_BOTTOM_HEIGHT,
} from '../connection/connection-list/constants.tsx';
import {
	FIELD_HEIGHT,
	FIELD_HEIGHT_COMPACT,
	ROW_GAP,
	CARD_PADDING,
	KEY_FIELD_HEIGHT,
	CARD_PADDING_REDUCED,
	ASSIGNEE_SUMMARY_MIN_HEIGHT,
	ASSIGNEE_SUMMARY_CONTAINER_GAP,
	ASSIGNEE_SUMMARY_AVATAR_WIDTH,
	ASSIGNEE_SUMMARY_RIGHT_PADDING,
	SUMMARY_FIELD_STYLES,
	STRING_FIELD_VALUE_STYLES,
} from './constants.tsx';

export const FOOTER_BORDER_TOP = 1;

const EXCLUDED_FIELD_KEYS = ['assignee', 'key', 'status', 'flag', 'issuetype'];

type IdeaCardContextType = {
	allFields: Field[];
	visibleFields: Field[];
	allFieldsByKey: Record<string, Field>;
	deliveryProgressField: Field | undefined;
	deliveryStatusField: Field | undefined;
};

const IdeaCardContext = createContext<IdeaCardContextType | undefined>(undefined);

export const useIdeaCardContext = () => {
	const context = useContext(IdeaCardContext);
	if (context === undefined) {
		throw new Error('IdeaCardContext must be used within a IdeaCardProvider');
	}
	return context;
};

const isExcludedFieldKey = (field: Field, isSummary = false) =>
	(isSummary
		? EXCLUDED_FIELD_KEYS.filter((key) => key !== 'assignee')
		: EXCLUDED_FIELD_KEYS
	).includes(field.key);

const isBodyField = (field: Field, isSummary = false) =>
	field.key !== KEY_FIELDKEY && !isActionField(field) && !isExcludedFieldKey(field, isSummary);

type IdeaCardContextProviderProps = {
	children: ReactNode;
	extraFields?: Field[];
};

export const IdeaCardContextProvider = (props: IdeaCardContextProviderProps) => {
	const { children, extraFields } = props;
	const viewFields = useCurrentViewVisibleFields();
	const viewLayoutType = useCurrentViewLayoutType();

	const isSummary = viewLayoutType === ViewLayoutType.SUMMARY;

	const allFields = useMemo(
		() => [...viewFields, ...(extraFields || [])],
		[viewFields, extraFields],
	);
	const visibleFields = useMemo(() => {
		const fields = uniqBy<Field>(allFields, 'key');

		if (!fields) {
			return [];
		}

		return fields.filter((field) => !isExcludedFieldKey(field, isSummary));
	}, [allFields, isSummary]);

	const value = useMemo(
		() => ({
			allFields,
			allFieldsByKey: keyBy(allFields, 'key'),
			visibleFields,
			deliveryProgressField: visibleFields.find(
				({ type }) => type === FIELD_TYPES.DELIVERY_PROGRESS,
			),
			deliveryStatusField: visibleFields.find(({ type }) => type === FIELD_TYPES.DELIVERY_STATUS),
		}),
		[visibleFields, allFields],
	);

	return <IdeaCardContext.Provider value={value}>{children}</IdeaCardContext.Provider>;
};

export const getCardBottomPadding = (
	isCompact: boolean,
	hasActionFields: boolean,
	hasDeliveryFields: boolean,
) => (isCompact && hasActionFields && !hasDeliveryFields ? CARD_PADDING_REDUCED : CARD_PADDING);

const textHeightsCache = new Map<string, number>();
const summaryHeightsCache = new Map<string, number>();

const CARD_SUMMARY_MEASUREMENT_ELEMENT_ID = 'card-summary-height-measurement';
const CARD_TEXT_MEASUREMENT_ELEMENT_ID = 'card-text-height-measurement';

const measureText = (text: string, width: number, isSummary = false) => {
	const cache = isSummary ? summaryHeightsCache : textHeightsCache;
	if (cache.get(`${text}:${width}`) !== undefined) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return cache.get(`${text}:${width}`)!;
	}

	const id = isSummary ? CARD_SUMMARY_MEASUREMENT_ELEMENT_ID : CARD_TEXT_MEASUREMENT_ELEMENT_ID;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	let div = document.getElementById(id);

	if (!div) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		container.style.height = '0px';
		container.style.overflow = 'hidden';
		container.style.visibility = 'hidden';

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		div = document.createElement('div');
		div.setAttribute('id', id);
		const styles = isSummary ? SUMMARY_FIELD_STYLES : STRING_FIELD_VALUE_STYLES;
		for (const [key, value] of Object.entries(styles)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(div.style as any)[key] = value;
		}
		div.style.minHeight = styles.lineHeight;
		div.style.width = `${width}px`;
		container.appendChild(div);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.body.appendChild(container);
	}

	div.style.width = `${width}px`;
	div.textContent = text;
	const height = div.clientHeight;
	cache.set(`${text}:${width}`, height);

	return height;
};

export type GetCardHeightProps = {
	isCompact: boolean;
	isSummary: boolean;
	isDisabled: boolean;
	hasMultilineStrings: boolean;
	hasActionFields: boolean;
	hasKeyField: boolean;
	hasStatus: boolean;
	hasAssignee: boolean;
	deliveryFieldsLength: number;
	bodyFields: Field[];
	visibleBodyFields: Field[];
	stringFieldValues?: Array<{ key: string; value: string }>;
	width?: number;
	// the FieldKey should always have value > 0
	connectionFieldsIssues?: Record<FieldKey, number>;
};

export const getCardHeight = (props: GetCardHeightProps) => {
	const {
		isCompact,
		isSummary,
		isDisabled,
		hasMultilineStrings,
		hasActionFields,
		hasKeyField,
		hasStatus,
		hasAssignee,
		deliveryFieldsLength,
		bodyFields,
		visibleBodyFields,
		stringFieldValues = [],
		width = 0,
		connectionFieldsIssues,
	} = props;
	const bottomPadding = getCardBottomPadding(isCompact, hasActionFields, deliveryFieldsLength > 0);

	const bodyFieldsWithoutSummary = bodyFields.filter((field) => field.key !== SUMMARY_FIELDKEY);

	if (isSummary) {
		return CARD_PADDING + bottomPadding + (FIELD_HEIGHT_COMPACT + ROW_GAP);
	}

	if (isCompact) {
		// extract in a different function
		const compactStringFieldsLength = hasMultilineStrings
			? 0
			: visibleBodyFields.filter(
					({ type }) => type === FIELD_TYPES.SUMMARY || type === FIELD_TYPES.SHORT_TEXT,
				).length;
		let totalHeight =
			CARD_PADDING + bottomPadding + compactStringFieldsLength * (FIELD_HEIGHT_COMPACT + ROW_GAP);

		// Header
		if (hasKeyField || hasStatus) {
			totalHeight += FIELD_HEIGHT_COMPACT + ROW_GAP;
		}

		// String fields with a variable height
		if (hasMultilineStrings) {
			for (const { key, value } of stringFieldValues) {
				// When there is no key and status, and there is an assignee,
				// the summary is displayed along the assignee at the top of the card
				const isSummaryDisplayedAlongAssignee = !hasKeyField && !hasStatus && hasAssignee;
				const summaryWidth = isSummaryDisplayedAlongAssignee
					? width -
						(ASSIGNEE_SUMMARY_CONTAINER_GAP +
							ASSIGNEE_SUMMARY_AVATAR_WIDTH +
							(isDisabled ? 0 : ASSIGNEE_SUMMARY_RIGHT_PADDING))
					: width - ASSIGNEE_SUMMARY_RIGHT_PADDING;
				const stringHeight = measureText(
					value,
					key === SUMMARY_FIELDKEY ? summaryWidth : width,
					key === SUMMARY_FIELDKEY,
				);
				if (key === SUMMARY_FIELDKEY && isSummaryDisplayedAlongAssignee) {
					totalHeight +=
						stringHeight < ASSIGNEE_SUMMARY_MIN_HEIGHT ? ASSIGNEE_SUMMARY_MIN_HEIGHT : stringHeight;
				} else {
					totalHeight += stringHeight;
				}
			}

			if (stringFieldValues.length > 0) {
				totalHeight += (stringFieldValues.length - 1) * ROW_GAP;
			}
		}

		// Fields of compact card in one row
		if (
			!stringFieldValues.length
				? bodyFieldsWithoutSummary.length
				: visibleBodyFields.length > stringFieldValues.length + deliveryFieldsLength
		) {
			totalHeight += FIELD_HEIGHT + ROW_GAP;
		}

		// Footer
		if (hasActionFields || deliveryFieldsLength > 0) {
			totalHeight += ROW_GAP + FOOTER_BORDER_TOP;
			if (deliveryFieldsLength > 0) {
				totalHeight += FIELD_HEIGHT_COMPACT + ROW_GAP;
			}
			if (hasActionFields) {
				totalHeight += FIELD_HEIGHT;
			}
		}

		// Edge case when there is only a summary displayed in the body, but there are other active fields with no values.
		// Because of the way the card FieldsContainer is implemented, there is an extra gap even though the body is empty.
		const onlySummaryFieldIsDisplayed = visibleBodyFields.length === 1;
		if (onlySummaryFieldIsDisplayed && bodyFieldsWithoutSummary.length > 0) {
			totalHeight += ROW_GAP;
		}

		return totalHeight;
	}

	let nonStringFields = visibleBodyFields.length - stringFieldValues.length;
	let totalHeight = 2 * CARD_PADDING;

	if (hasActionFields) {
		nonStringFields += 1;
		totalHeight += ROW_GAP;
	}
	if (hasAssignee || hasStatus) {
		nonStringFields += 1;

		if (visibleBodyFields.length > 0) {
			totalHeight += ROW_GAP;
		}
	}

	if (!isEmpty(connectionFieldsIssues)) {
		Object.values(connectionFieldsIssues).forEach((numberOfIssues) => {
			const numberOfIssuesCapped = Math.min(numberOfIssues, MAX_CONNECTION_LIST_ITEMS_AMOUNT);

			totalHeight += numberOfIssuesCapped * LIST_ITEM_HEIGHT;

			// Add one extra height for the "${amount} more" button at the bottom of the connections list
			if (numberOfIssues > numberOfIssuesCapped) {
				totalHeight += LIST_ITEM_HEIGHT;
			}

			// Accounts for the last element not having the bottom border
			totalHeight -= LIST_ITEM_BORDER_BOTTOM_HEIGHT;
		});

		totalHeight += (nonStringFields - Object.keys(connectionFieldsIssues).length) * FIELD_HEIGHT;
	} else {
		totalHeight += nonStringFields * FIELD_HEIGHT;
	}

	for (const { key, value } of stringFieldValues) {
		const finalWidth =
			key === SUMMARY_FIELDKEY ? width - (isDisabled ? 0 : ASSIGNEE_SUMMARY_RIGHT_PADDING) : width;
		const stringHeight = hasMultilineStrings
			? measureText(value, finalWidth, key === SUMMARY_FIELDKEY)
			: FIELD_HEIGHT;
		if (key === SUMMARY_FIELDKEY) {
			totalHeight += stringHeight;
		} else {
			totalHeight += stringHeight < FIELD_HEIGHT ? FIELD_HEIGHT : stringHeight;
		}
	}

	// Edge case when there is only a summary displayed in the body, but there are other active fields with no values.
	// Because of the way the card FieldsContainer is implemented, there is an extra gap even though the body is empty.
	const onlySummaryFieldIsDisplayed =
		visibleBodyFields.length === 1 && !hasActionFields && !hasAssignee && !hasStatus;
	if (onlySummaryFieldIsDisplayed && bodyFieldsWithoutSummary.length > 0) {
		totalHeight += ROW_GAP;
	}

	if (visibleBodyFields.length > 0) {
		totalHeight += (visibleBodyFields.length - 1) * ROW_GAP;
	}

	if (hasKeyField) {
		totalHeight += KEY_FIELD_HEIGHT + ROW_GAP;
	}

	if (hasActionFields) {
		totalHeight += FOOTER_BORDER_TOP;
	}

	return totalHeight;
};

// Returns height of the card based on shown fields
// Works only for IdeaCard that has both "hideEmptyFields" and "hasMultilineStrings" props set to false
export const useCardHeight = (isCompact = false, isSummary = false, isDisabled = false): number => {
	const { visibleFields, allFieldsByKey } = useIdeaCardContext();
	const visibleBodyFields = useMemo(
		() => visibleFields.filter((field) => !isActionField(field) && field.key !== KEY_FIELDKEY),
		[visibleFields],
	);
	const hasKeyField = allFieldsByKey[KEY_FIELDKEY] !== undefined;
	const hasActionFields = useCurrentViewVisibleIssueActionFields().length > 0;
	const hasAssignee = allFieldsByKey[ASSIGNEE_FIELDKEY] !== undefined;
	const hasStatus = allFieldsByKey[STATUS_FIELDKEY] !== undefined;

	const deliveryFields = visibleBodyFields.filter(
		({ type }) => type === FIELD_TYPES.DELIVERY_STATUS || type === FIELD_TYPES.DELIVERY_PROGRESS,
	);

	return useMemo(
		() =>
			getCardHeight({
				isCompact,
				isSummary,
				isDisabled,
				hasMultilineStrings: false,
				hasActionFields,
				hasKeyField,
				hasStatus,
				hasAssignee,
				deliveryFieldsLength: deliveryFields.length,
				bodyFields: visibleBodyFields,
				visibleBodyFields,
			}),
		[
			hasActionFields,
			hasAssignee,
			hasStatus,
			visibleBodyFields,
			hasKeyField,
			isCompact,
			isSummary,
			isDisabled,
			deliveryFields.length,
		],
	);
};

type UserDisplayInformation = {
	name: string;
	avatarUrl: string;
};

export const useVisibleAssignee = (
	issueId: LocalIssueId,
): UserDisplayInformation | undefined | null => {
	const { allFieldsByKey } = useIdeaCardContext();
	const userInformation = useUser('assignee', issueId);
	const assigneeField = allFieldsByKey.assignee;
	return useMemo(() => {
		if (
			!assigneeField ||
			userInformation === undefined ||
			userInformation.displayName === undefined ||
			userInformation.avatarUrls === undefined ||
			userInformation.avatarUrls['32x32'] === undefined
		) {
			return null;
		}
		return {
			name: userInformation.displayName,
			avatarUrl: userInformation.avatarUrls['32x32'],
		};
	}, [assigneeField, userInformation]);
};

export const useVisibleStatus = (
	issueId: LocalIssueId,
):
	| {
			name: string;
			isInProgress: boolean;
			isDone: boolean;
	  }
	| undefined => {
	const { allFieldsByKey } = useIdeaCardContext();
	const status = useStatus(issueId);
	const statusField = allFieldsByKey.status;
	return useMemo(() => {
		if (!status || !statusField) {
			return undefined;
		}
		return {
			name: status.name,
			isInProgress: status.statusCategory.key === 'indeterminate',
			isDone: status.statusCategory.key === 'done',
		};
	}, [status, statusField]);
};

export const getAppearance = (status: { isDone: boolean; isInProgress: boolean; name: string }) => {
	if (status.isDone) {
		return 'success';
	}
	if (status.isInProgress) {
		return 'inprogress';
	}
	return 'default';
};

type GetCardHeightsProps = {
	width: number;
	allFields: Field[];
	actionFieldKeys: string[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueMap: Map<string, Array<{ key: string; field: Field; value: any }>>;
	isCompact?: boolean;
	isSummary?: boolean;
	isDisabled?: boolean;
};

/**
 * Returns the height of the given cards, assuming that the texts can span multiple lines.
 * The issueMap param represents a map of card and their field and values.
 * The list of fields should be filtered up front depending wether we want to hide empty fields or not.
 */
export const getCardHeights = (props: GetCardHeightsProps) => {
	const {
		width,
		allFields,
		issueMap,
		actionFieldKeys,
		isCompact = false,
		isSummary = false,
		isDisabled = false,
	} = props;
	const cardHeights = new Map<string, number>();
	const bodyFields = allFields.filter((field) => isBodyField(field, isSummary));

	for (const [key, fields] of issueMap) {
		const stringFieldValues = fields
			.filter(
				({ field }) => field.type === FIELD_TYPES.SUMMARY || field.type === FIELD_TYPES.SHORT_TEXT,
			)
			.map((field) => ({
				key: field.key,
				value: field.value,
			}));
		const hasActionFields = actionFieldKeys.length > 0;
		const hasKeyField = fields.find((field) => field.key === KEY_FIELDKEY) !== undefined;
		const hasStatus = fields.find((field) => field.key === STATUS_FIELDKEY) !== undefined;
		const hasAssignee = fields.find((field) => field.key === ASSIGNEE_FIELDKEY) !== undefined;
		const deliveryFields = fields.filter(
			({ field }) =>
				field.type === FIELD_TYPES.DELIVERY_STATUS || field.type === FIELD_TYPES.DELIVERY_PROGRESS,
		);
		const fieldsWithNonEmptyConnection = fields.filter(
			({ field, value }) =>
				field.key !== FIELD_TYPES.CONNECTION || (Array.isArray(value) && value.length > 0),
		);
		const visibleBodyFields = fieldsWithNonEmptyConnection.filter(({ field }) =>
			isBodyField(field, isSummary),
		);

		const connectionFieldsIssues = fieldsWithNonEmptyConnection.reduce<Record<FieldKey, number>>(
			(acc, { field, value }) => {
				if (field.type === FIELD_TYPES.CONNECTION) {
					acc[field.key] = value.length;
				}
				return acc;
			},
			{},
		);

		const height = getCardHeight({
			isCompact,
			isSummary,
			isDisabled,
			hasMultilineStrings: true,
			hasActionFields,
			hasKeyField,
			hasStatus,
			hasAssignee,
			deliveryFieldsLength: deliveryFields.length,
			bodyFields,
			visibleBodyFields: visibleBodyFields.map((field) => field.field),
			stringFieldValues,
			width,
			connectionFieldsIssues,
		});

		cardHeights.set(key, height);
	}

	return cardHeights;
};

// extracted to separate hook for easier component testing
export const useSummaryTooltip = () => {
	const [summaryTooltipHidden, setSummaryTooltipHidden] = useState(false);

	const hideSummaryTooltip = useCallback(() => {
		setSummaryTooltipHidden(true);
	}, [setSummaryTooltipHidden]);

	const showSummaryTooltip = useCallback(() => {
		setSummaryTooltipHidden(false);
	}, [setSummaryTooltipHidden]);

	return { summaryTooltipHidden, hideSummaryTooltip, showSummaryTooltip };
};

import React from 'react';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import { useCurrentViewVisibleFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ExportViewDialog } from '@atlassian/jira-polaris-component-view-export/src/ui/export-view-dialog/index.tsx';
import type { ViewExportDialogProps } from '../types.tsx';

export const ListViewExportDialog = (props: ViewExportDialogProps) => {
	const visibleFields = useCurrentViewVisibleFields();
	const issueIds = useSortedIssueIds();

	return <ExportViewDialog {...props} issueIds={issueIds} visibleFields={visibleFields} />;
};

import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import {
	abortAttachmentInUpload,
	addAttachment,
	addAttachmentInUpload,
} from './add-attachment/index.tsx';
import * as commentsActions from './comments/index.tsx';
import { deleteAttachment, removeAttachments } from './delete-attachment/index.tsx';
import { loadDeliveryTickets, updateDeliveryTickets } from './delivery-tickets/index.tsx';
import {
	saveDescriptionDraft,
	loadDescriptionDraft,
	clearDescriptionDraft,
} from './draft/index.tsx';
import {
	setFieldSidebarFieldKey,
	setFieldSidebarMode,
	setFieldSidebarOptionId,
	setFieldSidebar,
	resetFieldSidebarMode,
} from './fields-sidebar/index.tsx';
import { loadIssue } from './load-issue/index.tsx';
import { refreshIssueLinks } from './refresh-issue-links/index.tsx';
import { closeSummaryEditor, setSummaryEditing } from './summary/index.tsx';
import { unlinkIssue, removeIssueFromIssueLinks } from './unlink-issue/index.tsx';
import { watchIssue, unWatchIssue, toggleIssueWatching } from './watchers/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadIssue,
	refreshIssueLinks,
	unlinkIssue,
	removeIssueFromIssueLinks,
	addAttachment,
	addAttachmentInUpload,
	abortAttachmentInUpload,
	deleteAttachment,
	removeAttachments,
	saveDescriptionDraft,
	loadDescriptionDraft,
	clearDescriptionDraft,
	loadDeliveryTickets,
	setFieldSidebarFieldKey,
	setFieldSidebarMode,
	setFieldSidebarOptionId,
	setFieldSidebar,
	resetFieldSidebarMode,
	watchIssue,
	unWatchIssue,
	toggleIssueWatching,
	updateDeliveryTickets,
	setSummaryEditing,
	closeSummaryEditor,
	...commentsActions,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

import React, { useCallback, useState } from 'react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import/no-restricted-import
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { VIEW_CREATE_POPUP_TYPES, type ViewCreatePopupType } from '../types.tsx';
import { Actions } from './actions/index.tsx';
import messages from './messages.tsx';
import { ButtonTrigger } from './triggers/button-trigger/index.tsx';
import { SidebarHeaderTrigger } from './triggers/sidebar-header-trigger/index.tsx';
import { SidebarSectionTrigger } from './triggers/sidebar-section-trigger/index.tsx';

type ViewCreatePopupProps = {
	type: ViewCreatePopupType;
	onCreateView: (kind: ViewKind) => void;
	onCreateSection?: () => void;
	viewEntityLimitMessage?: string | null;
	sectionEntityLimitMessage?: string | null;
};

export const ViewCreatePopup = ({
	type,
	onCreateView,
	onCreateSection,
	viewEntityLimitMessage,
	sectionEntityLimitMessage,
}: ViewCreatePopupProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	let trigger: ((triggerProps: TriggerProps) => React.JSX.Element) | null = null;
	switch (type) {
		case VIEW_CREATE_POPUP_TYPES.SIDEBAR_HEADER:
			trigger = (triggerProps: TriggerProps) => (
				<SidebarHeaderTrigger
					triggerProps={triggerProps}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					label={formatMessage(messages.createNewView)}
				/>
			);
			break;
		case VIEW_CREATE_POPUP_TYPES.SIDEBAR_SECTION_ITEM:
		case VIEW_CREATE_POPUP_TYPES.SIDEBAR_SUB_SECTION_ITEM:
			trigger = (triggerProps: TriggerProps) => (
				<SidebarSectionTrigger
					triggerProps={triggerProps}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					label={formatMessage(messages.createNewView)}
					type={type}
				/>
			);
			break;
		case VIEW_CREATE_POPUP_TYPES.BUTTON:
		default:
			trigger = (triggerProps: TriggerProps) => (
				<ButtonTrigger
					triggerProps={triggerProps}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					label={formatMessage(messages.createNewView)}
				/>
			);
			break;
	}

	const handleCreateView = useCallback(
		(kind: ViewKind) => {
			setIsOpen(false);
			onCreateView(kind);
		},
		[onCreateView],
	);

	return (
		<Popup
			placement={type === VIEW_CREATE_POPUP_TYPES.BUTTON ? 'bottom' : 'bottom-start'}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			testId="polaris-lib-create-view-popup.ui.popup"
			content={() => (
				<Actions
					onCreateView={handleCreateView}
					onCreateSection={
						onCreateSection
							? () => {
									setIsOpen(false);
									onCreateSection?.();
								}
							: undefined
					}
					viewEntityLimitMessage={viewEntityLimitMessage}
					sectionEntityLimitMessage={sectionEntityLimitMessage}
				/>
			)}
			trigger={trigger}
		/>
	);
};

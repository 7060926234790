import React, { useCallback, useRef } from 'react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { BulkIssueUpdateProgress } from './controllers/polling/index.tsx';
import type { BulkIssueUpdateProgressNotificationProps } from './controllers/types.tsx';
import { messages } from './messages.tsx';
import { ProgressDisplay } from './ui/index.tsx';

const FLAG_DISSMIS_DELAY = 3000;

export const useBulkIssueUpdateProgressNotification = () => {
	const flagDissmisseRef = useRef<() => void>();
	const { formatMessage } = useIntl();
	const { customFlag } = useNotifications();

	const onFinished = useCallback(() => {
		setTimeout(() => {
			flagDissmisseRef.current?.();
		}, FLAG_DISSMIS_DELAY);
	}, []);

	return useCallback(
		(props: BulkIssueUpdateProgressNotificationProps) => {
			flagDissmisseRef.current = customFlag({
				title: formatMessage(
					fg('polaris-issue-terminology-refresh')
						? messages.flagTitleIssueTermRefresh
						: messages.flagTitle,
				),
				description: (
					<BulkIssueUpdateProgress {...props} onFinished={onFinished}>
						<ProgressDisplay />
					</BulkIssueUpdateProgress>
				),
				icon: <InfoIcon label="Info" />,
			});
		},
		[customFlag, formatMessage, onFinished],
	);
};

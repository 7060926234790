import React from 'react';
import JpdListBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-list-blank/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages.tsx';
import { Container, Wrapper } from '../../../common/ui/container/styled.tsx';
import { ViewEmptyState } from '../../../common/ui/view-empty-state/index.tsx';
import { ListPlaceholder, RowPlaceholder } from '../../../common/ui/view-empty-state/styled.tsx';
import type { ViewKindEmptyStateProps } from '../../../types.tsx';
import { messages } from './messages.tsx';

export const ListViewEmptyState = ({
	onButtonClick,
	isCollectionView,
}: ViewKindEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container marginTop={10}>
			<Wrapper>
				<ViewEmptyState
					renderImage={JpdListBlankIllustrationWrapper}
					header={formatMessage(isCollectionView ? messages.roadmapViewHeader : messages.header)}
					description={formatMessage(
						isCollectionView ? messages.roadmapViewDescription : messages.description,
					)}
					buttonText={formatMessage(commonMessages.buttonTextIdea)}
					onButtonClick={onButtonClick}
				/>
			</Wrapper>
			<ListPlaceholder>
				{[...Array.from({ length: 25 })].map((_, i) => (
					<RowPlaceholder key={i} isHeader={i === 0} />
				))}
			</ListPlaceholder>
		</Container>
	);
};

const JpdListBlankIllustrationWrapper = () => <JpdListBlankIllustration alt="" width={306} />;

// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridgeWeak } from '@atlassian/jira-common-bridge/src';
import type { UserDataFromMonolith } from '../../types.tsx';

export type SetData = (
	userDataFromMonolith: UserDataFromMonolith,
	referenceElement: HTMLElement | string,
	offset: string,
) => void;

export type Api = {
	setData: SetData;
	hideProfileCard: () => void;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (api: Api) => {
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridgeWeak({
		name: 'jira/profilecard/bridge',
	}).then((bridge) => {
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		bridge(api);
	});
};

import { useEffect } from 'react';
import { useFieldKeys } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIsInitialized as useIsIssuesInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useIssueViewLayout } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useFieldKeyToAriMapAsync } from '@atlassian/jira-polaris-component-field-ari/src/index.tsx';

export const useLoadIssuesAndInitialize = () => {
	const { loadIssues, initialize } = useIssueActions();
	const [fieldKeys] = useFieldKeys();
	const fieldKeyToAriMap = useFieldKeyToAriMapAsync({ fieldKeys });
	const isIssuesInitialized = useIsIssuesInitialized();
	const issueViewLayout = useIssueViewLayout();
	const shouldInitialize = !isIssuesInitialized && issueViewLayout === undefined;

	useEffect(() => {
		if (shouldInitialize) {
			loadIssues().then(() => initialize(fieldKeyToAriMap));
		}
	}, [fieldKeyToAriMap, initialize, shouldInitialize, loadIssues]);

	return isIssuesInitialized;
};

import React from 'react';
import { Anchor, Box, Grid, Text, xcss } from '@atlaskit/primitives';
import { useIssueLink } from '../../common/utils/issue-link/index.tsx';
import { useConnectedIssueDataByIssueId } from '../../controllers/issue/selectors/connection-hooks.tsx';

type IssueCardProps = {
	issueKey: string;
	summary: string;
	issueType: {
		iconUrl: string;
		name: string;
	};
	type?: 'default' | 'link';
};

export const IssueCard = ({ issueKey, summary, issueType, type = 'default' }: IssueCardProps) => {
	const { link, target, onClick } = useIssueLink({
		issueKey,
		shouldOpenInSidebar: true,
	});
	const isLink = type === 'link';

	const card = (
		<Grid alignItems="center" gap="space.050" templateColumns="auto auto" xcss={cardStyles}>
			<img width={16} height={16} src={issueType.iconUrl} alt={issueType.name} />
			<Box xcss={summaryStyles}>
				<Text color={isLink ? 'color.text.selected' : undefined}>{summary}</Text>
			</Box>
		</Grid>
	);

	if (!isLink) {
		return card;
	}

	return (
		<Anchor onClick={onClick} xcss={linkStyles} href={link} target={target}>
			{card}
		</Anchor>
	);
};

export const ConnectedIssueCard = ({
	id,
	...props
}: { id: string } & Pick<IssueCardProps, 'type'>) => {
	const connectedIssue = useConnectedIssueDataByIssueId(id);

	if (!connectedIssue) {
		return null;
	}

	return (
		<IssueCard
			{...props}
			issueKey={connectedIssue.issueKey}
			issueType={connectedIssue.issueType}
			summary={connectedIssue.summary}
		/>
	);
};

const linkStyles = xcss({
	textDecoration: 'none',
});

const cardStyles = xcss({
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: '4px',
	paddingBlock: 'space.025',
	paddingInlineStart: 'space.050',
	paddingInlineEnd: 'space.100',
	backgroundColor: 'elevation.surface',
	height: '24px',
	maxWidth: 'fit-content',
});

const summaryStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

import React, { useRef, useState, useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { Inline, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useConnectionFieldHighlightableFieldsList } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { FieldSearchableDropdown } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';

export type FieldSelectProps = {
	connectionFieldKey: FieldKey;
};

export const FieldSelect = ({ connectionFieldKey }: FieldSelectProps) => {
	const { formatMessage } = useIntl();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const toggleDialog = useCallback(() => setDialogOpen(!dialogOpen), [dialogOpen]);
	const options = useConnectionFieldHighlightableFieldsList();
	const { updateConnectionFieldIssueViewLayout } = useFieldActions();

	return (
		<Inline alignBlock="center" xcss={containerStyles}>
			<PolarisInlineDialog
				noPadding
				onClose={(target) => {
					if (buttonRef.current && !buttonRef.current.contains(target)) {
						setDialogOpen(false);
					}
				}}
				isOpen={dialogOpen}
				placement="bottom-end"
				content={
					<FieldSearchableDropdown
						options={options.map(
							({ key, label: fieldLabel, type, emoji, configuration, global, custom }) => ({
								key,
								label: fieldLabel,
								type,
								emoji,
								global,
								custom,
								hasWeightType: configuration?.optionWeightType !== undefined,
								...(fg('jpd_issues_relationships') && {
									configuration,
								}),
							}),
						)}
						onSelect={(selectedFieldKey) => {
							updateConnectionFieldIssueViewLayout(connectionFieldKey, {
								selectedFieldKey,
							});
							setDialogOpen(false);
						}}
						onClose={() => setDialogOpen(false)}
						isSearchable
					/>
				}
			>
				<IconButton
					ref={buttonRef}
					label={formatMessage(messages.selectField)}
					spacing="compact"
					isSelected={dialogOpen}
					onClick={toggleDialog}
					icon={AddIcon}
				/>
			</PolarisInlineDialog>
		</Inline>
	);
};

const containerStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.100',
	height: '100%',
});

import React, { memo, useCallback, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import type { PolarisTooltipProps } from '@atlassian/jira-polaris-lib-tooltip/src/ui/types.tsx';
import { useField } from '../../controllers/issue/selectors/fields-hooks.tsx';
import type { HighlightedField } from '../../controllers/issue/types.tsx';
import { DateField } from './date-field/index.tsx';
import { DateTimeField } from './datetime-field/index.tsx';
import { IntervalField } from './interval-field/index.tsx';
import { MultiSelectField } from './multi-select-field/index.tsx';
import { NumberField } from './number-field/index.tsx';
import { SingleSelectField } from './single-select-field/index.tsx';
import { StringListField } from './string-list-field/index.tsx';

type FieldHighlightRowProps = {
	fieldKey: FieldKey;
	issueId: string;
};
const FieldHighlightRow = ({ fieldKey, issueId }: FieldHighlightRowProps) => {
	const field = useField(fieldKey);
	if (!field) return null;

	const { key, type } = field;

	if (type === FIELD_TYPES.DATE) {
		return <DateField key={key} issueId={issueId} fieldKey={key} />;
	}
	if (type === FIELD_TYPES.CREATED || type === FIELD_TYPES.UPDATED) {
		return <DateTimeField key={key} issueId={issueId} fieldKey={key} />;
	}
	if (
		type === FIELD_TYPES.NUMBER ||
		type === FIELD_TYPES.SLIDER ||
		type === FIELD_TYPES.FORMULA ||
		type === FIELD_TYPES.LINKED_ISSUES
	) {
		return <NumberField key={key} issueId={issueId} fieldKey={fieldKey} />;
	}
	if (type === FIELD_TYPES.SINGLE_SELECT) {
		return <SingleSelectField key={key} issueId={issueId} fieldKey={key} />;
	}
	if (type === FIELD_TYPES.LABELS || type === FIELD_TYPES.CUSTOM_LABELS) {
		return <StringListField key={key} issueId={issueId} fieldKey={key} />;
	}
	if (type === FIELD_TYPES.MULTI_SELECT || type === FIELD_TYPES.JSW_MULTI_SELECT) {
		return <MultiSelectField key={key} issueId={issueId} fieldKey={key} />;
	}
	if (type === FIELD_TYPES.INTERVAL) {
		return <IntervalField key={key} issueId={issueId} fieldKey={key} />;
	}

	return null;
};

type HighlightingTooltipProps = {
	children: ReactNode;
	position?: PolarisTooltipProps['position'];
	isResizing?: boolean;
	issueId: string;
	fields: HighlightedField[];
};

export const HighlightingTooltip = memo(
	({ fields, issueId, isResizing, children, position }: HighlightingTooltipProps) => {
		const getTooltipContent = useCallback(
			() => (
				<TooltipContent>
					{sortBy(uniqBy(fields, 'fieldKey'), [({ label }) => label.toLowerCase()]).map((field) => (
						<FieldHighlightRow key={field.fieldKey} fieldKey={field.fieldKey} issueId={issueId} />
					))}
				</TooltipContent>
			),
			[fields, issueId],
		);

		if (isResizing) {
			return <>{children}</>;
		}

		return (
			<PolarisTooltip hideTooltipOnClick content={getTooltipContent} position={position}>
				{children}
			</PolarisTooltip>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContent = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '240px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '-10px',
});

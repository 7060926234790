import { resetProvider, updateProvider } from './lifecycle.tsx';
import { presenceJoined, presenceLeft, updatePresence } from './presence.tsx';
import { getUser } from './user.tsx';

export const actions = {
	presenceJoined,
	presenceLeft,
	updatePresence,
	getUser,
	resetProvider,
	updateProvider,
};

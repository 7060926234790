import React, { useState, type ReactNode, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import cloneDeep from 'lodash/cloneDeep';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { autoScroller } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	monitorForElements,
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { B400, N0, N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ensureNonNullable } from '@atlassian/jira-polaris-lib-ts-utils/src/index.tsx';
import { updateCollectionAfterDnd } from './utils.tsx';

type DraggableProps<T> = {
	id: T;
	isDragDisabled?: boolean;
	isDropDisabled?: boolean;
	children: ReactNode;
};

export const Draggable = <T,>(props: DraggableProps<T>) => {
	const { children, id, isDragDisabled, isDropDisabled } = props;

	const ref = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, setDragStatus] = useState<'idle' | 'preview' | 'dragging'>('idle');

	useEffect(() => {
		if (!ref.current) return undefined;

		const cleanupDragAndDrop = combine(
			draggable({
				element: ref.current,
				onGenerateDragPreview: () => {
					setDragStatus('preview');
				},
				getInitialData() {
					return { id };
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					setDragStatus('idle');
				},
				canDrag: () => !isDragDisabled,
			}),
			dropTargetForElements({
				element: ref.current,
				getData({ input, element }) {
					return attachClosestEdge(
						{ id },
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				canDrop() {
					return !isDropDisabled;
				},
				onDrag(args) {
					if (args.source.data.id !== id) {
						const dropEdge = extractClosestEdge(args.self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [closestEdge, id, isDragDisabled, isDropDisabled]);

	return (
		<DraggableWrapper ref={ref} closestEdge={closestEdge} dragStatus={dragStatus}>
			{children}
		</DraggableWrapper>
	);
};

export type DroppableProps<T> = {
	onDragStart?: (args: { srcId: T }) => void;
	onDragEnd?: () => void;
	onSort: (args: { srcId: T; dstId: T; edge: Edge }) => void;
};

export const useDroppableEvents = <T,>({ onSort, onDragStart, onDragEnd }: DroppableProps<T>) => {
	const clientXPos = useRef<number>(0);

	useEffect(() => {
		const cleanupDragAndDrop = combine(
			monitorForElements({
				onDragStart: ({ source, location }) => {
					// fix scroll position to the current container only
					clientXPos.current = location.current.input.clientX;
					autoScroller.start({
						input: {
							...location.current.input,
							// lock the x axis
							pageX: clientXPos.current,
							clientX: clientXPos.current,
						},
					});
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onDragStart?.({ srcId: source.data.id as T });
				},
				onDrop: ({ source, location }) => {
					autoScroller.stop();

					const target = location.current.dropTargets?.[0];
					if (!target || source.data.id === target?.data?.id) {
						onDragEnd?.();
						return;
					}

					const edge = extractClosestEdge(target.data);
					if (edge !== 'top' && edge !== 'bottom') {
						onDragEnd?.();
						return;
					}

					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onSort({ srcId: source.data.id as T, dstId: target.data.id as T, edge });
					onDragEnd?.();
				},
				onDrag: ({ location }) => {
					autoScroller.updateInput({
						input: {
							...location.current.input,
							// lock the x axis
							pageX: clientXPos.current,
							clientX: clientXPos.current,
						},
					});
				},
			}),
		);

		return cleanupDragAndDrop;
	}, [onDragEnd, onDragStart, onSort]);
};

export type DroppableCollectionProps<Item, ItemKey> = {
	collection: Item[];
	isSrcIdRepresentingIndex?: boolean;
	getSrcIdForCollectionItem?: (item: Item) => unknown;
	onDragStart?: (args: { srcId: ItemKey }) => void;
	onDragEnd?: () => void;
	onSort: (args: { srcId: ItemKey; dstId: ItemKey; edge: Edge; updatedCollection: Item[] }) => void;
};

const defaultGetSrcIdForCollectionItem = <T,>(x: T): T => x;

export const useDroppableEventsCollectionUpdate = <Item, ItemKey>({
	collection,
	isSrcIdRepresentingIndex = false,
	getSrcIdForCollectionItem = defaultGetSrcIdForCollectionItem,
	onSort,
	onDragEnd,
	onDragStart,
}: DroppableCollectionProps<Item, ItemKey>) => {
	const sourceRef = useRef<Item>();

	useDroppableEvents<ItemKey>({
		onDragStart: ({ srcId }) => {
			// `item` needs to be cloned in the moment when it's being dragged
			// to avoid loosing reference as it may be removed
			// from the collection
			sourceRef.current = cloneDeep(
				isSrcIdRepresentingIndex
					? collection[Number(srcId)]
					: collection.find((item) => getSrcIdForCollectionItem(item) === srcId),
			);
			onDragStart?.({ srcId });
		},
		onDragEnd: () => {
			sourceRef.current = undefined;
			onDragEnd?.();
		},
		onSort: ({ srcId, dstId, edge }) => {
			const updatedCollection = updateCollectionAfterDnd({
				srcId,
				dstId,
				edge,
				collection,
				getSrcIdForCollectionItem,
				sourceItem: ensureNonNullable(sourceRef.current),
				isSrcIdRepresentingIndex,
			});
			onSort({ srcId, dstId, edge, updatedCollection });
		},
	});
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DraggableWrapper = styled.div<{
	closestEdge: Edge | null;
	dragStatus: string;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		width: '100%',
		height: '3px',
		position: 'absolute',
		zIndex: 200,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge }) => (closestEdge === 'top' ? '-1.5px' : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge }) => (closestEdge === 'bottom' ? '-1.5px' : 'auto'),
		backgroundColor: token('color.border.brand', B400),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus }) => dragStatus !== 'dragging' && 'none',
		content: '',
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: '0',
		left: '0',
		backgroundColor: token('color.background.input', N0),
		zIndex: 100,
		boxShadow: `inset ${token('elevation.shadow.overflow', N30A)}`,
	},
});

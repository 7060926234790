import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addDescription: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.description-editor.add-description',
		defaultMessage: 'Add a description...',
		description: 'Label for button to add a description',
	},
	deliveryProgressDescription: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.description-editor.delivery-progress-description',
		defaultMessage:
			'An estimation of the progress of delivery tickets from Jira (epics, issues) that are linked to the idea. For more details open each idea and the "Delivery" section',
		description: 'Delivery progress field description',
	},
	deliveryProgressDescriptionIssueTermRefresh: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.description-editor.delivery-progress-description-issue-term-refresh',
		defaultMessage:
			'An estimation of the progress of delivery work items from Jira such as epics that are linked to the idea. For more details open each idea and the “Delivery” section',
		description: 'Delivery progress field description',
	},
	deliveryStatusDescription: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.description-editor.delivery-status-description',
		defaultMessage:
			'The status of delivery tickets from Jira (epics, issues) that are linked to the idea. For more details open each idea and the "Delivery" section',
		description: 'Delivery status field description',
	},
	deliveryStatusDescriptionIssueTermRefresh: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.description-editor.delivery-status-description-issue-term-refresh',
		defaultMessage:
			'The status of delivery work items from Jira such as epics that are linked to an idea.” For more details open each idea and the “Delivery” section',
		description: 'Delivery status field description',
	},
});

import React from 'react';
import { Emoji } from '@atlaskit/emoji/element';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { EmojiWrapper } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { optionNoValueOptionMessage } from '../option/index.tsx';

type Props = {
	groupIdentity: string | undefined;
};

export const ReactionOptionRenderer = ({ groupIdentity }: Props) => {
	const emoji = useEmoji(groupIdentity);
	const { formatMessage } = useIntl();
	if (emoji === undefined) {
		return <Box paddingInlineStart="space.100">{formatMessage(optionNoValueOptionMessage)}</Box>;
	}
	return (
		<EmojiWrapper>
			<Emoji emoji={emoji} fitToHeight={18} showTooltip />
		</EmojiWrapper>
	);
};

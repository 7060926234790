import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fetchPermissions } from '../../../../services/load-permissions/index.tsx';
import type { Permission } from '../../../../services/load-permissions/types.tsx';
import type { State, Props } from '../../types.tsx';

const isPreviewContributorModeAllowed = (permissions: Permission[]) => {
	const canCreateIssues = permissions.find(
		({ key, havePermission }) => key === 'CREATE_ISSUES' && havePermission === true,
	);
	const canEditIssues = permissions.find(
		({ key, havePermission }) => key === 'EDIT_ISSUES' && havePermission === true,
	);
	return !!(canCreateIssues && canEditIssues);
};

export const loadPermissions =
	(): Action<State, Props> =>
	async (
		{ getState, setState },
		{
			containerAri,
			isSharedView,
			isCollectionView,
			isEmbeddedView,
			projectId,
			onLoadStart,
			onLoadEnd,
		},
	) => {
		onLoadStart?.();
		const loadingProps = {
			containerAri,
			isSharedView,
			isCollectionView,
			isEmbeddedView,
			projectId,
		};
		const {
			meta: { loadingProps: previousLoadingProps, loading, loaded },
			permissions: previousPermissions,
			isPreviewContributorMode: previousIsPreviewMode,
		} = getState();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const currUrl = new URL(window.location.href);
		const isPreviewRolePresentInUrl = currUrl.searchParams.get('previewRole') === 'contributor'; // for 1st version only contributor role is available for preview mode
		const isPreviewMode =
			isPreviewRolePresentInUrl && isPreviewContributorModeAllowed(previousPermissions);

		// Only load again when the props this function depends on have changed
		const isPropsNotChanged =
			previousPermissions.length > 0 &&
			previousLoadingProps !== undefined &&
			containerAri === previousLoadingProps.containerAri &&
			previousIsPreviewMode === isPreviewMode;

		if (!isSharedView && !isCollectionView && isPropsNotChanged) {
			return;
		}

		if (isSharedView && !projectId) {
			return;
		}

		if (isCollectionView && !projectId) {
			setState({
				meta: { loaded: true, loading: false, error: undefined, loadingProps },
				permissions: [],
			});
			return;
		}

		// do not re-fetch permissions
		if (
			getWillShowNav4() &&
			(loading || loaded) &&
			((containerAri !== '' && containerAri === previousLoadingProps?.containerAri) ||
				(projectId !== undefined && projectId === previousLoadingProps?.projectId))
		) {
			return;
		}

		setState({
			meta: { loaded: false, loading: true, error: undefined, loadingProps },
			permissions: [],
		});

		try {
			const id =
				(isSharedView || isCollectionView) && projectId
					? projectId
					: getAriConfig(containerAri).resourceId;

			const permissions = await fetchPermissions(Number(id));

			setState({
				meta: {
					loaded: true,
					loading: false,
					error: undefined,
					loadingProps,
				},
				permissions,
				isPreviewContributorMode:
					isPreviewContributorModeAllowed(permissions) && isPreviewRolePresentInUrl,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				meta: {
					loaded: true,
					loading: false,
					error,
					loadingProps,
				},
				permissions: [],
				isPreviewContributorMode: false,
			});
		} finally {
			onLoadEnd?.();
		}
	};

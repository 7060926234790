import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { jiraSideNavResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import { useResource } from '@atlassian/react-resource-router';

export const JswSideNavRecommendation = () => {
	return fg('post-office-shadow-rollout-jira-sidenav-ssr') ? <PlacementAPIFetchEnabled /> : null;
};

export const JpdSideNavRecommendation = () => null;

const PlacementAPIFetchEnabled = () => {
	// Use resource to make the shadow API call to Post Office.
	// Don't do anything with the result, we just want to mock the traffic for now.
	useResource(jiraSideNavResource);
	return null;
};

import React, { useEffect, type ReactNode } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Tooltip from '@atlaskit/tooltip';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { withFlagService, errorFlags, type FlagService } from '@atlassian/jira-flags';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useNotificationSettingsService as useNotificationSettingsServiceDI } from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-settings-service/main.tsx';
import AddNotificationModal from '../../common/ui/add-notification/index.tsx';
import { useNotificationSettingsPageController } from '../../controllers/notification-settings-controller/index.tsx';
import messages from './messages.tsx';

type Props = {
	pageTitle: string | ReactNode;
	children: ReactNode;
	useNotificationSettingsService: typeof useNotificationSettingsServiceDI;
	flagService: FlagService;
	intl: IntlShape;
};

const NotificationSettingsPageHeader = (props: Props) => {
	const {
		pageTitle,
		children,
		useNotificationSettingsService,
		flagService: { showFlag },
		intl: { formatMessage },
	} = props;

	const [{ isAddModalOpen }, { setIsAddNotificationModalOpen }] =
		useNotificationSettingsPageController();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const addNotificationClicked = (_: any, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'addNotificationButton');

		setIsAddNotificationModalOpen(true);
	};

	const [
		{
			data: { eventTypes },
		},
		{ fetchNotificationTypes },
	] = useNotificationSettingsService();

	useEffect(() => {
		if (!eventTypes) {
			const onFailure = () => {
				showFlag(errorFlags.genericError());
			};
			fetchNotificationTypes({ onFailure });
		}
	}, [eventTypes, fetchNotificationTypes, formatMessage, showFlag]);

	const AddButton = (
		<Button onClick={addNotificationClicked}>{formatMessage(messages.addNotification)}</Button>
	);
	const DisabledAddButton = (
		<Tooltip content={formatMessage(messages.disabledButtonTooltip)}>
			<Button isDisabled>{formatMessage(messages.addNotification)}</Button>
		</Tooltip>
	);

	const isAllEventsAdded = eventTypes && eventTypes.length === 0;
	const actions = isAllEventsAdded === true ? DisabledAddButton : AddButton;

	return (
		<>
			<PageHeader actions={actions}>{pageTitle}</PageHeader>
			{children}
			<ModalTransition>
				{isAddModalOpen && (
					<AddNotificationModal onClose={() => setIsAddNotificationModalOpen(false)} />
				)}
			</ModalTransition>
		</>
	);
};

NotificationSettingsPageHeader.defaultProps = {
	useNotificationSettingsService: useNotificationSettingsServiceDI,
};

export default withFlagService(injectIntl(NotificationSettingsPageHeader));

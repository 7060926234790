import type React from 'react';
import { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	usePermanentFieldFilter,
	useTemporaryFieldFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useCurrentViewHideEmptyBoardColumns } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useOptionsAsIdNameTuples } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/select/utils/options.tsx';
import type { SelectOption } from '@atlassian/jira-polaris-common/src/ui/common/decoration/select/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldValueFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useNextValidAddValue } from '@atlassian/jira-polaris-lib-option-utils/src/common/utils/add-value-generator/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useOnAddedHiddenColumn } from '../use-on-added-hidden-column/index.tsx';

const getFilterWithValues = (...filters: FieldValueFilter[]): FieldValueFilter | undefined =>
	filters.find((f) => f.values.length > 0);

export const useOnAddColumn = (fieldKey: FieldKey) => {
	const permanentFieldFilter = usePermanentFieldFilter(fieldKey);
	const temporaryFieldFilter = useTemporaryFieldFilter(fieldKey);
	const { updateFieldFilter } = useViewActions();
	const options = useOptionsAsIdNameTuples(fieldKey);
	const nextColumnName = useNextValidAddValue(
		undefined,
		options.map((option: SelectOption) => option.value),
	);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const onAddedHiddenColumn = useOnAddedHiddenColumn();
	const { addOption } = useFieldActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		async (_event: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			const newOptionId = await addOption(fieldKey, nextColumnName.value);

			const newOptionString = String(newOptionId);
			fireUIAnalytics(analyticsEvent, 'addFieldValue', {
				fieldValueId: newOptionString,
			});

			const filter = getFilterWithValues(permanentFieldFilter, temporaryFieldFilter);
			if (filter) {
				updateFieldFilter({
					...filter,
					values: [...filter.values, { stringValue: newOptionString }],
					isTemporary: filter.isTemporary,
				});
			}

			if (hideEmptyColumns) {
				onAddedHiddenColumn();
			}

			fireTrackAnalytics(createAnalyticsEvent({}), 'view updated', {
				viewFieldValuesUpdated: {
					added: [newOptionString],
				},
			});

			return newOptionString;
		},
		[
			addOption,
			createAnalyticsEvent,
			fieldKey,
			hideEmptyColumns,
			nextColumnName.value,
			onAddedHiddenColumn,
			permanentFieldFilter,
			temporaryFieldFilter,
			updateFieldFilter,
		],
	);
};

import React from 'react';
import head from 'lodash/head';
import { Box, xcss } from '@atlaskit/primitives';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useQuickSearchFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { TextFilterComponent } from '../../../../view-controls/config-filters/text/index.tsx';

export const SummaryFilterComponent = () => {
	const qsFilter = useQuickSearchFilter();
	const { updateQuickSearchFilter } = useViewActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onUpdateQuickSearchFilter = (stringValue: string) => {
		fireUIAnalytics(createAnalyticsEvent({}), 'summaryFilter updated', 'create-with-filters');

		updateQuickSearchFilter([{ stringValue }]);
	};

	const qsFilterVal = head(qsFilter.values.map(({ stringValue }) => stringValue)) ?? '';

	return (
		<Box xcss={summaryFilterWrapperStyles}>
			<TextFilterComponent value={qsFilterVal} onChange={onUpdateQuickSearchFilter} />
		</Box>
	);
};

const summaryFilterWrapperStyles = xcss({
	marginRight: 'space.100',
	marginTop: 'space.050',
	marginBottom: 'space.050',
});

import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueType } from '../types.tsx';

export const unassociateGlobalField = async (
	fieldKey: string,
	issueType: IssueType,
): Promise<void> => {
	await performPostRequest(`/rest/polaris/global-fields/${fieldKey}/unassociate`, {
		body: JSON.stringify(issueType),
	});
};

import React from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import { ExternalReferenceField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference/main.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { NoValueContainer } from '../../../common/board-header-column/styled.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const ExternalReferenceColumnHeader = ({ fieldKey, groupIdentity }: ColumnHeaderComponentProps) => (
	<ExternalReferenceField
		isEditable={false}
		fieldKey={fieldKey}
		value={[groupIdentity]}
		onUpdate={noop}
	/>
);

const EmptyExternalReferenceColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const externalReferenceColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: ExternalReferenceColumnHeader,
	EmptyComponent: EmptyExternalReferenceColumnHeader,
} as const;

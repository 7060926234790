import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { RenameFieldOptionRequest } from './types.tsx';

export const renameFieldOption = ({
	projectId,
	issueTypeId,
	fieldKey,
	optionId,
	newTitle,
}: RenameFieldOptionRequest): Promise<void> =>
	performPutRequest(
		`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options/${optionId}`,
		{
			body: JSON.stringify({
				issueTypeId: +issueTypeId,
				value: newTitle,
			}),
		},
	);

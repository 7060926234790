import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useGetIntervalFieldsRollup } from '../../../../controllers/issue/selectors/fields-rollup-hooks.tsx';
import { Wrapper } from '../../styled.tsx';
import type { Props } from '../../types.tsx';
import { messages } from '../messages.tsx';

export const IntervalFieldGroupedMin = ({ fieldKey, groupId, groupFieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const val = useGetIntervalFieldsRollup(fieldKey, groupFieldKey, groupId);
	const emptyMessage = formatMessage(messages.empty);

	return (
		<Wrapper>{formatMessage(messages.minVal, { val: `${val?.min ?? emptyMessage}` })}</Wrapper>
	);
};

import React, { useCallback, useMemo, useEffect, useRef, memo, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import {
	ViewLayoutType,
	type ViewKind,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useSelectedIssue } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import {
	useCurrentViewKind,
	useCurrentViewVisibleIssueActionFields,
} from '../../../controllers/views/selectors/view-hooks.tsx';
import {
	ROW_GAP,
	COLUMN_GAP,
	FIELD_HEIGHT,
	DISPLAYING_FIELDS_MIN_CARD_WIDTH,
} from '../constants.tsx';
import { ConnectionField } from './connection/index.tsx';
import { DateField } from './date/index.tsx';
import { DateTimeField } from './datetime/index.tsx';
import { DefaultFields } from './default-fields/index.tsx';
import { ExternalReferencePropertyField } from './external-reference-property/index.tsx';
import { ExternalReferenceField } from './external-reference/index.tsx';
import { IntervalDateField } from './interval-date/index.tsx';
import { LinkedIssuesProgressField } from './linked-issues-progress/index.tsx';
import { LinkedIssuesStatus } from './linked-issues-status/index.tsx';
import { messages } from './messages.tsx';
import { MultiSelectField } from './multi-select/index.tsx';
import { NumberField } from './number/index.tsx';
import { ProjectField } from './project/index.tsx';
import { SingleSelectField } from './single-select/index.tsx';
import { StringListField } from './string-list/index.tsx';
import { StringField } from './string/index.tsx';
import { TeamField } from './team/index.tsx';
import type { FieldListField } from './types.tsx';
import { UserField } from './users/index.tsx';
import { getFieldListFilteredFields } from './utils.tsx';

type StringFieldListProps = {
	issueId: string;
	fields: Field[];
	isCompact: boolean;
	hideEmptyFields?: boolean;
	hasMultilineStrings?: boolean;
	isResizing: boolean;
};

const StringFieldList = memo(
	({
		fields,
		isCompact,
		issueId,
		hideEmptyFields,
		hasMultilineStrings,
		isResizing,
	}: StringFieldListProps) => (
		<>
			{fields.map(({ key }) => (
				<StringField
					key={key}
					issueId={issueId}
					fieldKey={key}
					isCompact={isCompact && !hideEmptyFields && !hasMultilineStrings}
					hideEmpty={hideEmptyFields}
					isMultiline={hasMultilineStrings}
					isResizing={isResizing}
				/>
			))}
		</>
	),
);

type FieldListProps = {
	issueId: string;
	fields: FieldListField[];
	isCompact: boolean;
	isSummary: boolean;
	isResizing: boolean;
	hideEmptyFields?: boolean;
	hasMultilineStrings?: boolean;
	disableLazyRendering?: boolean;
	cappedFieldsDisplay?: boolean;
	viewKind?: ViewKind;
	isRenderedInPopup?: boolean;
};

export const FieldList = memo(
	({
		issueId,
		fields,
		isCompact,
		isSummary,
		isResizing = false,
		hideEmptyFields = false,
		hasMultilineStrings = false,
		disableLazyRendering = false,
		cappedFieldsDisplay = false,
		viewKind,
		isRenderedInPopup = false,
	}: FieldListProps) => (
		<>
			{fields.map(({ key, type }) => {
				const sharedProps = {
					issueId,
					fieldKey: key,
					isSummary,
				};

				switch (type) {
					case FIELD_TYPES.SHORT_TEXT:
					case FIELD_TYPES.HYPERLINK:
						return (
							<StringField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								isResizing={isResizing}
								hideEmpty={hideEmptyFields || isCompact}
								isMultiline={hasMultilineStrings}
							/>
						);
					case FIELD_TYPES.DATE:
						return (
							<DateField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
								disableLazyRendering={disableLazyRendering}
							/>
						);
					case FIELD_TYPES.CREATED:
					case FIELD_TYPES.UPDATED:
						return (
							<DateTimeField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
								disableLazyRendering={disableLazyRendering}
							/>
						);
					case FIELD_TYPES.INTERVAL:
						return (
							<IntervalDateField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
								disableLazyRendering={disableLazyRendering}
							/>
						);
					case FIELD_TYPES.NUMBER:
					case FIELD_TYPES.CHECKBOX:
					case FIELD_TYPES.FORMULA:
					case FIELD_TYPES.SLIDER:
					case FIELD_TYPES.RATING:
					case FIELD_TYPES.LINKED_ISSUES:
						return (
							<NumberField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
								disableLazyRendering={disableLazyRendering}
							/>
						);
					case FIELD_TYPES.SINGLE_SELECT:
						return (
							<SingleSelectField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
							/>
						);
					case FIELD_TYPES.LABELS:
					case FIELD_TYPES.CUSTOM_LABELS:
						return (
							<StringListField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
								cappedFieldsDisplay={cappedFieldsDisplay}
							/>
						);
					case FIELD_TYPES.MULTI_SELECT:
					case FIELD_TYPES.JSW_MULTI_SELECT:
						return (
							<MultiSelectField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
								cappedFieldsDisplay={cappedFieldsDisplay}
							/>
						);
					case FIELD_TYPES.ASSIGNEE:
					case FIELD_TYPES.REPORTER:
					case FIELD_TYPES.CREATOR:
					case FIELD_TYPES.PEOPLE:
					case FIELD_TYPES.JSW_PEOPLE:
						return (
							<UserField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
							/>
						);
					case FIELD_TYPES.DELIVERY_STATUS:
						return !isCompact ? (
							<LinkedIssuesStatus key={key} {...sharedProps} hideEmpty={hideEmptyFields} />
						) : null;
					case FIELD_TYPES.ATLAS_GOAL:
					case FIELD_TYPES.ATLAS_PROJECT:
						return (
							<ExternalReferenceField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
							/>
						);
					case FIELD_TYPES.ATLAS_PROJECT_STATUS:
						return (
							<ExternalReferencePropertyField
								key={key}
								{...sharedProps}
								isCompact={isCompact}
								hideEmpty={hideEmptyFields || isCompact}
							/>
						);
					case FIELD_TYPES.DELIVERY_PROGRESS:
						return !isCompact ? (
							<LinkedIssuesProgressField key={key} {...sharedProps} hideEmpty={hideEmptyFields} />
						) : null;
					case FIELD_TYPES.PROJECT:
						return (
							<ProjectField
								key={key}
								{...sharedProps}
								hideEmpty={hideEmptyFields}
								isCompact={isCompact}
							/>
						);
					case FIELD_TYPES.CONNECTION:
						return (
							<ConnectionField
								fieldKey={sharedProps.fieldKey}
								localIssueId={sharedProps.issueId}
								variant={isCompact ? ViewLayoutType.COMPACT : ViewLayoutType.DETAILED}
								renderDetailedAsCompact={viewKind === VIEW_KIND_TIMELINE && !isRenderedInPopup}
								hideEmpty={hideEmptyFields || isCompact}
							/>
						);
					case FIELD_TYPES.TEAM:
						if (fg('polaris_team_field_integration')) {
							return (
								<TeamField
									key={key}
									{...sharedProps}
									hideEmpty={hideEmptyFields}
									isCompact={isCompact}
								/>
							);
						}
						return null;
					default:
						break;
				}
				return null;
			})}
		</>
	),
);

type BodyProps = {
	issueId: string;
	fields: Field[];
	isCompact: boolean;
	isSummary: boolean;
	isHovered?: boolean;
	isFocused: boolean;
	isDisabled?: boolean;
	isResizing?: boolean;
	hoverBackgroundColor?: string;
	hideEmptyFields?: boolean;
	hasMultilineStrings?: boolean;
	disableLazyRendering?: boolean;
	cappedFieldsDisplay?: boolean;
	onAllFieldsClick: () => void;
	isRenderedInPopup?: boolean;
};

export const Body = ({
	issueId,
	fields,
	isCompact,
	isSummary,
	isHovered,
	isFocused,
	isDisabled,
	hoverBackgroundColor,
	hideEmptyFields = false,
	hasMultilineStrings = false,
	disableLazyRendering = false,
	isResizing = false,
	cappedFieldsDisplay,
	onAllFieldsClick,
	isRenderedInPopup,
}: BodyProps) => {
	const { formatMessage } = useIntl();
	const rootContainerRef = useRef<HTMLDivElement | null>(null);
	const fieldsContainerRef = useRef<HTMLDivElement | null>(null);
	const [hasHiddenFields, setHasHiddenFields] = useState(false);
	const actionFieldsCount = useCurrentViewVisibleIssueActionFields().length;
	const selectedIssue = useSelectedIssue();
	const isHighlighted = selectedIssue === issueId;
	const viewKind = useCurrentViewKind();

	const deliveryFieldsCount = useMemo(
		() =>
			fields.filter(
				({ type }) =>
					type === FIELD_TYPES.DELIVERY_STATUS || type === FIELD_TYPES.DELIVERY_PROGRESS,
			).length,
		[fields],
	);

	const stringFields = useMemo(
		() =>
			fields.filter(({ type }) => type === FIELD_TYPES.SUMMARY || type === FIELD_TYPES.SHORT_TEXT),
		[fields],
	);

	const fieldListFields = useMemo(
		() => getFieldListFilteredFields(fields, isCompact),
		[fields, isCompact],
	);

	const compactHasMoreThanOneField =
		fields.length - actionFieldsCount - deliveryFieldsCount - stringFields.length > 1;

	const resetHiddenFields = useCallback(() => {
		const fieldsContainerWidth = fieldsContainerRef.current?.clientWidth || 0;
		const rootContainerWidth = rootContainerRef.current?.clientWidth || 0;

		setHasHiddenFields(
			isCompact && fieldsContainerWidth > rootContainerWidth && compactHasMoreThanOneField,
		);
	}, [compactHasMoreThanOneField, isCompact]);

	useEffect(() => {
		if (isCompact) {
			resetHiddenFields();
		}

		const containerElement = rootContainerRef.current;
		let mutationObserver: MutationObserver;

		if (containerElement && isCompact) {
			// some fields (e.g. Atlas fields) are async and it takes a while to fetch thier value and rerender
			// mutationObserver looks for those changes and updates hidden fields state accordingly
			mutationObserver = new MutationObserver(debounce(resetHiddenFields, 500));
			mutationObserver.observe(containerElement, { childList: true, subtree: true });
		}

		return () => {
			if (mutationObserver) {
				mutationObserver.disconnect();
			}
		};
	}, [resetHiddenFields, isCompact]);

	useEffect(() => {
		const containerElement = rootContainerRef.current;
		let resizeObserver: ResizeObserver;

		if (containerElement && typeof ResizeObserver !== 'undefined' && isCompact) {
			resizeObserver = new ResizeObserver(debounce(resetHiddenFields, 150));

			// requestAnimationFrame is necessary to prevent "ResizeObserver - loop limit exceeded" errors
			// to be triggered, which breaks the drag and drop of cards when the user is using Windows OR MacOS
			// with the settings "Show scroll bars: Always"
			requestAnimationFrame(() => {
				resizeObserver.observe(containerElement);
			});
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		};
	}, [resetHiddenFields, isCompact]);

	useEffect(() => {
		if (!isCompact) {
			return;
		}
		resetHiddenFields();
	}, [resetHiddenFields, fields.length, isCompact]);

	if (isEmpty(fields)) {
		return null;
	}

	return (
		<Container ref={rootContainerRef}>
			{/* Here we render string fields (exc. hyperlink) for compact card */}
			{isCompact && (
				<StringFieldList
					fields={stringFields}
					isCompact={isCompact}
					hasMultilineStrings={hasMultilineStrings}
					hideEmptyFields={hideEmptyFields}
					issueId={issueId}
					isResizing={isResizing}
				/>
			)}
			{/* Show FieldsContainer if amount of fields without action fields is bigger than delivery fields + strings in compact */}
			{fieldListFields.length > 0 &&
				fields.length - actionFieldsCount >
					(isCompact ? deliveryFieldsCount + stringFields.length : 0) && (
					<FieldsContainer
						ref={fieldsContainerRef}
						isRow={isCompact}
						hasMaxContent={compactHasMoreThanOneField}
						hasFixedHeight={!hideEmptyFields && !hasMultilineStrings}
						cappedFieldsDisplay={cappedFieldsDisplay}
					>
						<FieldList
							viewKind={viewKind}
							fields={fieldListFields}
							isCompact={isCompact}
							isSummary={isSummary}
							isResizing={isResizing}
							hasMultilineStrings={hasMultilineStrings}
							hideEmptyFields={hideEmptyFields}
							issueId={issueId}
							disableLazyRendering={disableLazyRendering}
							cappedFieldsDisplay={cappedFieldsDisplay}
							isRenderedInPopup={isRenderedInPopup}
						/>
					</FieldsContainer>
				)}
			{hasHiddenFields && (
				<HiddenFieldsButtonWrapper
					isFocused={isFocused}
					isHighlighted={isHighlighted}
					hoverBackgroundColor={hoverBackgroundColor}
					isDisabled={isDisabled}
					isHovered={isHovered}
				>
					<Tooltip content={!isResizing && formatMessage(messages.allFields)} delay={0}>
						<Button
							iconBefore={<MoreIcon size="medium" label="" />}
							aria-label={formatMessage(messages.allFields)}
							onClick={onAllFieldsClick}
						/>
					</Tooltip>
				</HiddenFieldsButtonWrapper>
			)}
			{!isCompact && (
				<DefaultFields issueId={issueId} hideEmptyFields={hideEmptyFields} isSummary={isSummary} />
			)}
		</Container>
	);
};

Body.displayName = 'CardBody';
Body.whyDidYouRender = true;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${ROW_GAP}px 0`,
	position: 'relative',
	zIndex: '0',
	'&:empty': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		width: 0,
		overflow: 'hidden',
		visibility: 'hidden',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div<{
	isRow?: boolean;
	isCompact?: boolean;
	hasMaxContent?: boolean;
	hasFixedHeight?: boolean;
	cappedFieldsDisplay?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isRow }) => (isRow ? 'flex' : 'grid'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	gap: ({ isRow }) => (isRow ? `0 ${COLUMN_GAP}px` : `${ROW_GAP}px ${COLUMN_GAP}px`),
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isRow, hasMaxContent }) => (isRow && hasMaxContent ? 'max-content' : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridTemplateColumns: ({ isRow }) => !isRow && '[title] fit-content(100px) [content] 1fr',
	// the minmax is needed due to string fields which can have a higher height than FIELD_HEIGHT
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	gridAutoRows: ({ isRow }) => !isRow && `minmax(${FIELD_HEIGHT}px, auto)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: ({ isRow }) => isRow && `${FIELD_HEIGHT}px`,
	'&:empty': {
		display: 'none',
	},
	// selects all fields after the 3rd one
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div:nth-of-type(1n+4)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ isRow, cappedFieldsDisplay }) =>
			isRow && cappedFieldsDisplay ? 'none' : undefined,
	},
});

const getBackground = (
	isHovered?: boolean,
	isHighlighted?: boolean,
	isFocused?: boolean,
	hoverBackgroundColor?: string,
) =>
	isHovered
		? `linear-gradient(270deg, ${
				isHighlighted
					? token('color.background.selected.hovered')
					: hoverBackgroundColor ?? token('elevation.surface.hovered')
			} 65.49%, rgba(255, 255, 255, 0) 100%)`
		: `linear-gradient(270deg, ${
				// eslint-disable-next-line no-nested-ternary
				isHighlighted
					? token('color.background.selected')
					: isFocused
						? token('color.background.accent.blue.subtlest')
						: token('elevation.surface')
			} 65.49%, rgba(255, 255, 255, 0) 100%)`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HiddenFieldsButtonWrapper = styled.div<{
	isHighlighted?: boolean;
	isHovered?: boolean;
	isFocused?: boolean;
	hoverBackgroundColor?: string;
	isDisabled?: boolean;
}>({
	position: 'absolute',
	zIndex: 20,
	right: token('space.negative.150'),
	bottom: 0,
	width: token('space.1000'),
	height: '36px',
	paddingRight: token('space.150'),
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'flex-end',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isDisabled }) => isDisabled && 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isHighlighted, isHovered, isFocused, hoverBackgroundColor }) =>
		getBackground(isHovered, isHighlighted, isFocused, hoverBackgroundColor),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="container-J2q9"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ isHighlighted, isFocused, hoverBackgroundColor }) =>
			getBackground(true, isHighlighted, isFocused, hoverBackgroundColor),
	},
});

import log from '@atlassian/jira-common-util-logging/src/log.tsx';

const defaultWindowOptions = 'width=600,height=700';

export const openOauth2AuthenticationWindow = (
	authUrl: string,
	windowKey: string,
	onSuccess: () => void,
	onFailure: (err: Error) => void,
	options: string = defaultWindowOptions,
) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const newWin = window.open(authUrl, windowKey, options);
	const authOrigin = new URL(authUrl).origin;

	if (!newWin) {
		return;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const messageEventListener = (evt: any) => {
		if (authOrigin !== evt.origin) {
			return;
		}

		const type = evt?.data?.type;

		if (type === 'outbound-auth:success') {
			onSuccess();
			newWin.close();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('message', messageEventListener, false);
			return;
		}

		if (type === 'outbound-auth:failure') {
			onFailure(new Error(`Error ${evt.data.errorType}. ${evt.data.message}.`));
			newWin.close();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('message', messageEventListener, false);
			return;
		}

		const errorMessage = 'Unexpected message receive from oauth2 auth window';

		onFailure(new Error(errorMessage));
		log.safeWarnWithoutCustomerData('polaris-insights-oauth2', errorMessage, evt);

		newWin.close();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('message', messageEventListener, false);
	};

	newWin.onbeforeunload = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('message', messageEventListener, false);
	};

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.addEventListener('message', messageEventListener, false);
};

import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { N600 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { messages } from './messages.tsx';
import { HeaderTitle } from './styled.tsx';

type HeaderProps = {
	searchValue: string;
	onSearchChange: (value: string) => void;
	onClose: () => void;
};

export const Header = ({ searchValue, onClose, onSearchChange }: HeaderProps) => {
	const { formatMessage } = useIntl();

	return (
		<HeaderContainer>
			<HeaderTopContainer>
				<HeaderTitle>{formatMessage(messages.title)}</HeaderTitle>
				<Button
					appearance="subtle-link"
					iconBefore={<CrossIcon label="close" size="medium" />}
					onClick={onClose}
				/>
			</HeaderTopContainer>
			<SearchInput
				isCompact={false}
				value={searchValue}
				placeholder={formatMessage(messages.searchPlaceholder)}
				onChange={onSearchChange}
			/>
			<HeaderDescription>{formatMessage(messages.description)}</HeaderDescription>
		</HeaderContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	padding: `${token('space.300', '24px')} ${token('space.200', '16px')} ${token(
		'space.100',
		'8px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTopContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderDescription = styled.div({
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	textAlign: 'center',
	color: token('color.text.subtle', N600),
});

import React, { memo } from 'react';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { Observer } from '@atlassian/jira-polaris-lib-performance/src/controllers/index.tsx';
import { useBaseTableApi } from '../../controllers/selectors/ui-hooks.tsx';

type PerformanceObserverProps = {
	isGrouped: boolean;
	fieldsCount: number;
	issuesCount: number;
};

export const PerformanceObserver = memo<PerformanceObserverProps>(
	({ isGrouped, fieldsCount, issuesCount }: PerformanceObserverProps) => {
		const baseTableApi = useBaseTableApi();

		const scrollingContainer =
			baseTableApi?.getDOMNode()?.querySelector<HTMLElement>('.table__body') || null;

		return (
			<Observer
				scrollingContainer={scrollingContainer}
				jpdExperience={experience.listView.listScrollPerformance}
				metadata={{
					isGrouped: String(isGrouped),
					fieldsCount: String(fieldsCount),
					issuesCount: String(issuesCount),
				}}
			/>
		);
	},
);

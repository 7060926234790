import { B400, N600, B75, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { getSummaryCardLayout, getCompactCardLayout, getDetailedCardLayout } from './utils.tsx';

export const ConfigLayoutPreview = ({
	isSelected,
	isSummary = false,
	isCompact = false,
}: {
	isSelected: boolean;
	isSummary?: boolean;
	isCompact?: boolean;
}) => {
	const primaryColor = isSelected
		? token('color.background.accent.blue.bolder', B400)
		: token('color.background.neutral.bold', N600);
	const secondaryColor = isSelected
		? token('color.background.selected.hovered', B75)
		: token('color.background.accent.gray.subtler', N40);

	if (isSummary) {
		return getSummaryCardLayout(primaryColor);
	}
	if (isCompact) {
		return getCompactCardLayout(primaryColor, secondaryColor);
	}
	return getDetailedCardLayout(primaryColor, secondaryColor);
};

import {
	getCellComponent,
	getDragHandleComponent,
	getGroupCellComponent,
	getHeaderComponent,
	getRowComponent,
	getRowGroupComponent,
	getEmptyViewComponent,
	getGoToRowLabelComponent,
	getRowPinnedBottomComponent,
} from './components.tsx';
import { createSelectorHook } from './utils.tsx';

export const useCellComponent = createSelectorHook(getCellComponent);
export const useDragHandleComponent = createSelectorHook(getDragHandleComponent);
export const useGroupCellComponent = createSelectorHook(getGroupCellComponent);
export const useHeaderComponent = createSelectorHook(getHeaderComponent);
export const useRowComponent = createSelectorHook(getRowComponent);
export const useRowPinnedBottomComponent = createSelectorHook(getRowPinnedBottomComponent);
export const useRowGroupComponent = createSelectorHook(getRowGroupComponent);
export const useEmptyViewComponent = createSelectorHook(getEmptyViewComponent);
export const useGoToRowLabelComponent = createSelectorHook(getGoToRowLabelComponent);

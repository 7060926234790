import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import UserPickerView from '@atlassian/jira-issue-user-picker/src/main.tsx';
import type { UserOptionValue } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { User } from '../../../../../common/types/user/index.tsx';
import { getUsers } from '../../../../../services/jira/user-picker/index.tsx';
import ReporterEdit from '../../../../fields/user/reporter/index.tsx';
import messages from './messages.tsx';

type UserFieldProps = {
	isEditable: boolean;
	fieldKey: FieldKey;
	user: UserFieldValue | undefined;
	onUpdate: (inputValue: User | undefined) => void;
};

const getUserProps = (user?: UserFieldValue | null): User | null => {
	if (!user) {
		return null;
	}
	return {
		id: user.accountId,
		name: user.displayName ?? '',
		avatarUrl: user.avatarUrls?.['48x48'] ?? '',
	};
};

export const UserField = memo<UserFieldProps>(
	({ user, fieldKey, isEditable, onUpdate, ...fieldProps }: UserFieldProps) => {
		const { formatMessage } = useIntl();
		const currUser = getUserProps(user);

		const onChangeFieldValue = useCallback(
			(userOption: UserOptionValue) => {
				// ESC
				if (typeof userOption === 'string') {
					return;
				}

				// Value update
				if (userOption && userOption?.id !== currUser?.id) {
					const { id, name, avatarUrl } = userOption;
					onUpdate({ id, name, avatarUrl });
				}
			},
			[currUser, onUpdate],
		);

		if (fieldKey === REPORTER_FIELDKEY) {
			return (
				<ReporterContainer>
					<ReporterEdit
						fieldId={fieldKey}
						// @ts-expect-error TS2322: Type (userOption: UserOptionValue) => void is not assignable to type (arg1: UserOption | UserOption[]) => void
						onChange={onChangeFieldValue}
						value={currUser}
						isDisabled={!isEditable}
						fetchSuggestions={(query) => getUsers(query).then((users) => users)}
						isClearable={false}
					/>
				</ReporterContainer>
			);
		}
		return (
			<Container>
				<UserPickerView
					{...fieldProps}
					isDisabled={!isEditable}
					onChange={onChangeFieldValue}
					value={currUser}
					placeholder={formatMessage(messages.placeholder)}
					fieldId={fieldKey}
					fetchSuggestions={(query) => getUsers(query).then((users) => users)}
				/>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fabric-user-picker__menu': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: layers.modal,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReporterContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fabric-user-picker__control': {
		minHeight: '40px',
	},
});

import React, { useMemo, useState, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type { ValueRule } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import type { FieldDecoratorProps } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/types.tsx';
import { useCallbacks } from '../../../../controllers/selectors/callback-hooks.tsx';
import { useOuterSpacing } from '../../../../controllers/selectors/config-hooks.tsx';
import { useDecorations } from '../../../../controllers/selectors/decoration-hooks.tsx';
import { DecoratorDateTimeItem } from './item/index.tsx';
import messages from './messages.tsx';

type DecoratorItemsProps = {
	FieldDecorator: ComponentType<FieldDecoratorProps>;
	decorationConfigHeader?: React.JSX.Element;
	readonly?: boolean;
};

export const DecoratorDateTimeItems = ({
	FieldDecorator,
	decorationConfigHeader,
	readonly = false,
}: DecoratorItemsProps) => {
	const { formatMessage } = useIntl();
	const [openItem, setOpenItem] = useState<unknown>(undefined);
	const { onValueDecorationCreated } = useCallbacks();
	const outerSpacing = useOuterSpacing();
	const sortedDecorations = useDecorations();

	const onAddDecoration = async (rules: ValueRule[]) => {
		const localDecorationId = await onValueDecorationCreated?.({
			backgroundColor: undefined,
			emoji: undefined,
			highlightContainer: false,
			rules,
		});
		if (localDecorationId) setOpenItem(localDecorationId);
	};

	const availableOptions = useMemo(() => {
		const initialValue = '0';
		const allOptions: {
			label: string;
			rules: ValueRule[];
		}[] = [
			{
				label: formatMessage(messages.dateComingSoon),
				rules: [
					{ value: initialValue, operator: ValueRuleOperator.GTE },
					{ value: initialValue, operator: ValueRuleOperator.LT },
				],
			},
			{
				label: formatMessage(messages.dateOverdue),
				rules: [{ value: initialValue, operator: ValueRuleOperator.LT }],
			},
		];

		return allOptions.filter(
			(option) =>
				!sortedDecorations.some((decoration) =>
					isDecorationWithLogic(decoration)
						? false // TODO: Handle Decoration Logic
						: decoration.rules.length === option.rules.length,
				),
		);
	}, [sortedDecorations, formatMessage]);

	const isReadonlyWithoutFormatting = readonly && !sortedDecorations.length;

	return (
		<>
			{decorationConfigHeader}
			{isReadonlyWithoutFormatting && (
				<Text
					testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.datetime-decoration.no-formatting"
					size="small"
				>
					{formatMessage(messages.noFormatting)}
				</Text>
			)}
			<ItemsContainer>
				<DecoratorDateTimeWrapper outerSpacing={outerSpacing}>
					{sortedDecorations.map((decoration) => (
						<DecoratorDateTimeItem
							key={decoration.localDecorationId}
							FieldDecorator={FieldDecorator}
							decoration={decoration}
							isOpen={openItem === decoration.localDecorationId}
							onOpenChanged={(isOpen) =>
								isOpen ? setOpenItem(decoration.localDecorationId) : setOpenItem(undefined)
							}
							readonly={readonly}
							outerSpacing={outerSpacing}
						/>
					))}
				</DecoratorDateTimeWrapper>
				{!readonly && availableOptions.length > 0 && (
					<AddRuleContainer>
						<DropdownMenu
							trigger={({ triggerRef, ...triggerProps }) => (
								<AddButton
									{...triggerProps}
									ref={triggerRef}
									id="polaris.common.src.ui.config.fields.common.rollup-formula.item.add-input-button"
									iconBefore={
										<EditorAddIcon
											label={formatMessage(messages.addDecorationRule)}
											size="medium"
										/>
									}
								>
									{formatMessage(messages.addDecorationRule)}
								</AddButton>
							)}
						>
							<DropdownItemGroup>
								<DropdownItemGroup>
									{availableOptions.map(({ label, rules }, index) => (
										<DropdownItem key={index} onClick={() => onAddDecoration(rules)}>
											{label}
										</DropdownItem>
									))}
								</DropdownItemGroup>
							</DropdownItemGroup>
						</DropdownMenu>
					</AddRuleContainer>
				)}
			</ItemsContainer>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemsContainer = styled.div({
	overflow: 'visible',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddRuleContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DecoratorDateTimeWrapper = styled.div<{ outerSpacing: string }>({
	paddingBottom: token('space.100', '0.5rem'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) => `0 -${props.outerSpacing}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddButton = styled(Button)({
	marginBottom: '0.75rem',
});

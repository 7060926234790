// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See closest README.md

import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
import srcDark from '../../assets/no-restriction-pencil-darkmode.svg';
import srcLight from '../../assets/no-restriction-pencil.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
// view box should be automatically set by svg values
const viewBox: SVGViewBox = [0, 0, 229, 211];

export { viewBox };

import React, { memo, useCallback, type ReactNode } from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ColumnId } from '../../../common/types/index.tsx';
import { DraggableColumn } from '../../../common/ui/dnd-column/index.tsx';
import { useListActions } from '../../../controllers/index.tsx';
import { useColumnDraggingInfo } from '../../../controllers/selectors/ui-hooks.tsx';

type ColumnDndProps = {
	columnId: ColumnId;
	children: ReactNode;
};

export const ColumnDnd = memo<ColumnDndProps>(({ columnId, children }: ColumnDndProps) => {
	const { setColumnDraggingInfo, rankColumns, resetHorizontalScroll } = useListActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { columnId: draggingColumnId } = useColumnDraggingInfo();

	const onDndGeneratePreview = useCallback(() => {
		// Reset horizontal scroll position if the column is fixed
		resetHorizontalScroll(columnId, true);
	}, [columnId, resetHorizontalScroll]);

	const onDndStart = useCallback(
		(initialOffsetLeft: number) => {
			setColumnDraggingInfo({ columnId, delta: 0, initialOffsetLeft });
		},
		[columnId, setColumnDraggingInfo],
	);

	const onDndMove = useCallback(
		(delta?: number) => {
			setColumnDraggingInfo({ delta });
		},
		[setColumnDraggingInfo],
	);

	const onDndEnd = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'dragged', actionSubject: 'tableColumn' }),
			'field',
			{
				issueFieldKey: columnId,
			},
		);

		setColumnDraggingInfo({ initialOffsetLeft: null, delta: null, columnId: null });
	}, [setColumnDraggingInfo, createAnalyticsEvent, columnId]);

	const onDrop = useCallback(rankColumns, [rankColumns]);

	return (
		<DraggableColumn
			isDraggingActive={!!draggingColumnId}
			id={columnId}
			onDndGeneratePreview={onDndGeneratePreview}
			onDndStart={onDndStart}
			onDndMove={onDndMove}
			onDndEnd={onDndEnd}
			onDrop={onDrop}
		>
			{children}
		</DraggableColumn>
	);
});

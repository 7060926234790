import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import { createHook } from '@atlassian/react-sweet-state';
import { FieldStore } from '../main.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const EMPTY = [] as SnippetProvider[];

export const useSnippetProviders = createHook(FieldStore, {
	selector: (state) => state.snippetProviders || EMPTY,
});

import React from 'react';
import { type NoticeProps, components } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import type { ConnectionFieldOption } from '../../types.tsx';
import messages from './messages.tsx';

export const NoOptionsMessage = <IsMulti extends boolean>(
	props: NoticeProps<ConnectionFieldOption, IsMulti>,
) => {
	const { formatMessage } = useIntl();
	return (
		<components.NoOptionsMessage {...props}>
			{formatMessage(messages.noOptionsMessageNonFinal)}
		</components.NoOptionsMessage>
	);
};

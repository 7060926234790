import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	expand: {
		id: 'polaris-issue-types-manager.expand',
		defaultMessage: 'Expand',
		description: 'Label on the section expanding button',
	},
	collapse: {
		id: 'polaris-issue-types-manager.collapse',
		defaultMessage: 'Collapse',
		description: 'Label on the section collapsing button',
	},
	name: {
		id: 'polaris-issue-types-manager.name',
		defaultMessage: 'Name',
		description: 'Laber for the name field in the issue type form',
	},
	nameValidationErrorNoEmptyValue: {
		id: 'polaris-issue-types-manager.name-validation-error-no-empty-value',
		defaultMessage: "Name can't be empty",
		description: 'Validation error for when empty issue type name is provided by the user',
	},
	icon: {
		id: 'polaris-issue-types-manager.icon',
		defaultMessage: 'Icon',
		description: 'Label for the icon field in the issue type form',
	},
	description: {
		id: 'polaris-issue-types-manager.description',
		defaultMessage: 'Description',
		description: 'Label for the description field in the issue type form',
	},
	descriptionPlaceholder: {
		id: 'polaris-issue-types-manager.description-placeholder',
		defaultMessage: 'Let people know when to use this type',
		description: 'Placeholder displayed in the description field in the issue type form',
	},
	change: {
		id: 'polaris-issue-types-manager.change',
		defaultMessage: 'Change',
		description: 'Label for the button opening issue avatar picker',
	},
	cancel: {
		id: 'polaris-issue-types-manager.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the button cancelling the changes made in the issue type form',
	},
	create: {
		id: 'polaris-issue-types-manager.create',
		defaultMessage: 'Create',
		description: 'Label for the button submitting the changes made in the issue type creation form',
	},
	createType: {
		id: 'polaris-component-issue-types-manager.create-type',
		defaultMessage: 'Create type',
		description: 'Label for the button that opens the issue type creation form section',
	},
	newType: {
		id: 'polaris-component-issue-types-manager.new-type',
		defaultMessage: 'New type',
		description: 'Title of the form used to create new issue type',
	},
	deleteType: {
		id: 'polaris-component-issue-types-manager.delete-type	',
		defaultMessage: 'Delete',
		description: 'Label on the button deleting given issue type',
	},
	saveChanges: {
		id: 'polaris-component-issue-types-manager.save-changes',
		defaultMessage: 'Save changes',
		description: 'Label on the button saving changes in the issue type update form',
	},
	requiredSystemTypeLabel: {
		id: 'polaris-component-issue-types-manager.required-system-type-label',
		defaultMessage: 'Required system type',
		description: 'Label displayed in lozenge next to the issue type name',
	},
	savingChanges: {
		id: 'polaris-component-issue-types-manager.saving-changes',
		defaultMessage: 'Saving changes',
		description:
			'Label for the loading indicator displayed next to the field value while saving changes provided by user during inline-edit operation',
	},
});

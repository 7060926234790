import React from 'react';
import { isValid, parseISO } from 'date-fns';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { DATE_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { DecoratedDatetimeComponent } from '../datetime/index.tsx';
import type { DecoratedDateProps } from './types.tsx';

const getFormattedValue = (value: string, locale: Locale, format: string): string | undefined => {
	if (value === undefined) {
		return undefined;
	}

	const date = parseISO(value);

	if (!isValid(date)) {
		return undefined;
	}

	return formatWithLocale(date, format, locale);
};

const getVisibleValue = (datetime: string, locale: Locale) =>
	getFormattedValue(datetime, locale, DATE_FORMAT);

const DecoratedDateInternal = ({
	datetime,
	decoration,
	skipWrap,
	placeholder,
	isCompact,
}: DecoratedDateProps) => {
	const { locale } = useTenantContext();

	const emojiDescription = useEmoji(decoration?.emoji);

	const value = datetime !== undefined ? getVisibleValue(datetime, locale) : undefined;
	const formattedValue = value ? value.toString() : datetime?.toString();
	return (
		<DecoratedDatetimeComponent
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
			value={formattedValue}
			skipWrap={skipWrap}
			placeholder={placeholder}
			isCompact={isCompact}
		/>
	);
};

export const DecoratedDate = (props: DecoratedDateProps) => <DecoratedDateInternal {...props} />;

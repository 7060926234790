import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalBody } from '@atlaskit/modal-dialog';
import Portal from '@atlaskit/portal';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import {
	useCreateWithFilters,
	useSetFilteredLocalIssueId,
} from '../../../controllers/create-with-filters/index.tsx';
import { ActiveFilters } from './active-filters/index.tsx';
import { Key } from './key/index.tsx';
import messages from './messages.tsx';
import { Summary } from './summary/index.tsx';

export const FilteredIssueModal = () => {
	const { formatMessage } = useIntl();

	const [portalElement, setPortalElement] = useState<HTMLElement | undefined>(undefined);

	const [createWithFilters] = useCreateWithFilters();
	const setFilteredLocalIssue = useSetFilteredLocalIssueId();
	const { filteredLocalIssueId } = createWithFilters;

	const onClose = useCallback(() => setFilteredLocalIssue(undefined), [setFilteredLocalIssue]);

	if (filteredLocalIssueId === undefined) {
		return null;
	}

	return (
		<ModalTransitionUniversal>
			<ShortcutScope>
				<ModalDialog autoFocus={false} onClose={onClose} width="large">
					<ModalBody>
						<ContentContainer>
							<Portal zIndex={9999}>
								{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any */}
								<div ref={setPortalElement as any} />
							</Portal>
							<Wrapper>
								<KeyContainer>
									<Key localIssueId={filteredLocalIssueId} />
								</KeyContainer>
								<Box xcss={summaryContainerStyles}>
									<Summary localIssueId={filteredLocalIssueId} />
								</Box>
								<Description>{formatMessage(messages.description)}</Description>
								<Box xcss={filterContainerStyles}>
									<ActiveFilters
										localIssueId={filteredLocalIssueId}
										portalElement={portalElement}
										onClose={onClose}
									/>
								</Box>
							</Wrapper>
							<CloseButtonContainer>
								<Tooltip content={formatMessage(messages.closeButtonTooltip)}>
									<Button
										appearance="subtle-link"
										iconAfter={
											<EditorCloseIcon
												label={formatMessage(messages.editorCloseIcon)}
												LEGACY_size="large"
											/>
										}
										onClick={onClose}
									/>
								</Tooltip>
							</CloseButtonContainer>
						</ContentContainer>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		</ModalTransitionUniversal>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	flex: '1 1 auto',
	paddingTop: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
	display: 'flex',
	flexDirection: 'column',
	overflow: 'auto',
	width: '100%',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '9px',
	marginLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CloseButtonContainer = styled.div({
	position: 'absolute',
	right: '0px',
	top: '18px',
});

const summaryContainerStyles = xcss({
	marginTop: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	marginLeft: token('space.050', '4px'),
});

const filterContainerStyles = xcss({
	marginTop: 'space.200',
	marginLeft: 'space.050',
});

import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { JiraIcon, JiraProductDiscoveryIcon } from '@atlaskit/logo';
import Popup from '@atlaskit/popup';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { DN800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { RoundedButton } from '@atlassian/jira-polaris-lib-rounded-button/src/ui/index.tsx';
import messages from './messages.tsx';

export const Explanation = () => {
	const [isExplanationOpen, setExplanationOpen] = useState(false);
	const { formatMessage } = useIntl();

	return (
		<Popup
			id="field-explanation"
			isOpen={isExplanationOpen}
			onClose={() => setExplanationOpen(false)}
			placement="bottom-end"
			content={() => (
				<Box xcss={popupContentStyles}>
					<Stack space="space.300">
						<Stack space="space.100">
							<Box xcss={nonInteractiveButtonContainerStyles}>
								<RoundedButton
									isSelected
									iconBefore={<JiraProductDiscoveryIcon appearance="brand" size="small" />}
								>
									{formatMessage(messages.discoveryFields)}
								</RoundedButton>
							</Box>
							<Text>{formatMessage(messages.discoveryFieldsExplanation)}</Text>
						</Stack>
						<Stack space="space.100">
							<Box xcss={nonInteractiveButtonContainerStyles}>
								<RoundedButton isSelected iconBefore={<JiraIcon appearance="brand" size="small" />}>
									{formatMessage(messages.jiraFields)}
								</RoundedButton>
							</Box>
							<Text>{formatMessage(messages.jiraFieldsExplanation)}</Text>
						</Stack>
					</Stack>
				</Box>
			)}
			trigger={(triggerProps) => (
				<IconButton
					{...triggerProps}
					appearance="subtle"
					isSelected={isExplanationOpen}
					aria-label={formatMessage(messages.fieldsExplained)}
					onClick={() => setExplanationOpen(!isExplanationOpen)}
					iconAfter={<InfoIcon label="" size="small" primaryColor={token('color.icon', DN800)} />}
				/>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconButton = styled(Button)({
	width: token('space.400', '32px'),
});

const nonInteractiveButtonContainerStyles = xcss({
	pointerEvents: 'none',
	cursor: 'default',
});

const popupContentStyles = xcss({
	width: '294px',
	padding: 'space.250',
});

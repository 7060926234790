import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	merge: {
		id: 'polaris-common.merge-ideas-dialog.merge',
		defaultMessage: 'Merge',
		description: 'Button label for merging a selected ideas',
	},
	cancel: {
		id: 'polaris-common.merge-ideas-dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for cancel the merge-operation',
	},
	targetIdeaTitle: {
		id: 'polaris-common.merge-ideas-dialog.target-idea-title',
		defaultMessage: 'Target idea',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeMenuTitle: {
		id: 'polaris-common.merge-ideas-dialog.merge-menu-title',
		defaultMessage: 'Content to merge',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeDataPointsOption: {
		id: 'polaris-common.merge-ideas-dialog.merge-data-points-option',
		defaultMessage: 'Captured insights',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeAttachmentsOption: {
		id: 'polaris-common.merge-ideas-dialog.merge-attachments-option',
		defaultMessage: 'Attachments',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeLinkedDeliveryIssuesOption: {
		id: 'polaris-common.merge-ideas-dialog.merge-linked-delivery-issues-option',
		defaultMessage: 'Linked delivery issues',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeLinkedDeliveryIssuesOptionIssueTermRefresh: {
		id: 'polaris-common.merge-ideas-dialog.merge-linked-delivery-issues-option-issue-term-refresh',
		defaultMessage: 'Linked delivery work items',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeDescription: {
		id: 'polaris-common.merge-ideas-dialog.merge-description',
		defaultMessage: 'Keep the description from',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeIssueFields: {
		id: 'polaris-common.merge-ideas-dialog.merge-issue-fields',
		defaultMessage: 'Keep the field values from',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	mergeHeader: {
		id: 'polaris-common.merge-ideas-dialog.merge-header',
		defaultMessage: 'Merge ideas',
		description: 'Header caption in polaris merge ideas confirmation dialog',
	},
	description: {
		id: 'polaris-common.merge-ideas-dialog.description',
		defaultMessage:
			'This will copy content from the selected ideas to the “target idea” you specify below, and then archive those ideas. You will still be able to view them in the archive, but they won’t show in lists or boards.',
		description: 'Description in polaris merge view confirmation dialog',
	},
	issuePickerPlaceholder: {
		id: 'polaris-common.merge-ideas-dialog.issue-picker-placeholder',
		defaultMessage: 'Search for an idea to merge into',
		description: 'Issue picker placeholder in merge ideas dialog',
	},
});

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import IssueFieldTeam from '@atlassian/jira-issue-field-team/src/ui/main.tsx';
import {
	createIssueAri,
	createIssueFieldValueAriWithFieldKey,
} from '@atlassian/jira-polaris-common/src/common/utils/ari/index.tsx';
import {
	useLocalIssueIdToJiraIssueKeyMapper,
	useLocalIssueIdToJiraIssueIdMapper,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useTeamValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { createUseTeamFieldOverride } from '../../../../../../common/utils/create-use-team-field-override.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';

type Props = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const TeamField = ({ localIssueId, fieldKey }: Props) => {
	const cloudId = useCloudId();
	const isEditable = useIsEditable(fieldKey);
	const value = useTeamValue(fieldKey, localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const getIssueKeyFromLocalIssueId = useLocalIssueIdToJiraIssueKeyMapper();
	const getJiraIssueIdFromLocalIssueId = useLocalIssueIdToJiraIssueIdMapper();

	const issueKey = getIssueKeyFromLocalIssueId(localIssueId);
	const jiraIssueId = getJiraIssueIdFromLocalIssueId(localIssueId);

	if (!issueKey || !jiraIssueId) {
		return null;
	}

	const issueAri = createIssueAri(cloudId, jiraIssueId.toString());
	const fieldAri = createIssueFieldValueAriWithFieldKey(cloudId, jiraIssueId.toString(), fieldKey);

	return (
		<Box xcss={containerStyles}>
			<IssueFieldTeam
				issueKey={issueKey}
				issueId={issueAri}
				fieldKey={fieldAri}
				readView={<TeamValue id={value} name={value} displayPreview />}
				isFitContainerWidthReadView
				isCompact
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={document.body}
				useTeamField={createUseTeamFieldOverride({
					issueKey,
					fieldKey,
					canEditIssues: isEditable,
					initialValue: value,
					updateFieldValue: onUpdate,
				})}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	marginLeft: 'space.100',
	marginRight: 'space.negative.100',
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	copiedMessage: {
		id: 'polaris-ideas.settings.forms.copied-message',
		defaultMessage: 'URL copied to clipboard',
		description: 'Message for success after copying the sharing URL.',
	},
	copiedTitle: {
		id: 'polaris-ideas.settings.forms.copied-title',
		defaultMessage: 'Copied',
		description: 'Title for success after copying the sharing URL.',
	},
	copyFailMessage: {
		id: 'polaris-ideas.settings.forms.copy-fail-message',
		defaultMessage: 'Failed to copy URL to clipboard',
		description: 'Message for fail after copying the sharing URL.',
	},
	copyFailTitle: {
		id: 'polaris-ideas.settings.forms.copy-fail-title',
		defaultMessage: 'Error',
		description: 'Title for fail after copying the sharing URL.',
	},
});

import React, { useMemo } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { chromeIcon } from '../../../../common/ui/apps/index.tsx';
import type { AppPreset } from '../types.tsx';
import messages from './messages.tsx';

export const CHROME_KEY = 'chrome';

const ChromeExtensionInstallAction = () => {
	const { formatMessage } = useIntl();

	return (
		<a
			href="https://chrome.google.com/webstore/detail/polaris/flacajjkcnlngpeapmnmignmjgddamah"
			target="_blank"
		>
			<Button spacing="none" appearance="link">
				{formatMessage(messages.installItNow)}
			</Button>
		</a>
	);
};

export const useChromePreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: CHROME_KEY,
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: chromeIcon,
			isRecommended: true,
			Action: ChromeExtensionInstallAction,
		}),
		[formatMessage],
	);
};

import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useDateDecoration } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { DecoratedDate } from '@atlassian/jira-polaris-common/src/ui/decoration/date/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const DateColumnHeader = ({ fieldKey, groupIdentity }: ColumnHeaderComponentProps) => {
	const decoration = useDateDecoration(fieldKey, groupIdentity);
	return <DecoratedDate datetime={groupIdentity} decoration={decoration} />;
};

const EmptyDateColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const dateColumnHeaderConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: DateColumnHeader,
	EmptyComponent: EmptyDateColumnHeader,
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoValueContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
});

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentViewVerticalGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { Header } from '../column-header/main.tsx';
import { assertNonNullable } from '../utils/types.tsx';
import { messages } from './messages.tsx';

export const SwimlaneHeader: BoardProps['components']['SwimlaneHeader'] = ({
	groupIdentity,
	isReadOnly,
	cardsCount,
	onMenuOpen,
}) => {
	const { formatMessage } = useIntl();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	assertNonNullable(verticalGroupByField);

	return (
		<Header
			isRow
			isReadOnly={isReadOnly}
			fieldKey={verticalGroupByField.key}
			groupIdentity={groupIdentity}
			elemAfter={
				cardsCount ? (
					<Box xcss={issueCountContainerStyles}>
						{formatMessage(messages.issuesCountLabel, {
							issuesCount: cardsCount,
						})}
					</Box>
				) : null
			}
			onMenuOpen={onMenuOpen}
		/>
	);
};

const issueCountContainerStyles = xcss({
	marginLeft: 'space.150',
	color: 'color.text.subtle',
});

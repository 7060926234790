import React, { useCallback, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import UFOSegment from '@atlaskit/react-ufo/segment';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { ModalTransitionUniversal } from '../../common/ui/modal-transition/index.tsx';
import {
	useIsMergeEnabled,
	useMergeIdeasDialogState,
	useMergeIdeasDialogActions,
} from '../../controllers/merge-ideas-dialog/index.tsx';
import {
	DataPointsMergeOption,
	DescriptionMergeFromOption,
	FieldsMergeFromOption,
	LinkedDeliveryIssuesMergeOption,
	AttachmentsMergeOption,
} from '../../controllers/merge-ideas-dialog/types.tsx';
import { IssueSelect } from '../common/issue-select/main.tsx';
import { BaseOption as IssuePickerBaseOption } from '../common/issue-select/option/index.tsx';
import type { IssueOption } from '../common/issue-select/types.tsx';
import { useDefaultOptions, useExcludedProjectTypes } from '../common/issue-select/utils.tsx';
import messages from './messages.tsx';
import { useExcludedIssueIds } from './utils.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useMergeNotifications } from './notifications';

export const MergeIdeasModal = () => {
	const isMergeEnabled = useIsMergeEnabled();
	const { isOpen, isSaving, targetIssue, selectedIssues, mergeOptions, mergeFromOptions } =
		useMergeIdeasDialogState();
	const {
		toggleMergeIdeasDialog,
		mergeIdeas,
		toggleMergeOption,
		setTargetIssue,
		setMergeFromOption,
	} = useMergeIdeasDialogActions();
	const { formatMessage } = useIntl();

	const defaultOptions = useDefaultOptions();
	const excludedIssueIds = useExcludedIssueIds(selectedIssues);
	const excludedProjectTypes = useExcludedProjectTypes();

	const handleIssueSelected = useCallback(
		(selectedIssue: IssueOption | undefined) =>
			selectedIssue?.item?.id !== undefined
				? setTargetIssue(
						selectedIssue?.item.id,
						selectedIssue?.item.key,
						selectedIssue?.item.summaryText,
						selectedIssue?.item.img,
					)
				: undefined,
		[setTargetIssue],
	);

	return (
		<ModalTransitionUniversal>
			{isOpen && (
				<UFOSegment name="jpd.merge-ideas-dialog">
					<ContextualAnalyticsData sourceName="mergeIdeas" sourceType={MODAL}>
						<ShortcutScope>
							{/* @ts-expect-error TS2769 | TS2769 - No overload matches this call. | No overload matches this call. */}
							<ModalDialog onClose={toggleMergeIdeasDialog}>
								<ModalHeader>
									<ModalTitle>{formatMessage(messages.mergeHeader)}</ModalTitle>
								</ModalHeader>
								<ModalBody>
									<FireScreenAnalytics />
									<Description>{formatMessage(messages.description)}</Description>
									<SectionContainer>
										<IssuePickerTitle>{formatMessage(messages.targetIdeaTitle)}</IssuePickerTitle>
										<div>
											<IssueSelect
												hideArchived={!fg('jpd-disable-hiding-archived-ideas-in-issue-picker')}
												defaultOptions={defaultOptions}
												projectId={undefined}
												autoFocus
												isAttachedToBody
												placeholder={formatMessage(messages.issuePickerPlaceholder)}
												isDisabled={isSaving}
												excludedIssueIds={excludedIssueIds}
												excludedProjectTypes={excludedProjectTypes}
												onIssueSelected={handleIssueSelected}
											/>
										</div>
									</SectionContainer>
									<SectionContainer>
										<MenuTitle>{formatMessage(messages.mergeMenuTitle)}</MenuTitle>
										<MenuOption>
											<Checkbox
												isDisabled={isSaving}
												defaultChecked={mergeOptions[DataPointsMergeOption]}
												label={formatMessage(messages.mergeDataPointsOption)}
												value={Number(mergeOptions[DataPointsMergeOption])}
												onChange={() => toggleMergeOption(DataPointsMergeOption)}
											/>
										</MenuOption>
										<MenuOption>
											<Checkbox
												isDisabled={isSaving}
												defaultChecked={mergeOptions[AttachmentsMergeOption]}
												label={formatMessage(messages.mergeAttachmentsOption)}
												value={Number(mergeOptions[AttachmentsMergeOption])}
												onChange={() => toggleMergeOption(AttachmentsMergeOption)}
											/>
										</MenuOption>
										<MenuOption>
											<Checkbox
												isDisabled={isSaving}
												defaultChecked={mergeOptions[LinkedDeliveryIssuesMergeOption]}
												label={formatMessage(messages.mergeLinkedDeliveryIssuesOption)}
												value={Number(mergeOptions[LinkedDeliveryIssuesMergeOption])}
												onChange={() => toggleMergeOption(LinkedDeliveryIssuesMergeOption)}
											/>
										</MenuOption>
									</SectionContainer>
									<SectionContainer>
										<IssuePickerHorizontalContainer>
											<IssuePickerHorizontalLabel>
												{formatMessage(messages.mergeDescription)}
											</IssuePickerHorizontalLabel>
											<IssuePickerHorizontalInputWrapper>
												<DropdownMenu
													placement="bottom"
													shouldFlip
													trigger={({ triggerRef, ...triggerProps }) => (
														<Button
															{...triggerProps}
															isDisabled={isSaving}
															shouldFitContainer
															iconAfter={<ChevronDownIcon label="" />}
															ref={triggerRef}
														>
															{mergeFromOptions[DescriptionMergeFromOption] ? (
																<IssuePickerBaseOption
																	isOptionSelected
																	innerProps={null}
																	issueKey={mergeFromOptions[DescriptionMergeFromOption].key}
																	img={
																		/* eslint-disable @typescript-eslint/consistent-type-assertions */
																		(
																			mergeFromOptions[
																				DescriptionMergeFromOption
																				// eslint-disable-next-line @typescript-eslint/no-explicit-any
																			] as any
																		).fieldsForUpdate &&
																		(
																			mergeFromOptions[
																				DescriptionMergeFromOption
																				// eslint-disable-next-line @typescript-eslint/no-explicit-any
																			] as any
																		).issuetype
																			? /* eslint-enable @typescript-eslint/consistent-type-assertions */
																				mergeFromOptions[
																					DescriptionMergeFromOption
																					// @ts-expect-error - TS2339 - Property 'issuetype' does not exist on type 'IssueForMerge | TargetIssue'.
																				].issuetype.iconUrl
																			: mergeFromOptions[
																					DescriptionMergeFromOption
																					// @ts-expect-error - TS2339 - Property 'icon' does not exist on type 'IssueForMerge | TargetIssue'.
																				].icon
																	}
																	summary={mergeFromOptions[DescriptionMergeFromOption].summary}
																	hasHoverEffects={false}
																/>
															) : (
																<span />
															)}
														</Button>
													)}
												>
													<DropdownItemGroup>
														{targetIssue ? (
															<DropdownItem
																shouldTitleWrap={false}
																onClick={() => {
																	if (isSaving) {
																		return;
																	}
																	setMergeFromOption(DescriptionMergeFromOption, targetIssue);
																}}
															>
																<IssuePickerBaseOption
																	innerProps={null}
																	issueKey={targetIssue.key}
																	img={targetIssue.icon}
																	summary={targetIssue.summary}
																	hasHoverEffects={false}
																/>
															</DropdownItem>
														) : null}
														{selectedIssues.map((selectedIssue, key) => (
															<DropdownItem
																shouldTitleWrap={false}
																key={key}
																onClick={() => {
																	if (isSaving) {
																		return;
																	}
																	setMergeFromOption(DescriptionMergeFromOption, selectedIssue);
																}}
															>
																<IssuePickerBaseOption
																	innerProps={null}
																	issueKey={selectedIssue.key}
																	img={selectedIssue.issuetype.iconUrl}
																	summary={selectedIssue.summary}
																	hasHoverEffects={false}
																/>
															</DropdownItem>
														))}
													</DropdownItemGroup>
												</DropdownMenu>
											</IssuePickerHorizontalInputWrapper>
										</IssuePickerHorizontalContainer>
										<IssuePickerHorizontalContainer>
											<IssuePickerHorizontalLabel>
												{formatMessage(messages.mergeIssueFields)}
											</IssuePickerHorizontalLabel>
											<IssuePickerHorizontalInputWrapper>
												<DropdownMenu
													placement="bottom"
													shouldFlip
													trigger={({ triggerRef, ...triggerProps }) => (
														<Button
															{...triggerProps}
															isDisabled={isSaving}
															shouldFitContainer
															iconAfter={<ChevronDownIcon label="" />}
															ref={triggerRef}
														>
															{mergeFromOptions[FieldsMergeFromOption] ? (
																<IssuePickerBaseOption
																	isOptionSelected
																	innerProps={null}
																	issueKey={mergeFromOptions[FieldsMergeFromOption].key}
																	img={
																		/* eslint-disable @typescript-eslint/consistent-type-assertions */
																		(
																			mergeFromOptions[
																				FieldsMergeFromOption
																				// eslint-disable-next-line @typescript-eslint/no-explicit-any
																			] as any
																		).fieldsForUpdate &&
																		(
																			mergeFromOptions[
																				FieldsMergeFromOption
																				// eslint-disable-next-line @typescript-eslint/no-explicit-any
																			] as any
																		).issuetype
																			? (
																					mergeFromOptions[
																						FieldsMergeFromOption
																						// eslint-disable-next-line @typescript-eslint/no-explicit-any
																					] as any
																				).issuetype.iconUrl
																			: (
																					mergeFromOptions[
																						FieldsMergeFromOption
																						// eslint-disable-next-line @typescript-eslint/no-explicit-any
																					] as any
																				).icon
																		/* eslint-enable @typescript-eslint/consistent-type-assertions */
																	}
																	summary={mergeFromOptions[FieldsMergeFromOption].summary}
																	hasHoverEffects={false}
																/>
															) : (
																<span />
															)}
														</Button>
													)}
												>
													<DropdownItemGroup>
														{targetIssue ? (
															<DropdownItem
																shouldTitleWrap={false}
																onClick={() => {
																	if (isSaving) {
																		return;
																	}
																	setMergeFromOption(FieldsMergeFromOption, targetIssue);
																}}
															>
																<IssuePickerBaseOption
																	innerProps={null}
																	issueKey={targetIssue.key}
																	img={targetIssue.icon}
																	summary={targetIssue.summary}
																	hasHoverEffects={false}
																/>
															</DropdownItem>
														) : null}
														{selectedIssues.map((selectedIssue, key) => (
															<DropdownItem
																shouldTitleWrap={false}
																key={key}
																onClick={() => {
																	if (isSaving) {
																		return;
																	}
																	setMergeFromOption(FieldsMergeFromOption, selectedIssue);
																}}
															>
																<IssuePickerBaseOption
																	innerProps={null}
																	issueKey={selectedIssue.key}
																	img={selectedIssue.issuetype.iconUrl}
																	summary={selectedIssue.summary}
																	hasHoverEffects={false}
																/>
															</DropdownItem>
														))}
													</DropdownItemGroup>
												</DropdownMenu>
											</IssuePickerHorizontalInputWrapper>
										</IssuePickerHorizontalContainer>
									</SectionContainer>
								</ModalBody>
								<ModalFooter>
									<Button
										id="polaris-ideas.ui.merge-dialog.merge-button"
										appearance="primary"
										isDisabled={!isMergeEnabled || isSaving}
										onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
											fireUIAnalytics(analyticsEvent, 'merge');
											mergeIdeas();
										}}
									>
										{isSaving ? <Spinner size="small" /> : formatMessage(messages.merge)}
									</Button>
									<Button
										isDisabled={isSaving}
										onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
											fireUIAnalytics(analyticsEvent, 'cancel');
											toggleMergeIdeasDialog();
										}}
									>
										{formatMessage(messages.cancel)}
									</Button>
								</ModalFooter>
							</ModalDialog>
						</ShortcutScope>
					</ContextualAnalyticsData>
				</UFOSegment>
			)}
		</ModalTransitionUniversal>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuTitle = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	marginBottom: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuOption = styled.div({
	marginBottom: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssuePickerTitle = styled(MenuTitle)({
	marginBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssuePickerHorizontalLabel = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '10px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N800),
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssuePickerHorizontalInputWrapper = styled.div({
	flex: 1,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssuePickerHorizontalContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child': {
		marginBottom: token('space.200', '16px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionContainer = styled.div({
	marginTop: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

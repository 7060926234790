import type { ReactNode } from 'react';
import type { EmojiDescription } from '@atlaskit/emoji';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type {
	LocalDecorationId,
	ValueDecoration,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { DeliveryDateAggregationType } from '@atlassian/jira-polaris-domain-field/src/delivery/types.tsx';
import type { WeightType } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { IntervalFieldSource } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { DisplayMode } from '@atlassian/jira-polaris-domain-field/src/field/people/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { FieldData } from '../common/types.tsx';

export const DEFAULT_OPTION_WEIGHT = 1;

export type State = {
	fieldData: FieldData | undefined;
	canEditField: boolean;
	decorationsComponents: Partial<Record<FieldType, ReactNode>>;
	notEditableReason?: {
		label: string;
		tooltip: string;
	};
	optionsLimitCount?: number | null;
	outerSpacing: string;
	projectId?: string;
	projectKey?: string;
	readonly: boolean;
	isPreview?: boolean;
	isDescriptionEditorAiEnabled: boolean;
	defaultOpenSidebarOptionId?: string;

	components: {
		formulaContent?: ReactNode;
		renderConfigSection?: (children: ReactNode) => ReactNode;
	};

	formulaFieldData: {
		fieldsByKey: FieldsByKey;
	};

	callbacks: {
		onValidateFieldName?: (value?: string) => string | undefined;
		onUpdateFieldName?: (value?: string) => Promise<boolean | Error>;
		onUpdateDescription?: (description?: string) => Promise<void>;
		onUpdateEmoji?: (emoji: EmojiDescription | undefined) => Promise<void>;
		onValueDecorationCreated?: (props: CreateValueDecorationProps) => Promise<string>;
		onMultipleValueDecorationsCreated?: (props: CreateValueDecorationProps[]) => Promise<string[]>;
		onValueDecorationUpdated?: (props: ValueDecoration) => Promise<void>;
		onValueDecorationDeleted?: (props: DeleteValueDecorationProps) => Promise<void>;
		onAllValueDecorationsDeleted?: () => Promise<void>;
		// field options
		onOptionOpened?: (id: string | null) => void;
		onOptionAdded?: (value: string) => Promise<string | undefined>;
		onOptionDeleted?: (optionId: string) => Promise<void>;
		onOptionRenamed?: (optionId: string, value: string) => Promise<void>;
		onOptionsReordered?: (optionIds: string[]) => Promise<void>;
		onOptionsSearch?: () => void;
		onFieldOptionWeightUpdated?: (optionId: string, weight: number) => Promise<void>;
		onFieldOptionWeightTypeUpdated?: (weightType: WeightType | undefined) => Promise<void>;
		onFieldOptionAutoFormattingUpdated?: (value: boolean) => Promise<void>;
		onIntervalFieldSourceUpdated?: (
			source: IntervalFieldSource | undefined,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		onDeliveryDateSourceFieldUpdated?: (
			selectedFieldKey: string | undefined,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		onDeliveryDateAggregationTypeUpdated?: (
			aggregationType: DeliveryDateAggregationType,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		onDeliveryDateConfigurationComplete?: () => void;
		onFieldPeopleDisplayModeUpdated?: (displayMode: DisplayMode) => Promise<void>;
	};

	containerProps: Props | undefined;
};

export type OuterSpacing = '0' | `${number}${'px' | 'rem' | 'em' | 'vw' | 'vh' | '%'}`;

export type Props = {
	fieldData: FieldData;
	canEditField: boolean;
	/**
	 * Overrides the default components for the field decorations by field type.
	 */
	decorationsComponents?: Partial<Record<FieldType, ReactNode>>;
	notEditableReason?: {
		label: string;
		tooltip: string;
	};
	/**
	 * The maximum number of options that can be added to the field.
	 */
	optionsLimitCount?: number | null;
	/**
	 * A field config item is usually rendered within a box that has padding
	 * (outer spacing). Some field decorations overflow beyond the boundaries of
	 * this box. Because the outer spacing can vary depending on where
	 * it's rendered, this value is configurable.
	 *
	 * Any valid CSS size value that can be turned into a negative one
	 * with adding `-` sign.
	 * @example "16px"
	 * @example "1rem"
	 */
	outerSpacing?: OuterSpacing;
	projectId?: string;
	projectKey?: string;
	readonly?: boolean;
	/**
	 * Rendered as a preview in a hover tooltip
	 */
	isPreview?: boolean;

	// Temporary components. This should be removed once we migrate those
	// components into field-config-item component.
	formulaContent?: ReactNode;
	renderConfigSection?: (children: ReactNode) => ReactNode;

	// Prop to enable/disable the GenAI in description editor
	isDescriptionEditorAiEnabled?: boolean;

	defaultOpenSidebarOptionId?: string;

	// add callbacks here
	onHandleCalculatedFieldUpdate?: (updatedFormula: DynamicFieldFormula) => Promise<void>;
	onHandleCalculatedFieldLabelUpdate?: (updatedLabel: string) => boolean;
	onValidateFieldName?: (value?: string) => string | undefined;
	onUpdateFieldName?: (value?: string) => Promise<boolean | Error>;
	onUpdateDescription?: (description?: string) => Promise<void>;
	onUpdateEmoji?: (emoji: EmojiDescription | undefined) => Promise<void>;
	onChangeWeighting?: (weightType: WeightType | undefined) => Promise<void>;
	onValueDecorationCreated?: (props: CreateValueDecorationProps) => Promise<string>;
	onMultipleValueDecorationsCreated?: (props: CreateValueDecorationProps[]) => Promise<string[]>;
	onValueDecorationUpdated?: (props: ValueDecoration) => Promise<void>;
	onValueDecorationDeleted?: (props: DeleteValueDecorationProps) => Promise<void>;
	onAllValueDecorationsDeleted?: () => Promise<void>;
	onOptionOpened?: (id: string | null) => void;
	onOptionAdded?: (value: string) => Promise<string | undefined>;
	onOptionDeleted?: (optionId: string) => Promise<void>;
	onOptionRenamed?: (optionId: string, value: string) => Promise<void>;
	onOptionsReordered?: (optionIds: string[]) => Promise<void>;
	onOptionsSearch?: () => void;
	onFieldOptionWeightUpdated?: (optionId: string, weight: number) => Promise<void>;
	onFieldOptionWeightTypeUpdated?: (weightType: WeightType | undefined) => Promise<void>;
	onFieldOptionAutoFormattingUpdated?: (value: boolean) => Promise<void>;
	onIntervalFieldSourceUpdated?: (
		source: IntervalFieldSource | undefined,
		analyticsEvent: UIAnalyticsEvent,
	) => Promise<void>;
	onDeliveryDateSourceFieldUpdated?: (
		selectedFieldKey: string | undefined,
		analyticsEvent: UIAnalyticsEvent,
	) => Promise<void>;
	onDeliveryDateAggregationTypeUpdated?: (
		aggregationType: DeliveryDateAggregationType,
		analyticsEvent: UIAnalyticsEvent,
	) => Promise<void>;
	onDeliveryDateConfigurationComplete?: () => void;
	onFieldPeopleDisplayModeUpdated?: (displayMode: DisplayMode) => Promise<void>;
};

type SimpleUnionOmit<T, K extends string | number | symbol> = T extends unknown
	? Omit<T, K>
	: never;

export type CreateValueDecorationProps = SimpleUnionOmit<
	ValueDecoration,
	'localDecorationId' | 'backendId'
>;

type DeleteValueDecorationProps = {
	localDecorationId: LocalDecorationId;
};

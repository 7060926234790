import omit from 'lodash/omit';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types.tsx';
import { isOptimisticInsight } from '../utils/optimistic-updates.tsx';
import { signalInsightsUpdates } from '../utils/signal.tsx';

export const deleteInsight =
	(
		id: string,
		{
			performSideEffects = true,
			isBulkDeletion = false,
		}: { performSideEffects?: boolean; isBulkDeletion?: boolean } = {},
	) =>
	async ({ getState, setState }: StoreActionApi<State>, containerProps: Props) => {
		const { insightsRemote, onDeleteFailed, createAnalyticsEvent } = containerProps;
		const state = getState();

		if (isOptimisticInsight(id)) {
			return;
		}

		const newState = {
			...state,
			insights: {
				...omit(state.insights, id),
			},
		};

		setState(newState);

		if (performSideEffects) {
			signalInsightsUpdates(newState, containerProps);

			try {
				await insightsRemote.deleteInsight?.({ id });

				fireTrackAnalytics(
					createAnalyticsEvent({
						containers: {
							comment: {
								id,
								type: 'insight',
							},
						},
					}),
					'insight deleted',
					{
						isBulkDeletion,
					},
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				onDeleteFailed(error);
			}
		}
	};

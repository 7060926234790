import React, { type MouseEventHandler } from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { P200, N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type InfoMarkerProps = {
	id?: string;
	marginLeft?: number;
	marginRight?: number;
	isInline?: boolean;
	tooltipContent: TooltipProps['content'];
	onClick?: MouseEventHandler<HTMLDivElement>;
};

export const InfoMarker = ({
	id,
	isInline,
	marginLeft,
	marginRight,
	onClick,
	tooltipContent,
}: InfoMarkerProps) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={tooltipContent}>
			<Container
				id={id}
				marginLeft={marginLeft || 0}
				marginRight={marginRight || 0}
				isInline={isInline}
				clickable={!!onClick}
				onClick={onClick}
			>
				<InfoIcon
					color={token('color.icon.warning')}
					label={formatMessage(messages.warningMessage)}
					LEGACY_primaryColor={P200}
					LEGACY_size="small"
				/>
			</Container>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	marginLeft: string | number;
	marginRight: string | number;
	isInline?: boolean;
	clickable?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ clickable }) =>
		clickable && {
			height: 16,
			borderRadius: 3,
			padding: token('space.050', '4px'),
			':hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered', N20A),
				cursor: 'pointer',
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ marginLeft }) => `${marginLeft}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ marginRight }) => `${marginRight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isInline }) => isInline && 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	verticalAlign: ({ isInline }) => isInline && 'middle',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});

/** @jsx jsx */
import React, { useCallback, useEffect, useRef, type SyntheticEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import MediaServicesScaleLargeIcon from '@atlaskit/icon/glyph/media-services/scale-large';
import { Box, xcss } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { DN0, N0 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { DATETIME_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useViewExportActions } from '../../../../controllers/main.tsx';
import {
	useIsExportingViewImage,
	useIsReadyToExportViewImage,
} from '../../../../controllers/selectors.tsx';
import type { ExportCommonProps } from '../types.tsx';
import messages from './messages.tsx';

const viewContentCss = `
    [data-ds--page-layout--slot="main"],
    #polaris-ideas\\.ui\\.view-content-container {
        width: fit-content !important;
        height: fit-content !important;
    }
`;

const matrixCss = `
    #polaris-ideas\\.ui\\.ideas-bucket-button {
        display: none !important;
    }
    [data-component-selector="matrix-y-axis"] {
        overflow: visible !important;
    }
`;

const listCss = `
    #list-view-table .table__table {
        position: static !important;
    }

    #list-view-table,
    #list-view-table > div,
    #list-view-table .table,
    #list-view-table .table__header,
    #list-view-table .table__header > div,
    #list-view-table .table__body {
        width: unset !important;
        height: unset !important;
    }

    ${viewContentCss}
`;

const getExportCss = (viewKind?: ViewKind) => {
	switch (viewKind) {
		case VIEW_KIND_MATRIX:
			return matrixCss;
		case VIEW_KIND_TABLE:
			return listCss;
		default:
			return viewContentCss;
	}
};

export type ExportViewAsImageProps = ExportCommonProps & {
	viewKind?: ViewKind;
};

export const ExportViewAsImage = ({
	viewTitle,
	containerName,
	viewKind,
	isLoading,
	onExporting,
}: ExportViewAsImageProps) => {
	const { formatMessage } = useIntl();
	const isReadyToExportImage = useIsReadyToExportViewImage();
	const isExportingImage = useIsExportingViewImage();
	const { setIsExportingViewImage, setIsReadyToExportViewImage } = useViewExportActions();
	const { colorMode } = useThemeObserver();
	const [isFullscreen, { setIsFullscreen: setIsFullScreenLayout }] = useIsFullscreen();
	const isFullScreenRef = useRef(isFullscreen);

	const exportAsImageConfig = useCallback(
		(container: HTMLElement) => {
			let currentContainer = container;
			const viewPort = container.getBoundingClientRect();
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
			const columns = document.getElementsByClassName(
				'view-board-column',
			) as HTMLCollectionOf<HTMLElement>;

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const swimlanesContainer = document.getElementById('swimlanes-container');
			if (swimlanesContainer) {
				currentContainer = swimlanesContainer;
			}
			let viewHeight = currentContainer.scrollHeight + viewPort.top;

			// if it'a board
			if (columns.length > 0) {
				const columnsArray = Array.from(columns);

				const maxColumnHeight = Math.max(
					...columnsArray.map((col) => {
						const colElement = col;
						colElement.scrollTop = 0;
						return col.scrollHeight;
					}),
				);

				const diff = maxColumnHeight - columns[0].offsetHeight;

				viewHeight += diff;
			}

			return {
				logging: false,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				scale: window.devicePixelRatio || 2,
				useCORS: true,
				windowHeight: viewHeight,
				windowWidth: currentContainer.scrollWidth + viewPort.left,
				ignoreElements: (element: HTMLElement) => element.classList.contains('hide-from-export'),
				removeContainer: true, // Useful for debugging, set it to false to inspect the iframe.
				onclone: () => {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					const styleTag = document.createElement('style');
					styleTag.textContent = getExportCss(viewKind);
					const html2canvasIframes =
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						document.querySelectorAll<HTMLIFrameElement>('.html2canvas-container');

					html2canvasIframes.forEach((iframe) => {
						const headElement = iframe.contentWindow?.document.head;
						headElement?.appendChild(styleTag);
					});
				},
				backgroundColor: colorMode === 'dark' ? DN0 : N0,
			};
		},
		[colorMode, viewKind],
	);

	const exportAsImage = useCallback(async () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.getElementById('polaris-ideas.ui.view-content-container');
		if (!container) return;
		if (viewTitle === undefined || containerName === undefined) {
			return;
		}

		const canvas = await html2canvas(container, exportAsImageConfig(container));

		canvas.toBlob(
			(blob) => {
				if (blob) {
					saveAs(
						blob,
						`${viewTitle} - ${containerName} ${format(new Date(), DATETIME_FORMAT)}.jpg`,
					);
				}

				setIsReadyToExportViewImage(false);
				setIsExportingViewImage(false);
				onExporting(false);
				if (getWillShowNav4() && !isFullScreenRef.current) {
					setIsFullScreenLayout(false);
				}
			},
			'image/jpeg',
			0.9,
		);
	}, [
		viewTitle,
		containerName,
		exportAsImageConfig,
		setIsExportingViewImage,
		setIsReadyToExportViewImage,
		onExporting,
		setIsFullScreenLayout,
	]);

	const handleClick = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'downloadPNGButton');
			if (getWillShowNav4() && !isFullScreenRef.current) {
				setIsFullScreenLayout(true);
				setTimeout(() => {
					setIsExportingViewImage(true);
					onExporting(true);
				}, 0);
			} else {
				setIsExportingViewImage(true);
				onExporting(true);
			}
		},
		[onExporting, setIsExportingViewImage, setIsFullScreenLayout],
	);

	useEffect(() => {
		if (isReadyToExportImage && isExportingImage) {
			exportAsImage();
		}
	}, [isReadyToExportImage, isExportingImage, exportAsImage]);

	return (
		<div css={buttonWrapperStyles}>
			<Button
				id="pendo.export-modal.export-as-image-button"
				testId="polaris-component-view-export.ui.export-view-dialog.export-dialog-content.export-as-image.button"
				iconBefore={(iconProps) => (
					<MediaServicesScaleLargeIcon
						{...iconProps}
						label={formatMessage(messages.label)}
						size="medium"
					/>
				)}
				appearance="subtle"
				onClick={handleClick}
				isDisabled={isLoading && !isExportingImage}
				isLoading={isLoading && isExportingImage}
				data-component-selector="export-as-image-button-kDn7"
			>
				<Box xcss={messageContainerStyles}>{formatMessage(messages.button)}</Box>
			</Button>
		</div>
	);
};

const messageContainerStyles = xcss({
	textAlign: 'start',
});

const buttonWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="export-as-image-button-kDn7"]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '40px',
	},
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { keyframes, styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import MountAnimator, {
	type MountAnimationProps,
} from '@atlassian/jira-packages-components-animations/src/mount-animator.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useDeliveryTicketsByParent,
	useIsLoadingLinkedIssues,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { STATUS_ONLY } from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress/index.tsx';
import { useCanManageDeliveryTickets } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { deliveryIssueComparator } from '@atlassian/jira-polaris-domain-delivery/src/compare/index.tsx';
import type { StatusCategoryKey } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { LinkedDeliveryIssueItem } from '../../../../../common/ui/linked-delivery-issue-item/index.tsx';
import { ProgressItem } from '../progress-item/index.tsx';
import { transformData } from '../utils.tsx';
import messages from './messages.tsx';

export type DeliveryItem = {
	issueKey: string;
	summary: string;
	estimation?: number;
	status: {
		name: string;
		statusCategoryKey: StatusCategoryKey;
	};
	issueType: {
		iconUrl: string;
		name: string;
		isSubtask: boolean;
	};
	issueLinkId?: string;
};

export const DeliverItem = ({ item }: { item: DeliveryItem }) => {
	const { unlinkDeliveryIssue, refreshDeliveryProgressForSelectedIssue } = useIssueActions();
	const { removeIssueFromIssueLinks, loadDeliveryTickets } = useIdeaActions();
	const issueId = useSelectedIssue();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const canManageDelivery = useCanManageDeliveryTickets();
	const { formatMessage } = useIntl();
	const isLoadingLinkedIssues = useIsLoadingLinkedIssues();
	const deliveryTicketsByParent = useDeliveryTicketsByParent();
	const idMap = useLocalIssueIdsByJiraIssueId();

	const childIssues = useMemo(
		() =>
			((deliveryTicketsByParent && deliveryTicketsByParent[item.issueKey]) || [])
				.sort(deliveryIssueComparator)
				.map(transformData),
		[deliveryTicketsByParent, item.issueKey],
	);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isUnlinking, setIsUnlinking] = useState(false);
	const [currentLoadingIssue, setCurrentLoadingIssue] = useState<string | undefined>();

	useEffect(() => {
		if (!isLoadingLinkedIssues) {
			setCurrentLoadingIssue(undefined);
		}
	}, [isLoadingLinkedIssues]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRemoveIssueLink = useCallback(() => {
		const { issueLinkId } = item;
		if (issueId !== undefined && issueLinkId !== undefined) {
			setIsUnlinking(true);
			unlinkDeliveryIssue(issueId, issueLinkId).then(() => {
				refreshDeliveryProgressForSelectedIssue();
				removeIssueFromIssueLinks(issueLinkId);
				setIsUnlinking(false);

				fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), idMap[issueId], {
					removedDeliveryTicketId: issueLinkId,
				});
			});
		}
	}, [
		item,
		issueId,
		unlinkDeliveryIssue,
		refreshDeliveryProgressForSelectedIssue,
		removeIssueFromIssueLinks,
		createAnalyticsEvent,
		idMap,
	]);

	const onToggleChildren = useCallback(() => {
		if (!isExpanded) {
			loadDeliveryTickets(item.issueKey);
			setCurrentLoadingIssue(item.issueKey);
		}
		setIsExpanded(!isExpanded);
	}, [isExpanded, item.issueKey, loadDeliveryTickets]);

	const distribution = useMemo(() => {
		const dist = {
			new: 0,
			indeterminate: 0,
			done: 0,
			undefined: 0,
		};

		childIssues.forEach(({ status }) => {
			dist[status.statusCategoryKey] += 1;
		});

		return dist;
	}, [childIssues]);

	const renderChildren = useCallback(
		() => (
			<Box xcss={indentStyles}>
				{isLoadingLinkedIssues && currentLoadingIssue === item.issueKey ? (
					<SpinnerContainer>
						<Spinner />
					</SpinnerContainer>
				) : (
					<>
						{!childIssues.length ? (
							<NoChildMessageContainer>
								{formatMessage(
									fg('polaris-issue-terminology-refresh')
										? messages.noChildIssuesMessageIssueTermRefresh
										: messages.noChildIssuesMessage,
								)}
							</NoChildMessageContainer>
						) : (
							<MountAnimator mountAnimation={FlashAnimation}>
								{childIssues.map((child: DeliveryItem) => (
									<DeliverItem key={child.issueKey} item={child} />
								))}
							</MountAnimator>
						)}
					</>
				)}
			</Box>
		),
		[childIssues, currentLoadingIssue, formatMessage, isLoadingLinkedIssues, item.issueKey],
	);

	return (
		<>
			<LinkedDeliveryIssueItem
				summary={item.summary}
				issueKey={item.issueKey}
				estimation={item.estimation}
				issueTypeIconUrl={item.issueType.iconUrl}
				issueTypeName={item.issueType.name}
				allowChildren={!item.issueType.isSubtask}
				showChildren={isExpanded}
				AdditionalComponent={() => (
					<ProgressContainer>
						<ProgressItem
							distribution={distribution}
							status={item.status}
							appearance={STATUS_ONLY}
						/>
					</ProgressContainer>
				)}
				canUnlink={item.issueLinkId !== undefined && !isIdeaArchived}
				isUnlinking={isUnlinking}
				onRemoveIssueLink={canManageDelivery === true ? onRemoveIssueLink : undefined}
				onToggleChildren={onToggleChildren}
			/>
			{isExpanded && renderChildren()}
		</>
	);
};

const flash = keyframes({
	'0%': {
		backgroundColor: 'transparent',
	},
	'50%': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.accent.gray.subtlest', colors.N30A),
	},
	'100%': {
		backgroundColor: 'transparent',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlashAnimation = styled.div<MountAnimationProps>({
	animation: `${flash} 1s ease-in`,
	overflow: 'hidden',
});

const indentStyles = xcss({
	marginLeft: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressContainer = styled.div({
	display: 'flex',
	alignItems: 'flex-end',
	flexShrink: 1,
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	padding: `${token('space.100', '8px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoChildMessageContainer = styled.div({
	padding: `${token('space.100', '8px')} 0 ${token('space.100', '8px')} ${token(
		'space.200',
		'16px',
	)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border', colors.N40A)}`,
	transition: '0.3s',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border', colors.N30),
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.title',
		defaultMessage: 'Roadmap views use global and system fields',
		description: 'Title of the section message for roadmap view fields onboarding',
	},
	body: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.body',
		defaultMessage: 'Global fields help you achieve consistency across projects.',
		description: 'Body of the section message for roadmap view fields onboarding',
	},
});

import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ConfigVerticalGroupBy } from '../../view-controls/config-group-by/index.tsx';
import { Header } from '../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const GroupByPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<UFOSegment name="jpd.right-sidebar.group-by">
			<FieldsContainer>
				<Header title={formatMessage(messages.groupByOptions)} onClose={onClose} />
				<ConfigVerticalGroupBy />
			</FieldsContainer>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
});

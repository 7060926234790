import React, { useCallback, useMemo, useRef, type FormEventHandler } from 'react';
import { Grid, Stack } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import {
	INTERVAL_FILTER_DATE_TYPE_END,
	INTERVAL_FILTER_DATE_TYPE_START,
	INTERVAL_FILTER_PERIOD_DAY,
	INTERVAL_FILTER_PERIOD_MONTH,
	INTERVAL_FILTER_PERIOD_WEEK,
	INTERVAL_FILTER_PERIOD_YEAR,
	INTERVAL_FILTER_TIME_CURRENT,
	INTERVAL_FILTER_TIME_NEXT,
	INTERVAL_FILTER_TIME_PAST,
	type IntervalFilterDateType,
	type IntervalFilterPeriod,
	type IntervalFilterTime,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { ensureNonNullable } from '@atlassian/jira-polaris-lib-ts-utils/src/index.tsx';
import type { SelectOption } from '../utils/types.tsx';
import { messages } from './messages.tsx';

//
// Interval date type
//
export const ROLLING_DATES_INTERVAL_DATE_START = INTERVAL_FILTER_DATE_TYPE_START;
const ROLLING_DATES_INTERVAL_DATE_END = INTERVAL_FILTER_DATE_TYPE_END;

type RollingDatesIntervalDateType = IntervalFilterDateType;

//
// Time
//
const ROLLING_DATES_TIME_PAST = INTERVAL_FILTER_TIME_PAST;
export const ROLLING_DATES_TIME_CURRENT = INTERVAL_FILTER_TIME_CURRENT;
const ROLLING_DATES_TIME_NEXT = INTERVAL_FILTER_TIME_NEXT;

type RollingDatesTime = IntervalFilterTime;

//
// Period
//
const ROLLING_DATES_PERIOD_DAY = INTERVAL_FILTER_PERIOD_DAY;
export const ROLLING_DATES_PERIOD_WEEK = INTERVAL_FILTER_PERIOD_WEEK;
const ROLLING_DATES_PERIOD_MONTH = INTERVAL_FILTER_PERIOD_MONTH;
const ROLLING_DATES_PERIOD_YEAR = INTERVAL_FILTER_PERIOD_YEAR;

type RollingDatesPeriod = IntervalFilterPeriod;

export type IntervalFilterContentRollingDatesValue = {
	intervalDateType: RollingDatesIntervalDateType;
	time: RollingDatesTime;
	period: RollingDatesPeriod;
	count: number;
};

export type IntervalFilterContentRollingDatesProps = {
	value: IntervalFilterContentRollingDatesValue;
	onChange: (value: IntervalFilterContentRollingDatesValue) => void;
};

export const IntervalFilterContentRollingDates = ({
	value,
	onChange,
}: IntervalFilterContentRollingDatesProps) => {
	const { formatMessage } = useIntl();

	const intervalDateOptions: SelectOption<RollingDatesIntervalDateType>[] = useMemo(() => {
		return [
			{
				label: formatMessage(messages.startDate),
				value: ROLLING_DATES_INTERVAL_DATE_START,
			},
			{
				label: formatMessage(messages.endDate),
				value: ROLLING_DATES_INTERVAL_DATE_END,
			},
		];
	}, [formatMessage]);

	const timeOptions: SelectOption<RollingDatesTime>[] = useMemo(() => {
		return [
			{
				label: formatMessage(messages.past),
				value: ROLLING_DATES_TIME_PAST,
			},
			{
				label: formatMessage(messages.current),
				value: ROLLING_DATES_TIME_CURRENT,
			},
			{
				label: formatMessage(messages.next),
				value: ROLLING_DATES_TIME_NEXT,
			},
		];
	}, [formatMessage]);

	const periodOptions: SelectOption<RollingDatesPeriod>[] = useMemo(() => {
		return [
			{
				label: formatMessage(messages.day),
				value: ROLLING_DATES_PERIOD_DAY,
			},
			{
				label: formatMessage(messages.week),
				value: ROLLING_DATES_PERIOD_WEEK,
			},
			{
				label: formatMessage(messages.month),
				value: ROLLING_DATES_PERIOD_MONTH,
			},
			{
				label: formatMessage(messages.year),
				value: ROLLING_DATES_PERIOD_YEAR,
			},
		];
	}, [formatMessage]);

	const { count, intervalDateType, period, time } = value;

	const intervalOption = useMemo(
		() =>
			ensureNonNullable(intervalDateOptions.find((option) => option.value === intervalDateType)),
		[intervalDateType, intervalDateOptions],
	);
	const timeOption = useMemo(
		() => ensureNonNullable(timeOptions.find((option) => option.value === time)),
		[time, timeOptions],
	);
	const periodOption = useMemo(
		() => ensureNonNullable(periodOptions.find((option) => option.value === period)),
		[period, periodOptions],
	);

	const stateRef = useRef({
		intervalOption,
		timeOption,
		periodOption,
		count,
	});

	stateRef.current.intervalOption = intervalOption;
	stateRef.current.timeOption = timeOption;
	stateRef.current.periodOption = periodOption;
	stateRef.current.count = count;

	const handleChange = useCallback(() => {
		const changeSet = { ...stateRef.current };

		onChange({
			intervalDateType: changeSet.intervalOption.value,
			time: changeSet.timeOption.value,
			count: changeSet.count,
			period: changeSet.periodOption.value,
		});
	}, [onChange]);

	const handleIntervalDateChange = useCallback(
		(nextValue: SelectOption<RollingDatesIntervalDateType> | null) => {
			if (nextValue !== null) {
				stateRef.current.intervalOption = nextValue;
				handleChange();
			}
		},
		[handleChange],
	);

	const handleTimeChange = useCallback(
		(nextValue: SelectOption<RollingDatesTime> | null) => {
			if (nextValue !== null) {
				stateRef.current.timeOption = nextValue;
				handleChange();
			}
		},
		[handleChange],
	);

	const handlePeriodChange = useCallback(
		(nextValue: SelectOption<RollingDatesPeriod> | null) => {
			if (nextValue !== null) {
				stateRef.current.periodOption = nextValue;
				handleChange();
			}
		},
		[handleChange],
	);

	const handleCountChange: FormEventHandler<HTMLInputElement> = useCallback(
		(event) => {
			const nextCount = event.currentTarget.value;
			const nextCountParsed = parseInt(nextCount, 10);
			if (Number.isNaN(nextCountParsed)) {
				return;
			}

			stateRef.current.count = nextCountParsed;
			handleChange();
		},
		[handleChange],
	);

	return (
		<Stack space="space.150">
			<Select
				options={intervalDateOptions}
				value={intervalOption}
				onChange={handleIntervalDateChange}
				isClearable={false}
			/>
			<Grid
				columnGap="space.100"
				templateColumns={time === ROLLING_DATES_TIME_CURRENT ? '1fr 2fr' : '1fr 70px 1fr'}
			>
				<Select
					options={timeOptions}
					value={timeOption}
					onChange={handleTimeChange}
					isClearable={false}
				/>
				{time !== ROLLING_DATES_TIME_CURRENT && (
					<Textfield type="number" min="0" value={count} onChange={handleCountChange} />
				)}
				<Select
					options={periodOptions}
					value={periodOption}
					onChange={handlePeriodChange}
					isClearable={false}
				/>
			</Grid>
		</Stack>
	);
};

import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { ConnectedIssueCard } from '@atlassian/jira-polaris-common/src/ui/issue-card/index.tsx';
import type { FieldValueComponentProps, FieldValueConfiguration } from '../types.tsx';
import messages from './messages.tsx';

const Component = ({ groupIdentity }: FieldValueComponentProps) => (
	<Box padding="space.025">
		<ConnectedIssueCard id={groupIdentity} />
	</Box>
);

const EmptyComponent = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyColumnHeader)}</Box>;
};

export const connectionFieldValueConfig: FieldValueConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
	EmptyComponent,
};

import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useExitPreviewMode } from '@atlassian/jira-polaris-common/src/controllers/preview-mode/index.tsx';
import messages from './messages.tsx';

export const PreviewModeBanner = () => {
	const { formatMessage } = useIntl();
	const exitPreviewMode = useExitPreviewMode();

	return (
		<PreviewBannerContainer>
			<Caption>{formatMessage(messages.previewModeCaption)}</Caption>
			<StyledButton onClick={exitPreviewMode}>{formatMessage(messages.exitPreview)}</StyledButton>
		</PreviewBannerContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewBannerContainer = styled.div({
	padding: `${token('space.200', '16px')} ${token('space.200', '16px')} ${token(
		'space.250',
		'20px',
	)}`,
	margin: `${token('space.150', '12px')} ${token('space.negative.100', '-8px')} -14px`,
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.discovery.bold', colors.P300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	borderRadius: '3px',
	marginTop: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.selected', colors.B400)} !important`,
	'&:hover, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
	},
});

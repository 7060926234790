import React from 'react';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useLinkedDeliveryIssuesExist } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { LinkedStatus } from '../../../linked-issue-status/index.tsx';
import { FieldValue } from '../../body/field/styled.tsx';
import { SUMMARY_CARD_RIGHT_PADDING } from '../../constants.tsx';
import { DELIVERY_STATUS_COMPACT_GAP, MAX_DELIVERY_STATUS_COMPACT_WIDTH } from '../constants.tsx';

type Props = {
	issueId: LocalIssueId;
	containerWidth: number;
};

export const SummaryCardFieldDeliveryStatus = ({ issueId, containerWidth }: Props) => {
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(issueId);
	const showCompactDeliveryStatus =
		containerWidth + SUMMARY_CARD_RIGHT_PADDING * 2 <= MAX_DELIVERY_STATUS_COMPACT_WIDTH;
	const gap = showCompactDeliveryStatus ? DELIVERY_STATUS_COMPACT_GAP : undefined;

	if (!deliveryIssuesExist) {
		return null;
	}

	return (
		<FieldValue>
			<LinkedStatus gap={gap} localIssueId={issueId} />
		</FieldValue>
	);
};

import React, { useMemo } from 'react';
import { useFieldKeysOfType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIsLoadingLinkedIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useStatusCategories } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/status-categories-hook.tsx';
import {
	LinkedIssuesProgressComponent,
	type BAR_WITH_STATUS,
	STATUS_ONLY,
} from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { StatusCategoryKey } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';

type Props = {
	distribution: {
		new?: number;
		indeterminate?: number;
		done?: number;
		undefined?: number;
	};
	status?: {
		name: string;
		statusCategoryKey: StatusCategoryKey;
	};
	appearance?: typeof BAR_WITH_STATUS | typeof STATUS_ONLY;
};

export const ProgressItem = ({ distribution, status, appearance }: Props) => {
	const statusCategories = useStatusCategories();
	const isLoadingLinkedIssues = useIsLoadingLinkedIssues();
	const deliveryProgressFields = useFieldKeysOfType(FIELD_TYPES.DELIVERY_PROGRESS);

	const fieldKey = useMemo(() => deliveryProgressFields[0], [deliveryProgressFields]);

	const progress = useMemo(() => {
		let total = 0;
		total += distribution.new || 0;
		total += distribution.indeterminate || 0;
		total += distribution.done || 0;
		total += distribution.undefined || 0;
		return {
			total,
			distribution,
		};
	}, [distribution]);

	return (
		<LinkedIssuesProgressComponent
			progress={progress}
			fieldKey={fieldKey}
			isLoading={isLoadingLinkedIssues}
			statusCategories={statusCategories}
			height={8}
			appearance={appearance || STATUS_ONLY}
			status={status}
			minWidth={180}
		/>
	);
};

import sum from 'lodash/sum';
import type { EntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { VIEW_KIND_SECTION } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { createSelector } from '@atlassian/react-sweet-state';
import type { NavigationView, State } from '../types.tsx';

export type ProjectProps = {
	projectId: string;
};

export type EntityLimitsHookProp = ProjectProps & {
	entityLimitType: EntityLimitType;
};

// Get the limit for a specific entity type
export const getEntityLimits = (state: State, props: EntityLimitsHookProp) =>
	state.entityLimits[props.entityLimitType] ?? null;

// Select views for a specific project
const getProjectViews = (state: State, props: ProjectProps) =>
	state.projects[props.projectId].views;

// Count the number of views in a project
export const getProjectViewsCount = createSelector(getProjectViews, (projectViews) => {
	const countViewsRecursive = (view: NavigationView): number => {
		if (view.viewType !== VIEW_KIND_SECTION) {
			return 1;
		}
		return sum(view?.views?.map((v) => countViewsRecursive(v)));
	};

	return sum(projectViews.map((v) => countViewsRecursive(v)));
});

// Count the number of view sets in a project
export const getProjectViewsetsCount = createSelector(getProjectViews, (projectViews) => {
	const countViewSetsRecursive = (view: NavigationView): number => {
		if (view.viewType === VIEW_KIND_SECTION) {
			return 1 + sum(view?.views?.map((v) => countViewSetsRecursive(v)));
		}

		return 0;
	};

	// 1 for the root view set
	return 1 + sum(projectViews.map((v) => countViewSetsRecursive(v)));
});

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsHidden,
	useFieldType,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { fieldTypeNameForPolarisFieldType } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/field-type-name/index.tsx';

export type FieldTypeProps = {
	fieldKey: string;
};

export const FieldType = ({ fieldKey }: FieldTypeProps) => {
	const { formatMessage } = useIntl();
	const fieldType = useFieldType(fieldKey);
	const isHidden = useIsHidden(fieldKey);

	const type = fieldTypeNameForPolarisFieldType(fieldType);

	if (!type) {
		return null;
	}

	return (
		<Box
			xcss={isHidden && hiddenFieldStyles}
			testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.type"
		>
			{formatMessage(type)}
		</Box>
	);
};

const hiddenFieldStyles = xcss({
	color: 'color.text.subtlest',
});

import React, { useMemo } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ClickableBanner } from '@atlassian/jira-polaris-lib-clickable-banner/src/ui/index.tsx';
import type { ClickableBannerProps } from '@atlassian/jira-polaris-lib-clickable-banner/src/ui/types.tsx';
import { ContextLink } from './context-link/index.tsx';
import messages from './messages.tsx';
import type { RestrictedFieldBannerProps } from './types.tsx';

export const RestrictedFieldBanner = ({ fieldId, variant }: RestrictedFieldBannerProps) => {
	const { formatMessage } = useIntl();

	const textSize = variant === 'addToProject' ? 'medium' : 'small';
	const spacing: ClickableBannerProps['spacing'] =
		variant === 'addToProject' ? 'compact' : 'default';
	const iconAlignment = variant === 'addToProject' ? 'start' : 'center';
	const message = useMemo<React.JSX.Element>(() => {
		switch (variant) {
			case 'fieldSettingsNonAdmin':
				return (
					<Text size={textSize}>{formatMessage(messages.restrictedFieldWarningForOthers)}</Text>
				);
			case 'fieldSettingsAdmin':
				return (
					<Text size={textSize}>
						{formatMessage(messages.restrictedFieldWarningForJiraAdmin, {
							a: (text: React.ReactNode) => (
								<ContextLink fieldId={fieldId} text={text} textSize={textSize} />
							),
						})}
					</Text>
				);
			default:
				return (
					<Text size={textSize}>
						{formatMessage(messages.restrictedContext, {
							a: (text: React.ReactNode) => (
								<ContextLink fieldId={fieldId} text={text} textSize={textSize} />
							),
						})}
					</Text>
				);
		}
	}, [formatMessage, fieldId, textSize, variant]);

	return (
		<ClickableBanner
			icon={<WarningIcon primaryColor={token('color.icon.warning')} label="" />}
			message={message}
			type="warning"
			spacing={spacing}
			iconAlignment={iconAlignment}
		/>
	);
};

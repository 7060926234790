/** @jsx jsx */
import React, {
	memo,
	useState,
	useEffect,
	useMemo,
	type ReactNode,
	type ReactElement,
	type ChangeEvent,
} from 'react';
import { styled, jsx, css } from '@compiled/react';
import groupBy from 'lodash/groupBy';
import toLower from 'lodash/toLower';
import without from 'lodash/without';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type SelectOption = {
	label: string;
	/**
	 * can be used to override label when search and preview display should be
	 * different (e.g. search for both key and summary, only display summary in preview)
	 */
	previewLabel?: string;
	id: string | undefined;
	OptionRenderComponent: () => ReactElement;
};

type Props = {
	selected: (string | undefined)[];
	options: SelectOption[];
	onChange: (value: (string | undefined)[]) => void;
};

type OptionProps = {
	selected: boolean;
	component: ReactNode;
	onChange: () => void;
};

const Option = memo(({ selected, onChange, component }: OptionProps) => (
	<OptionWrapper onClick={onChange}>
		<CheckboxWrapper>
			<Checkbox isChecked={selected} />
		</CheckboxWrapper>
		<OptionContent>{component}</OptionContent>
	</OptionWrapper>
));

export const SelectFilterContentComponent = ({ options, selected, onChange }: Props) => {
	const { formatMessage } = useIntl();

	const [search, setSearch] = useState('');
	const [initialSelected, setInitialSelected] = useState(selected);

	useEffect(() => {
		if (initialSelected !== selected) {
			setSearch('');
			setInitialSelected(selected);
		}
	}, [initialSelected, selected]);

	const onOptionSelectionChanged = (optionId: string | undefined, selection: boolean) => {
		const newSelection = selection ? [...selected, optionId] : without(selected, optionId);
		onChange(newSelection);
	};

	const filteredOptions = useMemo(
		() => options.filter((option) => toLower(option.label).includes(toLower(search))),
		[options, search],
	);

	const visibleOptionsByInitialSelection = useMemo(
		() => groupBy(filteredOptions, ({ id }) => initialSelected.includes(id)),
		[filteredOptions, initialSelected],
	);

	const clearItems = () => {
		setInitialSelected([]);
		onChange([]);
	};

	return (
		<ContentContainer>
			<SearchFieldContainer>
				<Textfield
					autoFocus
					appearance="subtle"
					value={search}
					placeholder={formatMessage(messages.searchHint)}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
					elemAfterInput={
						<EditorSearchIcon
							color={token('color.icon.accent.gray')}
							label={formatMessage(messages.searchIconMessage)}
							LEGACY_primaryColor={token('color.border.input')}
							spacing="spacious"
						/>
					}
				/>
			</SearchFieldContainer>
			{filteredOptions.length === 0 && (
				<EmptyIndicator>{formatMessage(messages.noMatchesIndicator)}</EmptyIndicator>
			)}
			<OptionsContainer>
				{visibleOptionsByInitialSelection.true && (
					<>
						{visibleOptionsByInitialSelection.true.map(({ id, OptionRenderComponent }) => {
							const isSelected = selected.includes(id);
							return (
								<Option
									key={`selected-${id}`}
									selected={isSelected}
									onChange={() => onOptionSelectionChanged(id, !isSelected)}
									component={<OptionRenderComponent />}
								/>
							);
						})}
						<Button
							appearance="link"
							onClick={clearItems}
							css={[fg('polaris_better_date_filters') && clearSelectedItemsButtonStyles]}
						>
							{formatMessage(messages.clearSelectedItems)}
						</Button>
					</>
				)}
				{visibleOptionsByInitialSelection.false &&
					visibleOptionsByInitialSelection.false.map(({ id, OptionRenderComponent }) => {
						const isSelected = selected.includes(id);
						return (
							<Option
								key={`unselected-${id}`}
								selected={isSelected}
								onChange={() => onOptionSelectionChanged(id, !isSelected)}
								component={<OptionRenderComponent />}
							/>
						);
					})}
			</OptionsContainer>
		</ContentContainer>
	);
};

const clearSelectedItemsButtonStyles = css({
	alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '220px',
	maxWidth: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchFieldContainer = styled.div({
	borderBottom: `2px solid ${token('color.border')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.075'),
	marginBottom: token('space.075'),
	maxHeight: '300px',
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	minHeight: '32px',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: token('space.200'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyIndicator = styled.div({
	marginLeft: token('space.200'),
	color: token('color.text'),
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'*': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		cursor: 'pointer !important',
	},
});

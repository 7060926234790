import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	helpIconLabel: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.help-icon-label',
		defaultMessage: 'Help resources',
		description: 'Label for help resources popup icon button',
	},
	helpPopupTitle: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.help-popup-title',
		defaultMessage: 'Help and resources',
		description: 'Title for help popup',
	},
	linkIdeaHelpTitle: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.link-idea-help-title',
		defaultMessage: 'Link an idea to a delivery ticket',
		description: 'Title for link idea help section',
	},
	linkIdeaHelpDescription: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.link-idea-help-description',
		defaultMessage: 'Connect ideas to delivery work',
		description: 'Description for link idea help section',
	},
	autofillDeliveryDatesHelpTitle: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.autofill-delivery-dates-title',
		defaultMessage: 'Autofill delivery dates',
		description: 'Title for autofill delivery dates help section',
	},
	autofillDeliveryDatesHelpDescription: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup..autofill-delivery-dates-description',
		defaultMessage: 'Sync dates from linked delivery tickets',
		description: 'Description for autofill delivery dates help section',
	},
	plansIntegrationHelpTitle: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.plans-integration-help-title',
		defaultMessage: 'Jira Product Discovery and Jira Plans',
		description: 'Title for plans integration help section',
	},
	plansIntegrationHelpDescription: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.plans-integration-help-description',
		defaultMessage: 'Track delivery work from a Jira plan',
		description: 'Description for plans integration help section',
	},
	handbookLink: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.handbook-link',
		defaultMessage: 'Product Management Handbook',
		description: 'Handbook link',
	},
	communityLink: {
		id: 'polaris-ideas.idea-view.sections.deliver.resources-popup.community-link',
		defaultMessage: 'Community',
		description: 'Community link',
	},
});

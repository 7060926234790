import React, { createContext, useContext } from 'react';
import type { RemoteProviderProps, RemoteContextConfiguration } from './types.tsx';

export const createRemoteContext = <TRemote,>(
	contextKey: string,
): RemoteContextConfiguration<TRemote> => {
	const RemoteContext = createContext<TRemote | undefined>(undefined);

	const useRemoteFromContext = (): TRemote => {
		const remotes = useContext(RemoteContext);

		if (remotes === undefined) {
			throw new Error(
				`Missing '${contextKey}' remote context or remote not initialised. Add remote context provider to your tree before using remote hooks`,
			);
		}

		return remotes;
	};

	return {
		RemoteContextProvider: ({ children, remote }: RemoteProviderProps<TRemote>) => (
			<RemoteContext.Provider value={remote}>{children}</RemoteContext.Provider>
		),
		useRemoteFromContext,
	};
};

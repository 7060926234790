import React from 'react';
import Checkbox from '@atlaskit/checkbox';
import { Box, Flex, xcss, Text } from '@atlaskit/primitives';
import type { OptionProps } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { IssueCard } from '../../../../issue-card/index.tsx';
import type { ConnectionFieldOption } from '../../types.tsx';
import messages from './messages.tsx';

export const ConnectionFieldIssueOption = <IsMulti extends boolean>(
	props: OptionProps<ConnectionFieldOption, IsMulti>,
) => {
	const { isFocused, data, isSelected, innerProps, innerRef, isMulti } = props;
	const { formatMessage } = useIntl();
	const { issueKey, label, issueType } = data;

	if (data.isInCreation) {
		return (
			<div ref={innerRef} {...innerProps}>
				<Box xcss={[containerStyles, singleContainerStyles, isFocused && focusedContainerStyles]}>
					{formatMessage(messages.createOption, {
						value: (
							<Box paddingInlineStart="space.075">
								<IssueCard issueKey={issueKey} summary={label} issueType={issueType} />
							</Box>
						),
					})}
				</Box>
			</div>
		);
	}

	return (
		<div ref={innerRef} {...innerProps}>
			<Box
				xcss={[
					containerStyles,
					isFocused && focusedContainerStyles,
					isMulti ? multiContainerStyles : singleContainerStyles,
				]}
			>
				{isMulti && (
					<Box paddingInlineStart="space.075" paddingInlineEnd="space.050">
						<Checkbox isChecked={isSelected} />
					</Box>
				)}
				<Flex
					alignItems="center"
					justifyContent="space-between"
					gap="space.100"
					xcss={contentStyles}
				>
					<IssueCard issueKey={issueKey} summary={label} issueType={issueType} />

					<Text size="small" color="color.text.subtlest">
						{issueKey}
					</Text>
				</Flex>
			</Box>
		</div>
	);
};

const contentStyles = xcss({
	whiteSpace: 'nowrap',
	width: '100%',
});

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	height: '34px',
	width: '100%',
	background: 'transparent',
	marginBottom: 'space.025',
});

const multiContainerStyles = xcss({
	cursor: 'default',
	paddingInlineEnd: 'space.150',
});

const singleContainerStyles = xcss({
	cursor: 'pointer',
	paddingInline: 'space.150',
});

const focusedContainerStyles = xcss({
	background: token('color.background.neutral.subtle.hovered'),
});

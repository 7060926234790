import React from 'react';
import { HorizontalLayout } from './horizontal-layout/index.tsx';
import type { UserTagsProps } from './types.tsx';
import { VerticalLayout } from './vertical-layout/index.tsx';

export const UserTags = ({
	users,
	verticalLayout,
	hiddenCountRef,
	setHiddenCountRefValue,
}: UserTagsProps) =>
	verticalLayout ? (
		<VerticalLayout users={users} />
	) : (
		<HorizontalLayout
			users={users}
			hiddenCountRef={hiddenCountRef}
			setHiddenCountRefValue={setHiddenCountRefValue}
		/>
	);

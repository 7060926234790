import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_GetViewsRanks as GetViewsRanks,
	jira_polaris_GetViewsRanksVariables as GetViewsRanksVariables,
	jira_polaris_GetViewsRanks_polarisProject as RankResponseProject,
} from './__generated_apollo__/jira_polaris_GetViewsRanks';

const GET_VIEWS_RANKS_QUERY = gqlTagPolaris`
query jira_polaris_GetViewsRanks($id: ID!) {
    polarisProject(id: $id, skipRefresh: true) {
        id
        viewsets {
            id
            type
            rank
            name
            views {
                id
                rank
                viewSetId
            }
            viewsets {
                id
                type
                rank
                name
                views {
                    id
                    rank
                    viewSetId
                }
            }
        }
    }
}
`;

export const fetchViewRanks = async (
	apolloClient: PolarisApolloClient,
	projectAri: Ari,
): Promise<RankResponseProject> => {
	const { data, errors } = await apolloClient.query<GetViewsRanks, GetViewsRanksVariables>({
		query: GET_VIEWS_RANKS_QUERY,
		variables: {
			id: projectAri,
		},
		fetchPolicy: 'network-only',
	});

	if (errors !== undefined) {
		throw new Error(
			`project-config.get-views-ranks-error:${errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!data || !data.polarisProject) {
		throw new Error('project-config.get-views-ranks-error: no data');
	}

	return data.polarisProject;
};

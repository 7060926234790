import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { components } from '@atlaskit/select';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { SearchBoxComponent } from '../search-box/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MenuListComponentProps = any;

export const MenuListComponent = (props: MenuListComponentProps) => {
	const { children, selectProps } = props;
	const { inputValue, onInputChange, onMenuInputFocus, isFocused } = selectProps;

	const { innerRef, ...rest } = props;

	useEffect(() => {
		onMenuInputFocus();
	}, [onMenuInputFocus]);

	return (
		<>
			<SearchBoxWrapper>
				<SearchBoxComponent
					innerRef={selectProps.searchBoxRef}
					value={inputValue}
					placeholder={selectProps.placeholder}
					onChange={(val) =>
						onInputChange(val, {
							action: 'input-change',
						})
					}
					onFocus={onMenuInputFocus}
					isFocused={isFocused}
				/>
			</SearchBoxWrapper>
			<MenuListWrapper>
				<ShadowElementTop />
				<components.MenuList {...rest} innerRef={innerRef}>
					{children}
				</components.MenuList>
				<ShadowElementBottom />
			</MenuListWrapper>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuListWrapper = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShadowElement = styled.div({
	position: 'relative',
	zIndex: 7,
	height: '4px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShadowElementTop = styled(ShadowElement)({
	boxShadow: `0px 8px 8px -8px ${token('elevation.shadow.overflow', N0)} inset`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShadowElementBottom = styled(ShadowElement)({
	boxShadow: `0px -8px 8px -8px ${token('elevation.shadow.overflow', N0)} inset`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchBoxWrapper = styled.div({
	margin: `${token('space.150', '12px')} ${token('space.150', '12px')} 0 ${token(
		'space.150',
		'12px',
	)}`,
});

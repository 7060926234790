import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editFieldButtonLabelNonFinal: {
		id: 'polaris-common.fields.connection.edit.menu-list.edit-field-button-label-non-final',
		defaultMessage: 'Edit field',
		description: 'Label for edit field button in connection field select',
	},
	showFieldSettingsButtonLabelNonFinal: {
		id: 'polaris-common.fields.connection.edit.menu-list.show-field-settings-button-label-non-final',
		defaultMessage: 'Show field settings',
		description: 'Label for show field settings button in connection field select',
	},
	limitedOptionsHelperTextNonFinal: {
		id: 'polaris-common.fields.connection.edit.menu-list.limited-options-helper-text-non-final',
		defaultMessage: 'Showing the first {maxOptions} results. Refine your search to see more.',
		description: 'Helper text for limited options in connection field select',
	},
});

import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { IssueId, IssueKey, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State, Props } from '../types.tsx';

export const getIsLoading = (state: State): boolean => state.loading;
export const getError = (state: State): Error | undefined => state.error;
export const getIsRateLimitError = (state: State): boolean => state.isRateLimitError;
export const getProviders = (_: State, props: Props): SnippetProvider[] => props.snippetProviders;
export const getIssueId = (state: State): IssueId | undefined => state.meta?.issueId;
export const getIssueKey = (state: State): IssueKey | undefined => state.meta?.issueKey;
export const getProjectId = (state: State): ProjectId | undefined => state.meta?.projectId;

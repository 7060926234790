import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { N300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { FOOTER_HEIGHT } from '../../../constants.tsx';
import type { CustomComponents } from '../../../types/custom-components.tsx';

type FooterProps = {
	fieldValue: unknown;
	groupIdentity: string | undefined;
	fullWidth?: boolean;
	verticalGroupIdentity?: string;
	verticalFieldValue?: unknown;
	component: CustomComponents['ColumnFooter'];
};

export const Footer = ({
	fieldValue,
	groupIdentity,
	fullWidth,
	verticalGroupIdentity,
	verticalFieldValue,
	component: ColumnFooter,
}: FooterProps) => {
	const [separatorVisiblity, setSeparatorVisibility] = useState<boolean>(false);

	return (
		<Container
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="hide-from-export"
			fullWidth={fullWidth}
			hasSeparator={separatorVisiblity}
		>
			<ColumnFooter
				fieldValue={fieldValue}
				groupIdentity={groupIdentity}
				verticalGroupIdentity={verticalGroupIdentity}
				verticalFieldValue={verticalFieldValue}
				setSeparatorVisibility={setSeparatorVisibility}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ fullWidth?: boolean; hasSeparator?: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: `${FOOTER_HEIGHT}px`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ fullWidth }) => (fullWidth ? 0 : token('space.200', '16px')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: ({ hasSeparator }) => `1px solid ${hasSeparator ? N300 : 'transparent'}`,
});

import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { NotificationEmailModalProps } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNotificationEmailModalComponent = lazy(
	() => import(/* webpackChunkName: "async-notification-custom-email-modal" */ './index'),
);

export const AsyncNotificationEmailModal = (props: NotificationEmailModalProps) => (
	<Placeholder name="notification-custom-email-modal" fallback={null}>
		<LazyNotificationEmailModalComponent {...props} />
	</Placeholder>
);

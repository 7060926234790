import mapKeys from 'lodash/mapKeys';
import { EmailType, GroupType, UserType, type OptionData } from '@atlaskit/user-picker';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isValidEmail } from '../../common/utils.tsx';
import type { ServerUsersAndGroups, UsersAndGroups, ServerGroup, ServerUser } from './types.tsx';
import { getFilteredUsers } from './utils.tsx';

const MAX_RESULTS = 30;

const getRequestUrl = (query: string) => {
	const searchTextEncoded = encodeURIComponent(query);
	return `/rest/api/3/groupuserpicker?maxResults=${MAX_RESULTS}&query=${searchTextEncoded}&showAvatar=true&avatarSize=large&excludeConnectAddons=true`;
};

const fetchUsersAndGroups = async (query = ''): Promise<UsersAndGroups> => {
	let users: ServerUser[] = [];
	let groups: ServerGroup[] = [];

	if (query === '') {
		const [responseWithUsers, responseWithGroups] = await Promise.all([
			fetchJson<ServerUsersAndGroups>(getRequestUrl('*')),
			fetchJson<ServerUsersAndGroups>(getRequestUrl('')),
		]);

		users = responseWithUsers.users.users;
		groups = responseWithGroups.groups.groups;
	} else {
		const response = await fetchJson<ServerUsersAndGroups>(getRequestUrl(query));

		users = response.users.users;
		groups = response.groups.groups;
	}

	return {
		users: users.map(({ accountId, accountType, avatarUrl, displayName }) => ({
			id: accountId,
			name: displayName,
			accountType,
			avatarUrl,
			type: UserType,
		})),
		groups: groups.map(({ groupId, name }) => ({
			id: groupId,
			name,
			type: GroupType,
		})),
	};
};

const usersCache: Record<string, OptionData[]> = {};

type GetUsersAndGroupsProps = {
	query?: string;
	existingUsers: OptionData[];
	existingGroups: OptionData[];
	allowEmail?: boolean;
};

export const getUsersAndGroups = async ({
	query = '',
	existingUsers,
	existingGroups,
	allowEmail = true,
}: GetUsersAndGroupsProps): Promise<OptionData[]> => {
	const existingUsersMap = mapKeys(existingUsers, 'id');
	const existingGroupsMap = mapKeys(existingGroups, 'id');
	const enteredEmails = query.split(',').map((email) => email.trim());

	if (allowEmail && enteredEmails.length > 1) {
		const validEmails = enteredEmails.filter((email) => isValidEmail(email) === 'VALID');

		// In case of pasting emails, there is no point in caching the whole query as users
		// most likely won't paste same list twice. This will save some memory when they
		// paste multiple lists one by one
		const { users } = await fetchUsersAndGroups(validEmails.join(','));

		return validEmails.map((email) => {
			const fetchedUser = users.find(({ id }) => id === email);
			return fetchedUser || { id: email, name: email, type: EmailType };
		});
	}

	if (!usersCache[query]) {
		const { groups, users } = await fetchUsersAndGroups(query);
		usersCache[query] = [...groups, ...getFilteredUsers(users)];
	}

	return usersCache[query].filter(({ id }) => !existingUsersMap[id] && !existingGroupsMap[id]);
};

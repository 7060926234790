import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';

export const DELIVERY_HAS_TICKET = 'DELIVERY_HAS_TICKET';
export const DELIVERY_HAS_NO_TICKET = 'DELIVERY_HAS_NO_TICKET';

type DeliveryTicketFilterType = typeof DELIVERY_HAS_TICKET | typeof DELIVERY_HAS_NO_TICKET;

export type UiFilter = {
	id: DeliveryTicketFilterType;
	isChecked: (values: NumericFieldFilterValue[]) => boolean;
	getValues: () => NumericFieldFilterValue[] | undefined;
	hasParameter?: boolean;
	label: MessageDescriptor;
	activeLabel?: MessageDescriptor;
};

import { useState, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import fireErrorAnalytics, {
	trackFetchErrors,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { type NotificationEmailService, type EmailAddress, toEmailAddress } from './types.tsx';

// @ts-expect-error - TS2322 - Type '(projectId: string) => [{ data: { currentEmail: unknown; currentEmailStatus: unknown; allowList: unknown; }; loadingEmail: boolean; loadingAllowList: boolean; hasCustomDomainEmail: boolean; error: unknown; }, { fetchCurrentEmail: () => Promise<...>; fetchAllowList: () => Promise<...>; updateEmail: (updatedEmail: str...' is not assignable to type 'NotificationEmailService'.
export const useNotificationEmailService: NotificationEmailService = (projectId) => {
	di(window);
	const [error, setError] = useState<unknown>();
	const [currentEmail, setCurrentEmail] = useState<unknown>();
	const [currentEmailStatus, setCurrentEmailStatus] = useState<unknown>();
	const [allowList, setAllowList] = useState<unknown>([]);
	const [defaultAtlassianEmail, setDefaultAtlassianEmail] = useState<string>('');
	const [isLoadingEmail, setIsLoadingEmail] = useState<boolean>(false);
	const [isLoadingAllowList, setIsLoadingAllowList] = useState<boolean>(false);
	const [isLoadingDefaultEmail, setIsLoadingDefaultEmail] = useState<boolean>(false);

	const fetchCurrentEmail = useCallback(async () => {
		try {
			setIsLoadingEmail(true);
			const { emailAddress, emailAddressStatus } = await performGetRequest(
				`/rest/api/3/project/${projectId}/email`,
			);
			setCurrentEmail(emailAddress);
			if (emailAddressStatus) {
				setCurrentEmailStatus(emailAddressStatus);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setError(err);
			trackFetchErrors({
				error: err,
				id: 'useNotificationEmailServiceFetchCurrentEmail',
				packageName: 'jiraProjectSettingsAppsNotificationsServices',
				sendToPrivacyUnsafeSplunk: true,
			});
		} finally {
			setIsLoadingEmail(false);
		}
	}, [projectId]);

	const fetchAllowList = useCallback(async () => {
		try {
			setIsLoadingAllowList(true);
			const { emailAddresses: newAllowList } = await performGetRequest(
				'/rest/api/latest/notification/customdomain/allowlist',
			);
			setAllowList(newAllowList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'useNotificationEmailServiceFetchAllowList',
					packageName: 'jiraProjectSettingsAppsNotificationsServices',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		} finally {
			setIsLoadingAllowList(false);
		}
	}, []);

	const fetchDefaultEmailAndAllowList = useCallback(async (isFirstLoad = false) => {
		try {
			setIsLoadingDefaultEmail(isFirstLoad);
			setIsLoadingAllowList(true);
			const { defaultEmail: newDefaultEmail, customDomainEmails: newAllowList } =
				await performGetRequest('/rest/api/latest/notification/email-options');
			setAllowList(newAllowList);
			setDefaultAtlassianEmail(newDefaultEmail);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (isFirstLoad) {
				setError(err);
			}
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'useNotificationEmailServiceFetchDefaultEmailAndAllowList',
					packageName: 'jiraProjectSettingsAppsNotificationsServices',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		} finally {
			setIsLoadingAllowList(false);
			setIsLoadingDefaultEmail(false);
		}
	}, []);

	// noinspection ES6MissingAwait
	const updateEmail = useCallback(
		async (updatedEmail: EmailAddress) => {
			try {
				setIsLoadingEmail(true);
				error && setError(undefined);
				const url = `/rest/api/3/project/${projectId}/email`;
				await performPutRequest(url, {
					body: JSON.stringify({
						emailAddress: updatedEmail,
					}),
				});
				// If the user saves an empty email, the backend resets the email to the system default
				// In this case we want to re-fetch in order to keep the current email we display up to date
				if (updatedEmail === toEmailAddress('')) {
					const response = await performGetRequest(url);
					const { emailAddress: emailResponse } = response;
					setCurrentEmail(emailResponse);
					setCurrentEmailStatus(response.emailAddressStatus);
				} else {
					setCurrentEmail(updatedEmail);
					setCurrentEmailStatus(undefined);
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				// we want to clean up the error message to display
				// as performPutRequest by default joins multiple error messages with a semi-colon followed by a space
				if (err instanceof ValidationError && err.message) {
					err.message = err.message.replace(/;/g, '');
				}
				setError(err);
				fireErrorAnalytics({
					error: err,
					meta: {
						id: 'useNotificationEmailServiceUpdateEmail',
						packageName: 'jiraProjectSettingsAppsNotificationsServices',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			} finally {
				setIsLoadingEmail(false);
			}
		},
		[error, projectId],
	);

	const clearErrors = useCallback(() => {
		setError(undefined);
	}, [setError]);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { hostname } = window.location;
	const defaultAddress = useCallback(() => toEmailAddress(`jira@${hostname}`), [hostname]);
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	const hasCustomDomainEmail = Boolean(currentEmail && currentEmail.split('@')[1] !== hostname);
	return [
		{
			data: { currentEmail, currentEmailStatus, allowList, defaultAtlassianEmail },
			loadingEmail: isLoadingEmail,
			loadingAllowList: isLoadingAllowList,
			loadingDefaultEmail: isLoadingDefaultEmail,
			hasCustomDomainEmail,
			error,
		},
		{
			fetchCurrentEmail,
			fetchAllowList,
			updateEmail,
			clearErrors,
			defaultAddress,
			fetchDefaultEmailAndAllowList,
		},
	];
};

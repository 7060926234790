import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noChildIssuesMessage: {
		id: 'polaris-ideas.idea-view.sections.deliver.delivery-item.no-child-issues-message',
		defaultMessage: 'No child issues',
		description: 'Message to indicate that issue does not have any child issues.',
	},
	noChildIssuesMessageIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.sections.deliver.delivery-item.no-child-issues-message-issue-term-refresh',
		defaultMessage: 'No child work items',
		description: 'Message to indicate that issue does not have any child issues.',
	},
});

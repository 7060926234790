import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { toProjectAri } from '@atlassian/jira-polaris-domain-project/src/utils.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ArchivedMode } from '../../services/polaris-api/fetch-snippet-providers/types.tsx';
import { fetchSnippetProvidersFromPolarisApi } from './polaris-api/index.tsx';
import type { FieldSnippetRemote } from './types.tsx';

export const createNotImplementedFieldSnippetRemote = (): FieldSnippetRemote => ({
	fetchSnippetProviders: () => Promise.resolve([]),
});

export const createPolarisApiFieldSnippetRemote = (
	apolloClient: PolarisApolloClient,
	projectAri: Ari,
): FieldSnippetRemote => ({
	fetchSnippetProviders: (archivedMode: ArchivedMode) =>
		fetchSnippetProvidersFromPolarisApi(apolloClient, projectAri, archivedMode),
});

export const createMultiProjectPolarisApiFieldSnippetRemote = (
	cloudId: CloudId,
	apolloClient: PolarisApolloClient,
): FieldSnippetRemote => ({
	fetchSnippetProviders: (archivedMode: ArchivedMode, projectId?: ProjectId) =>
		projectId
			? fetchSnippetProvidersFromPolarisApi(
					apolloClient,
					toProjectAri(projectId, cloudId),
					archivedMode,
				)
			: Promise.resolve([]),
});

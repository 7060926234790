import React, { useMemo, useCallback } from 'react';
import { isUnsupportedUrlOauthClientId } from '@atlassian/jira-polaris-common/src/common/types/snippet/constants.tsx';
import { useCloudId } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { useConfigurePropertiesDialogActions } from '@atlassian/jira-polaris-common/src/controllers/configure-properties-dialog/index.tsx';
import { useMediaClientConfig } from '@atlassian/jira-polaris-common/src/controllers/media/index.tsx';
import { UnfurlContainer } from '@atlassian/jira-polaris-common/src/controllers/unfurl/index.tsx';
import type { ObjectResolved } from '@atlassian/jira-polaris-common/src/controllers/unfurl/types.tsx';
import {
	isPendingRefresh,
	isAuthRefreshError,
} from '@atlassian/jira-polaris-common/src/ui/common/refresh/index.tsx';
import { createCardWithUrlSnippetData } from '@atlassian/jira-polaris-common/src/ui/snippet/card/utils.tsx';
import { PolarisSnippet as SnippetComponent } from '@atlassian/jira-polaris-common/src/ui/snippet/index.tsx';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { useInsightsActions } from '../../../../../controllers/insights/main.tsx';
import { useSnippet } from '../../../../../controllers/insights/selectors/insights-hooks.tsx';
import {
	useIssueId,
	useProjectId,
} from '../../../../../controllers/insights/selectors/meta-hooks.tsx';
import { isOptimisticInsight } from '../../../../../controllers/insights/utils/optimistic-updates.tsx';
import { SmartCardWrapper } from '../../smart-card/index.tsx';

export type Props = {
	insightId: string;
	snippetId: string;
	container?: HTMLElement;
	spacingTop?: boolean;
};

export type PropsInternal = {
	snippet: Snippet;
	container?: HTMLElement;
	spacingTop?: boolean;
};

const InsightPanelSnippetInternal = (props: PropsInternal) => {
	const { snippet, container, spacingTop } = props;
	const cloudId = useCloudId();
	const issueId = useIssueId();
	const projectId = useProjectId();
	const { refreshSnippet } = useInsightsActions();
	const mediaClientConfig = useMediaClientConfig();
	const { id, url, data, refresh, updated } = snippet;
	const { openDialog } = useConfigurePropertiesDialogActions();
	const safeUrl = useMemo(() => (url !== undefined && url !== null ? url : null), [url]);

	const snippetData = useMemo(() => {
		if (data === null && safeUrl !== null) {
			return createCardWithUrlSnippetData(safeUrl);
		}
		if (data === null) {
			return null;
		}
		return data;
	}, [data, safeUrl]);

	const onUnfurlCondition = useCallback(
		() => !isPendingRefresh(refresh) && isAuthRefreshError(refresh),
		[refresh],
	);

	const onObjectResolved = useCallback(
		({ isUnsupported }: ObjectResolved) => {
			if (!isUnsupported) {
				refreshSnippet(id);
			}
		},
		[refreshSnippet, id],
	);

	const onConfigureProperties = useCallback(() => {
		if (snippet.url === undefined || snippet.url === null || !snippet.data) {
			return;
		}
		openDialog({
			onConfigChanged: () => refreshSnippet(id),
			oauthClientId: snippet.oauthClientId,
			url: snippet.url,
			data: snippet.data,
		});
	}, [id, openDialog, refreshSnippet, snippet.data, snippet.oauthClientId, snippet.url]);

	const insightsRemote = useInsightRemote();

	const onSnippetRefreshRequested = useCallback(() => refreshSnippet(id), [id, refreshSnippet]);

	if (snippetData === undefined || snippetData === null || projectId === undefined) {
		return null;
	}

	const isSmartLink = isUnsupportedUrlOauthClientId(snippet.oauthClientId);

	return (
		<SmartCardWrapper
			url={safeUrl}
			showChildren={Boolean(data) || !isSmartLink}
			container={container}
		>
			<UnfurlContainer
				insightsRemote={insightsRemote}
				onUnfurlCondition={onUnfurlCondition}
				key={id}
				url={url !== null ? url : undefined}
				externalErrorType={refresh?.errorType}
				cloudId={cloudId}
				projectId={projectId}
				issueId={issueId}
				onObjectResolved={onObjectResolved}
			>
				<WaitForAdfConsumerProps>
					{({ emojiProvider, dataProviders }) => (
						<SnippetComponent
							key={id}
							isReadOnly={isOptimisticInsight(id)}
							data={snippetData}
							updated={updated}
							properties={snippet.properties !== undefined ? snippet.properties : null}
							emojiProvider={emojiProvider}
							dataProviders={dataProviders}
							mediaClientConfig={mediaClientConfig}
							refresh={refresh}
							onSnippetRefreshRequested={onSnippetRefreshRequested}
							onConfigureProperties={onConfigureProperties}
							spacingTop={spacingTop}
						/>
					)}
				</WaitForAdfConsumerProps>
			</UnfurlContainer>
		</SmartCardWrapper>
	);
};

export const InsightPanelSnippet = ({ insightId, snippetId, container, spacingTop }: Props) => {
	const snippet = useSnippet(insightId, snippetId);
	if (snippet === undefined) {
		return null;
	}

	return (
		<InsightPanelSnippetInternal snippet={snippet} container={container} spacingTop={spacingTop} />
	);
};

import { useCallback } from 'react';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	buildBasePath,
	usePostOfficeContext,
	usePostOfficeEnvironment,
	type PostOfficeEnvironmentValues,
	type PostOfficeContextValue,
} from '@atlassian/post-office-context';
import { requestInitialData } from '@atlassian/post-office-placement-shared/request-initial-data';
import {
	type JiraTenantDataFields,
	tenantToPostOfficeContext,
	transformJiraEnvToPostOfficeEnvironment,
} from './tenant-to-post-office-context.tsx';

type OptionalEnvConfigsForPostOfficeEnvironment = Omit<PostOfficeEnvironmentValues, 'envConfigs'> &
	Partial<Pick<PostOfficeEnvironmentValues, 'envConfigs'>>;

/**
 * Common method for setting up the Post Office placement request URL with the environment and product context
 *
 * @param placementId
 * @param contextOptions
 * @returns
 */
const preloadPostOfficePlacement = (
	placementId: string,
	contextOptions: {
		postOfficeContext: PostOfficeContextValue;
		environment: OptionalEnvConfigsForPostOfficeEnvironment;
	},
) => {
	const { postOfficeContext, environment } = contextOptions;

	return requestInitialData(
		placementId,
		postOfficeContext,
		undefined,
		buildBasePath(environment.currentEnv, environment.envConfigs),
	);
};

/**
 * Returns a fetcher for Post Office placement API that is shared with the SSR preload
 *
 * @param placementId
 * @returns
 */
export const usePlacementSSRFetch = (placementId: string) => {
	const postOfficeContext = usePostOfficeContext();
	const environment = usePostOfficeEnvironment();

	return useCallback(
		() => preloadPostOfficePlacement(placementId, { postOfficeContext, environment }),
		[placementId, postOfficeContext, environment],
	);
};

/**
 * Preload a Post Office placement API via SSR
 *
 * @param placementId
 * @param preloadedTenantContext
 * @returns
 */
export const preloadPostOfficePlacementWithTenantContext = (
	placementId: string,
	preloadedTenantContext: Pick<TenantContext, JiraTenantDataFields | 'environment'>,
) => {
	return preloadPostOfficePlacement(placementId, {
		postOfficeContext: tenantToPostOfficeContext(preloadedTenantContext),
		environment: {
			currentEnv: transformJiraEnvToPostOfficeEnvironment(preloadedTenantContext.environment),
		},
	});
};

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-ideas.right-sidebar.filter.title',
		defaultMessage: 'Filters',
		description: 'Header label for filters list',
	},
	roadmapFiltersInfoText: {
		id: 'polaris-ideas.right-sidebar.filter.roadmap-filters-info-text',
		defaultMessage: 'This view is filtered at a roadmap level.',
		description: 'Text displayed if it is a roadmap view and the roadmap has some filters defined',
	},
	manageRoadmapLink: {
		id: 'polaris-ideas.right-sidebar.filter.manage-roadmap-link',
		defaultMessage: 'Manage roadmap filters',
		description: 'Link to access roadmap filters settings',
	},
});

import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { useCurrentViewHideEmptyBoardColumns } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useGroupOptions, useNonEmptyGroupOptions } from '../../../common/utils/group-options.tsx';
import type { DragState } from '../../utils/draggable.types.tsx';
import { SWIMLANE_HEADER_ID, HEADER_HEIGHT } from '../constants.tsx';
import { getSwimlanesBoardWidth } from '../utils.tsx';
import { DraggableColumnStickyHeader } from './draggable-column-sticky-header/index.tsx';

export type HeaderProps = {
	isReadOnly: boolean;
	groupByField: Field;
};

export const Header = (props: HeaderProps) => {
	const { isReadOnly, groupByField } = props;
	const [isDraggingStickyColumnHeader, setIsDraggingStickyColumnHeader] = useState(false);
	const extendedOptions = useGroupOptions(groupByField);
	const nonEmptyOptions = useNonEmptyGroupOptions(groupByField);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();

	const columnsCount = useMemo(
		() => (hideEmptyColumns ? nonEmptyOptions.length : extendedOptions.length),
		[extendedOptions, hideEmptyColumns, nonEmptyOptions],
	);

	const handleDragStatusChange = useCallback((dragStatus: DragState) => {
		setIsDraggingStickyColumnHeader(dragStatus === 'dragging');
	}, []);

	return (
		<HeaderStickyWrapper>
			<HeaderContainer
				id={SWIMLANE_HEADER_ID}
				columnsCount={columnsCount}
				isDraggingStickyColumnHeader={isDraggingStickyColumnHeader}
			>
				{extendedOptions.map((extendedOption, index) => {
					const nonEmptyOptionIndex = nonEmptyOptions.findIndex(
						(o) => o.groupIdentity === extendedOption.groupIdentity,
					);
					return (
						<DraggableColumnStickyHeader
							key={extendedOption.droppableId}
							index={index}
							isReadOnly={isReadOnly}
							draggableId={extendedOption.droppableId}
							fieldKey={groupByField.key}
							groupIdentity={extendedOption.groupIdentity}
							isLastHeader={index === extendedOptions.length - 1}
							onDragStatusChange={handleDragStatusChange}
							showCollapseAllButton={hideEmptyColumns ? nonEmptyOptionIndex === 0 : index === 0}
						/>
					);
				})}
			</HeaderContainer>
		</HeaderStickyWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderStickyWrapper = styled.div({
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_HEIGHT}px`,
	zIndex: 3,
	top: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div<{
	columnsCount: number;
	isDraggingStickyColumnHeader: boolean;
}>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${({ columnsCount }: { columnsCount: number }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		getSwimlanesBoardWidth(columnsCount)}px`,
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isDraggingStickyColumnHeader }) => (isDraggingStickyColumnHeader ? '100vh' : '100%'),
});

import React from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { LinkButton } from '@atlaskit/button/new';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Emoji } from '@atlaskit/emoji';
import PagesIcon from '@atlaskit/icon/core/pages';
import { Box, xcss, Flex, Text, Stack } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	NEW_DEFAULT_TEMPLATES,
	OLD_DEFAULT_TEMPLATES,
} from '@atlassian/jira-polaris-common/src/common/types/idea-templates/default/index.tsx';
import type { IdeaTemplate } from '@atlassian/jira-polaris-common/src/common/types/idea-templates/types.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useIsProjectAdmin,
	useIsAdmin,
	useIsSiteAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import messages from './messages.tsx';

type TemplatesPopupProps = {
	onApplyTemplate: (template: ADF | undefined) => void;
	onPreviewTemplate: (templateData: IdeaTemplate) => void;
	onResetPreviewTemplate: () => void;
	previewTemplateId: string | null;
};

const TemplateItem = ({ data }: { data: IdeaTemplate }) => {
	const { title, color, emoji } = data;
	const selectedEmoji = useEmoji(emoji);
	return (
		<Flex alignItems="center">
			<Box
				xcss={iconContainerStyles}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{ backgroundColor: color ?? 'color.text.brand' }}
			>
				{selectedEmoji !== undefined ? (
					<Emoji emoji={selectedEmoji} fitToHeight={20} />
				) : (
					<PagesIcon label="default_template" LEGACY_size="medium" />
				)}
			</Box>
			<Text>{title}</Text>
		</Flex>
	);
};

export const TemplatesPopup = ({
	onApplyTemplate,
	onPreviewTemplate,
	onResetPreviewTemplate,
	previewTemplateId,
}: TemplatesPopupProps) => {
	const { formatMessage } = useIntl();

	const projectKey = useProjectKeyUnsafe();

	const [isProjectAdmin] = useIsProjectAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const isAdmin = useIsAdmin();

	const DEFAULT_TEMPLATES = fg('jpd_idea_creation_form_experiment')
		? NEW_DEFAULT_TEMPLATES
		: OLD_DEFAULT_TEMPLATES;

	const renderTemplates = () => {
		return (
			<>
				{DEFAULT_TEMPLATES.length !== 0 && (
					<Box>
						<Box xcss={titleStyles}>{formatMessage(messages.defaultTemplatesTitle)}</Box>
						<Box xcss={templatesListStyles}>
							<DropdownItemGroup>
								{DEFAULT_TEMPLATES.map((templateData) => {
									const isPreviewTemplate = templateData.id === previewTemplateId;
									return (
										<Box
											key={templateData.id}
											xcss={isPreviewTemplate ? previewTemplateStyles : templateItemStyles}
										>
											<DropdownItem
												onClick={() => onApplyTemplate(templateData.template)}
												// @ts-expect-error - TS2322 - Type '{ children: string; iconBefore: Element; iconAfter: Element | null; onMouseEnter: () => void; onFocus: () => void; onMouseLeave: () => void; onBlur: () => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
												onMouseEnter={() => onPreviewTemplate(templateData)}
												onMouseLeave={onResetPreviewTemplate}
											>
												<TemplateItem data={templateData} />
											</DropdownItem>
										</Box>
									);
								})}
							</DropdownItemGroup>
						</Box>
					</Box>
				)}
			</>
		);
	};

	return (
		<Flex xcss={templatesContainerStyles}>
			<Stack>{renderTemplates()}</Stack>
			{(isProjectAdmin || isAdmin || isSiteAdmin) && (
				<LinkButton
					target="_blank"
					shouldFitContainer
					href={`/jira/polaris/projects/${projectKey}/ideas/settings/create-ideas`}
				>
					{formatMessage(messages.moreTemplatesButton)}
				</LinkButton>
			)}
		</Flex>
	);
};

const templatesContainerStyles = xcss({
	flexDirection: 'column',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	width: '270px',
});

const templatesListStyles = xcss({
	marginLeft: 'space.negative.150',
	marginRight: 'space.negative.150',
});

const iconContainerStyles = xcss({
	minWidth: '32px',
	width: '32px',
	height: '32px',
	borderRadius: 'border.radius.100',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	':hover': {
		cursor: 'pointer',
	},
	marginRight: 'space.150',
});

const previewTemplateStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const templateItemStyles = xcss({});

const titleStyles = xcss({
	font: 'font.heading.xxsmall',
});

import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

export const MOVE_FIRST_MUTATION = gqlTagPolaris`
mutation jira_polaris_moveFirst(
    $listId: ID!,
    $items: [ID!],
 ) {
  polaris {
    ranking {
      makeFirst(
        listId: $listId
        items: $items
      ) {
        changed {
          id
          rank
        }
        added {
          id
          rank
        }
        deleted {
          id
          rank
        }
      }
    }
  }
}`;

type MoveBatchFirstArgs = {
	listId: string;
	items: string[];
};

export const createMoveBatchFirst =
	(client: PolarisApolloClient): (({ listId, items }: MoveBatchFirstArgs) => Promise<void>) =>
	({ listId, items }) =>
		client
			.mutate({
				mutation: MOVE_FIRST_MUTATION,
				variables: { listId, items },
				errorPolicy: 'all',
			})
			.then((result) => {
				if (result.errors !== undefined) {
					throw new Error(
						`ranking-service.move-first-error:${result.errors.map((e) => e.message).join(', ')}`,
					);
				}
			});

type MoveFirstArgs = {
	listId: string;
	itemId: string;
};

export const createMoveFirst = (
	client: PolarisApolloClient,
): (({ listId, itemId }: MoveFirstArgs) => Promise<void>) => {
	const moveBatchFirst = createMoveBatchFirst(client);
	return ({ listId, itemId }) => moveBatchFirst({ listId, items: [itemId] });
};

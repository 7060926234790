import React from 'react';
import { Box, Stack } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useProgress } from '../controllers/context/index.tsx';
import { messages } from '../messages.tsx';

export const ProgressDisplay = () => {
	const { progress, error } = useProgress();
	const { formatMessage } = useIntl();

	return (
		<Stack space="space.150">
			{error ? (
				<Box>{formatMessage(messages.errorRetrievingProgress)}</Box>
			) : (
				<Box>{formatMessage(messages.progress, { progress: progress ?? 0 })}</Box>
			)}
		</Stack>
	);
};

const WORKSPACE_TYPE_GLOBAL_EXPERIENCE = 'GLOBAL_EXPERIENCE';

const getWorkspaceServiceUrl = (cloudId: string) =>
	`/gateway/api/townsquare/s/${cloudId}/workspace/existsWithWorkspaceType?cloudId=${cloudId}`;

export const isGlobalExperienceWorkspaceForCloudIds = async (
	cloudIds: string[],
): Promise<(boolean | undefined)[]> =>
	Promise.all(
		cloudIds.map((cloudId) =>
			fetch(getWorkspaceServiceUrl(cloudId)).then(async (response) => {
				if (response.ok) {
					const workspaceType = await response.text();
					return workspaceType === WORKSPACE_TYPE_GLOBAL_EXPERIENCE;
				}
				return undefined;
			}),
		),
	);

export const isGlobalExperienceWorkspaceForCloudId = async (cloudId: string): Promise<boolean> => {
	try {
		const response = await fetch(getWorkspaceServiceUrl(cloudId));
		if (response.ok) {
			const workspaceType = await response.text();
			return workspaceType === WORKSPACE_TYPE_GLOBAL_EXPERIENCE;
		}
		return false;
	} catch (err) {
		return false;
	}
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	closeButtonTooltip: {
		id: 'polaris-ideas.view-content.filtered-issue-modal.close-button-tooltip',
		defaultMessage: 'Close',
		description: 'Tooltip when hovered over dialog`s close button',
	},
	description: {
		id: 'polaris-ideas.view-content.filtered-issue-modal.description',
		defaultMessage:
			"These fields are currently filtered in your view. Add or update values for these fields if you'd like your idea to appear.",
		description: 'Description text in Create with filters modal',
	},
	editorCloseIcon: {
		id: 'polaris-ideas.view-content.filtered-issue-modal.editor-close-icon',
		defaultMessage: 'Close',
		description: 'Editor close icon description',
	},
});

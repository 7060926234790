import React from 'react';
import { Label } from '@atlaskit/form';
import { useIntl } from '@atlassian/jira-intl';
import { IconField } from './icon-field.tsx';
import { messages } from './messages.tsx';

type Props = {
	id: string;
	value: string;
	onChange: (value: string) => Promise<void>;
	isEditable: boolean;
};
export const InlineEditAvatarId = ({ id, value, onChange, isEditable }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Label htmlFor={id}>{formatMessage(messages.icon)}</Label>
			<IconField id={id} avatarId={value} isDisabled={!isEditable} onChange={onChange} />
		</>
	);
};

import { useCallback, useMemo } from 'react';
import difference from 'lodash/difference';
import values from 'lodash/values';
import {
	usePinnedViewActionFieldKeys,
	usePinnedViewFields,
	usePinnedViewFieldsForSmartCard,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { COMPOSITION_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/composition/types.tsx';
import { EXPRESSION_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/expression/types.tsx';

export const usePinnedFieldSections = () => {
	const pinnedFields = usePinnedViewFieldsForSmartCard();
	const pinnedFieldKeys = usePinnedViewFields();
	const actionPinnedFieldKeys = usePinnedViewActionFieldKeys();
	const nonActionPinnedFields = difference(pinnedFieldKeys, actionPinnedFieldKeys);

	const isFormulaFieldThatMightRequireAllIdeas = useCallback((field: Field) => {
		return (
			field.formula !== undefined &&
			[EXPRESSION_TEMPLATE, COMPOSITION_TEMPLATE].includes(field.formula.template)
		);
	}, []);

	return useMemo(
		() => ({
			actionPinnedFields: actionPinnedFieldKeys,
			nonActionPinnedFields,
			hasFormulaFields: values(pinnedFields).some((field) =>
				isFormulaFieldThatMightRequireAllIdeas(field),
			),
		}),
		[
			actionPinnedFieldKeys,
			nonActionPinnedFields,
			isFormulaFieldThatMightRequireAllIdeas,
			pinnedFields,
		],
	);
};

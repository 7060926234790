import React, { useRef } from 'react';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useConnectionFieldIssueIds } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import { ConnectionsList } from '../../../connection/connection-list/index.tsx';
import { TooltipComponent } from '../../../connection/tooltip-component/index.tsx';
import { ConnectedIssueCard } from '../../../issue-card/index.tsx';
import { ObservableValuesList } from '../../observable-values-list/index.tsx';
import type { ConnectionFieldProps } from '../types.tsx';

type Props = ConnectionFieldProps & {
	onClick: () => void;
};

export const ConnectionFieldView = ({ onClick, fieldKey, localIssueId }: Props) => {
	const listRef = useRef(null);
	const containerRef = useRef(null);
	const connectedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);

	const cards = connectedIssueIds.map(({ id }) => (
		<Box key={id} padding="space.050">
			<ConnectedIssueCard id={id} />
		</Box>
	));

	return (
		<Box ref={containerRef} onClick={onClick} xcss={containerStyles}>
			{connectedIssueIds.length > 1 ? (
				<Flex ref={listRef} alignItems="center" wrap="wrap" xcss={listStyles}>
					<ObservableValuesList
						listRef={listRef}
						isActive
						containerRef={containerRef}
						TooltipComponent={({ children }) => (
							<Tooltip
								hideTooltipOnClick
								component={TooltipComponent}
								content={
									<ConnectionsList
										localIssueId={localIssueId}
										fieldKey={fieldKey}
										connectionItems={connectedIssueIds}
									/>
								}
							>
								{children}
							</Tooltip>
						)}
					>
						{cards}
					</ObservableValuesList>
				</Flex>
			) : (
				cards
			)}
		</Box>
	);
};

const containerStyles = xcss({
	width: '100%',
	height: '31px',
	position: 'relative',
	borderRadius: '4px',
	cursor: 'pointer',
	lineHeight: 1,
	overflow: 'hidden',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
	':empty': {
		marginTop: 'space.050',
		marginBottom: 'space.050',
	},
});

const listStyles = xcss({
	width: 'max-content',
});

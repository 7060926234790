import React, { useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const ExpandableSection = ({
	title,
	onToggle,
	children,
	backgroundColor = 'color.background.accent.yellow.subtlest',
	testId = 'polaris-lib-expandable-section.ui',
}: Props) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const ToggleIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;

	const { formatMessage } = useIntl();

	const handleToggleClick = () => {
		setIsExpanded((prevIsExpanded) => {
			onToggle?.(!prevIsExpanded);
			return !prevIsExpanded;
		});
	};

	return (
		<Box
			testId={testId}
			xcss={containerStyles}
			padding="space.150"
			backgroundColor={backgroundColor}
		>
			<Box xcss={headerStyles} onClick={handleToggleClick}>
				<Text>{title}</Text>
				<IconButton
					testId={`${testId}.toggle-button`}
					appearance="subtle"
					label={formatMessage(messages.toggleButtonLabel)}
					icon={(iconProps) => (
						<ToggleIcon
							{...iconProps}
							color={token('color.icon', colors.N800)}
							LEGACY_primaryColor={token('color.icon', colors.N800)}
						/>
					)}
				/>
			</Box>
			{isExpanded && <>{children}</>}
		</Box>
	);
};

const containerStyles = xcss({
	gap: 'space.100',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: 'border.radius.100',
});

const headerStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	cursor: 'pointer',
	gap: 'space.050',
});

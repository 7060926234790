import type { ItemId } from '../../../types.tsx';

export const getStorageKey = (viewId: string) => `polaris-matrix-view-selection-view-${viewId}`;

// set selection items to session storage
export const setSelectionToStorage = (viewId: string, selectedItems: ItemId[]) => {
	const storageKey = getStorageKey(viewId);
	sessionStorage.setItem(storageKey, JSON.stringify(selectedItems));
};

// get selection items from session storage by key
export const getSelectionFromStorage = (viewId: string) => {
	const storageKey = getStorageKey(viewId);
	const viewSelectedItems = sessionStorage.getItem(storageKey) || undefined;
	return viewSelectedItems !== undefined ? JSON.parse(viewSelectedItems) : [];
};

// remove selection items from session storage by key
export const removeSelectionFromStorage = (viewId: string) => {
	const storageKey = getStorageKey(viewId);
	sessionStorage.removeItem(storageKey);
};

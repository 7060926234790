import React from 'react';

import { useIssueTypeIdsForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { IssueTypeSection } from './issue-type-section.tsx';
import { useProjectId } from './project-id-provider.tsx';

export const IssueTypeSections = () => {
	const projectId = useProjectId();

	const issueTypeIds = useIssueTypeIdsForProject({ projectId });

	return (
		<>
			{issueTypeIds.map((issueTypeId) => {
				return <IssueTypeSection key={issueTypeId} issueTypeId={issueTypeId} />;
			})}
		</>
	);
};

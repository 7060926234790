import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { DN100A } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { CONTINUOUS_PADDING_PIXELS } from '../common/constants/index.tsx';
import { useMatrixActions } from '../controllers/index.tsx';
import { useXAxis, useYAxis } from '../controllers/selectors/axis-hooks.tsx';
import { CONTINUOUS } from '../types.tsx';
import { XAxisComponent, YAxisComponent } from './axis/index.tsx';
import { ChartContainer } from './container/index.tsx';
import type { ChartContentComponentProps, CornerComponentProps } from './container/types.tsx';
import { MatrixContent } from './matrix-content/index.tsx';
import { Sidebar } from './sidebar/index.tsx';

const SizeAwareMain = ({ width, height, isItemsDragDisabled }: ChartContentComponentProps) => {
	const [, { setMatrixContainerDimensions }] = useMatrixActions();
	const yAxis = useYAxis();
	const xAxis = useXAxis();

	const isXAxisContinuous = xAxis?.type === CONTINUOUS;
	const isYAxisContinuous = yAxis?.type === CONTINUOUS;

	useEffect(() => {
		setMatrixContainerDimensions(width, height);
	}, [setMatrixContainerDimensions, width, height]);

	return (
		<MainWrapper>
			<MatrixContentContainer xContinuous={isXAxisContinuous} yContinuous={isYAxisContinuous}>
				<MatrixContent isItemsDragDisabled={isItemsDragDisabled} />
			</MatrixContentContainer>
		</MainWrapper>
	);
};

export const CornerComponent = ({
	xAxisSizeProps,
	yAxisSizeProps,
}: CornerComponentProps<number>) => (
	// @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string | number'. | TS2322 - Type 'number | undefined' is not assignable to type 'string | number'.
	<CornerComponentAxisContinuation xLabelHeight={xAxisSizeProps} yLabelHeight={yAxisSizeProps} />
);

type MainProps = {
	isSidebarOpen?: boolean;
	isItemsDragDisabled?: boolean;
};

export const Main = ({ isSidebarOpen = true, isItemsDragDisabled }: MainProps) => {
	const { colorMode } = useThemeObserver();
	return (
		<Wrapper>
			<Container isDarkMode={colorMode === 'dark'}>
				<ChartContainer
					XAxisComponent={XAxisComponent}
					YAxisComponent={YAxisComponent}
					ChartContentComponent={SizeAwareMain}
					CornerComponent={CornerComponent}
					isItemsDragDisabled={isItemsDragDisabled}
				/>
			</Container>
			{isSidebarOpen && <Sidebar />}
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isDarkMode: boolean }>({
	width: '100%',
	height: 'auto',
	display: 'flex',
	marginBottom: token('space.300', '24px'),
	padding: token('space.200', '16px'),
	borderRadius: '6px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: ({ isDarkMode }) => (isDarkMode ? DN100A : colors.N10),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainWrapper = styled.div({
	width: '100%',
	height: '100%',
	display: 'flex',
	flex: '1 1 auto',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MatrixContentContainer = styled.div<{ xContinuous: any; yContinuous: any }>({
	position: 'relative',
	flex: '1 1 auto',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingLeft: ({ xContinuous }) => xContinuous && `${CONTINUOUS_PADDING_PIXELS}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingRight: ({ xContinuous }) => xContinuous && `${CONTINUOUS_PADDING_PIXELS}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingTop: ({ yContinuous }) => yContinuous && `${CONTINUOUS_PADDING_PIXELS}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingBottom: ({ yContinuous }) => yContinuous && `${CONTINUOUS_PADDING_PIXELS}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CornerComponentAxisContinuation = styled.div<{
	yLabelHeight: string | number;
	xLabelHeight: string | number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ yLabelHeight }) => `${yLabelHeight !== undefined ? yLabelHeight : 0}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ yLabelHeight }) => `calc(100% - ${yLabelHeight !== undefined ? yLabelHeight : 0}px)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ xLabelHeight }) => `calc(100% - ${xLabelHeight !== undefined ? xLabelHeight : 0}px)`,
});

import React, { useEffect, useRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { PAGE_MARGIN_X } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { ISSUES_PAGE_LIMIT } from '@atlassian/jira-polaris-common/src/controllers/issue/constants.tsx';
import {
	useActiveIssueCount,
	useArchivedIssueCount,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

const VIEW_ALL_IDEAS_PATH = '/jira/search';

export const IssueLimitBanner = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const ref = useRef(false);
	const activeIssueCount = useActiveIssueCount();
	const archivedIssueCount = useArchivedIssueCount();
	const limitReached = activeIssueCount >= ISSUES_PAGE_LIMIT;
	const isSharedView = useIsSharedView();

	useEffect(() => {
		if (isSharedView) {
			return;
		}
		if (limitReached) {
			fireUIAnalytics(createAnalyticsEvent({}), 'activeIssueLimitReachedBanner shown', {
				activeIssueCount,
				archivedIssueCount,
			});
		} else if (!limitReached && ref.current) {
			fireUIAnalytics(createAnalyticsEvent({}), 'activeIssueLimitReachedBanner hidden', {
				activeIssueCount,
				archivedIssueCount,
			});
		}
		ref.current = limitReached;
	}, [limitReached, activeIssueCount, archivedIssueCount, isSharedView, createAnalyticsEvent]);

	if (!limitReached || isSharedView) {
		return null;
	}

	return (
		<Container>
			<SectionMessage appearance="warning">
				{formatMessage(messages.activeIssueLimitReachedBanner, {
					limit: ISSUES_PAGE_LIMIT,
					a: (chunks: ReactNode[]) => <Link to={VIEW_ALL_IDEAS_PATH}>{chunks}</Link>,
				})}
			</SectionMessage>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	padding: token('space.300', `${PAGE_MARGIN_X}px`),
	paddingBottom: token('space.025', '2px'),
});

/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { Emoji, type EmojiDescription } from '@atlaskit/emoji';
import Lozenge from '@atlaskit/lozenge';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { isSystemFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { messages } from './messages.tsx';

type FieldTitleWithIconProps = {
	emoji?: EmojiDescription;
	icon?: ReactNode;
	isGlobalCustomField?: boolean;
	isPreview?: boolean;
	title?: string;
	fieldType?: FieldType;
};

const SystemFieldLozenge = () => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={formatMessage(messages.systemFieldTooltip)}>
			<Lozenge>{formatMessage(messages.systemField)}</Lozenge>
		</Tooltip>
	);
};

export const FieldTitleWithIcon = ({
	emoji,
	icon,
	isGlobalCustomField,
	isPreview,
	title,
	fieldType,
}: FieldTitleWithIconProps) => {
	const displayGlobalFieldMarker = isPreview && isGlobalCustomField;

	return (
		<Inline alignBlock="center" xcss={fieldItemIconContainerStyles}>
			<div css={fieldIconWrapperStyles}>
				{emoji ? <Emoji emoji={emoji} fitToHeight={12} /> : <div css={fieldIconStyles}>{icon}</div>}
			</div>
			<Flex xcss={ellipsisContainerStyles} gap="space.100">
				<Box xcss={ellipsisStyles}>{title}</Box>
				{isSystemFieldType(fieldType) && <SystemFieldLozenge />}
			</Flex>
			{displayGlobalFieldMarker && (
				<div css={globalFieldMarkerWrapperStyles}>
					<GlobalFieldMarker />
				</div>
			)}
		</Inline>
	);
};

const fieldIconStyles = css({
	height: token('space.200', '16px'),
	boxSizing: 'border-box',
	transform: 'scale(0.75)',
});

const fieldIconWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'content-box',
	paddingRight: token('space.075', '6px'),
	width: token('space.150', '12px'),
});

const globalFieldMarkerWrapperStyles = css({
	height: token('space.200', '16px'),
});

const ellipsisContainerStyles = xcss({
	flexShrink: 1,
	minWidth: '0',
});

const ellipsisStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const fieldItemIconContainerStyles = xcss({
	overflow: 'hidden',
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { DocNode as ADF } from '@atlaskit/adf-schema';

const template: ADF = {
	type: 'doc',
	content: [
		{
			type: 'heading',
			attrs: {
				level: 1,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						id: '1f680',
						text: '🚀',
						shortName: ':rocket:',
					},
				},
				{
					text: ' Opportunity Statement',
					type: 'text',
				},
			],
		},
		{
			type: 'panel',
			attrs: {
				// @ts-expect-error -  Type '"info"' is not assignable to type 'PanelType'.
				panelType: 'info',
			},
			content: [
				{
					type: 'paragraph',
					content: [
						{
							text: 'Transform problems into opportunities to improve people’s experiences',
							type: 'text',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Problem context',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Describe the background or current situation that reveals the problem or unmet need.',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Example: Users are unable to provide feedback quickly and easily, leading to dissatisfaction and reduced engagement',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Impact',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Describe how the problem affects the customer experience. Highlight how it impacts the business objectives.',
					type: 'text',
					// @ts-expect-error- Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Example:',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
				{
					text: ' ',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
				{
					text: 'This issue results in fewer feedback submissions, making it difficult to gather user insights for improvement. This results in slower iterations, affecting overall customer retention',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Desired outcome',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Define what success looks like if this problem is solved, using measurable metrics where possible.',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Example: A streamlined feedback submission process would increase the feedback submission rate by 30% and lead to faster product iterations',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Resources (add your own):',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
			],
		},
		{
			type: 'bulletList',
			content: [
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f4dd',
										text: '📝',
										shortName: ':pencil:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'PRD/spec',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f4f9',
										text: '📹',
										shortName: ':video_camera:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Loom',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Video',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f469-200d-1f3a8',
										text: '👩‍🎨',
										shortName: ':woman_artist:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Design file',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
			],
		},
	],
	version: 1,
};

export default template;

import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	FIELD_TYPES,
	isSystemFieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MAX_FIELD_NAME_LENGTH } from '../../common/constants.tsx';
import {
	FieldNameAndEmojiEditor,
	type FieldNameAndEmojiEditorProps,
} from '../../common/ui/field-name-and-emoji-editor/index.tsx';
import { useCallbacks } from '../../controllers/selectors/callback-hooks.tsx';
import {
	useCanEditField,
	useIsDescriptionEditorAiEnabled,
	useIsPreview,
	useNotEditableReason,
	useProjectId,
	useProjectKey,
	useReadonly,
} from '../../controllers/selectors/config-hooks.tsx';
import {
	useFieldType,
	useFieldDescription,
	useFieldEmoji,
	useFieldIsLocked,
	useFieldKey,
	useFieldLabel,
} from '../../controllers/selectors/field-hooks.tsx';
import { type DescriptionEditorProps, DescriptionEditor } from './description-editor/index.tsx';
import { messages } from './messages.tsx';
import { ConnectionFieldEmojiIcon } from './connection/index.tsx';

export type FieldMainPropertiesEditorProps = {
	onUpdateFieldName: FieldNameAndEmojiEditorProps['onUpdateFieldName'];
	reasonFieldNameNotEditable?: {
		label: string;
		tooltip?: string;
	};
	onUpdateEmoji?: FieldNameAndEmojiEditorProps['onUpdateEmoji'];
	onUpdateDescription: DescriptionEditorProps['onUpdateDescription'];
};

/**
 * Used in Pendo, do not change
 */
const fieldDescriptionContainerTestId =
	'polaris-common.ui.config.fields.field-item.field-item-main-content.description-read-container';

export const FieldMainPropertiesEditor = ({
	onUpdateDescription,
	onUpdateEmoji,
	onUpdateFieldName,
}: FieldMainPropertiesEditorProps) => {
	const fieldKey = useFieldKey();
	const fieldEmoji = useFieldEmoji();
	const label = useFieldLabel();
	const fieldDescription = useFieldDescription();
	const readonly = useReadonly();
	const canEditField = useCanEditField();
	const fieldIsLocked = useFieldIsLocked();
	const fieldType = useFieldType();
	const isPreview = useIsPreview();
	const projectId = useProjectId();
	const projectKey = useProjectKey();
	const notEditableReason = useNotEditableReason();
	const isDescriptionEditorAiEnabled = useIsDescriptionEditorAiEnabled();
	const { onValidateFieldName } = useCallbacks();
	const emojiOverride =
		fieldType === FIELD_TYPES.CONNECTION ? <ConnectionFieldEmojiIcon /> : undefined;
	const isSystemField = isSystemFieldType(fieldType);

	const { formatMessage } = useIntl();

	const onFieldNameValidation = useCallback(
		(val?: string) => {
			if (val === undefined || val.trim().length === 0) {
				return formatMessage(messages.fieldNameRequiredError);
			}
			if (val.length > MAX_FIELD_NAME_LENGTH) {
				return formatMessage(messages.fieldNameTooLongError);
			}

			const validationError = onValidateFieldName && onValidateFieldName(val);
			if (validationError !== undefined) {
				return validationError;
			}

			return undefined;
		},
		[onValidateFieldName, formatMessage],
	);

	if (!fieldKey) return null;

	const displayEmojiPicker = !isPreview && !isSystemField;
	const displayDescriptionEditor = !isPreview || fieldDescription.value;
	const isReadOnly = !canEditField || fieldIsLocked || isSystemField || readonly;

	let reasonFieldNameNotEditable: FieldMainPropertiesEditorProps['reasonFieldNameNotEditable'];

	if (notEditableReason) {
		reasonFieldNameNotEditable = notEditableReason;
	} else if (fieldIsLocked) {
		reasonFieldNameNotEditable = {
			label: formatMessage(messages.lockedFieldHelperText),
			tooltip: formatMessage(messages.lockedFieldHelperTextTooltip),
		};
	}

	return (
		<>
			{!isPreview && (
				<FieldNameAndEmojiEditor
					isReadOnly={isReadOnly}
					emojiOverride={fg('jpd_issues_relationships') ? emojiOverride : undefined}
					value={label}
					onUpdateFieldName={onUpdateFieldName}
					emojiId={fieldEmoji}
					onUpdateEmoji={onUpdateEmoji}
					onFieldNameValidation={onFieldNameValidation}
					isEmojiPickerVisible={displayEmojiPicker}
					fieldNameInUseError={formatMessage(messages.fieldNameInUseError)}
					reasonNotEditableLabel={reasonFieldNameNotEditable?.label}
					reasonNotEditableTooltip={reasonFieldNameNotEditable?.tooltip}
				/>
			)}
			{displayDescriptionEditor && (
				<DescriptionEditor
					fieldDescription={fieldDescription}
					fieldKey={fieldKey}
					isEditable={!isReadOnly}
					isEditorAiEnabled={isDescriptionEditorAiEnabled}
					onUpdateDescription={onUpdateDescription}
					projectId={projectId || ''}
					projectKey={projectKey || ''}
					testId={fieldDescriptionContainerTestId}
					isPreview={isPreview}
				/>
			)}
		</>
	);
};

import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ConfigZAxis } from '../../../view-controls/config-matrix-axis/z-axis/index.tsx';
import { Header } from '../../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const ZAxisPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<UFOSegment name="jpd.right-sidebar.axis">
			<FieldsContainer>
				<Header title={formatMessage(messages.zAxisOptions)} onClose={onClose} />
				<ConfigZAxis />
			</FieldsContainer>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
});

import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import ChevronRight from '@atlaskit/icon/glyph/chevron-right';
import { useListActions } from '../../../controllers/index.tsx';
import { useRowGroups } from '../../../controllers/selectors/items-hooks.tsx';
import { useAreAllRowGroupsCollapsed } from '../../../controllers/selectors/rows-groups-hooks.tsx';

export const CollapseAllButton = () => {
	const rowGroups = useRowGroups();
	const areAllRowGroupsCollapsed = useAreAllRowGroupsCollapsed();
	const { toggleAllRowGroupExpansions } = useListActions();

	if (!rowGroups) return null;

	return (
		<CollapseButton
			onClick={toggleAllRowGroupExpansions}
			appearance="subtle"
			spacing="none"
			iconBefore={
				areAllRowGroupsCollapsed ? (
					<ChevronRight label="Expand all" />
				) : (
					<ChevronDown label="Collapse all" />
				)
			}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapseButton = styled(Button)({
	marginLeft: 4,
});

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	AddSharingStakeholders,
	RemoveSharingStakeholders,
	FetchSharingStakeholders,
} from './types.tsx';
import {
	getAddStakeholdersUrl,
	getFetchStakeholdersUrl,
	getRemoveStakeholdersUrl,
} from './utils.tsx';

export const addSharingStakeholders: AddSharingStakeholders = (
	viewUUID,
	{ accountIds, emails, groupIds },
) =>
	performPostRequest(getAddStakeholdersUrl(viewUUID), {
		body: JSON.stringify({
			accountIds,
			emails,
			groupIds,
		}),
	});

export const removeSharingStakeholders: RemoveSharingStakeholders = (
	viewUUID,
	{ accountIds, groupIds },
) =>
	performPostRequest(getRemoveStakeholdersUrl(viewUUID), {
		body: JSON.stringify({
			accountIds,
			groupIds,
		}),
	});

export const fetchSharingStakeholders: FetchSharingStakeholders = (viewUUID) =>
	fetchJson(getFetchStakeholdersUrl(viewUUID));

import React, { useCallback } from 'react';
import EmptyState from '@atlaskit/empty-state';
import errorIcon from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/generic-error/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ContactSupportLink } from '@atlassian/jira-polaris-common/src/common/utils/errors/contact-support-link/index.tsx';
import { LinkPressable } from '@atlassian/jira-polaris-lib-link-pressable/src/ui/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type ErrorStateProps = {
	header: string;
};

export const ErrorState = ({ header }: ErrorStateProps) => {
	const { formatMessage } = useIntl();

	const handleRefreshClick = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'refreshPage');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.reload();
		},
		[],
	);

	return (
		<EmptyState
			header={header}
			renderImage={getAkEmptyStateRenderImageFn(errorIcon)}
			maxImageWidth={120}
			description={formatMessage(messages.errorScreenDescription, {
				a: (text: React.ReactNode) => <ContactSupportLink>{text}</ContactSupportLink>,
			})}
			primaryAction={
				<LinkPressable onClick={handleRefreshClick} label={formatMessage(messages.refresh)} />
			}
		/>
	);
};

import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ArchivePolarisInsights } from './types.tsx';

export const ARCHIVE_INSIGHTS_MUTATION = gqlTagPolaris`
mutation jira_polaris_ArchivePolarisInsights($project: ID!, $containers: [ID!]) {
  archivePolarisInsights(project: $project, containers: $containers) {
    success
    errors {
      message
    }
  }
}
`;

export const archiveInsights = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	issueIds: string[],
): Promise<void> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

	return client
		.mutate<ArchivePolarisInsights>({
			mutation: ARCHIVE_INSIGHTS_MUTATION,
			variables: {
				containers: issueIds.map((issueId) =>
					createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'issue',
						resourceId: issueId,
					}).toString(),
				),
				project: projectAri.toString(),
			},
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-insights.archive-insights-error:${result.errors
						.map((e) => e.message)
						.join(', ')}`,
				);
			}

			if (
				!result.data ||
				!result.data.archivePolarisInsights ||
				!result.data.archivePolarisInsights.success
			) {
				throw new Error('polaris-insights.archive-insights-error: no data or no data node');
			}
		});
};

import React from 'react';
import { styled } from '@compiled/react';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { UnsupportedFieldsList } from '@atlassian/jira-polaris-lib-control-sharing/src/common/types.tsx';
import messages from './messages.tsx';

export const UnsupportedFieldsMessage = ({
	unsupportedFields,
	sharingLinkEnabled,
}: {
	unsupportedFields: UnsupportedFieldsList;
	sharingLinkEnabled?: boolean;
}) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<span>
				{sharingLinkEnabled
					? formatMessage(messages.misconfiguredPublishedViewDescription)
					: formatMessage(messages.misconfiguredUnpublishedViewDescription)}
			</span>
			<List>
				{unsupportedFields.map(({ icon: Icon, label }) => (
					<Item key={label}>
						<Flex justifyContent="center" alignItems="center" xcss={iconWrapperStyles}>
							<Icon label={label} size="small" primaryColor={token('color.icon', colors.N800)} />
						</Flex>
						{label}
					</Item>
				))}
			</List>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const List = styled.ul({
	paddingLeft: 0,
	margin: `${token('space.100', '8px')} 0`,
	listStyle: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Item = styled.li({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.050', '4px'),
	gap: token('space.050', '4px'),
});

const iconWrapperStyles = xcss({
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
});

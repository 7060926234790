import { useCallback } from 'react';
import { useBulkIssueUpdateProgressNotification } from '@atlassian/jira-polaris-component-bulk-issue-update-progress/src/index.tsx';
import type { IssueBulkUpdateProps } from '../../types.tsx';

export const useIssueBulkUpdate = () => {
	const bulkIssueUpdateProgressNotification = useBulkIssueUpdateProgressNotification();

	return useCallback(
		(props: IssueBulkUpdateProps) => {
			bulkIssueUpdateProgressNotification(props);
		},
		[bulkIssueUpdateProgressNotification],
	);
};

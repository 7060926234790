import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji/element';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { getDirectIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldType,
	useFieldTypes,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { FIELD_HEIGHT } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/constants.tsx';
import { FOOTER_BORDER_TOP } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { FieldComponent } from '../../fields/field/entry/index.tsx';
import messages from './messages.tsx';

export const FieldEntry = ({ fieldKey }: { fieldKey: FieldKey }) => {
	const fieldType = useFieldType(fieldKey);
	const label = useFieldLabel(fieldKey);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	// skip specific field types
	if (
		fieldType === FIELD_TYPES.ISSUE_COMMENTS ||
		fieldType === FIELD_TYPES.ISSUE_KEY ||
		fieldType === FIELD_TYPES.SUMMARY
	) {
		return null;
	}

	return (
		<FieldContainer>
			<Box xcss={fieldNameWrapperStyles}>
				{emoji && (
					<Box xcss={emojiStyles}>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</Box>
				)}
				<Box xcss={fieldLabelStyles}>{label}</Box>
			</Box>
			<Box xcss={fieldValueStyles}>
				<FieldComponent fieldKey={fieldKey} />
			</Box>
		</FieldContainer>
	);
};

export const ActionFields = ({ fieldKeys }: { fieldKeys: FieldKey[] }) => {
	const [fieldTypesByKey] = useFieldTypes();
	const issueKey = useSelectedIssueKey();
	const issueLinkUrl = getDirectIssueLink(issueKey, false);
	const { formatMessage } = useIntl();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const onPlayFieldClick = useCallback(() => window.open(issueLinkUrl, '_blank'), [issueLinkUrl]);

	return (
		<Flex xcss={actionFieldsContainerStyles}>
			<Box xcss={reactionsFieldContainerStyles}>
				{fieldKeys.map((fieldKey) => {
					const fieldType = fieldTypesByKey[fieldKey];
					if (fieldType === FIELD_TYPES.REACTIONS) {
						return <FieldComponent key={fieldKey} fieldKey={fieldKey} />;
					}
					return null;
				})}
			</Box>
			<Box xcss={votesFieldContainerStyles}>
				{fieldKeys.map((fieldKey) => {
					const fieldType = fieldTypesByKey[fieldKey];
					if (fieldType === FIELD_TYPES.VOTES) {
						return (
							<Tooltip
								key={fieldKey}
								content={formatMessage(messages.voteForIdeaTooltip)}
								position="top"
							>
								<Box onClick={onPlayFieldClick}>
									<FieldComponent fieldKey={fieldKey} />
								</Box>
							</Tooltip>
						);
					}
					return null;
				})}
			</Box>
		</Flex>
	);
};

const fieldNameWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	marginRight: 'space.100',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	overflow: 'hidden',
	maxWidth: '30%',
	width: 'inherit',
	color: 'color.text.subtlest',
});

const fieldLabelStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});

const fieldValueStyles = xcss({
	width: '100%',
});

const emojiStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	marginRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: 'calc(50% - 16px)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		maxWidth: '50%',
	},
});

const actionFieldsContainerStyles = xcss({
	display: 'grid',
	gridTemplateColumns: 'minmax(0, auto) auto',
	gridAutoColumns: 'min-content',
	gridAutoFlow: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridAutoRows: `${FIELD_HEIGHT - 1}px`,
	alignItems: 'center',
	gap: 'space.250',
	justifyContent: 'space-between',
	padding: 'space.150',
	borderTopStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopWidth: `${FOOTER_BORDER_TOP}px`,
	borderTopColor: 'color.border',
});

const reactionsFieldContainerStyles = xcss({
	minWidth: '0',
});

const votesFieldContainerStyles = xcss({
	minWidth: '0',
});

import React, { useCallback } from 'react';
import { AsyncSelect, type AsyncSelectProps } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type SelectOption = {
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
};

type IssueFieldPickerProps = {
	searchString?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLoadOptions: (search?: string) => Promise<Array<{ label: string; value: any }>>;
	onFieldSelected: (field: SelectOption | null) => void;
	defaultOptions?: SelectOption[];
	value?: SelectOption;
	readonly?: boolean;
	inputId?: string;
};

type OnChangeFunctionType = Required<AsyncSelectProps<SelectOption>>['onChange'];

export const IssueFieldPicker = ({
	searchString,
	onLoadOptions,
	onFieldSelected,
	defaultOptions,
	value,
	readonly = false,
	inputId,
}: IssueFieldPickerProps) => {
	const { formatMessage } = useIntl();

	const noOptionsMessage = () => {
		if (searchString !== undefined) {
			return formatMessage(messages.noFieldsFound);
		}
		return formatMessage(messages.typeToSearch);
	};

	const handleChange = useCallback<OnChangeFunctionType>(
		(field: SelectOption | null) => {
			onFieldSelected(field);
		},
		[onFieldSelected],
	);

	return (
		<AsyncSelect
			value={value}
			isSearchable
			isDisabled={readonly}
			isClearable
			searchString={searchString}
			loadOptions={onLoadOptions}
			defaultOptions={defaultOptions}
			onChange={handleChange}
			noOptionsMessage={noOptionsMessage}
			inputId={inputId}
		/>
	);
};

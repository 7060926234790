import React, { useCallback, useState, useEffect } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { IconButton } from '@atlaskit/button/new';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Flex, Grid, xcss, Box, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import { useSourceField } from '../../../../../controllers/index.tsx';
import {
	useAutomappedOptionsCount,
	useFieldOptionsValuesMapping,
	useSetFieldOptionsValuesMapping,
	useTargetField,
} from '../../../../../controllers/options-mapping/index.tsx';
import { FieldName } from './field-name/index.tsx';
import {
	GroupedOptions,
	OptionsMapper,
	type OptionsValueMapping,
} from './field-options-mapper/index.tsx';
import messages from './messages.tsx';

type OptionsMappingModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const OptionsMappingModal = ({ isOpen, onClose }: OptionsMappingModalProps) => {
	const { formatMessage } = useIntl();

	const automappedOptionsCount = useAutomappedOptionsCount();
	const optionsValueMapping = useFieldOptionsValuesMapping();
	const fromSelectField = useSourceField();
	const toSelectField = useTargetField();

	const setFieldOptionsValuesMapping = useSetFieldOptionsValuesMapping();

	const isAutomappedMode = automappedOptionsCount === Object.keys(optionsValueMapping).length;

	const [mapping, setMapping] = useState<OptionsValueMapping>({});

	useEffect(() => {
		setMapping(optionsValueMapping);
	}, [optionsValueMapping]);

	const onSetOptionValueMapping = useCallback(
		(fromOptionId: string, toOptionId: string | undefined) => {
			setMapping((optionsMap) => ({
				...optionsMap,
				[fromOptionId]: { optionId: toOptionId, automapped: false },
			}));
		},
		[setMapping],
	);

	const handleSaveClick = useCallback(
		(_event: React.MouseEvent, analyticEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticEvent, 'saveOptionsMapping');
			setFieldOptionsValuesMapping(convertOptionValueMappingToPlainMap(mapping, true));
			onClose();
		},
		[mapping, setFieldOptionsValuesMapping, onClose],
	);

	if (!fromSelectField || !toSelectField) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceName="copyValuesOptionsMapper" sourceType={MODAL}>
			<ModalTransition>
				{isOpen && (
					<Modal onClose={onClose}>
						<ModalHeader>
							<Grid gap="space.200" templateAreas={['title close']} xcss={gridStyles}>
								<Flex xcss={closeContainerStyles} justifyContent="end">
									<IconButton
										appearance="subtle"
										icon={CrossIcon}
										label={formatMessage(messages.closeModal)}
										onClick={onClose}
									/>
								</Flex>
								<Flex xcss={titleContainerStyles} justifyContent="start">
									<ModalTitle>
										{formatMessage(
											isAutomappedMode ? messages.reviewMappingHeader : messages.mapOptionsHeader,
										)}
									</ModalTitle>
								</Flex>
							</Grid>
						</ModalHeader>
						<ModalBody>
							<FireScreenAnalytics />
							<Box>
								{formatMessage(
									isAutomappedMode
										? messages.reviewMappingDescription
										: messages.mapOptionsDescription,
								)}
							</Box>
							<Flex xcss={fieldsNameStyles}>
								<Box>
									<Box>
										<b>{formatMessage(messages.projectField)}</b>
									</Box>
									<FieldName field={fromSelectField} />
								</Box>
								<Flex
									alignItems="center"
									justifyContent="center"
									xcss={[fieldNameArrowIconWrapperStyles, fieldNameArrowIconWrapperAlignedStyles]}
								>
									<ArrowRightIcon label="" primaryColor={token('color.icon.accent.gray')} />
								</Flex>
								<Box>
									<Inline>
										<b>{formatMessage(messages.globalField)}</b>
										<GlobalFieldMarker />
									</Inline>
									<FieldName field={toSelectField} />
								</Box>
							</Flex>
							<Box
								xcss={optionsSectionWrapperStyles}
								testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.full-match"
							>
								{isAutomappedMode ? (
									<OptionsMapper
										optionValueMapping={mapping}
										onSetOptionValueMapping={onSetOptionValueMapping}
									/>
								) : (
									<GroupedOptions
										optionValueMapping={mapping}
										onSetOptionValueMapping={onSetOptionValueMapping}
									/>
								)}
							</Box>
						</ModalBody>
						<ModalFooter>
							<Button
								testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.close-button"
								appearance="subtle"
								onClick={onClose}
							>
								{formatMessage(messages.cancel)}
							</Button>
							<Button appearance="primary" onClick={handleSaveClick}>
								{formatMessage(isAutomappedMode ? messages.save : messages.map)}
							</Button>
						</ModalFooter>
					</Modal>
				)}
			</ModalTransition>
		</ContextualAnalyticsData>
	);
};

export const convertOptionValueMappingToPlainMap = (
	fieldOptionsValueMapping: OptionsValueMapping,
	skipAutomappedOptions = false,
): Record<string, string | undefined> => {
	const plainMap: Record<string, string | undefined> = {};
	for (const [fromOptionId, toOption] of Object.entries(fieldOptionsValueMapping)) {
		if (skipAutomappedOptions && toOption?.automapped) {
			// eslint-disable-next-line no-continue
			continue;
		}

		plainMap[fromOptionId] = toOption?.optionId;
	}
	return plainMap;
};

const gridStyles = xcss({
	width: '100%',
});

const closeContainerStyles = xcss({
	gridArea: 'close',
});

const titleContainerStyles = xcss({
	gridArea: 'title',
});

const fieldNameArrowIconWrapperStyles = xcss({
	width: '40px',
});

const fieldNameArrowIconWrapperAlignedStyles = xcss({
	marginTop: 'space.300',
});

const fieldsNameStyles = xcss({
	marginTop: 'space.200',
	marginBottom: 'space.250',
});

const optionsSectionWrapperStyles = xcss({
	marginBottom: 'space.1000',
	paddingBottom: 'space.1000',
});

import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { type State, type Props, initialState } from '../types.tsx';

export const loadInsights =
	() =>
	async ({ setState }: StoreActionApi<State>, containerProps: Props) => {
		const { insightsRemote, issueId = '', projectId } = containerProps;

		try {
			const data = await insightsRemote.fetch?.({ issueId, projectId });

			if (data) {
				setState({
					error: undefined,
					isRateLimitError: false,
					insights: keyBy(data.insights, ({ id }) => id),
					labels: [...data.labels],
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const isRateLimitError = error?.networkError?.statusCode === 429;

			setState({
				error,
				isRateLimitError,
			});
		}

		setState({
			loading: false,
		});
	};

export const updateInsights =
	() =>
	async ({ dispatch, getState, setState }: StoreActionApi<State>, props: Props) => {
		const {
			insights,
			playContributions: newPlayContributions,
			playIdsToAris: newPlayIdsToAris,
			cloudId,
			issueId,
			issueKey,
			projectId,
			isCollectionView,
			isVisible,
		} = props;
		const newMeta = { cloudId, issueId, projectId, issueKey };
		const { meta, playContributions, playIdsToAris } = getState();

		if (!isCollectionView && !isVisible) {
			return;
		}

		if (
			!isEqual(meta, newMeta) ||
			!isEqual(Object.keys(playContributions), Object.keys(newPlayContributions)) ||
			!isEqual(Object.keys(playIdsToAris), Object.keys(playIdsToAris))
		) {
			setState({
				...(isEqual(meta, newMeta) ? {} : initialState),
				meta: newMeta,
				insights: keyBy(insights, ({ id }) => id),
				playContributions: newPlayContributions,
				playIdsToAris: newPlayIdsToAris,
				loading: true,
			});

			// Insights from props contain only minimal information, so we need to load the full insights
			// Also load insights if the play contributions have changed
			dispatch(loadInsights());
		}
	};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'polaris-ideas.view-content.idea-board-next.column-footer.search-and-create.placeholder.non-final',
		defaultMessage: 'Search or create an idea',
		description: 'Placeholder string for dropdown for adding or creating ideas',
	},
	maxLengthError: {
		id: 'polaris-ideas.view-content.idea-board-next.column-footer.search-and-create.max-length-error.non-final',
		defaultMessage: "Summary can't exceed 255 characters.",
		description: 'Error message for creatable select when value is too long',
	},
	error: {
		id: 'polaris-ideas.view-content.idea-board-next.column-footer.search-and-create.error.non-final',
		defaultMessage: 'Error',
		description: 'Label used for error icon displayed as validation icon',
	},
});

import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import type { PolarisPlay } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { EntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import {
	getPlayById,
	getPlayContributionsByIssueId,
	getPlayIdsToAris,
	getPlayCommentsLoadingMeta,
	getPlays,
} from './plays.tsx';
import { createProjectHook, createHigherLevelProjectHook } from './utils.tsx';

export const usePlays = createProjectHook(getPlays);

export const useGetPlayContributionsByIssueId = createProjectHook(getPlayContributionsByIssueId);

export const useGetPlayById = createHigherLevelProjectHook<
	Ari | undefined,
	PolarisPlay | undefined
>(getPlayById);

export const usePlayIdsToAris = createProjectHook(getPlayIdsToAris);

export const usePlayCommentsMeta = createProjectHook(getPlayCommentsLoadingMeta);

export const useHasReachedPlayContributionsLimit = (
	playId?: Ari,
): [EntityLimitType | null, number] => {
	const plays = usePlays();
	const limitPerPlay = useEntityLimitsByType(ENTITY_LIMIT_TYPE.PLAY_CONTRIBUTIONS_PER_PLAY);
	const limitPerProject = useEntityLimitsByType(ENTITY_LIMIT_TYPE.PLAY_CONTRIBUTIONS_PER_PROJECT);

	if (plays === null) {
		return [null, 0];
	}

	const play = plays.find(({ id }) => id === playId);
	if (play === undefined) {
		return [null, 0];
	}

	const playContributionsCount = (play.contributions || []).length;
	if (limitPerPlay && playContributionsCount >= limitPerPlay) {
		return [ENTITY_LIMIT_TYPE.PLAY_CONTRIBUTIONS_PER_PLAY, limitPerPlay];
	}

	const projectContributionsCount = plays.reduce<number>(
		(acc, { contributions }) => acc + (contributions || []).length,
		0,
	);
	if (limitPerProject && projectContributionsCount >= limitPerProject) {
		return [ENTITY_LIMIT_TYPE.PLAY_CONTRIBUTIONS_PER_PROJECT, limitPerProject];
	}

	return [null, 0];
};

import React, { Component, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import IframeSpaStatePageReady from './iframe-ready-state/index.tsx';

// the iframeAttributes has a mixed type because we have no control over the properties passed to us (external apps)
type Props = {
	iframeAttributes: unknown;
	id: string;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ConnectedIframe extends Component<Props> {
	/*
	 * This component is special because the iframe inside is STATEFUL
	 * Connect works this way. This component once rendered must stay the same.
	 *
	 * !!WARNING!! Rerendering breaks the thing!
	 *
	 * The iframe is then invisible, or half visible, or has no scrollbars, or does not finish loading after SPA transition.
	 * If you want different iframe, use different key to force React to drop one component and create another, instead of updating.
	 * DOUBLE TEST before changing.
	 */
	shouldComponentUpdate() {
		return false;
	}

	render() {
		return (
			<IframeWrapper>
				<IFrame
					title={this.props.id}
					// @ts-expect-error TS2698: Spread types may only be created from object types.
					{...this.props.iframeAttributes}
					frameBorder="0"
					width="100%"
					height="100%"
					referrerPolicy="no-referrer"
				/>
				{this.props.iframeAttributes != null &&
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					typeof this.props.iframeAttributes.src === 'string' && (
						// @ts-expect-error - TS2571 - Object is of type 'unknown'.
						<IframeSpaStatePageReady src={this.props.iframeAttributes.src} />
					)}
			</IframeWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IFrame = styled.iframe<{
	children?: ReactNode;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginTop: undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IframeWrapper = styled.div({
	flex: '1 1 auto',
});

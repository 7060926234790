import React, { useState, useCallback, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Form, { Field as FormField } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { MAX_NUMBER } from '../../../../../../common/constants.tsx';
import { useFieldActions } from '../../../../../../controllers/field/main.tsx';
import { useField } from '../../../../../../controllers/issue/selectors/fields-hooks.tsx';
import { messages } from '../../../common/messages.tsx';
import { VotingHint } from '../../../common/voting-hint/index.tsx';

type DecoratorItemsProps = {
	fieldKey: FieldKey;
	readonly?: boolean;
};

const DEFAULT_MAX_SPEND = 10;
const MIN_VALUE = 1;

export const DecoratorPlayItems = ({ fieldKey, readonly = false }: DecoratorItemsProps) => {
	const { formatMessage } = useIntl();
	const { updatePlayField } = useFieldActions();

	const field = useField(fieldKey);
	const maxSpendPerPlay = field?.play?.parameters?.maxSpend ?? DEFAULT_MAX_SPEND;

	const [value, setValue] = useState(maxSpendPerPlay);
	const [maxSpend, setMaxSpend] = useState(maxSpendPerPlay);
	const [error, setError] = useState('');

	const onBlur = useCallback(() => {
		if (error || maxSpend === field?.play?.parameters?.maxSpend) {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		updatePlayField(field!.key, maxSpend);
	}, [field, error, maxSpend, updatePlayField]);

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value: newValue } = event.target;
		const newMaxSpend = parseInt(newValue, 10);
		setValue(newMaxSpend);
		if (Number.isInteger(newMaxSpend) && newMaxSpend >= MIN_VALUE && newMaxSpend <= MAX_NUMBER) {
			setMaxSpend(newMaxSpend);
			setError('');
			return;
		}
		if (newMaxSpend > MAX_NUMBER) {
			setError(formatMessage(messages.votesMaxNumberTypeError));
			return;
		}
		setError(formatMessage(messages.fieldIsIntegerError));
	};

	return (
		<Form onSubmit={noop}>
			{({ formProps }) => (
				<form autoComplete="off" {...formProps}>
					<FormField name="maxSpend" defaultValue={DEFAULT_MAX_SPEND}>
						{({ fieldProps }) => (
							<>
								<FieldWrapper>
									<Textfield
										{...fieldProps}
										testId="polaris-common.ui.config.fields.field-item.decoration.play-decoration.vote-amount"
										isDisabled={readonly}
										appearance="standard"
										type="number"
										value={value}
										min={MIN_VALUE}
										isCompact
										onBlur={onBlur}
										onChange={onChange}
										isInvalid={!!error}
										label={formatMessage(messages.settingsMaxSpendPerUser)}
									/>
								</FieldWrapper>
								{error && <ErrorContainer>{error}</ErrorContainer>}
								<VotingHint />
							</>
						)}
					</FormField>
				</form>
			)}
		</Form>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

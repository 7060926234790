/* eslint-disable jira/integration/test-id-by-folder-structure */
import React, { useCallback, useMemo, type SyntheticEvent, type MouseEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { LinkItem } from '@atlaskit/side-navigation';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events/index.tsx';
import { getDirectIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import {
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useSafeIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getLinkStyle } from './styled.tsx';

type IssueKeyFieldProps = {
	issueKey?: IssueKey;
};

type Props = {
	interactive: boolean;
	localIssueId: LocalIssueId;
};

const PreviewKeyField = ({ issueKey }: IssueKeyFieldProps) => (
	<Box
		testId="polaris-ideas.ui.view-content.idea-list.rows.cells.key-field.read-only"
		xcss={styles}
	>
		{issueKey}
	</Box>
);

const InteractiveIssueKeyField = ({ issueKey }: IssueKeyFieldProps) => {
	const embedded = useIsEmbedded();
	const isSharedView = useIsSharedView();

	const { openIssueView } = usePolarisRouter();

	const href = useMemo<string | undefined>(
		() => (!embedded ? getDirectIssueLink(issueKey, isSharedView) : undefined),
		[embedded, issueKey, isSharedView],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			if (embedded || !issueKey) {
				return;
			}

			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' }),
				'fieldValue',
				{
					issueField: 'issueKey',
				},
			);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (shouldOpenInNewTab(e as MouseEvent) && href) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(href, '_blank');
			} else {
				openIssueView(issueKey);
			}
		},
		[embedded, href, openIssueView, issueKey, createAnalyticsEvent],
	);

	return (
		<LinkItem
			testId="polaris-ideas.ui.view-content.idea-list.cell.cells.key-field.link"
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
			cssFn={getLinkStyle}
			href={href}
			onClick={handleClick}
			isDisabled={!issueKey}
		>
			<PreviewKeyField issueKey={issueKey} />
		</LinkItem>
	);
};

export const IssueKeyField = ({ localIssueId, interactive }: Props) => {
	const issueKey = useSafeIssueKey(localIssueId);
	const embedded = useIsEmbedded();

	if (interactive && !embedded) {
		return <InteractiveIssueKeyField issueKey={issueKey} />;
	}

	return <PreviewKeyField issueKey={issueKey} />;
};

const styles = xcss({
	paddingBlock: 'space.150',
	paddingInline: 'space.075',
	minHeight: '16px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

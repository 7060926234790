import React, { type ReactNode, useState, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	text?: ReactNode;
	authHint: string | undefined;
	triggerOnMount: boolean;
	onSuccess: (apiKey: string) => void;
};

export const AuthenticateApiKeyButton = ({ text, authHint, onSuccess, triggerOnMount }: Props) => {
	const { formatMessage } = useIntl();

	const [inlineDialogOpen, setInlineDialogOpen] = useState(triggerOnMount);
	const [apiKey, setApiKey] = useState('');

	const hint =
		authHint !== null && authHint !== undefined ? (
			<AuthHint dangerouslySetInnerHTML={{ __html: authHint }} />
		) : (
			<AuthHint>{formatMessage(messages.authHint)}</AuthHint>
		);

	const content = (
		<>
			{hint}
			<Textfield
				type="password"
				value={apiKey}
				autoFocus
				onChange={(evt: ChangeEvent<HTMLInputElement>) => setApiKey(evt.target.value)}
			/>
			<Box xcss={containerStyles}>
				<Button
					appearance="primary"
					key="save-action"
					isDisabled={!apiKey}
					onClick={(evt) => {
						evt.preventDefault();
						onSuccess(apiKey);
						setApiKey('');
						setInlineDialogOpen(false);
					}}
				>
					{formatMessage(messages.saveButton)}
				</Button>
			</Box>
		</>
	);

	return (
		<PolarisInlineDialog
			onClose={() => {
				setInlineDialogOpen(false);
			}}
			content={content}
			isOpen={inlineDialogOpen}
		>
			<Button
				testId="polaris-common.controllers.unfurl.utils.authentication.apikey.button"
				isSelected={inlineDialogOpen}
				onClick={(evt) => {
					evt.preventDefault();
					setInlineDialogOpen(true);
				}}
			>
				{text !== undefined ? text : formatMessage(messages.authenticateButton)}
			</Button>
		</PolarisInlineDialog>
	);
};

const containerStyles = xcss({
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AuthHint = styled.div({
	marginBottom: token('space.075', '6px'),
});

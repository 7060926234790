import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useRollupFormulaParser } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/formula/formula-parser/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useFieldFormula,
	useVisibleFieldsByKey,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useSnippetProviders } from '../../../controllers/field/selectors/snippet-providers-hooks.tsx';
import { useRefreshErrorSnippetsIds } from '../../../controllers/issue/selectors/properties/insights/hooks.tsx';
import { RefreshErrorsDialog } from '../../refresh-errors-dialog/index.tsx';
import { InfoMarker } from '../info-marker/index.tsx';
import messages from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
};

export const FormulaFieldMarker = ({ fieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const formula = useFieldFormula(fieldKey);
	const refreshErrorSnippetsIds = useRefreshErrorSnippetsIds();
	const [snippetProviders] = useSnippetProviders();
	const [visibleFieldsByKey] = useVisibleFieldsByKey();
	const rollupFormulaParser = useRollupFormulaParser(snippetProviders, visibleFieldsByKey);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const formulaInputError = useMemo(() => {
		if (!formula) return null;

		const { rollupFields } = rollupFormulaParser(formula);

		return rollupFields.some(
			({ value }) => 'oauthClientId' in value && refreshErrorSnippetsIds.has(value.oauthClientId),
		);
	}, [formula, refreshErrorSnippetsIds, rollupFormulaParser]);

	const closeDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	if (!formulaInputError) return null;

	return (
		<>
			<InfoMarker
				onClick={() => setIsDialogOpen(true)}
				tooltipContent={formatMessage(messages.infoMessage)}
				marginRight={3}
			/>
			<RefreshErrorsDialog onClose={closeDialog} isOpen={isDialogOpen} />
		</>
	);
};

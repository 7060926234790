import { multiply, quotient, sum } from '../composition/index.tsx';
import {
	COMPOSITION_PRODUCT,
	COMPOSITION_TEMPLATE,
	COMPOSITION_QUOTIENT,
} from '../composition/types.tsx';
import { constant, isConstant } from '../constant/index.tsx';
import { isField } from '../field/index.tsx';
import { isNormalization, normalize } from '../normalize/index.tsx';
import { NORMALIZATION_TEMPLATE } from '../normalize/types.tsx';
import type { CompositionFormula, DynamicFieldFormula } from '../types.tsx';

export const weightedScore = (
	formulas: DynamicFieldFormula[],
	weights: number[],
): CompositionFormula =>
	sum([
		normalize(
			sum(
				formulas.map((formula, index) => multiply([constant(weights[index]), normalize(formula)])),
			),
		),
	]);

export const weightedScoreWithPositiveAndNegativeWeights = (
	positiveFormulas: DynamicFieldFormula[],
	positiveWeights: number[],
	negativeFormulas: DynamicFieldFormula[],
	negativeWeights: number[],
): CompositionFormula =>
	sum([
		normalize(
			quotient([
				weightedScore(positiveFormulas, positiveWeights),
				weightedScore(negativeFormulas, negativeWeights),
			]),
		),
	]);

export const isWeightedScoreFormula = (formula: DynamicFieldFormula): boolean =>
	formula.template === COMPOSITION_TEMPLATE &&
	formula.parameters.formulas.length === 1 &&
	formula.parameters.formulas[0].template === NORMALIZATION_TEMPLATE;

const tryDecomposeWeightedNormalization = (
	formula: DynamicFieldFormula,
): { factor: number; formula: DynamicFieldFormula } | undefined => {
	if (
		formula.template !== COMPOSITION_TEMPLATE ||
		formula.parameters.agg !== COMPOSITION_PRODUCT ||
		formula.parameters.formulas.length !== 2
	) {
		return undefined;
	}
	const factorFormula = formula.parameters.formulas.find(isConstant);
	if (factorFormula === undefined) {
		return undefined;
	}
	const normFormula = formula.parameters.formulas.find(isNormalization);
	const fieldFormula = formula.parameters.formulas.find(isField);
	if (normFormula === undefined && fieldFormula === undefined) {
		return undefined;
	}
	return {
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'value' does not exist on type '{ agg: "prod" | "sum" | "diff" | "quot"; formulas: DynamicFieldFormula[]; } | { value: number; } | { formula: DynamicFieldFormula; upperBounds: number[]; values: number[]; } | ... 6 more ... | { ...; }'.
		factor: factorFormula.parameters.value,
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'formula' does not exist on type '{ agg: "prod" | "sum" | "diff" | "quot"; formulas: DynamicFieldFormula[]; } | { value: number; } | { formula: DynamicFieldFormula; upperBounds: number[]; values: number[]; } | ... 6 more ... | { ...; }'.
		formula: normFormula?.parameters.formula || fieldFormula,
	};
};

const getSubFormulasOfNormalizedComposition = (
	formula: DynamicFieldFormula,
): DynamicFieldFormula[] | undefined => {
	if (formula.template !== 'composition') {
		return undefined;
	}
	if (!formula.parameters.formulas[0] || formula.parameters.formulas[0].template !== 'norm') {
		return undefined;
	}
	if (formula.parameters.formulas[0].parameters.formula.template !== 'composition') {
		return undefined;
	}
	return formula.parameters.formulas[0].parameters.formula.parameters.formulas;
};

const extractNormalizedFormulasFromWeightedQuotien = (
	formula: DynamicFieldFormula,
): DynamicFieldFormula[] | undefined => {
	if (formula.template !== 'composition') {
		return undefined;
	}
	if (!formula.parameters.formulas[0] || formula.parameters.formulas[0].template !== 'norm') {
		return undefined;
	}
	const subformula = formula.parameters.formulas[0].parameters.formula;

	if (subformula.template !== 'composition' || subformula.parameters.agg !== COMPOSITION_QUOTIENT) {
		return undefined;
	}

	return subformula.parameters.formulas;
};

export const getDecomposedTerms = (
	formula: DynamicFieldFormula,
): { factor: number; formula: DynamicFieldFormula }[][] => {
	const normalizedFormulas = extractNormalizedFormulasFromWeightedQuotien(formula);

	const subformulasGroup = normalizedFormulas
		? normalizedFormulas.map((f) => getSubFormulasOfNormalizedComposition(f) ?? [])
		: [getSubFormulasOfNormalizedComposition(formula) ?? [], []];
	return subformulasGroup.map((subformulas) =>
		subformulas.map(tryDecomposeWeightedNormalization).filter(Boolean),
	);
};

import { useEffect } from 'react';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useFieldIdMapping } from '@atlassian/jira-polaris-component-field-id-mapping/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useActions } from './controllers/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useFieldKeyToAriMapAsync, useFieldAri } from './controllers';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FieldKeyToAriMap } from './controllers/types';

export const FieldAriProvider = ({ onlyFieldKeys = false }) => {
	const projectId = useProjectId();
	const { loading, data: fieldIdMap } = useFieldIdMapping(projectId);
	const cloudId = useCloudId();
	const { updateMapping } = useActions();

	useEffect(() => {
		if (fieldIdMap !== undefined || onlyFieldKeys) {
			updateMapping(cloudId, loading, fieldIdMap ?? {});
		}
	}, [projectId, updateMapping, cloudId, loading, fieldIdMap, onlyFieldKeys]);

	return null;
};

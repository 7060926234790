import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { FetchFieldsResponse } from './types.tsx';

const getFieldsUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/public/views/${viewId}/fields`;

export const getFields = (viewId: string): Promise<FetchFieldsResponse> => {
	if (viewId === undefined) {
		return Promise.reject(new Error('viewId must be supplied, cannot load fields'));
	}
	return fetchJson(getFieldsUrl(viewId));
};

import React from 'react';
import type { IconProps } from '@atlaskit/icon';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentViewSharingSettings } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import {
	useCurrentViewConfigured,
	useCurrentViewFieldsUnsupportedForSharing,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCanPublishPublicDiscoveryViews,
	useCanPublishDiscoveryViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ReadOnlyMessage } from '@atlassian/jira-polaris-lib-control-sharing/src/common/types.tsx';
import { FieldIcon } from './field-icon/index.tsx';
import messages from './messages.tsx';
import { UnsupportedFieldsMessage } from './unsupported-fields/index.tsx';

const transformUnsupportedFields = (fields: Field[]) =>
	fields.map(({ label, key, type }) => ({
		label,
		type,
		icon: (props: IconProps) => <FieldIcon fieldKey={key} {...props} />,
	}));

export const useUnsupportedFieldsList = () => {
	const { viewUnsupportedFields, misconfiguredViewUnsupportedFields } =
		useCurrentViewFieldsUnsupportedForSharing();

	return {
		viewUnsupportedFields: transformUnsupportedFields(viewUnsupportedFields),
		misconfiguredViewUnsupportedFields: transformUnsupportedFields(
			misconfiguredViewUnsupportedFields,
		),
	};
};

export const useReadOnlyMessage = (): ReadOnlyMessage | undefined => {
	const { formatMessage } = useIntl();
	const canPublishDiscoveryViews = useCanPublishDiscoveryViews();
	const canPublishPublicDiscoveryViews = useCanPublishPublicDiscoveryViews();
	const isCurrentViewConfigured = useCurrentViewConfigured();
	const settings = useCurrentViewSharingSettings();
	const { misconfiguredViewUnsupportedFields } = useUnsupportedFieldsList();

	if (!isCurrentViewConfigured) {
		return {
			message: formatMessage(messages.viewNotConfiguredForPublishing),
			appearance: 'error',
			showMessageOnly: true,
		};
	}

	if (misconfiguredViewUnsupportedFields.length > 0) {
		return {
			title: settings?.sharingLinkEnabled
				? formatMessage(messages.misconfiguredPublishedViewTitle)
				: formatMessage(messages.misconfiguredUnpublishedViewTitle),
			message: (
				<UnsupportedFieldsMessage
					unsupportedFields={misconfiguredViewUnsupportedFields}
					sharingLinkEnabled={settings?.sharingLinkEnabled}
				/>
			),
			appearance: 'error',
			showMessageOnly: true,
			enableUnpublishButton: canPublishDiscoveryViews || canPublishPublicDiscoveryViews,
			action: {
				label: formatMessage(messages.reviewConfigurationLink),
				href: 'https://confluence.atlassian.com/jpdkb/error-when-trying-to-publish-a-view-in-jira-product-discovery-1346052400.html',
			},
		};
	}

	if (!canPublishDiscoveryViews && !canPublishPublicDiscoveryViews) {
		return {
			message: settings?.sharingLinkEnabled
				? formatMessage(messages.noPermissionsToUnpublish)
				: formatMessage(messages.noPermissionsToPublish),
			appearance: 'information',
			showMessageOnly: !isCurrentViewConfigured,
		};
	}

	if (settings?.publicSharingEnabled && !canPublishPublicDiscoveryViews) {
		return {
			message: formatMessage(messages.noPermissionsToPublishPublicDiscoveryView),
			enableUnpublishButton: false,
			appearance: 'information',
		};
	}

	return undefined;
};

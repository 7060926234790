import React from 'react';

import { Inline } from '@atlaskit/primitives';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/issue-type-avatar.tsx';
import { useIssueTypeAvatarId } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldMapping } from '../../../../../../controllers/issue/selectors/fields-hooks.tsx';

type Props = {
	field: Field;
	groupIdentity: string;
	value: IssueTypeFieldValue;
};

export const IssueTypeOptionRenderer = ({ field, groupIdentity, value }: Props) => {
	const mapping = useFieldMapping(field.key);
	const avatarId = useIssueTypeAvatarId({ issueTypeId: groupIdentity });

	if (mapping === undefined) {
		throw new Error('Mapping for issue type field does not exist.');
	}

	if (avatarId === undefined) {
		return null;
	}

	return (
		<Inline alignBlock="center" space="space.075">
			<IssueTypeAvatar avatarId={avatarId} size="xsmall" />
			{mapping.getLabel(groupIdentity, value)}
		</Inline>
	);
};

import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import messages from './messages.tsx';

export const useWorkflowLoadingFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return (errorObject: Error) => {
		errorWithRefresh({
			title: formatMessage(messages.errorTitle),
			description: formatMessage(messages.workflowLoadingFailedDescription),
		});
		fireErrorAnalytics(createErrorAnalytics('polaris.error.workflowLoadingFailed', errorObject));
	};
};

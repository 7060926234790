import { parseISO } from 'date-fns';
import type { CalendarProps, CalendarState } from '../../types/date-picker/index.tsx';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum FuzzyScale {
	DAY = 'day',
	MONTH = 'month',
	QUARTER = 'quarter',
}

export const getInitialMonthPickerDate = (props: CalendarProps): CalendarState => {
	const parsedDate = props.date ? parseISO(props.date) : new Date();
	return {
		initialYear: parsedDate.getFullYear(),
		year: parsedDate.getFullYear(),
		month: parsedDate.getMonth(),
	};
};

import React, { type ChangeEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList } from '@atlaskit/tabs';
import Textfield from '@atlaskit/textfield';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectIdUnsafe,
	useProjectId,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { IssuelessAdfProvider } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/utils/provider.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ModalTransitionUniversal } from '../../../common/ui/modal-transition/index.tsx';
import { useMediaClientConfig } from '../../../controllers/media/index.tsx';
import {
	UnfurlContainer,
	useUnfurlProps,
	useUnfurlState,
} from '../../../controllers/unfurl/index.tsx';
import { UnfurlStatus } from '../../../controllers/unfurl/utils/index.tsx';
import { PolarisSnippet } from '../../snippet/index.tsx';
import messages from './messages.tsx';

type Props = {
	title: string;
	placeholder: string;
	successMessage: string;
};

type InputFieldProps = {
	placeholder: string;
	onChange: (arg1: string | undefined) => void;
};

type ResolvedSnippetProps = {
	successMessage: string;
};

export const ExampleInputField = ({ onChange, placeholder }: InputFieldProps) => {
	const { url } = useUnfurlProps();
	const { isResolving, resolvedObject } = useUnfurlState();

	const handleChange = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			onChange(evt.target.value);
		},
		[onChange],
	);

	return isResolving || resolvedObject === undefined ? (
		<Textfield
			value={url || ''}
			placeholder={placeholder}
			onChange={handleChange}
			elemAfterInput={
				<ActionsContainer>
					<UnfurlStatus />
					{url !== undefined && url !== '' && !isResolving && (
						<Button
							key="clean-button"
							appearance="subtle-link"
							iconBefore={<CrossIcon label="" size="small" />}
							onClick={() => onChange(undefined)}
						/>
					)}
				</ActionsContainer>
			}
		/>
	) : null;
};

export const ResolvedSnippet = ({ successMessage }: ResolvedSnippetProps) => {
	const { url } = useUnfurlProps();
	const { resolvedObject, isResolving } = useUnfurlState();
	const mediaClientConfig = useMediaClientConfig();
	const { formatMessage } = useIntl();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();

	if (isResolving || url === undefined || url === '') {
		return null;
	}

	if (resolvedObject === undefined) {
		return <>{formatMessage(messages.unresolvableLink)}</>;
	}

	const { snippetData, properties } = resolvedObject;

	if (snippetData === undefined) {
		return <>{formatMessage(messages.unresolvableLink)}</>;
	}

	return (
		<>
			{formatMessage(messages.successMessagePrefix)} {successMessage}{' '}
			{formatMessage(messages.successMessage)}
			<Box xcss={captureSectionHeaderStyles}>
				<Tabs id="unfurl-dialog-tabs" shouldUnmountTabPanelOnChange>
					<TabList>
						<Tab>{formatMessage(messages.capture)}</Tab>
					</TabList>
				</Tabs>
			</Box>
			<IssuelessAdfProvider projectId={projectId} projectKey={projectKey}>
				{({ adfConfiguration }) => (
					<>
						{adfConfiguration && (
							<PolarisSnippet
								data={snippetData}
								properties={properties}
								updated={null}
								mediaClientConfig={mediaClientConfig}
								dataProviders={adfConfiguration.dataProviders}
								emojiProvider={adfConfiguration.emojiProvider}
							/>
						)}
					</>
				)}
			</IssuelessAdfProvider>
		</>
	);
};

export const UnfurlDialog = ({ title, placeholder, successMessage }: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [url, setUrl] = useState<string | undefined>(undefined);
	const close = useCallback(() => setIsOpen(false), []);
	const cloudId = useCloudId();
	const projectId = useProjectId();
	const insightsRemote = useInsightRemote();

	return (
		<>
			<Button spacing="none" appearance="link" onClick={() => setIsOpen(true)}>
				{formatMessage(messages.tryNow)}
			</Button>
			<ModalTransitionUniversal>
				{isOpen && (
					<ShortcutScope>
						<ModalDialog onClose={close}>
							<ModalHeader>
								<ModalTitle>{title}</ModalTitle>
							</ModalHeader>
							<ModalBody>
								<UnfurlContainer
									insightsRemote={insightsRemote}
									url={url}
									cloudId={cloudId}
									projectId={projectId}
									issueId="42" // NOTE: dummy id until issue is made optional for unfurling
									onObjectResolved={noop}
								>
									<ExampleInputField onChange={setUrl} placeholder={placeholder} />
									<ResolvedSnippet successMessage={successMessage} />
								</UnfurlContainer>
							</ModalBody>
							<ModalFooter>
								<Button onClick={close} appearance="primary">
									{formatMessage(messages.close)}
								</Button>
							</ModalFooter>
						</ModalDialog>
					</ShortcutScope>
				)}
			</ModalTransitionUniversal>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '10px',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

const captureSectionHeaderStyles = xcss({
	marginBottom: 'space.100',
});

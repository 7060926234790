import type { DocNode as ADF } from '@atlaskit/adf-schema';

const template: ADF = {
	version: 1,
	type: 'doc',

	content: [
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Use this template to quantify the value delivered by new solutions while bringing focus to the most valuable changes, and delivering incremental iterations.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#403294',
							},
						},
					],
				},
			],
		},
		{
			type: 'table',
			attrs: {
				isNumberColumnEnabled: false,
				layout: 'default',
				localId: 'f24e584e-0634-41d9-9609-de23a02122ad',
			},
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Driver',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who’s the driver for this exploration?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Contributors',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who are contributors helping define and validate potential solutions?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Informed',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who should be informed of the findings?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':bulb:',
						id: '1f4a1',
						text: '💡',
					},
				},
				{
					type: 'text',
					text: ' Hypothesis',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'We believe that',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [describe the change we are making]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'For',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [user group] ',
				},
				{
					type: 'text',
					text: 'in',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [product/feature/conditions where the change is happening]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Will result in',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [describe the outcome of the change]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'This will benefit users by',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [describe the user impact]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'And',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [describe the business impact]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'We will know if this is true if',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [main metric] ',
				},
				{
					type: 'text',
					text: 'is significantly',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [increased/decreased] ',
				},
				{
					type: 'text',
					text: 'after',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [runtime]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'We also expect ',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: '[secondary metrics] ',
				},
				{
					type: 'text',
					text: 'to',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [go up/down]',
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':test_tube:',
						id: '1f9ea',
						text: '🧪',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
				{
					type: 'text',
					text: 'Validation',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'After a runtime of',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [x weeks] ',
				},
				{
					type: 'text',
					text: 'we saw',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [main metric] [increase/decrease] [significantly/not].',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'This',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [validates/fails to validate] ',
				},
				{
					type: 'text',
					text: 'our hypotheses that',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [change] ',
				},
				{
					type: 'text',
					text: 'will cause',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [outcome].',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'We also observed',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [secondary metrics(s)] ',
				},
				{
					type: 'text',
					text: 'go',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [go up/down].',
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':heavy_check_mark:',
						id: '2714',
						text: '✔',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
				{
					type: 'text',
					text: 'Decision',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':check_mark:',
						id: 'atlassian-check_mark',
						text: ':check_mark:',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
				{
					type: 'text',
					text: 'Keep',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [change because hypotheses were validated]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':minus:',
						id: 'atlassian-minus',
						text: ':minus:',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
				{
					type: 'text',
					text: 'Reject',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [change because we failed to validate the hypothesis]',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':loop:',
						id: '27bf',
						text: '➿',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
				{
					type: 'text',
					text: 'Retry',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					type: 'text',
					text: ' [the test with [changes] because we failed to validate the hypothesis]',
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'text',
					text: 'Resources',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Here are plays from the Atlassian team playbook that are relevant to this:',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/okrs',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/customer-journey-mapping',
					},
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/empathy-mapping',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/customer-interview',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
	],
};

export default template;

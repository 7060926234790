import { useCallback } from 'react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	useDroppableEvents,
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import type { ExtendedOption } from '../../../../../common/utils/board.tsx';

export type DroppableHandlerProps = {
	children: React.ReactNode;
	options: ExtendedOption<unknown>[];
	onOptionsUpdate: (nextOptions: ExtendedOption<unknown>[]) => void;
};

const DroppableHandlerLegacy = ({ children, options, onOptionsUpdate }: DroppableHandlerProps) => {
	const onSort = useCallback(
		({ srcId, dstId, edge }: { srcId: number; dstId: number; edge: Edge }) => {
			let dstAdj = srcId < dstId && edge === 'top' ? -1 : 0;
			dstAdj = srcId > dstId && edge === 'bottom' ? 1 : dstAdj;

			const newOrder = [...options];
			const [removed] = newOrder.splice(srcId, 1);
			newOrder.splice(dstId + dstAdj, 0, removed);

			onOptionsUpdate(newOrder);
		},
		[options, onOptionsUpdate],
	);

	useDroppableEvents({
		onSort,
	});

	return children;
};

const DroppableHandlerNext = ({ children, options, onOptionsUpdate }: DroppableHandlerProps) => {
	const onSort: DroppableCollectionProps<ExtendedOption<unknown>, number>['onSort'] = useCallback(
		({ updatedCollection }) => {
			onOptionsUpdate(updatedCollection);
		},
		[onOptionsUpdate],
	);

	useDroppableEventsCollectionUpdate({
		onSort,
		collection: options,
		isSrcIdRepresentingIndex: true,
	});

	return children;
};

export const DroppableHandler = componentWithFG(
	'polaris_improved_dnd_handling',
	DroppableHandlerNext,
	DroppableHandlerLegacy,
);

import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { getViewComments } from '../../services/jpd-views-service/view-comments/fetch-view-comments/index.tsx';
import { createViewComment } from '../../services/polaris-api/create-view-comment/index.tsx';
import { deleteViewComment } from '../../services/polaris-api/delete-view-comment/index.tsx';
import { fetchViewComments } from '../../services/polaris-api/fetch-view-comments/index.tsx';
import { updateViewComment } from '../../services/polaris-api/update-view-comment/index.tsx';
import type { ViewCommentsRemote } from './types.tsx';

export const createPolarisApiViewCommentsRemote = (
	apolloClient: PolarisApolloClient,
): ViewCommentsRemote => ({
	fetchViewComments: (viewId) => fetchViewComments(apolloClient, viewId),
	createViewComment: (props) => createViewComment(apolloClient, props),
	updateViewComment: ({ commentId, update }) => updateViewComment(apolloClient, commentId, update),
	deleteViewComment: (commentId) => deleteViewComment(apolloClient, commentId),
});

export const createNotImplementedViewCommentsRemote = (): ViewCommentsRemote => ({
	createViewComment: notImplementedError('createViewComment'),
	deleteViewComment: notImplementedError('deleteViewComment'),
	fetchViewComments: () => Promise.resolve({ comments: [] }),
	updateViewComment: notImplementedError('updateViewComment'),
});

export const createSharingViewCommentsRemote = (viewId: string): ViewCommentsRemote => ({
	...createNotImplementedViewCommentsRemote(),
	fetchViewComments: () => getViewComments(viewId),
});

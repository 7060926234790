import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import IssueFieldTeam from '@atlassian/jira-issue-field-team/src/ui/main.tsx';
import {
	createIssueAri,
	createIssueFieldValueAriWithFieldKey,
} from '@atlassian/jira-polaris-common/src/common/utils/ari/index.tsx';
import {
	useSelectedIssueKey,
	useSelectedIssueJiraId,
	useSelectedIssueTeamValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { createUseTeamFieldOverride } from '../../../../../common/utils/create-use-team-field-override.tsx';
import { useUpdateFieldValue } from '../utils.tsx';

type Props = {
	fieldKey: FieldKey;
};

export const TeamField = ({ fieldKey }: Props) => {
	const cloudId = useCloudId();
	const issueKey = useSelectedIssueKey();
	const issueId = useSelectedIssueJiraId();
	const [canEditIssues] = useCanEditIssues();
	const value = useSelectedIssueTeamValue(fieldKey);
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (!issueKey || !issueId) {
		return null;
	}

	const issueAri = createIssueAri(cloudId, issueId.toString());
	const fieldAri = createIssueFieldValueAriWithFieldKey(cloudId, issueId.toString(), fieldKey);

	return (
		<IssueFieldTeam
			issueKey={issueKey}
			issueId={issueAri}
			fieldKey={fieldAri}
			readView={
				<Box xcss={valueStyles}>
					<TeamValue id={value} name={value} displayPlaceholder displayPreview />
				</Box>
			}
			useTeamField={createUseTeamFieldOverride({
				issueKey,
				fieldKey,
				canEditIssues,
				initialValue: value,
				updateFieldValue,
			})}
		/>
	);
};

const valueStyles = xcss({
	// we need 5px margin to match label/select/multi-select, so that they align if used together
	// xcss doesn't allow '5px', hence the transparent border
	borderLeft: '1px solid transparent',
});

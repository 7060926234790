import { useCallback } from 'react';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useIssueKey } from '../../issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '../../route/index.tsx';
import { useCurrentUser } from '../../user/index.tsx';
import { useIdeaActions } from '../main.tsx';

// Open idea view in sidebar (overview tab) + open field editor (with possibility focus on a specific option)
export const useOpenIdeaViewFieldSidebar = (localIssueId: LocalIssueId) => {
	const { openIssueView } = usePolarisRouter();
	const { setFieldSidebar } = useIdeaActions();

	const issueKey = useIssueKey(localIssueId);

	return useCallback(
		(fieldKey: string, optionId?: string) => {
			if (issueKey) {
				openIssueView(issueKey, { layout: 'sidebar', section: 'overview' });
			}

			setFieldSidebar(fieldKey, optionId);
		},
		[issueKey, setFieldSidebar, openIssueView],
	);
};

export const useToggleIssueWatching = () => {
	const { data: currentUser } = useCurrentUser();
	const { toggleIssueWatching } = useIdeaActions();

	return useCallback(() => {
		toggleIssueWatching(currentUser);
	}, [currentUser, toggleIssueWatching]);
};

import React, { type ReactNode, type Ref } from 'react';
import { styled } from '@compiled/react';
import DropdownMenu, { DropdownItemRadio, DropdownItemRadioGroup } from '@atlaskit/dropdown-menu';
import { N200, N20A, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useGetFieldRollupOperations } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-rollup-hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useGetCurrentViewFieldRollup } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-rollup-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactNode;
	fieldKey: FieldKey;
};

/**
 * Wrapper to display possible rollup actions for a group cell.
 */
export const DropdownGroupCell = ({ children, fieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const rollupOptions = useGetFieldRollupOperations(fieldKey);
	const operation = useGetCurrentViewFieldRollup(fieldKey);
	const { setRollupFieldsValue } = useViewActions();

	// If there are no rollup options, don't render the dropdown.
	if (!rollupOptions.length) return null;

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...triggerProps }) => (
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				<Button {...triggerProps} ref={triggerRef as Ref<HTMLButtonElement>}>
					{children}
				</Button>
			)}
			shouldFlip
		>
			<DropdownItemRadioGroup id="actions">
				<DropdownItemRadio
					key="none"
					id="none"
					onClick={() => setRollupFieldsValue(fieldKey, null)}
					isSelected={!operation}
				>
					{formatMessage(messages.none)}
				</DropdownItemRadio>
				{rollupOptions.map((option) => (
					<DropdownItemRadio
						key={option}
						id={option}
						onClick={() => setRollupFieldsValue(fieldKey, option)}
						isSelected={operation === option}
					>
						{formatMessage(messages[option])}
					</DropdownItemRadio>
				))}
			</DropdownItemRadioGroup>
		</DropdownMenu>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Button = styled.button<{ disabled?: boolean; isSelected?: boolean }>({
	color: token('color.text.subtlest', N200),
	flex: 1,
	width: '100%',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	border: 'none',
	outline: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isSelected }) =>
		isSelected ? token('color.background.neutral.subtle.hovered', N20A) : 'inherit',
	textAlign: 'left',
	minHeight: '30px',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ disabled }) => (disabled ? 'inherit' : 'pointer'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active, :hover, :focus, :focus-within': {
		backgroundColor: token('color.background.neutral.subtle.hovered', N20A),
		color: token('color.text.subtle', N500),
		boxShadow: 'none',
	},
});

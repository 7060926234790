import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import { type Props, type State, initialState } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	DataPointsMergeOption,
	LinkedDeliveryIssuesMergeOption,
	AttachmentsMergeOption,
	DescriptionMergeFromOption,
	FieldsMergeFromOption,
} from './types';

type Actions = typeof actions;

const MergeIdeasDialogStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'MergeIdeasDialogStore',
});

const useMergeIdeasDialogStore = createHook(MergeIdeasDialogStore);

export const useMergeIdeasDialogState = (): State => {
	const [state] = useMergeIdeasDialogStore();
	return state;
};

const useMergeIdeasDialogActionsHook = createHook(MergeIdeasDialogStore, {
	selector: null,
});

export const useMergeIdeasDialogActions = () => {
	const [, stateActions] = useMergeIdeasDialogActionsHook();
	return stateActions;
};

export const useIsMergeEnabled = () => {
	const { targetIssue, mergeOptions, mergeFromOptions } = useMergeIdeasDialogState();
	return (
		targetIssue !== null &&
		(Object.keys(mergeOptions).length || Object.keys(mergeFromOptions).length)
	);
};

export const MergeIdeasDialogContainer = createContainer<State, Actions, Props>(
	MergeIdeasDialogStore,
);

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	configureFieldTooltip: {
		id: 'polaris-ideas.view-controls.config-timeline.field-selection.configure-field-tooltip',
		defaultMessage: 'Configure field',
		description: 'Tooltip for configuring field',
	},
	createGlobalFieldButton: {
		id: 'polaris-ideas.view-controls.config-timeline.field-selection.create-global-field-button',
		defaultMessage: 'Create a global date field',
		description: 'Label for create global date field button',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editField: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.base.edit-field',
		defaultMessage: 'Edit field',
		description: 'Button to edit the field',
	},
	fieldSettings: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.base.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button to open settings for the currently open field',
	},
	reverseAxisOrderTooltip: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.base.reverse-axis-order-tooltip',
		defaultMessage: 'Reverse order of values',
		description: 'Tooltip for reversing order of field values in matrix axis config',
	},
	setUpXAxis: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.base.set-up-x-axis',
		defaultMessage: 'Set up x-axis',
		description: 'Button for setting up x-axis in matrix axis config',
	},
	setUpYAxis: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.base.set-up-y-axis',
		defaultMessage: 'Set up y-axis',
		description: 'Button for setting up y-axis in matrix axis config',
	},
	createGlobalFieldButton: {
		id: 'polaris-common.field-config.add.create-global-field-button',
		defaultMessage: 'Create a global field',
		description: 'Label for creating a global field button',
	},
});

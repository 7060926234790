import {
	createIdeaHook as createIdeaHookMain,
	createHigherLevelIdeaHook as createHigherLevelIdeaHookMain,
} from '../main.tsx';
import type { Props, State } from '../types.tsx';

export const createIdeaHook = createIdeaHookMain;

export const createHigherLevelIdeaHook = <TArg1, TReturnValue>(
	selectorCreator: (arg1: TArg1) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1) => TReturnValue) =>
	createHigherLevelIdeaHookMain<TReturnValue, [TArg1]>(selectorCreator);

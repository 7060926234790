import { getInsights } from '../../services/polaris-api/get-insights-for-idea/index.tsx';
import type {
	FetchInsightsForIssueRequest,
	ProviderProps,
	RemoteInsightsForIssueFetcher,
} from '../../types.tsx';

export const fetchInsightsForIssue =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteInsightsForIssueFetcher =>
	({ issueId }: FetchInsightsForIssueRequest) =>
		getInsights(apolloClient, cloudId, projectId, issueId).then((insights) => [
			...insights.map((insight) => ({
				...insight,
				snippets: [
					...insight.snippets.map((snippet) => ({
						...snippet,
						properties: {
							...snippet.properties,
						},
					})),
				],
			})),
		]);

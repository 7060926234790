import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CreateViewRequest, UpdateFilter } from '../../../common/types.tsx';
import { getSortMode } from '../../../common/utils.tsx';
import { getViewARI } from '../utils/index.tsx';

type Nullable<T> = { [K in keyof T]: T[K] | null };
export type ViewCreateRequest = Omit<Nullable<Partial<ViewResponse>>, 'filter'> & {
	filter: UpdateFilter[];
};

const transformViewPayload = (props: CreateViewRequest): ViewCreateRequest => ({
	name: props.name,
	visualizationType: props.kind,
	description: props.description,
	fields: props.fields,
	filter: props.filter,
	sort: props.sort,
	sortMode: getSortMode(props.sortMode),
	hidden: props.hidden,
	groupBy: props.groupBy,
	verticalGroupBy: props.verticalGroupBy,
	hideEmptyGroups: props.hideEmptyGroups,
	verticalGroupValues: props.verticalGroupValues,
	hideEmptyColumns: props.hideEmptyColumns,
	groupValues: props.groupValues,
	tableColumnSizes: props.tableColumnSizes,
	fieldRollups: props.fieldRollups,
	matrixConfig: props.matrixConfig,
	timelineConfig: props.timelineConfig,
	enabledAutoSave: props.enabledAutoSave,
});

export const createCollectionView = async (
	cloudId: CloudId,
	collectionUUID: string,
	props: CreateViewRequest,
): Promise<{ viewId: string; uuid: string }> => {
	const view = await performPostRequest<ViewResponse>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views`,
		{
			body: JSON.stringify(transformViewPayload(props)),
		},
	);

	return { viewId: getViewARI(cloudId, view.uuid), uuid: view.uuid };
};

import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { COLLECTIONS_TOUR_DISMISSED } from '../../../constants.tsx';
import type { Props, State } from '../../../types.tsx';

export const loadCollectionsTourDismissed =
	(): Action<State, Props, Promise<boolean | undefined>> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().collections.tourDismissed === 'boolean') {
			return;
		}

		try {
			const tourDismissed = await getUserProperty<boolean>(accountId, COLLECTIONS_TOUR_DISMISSED);

			const state = getState();

			setState({
				...state,
				collections: {
					...state.collections,
					tourDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && error.statusCode === 404) {
				const state = getState();

				setState({
					...state,
					collections: {
						...state.collections,
						tourDismissed: false,
					},
				});

				return;
			}

			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.project-fields.tour',
				`Failed to get user property with key: ${COLLECTIONS_TOUR_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
		return getState().collections.tourDismissed;
	};

export const setCollectionsTourDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			collections: {
				...state.collections,
				tourDismissed: true,
			},
		});

		try {
			await setUserProperties(accountId, COLLECTIONS_TOUR_DISMISSED, 'true');
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.project-fields.tour',
				`Failed to set user property with key: ${COLLECTIONS_TOUR_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};

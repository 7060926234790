import React, { useMemo } from 'react';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder/src/index.tsx';
import {
	useMultiSelect,
	useSingleSelect,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { SelectField } from './main.tsx';
import type { Props } from './types.tsx';

export const SingleSelectField = (props: Props) => {
	const { localIssueId, fieldKey } = props;
	const fieldOptions = useSingleSelect(fieldKey, localIssueId);
	return (
		<>
			{whyDidYouRenderPlaceholder('SingleSelectField')}
			<SelectField options={fieldOptions} {...props} />
		</>
	);
};

export const MultiSelectField = (props: Props) => {
	const { localIssueId, fieldKey, cappedFieldsDisplay } = props;
	const fieldOptions = useMultiSelect(fieldKey, localIssueId);
	const cappedFieldOptions = useMemo(() => fieldOptions.slice(0, 1), [fieldOptions]);

	return (
		<>
			{whyDidYouRenderPlaceholder('MultiSelectField')}
			<SelectField
				options={cappedFieldsDisplay ? cappedFieldOptions : fieldOptions}
				isMulti
				{...props}
			/>
		</>
	);
};

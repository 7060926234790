import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { State } from '../types.tsx';

export const getInsights = (state: State): Insight[] | null => state.project.insights;

export const getInsightsCount = (state: State): number => (state.project.insights || []).length;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_INSIGHTS: Record<string, any> = {};

export const getInsightsByIssueId = createSelector(getInsights, (insights) => {
	if (insights === null) {
		return EMPTY_INSIGHTS;
	}
	return groupBy(insights || [], (i) =>
		i.container ? getAriConfig(i.container).resourceId : undefined,
	);
});

import React from 'react';
import { useIsSingleIssueLoaded } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useIsInitialized as useIsIssuesInitialized,
	useIsLoadingLinkedIssues,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useLinkedDeliveryIssuesExist,
	useLinkedProgress,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useStatusCategories } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/status-categories-hook.tsx';
import { LinkedIssuesProgressComponent } from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export type FieldProps = {
	fieldKey: FieldKey;
};

export const DeliveryProgress = ({ fieldKey }: FieldProps) => {
	const ideaId = useSelectedIssue();
	const field = useField(fieldKey);
	const progress = useLinkedProgress(ideaId);
	const statusCategories = useStatusCategories();
	const isLoading = useIsLoadingLinkedIssues();
	const isIssuesInitialized = useIsIssuesInitialized();
	const isSingleIssueLoaded = useIsSingleIssueLoaded();
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(ideaId || '');

	if (ideaId === undefined || field === undefined || !deliveryIssuesExist) {
		return null;
	}

	return (
		<LinkedIssuesProgressComponent
			progress={progress}
			fieldKey={fieldKey}
			statusCategories={statusCategories}
			isLoading={isLoading || (!isIssuesInitialized && !isSingleIssueLoaded)}
			height={4}
		/>
	);
};

import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../types.tsx';

export const checkUrl =
	(url: string) =>
	async ({ getState, setState }: StoreActionApi<State>): Promise<boolean> => {
		const { states, client } = getState();
		if (!client) {
			return Promise.resolve(false);
		}
		if (states[url] !== undefined) {
			return Promise.resolve(states[url]);
		}

		setState({
			states: {
				...getState().states,
				[url]: false,
			},
		});

		return client
			.fetchData(url)
			.then((data) => data)
			.catch(() => false)
			.then((data) => {
				setState({
					states: {
						...getState().states,
						[url]: data,
					},
				});
				return Boolean(data);
			});
	};

import { useCallback } from 'react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useDroppableEvents,
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { handleDragDropFieldsSort } from '../utils.tsx';

type HandleSortProps = {
	srcId: string;
	dstId: string;
	edge: Edge;
};

type DroppableHandlerProps = {
	fields: Field[];
	onFieldsLegacyUpdate: (fieldKey: FieldKey, movedToKey: FieldKey) => void;
	onFieldsNextUpdate?: (updatedFields: Field[]) => void;
	children: React.ReactNode;
};

const DroppableHandlerLegacy = ({
	children,
	fields,
	onFieldsLegacyUpdate,
}: DroppableHandlerProps) => {
	const handleSort = useCallback(
		({ srcId, dstId, edge }: HandleSortProps) => {
			handleDragDropFieldsSort({
				srcId,
				dstId,
				edge,
				selectedFields: fields,
				onFieldsSort: onFieldsLegacyUpdate,
			});
		},
		[fields, onFieldsLegacyUpdate],
	);

	useDroppableEvents({
		onSort: handleSort,
	});

	return children;
};

const DroppableHandlerNext = ({ children, fields, onFieldsNextUpdate }: DroppableHandlerProps) => {
	const handleSort: DroppableCollectionProps<Field, FieldKey>['onSort'] = useCallback(
		({ updatedCollection }) => {
			onFieldsNextUpdate?.(updatedCollection);
		},
		[onFieldsNextUpdate],
	);

	useDroppableEventsCollectionUpdate({
		onSort: handleSort,
		collection: fields,
		getSrcIdForCollectionItem: (field) => field.key,
	});

	return children;
};

export const DroppableHandler = componentWithFG(
	'polaris_improved_dnd_handling',
	DroppableHandlerNext,
	DroppableHandlerLegacy,
);

/** @jsx jsx */
import React, { memo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { Bleed, Box, Text, xcss } from '@atlaskit/primitives';
import { type RendererProps, ReactRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { smartLinksDefault } from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	useFieldDescription,
	useIsHidden,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useRightSidebarActions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { FixedFieldDescription } from '@atlassian/jira-polaris-component-fixed-field-description/src/ui/index.tsx';
import { parseAdfString } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import {
	IssuelessAdfController,
	WaitForAdfConsumerProps,
} from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import messages from './messages.tsx';

export type FieldDescriptionProps = {
	fieldKey: string;
};

export const FieldDescription = ({ fieldKey }: FieldDescriptionProps) => {
	const fieldDescription = useFieldDescription(fieldKey);
	const [, { openOnField }] = useRightSidebarActions();
	const isHidden = useIsHidden(fieldKey);
	const { formatMessage } = useIntl();

	const openFieldSettings = useCallback(() => {
		openOnField(fieldKey, undefined, 'projectFieldsPage');
	}, [fieldKey, openOnField]);

	return (
		<PolarisTooltip
			testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.cell.description"
			content={
				<Box onClick={openFieldSettings} role="button">
					<Bleed xcss={clickableDescriptionContainerStyles} all="space.050">
						{fieldDescription.fixed ? (
							<FixedFieldDescription
								fieldKey={fieldKey}
								fallbackDescription={
									<Text as="p" color="color.text.subtlest">
										{formatMessage(messages.noDescription)}
									</Text>
								}
							/>
						) : (
							<RenderedDescription value={fieldDescription.value} />
						)}
					</Bleed>
				</Box>
			}
			maxWidth="350px"
			position="bottom-start"
		>
			{fieldDescription.fixed ? (
				<div css={fixedDescriptionWrapperStyles}>
					<FixedFieldDescription
						fieldKey={fieldKey}
						fallbackDescription={
							<Text as="p" color="color.text.subtlest">
								{formatMessage(messages.noDescription)}
							</Text>
						}
					/>
				</div>
			) : (
				<RenderedDescription value={fieldDescription.value} isCompact isHidden={isHidden} />
			)}
		</PolarisTooltip>
	);
};

type RenderedDescriptionProps = {
	value?: string;
	isCompact?: boolean;
	isHidden?: boolean;
};

const RenderedDescription = memo(
	({ value, isCompact = false, isHidden = false }: RenderedDescriptionProps) => {
		const { formatMessage } = useIntl();

		if (!value) {
			return (
				<Text as="p" color="color.text.subtlest">
					{formatMessage(messages.noDescription)}
				</Text>
			);
		}

		const rendererProps: Omit<RendererProps, 'document'> = isCompact
			? {
					truncated: true,
					maxHeight: 24, // One line of text
					fadeOutHeight: 0,
				}
			: {};

		const adf = parseAdfString(value);

		return (
			<div css={[isCompact && truncatedTextStyles, isHidden && hiddenFieldStyles]}>
				<IssuelessAdfController projectId="" projectKey="">
					<WaitForAdfConsumerProps>
						{({ akRendererProps }) => (
							<ReactRenderer
								{...akRendererProps}
								{...rendererProps}
								document={adf}
								smartLinks={smartLinksDefault}
								UNSTABLE_allowTableResizing={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
								UNSTABLE_allowTableAlignment={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
							/>
						)}
					</WaitForAdfConsumerProps>
				</IssuelessAdfController>
			</div>
		);
	},
);

const clickableDescriptionContainerStyles = xcss({
	padding: 'space.050',
	':hover': {
		cursor: 'pointer',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const truncatedTextStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document > p': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

const hiddenFieldStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document > p': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N100),
	},
});

const fixedDescriptionWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	br: {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

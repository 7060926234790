import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Axis, AxisGroupValue } from '../../../types.tsx';
import type { Props, State } from '../../types.tsx';

export const updateAxisState =
	(
		xAxis: Axis,
		yAxis: Axis,
		zAxis: Axis,
		isAxesDragDisabled: boolean,
		xAxisLocked: boolean,
		yAxisLocked: boolean,
	) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			xAxis,
			yAxis,
			zAxis,
			isAxesDragDisabled,
			xAxisLocked,
			yAxisLocked,
		});
	};

export const updateAxisOptions =
	(options: AxisGroupValue[], dimension: string) =>
	(storeApi: StoreActionApi<State>, props: Props) => {
		props.onSortAxisOptions && props.onSortAxisOptions(options, dimension);
	};

import React, { forwardRef, useCallback, useEffect, useState, type ComponentType } from 'react';
import isFunction from 'lodash/isFunction';

// Code originally copied from
// @see src/packages/software/roadmap/timeline-table/src/common/utils/use-resize-observer.js
//
// Today the implementation has changed, and parts of the code still is located in the `software/` package
// @see https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/src/packages/software/roadmap/timeline-table/src/common/utils/use-resize-observer/index.tsx?at=b7250dbdae5822a9468ba24d71c9099d9a38f87b

export type OnSizeChangedCallback = (width: number, height: number) => void;

export type SizedProps = {
	onSizeChanged?: OnSizeChangedCallback;
};

export type SizedOptions = {
	shouldUseRequestAnimationFrame?: boolean;
};

function useResizeObserver(
	element: HTMLElement | null,
	callback: OnSizeChangedCallback | undefined,
	{ shouldUseRequestAnimationFrame }: SizedOptions,
) {
	useEffect(() => {
		let resizeObserver: ResizeObserver;
		let rafId: number;

		if (element && callback && typeof ResizeObserver !== 'undefined') {
			resizeObserver = new ResizeObserver((entries) => {
				const run = () => {
					for (const entry of entries) {
						const { width, height } = entry.contentRect;
						callback(width, height);
					}
				};

				if (shouldUseRequestAnimationFrame) {
					rafId = requestAnimationFrame(run);
				} else {
					run();
				}
			});
			resizeObserver.observe(element);
		}

		return () => {
			cancelAnimationFrame(rafId);
			resizeObserver?.disconnect();
		};
	}, [element, callback, shouldUseRequestAnimationFrame]);

	return null;
}

export function sized<ComponentProps>(
	WrappedComponent: ComponentType<Omit<ComponentProps, keyof SizedProps>>,
	options: SizedOptions = {
		shouldUseRequestAnimationFrame: true,
	},
) {
	return forwardRef<HTMLElement, ComponentProps & SizedProps>((props, ref) => {
		const { onSizeChanged, ...rest } = props;
		const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

		const handleRef = useCallback(
			(element: HTMLElement) => {
				setContainerRef(element);
				if (ref !== undefined && ref !== null) {
					if (isFunction(ref)) {
						ref(element);
					} else {
						// Re-assignment is required here
						// to pass ratcheting on CI for no-param-reassign rule
						const _ref = ref;
						_ref.current = element;
					}
				}
			},
			[ref],
		);

		useResizeObserver(containerRef, onSizeChanged, options);

		return <WrappedComponent {...rest} ref={handleRef} />;
	});
}

import { useCallback, useMemo, useState, useEffect } from 'react';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { addMonths, addQuarters, endOfQuarter, format, isBefore, startOfQuarter } from 'date-fns';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldValuesUpdateRequest } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/update-field-value/types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useJiraIdToLocalIssueId,
	useLocalIssueIdToJiraIssueId,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useMultiSelect,
	usePeople,
	useStringListValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsSorted } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import {
	useTimelineDateFieldsKeys,
	useTimelineDuration,
	useTimelineItemIds,
	useTimelineItems,
} from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewArrangementInformation,
	useCurrentViewTimelineMode,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	PolarisTimelineMode,
	type GroupValueIdToIdArrangement,
} from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { Header as TimelineHeader } from '@atlassian/jira-polaris-lib-timeline/src/common/types/timeline/index.tsx';
import {
	type GroupedItemArrangement,
	type GroupId,
	type ItemRows,
	type ChangedItem,
	NO_VALUE_GROUP_ID,
} from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import type { ExtendedOption } from '../../../../common/utils/board.tsx';
import { useBucketIssueIds } from '../../../right-sidebar/ideas-bucket/index.tsx';
import { useGroupDropHandler, useRowGrouping } from '../../common/utils/group-options.tsx';

export const getHeaderLabel = (date: Date, timelineMode: PolarisTimelineMode) => {
	if (timelineMode === 'MONTHS') {
		return format(date, 'MMMM yyyy');
	}
	return `${format(startOfQuarter(date), 'MMMM')}-${format(endOfQuarter(date), 'MMMM yyyy')}`;
};

export const useHeaders = () => {
	const { startDate: initialStartDate, endDate } = useTimelineDuration();
	const mode = useCurrentViewTimelineMode();

	return useMemo(() => {
		if (!initialStartDate || !endDate || !mode) {
			return [];
		}

		let startDate = initialStartDate;
		const result: TimelineHeader[] = [];

		while (isBefore(startDate, endDate)) {
			const header = getHeaderLabel(startDate, mode);
			const subheaders = [];
			if (mode === PolarisTimelineMode.QUARTERS) {
				let startDateOfQuarter = startOfQuarter(startDate);
				while (isBefore(startDateOfQuarter, endOfQuarter(startDate))) {
					subheaders.push(format(startDateOfQuarter, 'MMMM'));
					startDateOfQuarter = addMonths(startDateOfQuarter, 1);
				}
			}
			result.push({ header, subheaders });
			startDate =
				mode === PolarisTimelineMode.MONTHS ? addMonths(startDate, 1) : addQuarters(startDate, 1);
		}

		return result;
	}, [endDate, initialStartDate, mode]);
};

export const getNewGroupValue = (
	groupIds: GroupId[],
	extendedOptions: ExtendedOption<unknown>[],
): unknown[] | unknown | undefined => {
	const optionsByGroupId = keyBy(extendedOptions, ({ groupIdentity }) => String(groupIdentity));
	const values = groupIds.map((groupId) => optionsByGroupId[groupId].value);
	if (values.length === 0) {
		return undefined;
	}
	return Array.isArray(values[0]) ? flatten(values) : values[0];
};

export const useUpdateItem = () => {
	const allItems = useTimelineItems();
	const [verticalGroupByField, extendedOptions] = useRowGrouping();
	const { updateFieldValues } = useIssueActions();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();

	return useCallback(
		(item: ChangedItem) => {
			if (!startDateFieldKey || !endDateFieldKey) {
				return;
			}

			const currentItem = allItems.find(({ id }) => id === item.id);
			const { startDateInterval, endDateInterval, oldGroupId, newGroupIds } = item;
			const isCurrentItemFromBucket = oldGroupId === undefined;
			const isCurrentItemFromNoValueGroup = oldGroupId === NO_VALUE_GROUP_ID;
			const isStartDateChanged =
				startDateInterval.value.start !== currentItem?.startDateInterval.value.start;
			const isEndDateChanged =
				endDateInterval.value.start !== currentItem?.endDateInterval.value.start;

			const fields: FieldValuesUpdateRequest['fields'] = {
				...(isStartDateChanged && {
					[startDateFieldKey]: {
						newValue: JSON.stringify({
							start: startDateInterval.value.start,
							end: startDateInterval.value.end,
						}),
					},
				}),
				...(isEndDateChanged && {
					[endDateFieldKey]: {
						newValue: JSON.stringify({
							start: endDateInterval.value.start,
							end: endDateInterval.value.end,
						}),
					},
				}),
			};

			if (verticalGroupByField?.editable && newGroupIds) {
				const newValue = getNewGroupValue(newGroupIds, extendedOptions || []);
				const { type, key } = verticalGroupByField;

				fields[key] = {
					newValue,
					appendMultiValues: isCurrentItemFromBucket,
				};

				if (type === FIELD_TYPES.CONNECTION && oldGroupId && !isCurrentItemFromNoValueGroup) {
					// disconnect from an issue that represents the old group
					fields[key].removeValue = [{ id: oldGroupId }];
					fields[key].appendMultiValues = true;
				}
			}

			updateFieldValues({
				localIssueIds: [item.id],
				fields,
			});
		},
		[
			startDateFieldKey,
			endDateFieldKey,
			allItems,
			verticalGroupByField,
			updateFieldValues,
			extendedOptions,
		],
	);
};

export const useUpdateItemOld = () => {
	const [item, setUpdatedItem] = useState<ChangedItem | null>(null);
	const [verticalGroupByField, extendedOptions] = useRowGrouping();
	const { updateFieldValues } = useIssueActions();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();
	const allItems = useTimelineItems();
	const bucketIssueIds = useBucketIssueIds('');

	const groupByFieldKey = verticalGroupByField?.key ?? '';
	const itemId = item?.id ?? '';
	const people = usePeople(groupByFieldKey, itemId);
	const multiSelectOptions = useMultiSelect(groupByFieldKey, itemId);
	const labels = useStringListValue(groupByFieldKey, itemId);

	useEffect(() => {
		if (!item || !startDateFieldKey || !endDateFieldKey) {
			return;
		}

		const { startDateInterval, endDateInterval } = item;
		const isCurrentItemFromBucket = bucketIssueIds.includes(item.id);
		const currentItem = allItems.find((issue) => issue.id === item.id);

		const isStartDateChanged =
			startDateInterval.value.start !== currentItem?.startDateInterval.value.start;
		const isEndDateChanged =
			endDateInterval.value.start !== currentItem?.endDateInterval.value.start;

		const fields: FieldValuesUpdateRequest['fields'] = {
			...(isStartDateChanged && {
				[startDateFieldKey]: {
					newValue: JSON.stringify({
						start: startDateInterval.value.start,
						end: startDateInterval.value.end,
					}),
				},
			}),
			...(isEndDateChanged && {
				[endDateFieldKey]: {
					newValue: JSON.stringify({
						start: endDateInterval.value.start,
						end: endDateInterval.value.end,
					}),
				},
			}),
		};

		if (verticalGroupByField !== undefined && item.newGroupIds && verticalGroupByField.editable) {
			let newValue = getNewGroupValue(item.newGroupIds, extendedOptions || []);

			// https://pi-dev-sandbox.atlassian.net/browse/POL-8235
			if (isCurrentItemFromBucket && Array.isArray(newValue)) {
				const { type: fieldType } = verticalGroupByField;

				if ((fieldType === FIELD_TYPES.PEOPLE || fieldType === FIELD_TYPES.JSW_PEOPLE) && people) {
					newValue = uniqBy([...people, ...newValue], 'accountId');
				} else if (
					(fieldType === FIELD_TYPES.MULTI_SELECT || fieldType === FIELD_TYPES.JSW_MULTI_SELECT) &&
					multiSelectOptions
				) {
					newValue = uniqBy([...multiSelectOptions, ...newValue], 'id');
				} else if (
					(fieldType === FIELD_TYPES.LABELS || fieldType === FIELD_TYPES.CUSTOM_LABELS) &&
					labels
				) {
					newValue = uniq([...labels, ...newValue]);
				}
			}

			fields[verticalGroupByField.key] = { newValue };

			if (fg('jpd_issues_relationships')) {
				if (
					verticalGroupByField.type === FIELD_TYPES.CONNECTION &&
					item.oldGroupId !== NO_VALUE_GROUP_ID
				) {
					fields[verticalGroupByField.key] = {
						newValue,
						removeValue: item.newGroupIds?.length ? [{ id: item.oldGroupId }] : undefined,
						appendMultiValues: true,
					};
				}
			}
		}

		updateFieldValues({
			localIssueIds: [item.id],
			fields,
		});

		setUpdatedItem(null);
	}, [
		allItems,
		bucketIssueIds,
		endDateFieldKey,
		extendedOptions,
		item,
		labels,
		multiSelectOptions,
		people,
		startDateFieldKey,
		updateFieldValues,
		verticalGroupByField,
	]);

	return setUpdatedItem;
};

export const useLocalArrangementInformation = ():
	| Record<string, GroupedItemArrangement>
	| undefined => {
	const isSorted = useIsSorted();
	const remoteArrangement = useCurrentViewArrangementInformation();
	const jiraIdToLocalIssueId = useJiraIdToLocalIssueId();

	if (isSorted || remoteArrangement === undefined) {
		return undefined;
	}
	return mapValues(remoteArrangement, (itemArrangement: GroupedItemArrangement) =>
		mapValues(itemArrangement, (issuesJiraId) =>
			mapKeys(issuesJiraId, (_, jiraId) => jiraIdToLocalIssueId[Number.parseInt(jiraId, 10)]),
		),
	);
};

export const useOnArrangementUpdate = () => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const { updateArrangementInformation } = useViewActions();
	return useCallback(
		(newLocalGroupArrangement: GroupedItemArrangement) => {
			const newRemoteGroupArrangement: GroupValueIdToIdArrangement = mapValues(
				newLocalGroupArrangement,
				(itemRows: ItemRows) => mapKeys(itemRows, (_, itemId) => localIssueIdToJiraId[itemId]),
			);
			updateArrangementInformation(newRemoteGroupArrangement);
		},
		[localIssueIdToJiraId, updateArrangementInformation],
	);
};

export const useOnGroupOrderChanged = () => {
	const groupDropHandler = useGroupDropHandler();
	const onGroupOrderChanged = useCallback(
		({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
			groupDropHandler({ oldIndex, newIndex });
		},
		[groupDropHandler],
	);
	return onGroupOrderChanged;
};

export const useTimelineIdeaCount = () => {
	const itemIds = useTimelineItemIds();

	return itemIds.length;
};

import React, { memo } from 'react';
import { styled } from '@compiled/react';
import AtkButton from '@atlaskit/button';
import LinkIcon from '@atlaskit/icon/glyph/link';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Button = styled(AtkButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		padding: '0 4px',
	},
});

type Props = {
	showLabel?: boolean;
	onClicked: () => void;
};

export const LinkButton = memo<Props>(({ onClicked, showLabel = false }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip
			content={formatMessage(
				fg('polaris-issue-terminology-refresh')
					? messages.linkButtonCaptionIssueTermRefresh
					: messages.linkButtonCaption,
			)}
		>
			<Button
				testId="polaris-ideas.ui.idea-view.controls.link.button"
				iconBefore={
					<LinkIcon
						label={formatMessage(
							fg('polaris-issue-terminology-refresh')
								? messages.linkButtonCaptionIssueTermRefresh
								: messages.linkButtonCaption,
						)}
					/>
				}
				onClick={onClicked}
			>
				{showLabel &&
					formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.linkButtonCaptionIssueTermRefresh
							: messages.linkButtonCaption,
					)}
			</Button>
		</Tooltip>
	);
});

import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from '@atlassian/jira-intl';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldLabel } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useSubmitIdeaFormMandatoryFields } from '../../../controllers/project-properties/index.tsx';
import { messages } from './messages.tsx';

export const MAX_TEXT_FIELD_LENGTH = 255;

export const useValidateFieldValue = (fieldKey: FieldKey) => {
	const { formatMessage } = useIntl();
	const areFieldsMandatory = useSubmitIdeaFormMandatoryFields();
	const fieldLabel = useFieldLabel(fieldKey);
	return useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(value: any) => {
			const notAllFieldsRequired =
				areFieldsMandatory.value !== true && !areFieldsMandatory.isLoading;
			if (notAllFieldsRequired || fieldKey === REPORTER_FIELDKEY) {
				return undefined;
			}
			if (
				value === undefined ||
				(typeof value !== 'number' && isEmpty(value)) ||
				(typeof value === 'string' && value.trim().length === 0)
			) {
				return formatMessage(messages.fieldValueRequiredError, { fieldLabel });
			}
			return undefined;
		},
		[areFieldsMandatory, fieldKey, fieldLabel, formatMessage],
	);
};

import React from 'react';
import Button from '@atlaskit/button';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import FileCabinetOpenIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/file-cabinet-open/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages.tsx';
import { Container, Wrapper } from '../../../common/ui/container/styled.tsx';
import { ViewEmptyState } from '../../../common/ui/view-empty-state/index.tsx';
import type { ViewKindEmptyStateProps } from '../../../types.tsx';
import { messages } from './messages.tsx';

const HELP_ARTICLE_ID = '5GPI7wekG7dM53IcsM0xqD';
const HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/archive-and-restore-ideas/';

export const ArchiveViewEmptyState = ({ onButtonClick }: ViewKindEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			<Wrapper>
				<ViewEmptyState
					renderImage={FileCabinetOpenIllustrationWrapper}
					header={formatMessage(messages.header)}
					description={formatMessage(messages.description)}
					primaryAction={
						<HelpPanelButton
							appearance="link"
							spacing="none"
							articleId={HELP_ARTICLE_ID}
							onClick={onButtonClick}
							fallbackComponent={
								<Button
									appearance="link"
									spacing="none"
									rel="noopener noreferrer"
									target="_blank"
									href={HELP_FALLBACK_URL}
								>
									{formatMessage(commonMessages.linkArchiveLearnMore)}
								</Button>
							}
						>
							{formatMessage(commonMessages.linkArchiveLearnMore)}
						</HelpPanelButton>
					}
				/>
			</Wrapper>
		</Container>
	);
};

const FileCabinetOpenIllustrationWrapper = () => <FileCabinetOpenIllustration alt="" width={175} />;

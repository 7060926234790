import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { ContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import type { CollectionUUID } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fetchProject } from '../services/jira/fetch-project/index.tsx';
import { fetchProjectsForJql } from '../services/jira/fetch-projects-for-jql/index.tsx';
import type { State } from './types.tsx';

export const addProjectsMetadata =
	(containerId: ContainerId, projectsMetadata: ProjectMetadata[]): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		setState({
			[containerId]: {
				isLoading: false,
				error: undefined,
				metadata: {
					...state[containerId]?.metadata,
					...keyBy(projectsMetadata, 'id'),
				},
			},
		});
	};

export const removeProjectMetadata =
	(containerId: ContainerId, projectId: ProjectId): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const metadata = state[containerId]?.metadata;

		if (metadata === undefined) {
			return;
		}

		setState({
			[containerId]: {
				isLoading: false,
				error: undefined,
				metadata: omitBy(metadata, (projectMetadata) => projectMetadata.id === projectId),
			},
		});
	};

export const fetchProjectMetadata =
	(projectId: ProjectId): Action<State, void, Promise<void>> =>
	async ({ setState, dispatch }) => {
		setState({
			[projectId]: {
				isLoading: true,
				error: undefined,
				metadata: undefined,
			},
		});

		try {
			// fetch single project
			const project = await fetchProject(projectId);

			dispatch(addProjectsMetadata(projectId, [project]));
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.fetchProjectMetadataFailed', error));

				setState({
					[projectId]: {
						isLoading: false,
						error,
						metadata: undefined,
					},
				});
			}

			throw error;
		}
	};

export const fetchCollectionMetadata =
	(collectionId: CollectionUUID, jql: string | undefined): Action<State, void, Promise<void>> =>
	async ({ getState, setState, dispatch }) => {
		if (jql === undefined || jql.trim() === '') {
			return;
		}

		const state = getState();
		if (state[collectionId]?.isLoading || state[collectionId]?.metadata !== undefined) {
			return;
		}

		setState({
			[collectionId]: {
				isLoading: true,
				error: undefined,
				metadata: undefined,
			},
		});

		try {
			const projects = await fetchProjectsForJql(jql);

			dispatch(addProjectsMetadata(collectionId, projects));
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.fetchCollectionMetadataFailed', error),
				);

				setState({
					[collectionId]: {
						isLoading: false,
						error,
						metadata: undefined,
					},
				});
			}

			throw error;
		}
	};

export const fetchBulkProjectsMetadata =
	(
		containers: Record<CollectionUUID, ProjectKey[]>,
		jql: string,
	): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		if (Object.keys(containers).length === 0) {
			return;
		}

		try {
			await wrapWithExperience(experience.collections.loadProjects, async () => {
				// loading state
				const containerLoadingState = Object.keys(containers).reduce(
					(result, containerId: ContainerId) => {
						Object.assign(result, {
							[containerId]: {
								isLoading: true,
								isError: false,
								metadata: undefined,
							},
						});
						return result;
					},
					{},
				);

				setState({
					...getState(),
					...containerLoadingState,
				});

				// bulk fetch project data
				const projects = await fetchProjectsForJql(jql);
				const projectsByKey = keyBy(projects, 'key');

				// generate new state
				const containerData = Object.keys(containers).reduce((result, containerId: ContainerId) => {
					if (containers[containerId]) {
						Object.assign(result, {
							[containerId]: {
								isLoading: false,
								isError: false,
								metadata: containers[containerId].reduce((projectMap, projectKey) => {
									const project = projectsByKey[projectKey];
									if (project !== undefined) {
										Object.assign(projectMap, {
											[project.id]: project,
										});
									}
									return projectMap;
								}, {}),
							},
						});
					}
					return result;
				}, {});

				setState({
					...getState(),
					...containerData,
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionProjectsLoadingFailed', error),
				);
			}
			throw error;
		}
	};

import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isGlobalExperienceWorkspaceForCloudIds } from '../../atlas/global-experience/index.tsx';
import { type AvailableProductsResponse, ProductType, type Site } from './types.tsx';

const getAvailableProductsServiceUrl = () => '/gateway/api/app-switcher/api/available-products';

const getOrgId = (cloudId: string, sites: Site[]): string | undefined =>
	sites.find((site) => site.cloudId === cloudId)?.org?.id;

const hasAtlasAvailable = (site: Site): boolean =>
	site.availableProducts.find((product) => product.productType === ProductType.ATLAS) !== undefined;

const getCloudIdToPlatformExperienceEnabled = async (
	cloudId: string,
	sites: Site[],
): Promise<Record<string, boolean>> => {
	const orgId = getOrgId(cloudId, sites);
	const atlasAvailable: Record<string, boolean> = {};
	const cloudIdsOfOrgWithoutAtlasProduct: string[] = [];
	sites.forEach((site) => {
		if (site?.org?.id !== orgId) {
			return;
		}
		if (hasAtlasAvailable(site)) {
			atlasAvailable[site.cloudId] = true;
		} else {
			cloudIdsOfOrgWithoutAtlasProduct.push(site.cloudId);
		}
	});

	const globalExperienceResponses = await isGlobalExperienceWorkspaceForCloudIds(
		cloudIdsOfOrgWithoutAtlasProduct,
	);
	cloudIdsOfOrgWithoutAtlasProduct.forEach((orgCloudId, i) => {
		atlasAvailable[orgCloudId] = globalExperienceResponses[i] || false;
	});
	return atlasAvailable;
};

const addAtlasToAvailableProductsResponse = (
	response: AvailableProductsResponse,
	cloudIdToPlatformExperienceEnabled: Record<string, boolean>,
) => {
	response.sites.forEach((site) => {
		if (cloudIdToPlatformExperienceEnabled[site.cloudId]) {
			site.availableProducts.push({
				productType: ProductType.ATLAS,
				url: null,
			});
		}
	});
	if (Object.values(cloudIdToPlatformExperienceEnabled).some((enabled) => enabled)) {
		log.safeInfoWithoutCustomerData(
			'polaris.info.adapt-available-products',
			'Adapted available products with global experience data',
		);
	}
	return response;
};

const adaptAvailableProductsResponseWithGlobalExperienceForOrg = async (
	response: AvailableProductsResponse,
	cloudId: string,
) => {
	try {
		const cloudIdToPlatformExperienceEnabled = await getCloudIdToPlatformExperienceEnabled(
			cloudId,
			response.sites,
		);
		return addAtlasToAvailableProductsResponse(response, cloudIdToPlatformExperienceEnabled);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'polaris.error.adapt-available-products',
			'Failed to adapt available products with global experience data',
			error,
		);
		return response;
	}
};

export const getAvailableProductsIncludingGlobalExperienceForOrg = (
	cloudId: string,
): Promise<AvailableProductsResponse> =>
	fetchJson(getAvailableProductsServiceUrl()).then((availableProductsResponse) =>
		adaptAvailableProductsResponseWithGlobalExperienceForOrg(availableProductsResponse, cloudId),
	);

import React from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import messages from './messages.tsx';

type Props = {
	isOpen: boolean;
	summary: string | undefined;
	onCancel: () => void;
	onConfirm: () => void;
};

export const DeleteModal = (props: Props) => {
	const { isOpen, onCancel, onConfirm, summary } = props;
	const { formatMessage } = useIntl();

	return (
		<ModalTransitionUniversal>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog onClose={onCancel}>
						<ModalHeader>
							<ModalTitle appearance="danger">{formatMessage(messages.deleteHeader)}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							<p>{formatMessage(messages.description, { summary })}</p>
						</ModalBody>
						<ModalFooter>
							<Button appearance="danger" onClick={onConfirm}>
								{formatMessage(messages.delete)}
							</Button>
							<Button onClick={onCancel}>{formatMessage(messages.cancel)}</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransitionUniversal>
	);
};

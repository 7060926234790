import React from 'react';
import { styled } from '@compiled/react';
import merge from 'lodash/merge';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import { ButtonItem, Section, type CSSFn } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFeedbackModal } from '@atlassian/jira-polaris-common/src/controllers/feedback-modal/index.tsx';
import messages from './messages.tsx';

const cssFn: CSSFn = (currentStyles) => {
	const newStyle = {
		padding: `${token('space.050', '4px')} 18px`,
		color: currentStyles?.isSelected
			? token('color.text.brand', colors.B400)
			: token('color.text.subtle', colors.N500),
		'&:hover': {
			color: token('color.text', colors.N800),
		},
		'& [data-item-elem-before]': {
			width: 24,
			height: 24,
			'& svg': {
				width: 18,
				height: 18,
				marginTop: token('space.025', '2px'),
				marginLeft: 3,
			},
		},
	};
	return merge(currentStyles, newStyle);
};

export const FeedbackButton = () => {
	const { formatMessage } = useIntl();
	const [{ isFeedbackModalOpen }, { setIsFeedbackModalOpen }] = useFeedbackModal();

	return (
		<Section hasSeparator>
			<SectionInner>
				<ButtonItem
					id="polaris.ideas.give-feedback"
					onClick={() => setIsFeedbackModalOpen(true)}
					// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
					cssFn={cssFn}
					isSelected={isFeedbackModalOpen}
					iconBefore={<FeedbackIcon label="feedback" />}
				>
					{formatMessage(messages.giveFeedback)}
				</ButtonItem>
			</SectionInner>
		</Section>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionInner = styled.div({
	margin: `0 ${token('space.negative.100', '-8px')}`,
});

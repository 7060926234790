import React, {
	type FormEventHandler,
	useCallback,
	type ComponentType,
	useState,
	useEffect,
} from 'react';
import { styled, keyframes } from '@compiled/react';
import clamp from 'lodash/clamp';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { Box, xcss } from '@atlaskit/primitives';
import { Section, ButtonItem } from '@atlaskit/side-navigation';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { N20A } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { WEIGHT_TYPE_RATING } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	T300,
	N1000,
	N100,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import { useDecorationForValue } from '../../../../../controllers/field/selectors/decoration/hooks.tsx';
import { useFieldType } from '../../../../../controllers/field/selectors/field-hooks.tsx';
import {
	useFieldOption,
	useFieldOptionWeightType,
	useFieldOptionWeight,
	useHasDefaultFieldOptionWeights,
} from '../../../../../controllers/field/selectors/options-hooks.tsx';
import { DEFAULT_OPTION_WEIGHT } from '../../../../../controllers/field/types.tsx';
import { useFieldOptionsWithAriResolved } from '../../../../../controllers/issue/selectors/fields-hooks.tsx';
import { DeleteOptionHandler } from '../../../../../controllers/issue/utils/delete-option/index.tsx';
import { useWeightContext } from '../../../../config/fields/common/weight-context/index.tsx';
import { FIELD_ITEM_OUTER_SPACING } from '../../../../config/fields/field-item/constants.tsx';
import { HAS_MORE_GLOBAL_LABELS_FLAG_ID } from '../menu-list/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectOptionComponentProps = any;

type OptionComponentProps = {
	isMulti: boolean;
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
};

// we are forking the implementation (and the props with them) between the Popup variant
// and the Right Sidebar variant.  Despite some amount of duplicated code, it becomes far
// easier to reason about how to handle state and clicking back and forth.  This way the
// popup variant can remain basically unchanged, until it gets completely removed, and
// the right sidebar variant can take advantage of the new controller management model
// (e.g., getting rid of the janky closeAllOtherOptions)

type EditOptionComponentProps = OptionComponentProps & {
	hideEditButton?: boolean;
	setOpenOption?: (optionId: string) => void;
};

type RightSidebarOptionComponentProps = OptionComponentProps & {
	fieldKey?: FieldKey;
	openOption: string | null;
	setOpenOption: (arg1: string | null) => void;
	FieldDecorator: ComponentType<{
		id: string;
	}>;
};

export const createRightSidebarOptionComponent =
	({
		fieldKey = '',
		isMulti,
		DecoratedTagComponent,
		FieldDecorator,
		openOption,
		setOpenOption,
	}: RightSidebarOptionComponentProps) =>
	({
		optionId,
		isDragDisabled,
		isSelected,
		setSort,
		readonly = false,
	}: SelectOptionComponentProps) => {
		const { formatMessage } = useIntl();
		const { isEditingWeight } = useWeightContext();
		const fieldType = useFieldType(fieldKey);
		const decoration = useDecorationForValue(fieldKey, optionId);
		const weight = useFieldOptionWeight(fieldKey, optionId);
		const option = useFieldOption(fieldKey, optionId);
		const weightType = useFieldOptionWeightType(fieldKey);
		const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(fieldKey);
		const { updateValueDecoration, updateFieldOptionWeight } = useFieldActions();
		const fieldOptions = useFieldOptionsWithAriResolved(fieldKey);
		const hasOnlyOneOption =
			(fieldType === FIELD_TYPES.MULTI_SELECT ||
				fieldType === FIELD_TYPES.JSW_MULTI_SELECT ||
				fieldType === FIELD_TYPES.SINGLE_SELECT) &&
			fieldOptions?.length === 1;

		const isEditing = openOption === optionId;
		const isDeleteDisabled =
			fieldType === FIELD_TYPES.LABELS ||
			fieldType === FIELD_TYPES.CUSTOM_LABELS ||
			hasOnlyOneOption;
		const [weightInputValue, setWeightInputValue] = useState(weight);

		const openDialog = useCallback(() => {
			setOpenOption(optionId);
		}, [optionId]);

		const closeDialog = useCallback(() => {
			setOpenOption(null);
		}, []);

		const onWeightChanged = useCallback(
			(value: number | undefined) => {
				updateFieldOptionWeight(fieldKey, optionId, value ?? DEFAULT_OPTION_WEIGHT);
			},
			[optionId, updateFieldOptionWeight],
		);

		const onToggleEdit = () => {
			isEditing ? closeDialog() : openDialog();
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const onEnterKeyPress = (cb: (() => void) | (() => any)) => (e: any) => {
			if (e.key === 'Enter') {
				cb();
			}
		};

		const isClearFormattingDisabled =
			decoration === undefined ||
			(decoration.emoji === undefined &&
				decoration.backgroundColor === undefined &&
				!decoration.highlightContainer);

		// Clears option formattings
		const onClearFormatting = () => {
			decoration !== undefined &&
				updateValueDecoration({
					fieldKey,
					localDecorationId: decoration.localDecorationId,
					backgroundColor: undefined,
					emoji: undefined,
					highlightContainer: false,
					rules: [{ value: optionId, operator: ValueRuleOperator.EQ }],
				});
		};

		const getDeleteOptionTooltipMessage = () => {
			if (!isDeleteDisabled) {
				return undefined;
			}

			if (fieldType === FIELD_TYPES.LABELS || fieldType === FIELD_TYPES.CUSTOM_LABELS) {
				return formatMessage(messages.deleteLabelOptionDisabled);
			}

			return formatMessage(messages.deleteSelectOptionDisabled);
		};

		const onNumberWeightChanged: FormEventHandler<HTMLInputElement> = (event) => {
			const newWeight = parseInt(event.currentTarget.value, 10);

			if (Number.isNaN(newWeight)) {
				onWeightChanged(DEFAULT_OPTION_WEIGHT);
				setWeightInputValue(undefined);
				return;
			}

			const ONE_HUNDRED_MILLIONS = 100000000;
			const clampedNewWeight = clamp(newWeight, -ONE_HUNDRED_MILLIONS, ONE_HUNDRED_MILLIONS);

			setWeightInputValue(clampedNewWeight);
			onWeightChanged(clampedNewWeight);
		};

		const syncNumberInputValueWithStoreValue = useCallback(() => {
			setWeightInputValue(weight);
		}, [weight]);

		useEffect(() => {
			if (!isEditingWeight && weightInputValue === undefined) {
				syncNumberInputValueWithStoreValue();
			}
		}, [isEditingWeight, weightInputValue, syncNumberInputValueWithStoreValue]);

		useEffect(() => {
			if (weightType === WEIGHT_TYPE_RATING) {
				syncNumberInputValueWithStoreValue();
			}
		}, [weightType, syncNumberInputValueWithStoreValue]);

		// content is the decoration manager itself
		const content = (
			<FieldDecoratorWrapper>
				<FieldDecorator id={optionId} />
			</FieldDecoratorWrapper>
		);

		const container = (
			<DeleteOptionHandler fieldKey={fieldKey}>
				{({ onOptionDeleteRequested }) => (
					<MenuOption
						menuComponent={
							<Section>
								<MenuOptionWrapper>
									<ButtonItem
										onClick={onToggleEdit}
										// @ts-expect-error - TS2322 - Type '{ children: string; cssFn: () => CSSFn; onClick: () => void; onKeyPress: (e: any) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
										onKeyPress={onEnterKeyPress(onToggleEdit)}
									>
										{formatMessage(messages.editOption)}
									</ButtonItem>
								</MenuOptionWrapper>
								<MenuOptionWrapper isDisabled={isClearFormattingDisabled}>
									<ButtonItem
										isDisabled={isClearFormattingDisabled}
										onClick={onClearFormatting}
										// @ts-expect-error - TS2322 - Type '{ children: string; cssFn: () => CSSFn; isDisabled: boolean; onClick: () => void; onKeyPress: (e: any) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
										onKeyPress={onEnterKeyPress(onClearFormatting)}
									>
										{formatMessage(messages.clearFormattingOption)}
									</ButtonItem>
								</MenuOptionWrapper>
								<MenuOptionWrapper isDisabled={isDragDisabled}>
									<ButtonItem
										isDisabled={isDragDisabled}
										onClick={() => setSort && setSort(-1)}
										// @ts-expect-error - TS2322 - Type '{ children: string; cssFn: () => CSSFn; onClick: () => any; onKeyPress: (e: any) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
										onKeyPress={onEnterKeyPress(() => setSort && setSort(-1))}
									>
										{formatMessage(messages.moveUpOption)}
									</ButtonItem>
								</MenuOptionWrapper>
								<MenuOptionWrapper isDisabled={isDragDisabled}>
									<ButtonItem
										isDisabled={isDragDisabled}
										onClick={() => setSort && setSort(1)}
										// @ts-expect-error - TS2322 - Type '{ children: string; cssFn: () => CSSFn; onClick: () => any; onKeyPress: (e: any) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
										onKeyPress={onEnterKeyPress(() => setSort && setSort(1))}
									>
										{formatMessage(messages.moveDownOption)}
									</ButtonItem>
								</MenuOptionWrapper>
								<Tooltip content={getDeleteOptionTooltipMessage()}>
									<MenuOptionWrapper isDisabled={isDeleteDisabled}>
										<ButtonItem
											isDisabled={isDeleteDisabled}
											onClick={() => {
												onOptionDeleteRequested(optionId);
											}}
											// @ts-expect-error - TS2322 - Type '{ children: string; cssFn: () => CSSFn; onClick: () => void; onKeyPress: (e: any) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
											onKeyPress={onEnterKeyPress(() => onOptionDeleteRequested(optionId))}
										>
											{formatMessage(messages.removeOption)}
										</ButtonItem>
									</MenuOptionWrapper>
								</Tooltip>
							</Section>
						}
						isActive={isEditing && !isEditingWeight}
						isDragEnabled={!isDragDisabled && openOption === null}
						hideActionMenu={readonly || isEditingWeight}
						setOpenOption={setOpenOption}
						outerSpacing={FIELD_ITEM_OUTER_SPACING}
					>
						<Wrapper>
							<OptionContentContainerSidebar isMulti={isMulti}>
								<OptionWrapper>
									{isMulti && (
										<CheckboxWrapper>
											<Checkbox isChecked={isSelected} />
										</CheckboxWrapper>
									)}
									<OptionContent>
										<DecoratedTagComponent id={optionId} />
									</OptionContent>
									{isEditingWeight &&
										(weightType === WEIGHT_TYPE_RATING ? (
											<WeightingWrapper
												onClick={(e) => e.stopPropagation()}
												onKeyDown={() => undefined}
												role="none"
											>
												<PolarisIcon
													name={PolarisIconType.Scales}
													size="small"
													label="Weighting value"
												/>
												<Rating mainColor={T300} value={weight} onChange={onWeightChanged} />
											</WeightingWrapper>
										) : (
											<WeightingWrapper>
												<PolarisIcon
													name={PolarisIconType.Scales}
													size="small"
													label="Weighting value"
												/>
												<NumberInput
													type="number"
													appearance="standard"
													value={weightInputValue}
													isCompact
													onChange={onNumberWeightChanged}
												/>
											</WeightingWrapper>
										))}
									{option && !isEditing && !isEditingWeight && !hasDefaultFieldOptionWeights && (
										<WeightTagContainer>
											<WeightTag options={[option]} tooltipPosition="left" />
										</WeightTagContainer>
									)}
								</OptionWrapper>
							</OptionContentContainerSidebar>
						</Wrapper>
					</MenuOption>
				)}
			</DeleteOptionHandler>
		);

		return (
			<Box xcss={isEditing && !isEditingWeight ? optionContainerStyles : []}>
				{container}
				{isEditing && !isEditingWeight && content}
			</Box>
		);
	};

export const createEditOptionComponent =
	({ isMulti, hideEditButton, DecoratedTagComponent, setOpenOption }: EditOptionComponentProps) =>
	({ data, innerProps, innerRef, isFocused, isSelected }: SelectOptionComponentProps) => {
		const { formatMessage } = useIntl();
		const canEditFields = useCanEditFields();

		const { id } = data;

		const onEditOption = setOpenOption
			? () => {
					setOpenOption(id);
				}
			: null;

		const { onClick, ...innerPropsRest } = innerProps;

		if (data.id === HAS_MORE_GLOBAL_LABELS_FLAG_ID) {
			return null;
		}

		return (
			<Wrapper>
				<OptionContentContainer isMulti={isMulti} isFocused={isFocused} {...innerPropsRest}>
					<OptionWrapper>
						{isMulti ? (
							<CheckboxWrapper>
								<Checkbox
									onClick={onClick}
									ref={innerRef}
									isChecked={isSelected}
									label={
										<OptionContent>
											<DecoratedTagComponent id={id} compact />
										</OptionContent>
									}
								/>
							</CheckboxWrapper>
						) : (
							<OptionContent onClick={onClick}>
								<DecoratedTagComponent id={id} compact />
							</OptionContent>
						)}
						{!hideEditButton && canEditFields && onEditOption && (
							<Tooltip content={formatMessage(messages.editOption)}>
								<EditButtonWrapper isFocused={isFocused}>
									<Button
										onClick={onEditOption}
										aria-label={formatMessage(messages.editOption)}
										iconAfter={<EditorEditIcon label="" size="medium" />}
										appearance="subtle"
										spacing="none"
									/>
								</EditButtonWrapper>
							</Tooltip>
						)}
					</OptionWrapper>
				</OptionContentContainer>
			</Wrapper>
		);
	};

type ReadOnlyOptionProps = {
	fieldKey?: string;
	optionId: string;
	isActive?: boolean;
	isMulti?: boolean;
	isSelected?: boolean;
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
};

/**
 * A lightweight version of the right sidebar option component that has no interactions.
 * This component is meant to be used in areas where performance is crucial and
 * when the user cannot interract with the option (e.g. during a drag and drop).
 */
export const RightSidebarReadOnlyOption = (props: ReadOnlyOptionProps) => {
	const {
		fieldKey = '',
		isActive = false,
		isMulti = false,
		isSelected = false,
		optionId,
		DecoratedTagComponent,
	} = props;
	const option = useFieldOption(fieldKey, optionId);
	const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(fieldKey);

	const container = (
		<Wrapper isActive={isActive}>
			<InvisibleDragHandleMock>⠿</InvisibleDragHandleMock>
			<OptionContentContainerSidebar isMulti={isMulti}>
				<OptionWrapper outerSpacing={FIELD_ITEM_OUTER_SPACING}>
					{isMulti && (
						<CheckboxWrapper>
							<Checkbox isChecked={isSelected} />
						</CheckboxWrapper>
					)}
					<OptionContent>
						<DecoratedTagComponent id={optionId} />
					</OptionContent>
					{option && !hasDefaultFieldOptionWeights && (
						<WeightTagContainer>
							<WeightTag options={[option]} tooltipPosition="left" />
						</WeightTagContainer>
					)}
				</OptionWrapper>
			</OptionContentContainerSidebar>
			<InvisibleMoreButtonMock />
		</Wrapper>
	);

	return <Box>{container}</Box>;
};

const optionContainerStyles = xcss({
	borderTop: `1px solid ${token('color.border', '#ebecf0')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isActive?: boolean }>({
	display: 'flex',
	flex: '1 1 auto',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isActive }) =>
		isActive ? token('color.background.disabled', N20A) : undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContentContainer = styled.div<{ isMulti?: boolean; isFocused?: boolean }>({
	width: '100%',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isMulti }) =>
		isMulti
			? `0 ${token('space.100', '8px')}`
			: `0 ${token('space.100', '8px')} 0 ${token('space.150', '12px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isFocused }) =>
		isFocused ? token('color.background.neutral.subtle.hovered', N20A) : 'transparent',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContentContainerSidebar = styled(OptionContentContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		padding: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	width: '100%',
	margin: `${token('space.050', '4px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div<{ outerSpacing?: string }>({
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props) => props.outerSpacing,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldDecoratorWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WeightingWrapper = styled.div({
	alignItems: 'center',
	marginRight: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginRight: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WeightTagContainer = styled.div({
	alignItems: 'center',
	marginRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

const animationFadeIn = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditButtonWrapper = styled.div<{ isFocused: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isFocused }) => (isFocused ? 'flex' : 'none'),
	opacity: 0,
	animationName: `${animationFadeIn}`,
	animationDuration: '150ms',
	animationDelay: '300ms',
	animationFillMode: 'forwards',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuOptionWrapper = styled.div<{ isDisabled?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'& button': ({ isDisabled }) => ({
		padding: `${token('space.075', '6px')} ${token('space.200', '16px')}`,
		backgroundColor: 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: isDisabled ? token('color.text.disabled', '#6B778C') : token('color.text', N1000),
		...(!isDisabled && {
			'&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: token('color.text', N1000),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.background.neutral.hovered', N100),
			},
		}),
	}),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberInput = styled(Textfield)({
	maxWidth: '80px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [data-ds--text-field--input]': {
		height: '1.6em',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InvisibleDragHandleMock = styled.div({
	width: '16px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '13px',
	visibility: 'hidden',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InvisibleMoreButtonMock = styled.div({
	width: '24px',
	flexShrink: 0,
});

import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { HelpPanelVideoTutorialPulse as VideoTutorialType } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyHelpPanelVideoTutorialPulseInternal = lazyForPaint<typeof VideoTutorialType>(
	() =>
		import(
			/* webpackChunkName: "async-polaris-lib-onboarding-help-panel-video-tutorial-pulse" */ './index'
		).then(({ HelpPanelVideoTutorialPulse }) => HelpPanelVideoTutorialPulse),
	{ ssr: false },
);

export const LazyHelpPanelVideoTutorialPulse = () => (
	<Placeholder name="polaris-lib-onboarding-video-tutorial">
		<LazyHelpPanelVideoTutorialPulseInternal />
	</Placeholder>
);

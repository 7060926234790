import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { isWatching as isWatchingIssue } from '../../selectors/index.tsx';
import type { Watcher, Props, State } from '../../types.tsx';

export const watchIssue =
	(user: Watcher | null): Action<State, Props> =>
	async ({ setState, getState }, { issueKey, onDataUpdateFailed, issuesRemote }) => {
		const { watchInfo } = getState();
		if (!watchInfo || !issueKey || !user?.accountId) {
			return;
		}
		const newWatchersList = [...watchInfo.watchersList, user];
		setState({
			watchInfo: {
				watchersList: newWatchersList,
			},
		});
		issuesRemote
			.createIssueWatcher({
				issueIdOrKey: issueKey,
				accountId: user.accountId,
			})
			.catch(onDataUpdateFailed);
	};

export const unWatchIssue =
	(accountId: AccountId | undefined): Action<State, Props> =>
	async ({ setState, getState }, { issueKey, onDataUpdateFailed, issuesRemote }) => {
		const { watchInfo } = getState();
		if (!watchInfo || !issueKey || !accountId) {
			return;
		}
		const newWatchersList = watchInfo.watchersList.filter(
			(watcher: Watcher) => watcher.accountId !== accountId,
		);
		setState({
			watchInfo: {
				watchersList: newWatchersList,
			},
		});
		issuesRemote
			.deleteIssueWatcher({
				issueIdOrKey: issueKey,
				accountId,
			})
			.catch(onDataUpdateFailed);
	};

export const toggleIssueWatching =
	(user: Watcher | null): Action<State, Props> =>
	({ getState, dispatch }) => {
		const isWatching = isWatchingIssue(user?.accountId)(getState());
		if (isWatching) {
			dispatch(unWatchIssue(user?.accountId));
		} else {
			dispatch(watchIssue(user));
		}
	};

import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';

export const deleteCollectionView = async (
	collectionUUID: string,
	viewUUID: string,
): Promise<void> => {
	await performDeleteRequest<ViewResponse>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}`,
	);
};

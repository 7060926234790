import React, { useMemo, memo } from 'react';
import { styled } from '@compiled/react';
import type { RowRendererProps } from '../../common/types/react-base-table/index.tsx';
import { useRowComponent } from '../../controllers/selectors/components-hooks.tsx';
import {
	useFixedColumnIndices,
	useHighlightedRow,
	useInvisibleColumnPlaceholderWidthLeft,
	useIsFramedDisplayMode,
	useVisibleColumnIndices,
} from '../../controllers/selectors/ui-hooks.tsx';
import { ROW_BORDER_WIDTH, activeBorderColor, borderColor } from '../constants.tsx';

const DefaultRowComponent = memo<RowRendererProps>(({ cells, rowData }: RowRendererProps) => {
	const fixedColumnIndices = useFixedColumnIndices();
	const visibleColumnIndices = useVisibleColumnIndices();
	const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();
	const isFramedDisplayMode = useIsFramedDisplayMode();
	const highlightedRow = useHighlightedRow();
	const isHighlighted = highlightedRow === rowData.id;

	const fixedCells = useMemo(
		() => cells.filter((_, index) => fixedColumnIndices.includes(index)),
		[cells, fixedColumnIndices],
	);

	const visibleCells = useMemo(
		() =>
			cells.filter(
				(_, index) => visibleColumnIndices.includes(index) && !fixedColumnIndices.includes(index),
			),
		[cells, fixedColumnIndices, visibleColumnIndices],
	);

	return (
		<RowContainer
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={rowData.updated ? 'updated' : ''}
			data-testid="polaris-lib-list.ui.row.row"
		>
			{fixedCells}
			{placeholderWidthLeft > 0 && <PlaceholderLeft $width={placeholderWidthLeft} />}
			{visibleCells}
			{!isFramedDisplayMode && <PlaceholderRight isHighlighted={isHighlighted} />}
		</RowContainer>
	);
});

export const RowComponent = memo<RowRendererProps>(({ ...props }: RowRendererProps) => {
	const ConsumerRowComponent = useRowComponent();

	if (ConsumerRowComponent !== undefined) {
		return <ConsumerRowComponent {...props} DefaultComponent={DefaultRowComponent} />;
	}

	return <DefaultRowComponent {...props} />;
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RowContainer = styled.div({
	display: 'flex',
	width: '100%',
	height: '100%',
	backgroundColor: 'inherit',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLeft = styled.div<{ $width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ $width }) => `${$width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderRight = styled.div<{ isHighlighted: boolean }>({
	flex: 1,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderTop: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isHighlighted ? `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}` : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderBottom: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		`${ROW_BORDER_WIDTH}px solid ${isHighlighted ? activeBorderColor : borderColor}`,
});

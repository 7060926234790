import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fetchGiraMediaContext } from '../services/gira/media-context/index.tsx';
import { fetchViewsServiceMediaContext } from '../services/views-service/media-context/index.tsx';
import type { MediaRemote } from '../types.tsx';

const createMediaRemote = (): MediaRemote => ({
	fetchMediaContext: ({ issueKey }) => fetchGiraMediaContext(issueKey),
});

const { RemoteContextProvider, useRemoteFromContext } = createRemoteContext<MediaRemote>('media');

export const useMediaRemote = useRemoteFromContext;

export const MediaRemoteProvider: ChainableContextProvider = ({ children }) => {
	const remote = createMediaRemote();

	return <RemoteContextProvider remote={remote}>{children}</RemoteContextProvider>;
};

export const createSharingMediaRemote = (viewId: string): MediaRemote => ({
	fetchMediaContext: ({ issueKey }) => fetchViewsServiceMediaContext(viewId, issueKey, true),
});

export const createSharingMediaRemoteProvider =
	(viewId: string): ChainableContextProvider =>
	({ children }) => {
		const mediaRemote = useMemo(() => createSharingMediaRemote(viewId), []);
		return <RemoteContextProvider remote={mediaRemote}>{children}</RemoteContextProvider>;
	};

import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapPromiseWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import {
	createAndLinkIssue,
	createAndLinkIssues,
} from '../../services/jira/create-and-link-issues/index.tsx';
import { getAggregatedDeliveryProgress } from '../../services/jira/delivery-progress/index.tsx';
import { getChildIssues } from '../../services/jira/issue-children/index.tsx';
import { fetchDeliveryData } from '../../services/views-service/delivery/index.tsx';
import type { FetchAggregatedDeliveryProgressRequest, IssueDeliveryRemote } from './types.tsx';

export const createNotImplementedIssueDeliveryRemote = (): IssueDeliveryRemote => ({
	fetchChildIssues: notImplementedError('fetchChildIssues'),
	fetchAggregatedDeliveryProgress: notImplementedError('fetchAggregatedDeliveryProgress'),
	createAndLinkIssue: notImplementedError('createAndLinkIssue'),
	createAndLinkIssues: notImplementedError('createAndLinkIssues'),
});

export const createSharingIssueDeliveryRemote = (viewId: string): IssueDeliveryRemote => ({
	...createNotImplementedIssueDeliveryRemote(),
	fetchAggregatedDeliveryProgress: () => fetchDeliveryData(viewId),
});

export const createIssueDeliveryRemote = (): IssueDeliveryRemote => ({
	fetchChildIssues: ({ parentIssueKey, parentLinkFields, storyPointsCustomFieldId }) =>
		wrapPromiseWithExperience(
			getChildIssues(parentIssueKey, storyPointsCustomFieldId, parentLinkFields),
			experience.deliveryItems.loadChildIssues,
		),
	fetchAggregatedDeliveryProgress: (request: FetchAggregatedDeliveryProgressRequest) =>
		wrapPromiseWithExperience(
			getAggregatedDeliveryProgress(request),
			experience.deliveryItems.loadAggregatedDeliveryProgress,
		),
	createAndLinkIssue: (request) => createAndLinkIssue(request),
	createAndLinkIssues: (request) => createAndLinkIssues(request),
});

export const createJqlIssueDeliveryRemote = (): IssueDeliveryRemote => ({
	fetchChildIssues: ({ parentIssueKey, parentLinkFields, storyPointsCustomFieldId }) =>
		wrapPromiseWithExperience(
			getChildIssues(parentIssueKey, storyPointsCustomFieldId, parentLinkFields),
			experience.deliveryItems.loadChildIssues,
		),
	fetchAggregatedDeliveryProgress: (request: FetchAggregatedDeliveryProgressRequest) =>
		wrapPromiseWithExperience(
			getAggregatedDeliveryProgress(request),
			experience.deliveryItems.loadAggregatedDeliveryProgress,
		),
	createAndLinkIssue: (request) => createAndLinkIssue(request),
	createAndLinkIssues: (request) => createAndLinkIssues(request),
});

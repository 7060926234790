import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueRule,
	ValueOperator,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	rules: ValueRule[];
	emoji: string | undefined;
	color: string | undefined;
	value: number;
};

const { LT, LTE, GT, GTE } = ValueRuleOperator;

export const DateDecorationLabel = (props: Props) => {
	const { formatMessage } = useIntl();
	const { rules, color, emoji, value } = props;
	const emojiObject = useEmoji(emoji);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const ltValue = rules.find(({ operator }) => ([LT, LTE] as ValueOperator[]).includes(operator));
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const gtValue = rules.find(({ operator }) => ([GT, GTE] as ValueOperator[]).includes(operator));

	const title =
		gtValue === undefined && ltValue !== undefined
			? formatMessage(messages.overdue)
			: formatMessage(messages.comingSoon, { days: value });

	return <CommonDecoratedTag mainColor={color} emoji={emojiObject} value={title} />;
};

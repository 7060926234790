import { type RefObject, useRef, useEffect } from 'react';
import { isPageVisible } from '@atlassian/jira-common-page-visibility/src/index.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	INTERVAL_5M,
	TRACKABLE_URL_PATTERNS,
	IGNORED_STATUS_CODES,
	SEND_EVENTS_INTERVAL,
} from './constants.tsx';
import type { RequestRecord, RequestError } from './types.tsx';
import { sendAnalytics } from './utils/analytics/index.tsx';
import { getMethod, getCleanedPath } from './utils/index.tsx';

const mockFetch = (
	enabledRef: RefObject<boolean>,
	requestsRef: RefObject<RequestRecord[]>,
	errorsRef: RefObject<RequestError[]>,
	onFetch: (path: string) => void,
) => {
	const globalVar = typeof window !== 'undefined' ? window : globalThis;

	if (globalVar?.fetch && '__mocked__' in globalVar.fetch) {
		return null;
	}

	const originalFetch: typeof window.fetch = globalVar.fetch;

	function mockedFetch(urlOrRequset: string | URL | Request, params?: RequestInit) {
		if (!enabledRef.current || !requestsRef.current || !errorsRef.current) {
			return originalFetch(urlOrRequset, params);
		}
		const method = getMethod(urlOrRequset, params);
		const { path, originPath } = getCleanedPath(urlOrRequset);

		const result = originalFetch(urlOrRequset, params);

		if (
			!originPath ||
			!TRACKABLE_URL_PATTERNS.some((urlPattern: string) => originPath.startsWith(urlPattern))
		) {
			return result;
		}

		onFetch(path);

		const request: RequestRecord = {
			time: undefined,
			path,
			originPath,
			method,
			status: undefined,
		};

		requestsRef.current.push(request);
		return result
			.then((response) => {
				if (
					errorsRef.current &&
					requestsRef.current &&
					response.status >= 400 &&
					IGNORED_STATUS_CODES[response.status] !== true
				) {
					errorsRef.current.push({
						requestWithError: request,
						requests: [...requestsRef.current],
						isActiveTab: isPageVisible(),
						time: Date.now(),
					});
				}
				request.status = response.status;
				request.time = Date.now();
				return response;
			})
			.catch((error) => {
				request.status = -1;
				request.time = Date.now();
				throw error;
			});
	}

	mockedFetch.__mocked__ = true;

	if (globalVar?.fetch !== undefined) {
		globalVar.fetch = mockedFetch;
		return () => {
			if (globalVar.fetch === mockedFetch) {
				globalVar.fetch = originalFetch;
			}
		};
	}

	return null;
};

export const TrackFetchErrors = ({ onFetch }: { onFetch: (path: string) => void }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cleanupIntervalRef = useRef<NodeJS.Timeout | null>(null);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const enabledRef = useRef<boolean>(false);
	const requestsRef = useRef<RequestRecord[]>([]);
	const errorsRef = useRef<RequestError[]>([]);
	const cleanupRef = useRef<(() => void) | null>(null);

	useRunOnce(() => {
		intervalRef.current = setInterval(() => {
			if (errorsRef.current && errorsRef.current.length) {
				try {
					sendAnalytics(errorsRef.current, createAnalyticsEvent);
				} catch {
					// do nothing
				}
				errorsRef.current = [];
			}
		}, SEND_EVENTS_INTERVAL);

		cleanupIntervalRef.current = setInterval(() => {
			// filter requests older than 5 minutes
			requestsRef.current = requestsRef.current.filter(
				(request) => request.time === undefined || request.time > Date.now() - INTERVAL_5M,
			);
		}, INTERVAL_5M);

		cleanupRef.current = mockFetch(enabledRef, requestsRef, errorsRef, onFetch);
		enabledRef.current = true;
	});

	useEffect(
		() => () => {
			enabledRef.current = false;
			requestsRef.current = [];
			errorsRef.current = [];
			intervalRef.current !== null && clearInterval(intervalRef.current);
			cleanupIntervalRef.current !== null && clearInterval(cleanupIntervalRef.current);
			cleanupRef.current?.();
		},
		[],
	);

	return null;
};

import { createSelector } from 'reselect';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type { OptionFieldConfiguration } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { mapOptionWeightType } from '@atlassian/jira-polaris-lib-field-option-configuration/src/utils/map-option-weight-type/index.tsx';
import { createGetFieldByKey } from './field.tsx';

export const createGetOptions = (fieldKey: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);

	return createSelector(getFieldByKey, (field) => field?.options);
};

export const createGetOption = (fieldKey: FieldKey, jiraOptionId: string) => {
	const getOptionsSelector = createGetOptions(fieldKey);

	return createSelector(getOptionsSelector, (options?: FieldOption[]) => {
		const fieldOption = options?.find(
			(option: FieldOption) => option.jiraOptionId === jiraOptionId,
		);
		return fieldOption;
	});
};

export const createGetOptionCount = (fieldKey: FieldKey) => {
	const getOptions = createGetOptions(fieldKey);

	return createSelector(getOptions, (options) => (options === undefined ? 0 : options.length));
};

export const createGetOptionWeightById = (fieldKey: FieldKey, jiraOptionId: string) => {
	const getOptionSelector = createGetOption(fieldKey, jiraOptionId);

	return createSelector(getOptionSelector, (option?: FieldOption) => option?.weight);
};

export const createGetFieldOptionTypeById = (fieldKey: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);

	return createSelector(getFieldByKey, (field) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		mapOptionWeightType(field?.configuration as OptionFieldConfiguration),
	);
};

export const createAreGlobalLabelsLoading = (fieldKey: FieldKey) => {
	const getOptions = createGetOptions(fieldKey);

	return createSelector(getOptions, (options) => options === undefined);
};

export const createIsGlobalLabelsLoadingFailed = (fieldKey: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);

	return createSelector(getFieldByKey, (field) => !!field?.meta?.globalLabels?.error);
};

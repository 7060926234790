import { useMemo } from 'react';
import max from 'lodash/max';
import some from 'lodash/some';
import startsWith from 'lodash/startsWith';
import trim from 'lodash/trim';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const useNextValidAddValue = (
	inputValue: string | null | undefined,
	optionValues: string[],
) => {
	const { formatMessage } = useIntl();
	const input = trim(inputValue ?? undefined);

	const isSearchStringValidNewValue = useMemo(() => {
		if (input === null || input === undefined || input === '') {
			return false;
		}

		return !some(optionValues, (value) => value === inputValue);
	}, [input, inputValue, optionValues]);

	return useMemo(() => {
		if (isSearchStringValidNewValue) {
			return {
				value: input,
				isCustom: true,
			};
		}

		const newValue = formatMessage(messages.newValue);

		const highestPostfix = max(
			optionValues.map((value) => {
				if (startsWith(value, newValue)) {
					const postfix = trim(value.substring(newValue.length));
					if (postfix === '') {
						return 1;
					}
					return Number(postfix);
				}
				return -1;
			}),
		);

		if (highestPostfix === undefined || highestPostfix === -1) {
			return {
				value: newValue,
				isCustom: false,
			};
		}

		return {
			value: `${newValue} ${highestPostfix + 1}`,
			isCustom: false,
		};
	}, [formatMessage, input, isSearchStringValidNewValue, optionValues]);
};

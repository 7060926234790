import React, { useEffect, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { get as getAvatars } from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/rest.tsx';
import type { AvatarCollections } from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueTypeAvatar } from './issue-type-avatar.tsx';

type Props = {
	onSelect: (avatarId: string) => void;
	projectId: ProjectId;
};

export const AvatarPicker = ({ onSelect, projectId }: Props) => {
	const [avatarCollections, setAvatarCollections] = useState<null | AvatarCollections>(null);

	useEffect(() => {
		getAvatars(Number(projectId)).then(setAvatarCollections);
	}, [projectId]);

	return (
		<Box xcss={pickerBoxStyles}>
			<Tabs id="default">
				<TabList>
					<Tab>Icons</Tab>
				</TabList>
				<TabPanel>
					<Box xcss={panelStyles}>
						<Inline space="space.100" shouldWrap>
							{avatarCollections?.system.map((avatar) => {
								return (
									<IconButton
										key={avatar.id}
										label=""
										icon={() => <IssueTypeAvatar avatarId={avatar.id} size="xsmall" />}
										onClick={() => onSelect(avatar.id)}
									/>
								);
							})}
						</Inline>
					</Box>
				</TabPanel>
			</Tabs>
		</Box>
	);
};

const panelStyles = xcss({
	paddingBlock: 'space.150',
});

const pickerBoxStyles = xcss({
	paddingInline: 'space.150',
	width: '216px',
});

import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import omit from 'lodash/omit';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { Box, xcss } from '@atlaskit/primitives';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	Skeleton as SkeletonComponent,
	Spacer,
} from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	POLARIS_OAUTH_CLIENT_ID,
	POLARIS_UNSUPPORTED_OAUTH_CLIENT_ID,
} from '@atlassian/jira-polaris-common/src/common/types/snippet/constants.tsx';
import { useConfigurePropertiesDialogActions } from '@atlassian/jira-polaris-common/src/controllers/configure-properties-dialog/index.tsx';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useMediaClientConfig } from '@atlassian/jira-polaris-common/src/controllers/media/index.tsx';
import {
	UnfurlContainer as UnfurlStoreContainer,
	useUnfurlActions,
} from '@atlassian/jira-polaris-common/src/controllers/unfurl/index.tsx';
import type { ObjectResolved } from '@atlassian/jira-polaris-common/src/controllers/unfurl/types.tsx';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useHasUnsavedChanges } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useScrollableContainer } from '@atlassian/jira-polaris-common/src/ui/common/scrollable-container/main.tsx';
import { PolarisNavigationBlocker } from '@atlassian/jira-polaris-common/src/ui/navigation-blocker/index.tsx';
import { createCardWithUrlSnippetData } from '@atlassian/jira-polaris-common/src/ui/snippet/card/utils.tsx';
import { PolarisSnippet } from '@atlassian/jira-polaris-common/src/ui/snippet/index.tsx';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import type {
	SnippetData,
	SnippetPropertiesObject,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { isEmpty as isEmptyAdf } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async.tsx';
import { prepareForSave } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { SpotlightTypes } from '@atlassian/jira-polaris-lib-onboarding/src/common/types.tsx';
import { useSpotlights } from '@atlassian/jira-polaris-lib-onboarding/src/controllers/index.tsx';
import { useSmartCardActions } from '@atlassian/jira-polaris-lib-smart-card/src/controllers/index.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import {
	FireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useInsights, useInsightsActions } from '../../../../controllers/insights/main.tsx';
import {
	useIssueKey,
	useSnippetProviders,
} from '../../../../controllers/insights/selectors/meta-hooks.tsx';
import { PropertiesBar } from '../insight/properties/index.tsx';
import { SmartCardWrapper } from '../smart-card/index.tsx';
import {
	ADD_LINK_TO_INSIGHT_HINT_CLOSED_KEY,
	AddLinkHint,
	AddLinkTextHint,
} from './add-link-hint/index.tsx';
import { AddInsightButtons, CreateInsightButton } from './buttons/index.tsx';
import { CreateInsightTextHint } from './create-insight-hint/index.tsx';
import { messages } from './messages.tsx';
import { UnfurlForm } from './unfurl-form/index.tsx';

type AddInsightStatelessProps = {
	isDisabled: boolean;
	isUrlDefined: boolean;
	isUnsupported?: boolean;
	isUpdating?: boolean;
	isCheckingSmartCard: boolean;
	isEditing: boolean;
	isAddLinkHintEnabled: boolean;
	isCompact?: boolean;
	isDescriptionEmpty: boolean;
	descriptionAdf?: ADF | null;
	url: string | null;
	oauthClientId: string | null;
	timeZone?: string;
	snippetData: SnippetData | null;
	properties: SnippetPropertiesObject | null;
	polarisProperties: SnippetPropertiesObject | null;
	mediaClientConfig: MediaClientConfig;
	onPropertiesUpdate: (key: string, value: number | undefined | string[]) => void;
	onSetInitUrl: (url: string) => void;
	onSave: () => void;
	onInputChange: (value: string | undefined) => void;
	onEdit: () => void;
	onCloseAddLinkHint: () => void;
	onObjectResolved?: (arg1: ObjectResolved) => Promise<void> | void;
	onDescriptionChange: (description: ADF | null | undefined) => void;
	onConfigChanged: () => void;
	onUnfurlCancel: () => void;
	onCancel: () => void;
	onDirty?: (_: boolean) => void;
};

const UnfurlSnippet = ({
	url,
	isUnsupported,
	snippetData,
	data,
	properties,
	mediaClientConfig,
	oauthClientId,
	onConfigChanged,
}: Pick<
	AddInsightStatelessProps,
	| 'url'
	| 'isUnsupported'
	| 'snippetData'
	| 'properties'
	| 'timeZone'
	| 'oauthClientId'
	| 'mediaClientConfig'
	| 'onConfigChanged'
> & { data: SnippetData | null }) => {
	const { openDialog } = useConfigurePropertiesDialogActions();
	const { unfurl } = useUnfurlActions();

	const handleConfigChanged = useCallback(() => {
		onConfigChanged();
		unfurl();
	}, [onConfigChanged, unfurl]);

	const handleConfigureProperties = useCallback(() => {
		if (url === undefined || url === null || oauthClientId === null || !snippetData) {
			return;
		}
		openDialog({
			onConfigChanged: handleConfigChanged,
			oauthClientId,
			url,
			data: snippetData,
		});
	}, [url, oauthClientId, snippetData, openDialog, handleConfigChanged]);

	return (
		<Box xcss={snippetContainerStyles}>
			<SmartCardWrapper url={url} showChildren={!isUnsupported || Boolean(snippetData)}>
				{data !== null ? (
					<WaitForAdfConsumerProps>
						{({ emojiProvider, dataProviders }) => (
							<PolarisSnippet
								data={data}
								updated={null}
								properties={properties}
								emojiProvider={emojiProvider}
								dataProviders={dataProviders}
								mediaClientConfig={mediaClientConfig}
								onConfigureProperties={handleConfigureProperties}
							/>
						)}
					</WaitForAdfConsumerProps>
				) : null}
			</SmartCardWrapper>
		</Box>
	);
};

export const AddInsightStateless = (props: AddInsightStatelessProps) => {
	const {
		descriptionAdf,
		isDescriptionEmpty,
		timeZone,
		onUnfurlCancel,
		url,
		oauthClientId,
		isUnsupported,
		isUrlDefined,
		isAddLinkHintEnabled,
		onEdit,
		isEditing,
		isCompact,
		onDescriptionChange,
		snippetData,
		polarisProperties,
		properties,
		mediaClientConfig,
		onSave,
		onInputChange,
		onObjectResolved,
		isDisabled,
		onPropertiesUpdate,
		onCloseAddLinkHint,
		onCancel,
		onDirty,
		onConfigChanged,
		isCheckingSmartCard,
		isUpdating,
	} = props;

	const { formatMessage } = useIntl();
	const isArchived = useIsSelectedIssueArchived();
	const scrollableContainer = useScrollableContainer();
	const isEditorAiEnabled = useEditorAiEnabled();
	const cloudId = useCloudId();
	const [{ meta }] = useInsights();

	const handleDescriptionChange = useCallback(
		(value?: ADF) => {
			onDescriptionChange(value);
			onDirty?.(true);
		},
		[onDescriptionChange, onDirty],
	);

	const providers = useSnippetProviders();

	const polarisSnippetDeclaredProps = useMemo(() => {
		for (let i = 0; i < providers.length; i += 1) {
			if (providers[i].app?.oauthClientId === POLARIS_OAUTH_CLIENT_ID) {
				return providers[i].properties || [];
			}
		}
		return [];
	}, [providers]);

	const data = isUnsupported && url != null ? createCardWithUrlSnippetData(url) : snippetData;

	const onSaveRef = useRef<{ onSave?: () => void }>({});

	const getEditor = useCallback(() => {
		// AkEditor caches and never updates a callback provided to its `onSave` property
		// it leads to usage of the stale state or other dependencies of the `onSave` callback
		// using ref is a temporary workaround to read up-to-date `onSave` callback until it's fixed on the AkEditor's side
		//
		// thread about the issue https://atlassian.slack.com/archives/CFG3PSQ9E/p1683670360768789
		// JPD issue https://pi-dev-sandbox.atlassian.net/browse/POL-6015
		// todo: remove the workaround when the issue is fixed in AkEditor
		onSaveRef.current.onSave = onSave;

		return (
			<WaitForAdfConsumerProps>
				{({ akEditorProps }) => (
					<EditorBoundary>
						<Editor
							{...akEditorProps}
							withDebouncedOnChange={false}
							shouldFocus
							autoScrollIntoView
							defaultValue={descriptionAdf ?? undefined}
							onChange={handleDescriptionChange}
							placeholder={formatMessage(messages.addDescriptionPlaceholder)}
							onSave={() => {
								onSaveRef.current.onSave?.();
							}}
							withActionButtons={false}
							popupsBoundariesElement={scrollableContainer || undefined}
							allowFullEditorCapabilities
							isAiEnabled={isEditorAiEnabled}
							useStickyToolbar
						/>
					</EditorBoundary>
				)}
			</WaitForAdfConsumerProps>
		);
	}, [
		descriptionAdf,
		formatMessage,
		handleDescriptionChange,
		onSave,
		scrollableContainer,
		isEditorAiEnabled,
	]);

	const snippetWithPolarisProperties = useMemo(
		() => ({
			appInfo: null,
			data: null,
			refresh: null,
			url: null,
			updated: '',
			id: '',
			oauthClientId: '',
			properties: polarisProperties,
		}),
		[polarisProperties],
	);

	const insightsRemote = useInsightRemote();

	return (
		<Container data-testid="polaris-ideas.ui.insights.insights.add-insight.container">
			{isEditing ? (
				<>
					{getEditor()}
					<UnfurlStoreContainer
						insightsRemote={insightsRemote}
						url={url ?? undefined}
						cloudId={cloudId}
						issueId={meta?.issueId}
						projectId={meta?.projectId}
						onObjectResolved={onObjectResolved}
					>
						<LineContainer>
							<UnfurlFormContainer>
								<UnfurlForm
									isDisabled={isArchived || isDisabled}
									isCompact={isCompact}
									onReset={onUnfurlCancel}
									onChange={onInputChange}
								/>
								{isAddLinkHintEnabled ? null : <AddLinkTextHint />}
							</UnfurlFormContainer>
						</LineContainer>
						{isAddLinkHintEnabled ? (
							<LineContainer>
								<FireTrackAnalytics eventName="hint viewed" actionSubjectId="addLinkToInsights" />
								<AddLinkHint onClose={onCloseAddLinkHint} />
							</LineContainer>
						) : null}
						{isCheckingSmartCard ? (
							<>
								<Spacer height="16px" />
								<SkeletonComponent height="16px" width="350px" />
								<Spacer height="8px" />
								<SkeletonComponent height="16px" width="400px" />
								<Spacer height="8px" />
								<SkeletonComponent height="16px" width="140px" />
								<Spacer height="8px" />
							</>
						) : null}
						<UnfurlSnippet
							oauthClientId={oauthClientId}
							isUnsupported={isUnsupported}
							snippetData={snippetData}
							url={url}
							data={data}
							mediaClientConfig={mediaClientConfig}
							properties={properties}
							onConfigChanged={onConfigChanged}
							timeZone={timeZone || ''}
						/>
						{!isUpdating && (
							<LineContainer>
								<PropertiesBar
									declared={polarisSnippetDeclaredProps}
									snippet={snippetWithPolarisProperties}
									onUpdate={onPropertiesUpdate}
								/>
							</LineContainer>
						)}
						<LineContainer>
							<AddInsightButtons
								onSave={onSave}
								onCancel={onCancel}
								isUpdating={isUpdating}
								isDisabled={
									(!isUrlDefined && isDescriptionEmpty) || (!isUrlDefined && !!url) || isDisabled
								}
							/>
						</LineContainer>
					</UnfurlStoreContainer>
				</>
			) : (
				<>
					<CreateInsightContainer>
						<CreateInsightButton isArchived={isArchived} onEdit={onEdit} />
						<CreateInsightTextHintContainer>
							<CreateInsightTextHint />
						</CreateInsightTextHintContainer>
					</CreateInsightContainer>
				</>
			)}
		</Container>
	);
};

AddInsightStateless.defaultProps = {
	isEditing: false,
	isDisabled: false,
	isAddLinkHintEnabled: false,
	isDescriptionEmpty: false,
	isCheckingSmartCard: false,
	isUrlDefined: false,
	descriptionAdf: null,
	snippetData: null,
	properties: null,
	polarisProperties: null,
	url: null,
	oauthClientId: null,
	isUnsupported: false,
	mediaClientConfig: null,
	initUrl: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSetInitUrl: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSave: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onEdit: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onInputChange: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onObjectResolved: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onDescriptionChange: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onCloseAddLinkHint: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onUnfurlCancel: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onCancel: () => {},
};

const defaultState = Object.freeze({
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	url: null as string | null,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isUnsupported: false as boolean,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isDescriptionEmpty: true as boolean,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isDescriptionDirty: false as boolean,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isSnippetsDirty: false as boolean,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	descriptionAdf: null as ADF | null | undefined,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	properties: null as SnippetPropertiesObject | null,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	polarisProperties: null as SnippetPropertiesObject | null,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	snippetData: null as SnippetData | null,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	oauthClientId: null as string | null,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isEditing: false as boolean,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isUrlDefined: false as boolean,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	isCheckingSmartCard: false as boolean,
});

type Props = {
	isCompact?: boolean;
	isDisabled?: boolean;
	isUpdating?: boolean;
	onSubmit: (args: SubmitArgs) => void;
	initialValues?: Partial<typeof defaultState>;
	onEnterEditMode?: () => void;
	onCloseEditMode?: () => void;
};

type CreateSnippetInput = {
	oauthClientId: string;
	url?: string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	properties: any | null;
};

export type SubmitArgs = {
	snippets: CreateSnippetInput[];
	description?: ADF | null;
	user: User;
	issueKey?: string;
	isSnippetsDirty: boolean;
};

export const AddInsight = ({
	isCompact,
	isDisabled,
	isUpdating,
	onSubmit,
	initialValues,
	onEnterEditMode,
	onCloseEditMode,
}: Props) => {
	const [state, setState] = useState({
		...defaultState,
		...initialValues,
		isDescriptionEmpty: initialValues?.descriptionAdf
			? isEmptyAdf(initialValues.descriptionAdf)
			: true,
		isUrlDefined: !!initialValues?.url,
		isUnsupported: initialValues?.url ? !initialValues.snippetData : false,
	});

	const issueKey = useIssueKey();
	const mediaClientConfig = useMediaClientConfig();
	const { data: loggedInUser } = useCurrentUser();
	const { checkUrl } = useSmartCardActions();
	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const hasUnsavedChanges = useHasUnsavedChanges();
	const { setUnsavedChanges } = useViewActions();
	const { closeSpotlight } = useSpotlights();

	const handleDescriptionChange = useCallback(
		(descriptionAdf: ADF | null | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'textfield changed', 'insightDescription');

			setState((prev) => ({
				...prev,
				descriptionAdf,
				isDescriptionEmpty: isEmptyAdf(prepareForSave(descriptionAdf)),
				isDescriptionDirty: true,
			}));

			setUnsavedChanges(true);
		},
		[createAnalyticsEvent, setUnsavedChanges],
	);

	const handleUnfurlCancel = useCallback(() => {
		setState((oldState) => ({
			...defaultState,
			descriptionAdf: oldState.descriptionAdf,
			polarisProperties: oldState.polarisProperties,
			isEditing: oldState.isEditing,
			isDescriptionEmpty: oldState.isDescriptionEmpty,
			isDescriptionDirty: oldState.isDescriptionDirty,
			isSnippetsDirty: true,
		}));
	}, []);

	const handleCancel = useCallback(() => {
		setState(defaultState);
		setUnsavedChanges(false);

		if (onCloseEditMode) {
			onCloseEditMode();
		}
	}, [onCloseEditMode, setUnsavedChanges]);

	const handleSave = useCallback(() => {
		if (loggedInUser === null) {
			return;
		}
		const {
			oauthClientId,
			descriptionAdf,
			url,
			snippetData: data,
			properties,
			isDescriptionDirty,
			isSnippetsDirty,
		} = state;
		const description = isDescriptionDirty ? prepareForSave(descriptionAdf) : descriptionAdf;
		setState(defaultState);

		if (!isDescriptionDirty && !isSnippetsDirty) {
			handleCancel();
			return;
		}

		const snippets: CreateSnippetInput[] =
			url || data
				? [
						{
							oauthClientId: oauthClientId ?? POLARIS_UNSUPPORTED_OAUTH_CLIENT_ID,
							url,
							data,
							properties: properties || {},
						},
					]
				: [];

		if (state.polarisProperties !== null) {
			snippets.push({
				oauthClientId: POLARIS_OAUTH_CLIENT_ID,
				properties: state.polarisProperties || {},
			});
		}

		onSubmit({
			snippets,
			issueKey,
			description: isEmptyAdf(description) ? null : description,
			user: loggedInUser,
			isSnippetsDirty,
		});

		setUnsavedChanges(false);

		closeSpotlight(SpotlightTypes.CREATE_INSIGHT);

		if (onCloseEditMode) {
			onCloseEditMode();
		}
	}, [
		loggedInUser,
		state,
		onSubmit,
		issueKey,
		setUnsavedChanges,
		closeSpotlight,
		onCloseEditMode,
		handleCancel,
	]);

	const handleEdit = useCallback(() => {
		setState((prev) => ({
			...prev,
			isEditing: true,
		}));

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	}, [onEnterEditMode]);

	const handleObjectResolved = useCallback(
		({
			url,
			snippetData: resolvedSnippetData,
			oauthClientId,
			isUnsupported,
			properties,
		}: ObjectResolved) => {
			const checkSmartCard = isUnsupported ? checkUrl(url) : Promise.resolve(false);

			setState((prev) => ({
				...prev,
				isCheckingSmartCard: true,
			}));

			return checkSmartCard.then(() => {
				setState((prev) => ({
					...prev,
					properties: properties || null,
					url: url !== undefined ? url : null,
					snippetData: resolvedSnippetData !== undefined ? resolvedSnippetData : null,
					oauthClientId: oauthClientId !== undefined ? oauthClientId : null,
					isUnsupported,
					isCheckingSmartCard: false,
					isUrlDefined: true,
					isSnippetsDirty: true,
				}));
			});
		},
		[checkUrl],
	);

	const handleInputChange = useCallback(
		(value?: string) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'textfield changed', 'insightUrl');

			setUnsavedChanges(true);

			setState((prev) => ({
				...prev,
				isUnsupported: false,
				url: value ?? null,
				snippetData: null,
				oauthClientId: null,
				isUrlDefined: false,
			}));
		},
		[createAnalyticsEvent, setUnsavedChanges],
	);

	const handlePropertiesUpdate = useCallback(
		(key: string, value: number | string[] | undefined) => {
			if (!key) return;

			setState((prev) => ({
				...prev,
				isSnippetsDirty: true,
				polarisProperties: value
					? {
							...prev.polarisProperties,
							[key]: {
								name: key,
								value,
							},
						}
					: omit(prev.polarisProperties, key),
			}));
		},
		[],
	);

	const handleConfigChanged = useCallback(() => {
		setState((prev) => ({
			...prev,
			snippetData: null,
		}));
	}, []);

	useEffect(() => {
		loadProperty(ADD_LINK_TO_INSIGHT_HINT_CLOSED_KEY);
	}, [loadProperty]);

	const [
		{
			value: addLinkToInsightHintClosed,
			isLoading: isLoadingaAdLinkToInsightHintClosed,
			error: addLinkToInsightHintClosedError,
		},
	] = useUserProperty(ADD_LINK_TO_INSIGHT_HINT_CLOSED_KEY);

	const handleCloseAddLinkHint = useCallback(() => {
		saveProperty(ADD_LINK_TO_INSIGHT_HINT_CLOSED_KEY, true);
	}, [saveProperty]);

	// Callback for navigation blocker
	const onSave = useCallback(
		(cb: () => void) => {
			// @TODO: make awaitable
			handleSave();
			cb();
		},
		[handleSave],
	);

	// Callback for navigation blocker
	const onDiscard = useCallback(() => {
		handleCancel();
	}, [handleCancel]);

	return (
		<>
			<PolarisNavigationBlocker
				onSave={onSave}
				onDiscard={onDiscard}
				isDirty={hasUnsavedChanges && state.isEditing}
				withSaveButton
			/>
			<AddInsightStateless
				isAddLinkHintEnabled={
					!isLoadingaAdLinkToInsightHintClosed &&
					addLinkToInsightHintClosed !== true &&
					addLinkToInsightHintClosedError === null
				}
				isDisabled={isDisabled}
				isUpdating={isUpdating}
				descriptionAdf={state.descriptionAdf}
				isDescriptionEmpty={state.isDescriptionEmpty}
				snippetData={state.snippetData}
				properties={state.properties}
				polarisProperties={state.polarisProperties}
				isUnsupported={state.isUnsupported}
				isCheckingSmartCard={state.isCheckingSmartCard}
				isCompact={isCompact}
				url={state.url}
				oauthClientId={state.oauthClientId}
				mediaClientConfig={mediaClientConfig}
				isUrlDefined={state.isUrlDefined}
				isEditing={isUpdating ?? state.isEditing}
				onUnfurlCancel={handleUnfurlCancel}
				onCancel={handleCancel}
				onInputChange={handleInputChange}
				onObjectResolved={handleObjectResolved}
				onSave={handleSave}
				onEdit={handleEdit}
				onPropertiesUpdate={handlePropertiesUpdate}
				onDescriptionChange={handleDescriptionChange}
				onCloseAddLinkHint={handleCloseAddLinkHint}
				onConfigChanged={handleConfigChanged}
				onDirty={setUnsavedChanges}
			/>
		</>
	);
};

AddInsight.defaultProps = {
	isDisabled: false,
};

export const CreateInsight = ({
	initialValues,
	isCompact,
	isDisabled,
	onCloseEditMode,
	onEnterEditMode,
}: Pick<
	Props,
	'isCompact' | 'isDisabled' | 'onEnterEditMode' | 'onCloseEditMode' | 'initialValues'
>) => {
	const { createInsight } = useInsightsActions();
	const handleSubmit = useCallback(
		({ snippets, description, user, issueKey }: SubmitArgs) => {
			experience.ideaView.insightCreate.start();
			createInsight({ user, description, snippets, issueKey })
				.then(() => {
					experience.ideaView.insightCreate.success();
				})
				.catch((error) => {
					experience.ideaView.insightCreate.failure(error);
					log.safeErrorWithoutCustomerData(
						'polaris.save-insight-error',
						'Failed to save insight',
						error,
					);
				});
		},
		[createInsight],
	);

	return (
		<AddInsight
			onCloseEditMode={onCloseEditMode}
			onEnterEditMode={onEnterEditMode}
			onSubmit={handleSubmit}
			isCompact={isCompact}
			isDisabled={isDisabled}
			initialValues={initialValues}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

const snippetContainerStyles = xcss({
	marginTop: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateInsightContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LineContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '10px',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnfurlFormContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateInsightTextHintContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
});

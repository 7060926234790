import React from 'react';
import { useGetSelectFieldsRollup } from '../../../controllers/issue/selectors/fields-rollup-hooks.tsx';
import { CommonFieldGrouped } from '../common/index.tsx';
import { messages } from '../messages.tsx';
import type { Props } from '../types.tsx';

export const SelectFieldGrouped = ({
	fieldKey,
	groupId,
	groupFieldKey,
	rollupOperation,
}: Props) => {
	const val = useGetSelectFieldsRollup(fieldKey, groupFieldKey, groupId, rollupOperation);

	return val !== undefined ? (
		<CommonFieldGrouped
			val={val}
			rollupOperation={rollupOperation}
			message={messages.optionsCount}
		/>
	) : null;
};

import React, { useCallback, useMemo, useState, Fragment } from 'react';
import { styled } from '@compiled/react';
import compact from 'lodash/compact';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { ButtonSkeleton } from '@atlassian/jira-polaris-common/src/common/ui/skeleton/index.tsx';
import { useIsArchivingEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIsInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useSelectedIssueId,
	useSelectedIssueSummary,
	useIsSelectedIssueArchived,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks.tsx';
import {
	useCanArchiveIdeas,
	useCanMergeIdeas,
	useCanMoveIssues,
	useCanCreateAndEditIssues,
	useCanDeleteIssues,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import ShareButton from '@atlassian/jira-share-button/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ArchiveButton } from './archive/index.tsx';
import { CloneIdeaButton } from './clone/index.tsx';
import { DeleteModal } from './delete-ideas-modal/modal/index.tsx';
import { MergeButton } from './merge/index.tsx';
import messages from './messages.tsx';
import { MoveButton } from './move/index.tsx';

const getShareUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const url = window.location.href;
	return url;
};

const useObjectAri = () => {
	const selectedIssueId = useSelectedIssueId();
	const { cloudId } = useTenantContext();
	return useMemo(
		() =>
			createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'issue',
				resourceId: selectedIssueId !== undefined ? selectedIssueId.toString() : '',
			}),
		[cloudId, selectedIssueId],
	);
};

type CollaborationControlsProps = {
	isCompact?: boolean;
};

export const CollaborationControls = ({ isCompact = false }: CollaborationControlsProps) => {
	const { formatMessage } = useIntl();
	const objectAri = useObjectAri();
	const summary = useSelectedIssueSummary();
	const isArchived = useIsSelectedIssueArchived();
	const { deleteIssues } = useIssueActions();
	const isViewsLoading = useIsViewsLoading();
	const [isArchivingEnabled] = useIsArchivingEnabled();
	const canCreateAndEditIssues = useCanCreateAndEditIssues();
	const [canDeleteIssues] = useCanDeleteIssues();
	const [canMoveIssues] = useCanMoveIssues();
	const canArchiveIdeas = useCanArchiveIdeas();
	const canMergeIdeas = useCanMergeIdeas();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const localIssueId = useSelectedIssue();
	const isInitialized = useIsInitialized();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { closeIssueView } = usePolarisRouter();

	const handleDelete = useCallback(() => {
		if (isInitialized) {
			setIsDeleteModalOpen(false);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			deleteIssues([localIssueId!]);
			closeIssueView();
			return;
		}

		setIsDeleting(true);
		setIsDeleteModalOpen(false);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		deleteIssues([localIssueId!], true, () => {
			closeIssueView();
			setIsDeleting(false);
		});
	}, [closeIssueView, deleteIssues, isInitialized, localIssueId]);

	const Container = isVisualRefreshEnabled() ? Fragment : ContainerOld;
	const ButtonContainer = isVisualRefreshEnabled() ? Box : ButtonContainerOld;

	if (isViewsLoading) {
		return (
			<Container>
				{!isCompact && (
					<>
						<ButtonContainer>
							<ButtonSkeleton width="66px" />
						</ButtonContainer>

						<ButtonContainer>
							<ButtonSkeleton width="66px" />
						</ButtonContainer>
						<ButtonContainer>
							<ButtonSkeleton width="74px" />
						</ButtonContainer>
					</>
				)}
				<ButtonContainer>
					<ButtonSkeleton width="32px" />
				</ButtonContainer>
				<ButtonContainer>
					<ButtonSkeleton width="32px" />
				</ButtonContainer>
			</Container>
		);
	}

	if (localIssueId === undefined) {
		return null;
	}

	const actions = compact([
		canMoveIssues && <MoveButton key="move-button" isDropdownItem={isCompact} />,
		canCreateAndEditIssues && <CloneIdeaButton key="clone-button" isDropdownItem={isCompact} />,
		canMergeIdeas && <MergeButton key="merge-button" isDropdownItem={isCompact} />,
		isArchivingEnabled && canArchiveIdeas && (
			<ArchiveButton key="archive-button" isDropdownItem={isCompact} />
		),
	]);

	const moreActions = canDeleteIssues
		? [
				<DropdownItem
					key="delete-button"
					testId="polaris-ideas.ui.idea-view.collaboration-controls.delete-button"
					isDisabled={isDeleting}
					onClick={() => {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'clicked',
								actionSubject: 'dropdownItem',
							}),
							'issueOption',
							{
								selectedActionItem: 'delete',
							},
						);
						setIsDeleteModalOpen(true);
					}}
				>
					{formatMessage(messages.delete)}
				</DropdownItem>,
			]
		: [];

	if (isCompact) {
		moreActions.unshift(...actions);
	}

	return isArchived ? null : (
		<Container>
			{!isCompact &&
				actions.map((actionButton) => (
					<ButtonContainer key={actionButton.key}>{actionButton}</ButtonContainer>
				))}
			<ButtonContainer id="polaris.idea.src.ui.collaboration-controls.share-button">
				<ShareButton
					integrationType="polarisIssue"
					triggerButtonStyle="icon-only"
					productId="jira"
					subProductId="jira-software"
					shareLink={getShareUrl()}
					shareTitle={summary !== undefined ? summary : formatMessage(messages.shareDefaultTitle)}
					shareContentType="polarisIssue"
					objectAri={objectAri}
				/>
			</ButtonContainer>
			{moreActions.length ? (
				<ButtonContainer>
					<DropdownMenu
						trigger={({ triggerRef, ...triggerProps }) =>
							isVisualRefreshEnabled() ? (
								<IconButton
									label={formatMessage(messages.moreButtonLabel)}
									{...triggerProps}
									onClick={(event) => {
										triggerProps.onClick?.(event);
										fireUIAnalytics(
											createAnalyticsEvent({
												action: 'toggled',
												actionSubject: 'dropdown',
											}),
											'moreOptions',
										);
									}}
									appearance="default"
									icon={MoreIcon}
									ref={triggerRef}
								/>
							) : (
								<Button
									{...triggerProps}
									onClick={(event) => {
										triggerProps.onClick?.(event);
										fireUIAnalytics(
											createAnalyticsEvent({
												action: 'toggled',
												actionSubject: 'dropdown',
											}),
											'moreOptions',
										);
									}}
									appearance="subtle"
									iconAfter={<MoreIcon label="More" />}
									ref={triggerRef}
								/>
							)
						}
					>
						<DropdownItemGroup>{moreActions}</DropdownItemGroup>
					</DropdownMenu>
					{canDeleteIssues ? (
						<DeleteModal
							isOpen={isDeleteModalOpen}
							onCancel={() => {
								setIsDeleteModalOpen(false);
							}}
							onConfirm={handleDelete}
							summary={summary}
						/>
					) : null}
				</ButtonContainer>
			) : null}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerOld = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginRight: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContainerOld = styled.span({
	marginLeft: token('space.150', '12px'),
});

import { getInsight } from '../../services/polaris-api/get-insight/index.tsx';
import { fetchInsights } from '../../services/polaris-api/get-insights/index.tsx';
import type {
	FetchInsightRequest,
	FetchInsightsRequest,
	ProviderProps,
	RemoteInsightsFetcher,
	RemoteInsightFetcher,
} from '../../types.tsx';

export const fetch =
	({ apolloClient, cloudId }: Omit<ProviderProps, 'projectId'>): RemoteInsightsFetcher =>
	({ issueId, projectId }: FetchInsightsRequest) =>
		fetchInsights(apolloClient, cloudId, projectId, issueId).then((response) => ({
			insights: [
				...response.insights.map((insight) => ({
					...insight,
					snippets: [
						...insight.snippets.map((snippet) => ({
							...snippet,
							properties: {
								...snippet.properties,
							},
						})),
					],
				})),
			],
			labels: [...response.labels],
		}));

export const fetchInsight =
	({ apolloClient, cloudId }: ProviderProps): RemoteInsightFetcher =>
	({ id }: FetchInsightRequest) =>
		getInsight(apolloClient, cloudId, id).then((response) => {
			if (response === undefined) {
				return response;
			}
			return {
				...response,
				snippets: response?.snippets ? [...response.snippets] : [],
				id: response.id,
			};
		});

import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const deleteAttachment =
	(attachmentId: number): Action<State, Props> =>
	async ({ getState, setState }, { issuesRemote }) => {
		issuesRemote.deleteIssueAttachment({ attachmentId });

		setState({
			attachments: {
				...getState().attachments,
				attachments: getState().attachments.attachments.filter(
					({ file }) => file.attachmentId !== attachmentId,
				),
			},
		});
	};

export const removeAttachments =
	(attachmentIds: string[]): Action<State, Props> =>
	({ getState, setState }) => {
		if (!attachmentIds.length) {
			return;
		}

		setState({
			attachments: {
				...getState().attachments,
				attachments: getState().attachments.attachments.filter(
					({ file }) => !attachmentIds.includes(file.attachmentMediaApiId),
				),
			},
		});
	};

import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Image from '@atlaskit/image';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCanAddComments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { Restore } from '../../restore/index.tsx';
import CommentsImgDark from './assets/comments-dark.svg';
import CommentsImg from './assets/comments.svg';
import messages from './messages.tsx';

const Description = ({ isIdeaArchived }: { isIdeaArchived: boolean }) => {
	const { formatMessage } = useIntl();
	const [canAddComments] = useCanAddComments();

	if (!canAddComments) return <>{formatMessage(messages.descriptionReadOnly)}</>;
	if (isIdeaArchived) return <>{formatMessage(messages.descriptionArchived)}</>;
	return <>{formatMessage(messages.description)}</>;
};

export const EmptyComments = () => {
	const isIdeaArchived = useIsSelectedIssueArchived();

	useEffect(() => {
		experience.ideaView.commentsSegmentLoad.success();
	}, []);

	return (
		<Box xcss={wrapperStyles}>
			<Container>
				<ImageContainer>
					<Image src={CommentsImg} srcDark={CommentsImgDark} alt="Comments" />
				</ImageContainer>
				<Content>
					<Description isIdeaArchived={isIdeaArchived} />
					{isIdeaArchived && <Restore />}
				</Content>
			</Container>
		</Box>
	);
};

const wrapperStyles = xcss({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	marginTop: token('space.300', '24px'),
	flexWrap: 'wrap',
	gap: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	flex: 1,
	width: '460px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	width: '110px',
	height: '88px',
});

import React from 'react';
import { FieldConfigurationContainer } from './controllers/index.tsx';
import type { Props } from './controllers/types.tsx';
import { FieldConfigurationInternal } from './ui/index.tsx';

export const FieldConfiguration = (props: Props) => {
	const fieldKey = props.fieldData?.field.key;
	return (
		<FieldConfigurationContainer key={fieldKey} {...props}>
			<FieldConfigurationInternal />
		</FieldConfigurationContainer>
	);
};

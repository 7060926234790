import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { PRODUCT_START_TIMESTAMP } from '../../constants.tsx';
import type { Props, State } from '../../types.tsx';

export const loadProductStartTimestamp =
	(): Action<State, Props> =>
	async ({ setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		try {
			const propertyValue = await getUserProperty<{ isoDateString: string }>(
				accountId,
				PRODUCT_START_TIMESTAMP,
			);

			setState({
				productUsageStartTimestamp: propertyValue.isoDateString,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (getPropertyError: any) {
			if (getPropertyError.statusCode === 404) {
				try {
					const productUsageStartTimestamp = new Date().toISOString();

					const propertyValue = {
						isoDateString: productUsageStartTimestamp,
					};

					await setUserProperties(
						accountId,
						PRODUCT_START_TIMESTAMP,
						JSON.stringify(propertyValue),
					);

					setState({
						productUsageStartTimestamp,
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (setPropertyError: any) {
					log.safeErrorWithoutCustomerData(
						'polaris.onboarding.product-start-timestamp.user-property',
						`Failed to set user property with key: ${PRODUCT_START_TIMESTAMP}`,
						setPropertyError,
					);
				}
			}

			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.product-start-timestamp.user-property',
				`Failed to get user property with key: ${PRODUCT_START_TIMESTAMP}`,
				getPropertyError,
			);
		}
	};

import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { SWIMLANE_HEADER_ID, HEADER_HEIGHT } from '../../../constants.tsx';
import type { ExtendedOption, IssuesByGroupIdentity } from '../../../types/common.tsx';
import type { CustomComponents } from '../../../types/custom-components.tsx';
import type { DragState } from '../../../types/draggable.tsx';
import type {
	CollapsedSwimlanes,
	ColumnsHighlightColors,
	OnAllSwimlanesToggle,
} from '../../../types/ui.tsx';
import { getSwimlanesBoardWidth } from '../utils.tsx';
import { DraggableColumnStickyHeader } from './draggable-column-sticky-header/index.tsx';

const useNonEmptyExtendedOptions = (
	extendedOptions: ExtendedOption<unknown>[],
	idsByColumn: IssuesByGroupIdentity,
): ExtendedOption<unknown>[] => {
	return useMemo(() => {
		const nonEmptyGroupOptions = extendedOptions.filter(({ groupIdentity }) => {
			if (groupIdentity === undefined) {
				return !!idsByColumn.empty?.length;
			}
			return !!idsByColumn.groups[groupIdentity]?.length;
		});

		return nonEmptyGroupOptions;
	}, [extendedOptions, idsByColumn]);
};

export type HeaderProps = {
	collapsedSwimlanes: CollapsedSwimlanes;
	columnsHighlightColors: ColumnsHighlightColors;
	components: CustomComponents;
	extendedOptions: ExtendedOption<unknown>[];
	hideEmptyColumns: boolean;
	idsByColumn: IssuesByGroupIdentity;
	isReadOnly: boolean;
	onAllSwimlanesToggle?: OnAllSwimlanesToggle;
};

export const Header = ({
	collapsedSwimlanes,
	columnsHighlightColors,
	components,
	extendedOptions,
	hideEmptyColumns,
	idsByColumn,
	isReadOnly,
	onAllSwimlanesToggle,
}: HeaderProps) => {
	const [isDraggingStickyColumnHeader, setIsDraggingStickyColumnHeader] = useState(false);

	const nonEmptyExtendedOptions = useNonEmptyExtendedOptions(extendedOptions, idsByColumn);
	const columnsCount = hideEmptyColumns ? nonEmptyExtendedOptions.length : extendedOptions.length;

	const handleDragStatusChange = useCallback((dragStatus: DragState) => {
		setIsDraggingStickyColumnHeader(dragStatus === 'dragging');
	}, []);

	return (
		<HeaderStickyWrapper>
			<HeaderContainer
				id={SWIMLANE_HEADER_ID}
				columnsCount={columnsCount}
				isDraggingStickyColumnHeader={isDraggingStickyColumnHeader}
			>
				{extendedOptions.map((extendedOption, index) => {
					const nonEmptyExtendedOptionIndex = nonEmptyExtendedOptions.findIndex(
						(o) => o.groupIdentity === extendedOption.groupIdentity,
					);

					return (
						<DraggableColumnStickyHeader
							key={extendedOption.droppableId}
							collapsedSwimlanes={collapsedSwimlanes}
							columnsHighlightColors={columnsHighlightColors}
							components={components}
							draggableId={extendedOption.droppableId}
							groupIdentity={extendedOption.groupIdentity}
							hideEmptyColumns={hideEmptyColumns}
							idsByColumn={idsByColumn}
							index={index}
							isLastHeader={index === extendedOptions.length - 1}
							isReadOnly={isReadOnly}
							onDragStatusChange={handleDragStatusChange}
							showCollapseAllButton={
								hideEmptyColumns ? nonEmptyExtendedOptionIndex === 0 : index === 0
							}
							onAllSwimlanesToggle={onAllSwimlanesToggle}
						/>
					);
				})}
			</HeaderContainer>
		</HeaderStickyWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderStickyWrapper = styled.div({
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_HEIGHT}px`,
	zIndex: 3,
	top: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div<{
	columnsCount: number;
	isDraggingStickyColumnHeader: boolean;
}>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${({ columnsCount }: { columnsCount: number }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		getSwimlanesBoardWidth(columnsCount)}px`,
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isDraggingStickyColumnHeader }) => (isDraggingStickyColumnHeader ? '100vh' : '100%'),
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	flagTitle: {
		id: 'polaris-component-bulk-issue-update-progress.flag-title',
		defaultMessage: 'Bulk issue update',
		description: 'Title of the flag that shows the bulk issue update progress',
	},
	flagTitleIssueTermRefresh: {
		id: 'polaris-component-bulk-issue-update-progress.flag-title-issue-term-refresh',
		defaultMessage: 'Bulk work item update',
		description: 'Title of the flag that shows the bulk issue update progress',
	},
	errorRetrievingProgress: {
		id: 'polaris-component-bulk-issue-update-progress.error-retrieving-progress',
		defaultMessage: 'Cannot retrieve progress. Please try again later.',
		description: 'Error message when the progress cannot be retrieved',
	},
	progress: {
		id: 'polaris-component-bulk-issue-update-progress.progress',
		defaultMessage: 'Progress: {progress}%',
		description: 'Progress of the bulk issue update',
	},
});

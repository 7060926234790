import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	opIsChecked: {
		id: 'polaris-common.filters.filter-component.checkbox.op-is-checked',
		defaultMessage: 'is checked',
		description: 'Checkbox is checked operator',
	},
	opIsNotChecked: {
		id: 'polaris-common.filters.filter-component.checkbox.op-is-not-checked',
		defaultMessage: 'is not checked',
		description: 'Checkbox is not checked operator',
	},
});

import merge from 'lodash/merge';
import type { PolarisApiInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/get-insight/types.tsx';
import type { InsightMap } from '../types.tsx';

/**
 * This function tries to enrich the insight payload with additional data
 * which is not received from the server during real-time updates.
 */
export function enrichPayload(
	insight: PolarisApiInsight,
	localInsights: InsightMap,
): PolarisApiInsight | null {
	// Check if the insight already has user account data then return it as is
	if (insight.account?.name && insight.account?.picture) {
		return insight;
	}

	// If the insight doesn't have user account data, try to enrich it from local insight
	let localInsight = localInsights[insight.id];
	if (localInsight) {
		return {
			...insight,
			account: {
				...merge({}, localInsight.account, insight.account),
			},
		};
	}

	// If the insight is not found in the local insights,
	// try to enrich it with the user account data from the other insights
	for (localInsight of Object.values(localInsights)) {
		if (insight.account?.accountId === localInsight.account?.accountId) {
			return {
				...insight,
				account: {
					...merge({}, localInsight.account, insight.account),
				},
			};
		}
	}

	// null indicates that the insight cannot be enriched
	return null;
}

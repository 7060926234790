import React, { type ReactNode, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji';
import type { InlineDialogProps } from '@atlaskit/inline-dialog/types';
import { ReactRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { TooltipPrimitive, type TooltipPrimitiveProps, type TooltipProps } from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { smartLinksDefault } from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { FixedFieldDescription } from '@atlassian/jira-polaris-component-fixed-field-description/src/ui/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	IssuelessAdfController,
	WaitForAdfConsumerProps,
} from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { useIsSharedView } from '../../controllers/environment/index.tsx';
import {
	useFieldDescription,
	useFieldEmoji,
	useFieldFormula,
	useFieldLabel,
	useFieldTypeIcon,
	useIsGlobalCustomField,
	useFieldType,
} from '../../controllers/field/selectors/field-hooks.tsx';
import { useOpenRightSidebarOnField } from '../../controllers/right-sidebar/actions/hooks.tsx';
import { useCurrentViewSummaryCardFieldKey } from '../../controllers/views/selectors/view-hooks.tsx';
import { FormulaContent } from '../config/fields/field-item/formula-content/index.tsx';
import { EmojiWrapper } from '../field-config/item/emoji-wrapper/index.tsx';
import { DeliveryDataRestrictedInfoText } from '../field-info-marker/delivery-data-restricted/index.tsx';
import { summaryCardFieldTooltipImage } from './assets/summary-card-field-tooltip-image.tsx';
import { messages } from './messages.tsx';

type FieldTooltipStrategy = 'click' | 'hover';

type FieldTooltipProps = {
	fieldKey: FieldKey;
	children: ReactNode;
	strategy: FieldTooltipStrategy;
	placement?: InlineDialogProps['placement'];
	position?: TooltipProps['position'];
	disabled?: boolean;
	hasDescriptionTrigger?: boolean;
	testId?: string;
	isSummaryCardField?: boolean;
};

export const FieldTooltip = ({
	children,
	fieldKey,
	strategy,
	placement = 'left',
	position = 'bottom',
	disabled = false,
	hasDescriptionTrigger = false,
	testId,
	isSummaryCardField,
}: FieldTooltipProps) => {
	const label = useFieldLabel(fieldKey);
	const formula = useFieldFormula(fieldKey);
	const description = useFieldDescription(fieldKey);
	const fieldType = useFieldType(fieldKey);
	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const icon = useFieldTypeIcon(fieldKey, undefined);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	const { formatMessage } = useIntl();
	const [tooltipWithClickOpen, setTooltipWithClickOpen] = useState(false);
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const isSharedView = useIsSharedView();
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
	const isFieldSelectedForSummaryCard = useCurrentViewSummaryCardFieldKey() === fieldKey;

	const isDeliveryProgressField = fieldType === FIELD_TYPES.DELIVERY_PROGRESS;
	const isDeliveryStatusField = fieldType === FIELD_TYPES.DELIVERY_STATUS;

	const descriptionReadValue = (value?: string) => {
		if (isDeliveryProgressField) {
			return (
				<DeliveryFieldDescriptionReadContainer>
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.deliveryProgressDescriptionIssueTermRefresh
							: messages.deliveryProgressDescription,
					)}
				</DeliveryFieldDescriptionReadContainer>
			);
		}

		if (isDeliveryStatusField) {
			return (
				<DeliveryFieldDescriptionReadContainer>
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.deliveryStatusDescriptionIssueTermRefresh
							: messages.deliveryStatusDescription,
					)}
				</DeliveryFieldDescriptionReadContainer>
			);
		}

		if (value) {
			return (
				<IssuelessAdfController projectId={projectId} projectKey={projectKey}>
					<WaitForAdfConsumerProps>
						{({ akRendererProps }) => (
							<ReactRenderer
								{...akRendererProps}
								document={description.adf}
								smartLinks={smartLinksDefault}
								UNSTABLE_allowTableResizing={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
								UNSTABLE_allowTableAlignment={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
							/>
						)}
					</WaitForAdfConsumerProps>
				</IssuelessAdfController>
			);
		}
		return formatMessage(messages.noDescription);
	};

	const descriptionContent = description.fixed ? (
		<FixedFieldDescription fieldKey={fieldKey} />
	) : (
		descriptionReadValue(description.value)
	);

	const isClickable = isSharedView
		? hasDescriptionTrigger && (!!description.value || description.fixed)
		: hasDescriptionTrigger;

	const openRightSidebarDescriptionClick = useCallback(() => {
		if (isClickable) {
			openRightSidebarOnField(fieldKey);
		}
	}, [fieldKey, isClickable, openRightSidebarOnField]);

	const tooltipContent = isSummaryCardField ? (
		<>
			{summaryCardFieldTooltipImage}
			{formatMessage(
				isFieldSelectedForSummaryCard
					? messages.summaryCardFieldDisableNonFinal
					: messages.summaryCardFieldEnableNonFinal,
			)}
		</>
	) : (
		<TooltipContent
			noPadding={strategy === 'hover'}
			data-testid={testId && `${testId}--tooltip-container`}
		>
			<FieldContainer>
				{emoji ? (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} />
					</EmojiWrapper>
				) : (
					<IconContainer>{icon}</IconContainer>
				)}
				<LabelContainer> {label}</LabelContainer>
				{isGlobalCustomField && <GlobalFieldMarker />}
			</FieldContainer>
			<DescriptionContainer
				isClickable={isClickable}
				isDarkMode={isDarkMode}
				onClick={openRightSidebarDescriptionClick}
				data-testid={testId && `${testId}--description-container`}
			>
				{descriptionContent}
			</DescriptionContainer>
			<DeliveryDataRestrictedInfoTextContainer>
				<DeliveryDataRestrictedInfoText fieldKey={fieldKey} />
			</DeliveryDataRestrictedInfoTextContainer>
			<FormulaContent fieldKey={fieldKey} formula={formula} readonly isFieldTooltip />
		</TooltipContent>
	);

	if (strategy === 'click') {
		return (
			<div
				onClick={() => setTooltipWithClickOpen(true)}
				onMouseLeave={() => setTooltipWithClickOpen(false)}
				onKeyDown={(e) =>
					(e.key === 'Enter' || e.key === 'Space') && setTooltipWithClickOpen(!tooltipWithClickOpen)
				}
				role="presentation"
			>
				<PolarisInlineDialog
					noPadding
					placement={placement}
					isOpen={tooltipWithClickOpen}
					onClose={() => setTooltipWithClickOpen(false)}
					content={disabled ? null : tooltipContent}
				>
					{children}
				</PolarisInlineDialog>
			</div>
		);
	}

	return (
		<PolarisTooltip
			hideTooltipOnClick
			content={disabled ? null : tooltipContent}
			position={position}
			component={isSummaryCardField ? SummaryCardFieldTooltipComponent : undefined}
		>
			{children}
		</PolarisTooltip>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContent = styled.div<{ noPadding: any }>({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '240px',
	maxWidth: '350px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ noPadding }) => (noPadding ? '0' : token('space.200', '16px')),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.large', fontFallback.body.large),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionContainer = styled.div<{
	isClickable: boolean;
	isDarkMode: boolean;
}>(
	{
		marginTop: token('space.075', '6px'),
		marginBottom: token('space.075', '6px'),
		/** This might look weird, but it's a bit of CSS magic; it pulls the container
		 * wider by 8px with the negative margins and then pushes the content in by 8px.
		 * This means the content doesn't shift for the user when viewing, but what it
		 * achieves is a larger border box. Because the background colour sits on the
		 * border box, it allows us to have a hover state more significant than the
		 * content without padding it, so it looks nicer. */
		paddingLeft: token('space.100', '8px'),
		paddingRight: token('space.100', '8px'),
		marginLeft: token('space.negative.100', '-8px'),
		marginRight: token('space.negative.100', '-8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N100),
		width: '100%',
		maxHeight: '400px',
		whiteSpace: 'break-spaces',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maskImage: ({ isDarkMode }) =>
			`linear-gradient(to bottom, ${isDarkMode ? 'white' : 'black'} 350px, transparent 100%)`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isClickable, isDarkMode }) =>
		isClickable
			? `:hover {
                cursor: pointer;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
                background-color: ${isDarkMode ? colors.DN60 : colors.N20};
              }`
			: '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryDataRestrictedInfoTextContainer = styled.div({
	marginTop: token('space.075', '6px'),
	marginBottom: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	width: '100%',
	overflowY: 'auto',
	maxHeight: '200px',
	whiteSpace: 'break-spaces',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryCardFieldTooltipComponent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	borderRadius: '6px',
	backgroundColor: token('color.icon', '#44546F'),
	boxShadow: `${token(
		'elevation.shadow.overlay',
		'0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)',
	)}`,
	padding: token('space.150', '12px'),
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.150', '12px'),
	color: token('color.text.inverse', '#FFFFFF'),
	fontSize: '13px',
	fontWeight: 600,
	lineHeight: '20px',
	width: '287px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryFieldDescriptionReadContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
});

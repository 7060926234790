// eslint-disable-next-line jira/import-whitelist
import { preloadPostOfficePlacementWithTenantContext } from '@atlassian/jira-post-office-provider/src/preload-placement.tsx';
import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_POST_OFFICE_PLACEMENT__JIRA_SIDE_NAV,
	RESOURCE_TYPE_POST_OFFICE_PLACEMENT__SCREEN_SPACE_FLAGS,
} from '../common/constants.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const screenSpaceFlagsResource = createResource<any>({
	type: RESOURCE_TYPE_POST_OFFICE_PLACEMENT__SCREEN_SPACE_FLAGS,
	getKey: () => 'state',
	getData: async (_, { tenantContext }) => {
		return preloadPostOfficePlacementWithTenantContext('screen-space-flags', tenantContext);
	},
	maxAge: Infinity,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jiraSideNavResource = createResource<any>({
	type: RESOURCE_TYPE_POST_OFFICE_PLACEMENT__JIRA_SIDE_NAV,
	getKey: () => 'state',
	getData: async (_, { tenantContext }) => {
		return preloadPostOfficePlacementWithTenantContext('jira-side-nav', tenantContext);
	},
	maxAge: Infinity,
});

import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import {
	emptyAdfObject,
	removeCollectionFromAdf,
	removeNewLineCharactersFromAdf,
	simplifyAdf,
} from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';

const jsonTransformer = new JSONTransformer();

export const prepareForSave = (value?: ADF | null): ADF => {
	if (value === undefined || value === null) {
		return emptyAdfObject;
	}
	// @ts-expect-error - TS2345 - Argument of type 'JSONDocNode' is not assignable to parameter of type 'DocNode'. | TS2345 - Argument of type 'DocNode' is not assignable to parameter of type 'ProsemirrorNode<any>'.
	return removeNewLineCharactersFromAdf(simplifyAdf(jsonTransformer.encode(value)));
};

export const prepareForRender = (adf?: ADF | null): ADF => {
	if (adf === undefined || adf === null) {
		return emptyAdfObject;
	}
	const stripped = removeCollectionFromAdf(adf);
	return stripped !== null ? stripped : emptyAdfObject;
};

import React from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props, ReducedProps } from './index.tsx';
import { WORKFLOW_LOAD_START_MARK_KEY } from './model/constants.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props };

// WorkflowEditorOld async export
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponentOld = lazy(() =>
	import(/* webpackChunkName: "async-workflow-editor" */ './index').then(
		(module) => module.WorkflowEditorOld,
	),
);

export const AsyncWorkflowEditorOld = (props: Props) => (
	<Placeholder
		name="workflow-editor"
		fallback={<PerformanceMark metricKey={WORKFLOW_LOAD_START_MARK_KEY} />}
	>
		{}
		<LazyComponentOld {...props} />
	</Placeholder>
);

// WorkflowEditorNew async export
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponent = lazy(() =>
	import(/* webpackChunkName: "async-workflow-editor-new" */ './index').then(
		(module) => module.WorkflowEditorNew,
	),
);

export const AsyncWorkflowEditor = (props: ReducedProps) => (
	<Placeholder
		name="workflow-editor-new"
		fallback={<PerformanceMark metricKey={WORKFLOW_LOAD_START_MARK_KEY} />}
	>
		{}
		<LazyComponent {...props} />
	</Placeholder>
);

import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const removeGlobalFieldLocally =
	(fieldKey: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		const globalFields = { ...getState().globalFields };
		delete globalFields[fieldKey];

		setState({
			globalFields,
		});
	};

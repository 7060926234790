import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';

const FIELD_TYPES_WITH_EDITABLE_OPTIONS: FieldType[] = [
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,
];

const FIELD_TYPES_WITH_DELETABLE_OPTIONS: FieldType[] = [
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,
	FIELD_TYPES.CONNECTION,
];

export const canModifyOptionName = (fieldType?: FieldType): boolean =>
	!!fieldType && FIELD_TYPES_WITH_EDITABLE_OPTIONS.includes(fieldType);

export const canDeleteOption = (fieldType?: FieldType): boolean =>
	!!fieldType && FIELD_TYPES_WITH_DELETABLE_OPTIONS.includes(fieldType);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	optionsCount: {
		id: 'polaris-common.common.decoration.select.control.options-count',
		defaultMessage: '{count, plural, =0 {No items} one {# item} other {# items}}',
		description: 'Number of options selected',
	},
	removeButtonLabel: {
		id: 'polaris-common.common.decoration.select.control.remove-button-label',
		defaultMessage: 'Remove',
		description: 'Label for remove all items button',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	changeIssueTypeLabel: {
		id: 'polaris-issue-types.editable-issue-type.change-issue-type-label',
		defaultMessage: 'Change Issue Type',
		description: 'Label for the button that opens the issue type dropdown',
	},
	editIssueTypesLabel: {
		id: 'polaris-issue-types.editable-issue-type.edit-issue-types-label',
		defaultMessage: 'Edit types',
		description: 'Label for the button that redirects to the issue types configuration page',
	},
});

import {
	createContainer,
	createStore,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { loadLicenceInformation } from './actions/load-licence-informatiion.tsx';
import type { State, Props } from './types.tsx';

const actions = {
	loadLicenceInformation,
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

export type Actions = typeof actions;

const initialState: State = {
	clouds: {},
};

export const LicenceInformationStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisLicenceInformationStore',
});

export const useLicenceInformationActions = createHook(LicenceInformationStore, {
	selector: null,
});

export const LicenceInformationContainer = createContainer<State, Actions, Props>(
	LicenceInformationStore,
);

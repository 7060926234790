import React, { Component, type ReactNode, type FocusEvent } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import Picker, { type Props as BaseProps } from '../base/index.tsx';

export type FormatOptionDataTransformer<SelectOption> = (option: SelectOption) => {
	label: string;
	avatar: undefined | string | ReactNode;
};
export type Props<SelectOption> = Flow.Diff<
	// @ts-expect-error - TS2344 - Type 'Props<SelectOption>' does not satisfy the constraint '{ formatOptionLabel: any; }'.
	BaseProps<SelectOption>,
	{
		// Do not allow configuring `formatOptionLabel` prop, we do it manually in this component
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		formatOptionLabel: any;
	}
> & {
	showFocusedAvatar?: boolean;
	/**
	 * Method that transforms option into an object of a predefined shape
	 * used to override default option appearance in the picker
	 */
	formatOptionDataTransformer: FormatOptionDataTransformer<SelectOption>;
};
type State = {
	canShowAvatar: boolean;
};

/**
 * Enhances a selection component with avatar visuals, allowing for a more engaging user experience.
 * This component integrates avatar images alongside select options, adjusting visibility based on focus.
 */
// eslint-disable-next-line jira/react/no-class-components
export default class PickerWithAvatar<SelectOption> extends Component<Props<SelectOption>, State> {
	static defaultProps = {
		showFocusedAvatar: false,
	};

	state = {
		canShowAvatar: true,
	};

	onBlur = (event: FocusEvent<HTMLElement>) => {
		this.setState(() => ({
			canShowAvatar: true,
		}));
		this.props.onBlur && this.props.onBlur(event);
	};

	onFocus = (event: FocusEvent<HTMLElement>) => {
		if (!this.props.showFocusedAvatar) {
			this.setState(() => ({
				canShowAvatar: false,
			}));
		}
		this.props.onFocus && this.props.onFocus(event);
	};

	renderOption = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option: any,
		{
			context, // eslint-disable-next-line @typescript-eslint/no-explicit-any
		}: any,
	) => {
		const { avatar, label } = this.props.formatOptionDataTransformer(option);
		return (
			<OptionWrapper>
				{typeof avatar !== 'undefined' && (context !== 'value' || this.state.canShowAvatar) ? (
					<AvatarWrapper>
						{typeof avatar === 'string' ? <Avatar src={avatar} size="xsmall" /> : avatar}
					</AvatarWrapper>
				) : null}
				<LabelWrapper>{label}</LabelWrapper>
			</OptionWrapper>
		);
	};

	render() {
		const { formatOptionDataTransformer, showFocusedAvatar, ...props } = this.props;
		return (
			<Picker
				{...props}
				onBlur={this.onBlur}
				onFocus={this.onFocus}
				formatOptionLabel={this.renderOption}
			/>
		);
	}
}
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.span({
	marginRight: token('space.100', '8px'),
	lineHeight: 1,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.span({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

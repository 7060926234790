import { useMemo } from 'react';
import { useAmplitudePreset } from './amplitude/index.tsx';
import { useChromePreset } from './chrome/index.tsx';
import { useIntercomPreset } from './intercom/index.tsx';
import { useJsmPreset } from './jsm/index.tsx';
import { usePendoPresets } from './pendo/index.tsx';
import { useSalesforcePreset } from './salesforce/index.tsx';
import { useSlackPreset } from './slack/index.tsx';
import { useTeamsPreset } from './teams/index.tsx';
import type { AppPreset } from './types.tsx';
import { useUserTestingPreset } from './user-testing/index.tsx';
import { useVivunPreset } from './vivun/index.tsx';
import { useZendeskPreset } from './zendesk/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { AppPreset } from './types';

export const usePresets = (): AppPreset[] => {
	const slack = useSlackPreset();
	const chrome = useChromePreset();
	const teams = useTeamsPreset();
	const zendesk = useZendeskPreset();
	const salesforce = useSalesforcePreset();
	const pendo = usePendoPresets();
	const intercom = useIntercomPreset();
	const vivun = useVivunPreset();
	const amplitude = useAmplitudePreset();
	const userTesting = useUserTestingPreset();
	const jsm = useJsmPreset();

	return useMemo(
		() => [
			chrome,
			slack,
			teams,
			zendesk,
			salesforce,
			...pendo,
			intercom,
			vivun,
			jsm,
			amplitude,
			userTesting,
		],
		[
			chrome,
			slack,
			teams,
			zendesk,
			salesforce,
			pendo,
			intercom,
			vivun,
			jsm,
			amplitude,
			userTesting,
		],
	);
};

import React, { type ReactElement } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { SpotlightTypes } from '../../common/types.tsx';
import { Spotlight } from '../../common/ui/spotlight/index.tsx';
import { getSpotlightAnalyticsContextData } from '../../common/utils.tsx';
import { useActions } from '../../controllers/index.tsx';
import { useIsLoaded } from '../../controllers/selectors/remote-state-hooks.tsx';
import { useIsCreateDeliveryTicketSpotlightShown } from '../../controllers/selectors/spotlights-hooks.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactElement;
	placement: 'bottom' | 'bottom-end';
};

export const CreateDeliveryTicketSpotlight = ({ children, placement }: Props) => {
	const { formatMessage } = useIntl();

	const isShown = useIsCreateDeliveryTicketSpotlightShown();
	const isLoaded = useIsLoaded();
	const { closeSpotlight } = useActions();

	return (
		<ContextualAnalyticsData {...getSpotlightAnalyticsContextData('createDeliveryTicket')}>
			<Spotlight
				isActive={isLoaded && isShown}
				title={formatMessage(messages.titleSpork)}
				description={formatMessage(messages.description)}
				learnMoreText={formatMessage(
					fg('polaris-issue-terminology-refresh')
						? messages.learnMoreTextIssueTermRefresh
						: messages.learnMoreText,
				)}
				articleId="79Gsxo13vpSGA8lVFlA88n"
				onClose={() => closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET)}
				mode="absolute"
				placement={placement}
			>
				{children}
			</Spotlight>
		</ContextualAnalyticsData>
	);
};

import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types.tsx';
import { copyInsights, enableCopyInsightsMode, disableCopyInsightsMode } from './copy-insights.tsx';
import { createInsight } from './create-insight.tsx';
import { deleteInsight } from './delete-insight.tsx';
import { loadInsights, updateInsights } from './load-insights.tsx';
import { refreshInsight, refreshInsights } from './refresh-insights.tsx';
import { refreshSnippet } from './refresh-snippet.tsx';
import {
	toogleInsightSeletion,
	clearInsightSeletion,
	deleteSelectedInsights,
} from './selection.tsx';
import { updateInsight } from './update-insight.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	createInsight,
	updateInsight,
	deleteInsight,
	refreshInsight,
	refreshInsights,
	refreshSnippet,
	copyInsights,
	loadInsights,
	deleteSelectedInsights,
	clearInsightSeletion,
	toogleInsightSeletion,
	enableCopyInsightsMode,
	disableCopyInsightsMode,
	updateInsights,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

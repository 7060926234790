import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	startDate: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.start-date',
		defaultMessage: 'Start date',
		description: 'Label in the select when choosing interval date option',
	},
	endDate: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.end-date',
		defaultMessage: 'End date',
		description: 'Label in the select when choosing interval date option',
	},
	past: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.past',
		defaultMessage: 'Past',
		description: 'Label in the select when choosing time option',
	},
	current: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.current',
		defaultMessage: 'This',
		description: 'Label in the select when choosing time option',
	},
	next: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.next',
		defaultMessage: 'Next',
		description: 'Label in the select when choosing time option',
	},
	day: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.day',
		defaultMessage: 'day',
		description: 'Label in the select when choosing period option',
	},
	week: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.week',
		defaultMessage: 'week',
		description: 'Label in the select when choosing period option',
	},
	month: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.month',
		defaultMessage: 'month',
		description: 'Label in the select when choosing period option',
	},
	year: {
		id: 'polaris-common.filters.filter-component.interval.content-rolling-dates.year',
		defaultMessage: 'year',
		description: 'Label in the select when choosing period option',
	},
});

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { JiraRestTransition, JiraTransitionResponse } from './types.tsx';

export const getTransitionsForIssueType = (
	projectId: string | number,
	issueTypeId: string | number,
): Promise<JiraTransitionResponse> =>
	fetchJson(`/rest/polaris/transitions/get/${projectId}/${issueTypeId}`, {
		method: 'GET',
	});

export const transitionIssue = (issueKey: string, transition: JiraRestTransition) =>
	performPostRequest(`/rest/api/2/issue/${issueKey}/transitions`, {
		body: JSON.stringify({ transition }),
	});

import gqlTagPolaris from 'graphql-tag';
import { toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_CreateViewSet as CreateViewSet,
	jira_polaris_CreateViewSetVariables as CreateViewSetVariables,
} from './__generated_apollo__/jira_polaris_CreateViewSet';
import type { CreateViewSetProps, CreateViewSetReturn } from './types.tsx';

const CREATE_VIEW_SET_MUTATION = gqlTagPolaris`
mutation jira_polaris_CreateViewSet($projectAri: ID!, $name: String!) {
    createPolarisViewSet(input: {
    container: $projectAri
    name: $name
  }) {
    success
    errors {
        message
    }
    node {
        id
        name
        type
      }
    }
  }
`;

export const createViewSet = async (
	apolloClient: PolarisApolloClient,
	props: CreateViewSetProps,
): Promise<CreateViewSetReturn> => {
	const result = await apolloClient.mutate<CreateViewSet, CreateViewSetVariables>({
		mutation: CREATE_VIEW_SET_MUTATION,
		variables: {
			projectAri: props.projectAri,
			name: props.name,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.create-view-set-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (result.data == null) {
		throw new Error('project-config.create-view-set-error: expected data, got null');
	}

	// Awkward unpacking dance to make flow type checking happy with all the nullable
	// types.

	const { createPolarisViewSet } = result.data;

	if (createPolarisViewSet == null) {
		throw new Error(
			'project-config.create-view-set-error: createPolarisViewSet in response, got null',
		);
	}

	const { node } = createPolarisViewSet;

	if (node == null) {
		throw new Error('project-config.create-view-set-error: expected data node, got null');
	}

	const viewSetId = toAri(node.id);
	if (viewSetId === undefined) {
		throw new Error(`project-config.create-view-set-error: expected ari for view, got: ${node.id}`);
	}

	return {
		viewSetId,
		node: { ...node },
	};
};

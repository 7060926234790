import React, { useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { addInsightsGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/add-insights/index.tsx';
import { insightsGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/insights/index.tsx';
import { useCanCreateInsights } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsEmbedded } from '../../../controllers/environment/index.tsx';
import { useSafeIssueKey } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import {
	useInsightsCountForIssue,
	useUnseenInsightsAvailableForIssue,
} from '../../../controllers/issue/selectors/properties/insights/hooks.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import { useIsExporting } from '../../../controllers/views/selectors/view-hooks.tsx';
import { ValueButtonField } from '../value-button-field/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	localIssueId: LocalIssueId;
	appearance: 'list' | 'board';
	testId?: string;
};

export const InsightsField = ({
	localIssueId,
	appearance,
	testId = 'polaris-common.ui.fields.insights.value-button-field',
}: Props) => {
	const { formatMessage } = useIntl();
	const insightCount = useInsightsCountForIssue(localIssueId);
	const hasUnread = useUnseenInsightsAvailableForIssue(localIssueId);
	const canCreateInsights = useCanCreateInsights();
	const { openIssueView } = usePolarisRouter();
	const issueKey = useSafeIssueKey(localIssueId);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isExportingViewImage = useIsExportingViewImage();
	const isExportingOld = useIsExporting();
	const embedded = useIsEmbedded();

	const handleShow = useCallback(() => {
		if (!issueKey) return;
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'fieldValue',
			{
				issueField: 'insights',
			},
		);
		openIssueView(issueKey, { layout: 'sidebar', section: 'capture' });
	}, [issueKey, openIssueView, createAnalyticsEvent]);

	return (
		<ValueButtonField
			testId={testId}
			appearance={appearance === 'board' ? 'compact' : 'normal'}
			addGlyph={addInsightsGlyph}
			glyph={insightsGlyph}
			onClick={handleShow}
			hasUnread={hasUnread}
			placeholder={formatMessage(messages.placeholder)}
			isReadOnly={embedded}
			itemCount={insightCount}
			label="insights"
			isExporting={fg('polaris_extract-view-export') ? isExportingViewImage : isExportingOld}
			canAdd={canCreateInsights}
		/>
	);
};

import React, { useCallback, memo } from 'react';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useIssueKey,
	useStatus,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { StatusField } from '@atlassian/jira-polaris-common/src/ui/fields/status/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

/**
 * quite similar to the handler defined for the ideas app in the table. however, the single idea
 * view does not support any concept of bulk update, so in can be kept a lot simpler
 */
const useStatusSubmitHandler = (fieldKey: FieldKey, localIssueId: LocalIssueId) => {
	const { updateStatusInStateOnly } = useIssueActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		(oldStatus: StatusValue, newStatus: StatusValue) => {
			// the status value type we get from the field is incomplete even though it contains
			// all the field that we need. that is why we have to resort to a type check
			// failure ignore annotations
			// @ts-expect-error - The types of 'statusCategory.name' are incompatible between these types.
			updateStatusInStateOnly(fieldKey, localIssueId, newStatus);

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueStatus changed', 'status-field', {
				action: 'single',
				oldStatus,
				newStatus,
			});
		},
		[createAnalyticsEvent, fieldKey, localIssueId, updateStatusInStateOnly],
	);
};

export const Status = memo<Props>(({ fieldKey, localIssueId }: Props) => {
	const field = useField(fieldKey);
	const value = useStatus(localIssueId);
	const ideaKey = useIssueKey(localIssueId);
	const [canEditIssues] = useCanEditIssues();
	const onSubmit = useStatusSubmitHandler(fieldKey, localIssueId);

	if (field === undefined) {
		return null;
	}

	return (
		<StatusField
			value={value}
			issueKey={ideaKey}
			issueId={localIssueId}
			isEditable={canEditIssues && field.editable}
			onSubmit={onSubmit}
		/>
	);
});

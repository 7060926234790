import { createSelector } from 'reselect';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	type State,
	type Showing,
	showingNothing,
	RightSidebarShowingPlay,
	RightSidebarShowingSort,
	RightSidebarShowingIdeasBucket,
	RightSidebarShowingFilter,
} from '../types.tsx';

export const getShowing = (state: State): Showing => state.showing;

export const getOption = (state: State): string | null => state.option;

export const isRightSidebarShowingSort = (state: State): boolean =>
	state.showing.mode === RightSidebarShowingSort;

export const isRightSidebarOpen = (state: State): boolean => state.showing !== showingNothing;

export type IssueCommentContext = {
	localIssueId: LocalIssueId;
	newComment: boolean;
};

export type IssueContext = {
	localIssueId: LocalIssueId;
};

export type IssuePlayContext = {
	localIssueId: LocalIssueId;
	playId: Ari;
};

export const getRightSidebarContextPlay = createSelector(getShowing, (showing) => {
	if (showing.mode === RightSidebarShowingPlay) {
		return {
			localIssueId: showing.localIssueId,
			playId: showing.playId,
		};
	}
	return undefined;
});

export const getRightSidebarFilterTmpFieldKey = createSelector(getShowing, (showing) => {
	if (showing.mode === RightSidebarShowingFilter) {
		return showing.tmpFieldKey;
	}
	return undefined;
});

export const isIdeasBucketOpen = createSelector(
	getShowing,
	(showing) => showing.mode === RightSidebarShowingIdeasBucket,
);

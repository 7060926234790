import React from 'react';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { ExternalReferencePropertyField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference-property/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type Props = {
	fieldKey: FieldKey;
};

export const ExternalReferenceProperty = ({ fieldKey }: Props) => {
	const ideaId = useSelectedIssue();
	const field = useField(fieldKey);

	if (ideaId === undefined || field === undefined) {
		return null;
	}

	return <ExternalReferencePropertyField localIssueId={ideaId} fieldKey={fieldKey} />;
};

import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';

export const CustomTooltip = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => (
	<WarningTooltip {...props} ref={ref} />
));

export const ValidationIcon = (props: { errorMessage: string | undefined }) => {
	// takes the scrollbar on windows into account because it overlays the error icon

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const isWindows = navigator?.userAgent?.includes('Windows');
	return (
		<Tooltip component={CustomTooltip} content={props.errorMessage} position="bottom-end">
			<ErrorIconContainer isWindows={isWindows}>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				<ErrorIcon primaryColor={colors.R400} label="error" />
			</ErrorIconContainer>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningTooltip = styled(TooltipPrimitive)({
	display: 'block',
	boxSizing: 'border-box',
	margin: '0 -3px',
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.danger', colors.R400),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
	),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconContainer = styled.div<{ isWindows?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ isWindows }) => (isWindows ? '18px' : token('space.150', '12px')),
	lineHeight: '100%',
	cursor: 'pointer',
});

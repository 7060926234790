import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch/src/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { copyMediaAndAttachments } from '../../../issue/actions/attachments/utils.tsx';
import { type Props, type State, AttachmentsMergeOption } from '../../types.tsx';

export const copyAttachments =
	(): Action<State, Props> =>
	async ({ getState }, { projectId, issuesRemote, insightsRemote }) => {
		const { targetIssue, selectedIssues, mergeOptions } = getState();
		if (projectId === null || targetIssue === null) {
			return;
		}
		if (!mergeOptions[AttachmentsMergeOption]) {
			return;
		}

		try {
			await runInBatch(
				selectedIssues.map(
					(selectedIssue) => () =>
						copyMediaAndAttachments(
							insightsRemote,
							issuesRemote,
							selectedIssue.id,
							selectedIssue.key,
							targetIssue.key,
						),
				),
				2,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			log.safeErrorWithoutCustomerData(
				'polaris.merge-ideas-error',
				'Failed to add attachments to target idea',
				err,
			);
			throw err;
		}
	};

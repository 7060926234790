import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import type { AvatarCollections, CroppingProperties, SavedAvatar } from './types.tsx';

export const constructEndpoint = (projectId: number) =>
	`/rest/api/2/universal_avatar/type/issuetype/owner/project:${projectId}`;

export const constructPostEndPoint = (
	context: {
		projectId: number;
	},
	croppingProperties: CroppingProperties,
): string => // Get XSRF token from cookies to prevent usage of expired / outdated tokens
	`${constructEndpoint(context.projectId)}?atl_token=${getXsrfToken() || ''}` +
	`&x=${Math.floor(croppingProperties.x)}` +
	`&y=${Math.floor(croppingProperties.y)}` +
	`&size=${Math.floor(croppingProperties.size)}`;

export const get = (projectId: number): Promise<AvatarCollections> =>
	fetchJson(constructEndpoint(projectId));

export const save = (
	projectId: number,
	image: File,
	properties: CroppingProperties,
): Promise<SavedAvatar> => {
	const url = constructPostEndPoint({ projectId }, properties);
	return performPostRequest(url, {
		body: image,
		headers: {
			'Content-Type': image.type,
		},
	});
};

import React, {
	useCallback,
	useState,
	useMemo,
	useEffect,
	type ComponentPropsWithoutRef,
} from 'react';
import { Label } from '@atlaskit/form';
import { Stack, Box, Inline, Text } from '@atlaskit/primitives';
import Select, { type SelectProps } from '@atlaskit/select';
import Toggle from '@atlaskit/toggle';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import { isIntervalFieldSourcesKey } from '@atlassian/jira-polaris-domain-field/src/field/interval/utils.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../controllers/selectors/callback-hooks.tsx';
import { useReadonly } from '../../../controllers/selectors/config-hooks.tsx';
import { useField } from '../../../controllers/selectors/field-hooks.tsx';
import { DeliveryDateConfiguration } from './delivery-date/index.tsx';
import messages from './messages.tsx';

type ToggleProps = ComponentPropsWithoutRef<typeof Toggle>;

type OptionType = {
	label: string;
	value:
		| typeof INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE
		| typeof INTERVAL_FIELD_SOURCES.DELIVERY_DATE;
};

const dateSourceFieldId = 'interval-override-config-date-source-select';

export const IntervalOverrideConfiguration = () => {
	const field = useField();
	const isReadonly = useReadonly();
	const { onIntervalFieldSourceUpdated } = useCallbacks();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const atlasProjectTargetDateOption = useMemo(
		() => ({
			label: formatMessage(messages.atlasProjectTargetDateOption),
			value: INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE,
		}),
		[formatMessage],
	);

	const deliveryDateOption = useMemo(
		() => ({
			label: formatMessage(messages.deliveryDateOption),
			value: INTERVAL_FIELD_SOURCES.DELIVERY_DATE,
		}),
		[formatMessage],
	);

	const options = useMemo(() => {
		return [atlasProjectTargetDateOption, deliveryDateOption];
	}, [atlasProjectTargetDateOption, deliveryDateOption]);

	const defaultValue = useMemo(() => {
		switch (field?.configuration?.source) {
			case INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE:
				return atlasProjectTargetDateOption;
			case INTERVAL_FIELD_SOURCES.DELIVERY_DATE:
				return deliveryDateOption;
			default:
				return deliveryDateOption;
		}
	}, [field, atlasProjectTargetDateOption, deliveryDateOption]);

	useEffect(() => {
		const selectedSource = (() => {
			switch (field?.configuration?.source) {
				case INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE:
					return atlasProjectTargetDateOption;
				case INTERVAL_FIELD_SOURCES.DELIVERY_DATE:
					return deliveryDateOption;
				default:
					return deliveryDateOption;
			}
		})();

		setSelectedSourceOption(selectedSource);
	}, [atlasProjectTargetDateOption, deliveryDateOption, field?.configuration?.source]);

	const [selectedSourceOption, setSelectedSourceOption] = useState(defaultValue);

	const handleOverrideToggle = useCallback<NonNullable<ToggleProps['onChange']>>(
		(event, analyticsEvent) => {
			if (field?.key === undefined) {
				return;
			}

			const enabled = event.currentTarget.checked;
			if (enabled) {
				onIntervalFieldSourceUpdated?.(INTERVAL_FIELD_SOURCES.DELIVERY_DATE, analyticsEvent);
			} else {
				onIntervalFieldSourceUpdated?.(undefined, analyticsEvent);
			}
		},
		[field?.key, onIntervalFieldSourceUpdated],
	);

	const onChange = useCallback<NonNullable<SelectProps<OptionType>['onChange']>>(
		(item) => {
			if (!field?.key || !item || !isIntervalFieldSourcesKey(item.value)) {
				return;
			}

			onIntervalFieldSourceUpdated?.(item.value, createAnalyticsEvent({}));
		},
		[field?.key, onIntervalFieldSourceUpdated, createAnalyticsEvent],
	);

	const isOverrideEnabled = Boolean(field?.configuration?.source);

	return (
		<Stack space="space.200">
			<Stack space="space.100">
				<Text weight="semibold">{formatMessage(messages.sourceTitle)}</Text>
				<Inline space="space.200">
					<Text color="color.text">{formatMessage(messages.sourceDescription)}</Text>
					<Box>
						<Toggle
							isChecked={isOverrideEnabled}
							onChange={handleOverrideToggle}
							isDisabled={isReadonly}
						/>
					</Box>
				</Inline>
			</Stack>
			{isOverrideEnabled && (
				<Stack space="space.100">
					<Box>
						<Label htmlFor={dateSourceFieldId}>{formatMessage(messages.sourceSelectLabel)}</Label>
						<Select<OptionType>
							inputId={dateSourceFieldId}
							isClearable={false}
							defaultValue={defaultValue}
							value={selectedSourceOption}
							options={options}
							onChange={onChange}
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							menuPortalTarget={document.body}
							// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
							styles={{
								// without proper zIndex value, dropdown with options is cut inside modal dialogs

								menuPortal: (base) => ({ ...base, zIndex: layers.modal }),
								menu: (base) => ({ ...base, zIndex: 10 }),
							}}
							isDisabled={isReadonly}
						/>
					</Box>
					{field?.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE && (
						<DeliveryDateConfiguration />
					)}
				</Stack>
			)}
		</Stack>
	);
};

import { useCallback, useMemo } from 'react';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type {
	FieldValueFilter,
	Filter,
	NumericFieldFilter,
	IntervalFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useFieldKeysOfType } from '../../controllers/field/selectors/field-hooks.tsx';
import {
	useViewFieldKeysByAri,
	useViewFilter,
} from '../../controllers/views/selectors/view-hooks.tsx';

const UNSUPPORTED_FIELD_TYPES = [
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.STATUS,
	FIELD_TYPES.ISSUE_ID,
	FIELD_TYPES.REACTIONS,
	FIELD_TYPES.ASSIGNEE,
	FIELD_TYPES.CREATOR,
	FIELD_TYPES.REPORTER,
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,
];

export type IssueFormData = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

const canApplyTheFilter = (filter: FieldValueFilter | NumericFieldFilter | IntervalFieldFilter) => {
	if (filter.type === 'INTERVAL' || UNSUPPORTED_FIELD_TYPES.some((t) => t === filter.fieldType)) {
		return false;
	}
	if (filter.values.length === 1 && filter.type === 'NUMBER') {
		const numericField: NumericFieldFilter = filter;
		if (numericField.values[0].operator === 'EQ') {
			return true;
		}
	}

	if (filter.type === 'FIELD') {
		const hasEmptyValue =
			filter.values.find(({ stringValue }) => stringValue === undefined) !== undefined;

		return filter.values.length <= 1 || hasEmptyValue;
	}

	return false;
};

const getNumericAndFieldValueFilters = (
	currentViewFilters: Filter[],
): (FieldValueFilter | NumericFieldFilter | IntervalFieldFilter)[] => // used reduce instead of filter because of known limitation of filter function https://github.com/facebook/flow/issues/6516
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentViewFilters.reduce<Array<any>>(
		(
			acc: (FieldValueFilter | NumericFieldFilter | IntervalFieldFilter)[],
			filter,
		): (FieldValueFilter | NumericFieldFilter | IntervalFieldFilter)[] => {
			if (filter.type === 'NUMBER' || filter.type === 'FIELD' || filter.type === 'INTERVAL') {
				acc.push(filter);
			}
			return acc;
		},
		[],
	);

const getFilterValueForUpdate = (
	filter: FieldValueFilter | NumericFieldFilter | IntervalFieldFilter,
	isCheckbox: boolean,
): unknown => {
	if (filter.type === 'INTERVAL') {
		return undefined;
	}
	if (filter.type === 'NUMBER') {
		const numericFilter: NumericFieldFilter = filter;
		const { numericValue } = numericFilter.values[0];

		if (isCheckbox && numericFilter.values[0].operator === 'NEQ' && numericValue === 1) {
			return 0;
		}

		return numericValue;
	}

	if (filter.type !== 'FIELD') {
		return undefined;
	}

	const fieldFilter: FieldValueFilter = filter;
	const hasEmptyValue =
		fieldFilter.values.find(({ stringValue }) => stringValue === undefined) !== undefined;
	const strValue = fieldFilter.values[0]?.stringValue;
	if (strValue == null || hasEmptyValue) {
		return undefined;
	}

	switch (fieldFilter.fieldType) {
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return [{ accountId: strValue }];
		case FIELD_TYPES.SINGLE_SELECT:
			return { id: strValue };
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return [strValue];
		default:
			return [{ id: strValue }];
	}
};

/**
 * @returns callback which returns predefined values for fields which match filters
 */

const useFiltersToApply = (viewAri: string | undefined) => {
	const viewFilters = useViewFilter(viewAri);
	const checkboxFieldKeys = useFieldKeysOfType(FIELD_TYPES.CHECKBOX);
	return useMemo(() => {
		if (!viewFilters) {
			return [];
		}
		const allFilters = getNumericAndFieldValueFilters(viewFilters);
		return allFilters.filter(
			(filter) => canApplyTheFilter(filter) || checkboxFieldKeys.includes(filter.field),
		);
	}, [viewFilters, checkboxFieldKeys]);
};

export const useTryApplyFiltersToIssuePayload = (viewAri: string | undefined) => {
	const filtersToApply = useFiltersToApply(viewAri);
	const viewFieldKeys = useViewFieldKeysByAri(viewAri);
	const checkboxFieldKeys = useFieldKeysOfType(FIELD_TYPES.CHECKBOX);
	return useCallback(
		(transformedData: IssueFormData) => {
			if (!filtersToApply || !filtersToApply.length) {
				return transformedData;
			}
			let dataWithFilters = transformedData;
			filtersToApply.forEach((filter) => {
				const newValue = getFilterValueForUpdate(filter, checkboxFieldKeys.includes(filter.field));
				if (viewFieldKeys && !viewFieldKeys.includes(filter.field)) {
					dataWithFilters = {
						...dataWithFilters,
						[filter.field]: newValue,
					};
				}
			});
			return dataWithFilters;
		},
		[checkboxFieldKeys, filtersToApply, viewFieldKeys],
	);
};

export const usePredefinedFiltersFieldValues = (viewAri: string | undefined) => {
	const filtersToApply = useFiltersToApply(viewAri);
	const checkboxFieldKeys = useFieldKeysOfType(FIELD_TYPES.CHECKBOX);
	if (!filtersToApply || !filtersToApply.length) {
		return [];
	}

	const predefinedValues = filtersToApply.map((filter) => {
		const newValue = getFilterValueForUpdate(filter, checkboxFieldKeys.includes(filter.field));
		return {
			fieldKey: filter.field,
			value: newValue,
		};
	});

	return predefinedValues;
};

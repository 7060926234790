import React, { useEffect } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { CROSS_PROJECT_MATRIX_VIEW_FIELDS_DISMISSED_KEY } from '../../../../controllers/constants.tsx';
import { useOnboardingFlowsActions } from '../../../../controllers/index.tsx';
import { useCrossProjectMatrixViewFieldsDismissed } from '../../../../controllers/selectors/cross-project-view-hooks.tsx';
import { CrossProjectViewFieldsOnboarding } from '../onboarding-fields/index.tsx';
import { messages } from './messages.tsx';

export const CrossProjectMatrixViewFieldsOnboarding = () => {
	const isCrossProjectMatrixViewFieldsDismissed = useCrossProjectMatrixViewFieldsDismissed();
	const { loadCrossProjectViewFieldsDismissed } = useOnboardingFlowsActions();
	const { formatMessage } = useIntl();

	useEffect(() => {
		loadCrossProjectViewFieldsDismissed(CROSS_PROJECT_MATRIX_VIEW_FIELDS_DISMISSED_KEY);
	}, [loadCrossProjectViewFieldsDismissed]);

	if (isCrossProjectMatrixViewFieldsDismissed !== false) {
		return null;
	}

	return (
		<Box xcss={wrapperStyles}>
			<CrossProjectViewFieldsOnboarding
				stateKey={CROSS_PROJECT_MATRIX_VIEW_FIELDS_DISMISSED_KEY}
				title={formatMessage(messages.title)}
				body={formatMessage(messages.body)}
			/>
		</Box>
	);
};

const wrapperStyles = xcss({
	marginLeft: 'space.200',
	marginRight: 'space.200',
});

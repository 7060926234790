import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { checkProperty } from './check-property/index.tsx';
import { closeDialog } from './close-dialog/index.tsx';
import { loadPropertiesConfig } from './load-propeties-config/index.tsx';
import { openDialog } from './open-dialog/index.tsx';
import { saveConfig } from './save-config/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadPropertiesConfig,
	openDialog,
	closeDialog,
	checkProperty,
	saveConfig,
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

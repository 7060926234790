import { useCallback, useEffect, useMemo } from 'react';
import type { BroadcastMessageMap } from './common/types/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FieldChange } from './common/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { GLOBAL_FIELDS_CHANNEL } from './common/constants';

export const useBroadcastListener = <Channel extends keyof BroadcastMessageMap>(
	channelName: Channel,
	onEvent: (payload: BroadcastMessageMap[Channel]) => void,
) => {
	const channel = useMemo(() => new BroadcastChannel(channelName), [channelName]);

	useEffect(
		() => () => {
			channel.close();
		},
		[channel],
	);

	const handleMessage = useCallback(
		(evt: MessageEvent<BroadcastMessageMap[Channel]>) => {
			onEvent(evt.data);
		},
		[onEvent],
	);

	useEffect(() => {
		channel.addEventListener('message', handleMessage);

		return () => {
			channel.removeEventListener('message', handleMessage);
		};
	}, [channel, handleMessage]);
};

export const useBroadcastProducer = <Channel extends keyof BroadcastMessageMap>(
	channelName: Channel,
) => {
	const channel = useMemo(() => new BroadcastChannel(channelName), [channelName]);

	useEffect(
		() => () => {
			channel.close();
		},
		[channel],
	);

	return useCallback(
		(payload: BroadcastMessageMap[Channel]) => {
			channel.postMessage(payload);
		},
		[channel],
	);
};

import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import { getViewUUID } from '../utils/index.tsx';

const getPutUrl = (collectionUUID: string, viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/rank`;

export type UpdateViewRankProps = {
	viewId: string;
	rank: number;
};

export const updateCollectionViewRank = (
	collectionUUID: string,
	{ viewId, rank }: UpdateViewRankProps,
) =>
	performPutRequest<ViewResponse>(getPutUrl(collectionUUID, getViewUUID(viewId)), {
		body: JSON.stringify({
			rank: rank - 1,
			parent: collectionUUID,
		}),
	});

import React, { useCallback } from 'react';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { AddFieldComponent } from '@atlassian/jira-polaris-common/src/ui/field-config/add/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Props } from './types.tsx';

export const ColumnAdd = ({ isDisabled }: Props) => {
	const isSharedView = useIsSharedView();
	const { toggleViewField } = useViewActions();

	const onAddField = useCallback(
		(fieldKey: FieldKey) => {
			toggleViewField({ fieldKey, isChecked: true });
		},
		[toggleViewField],
	);

	if (isSharedView) {
		// users cannot edit configuration in shared views
		return null;
	}

	return <AddFieldComponent isDisabled={isDisabled} onAddField={onAddField} />;
};

import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldRollupOperation } from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { PartialRecord } from '../types.tsx';
import {
	createGetFieldRollupOperations,
	createGetNumberFieldsRollup,
	createGetUserFieldsRollup,
	createGetCommentFieldsRollup,
	createGetSelectFieldsRollup,
	createGetLabelsFieldsRollup,
	createGetStringFieldsRollup,
	createGetIntervalFieldsRollup,
} from './fields-rollup.tsx';
import {
	createHigherLevelIssueHook,
	createHigherLevelIssueHook3,
	createHigherLevelIssueHook4,
} from './utils.tsx';

export const useGetFieldRollupOperations = createHigherLevelIssueHook<
	FieldKey,
	FieldRollupOperation[]
>(createGetFieldRollupOperations);

// Number fields rollups hooks
export const useGetNumberFieldsRollup = createHigherLevelIssueHook3<
	FieldKey,
	FieldKey,
	LocalIssueId,
	PartialRecord<FieldRollupOperation, number>
>(createGetNumberFieldsRollup);

// User fields rollups hooks
export const useGetUserFieldsRollup = createHigherLevelIssueHook4<
	FieldKey,
	FieldKey,
	LocalIssueId,
	FieldRollupOperation,
	number
>(createGetUserFieldsRollup);

// Comments fields rollups hooks
export const useGetCommentFieldsRollup = createHigherLevelIssueHook3<
	FieldKey,
	LocalIssueId,
	FieldRollupOperation,
	number
>(createGetCommentFieldsRollup);

// Select fields rollups hooks
export const useGetSelectFieldsRollup = createHigherLevelIssueHook4<
	FieldKey,
	FieldKey,
	LocalIssueId,
	FieldRollupOperation,
	number | undefined
>(createGetSelectFieldsRollup);

// Labels fields rollups hooks
export const useGetLabelsFieldsRollup = createHigherLevelIssueHook4<
	FieldKey,
	FieldKey,
	LocalIssueId,
	FieldRollupOperation,
	number
>(createGetLabelsFieldsRollup);

// String fields rollups hooks
export const useGetStringFieldsRollup = createHigherLevelIssueHook4<
	FieldKey,
	FieldKey,
	LocalIssueId,
	FieldRollupOperation,
	number
>(createGetStringFieldsRollup);

// Date fields rollups hooks
export const useGetIntervalFieldsRollup = createHigherLevelIssueHook3<
	FieldKey,
	FieldKey,
	LocalIssueId,
	{ min: string; max: string } | undefined
>(createGetIntervalFieldsRollup);

import type { ReactNode, ComponentType, RefObject } from 'react';
import type { Header, Scale } from './common/types/timeline/index.tsx';

/** id for items in the timeline - note that items can appear in several groups */
export type ItemId = string;

/** special group id for no assigned grouping value */
export const NO_VALUE_GROUP_ID = 'no-value-group-id';

/** The id of the value associated with an item */
export type GroupId = string | typeof NO_VALUE_GROUP_ID;

/** Mapping of group IDs to array of items in this group */
export type GroupToItems = Record<GroupId, ItemId[]>;

export type ItemProps = {
	id: ItemId;
	isDraggable: boolean;
	isResizing: boolean;
	isFillFullWidth: boolean;
};

export type DragItemWrapperProps = {
	id: ItemId;
	children: ReactNode;
};

export type ExternalDateActionDisabledHintProps = {
	issueId: ItemId;
	side: 'start' | 'end';
};

export type RowGroupProps = {
	children?: ReactNode;
	groupIdentity: string | undefined;
	itemsCount: number;
	isOpenGroup: boolean;
	/**
	 * The ref of the header container, used for groups drag and drop.
	 * Should be omitted in the case of a preview
	 */
	headerContainerRef?: RefObject<HTMLDivElement>;
	isPreview?: boolean;
};

export type RowGroupPreviewProps = Omit<RowGroupProps, 'headerContainerRef'>;

export type GroupedIds = {
	groups: GroupToItems;
	empty?: ItemId[];
	noGroup?: ItemId[];
};

export type Components = {
	Item?: ComponentType<ItemProps>;
	RowGroup?: ComponentType<RowGroupProps>;
	RowGroupPreview?: ComponentType<RowGroupPreviewProps>;
	ExternalDateDragDisabledHint?: ComponentType<ExternalDateActionDisabledHintProps>;
	ExternalDateResizeDisabledHint?: ComponentType<ExternalDateActionDisabledHintProps>;
	ExternalDragItemWrapper?: ComponentType<DragItemWrapperProps>;
};

export type ChangedItem = TimelineItem & {
	// group to be set, if absent - no change
	newGroupIds?: GroupId[];
	oldGroupId?: GroupId;
};

/** Mapping of items to preferred row number */
export type ItemRows = Record<ItemId, number>;

/** Mapping of groups to item-to-row mappings, as timelines can be grouped. */
export type GroupedItemArrangement = Record<GroupId, ItemRows>;

export type Marker = {
	id?: string;
	label: string;
	date: string;
	color: string;
};

export type CreateMarker = Pick<Marker, 'label' | 'color' | 'date'>;

type IntervalValue = {
	label?: string;
	value: { start: string; end: string };
	isExternal?: boolean;
};

export type TimelineItem = {
	id: string;
	startDateInterval: IntervalValue;
	endDateInterval: IntervalValue;
};

export type Props = {
	items: TimelineItem[];
	isReadOnly: boolean;
	hideEmptyGroups?: boolean;
	isSorted: boolean;
	groupedIds?: GroupedIds;
	rowGroups?: (GroupId | undefined)[];
	isGroupByFieldEditable: boolean;
	isMoveBetweenGroupsDisabled?: boolean;
	isVerticalCardReorderingEnabled?: boolean;
	headers: Header[];
	components?: Components | undefined;
	itemHeight: number;
	onItemChange?: (item: ChangedItem) => void;
	onArrangementUpdated: (newArrangement: GroupedItemArrangement) => void;
	itemArrangement: GroupedItemArrangement | undefined;
	onGroupOrderChanged?: (changeRequest: { oldIndex: number; newIndex: number }) => void;
	minColumnWidth?: number;
	maxColumnWidth?: number;
	scale: Scale;
	startDate: Date;
	endDate: Date;
	hasOffset?: boolean;
	// containerRef is a ref of the parent element of the timeline (scroll container). It is used to calculate the width of columns to make it responsive
	containerRef: RefObject<HTMLElement>;
	markers?: Marker[];
	isMarkerReadOnly?: boolean;
	onMarkerDelete?: (id: string) => void;
	onMarkerCreate?: (markerData: CreateMarker) => void;
	onMarkerUpdate?: (markerData: Marker) => void;
};

import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsEmbedded } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { usePreloadedSummary } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIsSelectedIssueAvailable } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import messages from './messages.tsx';

type EditableTitleProps = {
	isEditing: boolean;
	isEditingAllowed: boolean;
	summary: string;
	onEdit: () => void;
	onCancel: () => void;
	onConfirm: (value: string) => void;
};

type ReadViewProps = {
	isEditingAllowed: boolean;
	isEmbedded: boolean;
	summary: string;
	onEdit: () => void;
};

type EditViewProps = {
	summary: string;
	onConfirm: () => void;
	onChange: (value: string) => void;
	onCancel: () => void;
};

const ReadView = memo<ReadViewProps>(
	({ summary, onEdit, isEditingAllowed, isEmbedded }: ReadViewProps) => (
		<ReadViewContainer
			isEditingAllowed={isEditingAllowed}
			isEmbedded={isEmbedded}
			onClick={() => onEdit()}
		>
			{summary}
		</ReadViewContainer>
	),
);

const EditView = memo<EditViewProps>(
	({ summary, onConfirm, onCancel, onChange }: EditViewProps) => {
		const [valueInternal, setValueInternal] = useState(summary);

		useEffect(() => {
			setValueInternal(summary);
		}, [summary]);

		const changeHandler = (newValue: string) => {
			setValueInternal(newValue);
			onChange(newValue);
		};

		return (
			<>
				<TextField
					onBlur={onConfirm}
					maxLength={255}
					onKeyDown={(event) => {
						// handle ENTER key
						if (event.keyCode === 13) {
							onConfirm();
						}
						// handle ESC key
						if (event.keyCode === 27) {
							onCancel();
						}
					}}
					value={valueInternal}
					// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
					onChange={(event) => changeHandler(event.target.value)}
					autoFocus
					isCompact
				/>
			</>
		);
	},
);

export const EditableSummary = memo<EditableTitleProps>(
	({ summary, isEditing, isEditingAllowed, onEdit, onCancel, onConfirm }: EditableTitleProps) => {
		const isSelectedIssueAvailable = useIsSelectedIssueAvailable();
		const preloadedValue = usePreloadedSummary();

		const embedded = useIsEmbedded();

		const isAvailable = isSelectedIssueAvailable || preloadedValue !== undefined;

		const valueRef = useRef<string | null>(null);
		const confirmRef = useRef<((arg: string) => void) | null>(null);

		const [valueInternal, setValueInternal] = useState(summary);
		const { formatMessage } = useIntl();

		const confirm = useCallback(() => {
			if (valueInternal !== summary) {
				onConfirm(valueInternal || formatMessage(messages.defaultIdeaTitle));
			} else {
				onCancel();
			}
		}, [valueInternal, summary, formatMessage, onConfirm, onCancel]);

		useEffect(() => {
			valueRef.current = valueInternal;
			confirmRef.current = confirm;
		}, [valueInternal, confirm]);

		useEffect(() => {
			setValueInternal(summary);
		}, [summary]);

		useEffect(
			() =>
				function cleanup() {
					if (confirmRef.current && valueRef.current) {
						confirmRef.current(valueRef.current);
					}
				},
			[],
		);

		return (
			<Container isEmbedded={embedded} data-testid="polaris-ideas.ui.idea-view.summary.container">
				{!isAvailable && (
					<Box xcss={skeletonWrapperStyles}>
						<Skeleton height="36px" width="50%" />
					</Box>
				)}
				{isAvailable && !isEditing && (
					<ReadView
						isEmbedded={embedded}
						isEditingAllowed={isEditingAllowed}
						summary={summary}
						onEdit={onEdit}
					/>
				)}
				{isAvailable && isEditing && (
					<EditView
						summary={summary}
						onConfirm={confirm}
						onCancel={onCancel}
						onChange={setValueInternal}
					/>
				)}
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isEmbedded: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '24px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontWeight: ({ isEmbedded }) => (isEmbedded ? 600 : 500),
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '24px',
		marginLeft: token('space.075', '6px'),
		marginRight: '0px',
		marginTop: '0px',
		marginBottom: '0px',
		padding: '0px',
		lineHeight: '28px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{ isEditingAllowed: boolean; isEmbedded: boolean }>({
	overflow: 'hidden',
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
	borderTop: '3px solid transparent',
	borderBottom: '3px solid transparent',
	paddingLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '11px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '11px',
	transition: '0.3s',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: ({ isEmbedded }) => (isEmbedded ? '16px' : '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	lineHeight: ({ isEmbedded }) => (isEmbedded ? '20px' : '24px'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isEditingAllowed }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isEditingAllowed ? token('color.background.neutral.hovered', colors.N30) : 'transparent',
	},
});

const skeletonWrapperStyles = xcss({
	margin: 'space.100',
});

import React, { type PropsWithChildren, type ReactNode } from 'react';
import { useLocalStorage } from '@atlaskit/frontend-utilities';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';

type Props = {
	id: string;
	title: ReactNode;
};

export const CollapsibleGroup = ({ id, title, children }: PropsWithChildren<Props>) => {
	const [isOpen, setIsOpen] = useLocalStorage(id, true);

	return (
		<Stack space="space.100">
			<Pressable xcss={triggerStyles} onClick={() => setIsOpen(!isOpen)}>
				<Inline space="space.100" alignBlock="center">
					{isOpen ? <ChevronDownIcon label="" /> : <ChevronRightIcon label="" />}
					{title}
				</Inline>
			</Pressable>
			{isOpen && children}
		</Stack>
	);
};

const triggerStyles = xcss({
	background: 'none',
	paddingBlock: 'space.050',
	paddingInline: 'space.0',
});

import React, { memo } from 'react';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { PermalinkType } from '@atlassian/jira-polaris-common/src/common/utils/permalink/index.tsx';
import { useInfiniteScrollEmulator } from '../../../../common/utils/infinite-scroll-emulator.tsx';
import { Insight } from '../insight/index.tsx';
import { PlayInsight } from '../play-insight/index.tsx';

type InsightsListProps = {
	insightIds: string[];
	playInsightIds: Record<string, boolean>;
	playInsightIdsWithContributions: Record<string, boolean>;
	issueId: string;
	scrollableRef: React.RefObject<HTMLElement>;
};

const getPermalinkIdFromInsightAri = (ari: string) => {
	try {
		const config = getAriConfig(ari);
		return config.resourceId;
	} catch {
		return undefined;
	}
};

export const InsightsList = memo(
	({
		insightIds,
		playInsightIds,
		playInsightIdsWithContributions,
		issueId,
		scrollableRef,
	}: InsightsListProps) => {
		const visibleCount = useInfiniteScrollEmulator(scrollableRef, insightIds, {
			permalinkType: PermalinkType.INSIGHTS,
			getItemPermalinkId: getPermalinkIdFromInsightAri,
		});
		return (
			<>
				{insightIds.slice(0, visibleCount).map((id) => {
					if (playInsightIds[id] === true) {
						if (playInsightIdsWithContributions[id] === true) {
							return <PlayInsight key={id} insightId={id} issueId={issueId} />;
						}
						return null;
					}
					return <Insight key={id} insightId={id} />;
				})}
			</>
		);
	},
);

InsightsList.displayName = 'InsightsList';

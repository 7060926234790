import { colors } from '@atlaskit/theme';
import {
	type StatusCategory,
	UNDEFINED,
	TODO,
	IN_PROGRESS,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';

export const APP_ID = 'workflow-editor';

export const WORKFLOW_LOAD_START_MARK_KEY = 'WORKFLOW_LOAD_START_MARK_KEY';
export const WORKFLOW_LOAD_END_MARK_KEY = 'WORKFLOW_LOAD_END_MARK_KEY';
export const WORKFLOW_READY_MARK_KEY = 'WORKFLOW_READY_MARK_KEY';
export const WORKFLOW_EDITOR_APP_NAME = 'project-settings-apps.workflow-editor';

export const START_NODE_ID = 'START';

export const INITIAL = 'INITIAL' as const;
export const GLOBAL = 'GLOBAL' as const;
export const DIRECTED = 'DIRECTED' as const;

export type ErrorSeverityEnum = 'WARNING' | 'ERROR';
export const errorSeverity: {
	[key: string]: ErrorSeverityEnum;
} = {
	WARNING: 'WARNING',
	ERROR: 'ERROR',
};

export type ErrorTypeEnum =
	| 'CONFLICTING_NAME'
	| 'STATUS_ISLAND'
	| 'CUSTOM'
	| 'NON_DONE_SINK'
	| 'MISSING_FIELD'
	| 'MISSING_OPTION'
	| 'MISSING_ACCOUNT'
	| 'MISSING_ROLE'
	| 'MISSING_STATUS';

export const errorType: {
	[key: string]: ErrorTypeEnum;
} = {
	CONFLICTING_NAME: 'CONFLICTING_NAME',
	STATUS_ISLAND: 'STATUS_ISLAND',
	NON_DONE_SINK: 'NON_DONE_SINK',
	CUSTOM: 'CUSTOM',
	MISSING_FIELD: 'MISSING_FIELD',
	MISSING_OPTION: 'MISSING_OPTION',
	MISSING_ACCOUNT: 'MISSING_ACCOUNT',
	MISSING_ROLE: 'MISSING_ROLE',
	MISSING_STATUS: 'MISSING_STATUS',
};

export type ErrorNodeEnum = 'TRANSITION' | 'STATUS' | 'RULE' | 'WORKFLOW';
export const errorNode: Record<string, ErrorNodeEnum> = {
	TRANSITION: 'TRANSITION',
	STATUS: 'STATUS',
	RULE: 'RULE',
	WORKFLOW: 'WORKFLOW',
};

export type RuleErrorCode =
	| 'FIELD_NOT_FOUND'
	| 'TRANSITION_SCREEN_FIELD_NOT_FOUND'
	| 'FIELD_NOT_PRESENT_IN_ALL_ISSUE_TYPES'
	| 'TRANSITION_SCREEN_FIELD_NOT_PRESENT_IN_ALL_ISSUE_TYPES'
	| 'MISSING_RULE_PARAMETER'
	| 'PREVIOUS_STATUS_NOT_FOUND'
	| 'FIELD_VALUE_NOT_FOUND'
	| 'UNSUPPORTED_RULE'
	| 'ASSIGNEE_NOT_FOUND'
	| 'PROJECT_ROLE_NOT_FOUND'
	| 'UNSUPPORTED_FIELD_TYPE_FOR_RULE';
export const ruleErrorCodes = {
	FIELD_NOT_FOUND: 'FIELD_NOT_FOUND',
	TRANSITION_SCREEN_FIELD_NOT_FOUND: 'TRANSITION_SCREEN_FIELD_NOT_FOUND',
	FIELD_NOT_PRESENT_IN_ALL_ISSUE_TYPES: 'FIELD_NOT_PRESENT_IN_ALL_ISSUE_TYPES',
	TRANSITION_SCREEN_FIELD_NOT_PRESENT_IN_ALL_ISSUE_TYPES:
		'TRANSITION_SCREEN_FIELD_NOT_PRESENT_IN_ALL_ISSUE_TYPES',
	MISSING_RULE_PARAMETER: 'MISSING_RULE_PARAMETER', // This error is unlikey on load
	PREVIOUS_STATUS_NOT_FOUND: 'PREVIOUS_STATUS_NOT_FOUND',
	FIELD_VALUE_NOT_FOUND: 'FIELD_VALUE_NOT_FOUND',
	UNSUPPORTED_RULE: 'UNSUPPORTED_RULE',
	ASSIGNEE_NOT_FOUND: 'ASSIGNEE_NOT_FOUND',
	PROJECT_ROLE_NOT_FOUND: 'PROJECT_ROLE_NOT_FOUND',
	UNSUPPORTED_FIELD_TYPE_FOR_RULE: 'UNSUPPORTED_FIELD_TYPE_FOR_RULE',
} as const;

/** @deprecated Please use the type `StatusCategoryDiagramColors` from `@atlassian/jira-project-configuration-commons` */
type StatusCategoryDiagramColors = {
	border?: string;
	background: string;
	text: string;
};

/** @deprecated Please use `statusCategoryDiagramColors` or `useWorkflowDiagramTheme` from `@atlassian/jira-project-configuration-commons` */
export const statusCategoryDiagramColors: Record<StatusCategory, StatusCategoryDiagramColors> = {
	[UNDEFINED]: {
		border: colors.N50,
		background: colors.N30,
		text: colors.N500,
	},
	[TODO]: {
		border: colors.N50,
		background: colors.N40,
		text: colors.N500,
	},
	[IN_PROGRESS]: {
		border: colors.B75,
		background: colors.B50,
		text: colors.B500,
	},
	[DONE]: {
		border: colors.G75,
		background: colors.G50,
		text: colors.G500,
	},
};

export const MAX_NAME_LENGTH = 60;

export const onboardingTarget = {
	START: 'wfe-onboarding-start' as const,
	TOOLBAR_STATUSES: 'wfe-onboarding-toolbar-statuses' as const,
	TOOLBAR_TRANSITION: 'wfe-onboarding-toolbar-transition' as const,
	TOOLBAR_RULE: 'wfe-onboarding-toolbar-rule' as const,
	TOOLBAR_MORE_MENU: 'wfe-onboarding-toolbar-more-menu' as const,
} as const;

export const WORKFLOW_UNDO_HISTORY_LIMIT = 50;

export const WORKFLOW_OPENED_START_EVENT = 'workflowEditorOpenedStart';
export const WORKFLOW_OPENED_SUCCESS_EVENT = 'workflowEditorOpenedSuccess';
export const WORKFLOW_OPENED_SUCCESS_WITH_CAPABILITIES_EVENT =
	'workflowEditorOpenedSuccessWithCapabilities';
export const WORKFLOW_SAVED_START_EVENT = 'workflowEditorSavedStart';
export const WORKFLOW_SAVED_SUCCESS_EVENT = 'workflowEditorSavedSuccess';

export const migrationStates = Object.freeze({
	/** An issue migration is not happening. */
	NONE: 'NONE',
	/** An issue migration is happening and the UI will wait a short time. */
	SHORT_WAIT: 'SHORT_WAIT',
	/** An issue migration is taking a long time. */
	LONG_WAIT: 'LONG_WAIT',
	/** An issue migration finished after a short wait */
	REFRESH_AFTER_SHORT_WAIT: 'REFRESH_AFTER_SHORT_WAIT',
	/** An issue migration finished after a long wait */
	REFRESH_AFTER_LONG_WAIT: 'REFRESH_AFTER_LONG_WAIT',
});

export type MigrationStateEnum = (typeof migrationStates)[keyof typeof migrationStates];

export const WFE_LAYERS = {
	ADD_STATUS_MODAL: 'ADD_STATUS_MODAL',
	CREATE_TRANSITION_MODAL: 'CREATE_TRANSITION_MODAL',
	STATUS_REMAP_MODAL: 'STATUS_REMAP_MODAL',
} as const;

export type WfeLayerId = keyof typeof WFE_LAYERS;

import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueType } from '@atlassian/jira-polaris-component-issue-types/src/controllers/types.tsx';
import { IssueTypeChangeProvider } from '@atlassian/jira-polaris-component-issue-types/src/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { PolarisIdeasCommonContext } from '../context/index.tsx';
import { useActions as useDynamicFieldActions } from '../dynamic-field/index.tsx';
import type { FieldValueSyncTask } from './actions/sync-field-values/index.tsx';
import {
	useIssueActionFailedHandling,
	useIssueCreationFailedHandling,
	useIssueLoadingFailedHandling,
	useIssueUpdateFailedHandling,
} from './error/index.tsx';
import {
	IssueIdeaContextHandler,
	IssuesSweetStateContainer,
	IssuesLoader,
	useIssueActions as useIssueActionsInternal,
} from './main.tsx';
import { PolarisIssueRealtimeHandler } from './realtime/index.tsx';
import type { ExternalProps } from './types.tsx';
import { useIssueBulkUpdate } from './utils/issue-bulk-update/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useIssueActions, useIssueStore } from './main';

type IssueTypeChangeHandlerProps = {
	projectId: string | undefined;
};

const IssueTypeChangeHandler = ({ projectId }: IssueTypeChangeHandlerProps) => {
	const { syncFieldValues } = useIssueActionsInternal();

	const onChanged = (
		changes: { current: IssueType; previous: IssueType }[],
		deleted: IssueTypeId[],
	) => {
		const syncTasks: FieldValueSyncTask[] = [];

		deleted.forEach((id) => {
			syncTasks.push({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				identity: (t) => (t as IssueTypeFieldValue).id === id,
				newValue: undefined,
			});
		});

		changes.forEach(({ current }) => {
			syncTasks.push({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				identity: (t) => (t as IssueTypeFieldValue).id === current.id,
				newValue: {
					iconUrl: `/rest/api/2/universal_avatar/view/type/issuetype/avatar/${current.avatarId}?size=medium`,
					id: current.id,
					name: current.name,
				},
			});
		});

		syncFieldValues(FIELD_TYPES.ISSUE_TYPE, syncTasks);
	};

	if (projectId === undefined) {
		return null;
	}

	return <IssueTypeChangeProvider projectId={projectId} onChanged={onChanged} />;
};

export const IssuesContainer = (props: ExternalProps) => {
	const { children, ...rest } = props;
	const { projectId, onContainerReady } = rest;
	const handleIssueBulkUpdate = useIssueBulkUpdate();
	const handleIssueLoadingFailed = useIssueLoadingFailedHandling();
	const handleIssueCreationFailed = useIssueCreationFailedHandling();
	const handleIssueUpdateFailed = useIssueUpdateFailedHandling();
	const handleIssueActionFailed = useIssueActionFailedHandling();
	const { markDynamicFieldAsInitialized, initialized } = useDynamicFieldActions();

	return (
		<PolarisIdeasCommonContext.Consumer>
			{({ onUpdateComments, ...contextRest }) => (
				<IssuesSweetStateContainer
					{...rest}
					scope="issues-singleton"
					onIssueBulkUpdate={handleIssueBulkUpdate}
					onIssueLoadingFailed={handleIssueLoadingFailed}
					onIssueCreationFailed={handleIssueCreationFailed}
					onIssueUpdateFailed={handleIssueUpdateFailed}
					onActionFailed={handleIssueActionFailed}
					onDynamicFieldInitialized={markDynamicFieldAsInitialized}
					onDynamicFieldStoreInitialized={initialized}
					onUpdateComments={onUpdateComments}
				>
					<IssueIdeaContextHandler context={contextRest} />
					<IssuesLoader />
					{fg('jpd_extracted_issue_type_store') && <IssueTypeChangeHandler projectId={projectId} />}
					<PolarisIssueRealtimeHandler projectId={projectId} onContainerReady={onContainerReady} />
					{children}
				</IssuesSweetStateContainer>
			)}
		</PolarisIdeasCommonContext.Consumer>
	);
};

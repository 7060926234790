import { getProjectConfigInsights } from '../../services/polaris-api/get-insights/index.tsx';
import type {
	FetchProjectConfigInsightsRequest,
	ProviderProps,
	RemoteProjectConfigInsightsFetcher,
} from '../../types.tsx';

export const fetchProjectConfigInsights =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteProjectConfigInsightsFetcher =>
	({ createAnalyticsEvent, archivedMode, fireAnalyticsEvent }: FetchProjectConfigInsightsRequest) =>
		getProjectConfigInsights(
			apolloClient,
			cloudId,
			projectId,
			archivedMode,
			createAnalyticsEvent,
			fireAnalyticsEvent,
		).then((insights) => [
			...insights.map((insight) => ({
				description: null,
				...insight,
				snippets: [
					...insight.snippets.map((snippet) => ({
						data: null,
						oauthClientId: '',
						url: null,
						...snippet,
					})),
				],
			})),
		]);

import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const loadGlobalFields =
	({ skipIfLoaded }: { skipIfLoaded: boolean } = { skipIfLoaded: false }): Action<State, Props> =>
	async ({ getState, setState }, { globalFieldRemote }) => {
		if (skipIfLoaded && !getState().meta.loading) {
			return;
		}

		try {
			const globalFields = await wrapWithExperience(
				experience.globalFieldsAdministration.loadGlobalFields,
				() => globalFieldRemote.fetchGlobalFields(),
			);

			setState({
				meta: {
					loading: false,
					error: undefined,
				},
				globalFields,
			});
		} catch (err) {
			setState({
				meta: {
					loading: false,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: err as Error,
				},
			});

			throw err;
		}
	};

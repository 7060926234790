/* eslint-disable @atlaskit/design-system/no-deprecated-apis */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import merge from 'lodash/merge';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { ButtonItem, Section, type CSSFn } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAtlasFieldsEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIsProjectOnboarded } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import {
	usePolarisRouter,
	useCurrentPolarisRoute,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import type { PolarisRoute } from '@atlassian/jira-polaris-common/src/controllers/route/types.tsx';
import {
	useAllIdeasViewId,
	useViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useHasNoProjectPermissions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	ConnectAppsContainer,
	useConnectApps,
} from '@atlassian/jira-project-settings-apps-connect-apps/src/baskets/connect-apps/index.tsx';
import messages from './messages.tsx';
import { isSupportedConnectPlugin } from './utils.tsx';

const colorOnlyCssFn: CSSFn = (currentStyles) => {
	const newStyle = {
		color: token('color.text.subtle', colors.N500),
	};
	return merge(currentStyles, newStyle);
};

type SettingsSidebarProps = {
	prevRoute: PolarisRoute;
};

const SettingsSidebarInternal = ({ prevRoute }: SettingsSidebarProps) => {
	const { resource } = useCurrentPolarisRoute();
	const { routeTo, closeIssueView } = usePolarisRouter();
	const { formatMessage } = useIntl();
	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);
	const [atlasFieldsEnabled] = useAtlasFieldsEnabled();

	const [{ data, loading }, { fetch }] = useConnectApps();
	const projectKey = useProjectKeyUnsafe();

	const isFeaturesExpanded = useMemo(
		() => resource === 'create-ideas' || resource === 'atlas-integration',
		[resource],
	);
	const [isFeaturesSectionExpanded, setFeaturesSectionExpanded] = useState(isFeaturesExpanded);

	useEffect(() => {
		if (isFeaturesExpanded) {
			setFeaturesSectionExpanded(true);
		}
	}, [isFeaturesExpanded]);

	useEffect(() => {
		fetch(projectKey);
	}, [fetch, projectKey]);

	const onToggleFeaturesSection = useCallback(
		() => setFeaturesSectionExpanded(!isFeaturesSectionExpanded),
		[isFeaturesSectionExpanded],
	);

	const onBackToViews = useCallback(() => {
		if (allIdeasViewSlug) {
			routeTo({
				section: ViewSectionTypeViews,
				resource: allIdeasViewSlug,
			});
		} else {
			routeTo(prevRoute);
		}
	}, [allIdeasViewSlug, prevRoute, routeTo]);

	const onDetails = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'details',
		});
	}, [routeTo]);

	const onAccess = useCallback(() => {
		closeIssueView();

		routeTo({
			section: 'settings',
			resource: 'access',
		});
	}, [closeIssueView, routeTo]);

	const onTypes = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'types',
		});
	}, [routeTo]);

	const onFields = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'fields',
		});
	}, [routeTo]);

	const onNotifications = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'notifications',
		});
	}, [routeTo]);

	const onIdeaSubmission = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'create-ideas',
		});
	}, [routeTo]);

	const onAdvanced = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'atlas-integration',
		});
	}, [routeTo]);

	const onAutomation = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'automation',
		});
	}, [routeTo]);

	const onWorkflow = useCallback(() => {
		routeTo(
			{
				section: 'settings',
				resource: 'workflow',
			},
			{
				saveStorageRouteSlug: false,
			},
		);
	}, [routeTo]);

	const onConnectApp = useCallback(
		(appId: string) => {
			routeTo({
				section: 'settings',
				resource: `app-${appId}`,
			});
		},
		[routeTo],
	);

	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [isProjectOnboarded] = useIsProjectOnboarded();

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const supportedConnectApps = data?.filter(({ id }) => isSupportedConnectPlugin(id as string));

	return (
		<Section>
			<Section>
				{!hasNoProjectPermissions && (
					<ButtonItem
						id="polaris.ideas.src.ui.sidebar.settings.back-to-views"
						cssFn={colorOnlyCssFn}
						onClick={onBackToViews}
						iconBefore={
							<ArrowLeftCircleIcon label={formatMessage(messages.backToViews)} size="medium" />
						}
					>
						{formatMessage(messages.backToViews)}
					</ButtonItem>
				)}
			</Section>
			<Section hasSeparator>
				<ButtonItem
					id="polaris.ideas.src.ui.sidebar.settings.details"
					cssFn={colorOnlyCssFn}
					onClick={onDetails}
					isSelected={resource === 'details' || resource === undefined}
				>
					{formatMessage(messages.details)}
				</ButtonItem>
				<ButtonItem
					id="polaris.ideas.src.ui.sidebar.settings.access"
					cssFn={colorOnlyCssFn}
					onClick={onAccess}
					isSelected={resource === 'access'}
				>
					{formatMessage(messages.access)}
				</ButtonItem>
				{!hasNoProjectPermissions && fg('jpd_issues_relationships') && (
					<ButtonItem
						id="polaris.ideas.src.ui.sidebar.settings.types"
						cssFn={colorOnlyCssFn}
						onClick={onTypes}
						isSelected={resource === 'types'}
					>
						{formatMessage(messages.types)}
					</ButtonItem>
				)}
				{!hasNoProjectPermissions && (
					<ButtonItem
						id="polaris.ideas.src.ui.sidebar.settings.fields"
						cssFn={colorOnlyCssFn}
						onClick={onFields}
						isSelected={resource === 'fields'}
					>
						{formatMessage(messages.fields)}
					</ButtonItem>
				)}
				<ButtonItem
					id="polaris.ideas.src.ui.sidebar.settings.notifications"
					cssFn={colorOnlyCssFn}
					onClick={onNotifications}
					isSelected={resource === 'notifications'}
				>
					{formatMessage(messages.notifications)}
				</ButtonItem>
				{!hasNoProjectPermissions && isProjectOnboarded && (
					<FeaturesSectionButton>
						<ButtonItem
							id="polaris.ideas.src.ui.sidebar.settings.features"
							cssFn={colorOnlyCssFn}
							onClick={onToggleFeaturesSection}
							iconBefore={
								isFeaturesSectionExpanded ? (
									<ChevronDownIcon label="" />
								) : (
									<ChevronRightIcon label="" />
								)
							}
						>
							{formatMessage(messages.features)}
						</ButtonItem>
					</FeaturesSectionButton>
				)}
				{isFeaturesSectionExpanded && !hasNoProjectPermissions && isProjectOnboarded && (
					<FeatureSectionContainer>
						<ButtonItem
							id="polaris.ideas.src.ui.sidebar.settings.create-ideas"
							cssFn={colorOnlyCssFn}
							onClick={onIdeaSubmission}
							isSelected={resource === 'create-ideas'}
						>
							{formatMessage(messages.ideaCreation)}
						</ButtonItem>
						{atlasFieldsEnabled ? (
							<ButtonItem
								id="polaris.ideas.src.ui.sidebar.settings.atlas-integration"
								cssFn={colorOnlyCssFn}
								onClick={onAdvanced}
								isSelected={resource === 'atlas-integration'}
							>
								{formatMessage(messages.atlasIntegration)}
							</ButtonItem>
						) : null}
					</FeatureSectionContainer>
				)}
				{!hasNoProjectPermissions && isProjectOnboarded && (
					<ButtonItem
						id="polaris.ideas.src.ui.sidebar.settings.workflow"
						cssFn={colorOnlyCssFn}
						onClick={onWorkflow}
						isSelected={resource === 'workflow'}
					>
						{formatMessage(messages.workflow)}
					</ButtonItem>
				)}
				<ButtonItem
					id="polaris.ideas.src.ui.sidebar.settings.automation"
					cssFn={colorOnlyCssFn}
					onClick={onAutomation}
					isSelected={resource === 'automation'}
				>
					{formatMessage(messages.automation)}
				</ButtonItem>
			</Section>
			{supportedConnectApps && supportedConnectApps.length > 0 && (
				<Section hasSeparator>
					{supportedConnectApps.map(({ id, title }) => (
						<ButtonItem
							cssFn={colorOnlyCssFn}
							key={id}
							onClick={() => onConnectApp(id)}
							isSelected={resource === `app-${id}`}
						>
							{title}
						</ButtonItem>
					))}
					{loading && <Skeleton />}
				</Section>
			)}
		</Section>
	);
};

export const SettingsSidebar = (props: SettingsSidebarProps) => {
	const projectKey = useProjectKeyUnsafe();

	return (
		<ConnectAppsContainer onError={undefined} scope={projectKey}>
			<SettingsSidebarInternal {...props} />
		</ConnectAppsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeatureSectionContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '36px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeaturesSectionButton = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		paddingLeft: token('space.100', '8px'),
	},
});

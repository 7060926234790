import React, { forwardRef } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import type { TooltipProps } from './types.tsx';

const TooltipContainer = forwardRef<HTMLElement, TooltipPrimitiveProps>((props, ref) => {
	const { children, ...rest } = props;
	return (
		// @ts-expect-error - TS2783 - 'ref' is specified more than once, so this usage will be overwritten.
		<TooltipPrimitive ref={ref} {...rest}>
			<Flex xcss={innerContainerStyles}>{children}</Flex>
		</TooltipPrimitive>
	);
});

export const TooltipComponent = ({ children, header, content, ...rest }: TooltipProps) => (
	<Tooltip
		component={TooltipContainer}
		position="left"
		content={
			<Flex direction="column">
				<Flex>{header}</Flex>
				<Flex>{content}</Flex>
			</Flex>
		}
		{...rest}
	>
		{children}
	</Tooltip>
);

const innerContainerStyles = xcss({
	width: '192px',
	boxSizing: 'content-box',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: '3px',
	background: token('elevation.surface.overlay'),
	paddingBlock: 'space.100',
	paddingInline: 'space.150',
});

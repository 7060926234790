import { calculateCyclicDependencies } from '@atlassian/jira-polaris-domain-field/src/formula/index.tsx';
import { formulaUsesFields } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/main.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { JiraField } from '../../../../../services/types.tsx';

const analyzeFormulas = (formulas: Record<string, DynamicFieldFormula>) => {
	const formulaAnalysis: Record<string, string[]> = {};
	Object.entries(formulas).forEach(([key, formula]) => {
		formulaAnalysis[key] = formulaUsesFields(formula);
	});
	return calculateCyclicDependencies(formulaAnalysis);
};

const getFormulas = (jiraFields: JiraField[]) => {
	const formulas: Record<string, DynamicFieldFormula> = {};
	jiraFields.forEach((jiraField) => {
		const formula = jiraField.configuration?.polarisFormula;
		if (formula) {
			formulas[jiraField.key] = formula;
		}
	});
	return formulas;
};

export const getFormulaData = (jiraFields: JiraField[]) => {
	const formulas = getFormulas(jiraFields);
	return {
		formulas,
		cyclicDependencies: analyzeFormulas(formulas),
	};
};

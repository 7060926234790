import { useMemo, useCallback } from 'react';
import uniq from 'lodash/uniq';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import {
	ISSUE_COMMENTED_EVENT,
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import { buildChannelString } from '@atlassian/jira-realtime/src/common/utils/pubsub.utils.tsx';
import { useCloudId } from '../../../common/utils/tenant-context/index.tsx';
import { useIssueActions } from '../main.tsx';
import {
	useExternalIssueIdsByJiraIssueId,
	useLocalIssueIdsByJiraIssueId,
} from '../selectors/issue-ids-hooks.tsx';
import { useSelectedIssueId } from '../selectors/properties/hooks.tsx';
import { useExternalIssueProjects } from '../selectors/properties/linked-issues/hooks.tsx';
import { useOpenUpdateCounter } from '../selectors/real-time-hooks.tsx';
import { useDebouncedRefreshIssues } from './refresh-issues/index.tsx';

export const useJiraRealtimeChannels = (projectId?: string | number): string[] => {
	const cloudId = useCloudId();
	const linkedProjects = useExternalIssueProjects();

	const mainProject = useMemo(
		() => (projectId !== undefined ? [String(projectId)] : []),
		[projectId],
	);

	return useMemo(() => {
		const projects = uniq([...mainProject, ...linkedProjects]);
		return projects.map((pid) => buildChannelString(cloudId, pid));
	}, [cloudId, linkedProjects, mainProject]);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const BUNDLED_ISSUE_UPDATE_EVENTS = [
	ISSUE_UPDATED_EVENT,
	ISSUE_CREATED_EVENT,
	ISSUE_COMMENTED_EVENT,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
] as any[];

export const useJiraRealtimeEventHandler = (projectId?: string | number) => {
	const refreshIssues = useDebouncedRefreshIssues();
	const { deleteIssues, deleteExternalIssues, decrementOpenUpdateCounter, signalCommentsUpdates } =
		useIssueActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const idMap = useLocalIssueIdsByJiraIssueId();
	const externalIdMap = useExternalIssueIdsByJiraIssueId();
	const openUpdateCounter = useOpenUpdateCounter();
	const selectedIssueId = useSelectedIssueId();

	const validateEvent = useCallback(
		({ payload }: RealtimeEvent) => {
			if (!('issueId' in payload)) {
				return false;
			}

			const { issueId, projectId: eventProjectId } = payload;
			const localIssueId = idMap[issueId];
			const externalIssueId = externalIdMap[issueId];

			if (
				String(eventProjectId) === String(projectId) ||
				externalIssueId !== undefined ||
				localIssueId !== undefined
			) {
				return true;
			}

			return false;
		},
		[externalIdMap, idMap, projectId],
	);

	const collectIssuesForDeletion = useCallback(
		(event: RealtimeEvent | RealtimeEvent[]) => {
			const localEvents = Array.isArray(event) ? event : [event];
			return localEvents.reduce(
				(acc, e) => {
					if (e.type !== ISSUE_DELETED_EVENT || !('issueId' in e.payload)) {
						return acc;
					}

					const { issueId } = e.payload;
					const localIssueId = idMap[issueId];
					const externalIssueId = externalIdMap[issueId];
					if (externalIssueId !== undefined) {
						acc.externalIssues.push(externalIssueId);
					}
					if (localIssueId !== undefined) {
						acc.localIssues.push(localIssueId);
					}

					return acc;
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{ localIssues: [] as string[], externalIssues: [] as string[] },
			);
		},
		[externalIdMap, idMap],
	);

	const collectIssuesForUpdate = useCallback(
		(event: RealtimeEvent | RealtimeEvent[]) => {
			const localEvents = Array.isArray(event) ? event : [event];
			return localEvents.reduce((acc, e) => {
				if (
					!BUNDLED_ISSUE_UPDATE_EVENTS.includes(e.type) ||
					!('issueId' in e.payload) ||
					!validateEvent(e)
				) {
					return acc;
				}

				const { issueId } = e.payload;
				const localIssueId = idMap[issueId];
				if (openUpdateCounter[localIssueId] > 0) {
					decrementOpenUpdateCounter(localIssueId);
					return acc;
				}

				acc.push(String(issueId));
				return acc;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			}, [] as string[]);
		},
		[decrementOpenUpdateCounter, idMap, openUpdateCounter, validateEvent],
	);

	return useCallback(
		(event: RealtimeEvent | RealtimeEvent[]) => {
			const issuesForDeletion = collectIssuesForDeletion(event);
			if (issuesForDeletion.localIssues.length) {
				deleteIssues(issuesForDeletion.localIssues, false);
			}
			if (issuesForDeletion.externalIssues.length) {
				deleteExternalIssues(issuesForDeletion.externalIssues);
			}

			const issuesForUpdate = collectIssuesForUpdate(event);
			if (issuesForUpdate.length) {
				fireOperationalAnalytics(createAnalyticsEvent({}), 'jpdRefreshIssues triggered', {
					issuesCount: issuesForUpdate.length,
				});
				refreshIssues({
					jiraIssueIds: issuesForUpdate,
				});
			}

			// signal comments update if the selected idea is part of the event
			if (issuesForUpdate.includes(String(selectedIssueId))) {
				signalCommentsUpdates();
			}
		},
		[
			collectIssuesForDeletion,
			collectIssuesForUpdate,
			selectedIssueId,
			deleteIssues,
			deleteExternalIssues,
			createAnalyticsEvent,
			refreshIssues,
			signalCommentsUpdates,
		],
	);
};

import React, { useState, useEffect } from 'react';
import Popup from '@atlaskit/popup';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { ViewCollabProvider } from '../controllers/collab/index.tsx';
import { useActions } from '../controllers/last-seen/index.tsx';
import { OfflinePresenceSynchronizer } from './presence/index.tsx';
import { LastSeenTimestampUpdater } from './update/index.tsx';
import { useVisitorsWithUserData } from './utils.tsx';
import { VisitorsButton } from './visitors-button/index.tsx';
import { VisitorsList } from './visitors-list/index.tsx';

type Props = {
	viewUUID: ViewUUID;
	testId?: string;
};

type UnsafeProps = {
	viewUUID: ViewUUID | undefined;
	testId?: string;
};

const VisitorsControl = ({ viewUUID, testId }: Props) => {
	const visitors = useVisitorsWithUserData(viewUUID);
	const [isOpen, setOpen] = useState(false);
	const { cleanupViewLastSeenState } = useActions();

	useEffect(() => {
		return () => {
			if (viewUUID) {
				cleanupViewLastSeenState(viewUUID);
			}
		};
	}, [cleanupViewLastSeenState, viewUUID]);

	if (visitors.length === 0) {
		return <></>;
	}

	return (
		<Popup
			shouldUseCaptureOnOutsideClick
			placement="bottom-start"
			isOpen={isOpen}
			onClose={() => setOpen(false)}
			content={() => <VisitorsList viewUUID={viewUUID} testId={testId} />}
			trigger={(triggerProps) => (
				<VisitorsButton
					viewUUID={viewUUID}
					triggerProps={triggerProps}
					testId={testId}
					onClick={() => setOpen(true)}
				/>
			)}
		/>
	);
};

export const Visitors = ({ viewUUID, testId }: UnsafeProps) => {
	if (!viewUUID) {
		return null;
	}

	return (
		<>
			<ViewCollabProvider viewUUID={viewUUID} />
			<OfflinePresenceSynchronizer viewUUID={viewUUID} />
			<LastSeenTimestampUpdater viewUUID={viewUUID} />
			<VisitorsControl viewUUID={viewUUID} testId={testId} />
		</>
	);
};

import { createSelector } from 'reselect';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Props, State } from '../types.tsx';
import { filterByScope, filterBySearch } from './filters/index.tsx';
import { orderFieldsBy } from './sorting/index.tsx';

export const getFilters = (state: State) => state.filters;
export const getSorting = (state: State) => state.sort;

export const getFilteredFieldsKeys = (fields: Field[]) =>
	createSelector(
		getFilters,
		getSorting,
		(_state: State, props?: Props) => props?.formatMessage,
		(filters, sorting, formatMessage) => {
			const filteredFields = Object.values(fields).filter(
				(field) => filterByScope(field, filters.scope) && filterBySearch(field, filters.search),
			);

			const sortedFields = orderFieldsBy(
				filteredFields,
				sorting.column,
				sorting.direction,
				formatMessage,
			);

			return sortedFields.map((field) => field.key);
		},
	);

export const getFieldScopeFilter = createSelector(getFilters, (filters) => filters.scope);
export const getSearchQueryFilter = createSelector(getFilters, (filters) => filters.search);

export const getAreFiltersActive = createSelector(
	getFieldScopeFilter,
	getSearchQueryFilter,
	(originFilter, searchQuery) => !!originFilter || !!searchQuery,
);

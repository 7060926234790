import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import Button from '@atlaskit/button';
import { EmojiPicker, type EmojiId } from '@atlaskit/emoji';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import type { CSSFn, ItemState } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { LinkItem } from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import textFieldMessages from '@atlassian/jira-polaris-common/src/common/ui/field/text-field/messages.tsx';
import { MenuItemSkeleton } from '@atlassian/jira-polaris-common/src/common/ui/skeleton/index.tsx';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events/index.tsx';
import { getViewLink } from '@atlassian/jira-polaris-common/src/common/utils/view-link/index.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIsIssueOpenInFullscreen,
	usePolarisRouter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useCanManageView,
	useRemoveViewIfNoAccess,
	useCurrentViewSlug,
	useViewEmoji,
	useViewKind,
	useViewSlug,
	useViewTitle,
	useViewUUID,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { getCollectionViewPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useLoadViewAccessAction } from '@atlassian/jira-polaris-component-view-access/src/controllers/hooks/index.tsx';
import TitleIconResolver from '@atlassian/jira-polaris-component-view-icon/src/ui/index.tsx';
import {
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useEmojiProviderPromise } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { DeleteModal } from '../common/delete-modal/index.tsx';
import { EditableTitle } from '../common/editable-title/index.tsx';
import { Actions } from './actions/index.tsx';
import messages from './messages.tsx';

type Props = {
	isSorting: boolean;
	isReadOnly: boolean;
	isDraggingPreview?: boolean;
	cssFn?: CSSFn<ItemState>;
	viewId: LocalViewId;
	onSelectView: () => void;
	onDelete?: () => void;
	onSaveAsList?: () => void;
	onSaveAsBoard?: () => void;
	onSaveAsMatrix?: () => void;
	onSaveAsTimeline?: () => void;
	onRename: (arg1: string) => void;
	onAddEmoji?: (arg1: string) => void;
	onRemoveEmoji?: () => void;
};

const ViewItemInternal = (props: Props) => {
	const {
		cssFn,
		viewId,
		onSelectView,
		onSaveAsList,
		onSaveAsBoard,
		onSaveAsMatrix,
		onSaveAsTimeline,
		onDelete,
		onRename,
		onAddEmoji,
		onRemoveEmoji,
		isSorting,
		isReadOnly,
		isDraggingPreview,
	} = props;

	const [isRenaming, setIsRenaming] = useState(false);
	const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [isPickerOpen, setPickerOpen] = useState(false);

	const emojiProviderPromise = useEmojiProviderPromise();

	const linkItem = useRef<HTMLDivElement | null>(null);

	const projectKey = useProjectKeyUnsafe();
	const viewKind = useViewKind(viewId);
	const viewSlug = useViewSlug(viewId);
	const currentViewSlug = useCurrentViewSlug();
	const viewTitle = useViewTitle(viewId);
	const emojiId = useViewEmoji(viewId);
	const viewUUID = useViewUUID(viewId);

	const isCollectionView = useIsCollectionView();
	const environmentContainer = useEnvironmentContainer();
	const collectionUUID = isCollectionView ? environmentContainer?.id : undefined;
	const canManageView = useCanManageView(viewId, false);
	useRemoveViewIfNoAccess(viewId);

	const loadViewAccess = useLoadViewAccessAction();
	const debouncedLoadViewAccess = useMemo(
		() =>
			loadViewAccess && viewUUID && !isCollectionView
				? debounce(() => loadViewAccess(viewUUID), 100)
				: undefined,
		[loadViewAccess, viewUUID, isCollectionView],
	);

	const { closeIssueView } = usePolarisRouter();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { formatMessage } = useIntl();

	const isMatrixView = viewKind === VIEW_KIND_MATRIX;
	const isTimelineView = viewKind === VIEW_KIND_TIMELINE;
	const isSelected = viewSlug === currentViewSlug;

	useEffect(() => {
		if (isSorting) {
			setOpen(false);
			setIsRenaming(false);
		}
	}, [isSorting]);

	const onTriggerRename = useCallback(() => {
		setOpen(false);
		setIsRenaming(true);
		experience.navBar.changeViewName.start();
		fireCompoundAnalyticsEvent.NavSidebarViewKebabRenameViewClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent]);

	const onCancelRename = useCallback(() => {
		setIsRenaming(false);
		experience.navBar.changeViewName.abort();
	}, []);

	const onConfirmRename = useCallback(
		(value: string) => {
			setIsRenaming(false);
			onRename(value || formatMessage(textFieldMessages.defaultViewTitle));
		},
		[formatMessage, onRename],
	);

	const onTriggerDelete = useCallback(() => {
		setOpen(false);
		setIsConfirmDeleteOpen(true);
		fireCompoundAnalyticsEvent.NavSidebarViewKebabDeleteViewClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent]);

	const onConfirmDelete = useCallback(() => {
		setIsConfirmDeleteOpen(false);
		onDelete?.();
	}, [onDelete]);

	const onCancelDelete = useCallback(() => {
		setIsConfirmDeleteOpen(false);
	}, []);

	const onTriggerSaveAsList = useCallback(() => {
		setOpen(false);
		onSaveAsList?.();
		fireCompoundAnalyticsEvent.NavSidebarViewKebabCloneViewAsListClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent, onSaveAsList]);

	const onTriggerSaveAsBoard = useCallback(() => {
		setOpen(false);
		onSaveAsBoard?.();
		fireCompoundAnalyticsEvent.NavSidebarViewKebabCloneViewAsBoardClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent, onSaveAsBoard]);

	const onTriggerSaveAsMatrix = useCallback(() => {
		setOpen(false);
		onSaveAsMatrix?.();
		fireCompoundAnalyticsEvent.NavSidebarViewKebabCloneViewAsMatrixClicked(
			createAnalyticsEvent({}),
		);
	}, [createAnalyticsEvent, onSaveAsMatrix]);

	const onTriggerSaveAsTimeline = useCallback(() => {
		setOpen(false);
		onSaveAsTimeline?.();
		fireCompoundAnalyticsEvent.NavSidebarViewKebabCloneViewAsTimelineClicked(
			createAnalyticsEvent({}),
		);
	}, [createAnalyticsEvent, onSaveAsTimeline]);

	const onSelectEmoji = useCallback(
		(emoji: EmojiId) => {
			if (onAddEmoji) {
				setPickerOpen(false);
				// @ts-expect-error - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				onAddEmoji(emoji.id);
			}
		},
		[onAddEmoji],
	);

	const onRemoveEmojiClick = useCallback(() => {
		if (onRemoveEmoji) {
			onRemoveEmoji();
			fireCompoundAnalyticsEvent.NavSidebarViewEmojiRemove(createAnalyticsEvent({}));
		}
		setOpen(false);
	}, [createAnalyticsEvent, onRemoveEmoji]);

	const href = useMemo<string | undefined>(() => {
		if (isCollectionView && collectionUUID && viewUUID) {
			return getCollectionViewPageUrl(collectionUUID, viewUUID);
		}
		// viewSlug can by definition be undefined when a newly created view hasn't been saved yet on the backend
		if (!isCollectionView && viewSlug !== undefined) {
			return getViewLink(projectKey, viewSlug);
		}
	}, [isCollectionView, collectionUUID, viewUUID, viewSlug, projectKey]);

	const onIconClick = useCallback(
		(e: React.MouseEvent) => {
			if (!isReadOnly && canManageView) {
				e.preventDefault();
				e.stopPropagation();
				setPickerOpen(!isPickerOpen);

				fireCompoundAnalyticsEvent.NavSidebarViewEmojiAdd(createAnalyticsEvent({}));
			}
		},
		[canManageView, createAnalyticsEvent, isPickerOpen, isReadOnly],
	);

	const onSectionClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
		(e) => {
			e.preventDefault();
			const ariaLabel = e.target.getAttribute('aria-label');
			// Prevent from selecting a view if a menu button was clicked
			if (ariaLabel !== 'sidebar.ideas.more') {
				if (shouldOpenInNewTab(e) && href) {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.open(href, '_blank');
				} else {
					if (isSelected) {
						isIssueOpenInFullscreen && closeIssueView();
						return;
					}
					fireCompoundAnalyticsEvent.NavSidebarViewClicked(createAnalyticsEvent({}), viewKind);
					onSelectView();
				}
			}
		},
		[
			href,
			isSelected,
			createAnalyticsEvent,
			viewKind,
			onSelectView,
			isIssueOpenInFullscreen,
			closeIssueView,
		],
	);

	return (
		<>
			<Box
				ref={linkItem}
				data-component-selector="link-item-wrapper-5P4d"
				xcss={[isDraggingPreview && draggingContanerStyles]}
				onMouseOver={debouncedLoadViewAccess}
				onMouseLeave={debouncedLoadViewAccess?.cancel}
			>
				<LinkItem
					testId="polaris-ideas.ui.sidebar.sections.view-item.link-item"
					// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
					cssFn={cssFn}
					onClick={onSectionClick}
					isSelected={isSelected}
					href={isRenaming ? undefined : href}
					iconBefore={
						<>
							<DragHandle
								isVisibleOnHover={!isReadOnly && !isSorting}
								// @ts-expect-error - TS2322 - Type '{ children: Element; isVisibleOnHover: boolean; label: string; type: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
								label="view-dragHandle"
								type="drag-handle"
							>
								<DragHandlerIcon label="" />
							</DragHandle>
							<Popup
								placement="auto-start"
								isOpen={isPickerOpen}
								shouldUseCaptureOnOutsideClick
								onClose={() => setPickerOpen(false)}
								content={() => (
									<EmojiPicker
										emojiProvider={emojiProviderPromise}
										onSelection={(emoji) => onSelectEmoji(emoji)}
									/>
								)}
								trigger={(triggerProps) => (
									<Pressable
										{...triggerProps}
										onClick={onIconClick}
										padding="space.0"
										xcss={emojiPickerPressableStyles}
										// no way to set th default color of button with this value using xcss
										// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
										style={{ color: 'inherit' }}
										aria-label={formatMessage(messages.viewIconButonAriaLabel)}
									>
										<IconWrapper
											shouldHover={!isReadOnly && canManageView}
											data-component-selector="icon-wrapper-69Rx"
										>
											<TitleIconResolver
												isReadOnly={isReadOnly || !canManageView}
												emojiId={emojiId}
												viewKind={viewKind}
											/>
										</IconWrapper>
									</Pressable>
								)}
							/>
						</>
					}
					iconAfter={
						<Popup
							placement="auto-start"
							isOpen={isOpen}
							onClose={() => setOpen(false)}
							content={() => (
								<Actions
									viewId={viewId}
									onRename={onTriggerRename}
									onRemoveEmoji={onRemoveEmoji && Boolean(emojiId) ? onRemoveEmojiClick : undefined}
									onDelete={onDelete && onTriggerDelete}
									onSaveAsNewBoard={
										onSaveAsBoard && !isMatrixView && !isTimelineView
											? onTriggerSaveAsBoard
											: undefined
									}
									onSaveAsNewList={onSaveAsList && onTriggerSaveAsList}
									onSaveAsNewMatrix={
										isMatrixView && onSaveAsMatrix ? onTriggerSaveAsMatrix : undefined
									}
									onSaveAsNewTimeline={
										isTimelineView && onSaveAsTimeline ? onTriggerSaveAsTimeline : undefined
									}
								/>
							)}
							trigger={(triggerProps) =>
								!isReadOnly &&
								!isRenaming &&
								!isSorting && (
									<MoreButton isVisible={isOpen}>
										<Button
											{...triggerProps}
											isSelected={isOpen}
											onClick={() => {
												setOpen(!isOpen);
											}}
											appearance="subtle"
											iconAfter={<MoreVerticalIcon size="small" label="sidebar.ideas.more" />}
										/>
									</MoreButton>
								)
							}
						/>
					}
					// @ts-expect-error - TS2322 - Type '{ children: Element; testId: string; cssFn: CSSFn<ItemState> | undefined; onClick: (e: any) => void; isSelected: boolean; iconBefore: Element; iconAfter: Element; label: string; }' is not assignable to type 'IntrinsicAttributes & LinkItemProps & RefAttributes<HTMLElement>'.
					label="sidebar.ideas.switch-view"
				>
					<ItemWrapper>
						{viewTitle !== undefined && (
							<EditableTitle
								isRenaming={isRenaming}
								title={viewTitle}
								disableTooltip
								onChanged={onConfirmRename}
								onCancel={onCancelRename}
							/>
						)}
					</ItemWrapper>
				</LinkItem>
			</Box>
			{viewTitle !== undefined && (
				<DeleteModal
					type="view"
					isOpen={isConfirmDeleteOpen}
					title={viewTitle}
					onCancel={onCancelDelete}
					onConfirm={onConfirmDelete}
				/>
			)}
		</>
	);
};

export const ViewItem = memo<Props>(ViewItemInternal);

export const ViewItemSkeleton = () => (
	<Box data-component-selector="link-item-wrapper-5P4d">
		<MenuItemSkeleton />
	</Box>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DragHandle = styled.span<{ isVisibleOnHover?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisibleOnHover }) => (isVisibleOnHover ? 'hidden' : 'hidden!important'),
	cursor: 'grab',
	width: '18px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="link-item-wrapper-5P4d"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: ({ isVisibleOnHover }) => (isVisibleOnHover ? 'visible' : 'hidden'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		lineHeight: 'unset',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MoreButton = styled.div<{ isVisible: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisible }) => (isVisible ? 'visible' : 'hidden'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		height: '23px',
		lineHeight: '23px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20),
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="link-item-wrapper-5P4d"]:hover &': {
		visibility: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	lineHeight: '18px',
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div<{ shouldHover: boolean }>({
	display: 'flex',
	borderRadius: '3px',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ shouldHover }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			shouldHover ? token('color.background.neutral.subtle.hovered', colors.N20) : '',
	},
});

const draggingContanerStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const emojiPickerPressableStyles = xcss({ background: 'transparent' });

import { COUNT_ROLLUP, POLARIS_OAUTH_CLIENT_ID, PROPERTY_ROLLUP } from '../../../constants.tsx';
import type { RollupField } from '../../../types.tsx';

export const getAppName = (oauthClientId: string, appName: string): string =>
	oauthClientId === POLARIS_OAUTH_CLIENT_ID ? '' : appName;

export const getAppNameForRollupField = (field: RollupField): string | undefined =>
	field.kind === COUNT_ROLLUP || field.kind === PROPERTY_ROLLUP
		? getAppName(field.value.oauthClientId, field.value.appName)
		: undefined;

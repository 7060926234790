import type { ReadAttachmentsToken } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import { fetchViewsServiceMediaContextBase } from '@atlassian/jira-polaris-remote-media/src/services/views-service/media-context/index.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { getIssueAttachmentMedia } from '../../services/gira/attachments/index.tsx';
import {
	addMediaToIssueAttachments,
	deleteIssueAttachment,
} from '../../services/jira/attachments/index.tsx';
import type { IssueAttachmentsRemote, RemoteIssueAttachmentsResponse } from './types.tsx';

export const createNotImplementedIssueAttachmentsRemote = (): IssueAttachmentsRemote => ({
	createIssueAttachment: notImplementedError('createIssueAttachment'),
	deleteIssueAttachment: notImplementedError('deleteIssueAttachment'),
	fetchIssueAttachments: notImplementedError('fetchIssueAttachments'),
	addMediaToIssueAttachments: notImplementedError('addMediaToIssueAttachments'),
});

const EMPTY_MEDIA_CONTEXT = {
	clientId: '',
	endpointUrl: '',
	tokenLifespanInSeconds: 0,
	tokensWithFiles: [],
};

// POL-3861 https://pi-dev-sandbox.atlassian.net/browse/POL-6894 - combine media and issue attachment network calls and remove this
const toRemoteIssueAttachmentsResponse = (
	context: ReadAttachmentsToken,
): RemoteIssueAttachmentsResponse => {
	const tokensWithFiles = context.tokensWithFiles?.map(({ token, files }) => ({
		token,
		files: files.map(({ attachmentMediaApiId, issueId, attachmentId }) => ({
			// For sharing, we do not include the author id for the attachment
			authorAccountId: '',
			issueId: Number(issueId),
			attachmentId: Number(attachmentId),
			attachmentMediaApiId,
		})),
	}));

	return {
		...context,
		tokensWithFiles,
	};
};

export const createSharingIssueAttachmentsRemote = (viewId: string): IssueAttachmentsRemote => ({
	...createNotImplementedIssueAttachmentsRemote(),
	fetchIssueAttachments: async ({ issueIdOrKey }) => {
		const { readToken } = await fetchViewsServiceMediaContextBase(viewId, issueIdOrKey);
		if (!readToken) {
			return EMPTY_MEDIA_CONTEXT;
		}

		return toRemoteIssueAttachmentsResponse(readToken);
	},
});

export const createIssueAttachmentsRemote = (): IssueAttachmentsRemote => ({
	createIssueAttachment: ({ issueIdOrKey, fileId }) =>
		addMediaToIssueAttachments(issueIdOrKey, fileId).then((response) => ({
			attachmentId: response,
		})),
	deleteIssueAttachment: ({ attachmentId }) => deleteIssueAttachment(attachmentId),
	fetchIssueAttachments: ({ issueIdOrKey }) => getIssueAttachmentMedia(issueIdOrKey),
	addMediaToIssueAttachments,
});

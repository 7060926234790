import React from 'react';
import { Box, Text, Inline } from '@atlaskit/primitives';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/issue-type-avatar.tsx';
import {
	useIssueTypeAvatarId,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { ColumnHeaderComponentProps, ColumnHeaderConfiguration } from '../types.tsx';

const Component = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId: groupIdentity });
	const name = useIssueTypeName({ issueTypeId: groupIdentity });

	if (avatarId === undefined) {
		return null;
	}

	return (
		<Box padding="space.025">
			<Inline alignBlock="center" space="space.075">
				<IssueTypeAvatar avatarId={avatarId} size="xsmall" />
				<Text>{name}</Text>
			</Inline>
		</Box>
	);
};

export const issueTypeColumnHeaderConfig: ColumnHeaderConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
};

import {
	refreshSnippetMutation,
	refreshAllSnippetsMutation,
} from '../../services/polaris-api/refresh-snippet/index.tsx';
import type {
	ProviderProps,
	RemoteSnippetRefresher,
	RemoteSnippetsRefresher,
	RefreshSnippetRequest,
} from '../../types.tsx';

export const refreshSnippet =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteSnippetRefresher =>
	({ id }: RefreshSnippetRequest) =>
		refreshSnippetMutation(apolloClient, cloudId, projectId, id);

export const refreshSnippets =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteSnippetsRefresher =>
	() =>
		refreshAllSnippetsMutation(apolloClient, cloudId, projectId);

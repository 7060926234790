import { memo, useCallback, useEffect, useRef } from 'react';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { useScrollPerformance } from '../common/utils.tsx';

const analyticsEventsInterval = 1000 * 60 * 1; // 1 minute

type ObserverProps = {
	scrollingContainer: HTMLElement | null;
	jpdExperience: JPDExperience;
	metadata?: Record<string, string>;
};

export const Observer = memo<ObserverProps>(
	({ scrollingContainer, metadata, jpdExperience }: ObserverProps) => {
		const performanceData = useRef<{ fps: number[]; latency: number[] }>({
			fps: [],
			latency: [],
		});

		const onPerformanceMeasured = useCallback((fps: number, latency: number) => {
			performanceData.current.fps.push(fps);
			performanceData.current.latency.push(latency);
		}, []);

		// Start observing the scroll performance
		useScrollPerformance(scrollingContainer, onPerformanceMeasured);

		useEffect(() => {
			const interval = setInterval(() => {
				if (performanceData.current.fps.length > 0 && performanceData.current.latency.length > 0) {
					const { fps } = performanceData.current;
					const averageFps = fps.reduce((sum, value) => sum + value, 0) / fps.length;
					const fpsMin = Math.min(...fps);

					const { latency } = performanceData.current;
					const averageLatency = latency.reduce((sum, val) => sum + val, 0) / latency.length;
					const latencyMax = Math.max(...latency);

					jpdExperience.start(0);
					jpdExperience.success(
						{
							metadata: {
								fps: String(averageFps),
								fpsMin: String(fpsMin),
								latency: String(averageLatency),
								latencyMax: String(latencyMax),
								...metadata,
							},
						},
						averageLatency,
					);

					performanceData.current.fps = [];
					performanceData.current.latency = [];
				}
			}, analyticsEventsInterval);

			return () => {
				clearInterval(interval);
			};
		}, [jpdExperience, metadata]);

		return null;
	},
);

import React, { useState } from 'react';
import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/new';
import DeleteIcon from '@atlaskit/icon/core/delete';

import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { InlineEditAvatarId } from './inline-edit-avatar-id.tsx';
import { InlineEditDescription } from './inline-edit-description.tsx';
import { InlineEditName } from './inline-edit-name.tsx';
import { messages } from './messages.tsx';
import type { FormData } from './types.tsx';

type Props = {
	issueTypeId: IssueTypeId;
	initialData: FormData;
	onDataModified: (data: Partial<FormData>) => Promise<void>;
	onDelete: () => Promise<void>;
	isEditable: boolean;
	isDeletable: boolean;
};

export const IssueTypeContent = ({
	issueTypeId,
	initialData,
	onDataModified,
	onDelete,
	isDeletable,
	isEditable,
}: Props) => {
	const { formatMessage } = useIntl();

	const [isDeleting, setIsDeleting] = useState(false);

	return (
		<Box>
			<Box xcss={formBodyStyles}>
				<InlineEditName
					value={initialData.name}
					onChange={(value) => onDataModified({ name: value })}
					isEditable={isEditable}
				/>
				<InlineEditAvatarId
					id={`${issueTypeId}-avatar-id-field`}
					value={initialData.avatarId}
					onChange={(value) => onDataModified({ avatarId: value })}
					isEditable={isEditable}
				/>
				<InlineEditDescription
					value={initialData.description}
					onChange={(value) => onDataModified({ description: value })}
					isEditable={isEditable}
				/>
			</Box>

			<Box xcss={formFooterStyles}>
				<ButtonGroup>
					<Button
						onClick={() => {
							setIsDeleting(true);
							onDelete().catch(() => {
								setIsDeleting(false);
							});
						}}
						type="button"
						appearance="default"
						iconBefore={() => <DeleteIcon label="" />}
						isLoading={isDeleting}
						isDisabled={!isDeletable}
					>
						{formatMessage(messages.deleteType)}
					</Button>
				</ButtonGroup>
			</Box>
		</Box>
	);
};

const formBodyStyles = xcss({
	paddingBlockStart: 'space.150',
	paddingBlockEnd: 'space.150',
});

const formFooterStyles = xcss({
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
	paddingBlock: 'space.150',
	display: 'flex',
	justifyContent: 'start',
});

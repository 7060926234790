import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { View, ViewsResponse } from '../types.tsx';

export const getGlobalFieldProjectViews = async (
	fieldKey: string,
	projectId: string,
): Promise<View[]> => {
	const response = await fetchJson<ViewsResponse>(
		`/gateway/api/polaris/rest/projects/${projectId}/fields/${fieldKey}/views`,
	);

	return response.data.views.map((view) => ({
		id: view.xid,
		name: view.name,
		kind: view.kind,
	}));
};

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	link: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.link',
		defaultMessage: 'Global fields guidelines',
		description: 'Link to the global fields guidelines',
	},
	dismissButton: {
		id: 'polaris-lib-onboarding-flows.cross-project-view.fields.dismiss-button',
		defaultMessage: 'Got it',
		description: 'Text for "Got it" button to dismiss the onboarding message',
	},
});

import { useCallback } from 'react';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { flatPalette } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { useFieldValueDecorations } from '../../../../../controllers/selectors/field-hooks.tsx';

type UseFilteredOptionsProps = {
	query: string;
	options: FieldOption[];
};

export const useFilteredOptions = (props: UseFilteredOptionsProps) => {
	const { query, options } = props;

	return options.filter((option) =>
		option.value.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
	);
};

export const numberFieldCanBeConvertedToRatingField = (fieldOptions: FieldOption[]) =>
	!fieldOptions.some((option) => option.weight < 1 || option.weight > 5);

export const AUTO_FORMATTING_COLORS_ORDER = [
	6, 4, 2, 8, 5, 3, 1, 7, 0, 33, 31, 29, 35, 32, 30, 28, 34, 27,
];

export const useGetNextAutoFormattingColor = (): (() => string | undefined) => {
	const fieldValueDecorations = useFieldValueDecorations();

	return useCallback(() => {
		const getIsColorUsed = (color: string | undefined) =>
			fieldValueDecorations.some((decoration) => decoration.backgroundColor === color);

		let localColorOrderIndex = 0;

		const getColor = () => {
			const colorIndex = AUTO_FORMATTING_COLORS_ORDER[localColorOrderIndex];
			localColorOrderIndex++;
			return flatPalette[colorIndex]?.mainColor;
		};

		let color = getColor();
		let isColorUsed = getIsColorUsed(color);

		while (color !== undefined && isColorUsed) {
			color = getColor();
			isColorUsed = getIsColorUsed(color);
		}

		return color;
	}, [fieldValueDecorations]);
};

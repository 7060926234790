import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch/src/index.tsx';

export type Request = {
	jiraKeys: string[];
	onFinish: (arg1: (Error | undefined)[]) => void;
};

const BATCH_SIZE = 5;

export const deleteIssues = ({ jiraKeys, onFinish }: Request) =>
	runInBatch(
		jiraKeys.map(
			(jiraKey) => () =>
				performDeleteRequest(`/rest/api/3/issue/${jiraKey}?deleteSubtasks=true`)
					.then(() => undefined)
					.catch((error) => error),
		),
		BATCH_SIZE,
	).then((results) => onFinish(results));

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	linkButtonCaption: {
		id: 'polaris-ideas.idea-view.controls.link.link-button-caption',
		defaultMessage: 'Link issue',
		description: 'Button label for the link issue button',
	},
	linkButtonCaptionIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.controls.link.link-button-caption-issue-term-refresh',
		defaultMessage: 'Link work item',
		description: 'Button label for the link issue button',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	seeMore: {
		id: 'polaris-common.connection.extra-items-summary.see-more-non-final',
		defaultMessage: 'See more',
		description: 'Label for an icon inside a button that opens a sidebar with all connected issues',
	},

	more: {
		id: 'polaris-common.connection.extra-items-summary.more-non-final',
		defaultMessage: '{restItemsAmount} more',
		description:
			'Button label for opening sidebar with connected issues, showing how many more items are available, e.g.: 16 more',
	},
});

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type {
	PolarisArjConfigurationResponse,
	PolarisArjHierarchyConfigurationResponse,
} from './types.tsx';

export const getPolarisArjConfiguration = (): Promise<PolarisArjConfigurationResponse> =>
	fetchJson('/rest/polaris/configuration/arj');

export const getPolarisArjHierarchyConfiguration =
	(): Promise<PolarisArjHierarchyConfigurationResponse> =>
		fetchJson('/rest/jpo/1.0/hierarchyConfiguration');

import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_UpdatePolarisPlay as UpdatePlay,
	jira_polaris_UpdatePolarisPlayVariables as UpdatePlayVariables,
} from './__generated_apollo__/jira_polaris_UpdatePolarisPlay';

const UPDATE_PLAY_MUTATION = gqlTagPolaris`
  mutation jira_polaris_UpdatePolarisPlay($input: UpdatePolarisPlayInput!) {
    updatePolarisPlay(input: $input) {
      success
      errors {
        message
      }
      node {
        id
      }
    }
  }
`;

export const updatePlayField = async (
	apolloClient: PolarisApolloClient,
	input: UpdatePlayVariables['input'],
): Promise<void> => {
	const result = await apolloClient.mutate<UpdatePlay, UpdatePlayVariables>({
		mutation: UPDATE_PLAY_MUTATION,
		variables: {
			input,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.update-play-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.updatePolarisPlay?.node) {
		throw new Error('project-config.update-play-error: no data or no data node');
	}
};

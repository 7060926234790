import React, { type SyntheticEvent, memo, useCallback, useMemo, useRef, useEffect } from 'react';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { fg } from '@atlassian/jira-feature-gating';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START,
	PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END,
	PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START,
	PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project.tsx';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIsGlobalCustomField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useLocalIssueIdsByCell,
	useUnfilteredLocalIssueIdsByCell,
	useLocalIssueIdsByGroupIdentity,
	useUnfilteredLocalIssueIdsByGroupIdentity,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { useOpenRightSidebarGroupByOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingGroupByOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewAnalyticsAttributes } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/analytics.tsx';
import {
	useCurrentViewGroupBy,
	useCurrentViewVerticalGroupBy,
	useCurrentViewConfigured,
	useIsExporting,
	useHasSharedViewConfigError,
	useCanManageCurrentView,
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewLayoutType,
	useCurrentViewId,
	useCurrentViewAccessLevel,
	useCurrentViewCollapsedSwimlanes,
	useCurrentViewHideEmptyGroups,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { canModifyOptionName } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/utils/fields.tsx';
import { IdeaCardContextProvider } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils.tsx';
import {
	useCanEditFields,
	useHasNoProjectPermissions,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import {
	fireCompoundAnalyticsEvent,
	useViewOpenedAnalytics,
} from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { Board as LibBoard } from '@atlassian/jira-polaris-lib-board/src/index.tsx';
import type { BoardApi } from '@atlassian/jira-polaris-lib-board/src/types/api.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import { BoardViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/board-view/index.tsx';
import { PublishedViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/publish-view/index.tsx';
import {
	useAnalyticsEvents,
	SCREEN,
	ContextualAnalyticsData,
	type Attributes,
	type AnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useCurrentViewSuccessExperience } from '../common/utils/experience.tsx';
import { useGroupOptions } from '../common/utils/group-options.tsx';
import { useExtendedVerticalGroupOptions } from '../common/utils/vertical-group-options.tsx';
import { Card } from './card/index.tsx';
import { ColumnFooter } from './column-footer/index.tsx';
import { ColumnHeader } from './column-header/index.tsx';
import { EmptyColumn } from './empty-column/index.tsx';
import { SwimlaneHeader } from './swimlane-header/index.tsx';
import {
	useBoardViewWithGroups,
	useBoardViewWithoutGroups,
} from './use-board-view-groups/index.tsx';
import { useColumnsHighlightColors } from './use-column-highlight-colors/index.tsx';
import { useOnAddColumn } from './use-on-add-column/index.tsx';
import {
	useIsCardDropDisabledForColumn,
	useIsMoveBetweenGroupsDisabled,
} from './utils/drop-disabled.tsx';
import { useCardDropHandler, useOnColumnDrop, useOnRowDrop } from './utils/drop-handler.tsx';
import { assertNonNullable } from './utils/types.tsx';
import { useBoardExport } from './utils/use-board-export.tsx';
import { useDraggedCard } from './utils/use-dragged-card.tsx';

const components: BoardProps['components'] = {
	EmptyColumn,
	ColumnFooter,
	ColumnHeader,
	Card,
	SwimlaneHeader,
};

type LibBoardWrapperProps = {
	isReadOnly: boolean;
	isFooterVisible: boolean;
};

type LibBoardWrapperSharedProps = LibBoardWrapperProps & {
	isExporting: boolean;
};

type LibBoardWrapperWithoutGroupsProps = LibBoardWrapperSharedProps & {
	groupByField: Field;
};

type LibBoardWrapperWithGroupsProps = LibBoardWrapperSharedProps & {
	groupByField: Field;
	verticalGroupByField: Field;
};

const LibBoardWrapperWithGroups = ({
	groupByField,
	verticalGroupByField,
	...rest
}: LibBoardWrapperWithGroupsProps) => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START);
	});

	const boardApi = useRef<BoardApi>();

	const groups = useBoardViewWithGroups(groupByField, verticalGroupByField);
	const idsByColumn = useLocalIssueIdsByGroupIdentity(groupByField.key);
	const idsByCell = useLocalIssueIdsByCell(groupByField.key, verticalGroupByField.key);
	const unfilteredIdsByCell = useUnfilteredLocalIssueIdsByCell(
		groupByField.key,
		verticalGroupByField.key,
	);
	const isMoveBetweenColumnsDisabled = useIsMoveBetweenGroupsDisabled(groupByField);
	const isMoveBetweenGroupsDisabled = useIsMoveBetweenGroupsDisabled(verticalGroupByField);
	const extendedOptions = useGroupOptions(groupByField);
	const extendedVerticalOptions = useExtendedVerticalGroupOptions(verticalGroupByField);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { initSwimlanes, toogleSwimlane, toggleAllSwimlanes } = useViewActions();
	const currentViewId = useCurrentViewId();
	const layoutType = useCurrentViewLayoutType();
	const isCompact = layoutType === ViewLayoutType.COMPACT;

	const columnsHighlightColors = useColumnsHighlightColors({
		fieldKey: groupByField.key,
		extendedOptions,
	});

	const { draggedCard, onCardDrag, onCardDrop } = useDraggedCard();
	const isCardDropDisabledForColumn = useIsCardDropDisabledForColumn({
		field: groupByField,
		extendedOptions,
		draggedCard,
	});

	const {
		onCardDropChange,
		onCardDropClear,
		onCardDropVerticalChange,
		onCardDropVerticalClear,
		onCardDropStart,
		rankAfter,
		rankBefore,
	} = useCardDropHandler(groupByField.key, verticalGroupByField.key);

	const onColumnDrop = useOnColumnDrop(groupByField);
	const onRowDrop = useOnRowDrop();

	useEffect(() => {
		boardApi.current?.setScrollPosition(0);
	}, [isCompact]);

	useEffect(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END);
		fireCompoundAnalyticsEvent.BoardView.opened(createAnalyticsEvent({}));
		boardApi.current?.setScrollPosition(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentViewId]); // run when the view changes

	useEffect(() => {
		const swimlanes = extendedVerticalOptions.map(
			({ groupIdentity }) => groupIdentity ?? 'undefined', // we use "undefined" as a valid key
		);
		initSwimlanes(swimlanes);
	}, [extendedVerticalOptions, initSwimlanes]);

	return (
		<LibBoard
			{...rest}
			ref={boardApi}
			collapsedSwimlanes={collapsedSwimlanes}
			columnsHighlightColors={columnsHighlightColors}
			components={components}
			extendedOptions={extendedOptions}
			extendedVerticalOptions={extendedVerticalOptions}
			groups={groups}
			hideEmptyColumns={hideEmptyColumns}
			hideEmptyGroups={hideEmptyGroups}
			idsByCell={idsByCell}
			unfilteredIdsByCell={unfilteredIdsByCell}
			idsByColumn={idsByColumn}
			isMoveBetweenColumnsDisabled={isMoveBetweenColumnsDisabled}
			isMoveBetweenGroupsDisabled={isMoveBetweenGroupsDisabled}
			mode="vertical-grouping"
			isCardDropDisabledForColumn={isCardDropDisabledForColumn}
			onAllSwimlanesToggle={toggleAllSwimlanes}
			onCardDrag={onCardDrag}
			onCardDrop={onCardDrop}
			onCardDropChange={onCardDropChange}
			onCardDropClear={onCardDropClear}
			onCardDropStart={onCardDropStart}
			onCardDropVerticalChange={onCardDropVerticalChange}
			onCardDropVerticalClear={onCardDropVerticalClear}
			onColumnDrop={onColumnDrop}
			onRowDrop={onRowDrop}
			onSwimlaneToggle={toogleSwimlane}
			rankAfter={rankAfter}
			rankBefore={rankBefore}
		/>
	);
};

const LibBoardWrapperWithoutGroups = ({
	groupByField,
	...rest
}: LibBoardWrapperWithoutGroupsProps) => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START);
	});

	const { isReadOnly } = rest;

	const groups = useBoardViewWithoutGroups(groupByField);
	const idsByColumn = useLocalIssueIdsByGroupIdentity(groupByField.key);
	const unfilteredIdsByColumn = useUnfilteredLocalIssueIdsByGroupIdentity(groupByField.key);

	const isGlobalCustomField = useIsGlobalCustomField(groupByField.key);
	const isMoveBetweenColumnsDisabled = useIsMoveBetweenGroupsDisabled(groupByField);
	const extendedOptions = useGroupOptions(groupByField);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const canEditFields = useCanEditFields();

	const canAddColumns = useMemo(
		() => !isReadOnly && canEditFields && canModifyOptionName(groupByField.type),
		[canEditFields, groupByField.type, isReadOnly],
	);

	const { draggedCard, onCardDrag, onCardDrop } = useDraggedCard();
	const isCardDropDisabledForColumn = useIsCardDropDisabledForColumn({
		field: groupByField,
		extendedOptions,
		draggedCard,
	});

	const onColumnDrop = useOnColumnDrop(groupByField);

	const { onCardDropChange, onCardDropClear, onCardDropStart, rankAfter, rankBefore } =
		useCardDropHandler(groupByField.key);

	const onAddColumn = useOnAddColumn(groupByField.key);

	const columnsHighlightColors = useColumnsHighlightColors({
		fieldKey: groupByField.key,
		extendedOptions,
	});

	const baseAnalyticsAttributes = useCurrentViewAnalyticsAttributes();
	const viewAccessLevel = useCurrentViewAccessLevel();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();
	const currentViewId = useCurrentViewId();
	const isSharedView = useIsSharedView();

	const analyticsAttributes: Attributes = useMemo(
		() => ({
			...baseAnalyticsAttributes,
			viewColumnCount: extendedOptions.length,
			viewFieldValues: extendedOptions.map((i) => i.groupIdentity),
		}),
		[baseAnalyticsAttributes, extendedOptions],
	);

	const analyticsCallback = useCallback(
		() => (accessLevel: string | undefined) => {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END);
			fireCompoundAnalyticsEvent.BoardView.opened(createAnalyticsEvent({}), accessLevel);
		},
		[createAnalyticsEvent],
	);

	useViewOpenedAnalytics({
		viewId: currentViewId,
		viewAccessLevel,
		isViewPermissionsEnabled,
		analyticsCallback,
		isSharedView,
	});

	return (
		<ContextualAnalyticsData
			attributes={analyticsAttributes}
			sourceName="boardView"
			sourceType={SCREEN}
		>
			<LibBoard
				{...rest}
				canAddColumns={canAddColumns}
				columnsHighlightColors={columnsHighlightColors}
				components={components}
				extendedOptions={extendedOptions}
				groups={groups}
				hideEmptyColumns={hideEmptyColumns}
				idsByColumn={idsByColumn}
				unfilteredIdsByColumn={unfilteredIdsByColumn}
				isGlobalCustomField={isGlobalCustomField}
				isMoveBetweenColumnsDisabled={isMoveBetweenColumnsDisabled}
				mode="grouping"
				onAddColumn={onAddColumn}
				onCardDrag={onCardDrag}
				onCardDrop={onCardDrop}
				onCardDropChange={onCardDropChange}
				onCardDropClear={onCardDropClear}
				isCardDropDisabledForColumn={isCardDropDisabledForColumn}
				onCardDropStart={onCardDropStart}
				onColumnDrop={onColumnDrop}
				rankAfter={rankAfter}
				rankBefore={rankBefore}
			/>
		</ContextualAnalyticsData>
	);
};

const LibBoardWrapper = (props: LibBoardWrapperProps) => {
	const groupByField = useCurrentViewGroupBy();
	assertNonNullable(groupByField);

	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const isExportingViewImage = useIsExportingViewImage();
	const isExportingOld = useIsExporting();
	const isExporting = fg('polaris_extract-view-export') ? isExportingViewImage : isExportingOld;

	if (groupByField && verticalGroupByField) {
		return (
			<LibBoardWrapperWithGroups
				{...props}
				groupByField={groupByField}
				verticalGroupByField={verticalGroupByField}
				isExporting={isExporting}
			/>
		);
	}

	return (
		<LibBoardWrapperWithoutGroups
			{...props}
			groupByField={groupByField}
			isExporting={isExporting}
		/>
	);
};

export type IdeaBoardProps = {
	isReadOnly: boolean;
	isFooterVisible: boolean;
	isSharedView?: boolean;
};

const IdeaBoardInternal = (props: IdeaBoardProps) => {
	const { isReadOnly, isFooterVisible, isSharedView } = props;
	const isCurrentViewConfigured = useCurrentViewConfigured();

	useCurrentViewSuccessExperience();
	useBoardExport();

	const canManageCurrentView = useCanManageCurrentView();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const openGroupByOptions = useOpenRightSidebarGroupByOptions();
	const [sidebarShowing] = useRightSidebarShowing();
	const handleOpenColumnsOptions = useCallback(
		(_event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openColumnsSidebarButtonClicked(analyticsEvent, {
				isEmptyView: true,
			});
			openGroupByOptions();
		},
		[openGroupByOptions],
	);
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();

	if (isSharedView && hasSharedViewConfigError) {
		return (
			<PublishedViewEmptyState
				permissionsLoaded={permissionsLoaded}
				hasProjectPermissions={!hasNoProjectPermissions}
			/>
		);
	}

	if (!isCurrentViewConfigured) {
		return (
			<BoardViewEmptyState
				onButtonClick={canManageCurrentView ? handleOpenColumnsOptions : undefined}
				isHintHidden={sidebarShowing.mode === RightSidebarShowingGroupByOptions}
			/>
		);
	}

	// Here we can be sure that any call to
	// `useCurrentViewGroupBy` hook will NOT return `undefined`
	// because `useCurrentViewConfigured` is calculated based on
	// `groupBy` and in such case we always display
	// `BoardViewEmptyState`

	return (
		<IdeaCardContextProvider>
			<LibBoardWrapper isReadOnly={isReadOnly} isFooterVisible={isFooterVisible} />
		</IdeaCardContextProvider>
	);
};

export const IdeaBoard = memo<IdeaBoardProps>((props) => (
	<UFOSegment name="jpd.board-view">
		<ExperienceFailErrorBoundary
			experience={experience.boardView.pageSegmentLoad}
			metadata={{ isSharedView: props.isSharedView }}
		>
			<IdeaBoardInternal {...props} />
		</ExperienceFailErrorBoundary>
	</UFOSegment>
));

import React from 'react';
import throttle from 'lodash/throttle';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useToggleIssueWatching } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view.tsx';
import { TOGGLE_ISSUE_WATCHING_KEY } from '../constants.tsx';

export const IdeaViewKeyboardShortcuts = () => {
	const toggleIssueWatching = useToggleIssueWatching();

	const keyMap = {
		[TOGGLE_ISSUE_WATCHING_KEY]: {
			callback: throttle(toggleIssueWatching, 1000),
		},
	};

	return <Shortcuts keyMap={keyMap} />;
};

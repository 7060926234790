import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	DraggedCard,
	ExtendedOption,
	Group,
	IssuesByCell,
	IssuesByGroupIdentity,
} from './common.tsx';
import type { CustomComponents } from './custom-components.tsx';

export const MODE_VERTICAL_GROUPING = 'vertical-grouping';
export const MODE_GROUPING = 'grouping';

export type OnColumnDrop = (
	nextExtendedOptions: ExtendedOption<unknown>[],
	source: ExtendedOption<unknown>,
) => void;

export type OnRowDrop = (nextExtendedOptions: ExtendedOption<unknown>[]) => void;

export type RankAfter = (id: string, destinationAfterId: string) => void;
export type RankBefore = (id: string, destinationBeforeId: string) => void;

export type IsCardDropDisabledForColumn = (groupIdentity?: string) => boolean;

export type OnCardDropChange = ({
	issueId,
	targetValue,
	sourceValue,
}: {
	issueId: string;
	targetValue: unknown;
	sourceValue: unknown;
}) => void;
export type OnCardDropClear = (issueId: string) => void;
export type OnCardDropStart = (issueId: string) => void;
export type OnCardDrop = () => void;
export type OnCardDrag = (draggedCard: DraggedCard) => void;

export type OnAddColumn = (
	event: React.MouseEvent<HTMLElement>,
	analyticsEvent: UIAnalyticsEvent,
) => Promise<string>;

export type OnSwimlaneToggle = (verticalGropIdentity: string, isHiddenByDefault: boolean) => void;
export type OnAllSwimlanesToggle = () => void;

export type ColumnsHighlightColors = Map<string | undefined, string>;

export type CollapsedSwimlanes = Record<string, boolean>;

type BoardSharedProps = {
	columnsHighlightColors: ColumnsHighlightColors;
	components: CustomComponents;
	extendedOptions: ExtendedOption<unknown>[];
	groups: Group[];
	hideEmptyColumns: boolean;
	idsByColumn: IssuesByGroupIdentity;
	isExporting: boolean;
	isFooterVisible: boolean;
	isMoveBetweenColumnsDisabled: boolean;
	isReadOnly: boolean;
	isCardDropDisabledForColumn: IsCardDropDisabledForColumn;
	onCardDrag: OnCardDrag;
	onCardDrop: OnCardDrop;
	onCardDropChange: OnCardDropChange;
	onCardDropClear: OnCardDropClear;
	onCardDropStart: OnCardDropStart;
	onColumnDrop: OnColumnDrop;
	rankAfter: RankAfter;
	rankBefore: RankBefore;
};

export type BoardWithoutGroupsProps = {
	canAddColumns?: boolean;
	isGlobalCustomField?: boolean;
	mode: typeof MODE_GROUPING;
	unfilteredIdsByColumn: IssuesByGroupIdentity;
	onAddColumn: OnAddColumn;
} & BoardSharedProps;

export type BoardWithGroupsProps = {
	collapsedSwimlanes: CollapsedSwimlanes;
	extendedVerticalOptions: ExtendedOption<unknown>[];
	hideEmptyGroups: boolean;
	idsByCell: IssuesByCell;
	isMoveBetweenGroupsDisabled: boolean;
	mode: typeof MODE_VERTICAL_GROUPING;
	unfilteredIdsByCell: IssuesByCell;
	onRowDrop: OnRowDrop;
	onSwimlaneToggle: OnSwimlaneToggle;
	onAllSwimlanesToggle: OnAllSwimlanesToggle;
	onCardDropVerticalChange: OnCardDropChange;
	onCardDropVerticalClear: OnCardDropClear;
} & BoardSharedProps;

export type BoardProps = BoardWithoutGroupsProps | BoardWithGroupsProps;

import React, { Component, type ReactNode, createRef } from 'react';
import { styled } from '@compiled/react';
import {
	ProfileCardTrigger,
	type ProfileClient,
	type ProfileCardAction,
	type ProfilecardTriggerPosition,
	type TriggerType,
	type PrepopulatedData,
} from '@atlaskit/profilecard';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { type FlagId, AkFlag, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { analyticsViewComponentName } from './constants.tsx';

export type Props = {
	accountId: string;
	children: ReactNode;
	cloudId: string;
	profileClient: ProfileClient | null;
	actions?: ProfileCardAction[];
	position?: ProfilecardTriggerPosition;
	trigger?: TriggerType;
	onProfileClick?: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/react/handler-naming
	addFlag: (flag: any) => void;
	// eslint-disable-next-line jira/react/handler-naming
	dismissFlag: (flagId: FlagId) => void;
	testId?: string;
	prepopulatedData?: PrepopulatedData;
	viewingUserId?: string;
};

const PEOPLE_PROFILE_PATH = '/people/';

const ProfileCardTriggerWithAnalyticsNew = AnalyticsEventToProps(analyticsViewComponentName, {
	analytics: '',
})(ProfileCardTrigger);

// eslint-disable-next-line jira/react/no-class-components
class ProfileCardView extends Component<Props> {
	static defaultProps = {
		position: 'top-start',
		trigger: 'hover',
	};

	profileCardWrapperRef = createRef<HTMLDivElement>();

	render() {
		const {
			children,
			position,
			trigger,
			accountId,
			cloudId,
			profileClient,
			actions,
			onProfileClick,
			addFlag,
			dismissFlag,
			testId,
			prepopulatedData,
			viewingUserId,
		} = this.props;

		const ProfileCardComponent = ProfileCardTriggerWithAnalyticsNew;

		return (
			// Stop the propagation of the click event when clicking on the profile card pop up,
			// to avoid closing the popup and opening the people picker when clicking the info inside.
			// Don't stop propagation when clicking the people picker directly.
			<Wrapper
				ref={this.profileCardWrapperRef}
				onClick={(event) => {
					if (
						this.profileCardWrapperRef?.current !== null &&
						// @ts-expect-error - TS2571 - Object is of type 'unknown'.
						!this.profileCardWrapperRef?.current?.contains(event.target)
					) {
						event.stopPropagation();
					}
				}}
				data-vc={`profilecard-wrapper${__SERVER__ ? '-ssr' : ''}`}
				isVisualRefresh={isVisualRefreshEnabled() && fg('visual-refresh_drop_2')}
			>
				<ProfileCardComponent
					cloudId={cloudId}
					userId={accountId}
					resourceClient={profileClient}
					position={position}
					trigger={trigger}
					testId={testId}
					actions={actions}
					reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
					prepopulatedData={prepopulatedData}
					onReportingLinesClick={
						// @ts-expect-error - TS7006 - Parameter 'user' implicitly has an 'any' type.
						onProfileClick ? (user) => onProfileClick(user.accountIdentifier) : null
					}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					addFlag={(flag: any) => {
						const flagId = toFlagId(flag.id);
						addFlag({
							id: flagId,
							render: (props: FlagCustomRenderProps) => (
								<AkFlag
									{...flag}
									onDismissed={() => {
										dismissFlag(flagId);
									}}
									{...props}
								/>
							),
						});
					}}
					viewingUserId={viewingUserId}
				>
					<div data-testid="profilecard-next.ui.profilecard.profilecard-trigger">
						<>{children}</>
					</div>
				</ProfileCardComponent>
			</Wrapper>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ProfileCardView as Flow.AbstractComponent<Props>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapperOld = styled.div<{ isVisualRefresh: boolean }>({
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	color: ({ isVisualRefresh }) => (isVisualRefresh ? token('color.text.subtle') : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div:first-of-type': {
		width: '100%',
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapperNew = styled.div<{ isVisualRefresh: boolean }>({
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	color: ({ isVisualRefresh }) => (isVisualRefresh ? token('color.text.subtle') : undefined),
});
const Wrapper = componentWithFG('platform_profile_card_css_refactor', WrapperNew, WrapperOld);

Wrapper.displayName = 'Wrapper';

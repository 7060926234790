import {
	B300,
	B200,
	P200,
	G200,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type { IdeaTemplate } from '../types.tsx';
import EXPERIMENT_IDEA_TEMPLATE from './experiment-template/index.tsx';
import HYPOTHESIS_TEMPLATE from './hypothesis/index.tsx';
import IDEA_EVALUATION_TEMPLATE from './idea-evaluation/index.tsx';
import OPPORTUNITY_STATEMENT_TEMPLATE from './opportunity-statement/index.tsx';
import PROBLEM_DEFINITION_TEMPLATE from './problem-definition/index.tsx';
import SOLUTION_DEFINITION_TEMPLATE from './solution-definition/index.tsx';
import SOLUTION_RETRO_TEMPLATE from './solution-retro/index.tsx';

const HYPOTHESIS: IdeaTemplate = {
	id: 'HypothesisTesting',
	title: 'Hypothesis testing',
	description: 'Define product hypothesis, validate them and capture key decisions.',
	template: HYPOTHESIS_TEMPLATE,
	color: B300,
	emoji: '1f4ca',
};

const PROBLEM_DEFINITION: IdeaTemplate = {
	id: 'ProblemDefinition',
	title: 'Problem definition',
	description: "Define what problem you're solving and for whom.",
	template: PROBLEM_DEFINITION_TEMPLATE,
	color: B300,
	emoji: '2601',
};

const SOLUTION_DEFINITION: IdeaTemplate = {
	id: 'SolutionDefinition',
	title: 'Solution definition',
	description: 'Define potential solutions and how to validate them.',
	template: SOLUTION_DEFINITION_TEMPLATE,
	color: B300,
	emoji: '1f5d2',
};

const SOLUTION_RETRO: IdeaTemplate = {
	id: 'SolutionRetro',
	title: 'Solution retrospective',
	description: 'Review the outcomes of a particular solution exploration.',
	template: SOLUTION_RETRO_TEMPLATE,
	color: B300,
	emoji: '2754',
};

const OPPORTUNITY_STATEMENT: IdeaTemplate = {
	id: 'OpportunityStatement',
	title: 'Opportunity Statement',
	description: 'Transform customer problems into opportunities to improve people’s experiences',
	template: OPPORTUNITY_STATEMENT_TEMPLATE,
	color: B200,
	emoji: '1f680',
};

const EXPERIMENT_TEMPLATE: IdeaTemplate = {
	id: 'ExperimentTemplate',
	title: 'Experiment template',
	description: 'This blueprint helps you capture and prioritise growth experiment ideas',
	template: EXPERIMENT_IDEA_TEMPLATE,
	color: P200,
	emoji: '1f9ea',
};

const IDEA_EVALUATION: IdeaTemplate = {
	id: 'IdeaEvaluation',
	title: 'Idea evaluation template',
	description:
		'Use this to evaluate ideas, assess MVP features, and ensure the product meets customer needs',
	template: IDEA_EVALUATION_TEMPLATE,
	color: G200,
	emoji: '1f9d0',
};

export const OLD_DEFAULT_TEMPLATES = [
	HYPOTHESIS,
	PROBLEM_DEFINITION,
	SOLUTION_DEFINITION,
	SOLUTION_RETRO,
];

export const NEW_DEFAULT_TEMPLATES = [OPPORTUNITY_STATEMENT, EXPERIMENT_TEMPLATE, IDEA_EVALUATION];

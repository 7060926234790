import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useStringValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { StringField } from '@atlassian/jira-polaris-common/src/ui/fields/string/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import messages from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

export const String = ({ fieldKey, localIssueId }: Props) => {
	const { formatMessage } = useIntl();
	const field = useField(fieldKey);
	const value = useStringValue(fieldKey, localIssueId);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	if (field === undefined) {
		return null;
	}

	return (
		<StringField
			value={value}
			fieldType={field.type}
			isEditable={canEditIssues && field.editable}
			onUpdate={(newValue?: string) => {
				updateFieldValue({
					fieldKey,
					localIssueIds: [localIssueId],
					newValue,
				});
			}}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};

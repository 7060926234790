import React, { type SyntheticEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type ArchiveButtonProps = {
	isDropdownItem?: boolean;
};

export const ArchiveButton = ({ isDropdownItem = false }: ArchiveButtonProps) => {
	const { formatMessage } = useIntl();
	const localIssueId = useSelectedIssue();
	const { archiveIssues } = useIssueActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent?: UIAnalyticsEvent) => {
			if (isDropdownItem) {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
					'issueOption',
					{
						selectedActionItem: 'archive',
					},
				);
			} else {
				// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
				fireUIAnalytics(analyticsEvent, 'archive');
			}

			if (localIssueId === undefined) {
				return;
			}
			archiveIssues([localIssueId]);
		},
		[archiveIssues, localIssueId, createAnalyticsEvent, isDropdownItem],
	);

	if (localIssueId === undefined) {
		return null;
	}

	const Component = isDropdownItem ? DropdownItem : Button;

	return (
		<Component
			testId="polaris-ideas.ui.idea-view.collaboration-controls.archive.button"
			onClick={handleClick}
		>
			{formatMessage(messages.archiveButton)}
		</Component>
	);
};

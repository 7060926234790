import type { RowGroupId, RowId } from '../../../common/types/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../../constants.tsx';
import type { RowGrouping } from '../../types.tsx';

const getRowIdxInsideGroups = (
	rowGroups?: (RowGroupId | undefined)[],
	groupsToItems?: RowGrouping,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	collapsedGroupIds: RowGroupId[],
	id: RowId,
): number => {
	if (rowGroups === undefined || groupsToItems === undefined) {
		return -1;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rows: Record<string, any> = {};
	let index = 0;
	groupsToItems.noGroup?.forEach((rowId: RowId) => {
		rows[rowId] = index;
		index += 1;
	});
	rowGroups.forEach((groupId = NO_VALUE_GROUP_ID) => {
		rows[groupId] = index;
		index += 1;
		const container =
			groupId === NO_VALUE_GROUP_ID ? groupsToItems?.empty : groupsToItems?.groups[groupId];
		!collapsedGroupIds.includes(groupId) &&
			container?.forEach((rowId: RowId) => {
				rows[rowId] = index;
				index += 1;
			});
	});
	return rows[id] ?? -1;
};

export const isRowMovedInsideGroups = (
	rowGroups?: (RowGroupId | undefined)[],
	groupsToItems?: RowGrouping,
	newRowGroups?: (RowGroupId | undefined)[],
	newGroupsToItems?: RowGrouping,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	collapsedGroupIds: RowGroupId[],
	id: RowId,
): boolean => {
	const oldIdx = getRowIdxInsideGroups(rowGroups, groupsToItems, collapsedGroupIds, id);
	const newIdx = getRowIdxInsideGroups(newRowGroups, newGroupsToItems, collapsedGroupIds, id);
	return oldIdx !== newIdx;
};

import React from 'react';
import { styled } from '@compiled/react';
import { useHighlightedFields } from '../../../controllers/issue/selectors/decorations-hooks.tsx';
import { Highlighting } from '../../decoration/highlighting/index.tsx';
import { HighlightingTooltip } from '../../highlighting-tooltip/index.tsx';

type HighlighterProps = { id: string; isResizing?: boolean };

export const Highlighter = ({ id, isResizing }: HighlighterProps) => {
	const highlightedFields = useHighlightedFields(id);
	return (
		<HighlightingWrapper>
			<HighlightingTooltip
				issueId={id}
				fields={highlightedFields}
				isResizing={isResizing}
				position="mouse"
			>
				<Highlighting fields={highlightedFields} />
			</HighlightingTooltip>
		</HighlightingWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HighlightingWrapper = styled.div({
	display: 'flex',
	position: 'absolute',
	top: 0,
	left: 0,
	height: '100%',
	pointerEvents: 'auto',
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createIdeaMessage: {
		id: 'polaris-ideas.create-issue-interceptor.create-idea-message',
		defaultMessage: 'Create an idea',
		description: 'Button message for creating an idea',
	},
	contributorsSpotlightCreateIdeaBtn: {
		id: 'polaris-ideas.create-issue-interceptor.contributors-spotlight-create-idea-btn',
		defaultMessage: 'Contributors will be able to create ideas from here.',
		description: 'Spotlight message for create idea button for contributors',
	},
	contributorsSpotlightCloseBtn: {
		id: 'polaris-ideas.create-issue-interceptor.contributors-spotlight-close-btn',
		defaultMessage: 'Got it',
		description: 'Button message for closing spotlight',
	},
	spotlightCreateIdeaBtn: {
		id: 'polaris-ideas.create-issue-interceptor.spotlight-create-idea-btn',
		defaultMessage:
			'To create an idea, select + Create and modify the idea fields you need. These can be changed anytime. ',
		description: 'Spotlight message for create idea button',
	},
	spotlightCloseBtn: {
		id: 'polaris-ideas.create-issue-interceptor.spotlight-close-btn',
		defaultMessage: 'Ok, got it',
		description: 'Button message for closing spotlight',
	},
});

import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import AkLozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { ASSIGNEE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { FIELD_HEIGHT, FIELD_HEIGHT_COMPACT } from '../../constants.tsx';
import {
	getAppearance,
	useVisibleAssignee,
	useVisibleStatus,
	useIdeaCardContext,
} from '../../utils.tsx';
import { FieldValue } from '../field/styled.tsx';

type Props = {
	issueId: string;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmptyFields?: boolean;
};

export const useIsDefaultFieldsVisible = (issueId: string) => {
	const { allFieldsByKey } = useIdeaCardContext();
	const isAssigneeFieldVisible = allFieldsByKey[ASSIGNEE_FIELDKEY] !== undefined;
	const status = useVisibleStatus(issueId);

	return !!status || isAssigneeFieldVisible;
};

export const DefaultFields = memo(({ issueId, isCompact, isSummary, hideEmptyFields }: Props) => {
	const { allFieldsByKey } = useIdeaCardContext();
	const isAssigneeFieldVisible = allFieldsByKey[ASSIGNEE_FIELDKEY] !== undefined;
	const assignee = useVisibleAssignee(issueId);
	const status = useVisibleStatus(issueId);
	const shouldRender = useIsDefaultFieldsVisible(issueId);

	if (!shouldRender || (hideEmptyFields && !assignee && !status)) {
		return null;
	}

	const hasAssignee = isAssigneeFieldVisible && (assignee || (!status && !isCompact));

	return (
		<FieldValue isFullwidth>
			<Container isCompact={isCompact} isSingleColumn={!hasAssignee}>
				<StatusWrapper>
					{status && (
						<AkLozenge appearance={getAppearance(status)} maxWidth={300}>
							{status.name}
						</AkLozenge>
					)}
				</StatusWrapper>

				{isAssigneeFieldVisible && !isSummary && (
					<>
						{assignee && (
							<AvatarWrapper>
								<Avatar size="small" src={assignee.avatarUrl} name={assignee.name} />
							</AvatarWrapper>
						)}
						{(assignee === null || assignee === undefined) && !status && !isCompact && (
							<AvatarWrapper>
								<Avatar size="small" />
							</AvatarWrapper>
						)}
					</>
				)}
			</Container>
		</FieldValue>
	);
});

DefaultFields.displayName = 'CardDefaultFields';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	isCompact?: boolean;
	isSingleColumn?: boolean;
}>({
	display: 'grid',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridTemplateColumns: ({ isSingleColumn }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isSingleColumn ? '1fr' : `minmax(0, 1fr) ${FIELD_HEIGHT}px`,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	gridAutoRows: ({ isCompact }) => `${isCompact ? FIELD_HEIGHT_COMPACT : FIELD_HEIGHT}px`,
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		margin: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusWrapper = styled.div({
	display: 'flex',
});

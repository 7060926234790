import React from 'react';
import { styled } from '@compiled/react';
import { LinkButton } from '@atlaskit/button/new';
import ArrowUpRight from '@atlaskit/icon/core/migration/arrow-up-right--open';
import { Box, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { CreateFieldButton } from '@atlassian/jira-polaris-common/src/ui/common/create-field/index.tsx';
import { messages } from './messages.tsx';

type FooterProps = {
	displayCreateFieldButton: boolean;
	displayCreateGlobalFieldButton: boolean;
	onCreate: () => void;
};

export const Footer = ({
	displayCreateFieldButton,
	displayCreateGlobalFieldButton,
	onCreate,
}: FooterProps) => {
	const { formatMessage } = useIntl();

	if (!displayCreateFieldButton && !displayCreateGlobalFieldButton) {
		return null;
	}

	return (
		<FooterContainer>
			{displayCreateFieldButton && (
				<CreateFieldButton shouldFitContainer appearance="primary" onCreate={onCreate} />
			)}
			{displayCreateGlobalFieldButton && (
				<LinkButton
					appearance="primary"
					shouldFitContainer
					href="/jira/settings/products/jira-product-discovery/global-fields?openNewFieldPanel=true"
					target="_blank"
				>
					<Inline space="space.050" alignBlock="center" alignInline="center">
						<Box>{formatMessage(messages.createGlobalField)}</Box>
						<ArrowUpRight label="" color={token('color.text.inverse')} />
					</Inline>
				</LinkButton>
			)}
		</FooterContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.footer({
	padding: token('space.200', '16px'),
});

import React, {
	type PropsWithChildren,
	memo,
	type ReactNode,
	useMemo,
	useCallback,
	useLayoutEffect,
} from 'react';
import noop from 'lodash/noop';
import { useApolloClient } from '@apollo/react-hooks';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project.tsx';
import {
	useCloudId,
	useCurrentUser,
} from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import {
	useIsEmbedded,
	PolarisEnvironmentContainer,
	useIsSharedView,
	useIsCollectionView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { FieldContainer } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useFieldValueDecorations } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useAllFieldsArray,
	useFieldsByKey,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIsInitializedPromise as useIsFieldsInitializedPromise } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import { IdeaViewContainer } from '@atlassian/jira-polaris-common/src/controllers/idea/index.tsx';
import { IssuesContainer } from '@atlassian/jira-polaris-common/src/controllers/issue/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useIsInitialized,
	useIsInitializedPromise,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useTimestampCurrentUserSeenComments } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/comments/hooks.tsx';
import { useJiraRankFieldKey } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import {
	useCurrentPolarisRoute,
	useIsIssueOpenInSidebar,
	usePolarisRouter,
	useSelectedIssueQueryParameter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { SharingContainer } from '@atlassian/jira-polaris-common/src/controllers/sharing/index.tsx';
import { useCurrentUserTimezone } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import { ViewContainer } from '@atlassian/jira-polaris-common/src/controllers/views/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewFilterForShared,
	useCurrentViewInitialFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useCurrentViewSelectedIssueIdsAsList,
	useCurrentViewIssueRanking,
	useCurrentViewSortMode,
	useCurrentViewProjectId,
	useCurrentViewAri,
	useCurrentViewUUID,
	useCurrentViewContainsArchived,
	useCurrentViewSortBy,
	useCurrentViewRankFieldKey,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { WorkflowContainer } from '@atlassian/jira-polaris-common/src/controllers/workflow/main.tsx';
import { useReactionClient } from '@atlassian/jira-polaris-common/src/services/polaris-api/reactions/index.tsx';
import { PermissionsContainer } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useCanMakeBulkChanges,
	useCanRankIdeas,
	useHasNoProjectPermissions,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import type { StatusCategory } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { EmojiContainer } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import {
	useOnCyclicFormulaFieldError,
	useErrorHandlers,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { SmartCardContainer } from '@atlassian/jira-polaris-lib-smart-card/src/controllers/index.tsx';
import { useFieldRemote } from '@atlassian/jira-polaris-remote-field/src/main.tsx';
import { useGlobalFieldRemote } from '@atlassian/jira-polaris-remote-global-field/src/index.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { usePlayContributionRemote } from '@atlassian/jira-polaris-remote-play-contribution/src/main.tsx';
import { useRankingRemote } from '@atlassian/jira-polaris-remote-ranking/src/controllers/index.tsx';
import { useViewRemote } from '@atlassian/jira-polaris-remote-view/src/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { type IssueTypeId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { UserContainer } from '@atlassian/jira-user-services/src/main.tsx';
import { SharingRouteContainer } from './router/index.tsx';

const IdeaViewDataContainer = ({
	children,
	issuesRemote,
}: {
	children: ReactNode;
	issuesRemote: IssuesRemote;
}) => {
	const selectedIssue = useSelectedIssueQueryParameter();
	const polarisIssueLinkTypes = useMemo(
		() => ({
			polarisDeliveryIssueLinkType: undefined,
			polarisDataPointLinkType: undefined,
		}),
		[],
	);

	const { generalDataLoadingFailedError, generalDataUpdateFailedError, generalActionFailedError } =
		useErrorHandlers();

	const issuesContainerInitialized = useIsInitializedPromise();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();

	const {
		addPropertiesForLoadedIssue,
		updateFieldValueForSelectedIssue,
		setIssueLinksForSelectedIssue,
		refreshDeliveryProgressForSelectedIssue,
		setCommentsForSelectedIssue,
	} = useIssueActions();
	const fieldsContainerInitialized = useIsFieldsInitializedPromise();

	const onDeliveryIssuesUpdated = useCallback(() => {
		refreshDeliveryProgressForSelectedIssue();
	}, [refreshDeliveryProgressForSelectedIssue]);

	const isIssuesInitialized = useIsInitialized();
	const timestampCurrentUserSeenComments = useTimestampCurrentUserSeenComments();
	const [fields] = useAllFieldsArray();
	const [jiraRankFieldKey] = useJiraRankFieldKey();

	const onIssueLoadedCallback = useCallback(
		(response: RemoteIssue) => {
			setIssueLinksForSelectedIssue(response);
			fieldsContainerInitialized().then(() => {
				// only add properties if issues container has not been initialized yet
				!isIssuesInitialized && addPropertiesForLoadedIssue(response);
			});
			return issuesContainerInitialized().then(() => {
				// set description also in issues store only after all issues have been loaded (all the properties are replaced)
				const value: DocumentFieldValue = response.fields.description;
				updateFieldValueForSelectedIssue({
					fieldKey: 'description',
					newValue: value,
					performSideEffects: false,
				});
			});
		},
		[
			addPropertiesForLoadedIssue,
			fieldsContainerInitialized,
			isIssuesInitialized,
			issuesContainerInitialized,
			setIssueLinksForSelectedIssue,
			updateFieldValueForSelectedIssue,
		],
	);

	return (
		<IdeaViewContainer
			projectId=""
			arjConfiguration={undefined}
			timestampCurrentUserSeenComments={timestampCurrentUserSeenComments}
			timestampCurrentUserSeenInsights={undefined}
			onCommentsUpdated={setCommentsForSelectedIssue}
			hasNoProjectPermissions={hasNoProjectPermissions}
			issuesRemote={issuesRemote}
			initialDeliveryTickets={[]}
			archivedFieldsConfig={undefined}
			polarisIssueLinkTypes={polarisIssueLinkTypes}
			issueKey={selectedIssue}
			fields={fields}
			rankField={jiraRankFieldKey}
			onDataLoadingFailed={generalDataLoadingFailedError}
			onDataUpdateFailed={generalDataUpdateFailedError}
			onActionFailed={generalActionFailedError}
			onDeliveryIssuesUpdated={onDeliveryIssuesUpdated}
			onIssuesContainerInitialized={issuesContainerInitialized}
			onIssueLoaded={onIssueLoadedCallback}
		>
			{children}
		</IdeaViewContainer>
	);
};

const emptyIssueTypeIds: IssueTypeId[] = [];
const emptyStatusCategories: StatusCategory[] = [];

export const WorkflowDataContainer = ({ children }: { children: ReactNode }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError } = useErrorHandlers();

	const issuesRemote = useIssueRemote();

	return (
		<WorkflowContainer
			isSharedView
			issuesRemote={issuesRemote}
			statusCategories={emptyStatusCategories}
			issueTypeIds={emptyIssueTypeIds}
			createAnalyticsEvent={createAnalyticsEvent}
			onActionFailed={generalActionFailedError}
			isProjectLoading={false}
		>
			{children}
		</WorkflowContainer>
	);
};

export const IssueDataContainer = ({ children }: { children: ReactNode }) => {
	const { locale } = useTenantContext();
	const timezone = useCurrentUserTimezone();
	const issueTypeIds = useMemo(() => [], []);
	const issueTypesNameFilter = useCallback(() => [], []);
	const insights = useMemo(() => ({}), []);
	const plays = useMemo(() => ({}), []);
	const [fields] = useAllFieldsArray();
	const [fieldValueDecorations] = useFieldValueDecorations();
	const sortBy = useCurrentViewSortBy();
	const filter = useCurrentViewFilterForShared();
	const initialFilter = useCurrentViewInitialFilter();
	const containsArchived = useCurrentViewContainsArchived();
	const [{ polarisDeliveryIssueLinkType }] = [{ polarisDeliveryIssueLinkType: undefined }]; // useInstanceData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError } = useErrorHandlers();
	const apolloClient = useApolloClient();
	const reactionsClient = useReactionClient();
	const cloudId = useCloudId();
	const { refreshAllSnippets } = {
		refreshAllSnippets: noop,
	};
	const embedded = useIsEmbedded();

	const onContainerReady = useCallback(() => refreshAllSnippets(), [refreshAllSnippets]);
	const currentUser = useCurrentUser();
	const arjHierarchyConfiguration = useMemo(() => [], []);
	const arjLicensed = false;
	const hiddenIssueLinkTypes = useMemo(() => [], []);
	const transitions = useMemo(() => ({}), []);
	const selectedIssueIds = useCurrentViewSelectedIssueIdsAsList();
	const sortMode = useCurrentViewSortMode();
	const externalRanks = useCurrentViewIssueRanking();
	const canRankIdeas = useCanRankIdeas();
	const selectedIssue = useSelectedIssueQueryParameter();
	const rankField = useCurrentViewRankFieldKey();
	const [hasBulkChangePermissions] = useCanMakeBulkChanges();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const projectId = useCurrentViewProjectId();
	const currentRoute = useCurrentPolarisRoute();

	const insightsRemote = useInsightRemote();
	const issuesRemote = useIssueRemote();
	const playContributionRemote = usePlayContributionRemote();

	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const singleIdeaVisible = selectedIssue !== undefined && !isIssueOpenInSidebar;
	const { handleDeliveryDataFetchError } = useViewActions();

	return (
		<IssuesContainer
			insightsRemote={insightsRemote}
			issuesRemote={issuesRemote}
			playContributionRemote={playContributionRemote}
			arjLicensed={arjLicensed}
			arjConfiguration={undefined}
			arjHierarchyConfiguration={arjHierarchyConfiguration}
			hasBulkChangePermissions={hasBulkChangePermissions}
			hasNoProjectPermissions={hasNoProjectPermissions}
			currentUser={currentUser}
			locale={locale}
			timezone={timezone}
			projectId={projectId?.toString()}
			issueTypeIds={issueTypeIds}
			getIssueTypeNameFilter={issueTypesNameFilter}
			fields={fields}
			insights={insights}
			plays={plays}
			rankField={rankField}
			archivedFieldsConfig={undefined}
			sortMode={sortMode}
			sortBy={sortBy}
			externalIssueRanking={externalRanks}
			filter={filter}
			onLoadArchivedIssues={noop}
			initialFilter={initialFilter}
			containsArchived={containsArchived}
			decorations={fieldValueDecorations}
			polarisIssueLinkType={polarisDeliveryIssueLinkType}
			hiddenIssueLinkTypes={hiddenIssueLinkTypes}
			createAnalyticsEvent={createAnalyticsEvent}
			onActionFailed={generalActionFailedError}
			apolloClient={apolloClient}
			reactionsClient={reactionsClient}
			cloudId={cloudId}
			onContainerReady={onContainerReady}
			embedded={embedded}
			currentViewSelectedIssueId={selectedIssueIds}
			selectedIssue={selectedIssue !== undefined ? toIssueKey(selectedIssue) : selectedIssue}
			workflowTransitions={transitions}
			isRankingEnabled={canRankIdeas}
			projectOnboardedAt={undefined}
			projectTemplateVersion={undefined}
			permissionsLoaded={permissionsLoaded}
			isSharedView
			singleIdeaVisible={singleIdeaVisible}
			onDeliveryDataFetchFailed={handleDeliveryDataFetchError}
			selectedViewId={currentRoute?.resource}
		>
			<IdeaViewDataContainer issuesRemote={issuesRemote}>{children}</IdeaViewDataContainer>
		</IssuesContainer>
	);
};

const ViewDataContainer = memo<{ children: ReactNode; viewId: string }>(
	({ children, viewId }: { children: ReactNode; viewId: string }) => {
		const [fieldsByKey] = useFieldsByKey();

		const {
			generalDataLoadingFailedError,
			generalDataUpdateFailedError,
			generalActionFailedError,
		} = useErrorHandlers();

		const router = usePolarisRouter();
		const currentUser = useCurrentUser();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const cloudId = useCloudId();

		const viewRemote = useViewRemote();
		const rankingRemote = useRankingRemote();
		const isIssueOpenInSidebar = useIsIssueOpenInSidebar();

		const viewDataLoadingErrorHandler = useCallback(
			(errorObject: Error) => {
				generalDataLoadingFailedError(errorObject, (metadata) => {
					const failureMetadata = {
						...metadata,
						metadata: {
							...metadata.metadata,
							isSharedView: true,
						},
					};

					experience.listView.pageSegmentLoad.failure(failureMetadata);
					experience.boardView.pageSegmentLoad.failure(failureMetadata);
					experience.matrixView.pageSegmentLoad.failure(failureMetadata);
					experience.timelineView.pageSegmentLoad.failure(failureMetadata);
				});
			},
			[generalDataLoadingFailedError],
		);

		return (
			<ViewContainer
				isSharedView
				scope="view-singleton"
				containerAri=""
				viewRemote={viewRemote}
				rankingRemote={rankingRemote}
				fields={fieldsByKey}
				currentUser={currentUser}
				onViewLoadingFailed={viewDataLoadingErrorHandler}
				onViewUpdateFailed={generalDataUpdateFailedError}
				hasNoProjectPermissions={false}
				onActionFailed={generalActionFailedError}
				createAnalyticsEvent={createAnalyticsEvent}
				router={router}
				currentViewSection="view"
				currentViewSlug={viewId}
				cloudId={cloudId}
				projectOnboardedAt={undefined}
				onDeliveryDataFetchFailed={generalDataLoadingFailedError}
				delayDeliveryDataFetchFailedNotification={!isIssueOpenInSidebar}
				onSyncViewName={noop}
				onSyncViewEmoji={noop}
				onSyncViewDeletion={noop}
			>
				{children}
			</ViewContainer>
		);
	},
);

const FieldDataContainer = memo<{ children: ReactNode }>(
	({ children }: { children: ReactNode }) => {
		const {
			generalDataLoadingFailedError,
			generalDataUpdateFailedError,
			generalActionFailedError,
		} = useErrorHandlers();
		const onCyclicFormulaDependencyDetected = useOnCyclicFormulaFieldError();

		const projectId = undefined;
		const issueTypeIds = useMemo(() => [], []);
		const { cloudId, environment } = useTenantContext();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const fieldRemote = useFieldRemote();
		const globalFieldRemote = useGlobalFieldRemote();
		const isSiteAdmin = useIsSiteAdmin();

		return (
			<FieldContainer
				scope="field-singleton"
				containerAri=""
				isContainerInitialized
				fieldRemote={fieldRemote}
				globalFieldRemote={globalFieldRemote}
				hasNoProjectPermissions={false}
				deletePlay={noop}
				onFieldLoadingFailed={generalDataLoadingFailedError}
				onDecorationUpdateFailed={generalDataUpdateFailedError}
				onFieldUpdateFailed={generalDataUpdateFailedError}
				onActionFailed={generalActionFailedError}
				onCyclicFormulaDependencyDetected={onCyclicFormulaDependencyDetected}
				createAnalyticsEvent={createAnalyticsEvent}
				projectId={projectId}
				issueTypeIds={issueTypeIds}
				isAtlasIntegrationEnabled={false}
				cloudId={cloudId}
				isSiteAdmin={isSiteAdmin}
				environment={environment}
			>
				{children}
			</FieldContainer>
		);
	},
);

const SharedViewExperiencesStart = () => {
	const viewKind = useCurrentViewKind();
	const isSharedView = useIsSharedView();

	useLayoutEffect(() => {
		if (!isSharedView) {
			return;
		}

		switch (viewKind) {
			case VIEW_KIND_MATRIX:
				experience.matrixView.pageSegmentLoad.start();
				break;
			case VIEW_KIND_TIMELINE:
				experience.timelineView.pageSegmentLoad.start();
				break;
			case VIEW_KIND_BOARD:
				experience.boardView.pageSegmentLoad.start();
				break;
			case VIEW_KIND_TABLE:
				experience.listView.pageSegmentLoad.start();
				break;
			default:
		}
	}, [isSharedView, viewKind]);

	return null;
};

const PermissionsDataContainer = ({ children }: { children: ReactNode }) => {
	const projectId = useCurrentViewProjectId();
	const isEmbeddedView = useIsEmbedded();
	const isCollectionView = useIsCollectionView();
	return (
		<PermissionsContainer
			containerAri=""
			scope={
				getWillShowNav4() && projectId
					? getProjectContainerScope(projectId)
					: 'permissions-singleton'
			}
			isSharedView
			isEmbeddedView={isEmbeddedView}
			isCollectionView={isCollectionView}
			projectId={projectId}
			onLoadStart={jpdProjectPageLoadPermissionsStart}
			onLoadEnd={jpdProjectPageLoadPermissionsEnd}
		>
			{children}
		</PermissionsContainer>
	);
};

const SharingDataContainer = ({ children }: PropsWithChildren) => {
	const viewUUID = useCurrentViewUUID();
	const viewRemote = useViewRemote();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError, generalDataLoadingFailedError } = useErrorHandlers();
	const viewAri = useCurrentViewAri();
	const { loadViewComments } = useViewActions();

	const onLoadViewComments = useCallback(
		() => viewAri && loadViewComments(viewAri),
		[loadViewComments, viewAri],
	);

	return (
		<SharingContainer
			viewUUID={viewUUID}
			onActionFailed={generalActionFailedError}
			onLoadFailed={generalDataLoadingFailedError}
			viewRemote={viewRemote}
			createAnalyticsEvent={createAnalyticsEvent}
			onLoadViewComments={onLoadViewComments}
			isSharedView
		>
			{children}
		</SharingContainer>
	);
};

const EmojiDataContainer = ({ children }: PropsWithChildren) => {
	const { cloudId } = useTenantContext();
	const currentUser = useCurrentUser();
	return (
		<EmojiContainer cloudId={cloudId} currentUser={currentUser}>
			{children}
		</EmojiContainer>
	);
};

type DataContainerProps = PropsWithChildren<{ viewId: string; embedded: boolean }>;

export const DataContainer = ({ children, viewId, embedded }: DataContainerProps) => (
	<SharingRouteContainer>
		<PolarisEnvironmentContainer isSharedView embedded={embedded}>
			<UserContainer scope="user-singleton" baseUrl="">
				<FieldDataContainer>
					<ViewDataContainer viewId={viewId}>
						<SharedViewExperiencesStart />
						<PermissionsDataContainer>
							<SmartCardContainer>
								<IssueDataContainer>
									<WorkflowDataContainer>
										<SharingDataContainer>
											<EmojiDataContainer>{children}</EmojiDataContainer>
										</SharingDataContainer>
									</WorkflowDataContainer>
								</IssueDataContainer>
							</SmartCardContainer>
						</PermissionsDataContainer>
					</ViewDataContainer>
				</FieldDataContainer>
			</UserContainer>
		</PolarisEnvironmentContainer>
	</SharingRouteContainer>
);

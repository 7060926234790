import React from 'react';
import { styled } from '@compiled/react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { SkeletonItem } from '@atlassian/jira-spa-apps-common/src/skeletons/main.tsx';

const skeletonWidth = 500;

export const Skeleton = () => (
	<>
		<SkeletonItem width={skeletonWidth} height={gridSize * 2} />
		<Spacer margin={gridSize / 2} />
		<SkeletonItem width={skeletonWidth} height={gridSize * 2} />

		<Spacer margin={gridSize * 3} />
		<SkeletonItem width={skeletonWidth} height={gridSize * 5} />

		<Spacer margin={gridSize * 3} />
		<SkeletonItem width={skeletonWidth / 2} height={gridSize * 3} />
		<Spacer margin={gridSize} />
		<SkeletonItem width={skeletonWidth / 2} height={gridSize * 3} />
		<Spacer margin={gridSize} />
		<SkeletonItem width={skeletonWidth / 2} height={gridSize * 3} />
	</>
);

export default Skeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Spacer = styled.div<{ margin: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 6}px`,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: `${(props: any) => props.margin}px`,
});

import React from 'react';
import { ReactionsField as Reactions } from '@atlassian/jira-polaris-common/src/ui/fields/reactions/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type ReactionsFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const ReactionsField = ({ localIssueId, fieldKey }: ReactionsFieldProps) => (
	<Reactions localIssueId={localIssueId} fieldKey={fieldKey} />
);

import React from 'react';
import type { NewIconProps } from '@atlaskit/icon/types';

// This icon replaces the drag handle icon from the @atlaskit/icon. The icon from @atlasskit/icon is not suitable for the JPD UI.

type DragHandleThinProps = {
	color: NewIconProps['color'];
	label: NewIconProps['label'];
};

export const DragHandleThin = ({ color, label }: DragHandleThinProps) => {
	const fill = color || 'currentColor';

	return (
		<svg
			aria-label={label}
			xmlns="http://www.w3.org/2000/svg"
			width="6"
			height="10"
			viewBox="0 0 6 10"
			fill="none"
		>
			<circle cx="1" cy="1" r="1" fill={fill} />
			<circle cx="1" cy="5" r="1" fill={fill} />
			<circle cx="1" cy="9" r="1" fill={fill} />
			<circle cx="5" cy="1" r="1" fill={fill} />
			<circle cx="5" cy="5" r="1" fill={fill} />
			<circle cx="5" cy="9" r="1" fill={fill} />
		</svg>
	);
};

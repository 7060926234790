import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_CollabToken as CollabQuery } from './__generated_apollo__/jira_polaris_CollabToken';
import type { CollabConfig } from './types.tsx';

export const GET_POLARIS_COLLAB_QUERY = gqlTagPolaris`
query jira_polaris_CollabToken($view: ID!) {
    polarisCollabToken(viewID: $view) {
        token
        expires
    }
}
`;

export const ERROR_REASON_POSSIBLE_ACCESS_LOSS = 'POSSIBLE_ACCESS_LOSS';

export const getPolarisCollabToken = (
	client: PolarisApolloClient,
	viewAri: string,
	onCriticalError: (error: Error, reason: String, status: Number) => void,
): Promise<CollabConfig> =>
	client
		.query<CollabQuery>({
			query: GET_POLARIS_COLLAB_QUERY,
			variables: {
				view: viewAri,
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors) {
				onCriticalError?.(
					new Error('Could not get collab token from API'),
					ERROR_REASON_POSSIBLE_ACCESS_LOSS,
					403,
				);
				throw new Error('polaris-collab.fetch-error: possible site or project access loss');
			}

			if (!result.data || !result.data.polarisCollabToken) {
				throw new Error('polaris-collab.fetch-error: no data or no data node');
			}

			const { token, expires } = result.data.polarisCollabToken;

			return {
				token,
				expires,
			};
		});

import React from 'react';
import { styled } from '@compiled/react';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { FieldValue } from '../../../../common/ui/field/styled.tsx';
import { useIntervalDateDecoration } from '../../../../controllers/field/selectors/decoration/hooks.tsx';
import {
	useFieldOfType,
	useFieldLabel,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useAllExternalReferenceEntities } from '../../../../controllers/issue/selectors/external-reference-hooks.tsx';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import {
	useExternalReferenceValue,
	useStringValue,
	useSingleIssueDeliveryDate,
} from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { DecoratedDate } from '../../../decoration/date/index.tsx';
import { AtlasProjectTargetDate } from '../../../fields/atlas-project-date/index.tsx';
import { DeliveryDate } from '../../../fields/delivery-date/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel } from '../field/styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	disableLazyRendering?: boolean;
};

type InternalCommonProps = {
	label: string | undefined;
	datetime: string | undefined;
	decoration: ValueDecoration | undefined;
};

const IntervalDateFieldDefault = ({
	label,
	datetime,
	decoration,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	disableLazyRendering = false,
}: InternalCommonProps & Omit<Props, 'issueId' | 'fieldKey'>) => {
	if (hideEmpty && !datetime) {
		return null;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<StyledFieldValue>
				{datetime !== undefined ? (
					<DecoratedDate
						datetime={datetime}
						decoration={decoration}
						isCompact={isCompact}
						disableLazyRendering={disableLazyRendering}
					/>
				) : (
					isSummary && <EmptyField />
				)}
			</StyledFieldValue>
		</>
	);
};

const IntervalDateFieldAtlas = ({
	issueId,
	fieldKey,
	label,
	datetime,
	decoration,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	disableLazyRendering = false,
}: InternalCommonProps & Omit<Props, 'onValueChange'>) => {
	const atlasProjectField = useFieldOfType(FIELD_TYPES.ATLAS_PROJECT);
	const allExternalReferenceEntities = useAllExternalReferenceEntities();
	const externalReferenceValue = useExternalReferenceValue(atlasProjectField?.key, issueId);
	const date =
		typeof externalReferenceValue === 'string'
			? allExternalReferenceEntities[externalReferenceValue]?.dueDate?.dateRange
			: undefined;

	if (hideEmpty && (!date?.start || !date?.end) && !datetime) {
		return null;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<StyledFieldValue>
				{isSummary && !((date?.start && date?.end) || datetime) ? (
					<EmptyField />
				) : (
					<AtlasProjectTargetDate
						fieldKey={fieldKey}
						issueId={issueId}
						renderFallback={
							<DecoratedDate
								datetime={datetime}
								decoration={decoration}
								isCompact={isCompact}
								disableLazyRendering={disableLazyRendering}
							/>
						}
					/>
				)}
			</StyledFieldValue>
		</>
	);
};

const DeliveryDateField = ({
	issueId,
	fieldKey,
	label,
	datetime,
	decoration,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	disableLazyRendering = false,
}: InternalCommonProps & Omit<Props, 'onValueChange'>) => {
	const field = useField(fieldKey);
	const date = useSingleIssueDeliveryDate(
		issueId,
		field?.configuration?.customfieldKey,
		field?.configuration?.aggregationType,
	);

	if (hideEmpty && !date && !datetime) {
		return null;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<StyledFieldValue>
				{isSummary && !(datetime || date) ? (
					<EmptyField />
				) : (
					<DeliveryDate
						fieldKey={fieldKey}
						issueId={issueId}
						renderFallback={
							datetime ? (
								<DecoratedDate
									datetime={datetime}
									decoration={decoration}
									isCompact={isCompact}
									disableLazyRendering={disableLazyRendering}
								/>
							) : null
						}
						hideEmpty={hideEmpty}
					/>
				)}
			</StyledFieldValue>
		</>
	);
};

export const IntervalDateField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	disableLazyRendering = false,
}: Props) => {
	const { locale } = useTenantContext();
	const label = useFieldLabel(fieldKey);
	const field = useField(fieldKey);
	const value = useStringValue(fieldKey, issueId);
	const parsedDate = value ? JSON.parse(value) : value;
	const decoration = useIntervalDateDecoration(fieldKey, parsedDate, locale);
	const datetime = renderDateString(parsedDate);
	const isAtlasProjectStartDate =
		field?.configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE;

	const isDeliveryDate = field?.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE;

	if (isDeliveryDate) {
		return (
			<DeliveryDateField
				issueId={issueId}
				fieldKey={fieldKey}
				label={label}
				datetime={datetime}
				decoration={decoration}
				isCompact={isCompact}
				isSummary={isSummary}
				hideEmpty={hideEmpty}
				disableLazyRendering={disableLazyRendering}
			/>
		);
	}

	if (isAtlasProjectStartDate) {
		return (
			<IntervalDateFieldAtlas
				issueId={issueId}
				fieldKey={fieldKey}
				label={label}
				datetime={datetime}
				decoration={decoration}
				isCompact={isCompact}
				isSummary={isSummary}
				hideEmpty={hideEmpty}
				disableLazyRendering={disableLazyRendering}
			/>
		);
	}

	return (
		<IntervalDateFieldDefault
			label={label}
			datetime={datetime}
			decoration={decoration}
			isCompact={isCompact}
			isSummary={isSummary}
			hideEmpty={hideEmpty}
			disableLazyRendering={disableLazyRendering}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledFieldValue = styled(FieldValue)({
	display: 'flex',
	alignItems: 'center',
});

import React from 'react';
import { xcss, Box } from '@atlaskit/primitives';

export type EmptyColumnProps = {
	children: React.ReactNode;
};

export const EmptyColumn = ({ children }: EmptyColumnProps) => {
	return (
		<Box xcss={emptyColumnPlaceholderContainerStyles}>
			<Box xcss={emptyColumnPlaceholderContentStyles}>{children}</Box>
		</Box>
	);
};

const emptyColumnPlaceholderContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	color: 'color.text.disabled',
	alignItems: 'center',
	marginRight: 'space.200',
});

const emptyColumnPlaceholderContentStyles = xcss({
	display: 'flex',
	width: '187px',
	alignItems: 'center',
	textAlign: 'center',
	justifyContent: 'center',
	marginTop: 'space.250',
});

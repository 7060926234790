import React from 'react';
import type { ColumnShape } from 'react-base-table';
import { useConnectionFieldHighlightedFieldKey } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { SelectedFieldCellRenderer } from './cells/selected-field/index.tsx';
import { IssueSummaryCellRenderer } from './cells/summary/index.tsx';
import { FieldSelect } from './header/field-select/index.tsx';
import { HeaderCellRenderer } from './header/index.tsx';
import type { RowData } from './types.tsx';

export const useColumns = (fieldKey: FieldKey): ColumnShape<RowData>[] => {
	const highlightedFieldKey = useConnectionFieldHighlightedFieldKey(fieldKey);

	const highlightedFieldColumn: ColumnShape<RowData> | undefined = highlightedFieldKey
		? {
				key: highlightedFieldKey,
				width: 0,
				flexGrow: 1,
				headerRenderer: () => (
					<HeaderCellRenderer connectionFieldKey={fieldKey} cellFieldKey={highlightedFieldKey} />
				),
				cellRenderer: ({ rowData }) => (
					<SelectedFieldCellRenderer
						fieldKey={highlightedFieldKey}
						issueId={parseInt(rowData.id, 10)}
					/>
				),
			}
		: {
				key: 'add-highlighted-field',
				width: 38,
				headerRenderer: () => <FieldSelect connectionFieldKey={fieldKey} />,
			};

	return [
		{
			key: SUMMARY_FIELDKEY,
			width: 0,
			flexGrow: 2,
			headerRenderer: () => (
				<HeaderCellRenderer connectionFieldKey={fieldKey} cellFieldKey={SUMMARY_FIELDKEY} />
			),
			cellRenderer: ({ rowData }) => (
				<IssueSummaryCellRenderer issueId={parseInt(rowData.id, 10)} />
			),
		},
		highlightedFieldColumn,
	];
};

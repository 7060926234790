import React from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import TextArea from '@atlaskit/textarea';
import TextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '../common/consts.tsx';
import { IconField } from './icon-field.tsx';
import { messages } from './messages.tsx';
import type { FormData } from './types.tsx';

type Props = {
	defaultValues: FormData;
	isEditable: boolean;
};

export const IssueTypeFormFields = ({ defaultValues, isEditable }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Field
				aria-required
				name="name"
				label={formatMessage(messages.name)}
				isRequired
				isDisabled={!isEditable}
				defaultValue={defaultValues.name}
				validate={(value) => {
					const content = (value ?? '').trim();
					if (content.length === 0) {
						return formatMessage(messages.nameValidationErrorNoEmptyValue);
					}
				}}
			>
				{({ fieldProps, error }) => (
					<>
						<TextField autoComplete="off" {...fieldProps} maxLength={MAX_NAME_LENGTH} />
						{error && <ErrorMessage>{error}</ErrorMessage>}
					</>
				)}
			</Field>

			<Field
				name="avatarId"
				label={formatMessage(messages.icon)}
				defaultValue={defaultValues.avatarId}
				isDisabled={!isEditable}
			>
				{({ fieldProps }) => (
					<IconField
						avatarId={fieldProps.value}
						{...fieldProps}
						onChange={(avatarId) => fieldProps.onChange(avatarId)}
					/>
				)}
			</Field>

			<Field
				aria-required
				name="description"
				label={formatMessage(messages.description)}
				defaultValue={defaultValues.description}
				isDisabled={!isEditable}
			>
				{({ fieldProps }) => {
					return (
						<TextArea
							resize="auto"
							maxHeight="20vh"
							{...fieldProps}
							onChange={(event) => fieldProps.onChange(event.target.value)}
							placeholder={formatMessage(messages.descriptionPlaceholder)}
							maxLength={MAX_DESCRIPTION_LENGTH}
						/>
					);
				}}
			</Field>
		</>
	);
};

import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './controllers/types.tsx';
import type { ProjectFormsSettings } from './ui/main.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Components, DescriptionTemplatesProps } from './controllers/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPolarisFormsSettingsScreen = lazyForPaint<typeof ProjectFormsSettings>(
	() => import(/* webpackChunkName: "async-polaris-project-forms-settings" */ './ui'),
	{ ssr: false },
);

const SafeLazyPolarisFormsSettingsScreen = (props: Props) => (
	<JSErrorBoundary
		fallback="flag"
		id="async.jira.polaris.project-settings"
		packageName="atlassian/jira-polaris-lib-project-access"
	>
		<Placeholder name="polaris-project-forms-settings" fallback={<></>}>
			<LazyPolarisFormsSettingsScreen {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

SafeLazyPolarisFormsSettingsScreen.defaultProps = {
	breadcrumbs: undefined,
};

export default SafeLazyPolarisFormsSettingsScreen;

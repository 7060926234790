/**
 * Copied to
 * @see src/packages/polaris/lib/board/src/utils/board.tsx
 *
 * Remove duplicated code when cleaning up feature flag
 * `polaris_board_lib_refactoring`
 */

export const EMPTY_VALUE_ID = 'empty';
export const VALUE_COLUMN_ID_PREFIX = '+';
export const COLUMN_DROPPABLE_ID = 'board';
export const ROW_DROPPABLE_ID = 'row';

// TODO move to different place (used by board and list)
export type ExtendedOption<TValue> = {
	groupIdentity: string | undefined;
	droppableId: string;
	value?: TValue | undefined;
};

export const getGroupIdentityForDroppableId = (droppableId?: string): string | undefined => {
	if (droppableId === EMPTY_VALUE_ID) {
		return undefined;
	}
	return droppableId !== undefined
		? droppableId.substring(VALUE_COLUMN_ID_PREFIX.length)
		: undefined;
};

export const getGroupIdentityPairForDroppableId = (droppableId?: string) => {
	if (droppableId !== undefined && droppableId.indexOf(VALUE_COLUMN_ID_PREFIX) === 0) {
		try {
			const result = JSON.parse(droppableId.substring(VALUE_COLUMN_ID_PREFIX.length));
			if (Array.isArray(result) && result.length === 2) {
				const [realRowId, realColumnId] = result;
				if (realRowId && realColumnId) {
					return [
						getGroupIdentityForDroppableId(realRowId),
						getGroupIdentityForDroppableId(realColumnId),
					];
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			// do nothing
		}
	}
	return [];
};

export const createGroupIdentityPair = (droppableId: string, verticalDroppableId: string): string =>
	`+${JSON.stringify([droppableId, verticalDroppableId])}`;

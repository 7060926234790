import React, { useMemo, type ComponentPropsWithoutRef } from 'react';
import { styled, keyframes } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { N200, N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import type { CommentUser } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type { PolarisPlayContributionComment } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { FormattedDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerPropsForComment } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '../../../common/constants.tsx';
import messages from './messages.tsx';

const SEPARATOR = '・';

type Props = {
	comment: PolarisPlayContributionComment;
	votes: number;
	onActivateEdit?: () => void;
	author: CommentUser;
};

const ContributionHeaderAuthorDetails = (
	props: ComponentPropsWithoutRef<typeof ContributionHeaderAuthorDetailsComponent>,
) => (
	<Heading size="xsmall">
		<ContributionHeaderAuthorDetailsComponent {...props} />
	</Heading>
);

const VoteIconWrapper = (props: ComponentPropsWithoutRef<typeof VoteIconWrapperComponent>) => (
	<Heading size="xxsmall">
		<VoteIconWrapperComponent {...props} />
	</Heading>
);

export const PlayContributionComponent = ({ comment, votes, author, onActivateEdit }: Props) => {
	const { formatMessage } = useIntl();

	const safeCommentBody = useMemo(() => prepareForRender(comment.body), [comment.body]);

	return (
		<ContributionContainer
			data-testid="polaris-common.ui.plays.contribution.contribution-container"
			data-component-selector="contribution-container-xU79"
		>
			<ContributionHeaderContainer>
				<div>
					<Avatar src={author.avatarUrls['32x32']} size="medium" />
				</div>
				<ContributionHeaderDetailsContainer>
					<ContributionHeaderAuthorDetails data-testid="polaris-common.ui.plays.contribution.contribution-header-author-details">
						{author.displayName}
					</ContributionHeaderAuthorDetails>
					<ContributionHeaderDetails>
						<div>
							<FormattedDate date={comment.created} />
						</div>
						{comment.updated !== comment.created && (
							<div>
								{SEPARATOR}
								{formatMessage(messages.commentEdited)}
							</div>
						)}
					</ContributionHeaderDetails>
				</ContributionHeaderDetailsContainer>
				<VoteIconWrapper data-testid="polaris-common.ui.plays.contribution.vote-icon-wrapper">
					<LikeIcon size="small" label="" />
					{votes}
				</VoteIconWrapper>
			</ContributionHeaderContainer>
			<ContributionContentContainer>
				<CommentWrapper data-testid="polaris-common.ui.plays.contribution.comment-wrapper">
					<WaitForAdfConsumerPropsForComment commentId={comment.id}>
						{({ akRendererProps }) => (
							<AkRenderer
								{...akRendererProps}
								appearance="comment"
								document={safeCommentBody}
								analyticsEventSeverityTracking={{
									enabled: true,
									severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
									severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
								}}
								unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
								useSpecBasedValidator
								disableHeadingIDs
								eventHandlers={{
									smartCard: { onClick: onLinkClick },
									link: { onClick: onLinkClick },
								}}
								featureFlags={{
									codeBidiWarnings: true,
									'code-bidi-warnings': true,
									'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
								}}
								smartLinks={smartLinksDefault}
								UNSTABLE_allowTableResizing={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
								UNSTABLE_allowTableAlignment={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
							/>
						)}
					</WaitForAdfConsumerPropsForComment>
				</CommentWrapper>
			</ContributionContentContainer>
			{onActivateEdit && (
				<Tooltip content={formatMessage(messages.editVoteTooltip)}>
					<EditButtonWrapper>
						<Button
							testId="polaris-common.ui.plays.contribution.button"
							onClick={onActivateEdit}
							iconAfter={
								<EditorEditIcon label={formatMessage(messages.editVoteTooltip)} size="medium" />
							}
							appearance="subtle"
							spacing="none"
						/>
					</EditButtonWrapper>
				</Tooltip>
			)}
		</ContributionContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionContainer = styled.div({
	padding: `${token('space.150', '12px')} 0`,
	width: '100%',
	position: 'relative',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionHeaderContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	marginBottom: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionHeaderDetailsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionHeaderDetails = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '3px',
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionContentContainer = styled.div({
	display: 'grid',
	gridTemplateColumns: '36px 1fr',
	gridTemplateRows: '1fr',
	gap: `0px ${token('space.025', '2px')}`,
	gridTemplateAreas: '"vote comment"',
	justifyItems: 'normal',
	alignItems: 'start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentWrapper = styled.div({
	gridArea: 'comment',
	marginLeft: token('space.050', '4px'),
});

const animationFadeIn = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditButtonWrapper = styled.div({
	position: 'absolute',
	top: token('space.200', '16px'),
	right: token('space.200', '16px'),
	display: 'none',
	opacity: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="contribution-container-xU79"]:hover &': {
		display: 'flex',
		animation: `${animationFadeIn} 150ms`,
		animationDelay: '300ms',
		animationFillMode: 'forwards',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionHeaderAuthorDetailsComponent = styled.div({
	color: token('color.text', N900),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '16px',
	marginTop: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VoteIconWrapperComponent = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	margin: 0,
	color: token('color.text.subtlest', N200),
});

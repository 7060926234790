import React, { useCallback, memo, type SyntheticEvent, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import startsWith from 'lodash/startsWith';
import Button from '@atlaskit/button';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events/index.tsx';
import { useLocalIssueIdForJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	usePolarisRouter,
	useIssueViewLayout,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { StatusField } from '@atlassian/jira-polaris-common/src/ui/fields/status/index.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { StatusCategoryKey } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

type RelatedIssue = {
	issueId: number;
	isArchived: boolean;
	issueKey: string;
	summary: string;
	status: {
		categoryKey: StatusCategoryKey;
		categoryId: number;
		categoryName: string;
		categoryColorName: string;
		statusId: string;
		statusName: string;
	};
	issueType: {
		id: string;
		name: string;
		iconUrl: string;
		subtask: boolean;
	};
};

type Props = {
	issue: RelatedIssue;
	isLoadingArchived?: boolean;
	isUnlinking?: boolean;
	onRemoveIssueLink?: () => void;
};

const KeyLinkComponent = ({ issueKey, href }: { issueKey: string; href: string }) => (
	<IssueKeyWrapper>
		<IssueKey title={issueKey} href={href}>
			{issueKey}
		</IssueKey>
	</IssueKeyWrapper>
);

export const RelatedIssue = memo<Props>((props: Props) => {
	const { issue, onRemoveIssueLink, isUnlinking, isLoadingArchived } = props;
	const { formatMessage } = useIntl();
	const onStatusSubmit = useCallback(() => undefined, []);
	const projectKey = useProjectKeyUnsafe();
	const issueViewLayout = useIssueViewLayout();
	const { openIssueView } = usePolarisRouter();
	const localIssueId = useLocalIssueIdForJiraIssueId(toIssueId(issue.issueId.toString()));
	const issueKey = toIssueKey(issue.issueKey);
	const href = `/browse/${issueKey}`;

	const handleRemoveIssueLink = useCallback(
		(e: SyntheticEvent) => {
			e.stopPropagation();
			onRemoveIssueLink && onRemoveIssueLink();
		},
		[onRemoveIssueLink],
	);

	const handleOpenLinkedIssue = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			if (
				!startsWith(issueKey, `${projectKey}-`) ||
				localIssueId === undefined ||
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				shouldOpenInNewTab(e as MouseEvent)
			) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(href, '_blank');
				return;
			}
			openIssueView(issueKey, { layout: issueViewLayout });
		},
		[href, issueKey, issueViewLayout, localIssueId, openIssueView, projectKey],
	);

	// FIXME: Polaris - fix this by extracting memoed component
	// eslint-disable-next-line react/no-unstable-nested-components
	const Status = memo<RelatedIssue['status']>((statusFieldProps) => {
		const { statusId, statusName, categoryId, categoryKey, categoryName } = statusFieldProps;
		const statusValue = {
			id: statusId,
			name: statusName,
			statusCategory: {
				id: categoryId,
				key: categoryKey,
				name: categoryName,
			},
		};
		return (
			<StatusField
				issueId={String(issue?.issueId ?? '')}
				issueKey={issueKey}
				isEditable={false}
				value={statusValue}
				onSubmit={onStatusSubmit}
			/>
		);
	});

	let status = null;

	switch (true) {
		case isLoadingArchived:
			status = null;
			break;
		case issue.isArchived:
			status = <Lozenge>{formatMessage(messages.archivedLabel)}</Lozenge>;
			break;
		default:
			status = (
				<Box xcss={statusFieldContainerStyles}>
					<Status {...issue.status} />
				</Box>
			);
			break;
	}

	return (
		<IssueContainer
			key={issue.issueId}
			data-testid="polaris-ideas.common.ui.related-issue.issue-container"
			data-component-selector="issue-container-tZ89"
			onClick={handleOpenLinkedIssue}
		>
			<RelatedIssueType
				title={issue.issueType.name}
				src={issue.issueType.iconUrl}
				alt={issue.issueType.name}
			/>
			<KeyLinkComponent issueKey={issue.issueKey} href={href} />
			<Summary title={issue.summary} href={href}>
				{issue.summary}
			</Summary>
			{status !== null ? <div>{status}</div> : null}
			{onRemoveIssueLink !== undefined ? (
				<>
					{isUnlinking !== true && (
						<Tooltip
							content={formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.removeLinkTooltipIssueTermRefresh
									: messages.removeLinkTooltip,
							)}
						>
							<RemoveLink
								testId="polaris-ideas.common.ui.related-issue.remove-link"
								onClick={handleRemoveIssueLink}
								iconBefore={<EditorCloseIcon label="remove link" />}
								appearance="subtle"
								spacing="none"
							/>
						</Tooltip>
					)}
					{isUnlinking === true && (
						<LoadingWrapper>
							<Spinner size="xsmall" />
						</LoadingWrapper>
					)}
				</>
			) : (
				<Box xcss={spacingStyles} />
			)}
		</IssueContainer>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueContainer = styled.div({
	borderBottom: `1px solid ${token('color.border', '#ebecf0')}`,
	display: 'flex',
	paddingLeft: token('space.200', '16px'),
	height: '40px',
	alignItems: 'center',
	transition: '0.3s',
	cursor: 'pointer',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RelatedIssueType = styled.img({
	width: '16px',
	height: '16px',
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKeyWrapper = styled.div({
	width: '80px',
	padding: `0 ${token('space.050', '4px')} 0 ${token('space.025', '2px')}`,
	flexShrink: 0,
	flexGrow: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKey = styled.a({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: 'block',
	'&:hover, &:active, &:visited, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N100),
		boxShadow: 0,
		border: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.a({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	marginRight: 'auto',
	flexShrink: 1000,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N800),
	},
});

const statusFieldContainerStyles = xcss({
	marginLeft: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '24px',
	marginLeft: token('space.050', '4px'),
	marginRight: token('space.100', '8px'),
	justifyContent: 'center',
});

const spacingStyles = xcss({
	marginRight: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoveLink = styled(Button)({
	visibility: 'hidden',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="issue-container-tZ89"]:hover &': {
		visibility: 'visible',
	},
	width: '24px',
	lineHeight: 1,
	marginTop: token('space.050', '4px'),
	marginLeft: token('space.050', '4px'),
	marginRight: token('space.100', '8px'),
});

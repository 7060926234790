import { notImplementedError } from '../../common/utils.tsx';
import { addWatcher } from '../../services/jira/watchers/add/index.tsx';
import { deleteWatcher } from '../../services/jira/watchers/delete/index.tsx';
import { getAssignableWatchers, getWatchers } from '../../services/jira/watchers/get/index.tsx';
import type { IssueWatchersRemote } from './types.tsx';

export const createNotImplementedIssueWatchersRemote = (): IssueWatchersRemote => ({
	createIssueWatcher: notImplementedError('createIssueWatcher'),
	fetchIssueWatchers: notImplementedError('fetchIssueWatchers'),
	deleteIssueWatcher: notImplementedError('deleteIssueWatcher'),
	fetchAssignableWatchers: notImplementedError('fetchAssignableWatchers'),
});

export const createSharingIssueWatchersRemote = (): IssueWatchersRemote => ({
	createIssueWatcher: notImplementedError('createIssueWatcher'),
	fetchIssueWatchers: () => Promise.resolve({ isWatching: false, watchCount: 0, watchers: [] }),
	deleteIssueWatcher: notImplementedError('deleteIssueWatcher'),
	fetchAssignableWatchers: notImplementedError('fetchAssignableWatchers'),
});

export const createIssueWatchersRemote = (): IssueWatchersRemote => ({
	createIssueWatcher: ({ issueIdOrKey, accountId }) => addWatcher(issueIdOrKey, accountId),
	fetchIssueWatchers: ({ issueIdOrKey }) => getWatchers(issueIdOrKey),
	deleteIssueWatcher: ({ issueIdOrKey, accountId }) => deleteWatcher(issueIdOrKey, accountId),
	fetchAssignableWatchers: (request) => getAssignableWatchers(request),
});

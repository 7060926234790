import { treeItemDataPrivateKey } from './constants.tsx';
import type { TreeItemData } from './types.tsx';

export const getTreeItemData = (data: Omit<TreeItemData, typeof treeItemDataPrivateKey>) => ({
	[treeItemDataPrivateKey]: true,
	...data,
});

export const isTreeItemData = (data: Record<string | symbol, unknown>): data is TreeItemData =>
	Boolean(data[treeItemDataPrivateKey]);

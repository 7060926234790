import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '../../../modal-transition/index.tsx';
import slackHowTo from '../../assets/slack-howto.svg';
import messages from './messages.tsx';

type SlackInfoDialogProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const SlackHowToDialog = ({ isOpen, onClose }: SlackInfoDialogProps) => {
	const { formatMessage } = useIntl();
	return (
		<ModalTransitionUniversal>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog onClose={onClose}>
						<ContentWrapper>
							{/* eslint-disable-next-line jsx-a11y/alt-text */}
							<img src={slackHowTo} />
							<MainContentArea>
								{formatMessage(messages.preInstruction)}
								<InstallArea>
									<a
										href="https://marketplace.atlassian.com/apps/1216863/jira-cloud-for-slack-official?hosting=cloud&tab=overview"
										target="_blank"
									>
										<Button>{formatMessage(messages.installCaption)}</Button>
									</a>
								</InstallArea>
								{formatMessage(
									fg('polaris-issue-terminology-refresh')
										? messages.postInstructionIssueTermRefresh
										: messages.postInstruction,
								)}
							</MainContentArea>
							<ActionArea>
								<Button onClick={onClose}>{formatMessage(messages.close)}</Button>
							</ActionArea>
						</ContentWrapper>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransitionUniversal>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentArea = styled.div({
	padding: token('space.300', '24px'),
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InstallArea = styled.div({
	marginTop: token('space.100', '8px'),
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'*': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		textDecoration: 'none !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		boxShadow: 'none !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionArea = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
});

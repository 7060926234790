import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { copyInsights as copyInsightsCall } from '../../services/polaris-api/copy-insights/index.tsx';
import type { RemoteInsightsCopier, CopyInsightsRequest, ProviderProps } from '../../types.tsx';

export const copyInsights =
	({ apolloClient, cloudId }: Omit<ProviderProps, 'projectId'>): RemoteInsightsCopier =>
	({ source, destination, insights }: CopyInsightsRequest) =>
		copyInsightsCall(
			apolloClient,
			{
				project: createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'project',
					resourceId: source.projectId,
				}),
				container: source.issueId
					? createAri({
							resourceOwner: 'jira',
							cloudId,
							resourceType: 'issue',
							resourceId: source.issueId,
						})
					: undefined,
			},
			{
				project: createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'project',
					resourceId: destination.projectId,
				}),
				container: destination.issueId
					? createAri({
							resourceOwner: 'jira',
							cloudId,
							resourceType: 'issue',
							resourceId: destination.issueId,
						})
					: undefined,
			},
			insights,
		);

import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import InlineDialog from '@atlaskit/inline-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { Y300, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import messages from './messages.tsx';

const SEPARATOR = '・';

type FooterProps = {
	contributionId: Ari;
	onEditRequested: () => void;
	onDeleteRequested: () => void;
};

type DeleteActionProps = {
	contributionId: string;
	onDeleteRequested: (arg1: string) => void;
};

const DeleteAction = ({ contributionId, onDeleteRequested }: DeleteActionProps) => {
	const { formatMessage } = useIntl();
	const [inDelete, setInDelete] = useState(false);

	const handleDelete = useCallback(() => {
		setInDelete(true);
	}, []);

	const performDelete = useCallback(() => {
		onDeleteRequested(contributionId);
		setInDelete(false);
	}, [contributionId, onDeleteRequested]);

	const cancelDelete = useCallback(() => {
		setInDelete(false);
	}, []);

	return (
		<div>
			<InlineDialog
				testId="polaris-common.ui.plays.add-contribution.footer.inline-dialog-content"
				onClose={cancelDelete}
				isOpen={inDelete}
				placement="auto"
				content={
					<DeleteConfirmContentContainer>
						<DeleteConfirmHeaderContainer>
							{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
							<WarningIcon label="error" primaryColor={Y300} />
							{formatMessage(messages.deleteContributionWarningHeader)}
						</DeleteConfirmHeaderContainer>
						<Box xcss={deleteConfirmMessageContainerStyles}>
							{formatMessage(messages.deleteContributionWarning)}
						</Box>
						<DeleteConfirmActionsContainer>
							<Button onClick={cancelDelete}>{formatMessage(messages.cancel)}</Button>
							<Button appearance="warning" onClick={performDelete}>
								{formatMessage(messages.deleteContribution)}
							</Button>
						</DeleteConfirmActionsContainer>
					</DeleteConfirmContentContainer>
				}
			>
				<Button
					testId="polaris-common.ui.plays.add-contribution.footer.delete"
					spacing="none"
					appearance="subtle-link"
					onClick={handleDelete}
				>
					{formatMessage(messages.deleteContribution)}
				</Button>
			</InlineDialog>
		</div>
	);
};

export const PlayContributionFooterComponent = ({
	contributionId,
	onEditRequested,
	onDeleteRequested,
}: FooterProps) => {
	const { formatMessage } = useIntl();

	return (
		<ContributionFooterContainer>
			<ControlsContainer>
				<div>
					<Button
						testId="polaris-common.ui.plays.add-contribution.footer.edit"
						spacing="none"
						appearance="subtle-link"
						onClick={onEditRequested}
					>
						{formatMessage(messages.editContribution)}
					</Button>
				</div>
				{SEPARATOR}
				<div>
					<DeleteAction contributionId={contributionId} onDeleteRequested={onDeleteRequested} />
				</div>
			</ControlsContainer>
		</ContributionFooterContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionFooterContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginBottom: token('space.075', '6px'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsContainer = styled.div({
	marginRight: token('space.100', '8px'),
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	alignItems: 'center',
	color: N200,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteConfirmContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteConfirmHeaderContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	fontWeight: token('font.weight.bold'),
});

const deleteConfirmMessageContainerStyles = xcss({
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteConfirmActionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginTop: token('space.200', '16px'),
	alignItems: 'center',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		marginLeft: token('space.075', '6px'),
	},
});

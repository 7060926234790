/**
 * This is the base for Picker that can be used to create any type of component:
 * 1) with a list of predefined options
 * 2) that dynamically fetches its options from external resource.
 */

import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import Picker, { type Props } from './base/index.tsx';
import WithAvatar from './with-avatar/index.tsx';

export type PickerProps<Option> = Props<Option>;

export const PickerWithAvatar = WithAvatar;

export const PickerWithAvatarAndAnalytics = ComponentWithAnalytics('picker', {
	onSelected: 'changed',
	onMultiSelected: 'multi-changed',
})(WithAvatar);

export const PickerWithAnalytics = ComponentWithAnalytics('picker', {
	onSelected: 'changed',
	onMultiSelected: 'multi-changed',
})(Picker);

export type SelectOption = {
	readonly label: string;
	readonly value: string;
};

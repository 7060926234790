import React from 'react';
import { InlineScripts } from '@atlassian/jira-polaris-common/src/ui/inline-scripts/index.tsx';
import { PolarisCustomEnvironmentContainerProvider } from '@atlassian/jira-polaris-component-environment-container/src/controllers/custom/index.tsx';
import { useIsEnvironmentContainerAvailable } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import { PolarisIdeas } from '@atlassian/jira-polaris-ideas/src/ui/main.tsx';
import { TrackActivity } from '@atlassian/jira-polaris-lib-activity-metric/src/index.tsx';
import { TrackFetchErrors } from '@atlassian/jira-polaris-lib-fetch-metric/src/index.tsx';
import { RealtimeQueueContextProvider } from '@atlassian/jira-polaris-lib-realtime-queue/src/utils/context/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { usePathParam, useQueryParam } from '@atlassian/react-resource-router';
import { DataContainer } from './controllers/index.tsx';
import { SharingAppRemoteContext } from './controllers/remotes/index.tsx';

const SafePolarisIdeas = () => {
	const isEnvironmentContainerAvailable = useIsEnvironmentContainerAvailable();

	return <>{isEnvironmentContainerAvailable && <PolarisIdeas />}</>;
};

const ShareView = () => {
	const [viewId] = usePathParam('resource');
	const [embedded] = useQueryParam('embedded');

	if (viewId === undefined) {
		return null;
	}

	const isEmbedded = embedded === 'true';

	return (
		<ContextualAnalyticsData
			objectType="shared-view"
			objectId={viewId}
			attributes={{
				viewId,
			}}
		>
			<RealtimeQueueContextProvider>
				<TrackActivity>{({ onFetch }) => <TrackFetchErrors onFetch={onFetch} />}</TrackActivity>
				<SharingAppRemoteContext viewId={viewId}>
					<MarkProductStart />
					<PolarisCustomEnvironmentContainerProvider
						id={`sharing-app-project-environment-${viewId}`}
						type={PolarisEnvironmentContainerTypes.VIEW}
						viewId={viewId}
					/>
					<DataContainer viewId={viewId} embedded={isEmbedded}>
						<InlineScripts embedded={isEmbedded} />
						<SafePolarisIdeas />
					</DataContainer>
				</SharingAppRemoteContext>
			</RealtimeQueueContextProvider>
		</ContextualAnalyticsData>
	);
};

export default ShareView;

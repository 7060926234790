import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { createHigherLevelIssueHook3 } from '../utils.tsx';
import { createGetFieldItemValuesSelector } from './matrix.tsx';

export const useFieldItemValues = createHigherLevelIssueHook3<
	FieldKey | undefined,
	LocalIssueId[],
	boolean | undefined,
	Partial<Record<LocalIssueId, number | string>>
>(createGetFieldItemValuesSelector);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchHint: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.search-hint',
		defaultMessage: 'Search',
		description: 'Search hint for the search bar in a generic filter dropdown',
	},
	noMatchesIndicator: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.no-matches-indicator',
		defaultMessage: 'No matches found',
		description: 'Indicator that no options are visible in the dropdown.',
	},
	roadmapNoOptions: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.roadmap-no-options',
		defaultMessage: 'All global and system fields are in use',
		description: 'Indicator that no options are visible in the dropdown on roadmap views.',
	},
	createOption: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.create-option',
		defaultMessage: 'Create {value}',
		description: 'Option to create a new option',
	},
	creatableSelectHelperText: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.creatable-select-helper-text',
		defaultMessage: 'Select a field or create one',
		description: "Helper text for the select when it's creatable",
	},
});

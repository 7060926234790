import { useEffect } from 'react';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIsCurrentViewPublicSharingEnabled,
	useIsSitePublicSharingEnabled,
	useIsCurrentViewSharingSettingsInitialLoading,
	useIsSitePublicSharingLoading,
} from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import { useCurrentViewKind } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

export const PublishViewAnalyticsHandler = () => {
	const isCurrentViewPublicSharingEnabled = useIsCurrentViewPublicSharingEnabled();
	const isSharingSettingsLoading = useIsCurrentViewSharingSettingsInitialLoading();
	const isSitePublicSharingEnabled = useIsSitePublicSharingEnabled();
	const isSitePublicSharingLoading = useIsSitePublicSharingLoading();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const viewKind = useCurrentViewKind();
	const isSharedView = useIsSharedView();
	const isLoggedIn = useAccountId() !== null;

	const isCurrentViewOpenedAsPublic = isLoggedIn
		? isCurrentViewPublicSharingEnabled && isSitePublicSharingEnabled
		: isCurrentViewPublicSharingEnabled;
	const shouldFireEvents =
		viewKind && isSharedView && !isSharingSettingsLoading && !isSitePublicSharingLoading;

	useEffect(() => {
		if (shouldFireEvents) {
			const actionSubjectAndAction = 'publishedView viewed';
			const attributes = {
				viewKind,
				isPublic: isCurrentViewOpenedAsPublic,
			};
			sendPendoTrackEvent({ actionSubjectAndAction, attributes });
			fireTrackAnalytics(createAnalyticsEvent({}), actionSubjectAndAction, attributes);
		}
	}, [createAnalyticsEvent, isCurrentViewOpenedAsPublic, shouldFireEvents, viewKind]);

	return null;
};

import React, { useEffect, type ComponentPropsWithoutRef } from 'react';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { startPulseAnimation } from './utils.tsx';

type Props = ComponentPropsWithoutRef<typeof SpotlightPulse> & {
	target?: HTMLElement | SVGElement | null;
};

export const Pulse = ({ target, children, pulse = true, radius, ...pulseProps }: Props) => {
	const isHTMLElement = target instanceof Element;

	useEffect(() => {
		if (isHTMLElement && pulse) {
			return startPulseAnimation(target);
		}

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}, [isHTMLElement, target, pulse, radius]);

	if (isHTMLElement || target === null) {
		return null;
	}

	return (
		<SpotlightPulse pulse={pulse} radius={radius} {...pulseProps}>
			{children}
		</SpotlightPulse>
	);
};

import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fetchViewRanks } from '../../services/polaris-api/fetch-view-ranks/index.tsx';
import { updateViewRank } from '../../services/polaris-api/update-view-rank/index.tsx';

export const fetchAndTransformViewRanks = (apolloClient: PolarisApolloClient, projectAri: Ari) =>
	fetchViewRanks(apolloClient, projectAri).then((response) =>
		response.viewsets
			? {
					viewsets: [...response.viewsets].map((viewset) => ({
						...viewset,
						views: [...viewset.views],
						viewsets: [...viewset.viewsets].map((innerViewset) => ({
							...innerViewset,
							views: [...innerViewset.views],
						})),
					})),
				}
			: { viewsets: null },
	);

export const updateAndTransformViewRank = (
	apolloClient: PolarisApolloClient,
	props: { viewId: string; rank: number; containerId: Ari },
) =>
	updateViewRank(apolloClient, props).then((response) => ({
		views: [...response.node.views],
		viewsets: [...response.node.viewsets].map((viewset) => ({
			...viewset,
			views: [...viewset.views],
		})),
	}));

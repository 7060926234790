import { memoizedFetchSitePersonalization } from '@atlassian/jira-personalization-service/src/services/site/index.tsx';
import type { Trait } from '@atlassian/jira-personalization-service/src/types.tsx';

export const isJPDLicenseMoreThan1DayOld = async (cloudId: string) => {
	const siteTraits = await memoizedFetchSitePersonalization({ cloudId });
	return checkIfJPDActivationDateIsMoreThan1Day(siteTraits);
};

const checkIfJPDActivationDateIsMoreThan1Day = (traits: Trait[]): boolean => {
	const activationTrait = traits.find(
		(trait) => trait.name === 'jira-product-discovery.activationDate',
	);

	if (activationTrait?.value) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const oneDayLater = new Date(activationTrait.value as string);
		oneDayLater.setDate(oneDayLater.getDate() + 1);

		// Compare with the current date
		const today = new Date();
		return today >= oneDayLater;
	}
	return false;
};

import React from 'react';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { BANNER, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge/src/utils/analytics-context-wrapper';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics-component';
import type { USER_PROPERTY_BY_STATE_KEY } from '../../../../controllers/constants.tsx';
import { useOnboardingFlowsActions } from '../../../../controllers/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	title: string;
	body: string;
	stateKey: keyof typeof USER_PROPERTY_BY_STATE_KEY;
};

export const CrossProjectViewFieldsOnboarding = ({ stateKey, title, body }: Props) => {
	const { setCrossProjectViewFieldsDismissed } = useOnboardingFlowsActions();
	const { formatMessage } = useIntl();

	return (
		<ContextualAnalyticsData sourceType={BANNER} sourceName="roadmapViewFields">
			<FireScreenAnalytics />
			<Box padding="space.200" xcss={sectionMessageStyles}>
				<Stack space="space.100">
					<Heading size="xsmall">{title}</Heading>
					<Box>{body}</Box>
					<Link
						href="https://support.atlassian.com/jira-product-discovery/docs/create-and-manage-global-fields/"
						target="_blank"
					>
						{formatMessage(messages.link)}
					</Link>
					<Box>
						<ButtonGroup>
							<Button
								onClick={(_, analyticsEvent) => {
									setCrossProjectViewFieldsDismissed(stateKey);
									fireUIAnalytics(analyticsEvent, 'dismiss');
								}}
							>
								{formatMessage(messages.dismissButton)}
							</Button>
						</ButtonGroup>
					</Box>
				</Stack>
			</Box>
		</ContextualAnalyticsData>
	);
};

const sectionMessageStyles = xcss({
	backgroundColor: 'color.background.discovery',
	borderRadius: 'border.radius.100',
});

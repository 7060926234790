import React from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Inline, xcss, Text, Pressable } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useCollectionFiltersJql } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PolarisFilters } from '../../view-controls/config-filters/index.tsx';
import { Header } from '../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

const useHasCurrentCollectionFilters = (): boolean => {
	const env = useEnvironmentContainer();
	const collectionUUID =
		env?.type === PolarisEnvironmentContainerTypes.COLLECTION ? env.collectionId : undefined;
	return !!useCollectionFiltersJql({ collectionUUID });
};

const CollectionFiltersMessage = () => {
	const { formatMessage } = useIntl();
	const hasCurrentCollectionFilters = useHasCurrentCollectionFilters();

	if (!hasCurrentCollectionFilters || !fg('jpd-aurora-roadmap-advanced-filtering')) {
		return;
	}

	return (
		<Inline xcss={roadmapFiltersInfoStyles} alignBlock="start" space="space.100">
			<InfoIcon primaryColor="color.icon" label={formatMessage(messages.roadmapFiltersInfoText)} />
			<span>
				<Text>{formatMessage(messages.roadmapFiltersInfoText)}</Text>
				<Pressable xcss={manageRoadmapLinkStyles}>
					{formatMessage(messages.manageRoadmapLink)}
				</Pressable>
			</span>
		</Inline>
	);
};

export const FilterPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<UFOSegment name="jpd.right-sidebar.filters">
			<FieldsContainer>
				<Header title={formatMessage(messages.title)} onClose={onClose} />
				<CollectionFiltersMessage />
				<PolarisFilters />
			</FieldsContainer>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
});

const roadmapFiltersInfoStyles = xcss({
	paddingInline: 'space.200',
	paddingBlock: 'space.100',
	backgroundColor: 'elevation.surface.sunken',
});

const manageRoadmapLinkStyles = xcss({
	color: 'color.link',
	padding: 'space.0',
	background: 'transparent',
	':hover': {
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.link.pressed',
	},
});

import React from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { Header } from './header/index.tsx';
import { ViewDescription } from './view-description/index.tsx';

type Props = {
	onClose: () => void;
};

export const ViewInfoPanel = ({ onClose }: Props) => (
	<UFOSegment name="jpd.right-sidebar.view-info">
		<Flex
			direction="column"
			gap="space.300"
			xcss={containerStyles}
			testId="polaris-ideas.ui.right-sidebar.view-info"
		>
			<Header onClose={onClose} />
			<ViewDescription />
		</Flex>
	</UFOSegment>
);

const containerStyles = xcss({
	paddingTop: 'space.250',
	paddingRight: 'space.300',
	paddingBottom: 'space.250',
	paddingLeft: 'space.300',
	maxHeight: 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))',
});

import React, { useCallback } from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FuzzyDateField } from '../../../idea-list/cell/cells/fuzzy-date-field/index.tsx';
import { Date } from './date/index.tsx';
import { DateTime } from './datetime/index.tsx';
import { ExternalReference } from './external-reference/index.tsx';
import { Labels } from './label/index.tsx';
import { NumDataPoints } from './num-data-points/index.tsx';
import { Number } from './number/index.tsx';
import { MultiSelect, SingleSelect } from './select/index.tsx';
import { Status } from './status/index.tsx';
import { String } from './string/index.tsx';
import { User } from './user/index.tsx';
import { Users } from './users/index.tsx';

type FieldComponentProps = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	portalElement?: HTMLElement;
	onManageField: (arg1: FieldKey, optionId?: string) => void;
	isTemporary?: boolean;
};
export const FieldComponent = ({
	fieldKey,
	localIssueId,
	onManageField,
	portalElement,
	isTemporary = false,
}: FieldComponentProps) => {
	const fieldType = useFieldType(fieldKey);

	const onConfigRequested = useCallback(
		(optionId?: string) => {
			if (fieldKey !== undefined) {
				onManageField(fieldKey, optionId);
			}
		},
		[fieldKey, onManageField],
	);

	switch (fieldType) {
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return <Number fieldKey={fieldKey} localIssueId={localIssueId} />;
		case FIELD_TYPES.INSIGHTS:
			return (
				<NumDataPoints fieldKey={fieldKey} localIssueId={localIssueId} isTemporary={isTemporary} />
			);
		case FIELD_TYPES.DATE:
			return <Date fieldKey={fieldKey} localIssueId={localIssueId} />;
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.UPDATED:
			return <DateTime fieldKey={fieldKey} localIssueId={localIssueId} />;
		case FIELD_TYPES.STATUS:
			return <Status fieldKey={fieldKey} localIssueId={localIssueId} />;
		case FIELD_TYPES.SHORT_TEXT:
		case FIELD_TYPES.SUMMARY:
		case FIELD_TYPES.HYPERLINK:
			return <String fieldKey={fieldKey} localIssueId={localIssueId} />;
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
			return <User fieldKey={fieldKey} localIssueId={localIssueId} portalElement={portalElement} />;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return (
				<Users fieldKey={fieldKey} localIssueId={localIssueId} portalElement={portalElement} />
			);
		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<SingleSelect
					fieldKey={fieldKey}
					localIssueId={localIssueId}
					onConfigRequested={onConfigRequested}
					menuPortalTarget={portalElement}
				/>
			);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<MultiSelect
					fieldKey={fieldKey}
					localIssueId={localIssueId}
					onConfigRequested={onConfigRequested}
					menuPortalTarget={portalElement}
				/>
			);
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return (
				<Labels
					fieldKey={fieldKey}
					localIssueId={localIssueId}
					onConfigRequested={onConfigRequested}
					menuPortalTarget={portalElement}
				/>
			);
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return (
				<ExternalReference
					fieldKey={fieldKey}
					localIssueId={localIssueId}
					onConfigRequested={onConfigRequested}
					menuPortalTarget={portalElement}
				/>
			);
		case FIELD_TYPES.INTERVAL:
			return <FuzzyDateField fieldKey={fieldKey} localIssueId={localIssueId} />;
		default:
			break;
	}
	return null;
};

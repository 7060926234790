import React, { type PropsWithChildren, Fragment } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

const FullHeightWrapper = ({ children }: PropsWithChildren) => <Box xcss={styles}>{children}</Box>;

export const Wrapper = ({ children }: PropsWithChildren) => {
	const PageWrapper = getWillShowNav4() ? FullHeightWrapper : Fragment;
	return <PageWrapper>{children}</PageWrapper>;
};

const styles = xcss({
	height: '100vh',
});

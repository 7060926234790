import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { RowGroupId } from '../../../common/types/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../../constants.tsx';
import { getRowGroups } from '../../selectors/items.tsx';
import { areAllRowGroupsCollapsed } from '../../selectors/row-groups.tsx';
import type { Props, State } from '../../types.tsx';

export const triggerRowGroupExpansion =
	(rowGroupId: RowGroupId) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { collapsedGroups } = getState();
		const isCollapsed = collapsedGroups.includes(rowGroupId);
		if (!isCollapsed) {
			// The group is already expanded.
			// No need to update state.
			return;
		}

		const newCollapsedGroups = collapsedGroups.filter((groupId) => groupId !== rowGroupId);
		setState({ collapsedGroups: newCollapsedGroups });
	};

export const toggleRowGroupExpansion =
	(rowGroupId: RowGroupId) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { collapsedGroups } = getState();
		const isCollapsed = collapsedGroups.includes(rowGroupId);
		const newCollapsedGroups = !isCollapsed
			? [...collapsedGroups, rowGroupId]
			: collapsedGroups.filter((group) => group !== rowGroupId);
		setState({ collapsedGroups: newCollapsedGroups });
	};

export const toggleAllRowGroupExpansions =
	() =>
	({ setState, getState }: StoreActionApi<State>) => {
		const state = getState();
		const rowGroups = getRowGroups(state) ?? [];
		const collapsedGroups = areAllRowGroupsCollapsed(state) ? [] : [...rowGroups];
		setState({ collapsedGroups });
	};

export const reOrderGroups =
	({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) =>
	({ setState, getState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const rowGroups = getRowGroups(state);
		const { onGroupOrderChanged } = props;

		if (rowGroups === undefined || oldIndex === newIndex) {
			return;
		}
		// cannot drag and drop before no value group
		if (rowGroups[newIndex] !== NO_VALUE_GROUP_ID) {
			const newRowGroups = [...rowGroups];
			const [removed] = newRowGroups.splice(oldIndex, 1);
			newRowGroups.splice(newIndex, 0, removed);
			setState({ rowGroups: newRowGroups });

			onGroupOrderChanged && onGroupOrderChanged({ oldIndex, newIndex });
		}
	};

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	performDeleteRequest,
	performPostRequest,
	performPutRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	MarkersResponse,
	FetchSharingViewMarkers,
	DeleteCollectionViewMarker,
	UpdateCollectionViewMarker,
	CreateCollectionViewMarker,
	FetchCollectionViewMarkers,
} from './types.tsx';
import {
	getCollectionMarkersUrl,
	getCollectionMarkersUrlWithId,
	getSharingMarkersUrl,
} from './utils.tsx';

export const fetchSharingViewMarkers: FetchSharingViewMarkers = (viewUUID) =>
	fetchJson<MarkersResponse>(getSharingMarkersUrl(viewUUID));

export const fetchCollectionViewMarkers: FetchCollectionViewMarkers =
	(collectionUUID) => (viewUUID) =>
		fetchJson<MarkersResponse>(getCollectionMarkersUrl(collectionUUID, viewUUID));

export const createCollectionViewMarker: CreateCollectionViewMarker =
	(collectionUUID) => (viewUUID, marker) =>
		performPostRequest(getCollectionMarkersUrl(collectionUUID, viewUUID), {
			body: JSON.stringify(marker),
		});

export const updateCollectionViewMarker: UpdateCollectionViewMarker =
	(collectionUUID) => (viewUUID, updatedMarker) => {
		if (updatedMarker.id) {
			return performPutRequest(
				getCollectionMarkersUrlWithId(collectionUUID, viewUUID, updatedMarker.id),
				{
					body: JSON.stringify(updatedMarker),
				},
			);
		}
	};

export const deleteCollectionViewMarker: DeleteCollectionViewMarker =
	(collectionUUID) => (viewUUID, markerId) =>
		performDeleteRequest(getCollectionMarkersUrlWithId(collectionUUID, viewUUID, markerId));

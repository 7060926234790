import React, { memo, useMemo } from 'react';
import { keyframes, styled } from '@compiled/react';
import { useXAxis, useYAxis } from '../../controllers/selectors/axis-hooks.tsx';
import { useItemWrapperRendererComponent } from '../../controllers/selectors/components-hooks.tsx';
import { useClusteredItemIds, useEmptyState } from '../../controllers/selectors/items-hooks.tsx';
import { useDragValue } from '../../controllers/selectors/ui-hooks.tsx';
import { Bubble, SimpleDefaultBubbleWrapperComponent } from '../bubble/index.tsx';
import { MatrixDragLayer } from '../dnd/index.tsx';
import { EmptyMatrixContent } from './empty-content/index.tsx';

const useRefreshOnAxesUpdate = (itemClusters: string[][]): string[][] => {
	const xAxis = useXAxis();
	const yAxis = useYAxis();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => itemClusters.map((cluster) => [...cluster]), [itemClusters, xAxis, yAxis]);
};

type MatrixContentProps = {
	isItemsDragDisabled?: boolean;
};

export const MatrixContent = memo(({ isItemsDragDisabled }: MatrixContentProps) => {
	// useClusteredItemIds() is not aware of axis config changes (reversing and reordering of axis values)
	// useRefreshOnAxesUpdate() keeps track on axis changes and recreates itemCluster arrays to trigger bubble position recomputation
	const itemClusters = useRefreshOnAxesUpdate(useClusteredItemIds());
	const emptyState = useEmptyState();
	const dragValue = useDragValue();

	const BubbleWrapperRenderComponent = useItemWrapperRendererComponent();

	const BubbleWrapperComponent =
		BubbleWrapperRenderComponent || SimpleDefaultBubbleWrapperComponent;

	return (
		<>
			{emptyState !== 'NONE' && !dragValue && <EmptyMatrixContent cause={emptyState} />}
			<Container>
				{itemClusters.map((cluster) => {
					if (cluster.length === 0) {
						return null;
					}
					return (
						<BubbleWrapperComponent itemIds={cluster} key={cluster[0]}>
							<Bubble itemIds={cluster} isItemsDragDisabled={isItemsDragDisabled} />
						</BubbleWrapperComponent>
					);
				})}
			</Container>
			<MatrixDragLayer />
		</>
	);
});

const appearAnimation = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
	height: '100%',
	overflow: 'visible',
	animationName: appearAnimation,
	animationDuration: '1s',
});

import React from 'react';
import {
	RANGE_ROLLUP,
	MIN_ROLLUP,
	MAX_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { Props } from '../types.tsx';
import { IntervalFieldGroupedMax } from './max/index.tsx';
import { IntervalFieldGroupedMin } from './min/index.tsx';
import { IntervalFieldGroupedRange } from './range/index.tsx';

export const IntervalFieldGrouped = (props: Props) => {
	switch (props.rollupOperation) {
		case RANGE_ROLLUP:
			return <IntervalFieldGroupedRange {...props} />;
		case MIN_ROLLUP:
			return <IntervalFieldGroupedMin {...props} />;
		case MAX_ROLLUP:
			return <IntervalFieldGroupedMax {...props} />;
		default:
			return null;
	}
};

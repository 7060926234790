/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { AutoResizer, BaseTableV2 } from 'react-base-table';
import { useSelectedIssueConnectionFieldIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useCanConnectIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ListPortalProvider } from '../../../view-content/idea-list/portal/index.tsx';
import { ROW_HEIGHT } from '../constants.tsx';
import { DATA_COMPONENT_SELECTOR } from './cells/summary/unlink/index.tsx';
import {
	ACCENT_BACKGROUND_COLOR,
	BORDER_COLOR,
	ROW_BORDER_WIDTH,
	TABLE_CLASS_PREFIX,
} from './constants.tsx';
import { FooterRenderer } from './footer/index.tsx';
import type { RowData } from './types.tsx';
import { useColumns } from './utils.tsx';

type Props = {
	fieldKey: FieldKey;
};

export const Content = ({ fieldKey }: Props) => {
	const connectionFieldIssueIds = useSelectedIssueConnectionFieldIssueIds(fieldKey);
	const columns = useColumns(fieldKey);
	const canConnectIssues = useCanConnectIssues();
	const footerRenderer = useCallback(
		() => (canConnectIssues ? <FooterRenderer fieldKey={fieldKey} /> : null),
		[fieldKey, canConnectIssues],
	);

	return (
		<ListPortalProvider>
			<div css={wrapperStyles}>
				<AutoResizer>
					{({ width, height }) => (
						<BaseTableV2<RowData>
							width={width}
							height={height}
							fixed={false}
							columns={columns}
							data={connectionFieldIssueIds}
							classPrefix={TABLE_CLASS_PREFIX}
							headerHeight={ROW_HEIGHT}
							rowHeight={ROW_HEIGHT}
							footerHeight={canConnectIssues ? ROW_HEIGHT : undefined}
							footerRenderer={footerRenderer}
						/>
					)}
				</AutoResizer>
			</div>
		</ListPortalProvider>
	);
};

/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-selectors, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors */
const wrapperStyles = css({
	height: '100%',
	[`.${TABLE_CLASS_PREFIX}`]: {
		'&__table': {
			display: 'flex',
			flexDirection: 'column-reverse',
		},

		'&__row': {
			display: 'flex',
			boxSizing: 'border-box',

			'&:not(:first-child)': {
				borderTop: `${ROW_BORDER_WIDTH}px solid ${BORDER_COLOR}`,
			},

			[`[data-component-selector="${DATA_COMPONENT_SELECTOR}"]`]: {
				display: 'none',
			},

			'&:hover': {
				backgroundColor: ACCENT_BACKGROUND_COLOR,

				[`[data-component-selector="${DATA_COMPONENT_SELECTOR}"]`]: {
					display: 'flex',
				},
			},
		},

		'&__header': {
			boxSizing: 'border-box',
			backgroundColor: ACCENT_BACKGROUND_COLOR,
			borderBottom: `${ROW_BORDER_WIDTH}px solid ${BORDER_COLOR}`,
		},

		'&__header-row': {
			display: 'flex',
			boxSizing: 'border-box',
		},

		'&__header-cell, &__row-cell': {
			height: '100%',
			boxSizing: 'border-box',

			'&:not(:last-child)': {
				borderRight: `${ROW_BORDER_WIDTH}px solid ${BORDER_COLOR}`,
			},
		},

		'&__footer': {
			boxSizing: 'border-box',
			borderTop: `${ROW_BORDER_WIDTH}px solid ${BORDER_COLOR}`,
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			padding: '0 1px',
		},
	},
});
/* eslint-enable @atlaskit/ui-styling-standard/no-unsafe-selectors, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors */

import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/index.tsx';
import { useConnectedIssueDataByIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import messages from './messages.tsx';

type UnlinkCellRendererProps = {
	issueId: number;
};

export const DATA_COMPONENT_SELECTOR = 'unlink-button-234io';

export const UnlinkButton = ({ issueId }: UnlinkCellRendererProps) => {
	const { formatMessage } = useIntl();
	const { updateIssueConnections } = useIssueActions();
	const currentIssueLocalIssueId = useSelectedIssue();
	const issueToDisconnect = useConnectedIssueDataByIssueId(issueId.toString());

	const handleUnlink = () => {
		if (!currentIssueLocalIssueId || !issueToDisconnect) {
			return;
		}

		updateIssueConnections({
			localIssueId: currentIssueLocalIssueId,
			issuesToDisconnect: [
				{
					...issueToDisconnect,
					id: issueToDisconnect.issueId.toString(),
				},
			],
		});
	};

	return (
		<Box xcss={containerStyles} data-component-selector={DATA_COMPONENT_SELECTOR}>
			<IconButton
				appearance="subtle"
				spacing="compact"
				icon={CloseIcon}
				label={formatMessage(messages.unlinkButtonLabelNonFinal)}
				isTooltipDisabled={false}
				onClick={handleUnlink}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginLeft: 'auto',
});

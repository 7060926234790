import React, { useCallback } from 'react';
import { useOpenIdeaViewFieldSidebar } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view.tsx';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { LabelsField } from '@atlassian/jira-polaris-common/src/ui/fields/labels/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal/index.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';
import { CellPaddingAdjust } from '../utils/cell-padding-adjust.tsx';

type EditableLabelsFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
};

export const EditableLabelsField = ({ localIssueId, fieldKey }: EditableLabelsFieldProps) => {
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const portalElement = useListPortalElement();

	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const onConfigRequested = useCallback(
		(optionId?: string) => {
			openIdeaViewFieldSidebar(fieldKey, optionId);
		},
		[openIdeaViewFieldSidebar, fieldKey],
	);

	return (
		<CellPaddingAdjust left={6} right={4}>
			<LabelsField
				localIssueId={localIssueId}
				fieldKey={fieldKey}
				isEditable={isEditable && !isIdeaArchived}
				onUpdate={onUpdate}
				onConfigRequested={onConfigRequested}
				menuPortalTarget={portalElement}
			/>
		</CellPaddingAdjust>
	);
};

import React, { useMemo, useCallback, type ReactNode, type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import FocusLock from 'react-focus-lock';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { type KeyboardOrMouseEvent } from '@atlaskit/modal-dialog';
import { SpotlightManager } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ClipboardContextBoundaryContainer as ClipboardBoundary } from '@atlassian/jira-clipboard/src/controllers/boundary/index.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import {
	MODAL_DROPDOWN_PORTAL_CLASS_NAME,
	EDITOR_DROPDOWN_PORTAL_CLASS_NAME,
	ELID_ISSUE_HEADER,
	issueAppModalWrapperClassName,
} from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import AuiModalStackIndex from '@atlassian/jira-issue-view-common-utils/src/aui-modal-stack-index/index.tsx';
import { isKeyEscapeEvent } from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import type PrefetchedResourceManager from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { PrefetchedResourceStoreContainer } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { GRID_PADDING_OVERRIDE_COMPONENT_SELECTOR } from '@atlassian/jira-issue-view-common-views/src/issue-page-grid/styled.tsx';
import { IssueViewErrorBoundary } from '@atlassian/jira-issue-view-error-boundary/src/ui/issue-view-error-boundary/index.tsx';
import { IssueViewExperienceTrackingProviders } from '@atlassian/jira-issue-view-experience-tracking-providers/src/index.tsx';
import { ErrorHeader } from '@atlassian/jira-issue-view-foundation/src/header/error-header/index.tsx';
import Header from '@atlassian/jira-issue-view-foundation/src/header/index.tsx';
import { IssueViewModalSkeleton } from '@atlassian/jira-issue-view-foundation/src/issue-view-modal-skeleton/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { mainIssueAggQuery$data } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { markStartIssue } from '../../../common/metrics/index.tsx';
import AssignIssueParentModal from '../../assign-issue-parent-modal/index.tsx';
import Banner from '../../banner/view.tsx';
import AppProvider from '../app-provider/index.tsx';
import { IssueViewCriticalQueryPreloader } from '../app-provider/resource-manager/index.tsx';
import type IssueViewType from '../issue-view.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

type AppProviderProps = React.ComponentProps<typeof AppProvider>;

const modalDialogContainerSelectorName = 'jira.issue-view.issue-details.modal-dialog-container';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IssueView = lazyForPaint<typeof IssueViewType>(
	() => import(/* webpackChunkName: "async-issue-app--modal" */ '../issue-view'),
	{ ssr: false },
);

// @ts-expect-error - TS7023 - 'isInLegacyJiraDialog' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
const isInLegacyJiraDialog = (
	/**
	 * We need to type this as `any` because `className` exists on `Element` but not `Node`
	 * and `Node.parentNode` is defined to return `Node`,
	 * and Flow has no way to determine the type at runtime.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	node: any,
) =>
	node &&
	((node.className && ` ${node.className} `.includes(' jira-dialog ')) ||
		isInLegacyJiraDialog(node.parentNode));

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isMediaPickerPopupOpen = () => !!document.querySelector('.e2e-upload-button');

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isMediaViewerPopupOpen = () => !!document.querySelector('.mvng-hide-controls');

const FocusUnlock = () => (
	// a focus-lock that covers the whole body
	// effectively replaces another active lock (from modal)
	// and allows other solutions (focus-trap/ AUI) to work without interruptions
	// used to "support" AUI focus lock implementation
	<FocusLock
		// set FocusLock to ignore all elements
		whiteList={useCallback(() => false, [])}
		returnFocus
		// disable autofocus in order to minimize interference
		autoFocus={false}
	/>
);

export const IssueModal = ({
	renderFeedback,
	viewModeOptions = { viewMode: 'MODAL', viewModeSwitchEnabled: false },
	onClose = noop,
	renderTopBanner,
	onIssueDeleteSuccess = noop,
	onIssueDeleteFailure = noop,
	analyticsSource,
	backButtonUrl,
	boardId,
	boardType,
	dispatchExternalActionRef,
	ecosystemEnabled,
	estimationStatistic,
	history,
	isLoadedWithPage = false,
	issueActions,
	issueKey,
	loginRedirectUrl,
	metrics,
	onChange,
	onError,
	applicationKey,
	onIssueKeyChange,
	rapidViewId,
	rapidViewMap,
}: Props) => {
	const modalWidth = 160 * gridSize;
	const previousCurrentIssueKey = usePrevious(issueKey);
	const { formatMessage } = useIntl();

	useMemo(() => {
		if (issueKey !== previousCurrentIssueKey) {
			markStartIssue();
		}
	}, [issueKey, previousCurrentIssueKey]);

	const handleCloseModal = useCallback(() => {
		onClose(issueKey);
	}, [issueKey, onClose]);

	const handleOnClose = useCallback(
		(event: KeyboardOrMouseEvent) => {
			if (
				isKeyEscapeEvent(event) &&
				(isInLegacyJiraDialog(event.target) || isMediaPickerPopupOpen() || isMediaViewerPopupOpen())
			) {
				return;
			}
			handleCloseModal();
		},
		[handleCloseModal],
	);

	const renderModalHeaderAndBody = (
		issueViewRelayFragment: IssueViewRelayFragment | null,
		rootRelayFragment: MainIssueAggQueryRelayFragment | null,
	) => (
		<>
			<ModalHeader id={ELID_ISSUE_HEADER}>
				{renderTopBanner ? (
					<Banner
						renderTopBanner={renderTopBanner}
						applicationKey={applicationKey}
						isProjectSimplified={false}
					/>
				) : null}
				<Header
					shouldShowCloseButton
					onClose={handleCloseModal}
					renderFeedback={renderFeedback}
					issueDeleteCallbacks={{
						onIssueDeleteSuccess,
						onIssueDeleteFailure,
					}}
					viewModeOptions={viewModeOptions}
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			</ModalHeader>
			<IssueModalBody>
				<ModalDialogContainer data-component-selector={modalDialogContainerSelectorName}>
					<AssignIssueParentModal />
					<IssueView
						externalId="issue"
						issueViewRelayFragment={issueViewRelayFragment}
						rootRelayFragment={rootRelayFragment}
						shouldSetInitialFocus
					/>
					{/*
                        Used for AK dropdowns in field tabs (like User Picker),
                        which don't have a fixed position, don't respect the parent
                        elements offset position, and need to be fixed in AK and bumped.
                        See ticket: https://jdog.jira-dev.com/browse/BENTO-4101
                    */}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<ModalAkDropdownPortal className={MODAL_DROPDOWN_PORTAL_CLASS_NAME} />
					{/*
                        Used for Editor dropdowns in field tabs; they don't have a
                        fixed position (causing overflow) and this needs to be fixed
                        in AK and bumped. But they do respect the parent elements offset
                        position and should be separate from ModalAkDropdownPortal above.
                        See ticket: https://jdog.jira-dev.com/browse/BENTO-4100
                    */}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={EDITOR_DROPDOWN_PORTAL_CLASS_NAME} />
				</ModalDialogContainer>
			</IssueModalBody>
		</>
	);

	const renderAppProviderAndContent = ({
		prefetchedResourceManager,
		issueAggQueryData,
	}: {
		prefetchedResourceManager: PrefetchedResourceManager;
		issueAggQueryData: mainIssueAggQuery$data | null;
	}) => {
		const appProviderProps: AppProviderProps = {
			analyticsSource,
			backButtonUrl,
			boardId,
			boardType,
			dispatchExternalActionRef,
			ecosystemEnabled,
			estimationStatistic,
			history,
			isLoadedWithPage,
			isModal: true,
			issueActions,
			issueKey,
			loginRedirectUrl,
			metrics,
			onChange,
			onError,
			onIssueKeyChange,
			rapidViewId,
			rapidViewMap,
			viewMode: viewModeOptions && viewModeOptions.viewMode,
			prefetchedResourceManager,
			issueAggQueryData,
		};

		return <AppProvider {...appProviderProps}>{renderModalHeaderAndBody}</AppProvider>;
	};

	const renderWithErrorBoundary = (node: ReactNode) => (
		<IssueViewExperienceTrackingProviders
			issueKey={issueKey}
			analyticsSource={analyticsSource}
			// this is set to true because if we are loading this component here it means the issue view has finished loading
			isExperienceReady
		>
			<IssueViewErrorBoundary
				errorBoundaryId="issue-view-modal-app-provider"
				loginRedirectUrl={loginRedirectUrl}
				fallback={(errorComponent) => (
					<>
						<ModalHeader id={ELID_ISSUE_HEADER}>
							<ErrorHeader onClose={handleCloseModal} renderFeedback={renderFeedback} />
						</ModalHeader>
						<IssueModalBody>
							<ModalDialogContainer data-component-selector={modalDialogContainerSelectorName}>
								{}
								<Box xcss={styles}>{errorComponent}</Box>
							</ModalDialogContainer>
						</IssueModalBody>
					</>
				)}
			>
				{node}
			</IssueViewErrorBoundary>
		</IssueViewExperienceTrackingProviders>
	);

	const renderWithSuspenseBoundary = (node: ReactNode) => (
		<Placeholder
			name="issue-view-modal-skeleton"
			fallback={
				<IssueViewModalSkeleton
					onClose={handleCloseModal}
					renderFeedback={renderFeedback}
					issueKey={issueKey}
				/>
			}
		>
			{node}
		</Placeholder>
	);

	// https://atlassian-labs.github.io/react-loosely-lazy/#/guides/preloading?id=preloading-on-component-mount
	// Preload `issue-view` bundle early, before suspend!
	// A better solution would be to introduce entry points for Issue View - JIV-16312
	if (IssueView.preload) {
		IssueView.preload();
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={issueAppModalWrapperClassName}>
			<SpotlightManager>
				<AuiModalStackIndex>
					{(stackIndex) => (stackIndex ? <FocusUnlock /> : null)}
				</AuiModalStackIndex>
				<ShortcutScope>
					<ModalDialog
						testId="issue.views.issue-details.issue-modal.modal-dialog"
						autoFocus={false}
						onClose={handleOnClose}
						width={modalWidth}
						{...(fg('enghealth-11196-missing-aria-label')
							? { label: formatMessage(messages.issueModalAriaLabel) }
							: {})}
					>
						<ClipboardBoundary>
							<PrefetchedResourceStoreContainer>
								{renderWithErrorBoundary(
									renderWithSuspenseBoundary(
										<IssueViewCriticalQueryPreloader issueKey={issueKey}>
											{renderAppProviderAndContent}
										</IssueViewCriticalQueryPreloader>,
									),
								)}
							</PrefetchedResourceStoreContainer>
						</ClipboardBoundary>
					</ModalDialog>
				</ShortcutScope>
			</SpotlightManager>
		</div>
	);
};

export const ModalDialogContainer = (
	props: ComponentPropsWithoutRef<typeof ModalDialogContainerComponent>,
) => (
	<ModalDialogContainerComponent
		data-component-selector={modalDialogContainerSelectorName}
		{...props}
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalHeader = styled.div({
	paddingTop: token('space.300', '24px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.400', '32px'),
	paddingRight: token('space.400', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[GRID_PADDING_OVERRIDE_COMPONENT_SELECTOR]: {
		paddingLeft: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ModalAkDropdownPortal = styled.div({
	position: 'fixed',
	top: 0,
	left: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueModalBody = styled.div<{ shouldHandleBrokenSafari?: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.shouldHandleBrokenSafari && {
			marginTop: 0,
			marginBottom: 0,
			marginLeft: token('space.negative.300', '-24px'),
			marginRight: token('space.negative.300', '-24px'),
		},
	/* styles from Atlaskit modal */
	{
		overflowX: 'hidden' /* 2 value shorthand syntax is not supported in IE11 */,
		overflowY: 'auto',
		/* custom styles */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			/* inner div from Ataslkit */
			height: '100%',
		},
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalDialogContainerComponent = styled.div({
	height: '100%',
});
const styles = xcss({ paddingBottom: 'space.400' });

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { FetchResponse, ViewConfigFetcher } from './types.tsx';

const getConfigUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/public/views/${viewId}/config`;

type Cache = {
	response: Promise<FetchResponse> | undefined;
};

export const createCachedViewConfigFetcher = (viewId: string): ViewConfigFetcher => {
	const cache: Cache = {
		response: undefined,
	};

	return () => {
		if (cache.response === undefined) {
			cache.response = fetchJson(getConfigUrl(viewId));
		}

		return cache.response;
	};
};

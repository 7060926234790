import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { InsightsRemote } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueForMerge } from '../issue/types.tsx';

export const DataPointsMergeOption = 'data_points';
export const AttachmentsMergeOption = 'attachments';
export const LinkedDeliveryIssuesMergeOption = 'linked_delivery_options';
export const DescriptionMergeFromOption = 'description';
export const FieldsMergeFromOption = 'fields';

export type MergeOption =
	| typeof DataPointsMergeOption
	| typeof AttachmentsMergeOption
	| typeof LinkedDeliveryIssuesMergeOption;

export type MergeFromOption = typeof DescriptionMergeFromOption | typeof FieldsMergeFromOption;

export type SelectedIssue = IssueForMerge;

export type TargetIssue = {
	id: number;
	key: IssueKey;
	summary: string;
	icon: string;
};

export type TargetIssueMerged = TargetIssue & {
	projectKey: string;
	isSameProject: boolean;
};

export type SuccessCallback = (
	targetIssue: TargetIssueMerged,
	mergedIssues: SelectedIssue[],
) => void;

export type State = {
	isOpen: boolean;
	isSaving: boolean;
	mergeOptions: Record<MergeOption, true>;
	mergeFromOptions: Record<MergeFromOption, SelectedIssue | TargetIssue>;
	selectedIssues: SelectedIssue[];
	targetIssue: TargetIssue | null;
	successCallback: SuccessCallback;
	error: Error | null;
};

export type Props = {
	projectId: string;
	cloudId: string;
	insightsRemote: InsightsRemote;
	issuesRemote: IssuesRemote;
	apolloClient: PolarisApolloClient;
	polarisDeliveryIssueLinkType: string | undefined;
	polarisMergeIssueLinkType: string | undefined;
	polarisDataPointIssueLinkType: string | undefined;
	hasNoProjectPermissions: boolean;
	onSuccess: SuccessCallback;
	onFailure: (err: Error) => void;
	onIssueLinked?: (outwardIssueKey: string, inwardIssueKey: string, isSameProject: boolean) => void;
};

// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type 'Readonly<{ isOpen: false; isSaving: false; selectedIssues: never[]; mergeOptions: {}; mergeFromOptions: {}; targetIssue: null; error: null; }>' is not assignable to type 'State'.
export const initialState: State = Object.freeze({
	isOpen: false,
	isSaving: false,
	selectedIssues: [],
	mergeOptions: {
		[DataPointsMergeOption]: true,
		[AttachmentsMergeOption]: true,
		[LinkedDeliveryIssuesMergeOption]: true,
	},
	mergeFromOptions: {},
	targetIssue: null,
	successCallback: null,
	error: null,
});

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	largeNumberTypeError: {
		id: 'polaris-common.fields.number.numeric.large-number-type-error',
		defaultMessage: 'The number can’t be greater than 1e14',
		description: 'Error message for numbers that are too large',
	},
	errorMessage: {
		id: 'polaris-common.fields.number.numeric.error-message',
		defaultMessage: 'Error',
		description: 'Error message for invalid numbers',
	},
});

import React from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import {
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST,
} from '../../../../controllers/constants.tsx';
import { useOnboardingFlowsActions } from '../../../../controllers/index.tsx';
import { useTourSpotlightStep } from '../../../../controllers/selectors/project-fields-hooks.tsx';
import type { ProjectFieldsTourSteps } from '../../../../controllers/types.tsx';
import addGlobalFieldImage from './assets/add-global-field.gif';
import createFieldImage from './assets/create-field.gif';
import manageFieldsImage from './assets/manage-fields.gif';
import manageGlobalFieldsImage from './assets/manage-global-fields.gif';
import switchingTabsImage from './assets/switching-tabs.gif';
import { messages } from './messages.tsx';

type Props = {
	canManageGlobalFields: boolean;
};

type SpotlightProps = React.ComponentProps<typeof JiraSpotlight>;
type SpotlightActions = NonNullable<SpotlightProps['actions']>;

export const ProjectFieldsSpotlightsTour = ({ canManageGlobalFields }: Props) => {
	const activeSpotlight = useTourSpotlightStep();
	const { setProjectFieldsTourSpotlightStep, setProjectFieldsTourDismissed } =
		useOnboardingFlowsActions();
	const { formatMessage } = useIntl();

	const goTo = (step: ProjectFieldsTourSteps, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'goToSpotlight', { activeSpotlight, goTo: step });
		setProjectFieldsTourSpotlightStep(step);
	};
	const end = (analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'dismiss', { activeSpotlight });

		if (activeSpotlight === PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST) {
			setProjectFieldsTourSpotlightStep(null);
		} else {
			setProjectFieldsTourSpotlightStep(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST);
		}

		setProjectFieldsTourDismissed();
	};

	const totalSteps = canManageGlobalFields ? 5 : 4;

	const stepFourActions: SpotlightActions = canManageGlobalFields
		? [
				{
					onClick: (_, analyticsEvent) =>
						goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5, analyticsEvent),
					text: formatMessage(messages.nextButton),
				},
				{
					onClick: (_, analyticsEvent) =>
						goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3, analyticsEvent),
					text: formatMessage(messages.backButton),
					appearance: 'subtle',
				},
				{
					onClick: (_, analyticsEvent) => end(analyticsEvent),
					text: formatMessage(messages.dismissButton),
					appearance: 'subtle',
				},
			]
		: [
				{
					onClick: (_, analyticsEvent) =>
						goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST, analyticsEvent),
					text: formatMessage(messages.okButton),
				},
				{
					onClick: (_, analyticsEvent) =>
						goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3, analyticsEvent),
					text: formatMessage(messages.backButton),
					appearance: 'subtle',
				},
			];

	const renderActiveSpotlight = () => {
		if (activeSpotlight === null) {
			return null;
		}

		const spotlights = {
			[PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1]: (
				<JiraSpotlight
					actions={[
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2, analyticsEvent),
							text: formatMessage(messages.nextButton),
						},
						{
							onClick: (_, analyticsEvent) => end(analyticsEvent),
							text: formatMessage(messages.dismissButton),
							appearance: 'subtle',
						},
					]}
					actionsBeforeElement={`1/${totalSteps}`}
					dialogPlacement="bottom right"
					dialogWidth={300}
					image={createFieldImage}
					target={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1}
					key={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1}
					targetRadius={3}
					targetBgColor={token('elevation.surface', '#000000')}
					messageId="polaris-lib-onboarding-flows.ui.project-fields.tour.spotlights.jira-spotlight"
					messageType="transactional"
				>
					{formatMessage(messages.firstStepBody)}
				</JiraSpotlight>
			),
			[PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2]: (
				<JiraSpotlight
					actions={[
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3, analyticsEvent),
							text: formatMessage(messages.nextButton),
						},
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1, analyticsEvent),
							text: formatMessage(messages.backButton),
							appearance: 'subtle',
						},
						{
							onClick: (_, analyticsEvent) => end(analyticsEvent),
							text: formatMessage(messages.dismissButton),
							appearance: 'subtle',
						},
					]}
					actionsBeforeElement={`2/${totalSteps}`}
					dialogPlacement="bottom right"
					dialogWidth={300}
					image={addGlobalFieldImage}
					target={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2}
					key={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2}
					targetRadius={3}
					targetBgColor={token('elevation.surface', '#000000')}
					messageId="polaris-lib-onboarding-flows.ui.project-fields.tour.spotlights.jira-spotlight.1"
					messageType="transactional"
				>
					{formatMessage(messages.secondStepBody)}
				</JiraSpotlight>
			),
			[PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3]: (
				<JiraSpotlight
					actions={[
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4, analyticsEvent),
							text: formatMessage(messages.nextButton),
						},
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2, analyticsEvent),
							text: formatMessage(messages.backButton),
							appearance: 'subtle',
						},
						{
							onClick: (_, analyticsEvent) => end(analyticsEvent),
							text: formatMessage(messages.dismissButton),
							appearance: 'subtle',
						},
					]}
					actionsBeforeElement={`3/${totalSteps}`}
					dialogPlacement="bottom center"
					dialogWidth={300}
					image={switchingTabsImage}
					target={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3}
					key={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3}
					targetRadius={36}
					targetBgColor={token('elevation.surface', '#000000')}
					messageId="polaris-lib-onboarding-flows.ui.project-fields.tour.spotlights.jira-spotlight.2"
					messageType="transactional"
				>
					{formatMessage(messages.thirdStepBody)}
				</JiraSpotlight>
			),
			[PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4]: (
				<JiraSpotlight
					actions={stepFourActions}
					actionsBeforeElement={`4/${totalSteps}`}
					dialogPlacement="left top"
					dialogWidth={300}
					image={manageFieldsImage}
					target={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4}
					key={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4}
					targetRadius={3}
					targetBgColor={token('elevation.surface', '#000000')}
					messageId="polaris-lib-onboarding-flows.ui.project-fields.tour.spotlights.jira-spotlight.3"
					messageType="transactional"
				>
					{formatMessage(messages.forthStepBody)}
				</JiraSpotlight>
			),
			[PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5]: (
				<JiraSpotlight
					actions={[
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST, analyticsEvent),
							text: formatMessage(messages.okButton),
						},
						{
							onClick: (_, analyticsEvent) =>
								goTo(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4, analyticsEvent),
							text: formatMessage(messages.backButton),
							appearance: 'subtle',
						},
					]}
					actionsBeforeElement={`5/${totalSteps}`}
					dialogPlacement="bottom right"
					dialogWidth={300}
					image={manageGlobalFieldsImage}
					target={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5}
					key={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5}
					targetRadius={3}
					targetBgColor={token('elevation.surface', '#000000')}
					messageId="polaris-lib-onboarding-flows.ui.project-fields.tour.spotlights.jira-spotlight.4"
					messageType="transactional"
				>
					{formatMessage(messages.fifthStepBody)}
				</JiraSpotlight>
			),
			[PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST]: (
				<JiraSpotlight
					actions={[
						{
							onClick: (_, analyticsEvent) => end(analyticsEvent),
							text: formatMessage(messages.gotItButton),
						},
					]}
					dialogPlacement="bottom left"
					dialogWidth={250}
					target={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST}
					key={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST}
					targetRadius={3}
					targetBgColor={token('elevation.surface', '#000000')}
					messageId="polaris-lib-onboarding-flows.ui.project-fields.tour.spotlights.jira-spotlight.5"
					messageType="transactional"
				>
					{formatMessage(messages.lastStepBody)}
				</JiraSpotlight>
			),
		};

		return spotlights[activeSpotlight];
	};

	return <SpotlightTransition>{renderActiveSpotlight()}</SpotlightTransition>;
};

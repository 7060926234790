import { useCallback, useState, useRef, useEffect } from 'react';
import { MAX_TASK_ERROR_RETRY_NUMBER, MAX_RETRY_NUMBER, REFETCH_DELAY } from './constants.tsx';
import type { RetryStatus } from './types.tsx';

export const useAllowedRetries = () => {
	const [retryStatus, setRetryStatus] = useState<RetryStatus>('underLimit');
	const numberOfAttempts = useRef<number>(0);
	const isPreviousTaskError = useRef<boolean | undefined>();
	const logRetryAttempt = useCallback((isTaskError: boolean) => {
		if (isPreviousTaskError.current !== undefined && isPreviousTaskError.current !== isTaskError) {
			numberOfAttempts.current = 0;
		}
		numberOfAttempts.current += 1;
		if (
			numberOfAttempts.current >= (isTaskError ? MAX_TASK_ERROR_RETRY_NUMBER : MAX_RETRY_NUMBER)
		) {
			setRetryStatus(isTaskError ? 'maxTaskErrorAttemptsReached' : 'maxAttemptsReached');
		}
		isPreviousTaskError.current = isTaskError;
	}, []);
	const resetRetryCount = useCallback(() => {
		setRetryStatus('underLimit');
		numberOfAttempts.current = 0;
	}, []);
	return {
		logRetryAttempt,
		retryStatus,
		resetRetryCount,
	};
};

export const useRetryInterval = (taskId: string | undefined, callback: () => void) => {
	const callbackRef = useRef<() => void>(callback);
	useEffect(() => {
		// make a reference to the retry callback for setInterval to invoke.
		callbackRef.current = callback;
	}, [callback]);
	const intervalId = useRef<number>();

	const clearRetryInterval = useCallback(() => {
		if (intervalId.current) {
			clearInterval(intervalId.current);
			intervalId.current = undefined;
		}
	}, []);

	useEffect(() => {
		if (taskId) {
			const retry = () => {
				callbackRef.current();
			};

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			intervalId.current = window.setInterval(retry, REFETCH_DELAY);
		}
		return clearRetryInterval;
	}, [clearRetryInterval, taskId]);
	return clearRetryInterval;
};

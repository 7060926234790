import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useSubmitIdeaFormView } from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import { useInvalidIssueSubmissionFormFieldLabels } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { messages } from './messages.tsx';
import { SectionMessage } from './styled.tsx';

export const SectionMessageComponent = () => {
	const { formatMessage } = useIntl();
	const viewId = useSubmitIdeaFormView();
	const unsupportedFields = useInvalidIssueSubmissionFormFieldLabels(viewId.value);

	if (!unsupportedFields.length) {
		return null;
	}

	return (
		<SectionMessage appearance="information">
			{formatMessage(messages.unsupportedFieldsInfoFirst)}
			<br />
			{formatMessage(messages.unsupportedFieldsInfoSecond, {
				fields: unsupportedFields.join(', '),
			})}
		</SectionMessage>
	);
};

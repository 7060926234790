import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const NoDataHint = () => {
	const { formatMessage } = useIntl();

	return (
		<NoDataHintDescription>{formatMessage(messages.hasNoDataDescription)}</NoDataHintDescription>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoDataHintDescription = styled.div({
	padding: `${token('space.150', '12px')} ${token('space.100', '8px')}`,
});

import React from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import {
	useFieldLabel,
	useFieldTypeIcon,
	useFieldEmoji,
	useIsGlobalCustomField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';

type Props = {
	fieldKey: FieldKey;
};

export const SelectOptionComponent = ({ fieldKey }: Props) => {
	const label = useFieldLabel(fieldKey);
	const icon = useFieldTypeIcon(fieldKey, undefined);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);

	return (
		<OptionWrapper>
			{emoji ? (
				<Flex justifyContent="center" alignItems="center" xcss={emojiWrapperStyles}>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</Flex>
			) : (
				<Box xcss={iconContainerStyles}>{icon}</Box>
			)}
			<OptionContent>{label}</OptionContent>
			{isGlobalCustomField && <GlobalFieldMarker />}
		</OptionWrapper>
	);
};

const emojiWrapperStyles = xcss({
	height: '20px',
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	lineHeight: '20px',
});

const iconContainerStyles = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
});

import { createSelector } from 'reselect';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache/src/index.tsx';
import type { State } from '../types.tsx';

export const getDraggingInfo = (state: State) => state;

export const getDraggingRowId = cacheSelectorCreator((state: State) => state.rowId);

export const getIsDragging = createSelector(
	getDraggingInfo,
	(draggingInfo) => draggingInfo.rowId !== undefined,
);

export const createGetIsDropTargetItem = cacheSelectorCreator((rowId: string) =>
	createSelector(getDraggingInfo, (draggingInfo) => draggingInfo.dropTarget === rowId),
);

import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Title } from './styled.tsx';

type HeaderProps = {
	fieldsCount?: number;
	title?: string;
	helpText?: string;
};

type ExpandableHeaderProps = HeaderProps & {
	isExpanded: boolean;
	onChange: (isExpanded: boolean) => void;
};

export const Header = memo<HeaderProps>(({ fieldsCount, title, helpText }: HeaderProps) => (
	<Wrapper>
		{title ? (
			<Title>
				{title} ({fieldsCount})
			</Title>
		) : null}
		{helpText ? <HelpText>{helpText}</HelpText> : null}
	</Wrapper>
));

export const ExpandableHeader = memo<ExpandableHeaderProps>(
	({ fieldsCount, title, helpText, onChange, isExpanded }: ExpandableHeaderProps) => (
		<ExpandableHeaderWrapper>
			<div>
				{title ? (
					<Title>
						{title} ({fieldsCount})
					</Title>
				) : null}
				{helpText ? <HelpText>{helpText}</HelpText> : null}
			</div>
			<Button
				appearance="subtle"
				aria-expanded={isExpanded}
				spacing="none"
				iconBefore={
					isExpanded ? <ChevronDownIcon label="collapse" /> : <ChevronRightIcon label="expand" />
				}
				onClick={() => onChange(!isExpanded)}
			/>
		</ExpandableHeaderWrapper>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	padding: `0 ${token('space.200', '16px')} ${token('space.050', '4px')} ${token(
		'space.200',
		'16px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandableHeaderWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: `0 ${token('space.200', '16px')} ${token('space.050', '4px')} ${token(
		'space.200',
		'16px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	paddingTop: token('space.075', '6px'),
});

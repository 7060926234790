import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type TimelineType from './index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyTimeline = lazyForPaint<typeof TimelineType>(
	() => import(/* webpackChunkName: "async-polaris-timeline" */ './index'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	if (__SERVER__) {
		return null;
	}
	return (
		<Placeholder name="polaris-timeline" fallback={<></>}>
			<LazyTimeline {...props} />
		</Placeholder>
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	startDate: {
		id: 'polaris-common.filters.filter-component.interval.content-absolute-dates.start-date',
		defaultMessage: 'Start date',
		description:
			'Label in the dropdown for Start date visible after choosing the Specific dates option',
	},
	endDate: {
		id: 'polaris-common.filters.filter-component.interval.content-absolute-dates.end-date',
		defaultMessage: 'End date',
		description:
			'Label in the dropdown for End date visible after choosing the Specific dates option',
	},
	bigBang: {
		id: 'polaris-common.filters.filter-component.interval.content-absolute-dates.big-bang',
		defaultMessage: 'Big bang',
		description: 'Placeholder for Start date datepicker',
	},
	forever: {
		id: 'polaris-common.filters.filter-component.interval.content-absolute-dates.forever',
		defaultMessage: 'Forever',
		description: 'Placeholder for End date datepicker',
	},
});

import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from '../../messages.tsx';
import { Wrapper } from '../../styled.tsx';

export const FieldGroupedSum = ({ val }: { val: number }) => {
	const { formatMessage, formatNumber } = useIntl();
	const valFormatted = formatNumber(val, {
		maximumFractionDigits: 1,
	});

	return <Wrapper>{formatMessage(messages.sumVal, { val: valFormatted })}</Wrapper>;
};

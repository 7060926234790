import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNotificationsTableComponent = lazy(
	() => import(/* webpackChunkName: "async-notifications-table" */ './main'),
);

export const AsyncNotificationsTable = (props: Props) => (
	<Placeholder name="notifications-table" fallback={null}>
		<LazyNotificationsTableComponent {...props} />
	</Placeholder>
);

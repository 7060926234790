import React from 'react';
import { Box } from '@atlaskit/primitives';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { useIssueTypeAvatarId } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/issue-type-avatar.tsx';
import { useConnectionFieldIssueTypeId } from '../../../controllers/selectors/field-hooks.tsx';

export const ConnectionFieldEmojiIcon = () => {
	const issueTypeId = useConnectionFieldIssueTypeId() ?? '';
	const avatarId = useIssueTypeAvatarId({ issueTypeId });

	if (!issueTypeId || !avatarId) {
		return <EmojiPicker readonly />;
	}

	return (
		<Box paddingBlockStart="space.050" paddingInlineStart="space.075">
			<IssueTypeAvatar avatarId={avatarId} size="xsmall" />
		</Box>
	);
};

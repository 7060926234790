import React from 'react';
import { ColumnDndOverlay } from './column-dnd/index.tsx';
import { MovedRowOverlay } from './moved-row/index.tsx';

export const OverlayRenderer = ({ isBulkEditDialogShown }: { isBulkEditDialogShown: boolean }) => (
	<>
		<ColumnDndOverlay />
		<MovedRowOverlay isBulkEditDialogShown={isBulkEditDialogShown} />
	</>
);

import React, { type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type Props = {
	onCancel: () => void;
	onConfirm: (e: SyntheticEvent) => void;
};

const ConfirmDismissModal = (props: Props) => {
	const { formatMessage } = useIntl();
	const { onCancel = noop, onConfirm = noop } = props;

	return (
		<ModalDialog onClose={onCancel} width="small">
			<ModalHeader>
				<ModalTitle>{formatMessage(messages.title)}</ModalTitle>
			</ModalHeader>
			<ModalBody>{formatMessage(messages.description)}</ModalBody>

			<ModalFooter>
				<Button appearance="subtle-link" onClick={onCancel}>
					{formatMessage(messages.cancelButtonLabel)}
				</Button>
				<Button appearance="primary" onClick={onConfirm}>
					{formatMessage(messages.confirmButtonLabel)}
				</Button>
			</ModalFooter>
		</ModalDialog>
	);
};
export default ConfirmDismissModal;

import { useEffect, useMemo } from 'react';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createStore, type StoreActionApi } from '@atlassian/react-sweet-state';
import type {
	PolarisIdeasCommonContextType,
	PolarisCommentsUpdateSignal,
} from '../context/types.tsx';
import actions from './actions/index.tsx';
import type { Props, State } from './types.tsx';

export type Actions = typeof actions;

const EMPTY_STATE: State = {
	issueKey: undefined,
	meta: {
		isLoading: true,
		error: undefined,
		loadingProps: undefined,
		loadingLinkedIssues: false,
		issueStateCallbackHandled: false,
		isSingleIssueLoaded: false,
	},
	archivedMeta: {
		isLoading: true,
		loadingProps: undefined,
	},
	archivedLinkedIssues: [],
	fieldsSidebar: {
		mode: 'VALUES',
		fieldKey: undefined,
		optionId: undefined,
	},
	votes: undefined,
	watchInfo: undefined,
	issueLinks: [],
	comments: [],
	deliveryTickets: [],
	polarisDeliveryIssueLinkType: undefined,
	storyPointsCustomFieldId: undefined,
	attachments: {
		attachments: [],
		inUpload: [],
	},
	preloadedFields: undefined,
	containerProps: undefined,
	isEditingSummary: {
		id: '',
		value: false,
	},
};

const initialState = EMPTY_STATE;

export const IdeaViewStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisIdeaViewStore',
});

const onUpdateContainer = (
	{ getState, setState, dispatch }: StoreActionApi<State>,
	props: Props,
) => {
	const state = getState();
	// if issueKey changed
	if (props.issueKey !== state.issueKey) {
		if (props.issueKey) {
			setState({
				meta: { ...state.meta, error: undefined },
				issueKey: props.issueKey,
				deliveryTickets: props.initialDeliveryTickets,
			});
			dispatch(actions.loadIssue());
		} else {
			// reset state
			setState(initialState);
		}
	} else if (
		props.polarisIssueLinkTypes.polarisDeliveryIssueLinkType !==
			state.polarisDeliveryIssueLinkType ||
		props.arjConfiguration?.storyPointsCustomFieldId !== state.storyPointsCustomFieldId
	) {
		dispatch(actions.updateDeliveryTickets());
	}
};

export const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(IdeaViewStore, {
	onInit: () => onUpdateContainer,
	onUpdate: () => onUpdateContainer,
});

export const useIdeaActions = useActions;
export const createIdeaHook = createHook;
export const createHigherLevelIdeaHook = createHigherLevelHook;

export const IdeaContextHandler = ({ context }: { context: PolarisIdeasCommonContextType }) => {
	const { register, unregister } = context;
	const { fetchAndUpdateIdeaComments } = useIdeaActions();

	const listener = useMemo(
		() => ({
			onUpdateComments: ({ issueKey }: PolarisCommentsUpdateSignal) => {
				fetchAndUpdateIdeaComments(issueKey);
			},
		}),
		[fetchAndUpdateIdeaComments],
	);

	useEffect(() => {
		register(listener);
		return () => unregister(listener);
	}, [listener, register, unregister]);

	return null;
};

import { getOption } from '@atlassian/jira-polaris-remote-field/src/controllers/crud/jira/transform/utils.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { addGlobalFieldOption } from '../../services/jira/add-global-field-option/index.tsx';
import { deleteGlobalFieldOption } from '../../services/jira/delete-global-field-option/index.tsx';
import { getGlobalFieldContextId } from '../../services/jira/get-global-field-context-id/index.tsx';
import { getGlobalLabels } from '../../services/jira/get-global-labels/index.tsx';
import { moveGlobalFieldOption } from '../../services/jira/move-global-field-option/index.tsx';
import { renameGlobalFieldOption } from '../../services/jira/rename-global-field-option/index.tsx';
import type { GlobalFieldOptionRemote } from './types.tsx';

const DEFAULT_OPTION_WEIGHT = 1;

export const createNotImplementedJiraGlobalFieldOptionRemote = (): GlobalFieldOptionRemote => ({
	updateGlobalFieldOptionsRank: notImplementedError('updateGlobalFieldOptionsRank'),
	deleteGlobalFieldOption: notImplementedError('deleteGlobalFieldOption'),
	addGlobalFieldOptions: notImplementedError('addGlobalFieldOptions'),
	renameGlobalFieldOption: notImplementedError('renameGlobalFieldOption'),
	getGlobalFieldContextId: notImplementedError('getGlobalFieldContextId'),
	getGlobalLabels: notImplementedError('getGlobalLabels'),
});

export const createJiraGlobalFieldOptionRemote = (): GlobalFieldOptionRemote => ({
	updateGlobalFieldOptionsRank: async ({ fieldKey, contextId, optionIds }) => {
		await moveGlobalFieldOption(fieldKey, contextId, optionIds);
	},
	deleteGlobalFieldOption: async ({ fieldKey, optionId, contextId }) => {
		await deleteGlobalFieldOption(fieldKey, contextId, optionId);
	},
	addGlobalFieldOptions: async ({ fieldKey, values, contextId }) => {
		const options = await addGlobalFieldOption(fieldKey, contextId, values);

		return options.map((option) => getOption(option, DEFAULT_OPTION_WEIGHT));
	},
	renameGlobalFieldOption: async ({ fieldKey, contextId, optionId, value }) => {
		await renameGlobalFieldOption(fieldKey, contextId, optionId, value);
	},
	getGlobalFieldContextId: async ({ fieldKey }) => getGlobalFieldContextId(fieldKey),
	getGlobalLabels: ({ fieldKey }) => getGlobalLabels(fieldKey),
});

import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { EmptyColumn as DefaultEmptyColumn } from '@atlassian/jira-polaris-lib-board/src/ui/empty-column/index.tsx';
import messages from './messages.tsx';

export const EmptyColumn: BoardProps['components']['EmptyColumn'] = () => {
	const { formatMessage } = useIntl();
	const canManageCurrentView = useCanManageCurrentView();

	if (!canManageCurrentView) {
		return null;
	}

	return <DefaultEmptyColumn>{formatMessage(messages.emptyText)}</DefaultEmptyColumn>;
};

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectsResponse, DetailedProjectResponse } from './types.tsx';

const MAX_PROJECTS = 5;
const VALID_PROJECT_TYPES: string[] = ['business', 'service_desk', 'software'];

export const getProjectsEndpointUrl = (baseUrl: string): string =>
	`${baseUrl}/rest/api/2/project/search`;

export const getProjectEndpointUrl = (baseUrl: string): string => `${baseUrl}/rest/api/2/project`;

export const getProjectsForFilterUrl = (
	baseUrl: string,
	filter: string,
	detailed: boolean,
	requireBrowsePermission: boolean,
	maxResults: number = MAX_PROJECTS,
	projectType = '',
): string => {
	const urlSearchParams = new URLSearchParams();
	urlSearchParams.set('orderBy', 'NAME');
	urlSearchParams.set('startAt', '0');
	urlSearchParams.set('query', filter);
	urlSearchParams.set('maxResults', maxResults.toString());
	if (detailed) {
		urlSearchParams.set('expand', 'lead,issueTypes');
	}
	if (requireBrowsePermission) {
		urlSearchParams.set('action', 'browse');
	}
	if (VALID_PROJECT_TYPES.includes(projectType)) {
		urlSearchParams.set('typeKey', projectType);
	}

	const urlParams = `?${urlSearchParams.toString()}`;
	return `${getProjectsEndpointUrl(baseUrl)}${urlParams}`;
};

export const getProjectForId = (baseUrl: string, projectId: string): string =>
	`${getProjectEndpointUrl(baseUrl)}/${encodeURIComponent(projectId)}`;

export const getProjects = (
	baseUrl: string,
	filter = '',
	detailed = false,
	requireBrowsePermission = false,
	maxResults: number = MAX_PROJECTS,
	projectType = '',
): Promise<ProjectsResponse> =>
	fetchJson(
		getProjectsForFilterUrl(
			baseUrl,
			filter,
			detailed,
			requireBrowsePermission,
			maxResults,
			projectType,
		),
	);

export const getProject = (baseUrl: string, projectId = ''): Promise<DetailedProjectResponse> =>
	fetchJson(getProjectForId(baseUrl, projectId));

import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { messages } from './messages.tsx';

export const useOnAddedHiddenColumn = () => {
	const { success } = useNotifications();
	const { setHideEmptyColumns } = useViewActions();
	const { formatMessage } = useIntl();

	return useCallback(() => {
		const dismissFunction = success({
			title: formatMessage(messages.addingColumnSuccess),
			description: formatMessage(messages.addingColumnSuccessInvisibleDescription),
			actions: [
				{
					content: formatMessage(messages.showEmptyColumns),
					onClick: () => {
						setHideEmptyColumns(false);
						dismissFunction();
					},
				},
			],
		});
	}, [formatMessage, setHideEmptyColumns, success]);
};

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { isSafari as isBrowserSafari } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { useCurrentViewLayoutType } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IdeaCard } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';

const isSafari = isBrowserSafari();
const CARD_CLONE_WIDTH = 326;

export const Card: BoardProps['components']['Card'] = ({ id, dragStatus, isPreview }) => {
	const viewLayoutType = useCurrentViewLayoutType();

	if (isPreview) {
		return (
			<Box xcss={[cardCloneWrapperStyles]}>
				<IdeaCard
					id={id}
					hasHoverState
					viewLayoutType={viewLayoutType}
					hideEmptyFields
					hasMultilineStrings
					isDisabled
					disableLazyRendering
					cappedFieldsDisplay={isSafari}
				/>
			</Box>
		);
	}

	return (
		<IdeaCard
			id={id}
			hasHoverState={dragStatus !== 'dragging'}
			viewLayoutType={viewLayoutType}
			hideEmptyFields
			hasMultilineStrings
			isGrayedOut={dragStatus === 'dragging'}
			hoverBackgroundColor={token('elevation.surface.hovered', N20)}
		/>
	);
};

const cardCloneWrapperStyles = xcss({
	width: `${CARD_CLONE_WIDTH}px`,
});

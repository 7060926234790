import React, { useState, useRef, type FC } from 'react';
import { di } from 'react-magnetic-di';
// `DELAY_MS_SHOW` and `withOuterListeners` are not changed between `@ataslkit/profilecard` v13.x and v15.6.1
// so it's fine to use either `DELAY_MS_SHOW` and `withOuterListeners` from v13.x or v15.6.1
import {
	type ProfileClient,
	type ProfileCardAction,
	DELAY_MS_SHOW,
	withOuterListeners,
} from '@atlaskit/profilecard';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { ProfileCardLegacyWrapper } from '../../common/ui/legacy-profile-card/index.tsx';
import {
	useHideProfileCardDelayed,
	useEventListener,
} from '../../common/ui/legacy-profile-card/utils.tsx';
import exposeApiWithBridge from '../../services/expose-api/index.tsx';
import type { UserDataFromMonolith } from '../../types.tsx';
import { analyticsViewComponentName } from './constants.tsx';
import ProfileCardResourced from './profilecard-resourced/index.tsx';

const AnalyticsWrapper = AnalyticsEventToProps(analyticsViewComponentName, {
	onAnalytics: 'fired',
});
export const ProfileCardResourcedWithAnalytics = AnalyticsWrapper(
	withOuterListeners(ProfileCardResourced),
);

type Props = {
	baseUrl: string;
	profileClient: ProfileClient | null;
	cloudId: string;
	onGetActions: (accountId: string, cleanup?: () => void) => ProfileCardAction[];
	onProfileClick?: (arg1: string) => void;
};

const eventListenerOptions = {
	passive: true,
} as const;

// @ts-expect-error - TS2322 - Type '({ baseUrl, profileClient, cloudId, onGetActions, onProfileClick, }: Props) => unknown' is not assignable to type 'FC<Props>'.
export const ProfileCardLegacy: FC<Props> = ({
	baseUrl,
	profileClient,
	cloudId,
	onGetActions,
	onProfileClick,
}: Props) => {
	di(setTimeout);

	// Hooks
	const [userData, setUserData] = useState<unknown>(null);
	const [referenceElement, setReferenceElement] = useState<unknown>(null);
	const [showProfileCard, setShowProfileCard] = useState<boolean>(false);
	const [offset, setOffset] = useState<unknown>(null);
	const showTimeoutId = useRef<unknown>(null);

	const onMouseLeaveCancelShowCard = () => {
		if (showTimeoutId.current) {
			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(showTimeoutId.current);
		}
	};

	const onHideProfileCard = () => {
		setShowProfileCard(false);
		onMouseLeaveCancelShowCard();
	};

	const { setTriggerRef, setCardRef, unsetTriggerRef, unsetCardRef, getTimeoutId } =
		useHideProfileCardDelayed(true, false, onHideProfileCard);

	// Add event listener using our hook
	// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null'.
	useEventListener('mouseleave', unsetTriggerRef, referenceElement, eventListenerOptions);
	useEventListener(
		'mouseleave',
		onMouseLeaveCancelShowCard,
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null'.
		referenceElement,
		eventListenerOptions,
	);
	// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null'.
	useEventListener('mouseenter', setTriggerRef, referenceElement, eventListenerOptions);
	// @ts-expect-error - TS2345 - Argument of type 'Window & typeof globalThis' is not assignable to parameter of type 'HTMLElement | null'.
	useEventListener('scroll', onHideProfileCard, window, eventListenerOptions);

	const clearHideCardTimeout = (timeoutId: null | number) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
	};

	const setShowProfileCardDelayed = () => setTimeout(() => setShowProfileCard(true), DELAY_MS_SHOW);

	const setData = (data: UserDataFromMonolith, ref: HTMLElement | string, offsetValue: string) => {
		clearHideCardTimeout(getTimeoutId());
		setOffset(offsetValue || '0, 8px');
		setUserData(data);
		setReferenceElement(ref);
		showTimeoutId.current = setShowProfileCardDelayed();
	};

	const api = { setData, hideProfileCard: onHideProfileCard };
	exposeApiWithBridge(api);

	return (
		showProfileCard &&
		referenceElement && (
			<ProfileCardLegacyWrapper
				onMouseEnter={setCardRef}
				onMouseLeave={unsetCardRef}
				// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'HTMLElement'.
				referenceElement={referenceElement}
				// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'string | null'.
				offset={offset}
			>
				{() => (
					<ProfileCardResourcedWithAnalytics
						baseUrl={baseUrl}
						// @ts-expect-error - TS2571 - Object is of type 'unknown'.
						accountId={userData && userData.accountId}
						cloudId={cloudId}
						handleEscapeKeydown={onHideProfileCard}
						handleClickOutside={onHideProfileCard}
						profileClient={profileClient}
						// @ts-expect-error - TS2571 - Object is of type 'unknown'.
						actions={onGetActions(userData ? userData.accountId : '', () => {
							clearHideCardTimeout(getTimeoutId());
							onHideProfileCard();
						})}
						onProfileClick={onProfileClick}
					/>
				)}
			</ProfileCardLegacyWrapper>
		)
	);
};

export default ProfileCardLegacy;

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	clearButton: {
		id: 'polaris-component-view-sort-configuration.config-sort.field.clear-button',
		defaultMessage: 'Clear',
		description: 'Clear sorting rule',
	},
	fieldSelectPlaceholder: {
		id: 'polaris-component-view-sort-configuration.config-sort.field.field-select-placeholder',
		defaultMessage: 'Select field',
		description: '',
	},
	ascSortButton: {
		id: 'polaris-component-view-sort-configuration.config-sort.field.asc-sort-button',
		defaultMessage: 'Ascending',
		description: '',
	},
	descSortButton: {
		id: 'polaris-component-view-sort-configuration.config-sort.field.desc-sort-button',
		defaultMessage: 'Descending',
		description: '',
	},
});

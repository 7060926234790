import React, { useCallback } from 'react';
import { useOpenIdeaViewFieldSidebar } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view.tsx';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { MultiSelectField } from '@atlassian/jira-polaris-common/src/ui/fields/select/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal/index.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';
import { CellPaddingAdjust } from '../utils/cell-padding-adjust.tsx';

type EditableMultiSelectFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
	interactive: boolean;
};

export const EditableMultiSelectField = ({
	localIssueId,
	fieldKey,
	interactive,
}: EditableMultiSelectFieldProps) => {
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	const portalElement = useListPortalElement();
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const onConfigRequested = useCallback(
		(optionId?: string) => {
			openIdeaViewFieldSidebar(fieldKey, optionId);
		},
		[openIdeaViewFieldSidebar, fieldKey],
	);

	return (
		<CellPaddingAdjust right={4}>
			<MultiSelectField
				fieldKey={fieldKey}
				isActive={interactive}
				isEditable={isEditable && !isIdeaArchived}
				localIssueId={localIssueId}
				menuPortalTarget={portalElement}
				onConfigRequested={onConfigRequested}
				onUpdate={onUpdate}
			/>
		</CellPaddingAdjust>
	);
};

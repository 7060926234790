import { useMemo } from 'react';
import { PRINCIPAL_TYPES } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	AccessRoleError,
	Account,
	Group,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { transformRoleAriToRole } from '../../../../utils.tsx';
import type {
	ProcessedAdditionalAccessEntry,
	ProcessedGroupEntry,
	ProcessedUserEntry,
} from '../types.tsx';
import { sortPrincipals } from './utils.tsx';

export const useProcessedAdditionalAccessEntries = (
	additionalAccess: {
		profiles: Account[];
		groups: Group[];
	},
	currentUserAccountId?: AccountId,
	skippedAccountFailures?: {
		name: string;
		accountId: string;
		errorCode: AccessRoleError;
		avatarUrl?: string;
		requestedRole: AccessRole;
	}[],
): ProcessedAdditionalAccessEntry[] =>
	useMemo(() => {
		const user = additionalAccess.profiles.find(
			(profile) => currentUserAccountId === profile.accountId,
		);
		const currentUserAccess = user
			? {
					id: user.accountId,
					name: user.name,
					role: transformRoleAriToRole(user.role),
					avatarUrl: user.avatarUrl,
					userType: user.userType,
					type: PRINCIPAL_TYPES.PROFILE,
					isCurrentUser: true,
				}
			: undefined;

		const groups: ProcessedGroupEntry[] = additionalAccess.groups.map((group) => ({
			id: group.groupId,
			name: group.name,
			role: transformRoleAriToRole(group.role),
			type: PRINCIPAL_TYPES.GROUP,
		}));

		const skippedProfiles: ProcessedUserEntry[] =
			skippedAccountFailures?.map((skippedAccount) => ({
				id: skippedAccount.accountId,
				name: skippedAccount.name,
				role: skippedAccount.requestedRole,
				avatarUrl: skippedAccount.avatarUrl,
				userType: undefined,
				type: PRINCIPAL_TYPES.PROFILE,
				error: skippedAccount.errorCode,
			})) ?? [];

		const profiles: ProcessedUserEntry[] = additionalAccess.profiles
			.filter(
				(profile) =>
					profile.accountId !== currentUserAccountId &&
					!skippedProfiles.find((skippedProfile) => skippedProfile.id === profile.accountId),
			)
			.map((profile) => ({
				id: profile.accountId,
				name: profile.name,
				role: transformRoleAriToRole(profile.role),
				avatarUrl: profile.avatarUrl,
				userType: profile.userType,
				type: PRINCIPAL_TYPES.PROFILE,
			}));

		return [
			...(currentUserAccess ? [currentUserAccess] : []),
			...groups.sort(sortPrincipals),
			...profiles.concat(skippedProfiles).sort(sortPrincipals),
		];
	}, [
		additionalAccess.groups,
		additionalAccess.profiles,
		currentUserAccountId,
		skippedAccountFailures,
	]);

import { createSelector } from 'reselect';
import type { ColumnId } from '../../common/types/index.tsx';
import type { ColumnSizes } from '../../types.tsx';
import type { State } from '../types.tsx';

export const getColumnSizes = (state: State): ColumnSizes => state.columnSizes;
export const getColumnMinSizes = (state: State): ColumnSizes => state.columnMinSizes;
export const getHoveredColumn = (state: State): ColumnId | undefined => state.hoveredColumn;
export const getFixedColumns = (state: State): ColumnId[] => state.fixedColumns;
export const getHoverableColumns = (state: State): ColumnId[] => state.hoverableColumns;

export const getResizingColumn = (state: State): ColumnSizes | undefined => state.resizingCol;

export const isHoveredColumn = (columnId: ColumnId) =>
	createSelector(
		(state: State) => state.hoveredColumn,
		(hoveredColumnId) => hoveredColumnId === columnId,
	);

import type { Action } from '@atlassian/react-sweet-state';
import type { CollectionsTourSteps, State } from '../../../types.tsx';

export const setCollectionsTourSpotlightStep =
	(step: CollectionsTourSteps | null): Action<State> =>
	({ getState, setState }) => {
		const state = getState();

		setState({
			...state,
			collections: {
				...state.collections,
				tourSpotlightStep: step,
			},
		});
	};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageHeader: {
		id: 'polaris-ideas.settings.types.project-types-screen.page-header',
		defaultMessage: 'Types',
		description: 'Header of the project types settings page',
	},
	pageDescription: {
		id: 'polaris-ideas.settings.types.project-types-screen.page-descriptionNonFinal',
		defaultMessage:
			'This is some text that explains what this idea type is about and provides details regarding its purpose. Additionally, it helps users understand how to manage and resolve related tasks efficiently.',
		description: 'Description for the project types settings page',
	},
});

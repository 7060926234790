import React from 'react';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import IdeaView from '../../idea-view/async/index.tsx';

export const IdeaPanel = () => {
	const issueKey = useSelectedIssueKey();

	return (
		<IdeaView
			issueKey={issueKey}
			isSidebarPreview
			testId="polaris-ideas.ui.right-sidebar.idea.container"
		/>
	);
};

import { stringify } from 'query-string';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/operator/map';
import type { MentionContextIdentifier } from '@atlaskit/mention';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { TeamMember } from '@atlassian/jira-issue-view-common-types/src/user-type.tsx';
import { UserMentionProvider } from '@atlassian/jira-mention-provider/src/services/user-mention-provider/index.tsx';
import {
	toAccountId,
	type AccountId,
	type BaseUrl,
	type IssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';

const ACCESS_LEVEL = 'CONTAINER';
const USER_TYPE = 'TEAM';

type ServerTeamMember = {
	accountId: string;
	name: string;
};

type ServerUserTeam = {
	name: string;
	avatarUrl: string;
	accountId?: string;
	teamId?: string;
	nickname?: string;
	email?: string;
	members?: ServerTeamMember[];
	memberCount?: number;
	includesYou?: boolean;
};

type UserTeamMention = {
	id: string;
	name: string;
	avatarUrl: string;
	mentionName?: string;
	nickname?: string;
	accessLevel?: string;
	userType?: string;
	context?: {
		members: TeamMember[];
		memberCount: number;
		includesYou: boolean;
		teamLink: string;
	};
};

const getTeamUrl = (teamId: undefined | string) => {
	if (teamId == null || teamId === '') {
		return '';
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return `${window.location.origin}/jira/people/team/${teamId}?ref=jira$&src=issue`;
};

const transformTeamMember = ({ accountId, name }: ServerTeamMember): TeamMember => ({
	id: accountId,
	name,
});

export const transformUserTeamToMention = ({
	accountId,
	teamId,
	name,
	avatarUrl,
	members,
	memberCount,
	includesYou,
}: ServerUserTeam): UserTeamMention =>
	members
		? {
				id: toAccountId(teamId || ''),
				name,
				accessLevel: ACCESS_LEVEL,
				userType: USER_TYPE,
				avatarUrl,
				context: {
					members: members.map<TeamMember>((member) => transformTeamMember(member)),
					memberCount: memberCount || 0,
					includesYou: includesYou || false,
					teamLink: getTeamUrl(teamId),
				},
			}
		: {
				name,
				mentionName: name,
				nickname: name,
				id: toAccountId(accountId || ''),
				avatarUrl,
			};

export default class IssueUserTeamMentionProvider extends UserMentionProvider {
	constructor(
		baseUrl: BaseUrl,
		issueKey: IssueKey,
		loggedInAccountId: AccountId | null,
		projectType?: ProjectType | null,
		orgId?: string | null,
	) {
		super(baseUrl, issueKey, loggedInAccountId, projectType);
		this.orgId = orgId || '';
	}

	orgId: string | null;

	getQueryString = (query?: string, contextIdentifier?: MentionContextIdentifier) =>
		stringify({
			query: query || '',
			issueKey: this.issueKey,
			organizationId: this.orgId,
			maxResults: 20,
			...contextIdentifier,
		});

	getUrl = (queryString: string) =>
		`${this.baseUrl}/rest/internal/2/userteam/mention/search?${queryString}`;

	search(query?: string, context?: MentionContextIdentifier) {
		const queryString = this.getQueryString(query, context);
		const url = this.getUrl(queryString);
		this.activeSearches.add(query || '');

		return (
			// @ts-expect-error - TS2769 - No overload matches this call.
			fetchJson$(url)
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				.mergeMap((users) => users.map(transformUserTeamToMention))
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/js/no-reduce-accumulator-spread
				.reduce<Array<any>>((mentions, mention) => [...mentions, mention], [])
				.map((mentions) => ({ query: query || '', mentions }))
				.toPromise()
		);
	}
}

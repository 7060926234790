import React, { useState, useCallback } from 'react';
import { useCanModifyReporter } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { User as UserType } from '../../../../common/types/user/index.tsx';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useUserByAccountId } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { useCurrentUserAccountId } from '../../../../controllers/user/index.tsx';
import type { FieldComponentProps } from '../types.tsx';
import { UserField } from './user-field/index.tsx';

type UserFieldProps = FieldComponentProps & {
	fieldKey: FieldKey;
};

export const UserFieldComponent = ({
	fieldKey,
	onUpdate,
	isEditable,
	...fieldProps
}: UserFieldProps) => {
	const accountId = useCurrentUserAccountId();
	const userInfo = useUserByAccountId(fieldKey, accountId ?? '');
	const [value, setValue] = useState<UserFieldValue | undefined>(userInfo);
	const field = useField(fieldKey);
	const [hasModifyReportPermission] = useCanModifyReporter();

	const updateFieldValue = useCallback(
		(user?: UserType) => {
			const displayUser: UserFieldValue | undefined = user
				? {
						accountId: user.id,
						displayName: user.name || '',
						avatarUrls: {
							'16x16': user.avatarUrl ?? '',
							'24x24': user.avatarUrl ?? '',
							'32x32': user.avatarUrl ?? '',
							'48x48': user.avatarUrl ?? '',
						},
					}
				: undefined;
			setValue(displayUser);
			onUpdate(displayUser);
		},
		[onUpdate],
	);

	if (field === undefined) {
		return null;
	}

	let isFieldEditable = isEditable;

	if (fieldKey === REPORTER_FIELDKEY) {
		isFieldEditable = hasModifyReportPermission && isEditable;
	}

	return (
		<UserField
			{...fieldProps}
			user={value}
			fieldKey={fieldKey}
			isEditable={isFieldEditable}
			onUpdate={(user?: UserType) => updateFieldValue(user)}
		/>
	);
};

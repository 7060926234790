import gqlTagPolaris from 'graphql-tag';
import { INSIGHT_FRAGMENT_WITH_FULL_SNIPPET } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/insight/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_CreateInsight_createPolarisInsight_node as CreatedInsight,
	jira_polaris_CreateInsightVariables as CreateInsightVariables,
	jira_polaris_CreateInsight as CreateInsight,
} from './__generated_apollo__/jira_polaris_CreateInsight';

const CREATE_INSIGHT_QUERY = gqlTagPolaris`
mutation jira_polaris_CreateInsight($input: CreatePolarisInsightInput!) {
  createPolarisInsight(input: $input) {
    success
    errors {
        message
    }
    node {
      ...Insight
    }
  }
}
${INSIGHT_FRAGMENT_WITH_FULL_SNIPPET}
`;

export const createInsight = async (
	apolloClient: PolarisApolloClient,
	input: CreateInsightVariables['input'],
): Promise<CreatedInsight> => {
	const result = await apolloClient.mutate<CreateInsight>({
		mutation: CREATE_INSIGHT_QUERY,
		variables: {
			input,
		},
	});

	if (!result.data?.createPolarisInsight?.success) {
		throw new Error('polaris-insights.create-insight-error: no error but not successful either');
	}

	if (result.data.createPolarisInsight.errors) {
		throw new Error(
			`polaris-insights.create-insight-error:${result.data.createPolarisInsight.errors
				.map(({ message }) => message)
				.join(', ')}`,
		);
	}

	if (!result.data.createPolarisInsight.node) {
		throw new Error('polaris-insights.create-insight-error: node is undefined');
	}

	return result.data.createPolarisInsight.node;
};

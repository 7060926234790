import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import { normalizeAggregatedDeliveryProgressResponse } from '../../common/normalize-aggregated-delivery-progress-response/index.tsx';
import type { AggregatedDeliveryProgressResponse } from '../../jira/types.tsx';

const getDeliveryUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/public/views/${viewId}/delivery-data`;

export const fetchDeliveryData = (viewId: string): Promise<AggregatedDeliveryProgressResponse> =>
	fetchJson(getDeliveryUrl(viewId)).then(normalizeAggregatedDeliveryProgressResponse);

import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	type RefCallback,
} from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { BoardApi } from '../../types/api.tsx';
import type { DropResult } from '../../types/draggable.tsx';
import type { BoardWithGroupsProps } from '../../types/ui.tsx';
import { getGroupIdentityPairForDroppableId } from '../../utils/board.tsx';
import { useBoardDND, type UseBoardDNDProps } from '../../utils/draggable.tsx';
import { useSwimlanesDropCallback } from '../../utils/drop-handler.tsx';
import { useBoardVirtualization } from '../../utils/use-board-virtualization/main.tsx';
import { BoardDimensionsProvider } from '../board-dimensions-provider/index.tsx';
import { SwimlanesBody } from '../swimlanes/body/index.tsx';
import { Header } from '../swimlanes/header/index.tsx';

type BoardWithGroupsPropsWithRef = Omit<BoardWithGroupsProps, 'mode'> & {
	scrollContainerRef: RefCallback<HTMLDivElement>;
};

const BoardWithGroups = forwardRef<BoardApi | undefined, BoardWithGroupsPropsWithRef>(
	(
		{
			collapsedSwimlanes,
			columnsHighlightColors,
			components,
			extendedOptions,
			extendedVerticalOptions,
			groups,
			hideEmptyColumns,
			hideEmptyGroups,
			idsByColumn,
			idsByCell,
			unfilteredIdsByCell,
			isExporting,
			isFooterVisible,
			isMoveBetweenColumnsDisabled,
			isMoveBetweenGroupsDisabled,
			isReadOnly,
			isCardDropDisabledForColumn,
			onAllSwimlanesToggle,
			onCardDrag,
			onCardDrop,
			onCardDropChange,
			onCardDropClear,
			onCardDropStart,
			onCardDropVerticalChange,
			onCardDropVerticalClear,
			onColumnDrop,
			onRowDrop,
			onSwimlaneToggle,
			rankAfter,
			rankBefore,
			scrollContainerRef,
		},
		ref,
	) => {
		const boardRef = useRef<HTMLDivElement | null>(null);

		const setScrollPosition: BoardApi['setScrollPosition'] = useCallback((scrollTop: number) => {
			if (!boardRef.current) {
				return;
			}

			boardRef.current.scrollTop = scrollTop;
		}, []);

		useImperativeHandle(ref, () => {
			const boardApi: BoardApi = {
				setScrollPosition,
			};

			return boardApi;
		}, [setScrollPosition]);

		const handleDropCallback = useSwimlanesDropCallback({
			extendedOptions,
			extendedVerticalOptions,
			idsByCell,
			unfilteredIdsByCell,
			onCardDropChange,
			onCardDropClear,
			onCardDropStart,
			onCardDropVerticalChange,
			onCardDropVerticalClear,
			onColumnDrop,
			onRowDrop,
			rankAfter,
			rankBefore,
		});

		const handleCardDrop: UseBoardDNDProps['onDrop'] = useCallback(
			(...args) => {
				onCardDrop();
				handleDropCallback(...args);
			},
			[handleDropCallback, onCardDrop],
		);

		const shouldInterruptDnd = useCallback(
			({ source, destination }: DropResult) => {
				const [sourceGroupId, sourceColumnId] = getGroupIdentityPairForDroppableId(
					source?.droppableId,
				);
				const [destinationGroupId, destinationColumnId] = getGroupIdentityPairForDroppableId(
					destination?.droppableId,
				);

				return Boolean(
					(isMoveBetweenColumnsDisabled && sourceColumnId !== destinationColumnId) ||
						(isMoveBetweenGroupsDisabled && sourceGroupId !== destinationGroupId),
				);
			},
			[isMoveBetweenColumnsDisabled, isMoveBetweenGroupsDisabled],
		);

		useBoardDND({
			boardContainerRef: boardRef,
			onDrag: onCardDrag,
			onDrop: handleCardDrop,
			shouldInterruptDnd,
		});

		const onSwimlanesContainerRefChange = useCallback(
			(element: HTMLDivElement | null) => {
				boardRef.current = element;
				scrollContainerRef(element);
			},
			[scrollContainerRef],
		);

		return (
			<Box
				ref={onSwimlanesContainerRefChange}
				xcss={[
					swimlanesContainerStyles,
					isExporting && overflowVisibleStyle,
					!isExporting && overflowAutoStyle,
				]}
				testId="polaris-lib-board.ui.board-with-groups.swimlanes-container"
				id="swimlanes-container"
			>
				<SwimlanesBody
					columnsHighlightColors={columnsHighlightColors}
					collapsedSwimlanes={collapsedSwimlanes}
					components={components}
					extendedOptions={extendedOptions}
					extendedVerticalOptions={extendedVerticalOptions}
					groups={groups}
					hideEmptyColumns={hideEmptyColumns}
					hideEmptyGroups={hideEmptyGroups}
					isReadOnly={isReadOnly}
					isFooterVisible={isFooterVisible}
					isCardDropDisabledForColumn={isCardDropDisabledForColumn}
					idsByColumn={idsByColumn}
					onSwimlaneToggle={onSwimlaneToggle}
				>
					<Header
						idsByColumn={idsByColumn}
						extendedOptions={extendedOptions}
						hideEmptyColumns={hideEmptyColumns}
						isReadOnly={isReadOnly}
						collapsedSwimlanes={collapsedSwimlanes}
						columnsHighlightColors={columnsHighlightColors}
						components={components}
						onAllSwimlanesToggle={onAllSwimlanesToggle}
					/>
				</SwimlanesBody>
			</Box>
		);
	},
);

BoardWithGroups.displayName = 'BoardWithGroups';

export const BoardWithGroupsWrapper = forwardRef<
	BoardApi | undefined,
	Omit<BoardWithGroupsProps, 'mode'>
>((props, ref) => {
	const {
		collapsedSwimlanes,
		columnsHighlightColors,
		components,
		extendedOptions,
		extendedVerticalOptions,
		groups,
		hideEmptyColumns,
		hideEmptyGroups,
		idsByColumn,
		idsByCell,
		unfilteredIdsByCell,
		isExporting,
		isFooterVisible,
		isMoveBetweenColumnsDisabled,
		isMoveBetweenGroupsDisabled,
		isReadOnly,
		isCardDropDisabledForColumn,
		onAllSwimlanesToggle,
		onCardDrag,
		onCardDrop,
		onCardDropChange,
		onCardDropClear,
		onCardDropStart,
		onCardDropVerticalChange,
		onCardDropVerticalClear,
		onColumnDrop,
		onRowDrop,
		onSwimlaneToggle,
		rankAfter,
		rankBefore,
	} = props;
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	const { groupColumnContentHeights, subscribeToColumnChanges, unsubscribeToColumnChanges } =
		useBoardVirtualization({
			groups,
			containerRef: scrollContainerRef,
			isReadOnly,
			isFooterVisible,
			isExporting,
			hasColumnWithHeaders: false,
		});

	const onScrollContainerRefChange = useCallback(
		(element: HTMLDivElement | null) => {
			scrollContainerRef.current = element;
		},
		[scrollContainerRef],
	);

	return (
		<>
			<BoardDimensionsProvider
				groupColumnContentHeights={groupColumnContentHeights}
				subscribeToColumnChanges={subscribeToColumnChanges}
				unsubscribeToColumnChanges={unsubscribeToColumnChanges}
			>
				<BoardWithGroups
					ref={ref}
					collapsedSwimlanes={collapsedSwimlanes}
					columnsHighlightColors={columnsHighlightColors}
					components={components}
					extendedOptions={extendedOptions}
					extendedVerticalOptions={extendedVerticalOptions}
					groups={groups}
					hideEmptyColumns={hideEmptyColumns}
					hideEmptyGroups={hideEmptyGroups}
					idsByColumn={idsByColumn}
					idsByCell={idsByCell}
					unfilteredIdsByCell={unfilteredIdsByCell}
					isExporting={isExporting}
					isFooterVisible={isFooterVisible}
					isMoveBetweenColumnsDisabled={isMoveBetweenColumnsDisabled}
					isMoveBetweenGroupsDisabled={isMoveBetweenGroupsDisabled}
					isReadOnly={isReadOnly}
					isCardDropDisabledForColumn={isCardDropDisabledForColumn}
					onAllSwimlanesToggle={onAllSwimlanesToggle}
					onCardDrag={onCardDrag}
					onCardDrop={onCardDrop}
					onCardDropChange={onCardDropChange}
					onCardDropClear={onCardDropClear}
					onCardDropStart={onCardDropStart}
					onCardDropVerticalChange={onCardDropVerticalChange}
					onCardDropVerticalClear={onCardDropVerticalClear}
					onColumnDrop={onColumnDrop}
					onRowDrop={onRowDrop}
					onSwimlaneToggle={onSwimlaneToggle}
					rankAfter={rankAfter}
					rankBefore={rankBefore}
					scrollContainerRef={onScrollContainerRefChange}
				/>
			</BoardDimensionsProvider>
		</>
	);
});

const swimlanesContainerStyles = xcss({
	flexDirection: 'column',
	display: 'flex',
	width: '100%',
	minHeight: '100%',
	justifyContent: 'flex-start',
	boxSizing: 'border-box',
	background: 'elevation.surface',
});

const overflowVisibleStyle = xcss({
	overflow: 'visible',
});

const overflowAutoStyle = xcss({
	overflow: 'auto',
});

import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State, MergeOption } from '../../types.tsx';

export const toggleMergeOption =
	(mergeOption: MergeOption): Action<State, Props> =>
	({ setState, getState }) => {
		const { mergeOptions: newMergeOptions } = getState();
		if (newMergeOptions[mergeOption]) {
			delete newMergeOptions[mergeOption];
		} else {
			newMergeOptions[mergeOption] = true;
		}
		setState({
			mergeOptions: newMergeOptions,
		});
	};

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { LinkedStatus } from '@atlassian/jira-polaris-common/src/ui/linked-issue-status/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type LinkedIssuesProgressFieldProps = {
	issueId: LocalIssueId;
	hideTooltip?: boolean;
};

export const LinkedIssuesStatus = ({ issueId, hideTooltip }: LinkedIssuesProgressFieldProps) => (
	<Box xcss={containerStyles}>
		<LinkedStatus localIssueId={issueId} hideTooltip={hideTooltip} />
	</Box>
);

const containerStyles = xcss({
	paddingLeft: 'space.100',
});

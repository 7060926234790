import React, { type ComponentPropsWithoutRef, useCallback, useEffect, useState } from 'react';
import { Modal, ModalTransition } from '@atlaskit/onboarding';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import {
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST,
} from '../../../../controllers/constants.tsx';
import { useOnboardingFlowsActions } from '../../../../controllers/index.tsx';
import { useTourDismissed } from '../../../../controllers/selectors/project-fields-hooks.tsx';
import benefitsModalIllustration from './assets/project-fields-benefits-modal.png';
import { messages } from './messages.tsx';

type ActionOnClick = NonNullable<
	NonNullable<ComponentPropsWithoutRef<typeof Modal>['actions']>[0]['onClick']
>;

export const ProjectFieldsBenefitsModal = () => {
	const [isActive, setIsActive] = useState(true);
	const { formatMessage } = useIntl();
	const {
		loadProjectFieldsTourDismissed,
		setProjectFieldsTourDismissed,
		setProjectFieldsTourSpotlightStep,
	} = useOnboardingFlowsActions();
	const isTourDismissed = useTourDismissed();

	useEffect(() => {
		loadProjectFieldsTourDismissed();
	}, [loadProjectFieldsTourDismissed]);

	const startTour = useCallback<ActionOnClick>(
		(_, analyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'startTour');
			setIsActive(false);
			setProjectFieldsTourSpotlightStep(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1);
		},
		[setProjectFieldsTourSpotlightStep],
	);
	const dismissTour = useCallback<ActionOnClick>(
		(_, analyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'dismiss');
			setIsActive(false);
			setProjectFieldsTourSpotlightStep(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST);
			setProjectFieldsTourDismissed();
		},
		[setProjectFieldsTourSpotlightStep, setProjectFieldsTourDismissed],
	);

	const isModalActive = isActive && isTourDismissed === false;

	return (
		<ModalTransition>
			{isModalActive && (
				<ContextualAnalyticsData sourceType={MODAL} sourceName="projectFieldsBenefits">
					<FireScreenAnalytics />
					<Modal
						actions={[
							{
								onClick: startTour,
								text: formatMessage(messages.modalStartTourButton),
							},
							{
								onClick: dismissTour,
								text: formatMessage(messages.modalDismissButton),
							},
						]}
						heading={formatMessage(messages.modalTitle)}
						image={benefitsModalIllustration}
					>
						<Text as="p">{formatMessage(messages.modalBody)}</Text>
					</Modal>
				</ContextualAnalyticsData>
			)}
		</ModalTransition>
	);
};

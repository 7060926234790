// user property key for showing delivery popup open for the first time for the user
export const DELIVERY_RESOURCES_POPUP_CLOSED_KEY = 'polaris.delivery-resources-popup-closed';

// links to resources
export const HANDBOOK_URL =
	'https://www.atlassian.com/software/jira/product-discovery/resources/discovery-handbook';
export const COMMUNITY_URL = 'https://community.atlassian.com/t5/Jira-Product-Discovery/ct-p/jpd';

export const LINK_IDEA_TO_ISSUE_ARTICLE_ID = '1Ai3TsqglX7sjaIkA4eA9D';
export const LINK_IDEA_TO_ISSUE_SUPPORT_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/link-an-idea-to-a-jira-issue/';

export const AUTOFILL_DATE_FIELDS_ARTICLE_ID = '2sUGkk9iwLVMuitSDQPtem';
export const AUTOFILL_DATE_FIELDS_SUPPORT_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/configure-autofill-dates/';

export const JPD_PLANS_INTEGRATION_ARTICLE_ID = '';
export const JPD_PLANS_INTEGRATION_SUPPORT_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/jira-product-discovery-and-jira-plans/';

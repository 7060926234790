import React, { useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { CROSS_PROJECT_LIST_VIEW_FIELDS_DISMISSED_KEY } from '../../../../controllers/constants.tsx';
import { useOnboardingFlowsActions } from '../../../../controllers/index.tsx';
import { useCrossProjectListViewFieldsDismissed } from '../../../../controllers/selectors/cross-project-view-hooks.tsx';
import { CrossProjectViewFieldsOnboarding } from '../onboarding-fields/index.tsx';
import { messages } from './messages.tsx';

export const CrossProjectListViewFieldsOnboarding = () => {
	const isCrossProjectListViewFieldsDismissed = useCrossProjectListViewFieldsDismissed();
	const { loadCrossProjectViewFieldsDismissed } = useOnboardingFlowsActions();
	const { formatMessage } = useIntl();

	useEffect(() => {
		loadCrossProjectViewFieldsDismissed(CROSS_PROJECT_LIST_VIEW_FIELDS_DISMISSED_KEY);
	}, [loadCrossProjectViewFieldsDismissed]);

	if (isCrossProjectListViewFieldsDismissed !== false) {
		return null;
	}

	return (
		<CrossProjectViewFieldsOnboarding
			stateKey={CROSS_PROJECT_LIST_VIEW_FIELDS_DISMISSED_KEY}
			title={formatMessage(messages.title)}
			body={formatMessage(messages.body)}
		/>
	);
};

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { AsyncSelect, type AsyncSelectProps } from '@atlaskit/select';
import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import { type Ari, getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useSelectedDeliveryProject } from '../../../controllers/project/selectors/project-hooks.tsx';
import { getLinkIssuesProjectPermissions } from '../../../services/jira/check-link-issues-project-permission/index.tsx';
import {
	getProject,
	type JiraProjectGetResponse,
} from '../../../services/jira/get-project/index.tsx';
import { searchProjects } from '../../../services/jira/search-projects/index.tsx';
import messages from './messages.tsx';
import { OptionToSelect, SelectedOption, type SelectedProject } from './option/index.tsx';

export const PROJECT_TYPES_WITHOUT_JPD = [SOFTWARE_PROJECT, CORE_PROJECT, SERVICE_DESK_PROJECT];

type Props = {
	width?: string;
	maxWidth?: string;
	isAttachedToBody: boolean;
	includeProjectTypes?: string[];
	onProjectSelected: (selectedProject?: SelectedProject) => void;
	selectedProject: SelectedProject | undefined;
	autoFocus?: boolean;
};

type SelectOption = {
	label: string;
	value: string;
	item: unknown;
};

type OnChangeFunctionType = Required<AsyncSelectProps<SelectedProject>>['onChange'];

type ProjectSelectProps = {
	autoFocus?: boolean;
	searchString: string | undefined;
	width: string | undefined;
	maxWidth: string | undefined;
	selectedProject: SelectedProject | undefined;
	isAttachedToBody: boolean;
	defaultOptions:
		| {
				label: string;
				options: SelectOption[];
		  }[]
		| undefined;
	onLoadOptions: (search?: string) => Promise<
		{
			label: string;
			options: SelectOption[];
		}[]
	>;
	onProjectSelected: (selectedProject?: SelectedProject | null) => void;
};

const ProjectSelect = ({
	searchString,
	selectedProject,
	defaultOptions,
	onLoadOptions,
	onProjectSelected,
	isAttachedToBody,
	width,
	maxWidth,
	autoFocus,
}: ProjectSelectProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const noOptionsMessage = () => {
		if (searchString !== undefined) {
			return formatMessage(messages.noProjectsFound);
		}
		return formatMessage(messages.typeToSearch);
	};

	const handleChangeNext = useCallback<OnChangeFunctionType>(
		(project?: SelectedProject | null) => {
			onProjectSelected(project);
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
				'project',
				{
					selectedProjectId: project?.item?.id,
				},
			);
		},
		[onProjectSelected, createAnalyticsEvent],
	);

	const handleBlur = useCallback(
		() => onProjectSelected(selectedProject),
		[onProjectSelected, selectedProject],
	);

	const fireDropdownToggleAnalyticsEvent = useCallback(
		({ isOpen }: { isOpen: boolean }) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
				'projectList',
				{
					dropdownOpen: isOpen,
				},
			);
		},
		[createAnalyticsEvent],
	);

	const onMenuOpen = useCallback(() => {
		fireDropdownToggleAnalyticsEvent({ isOpen: true });
	}, [fireDropdownToggleAnalyticsEvent]);

	const onMenuClose = useCallback(() => {
		fireDropdownToggleAnalyticsEvent({ isOpen: false });
	}, [fireDropdownToggleAnalyticsEvent]);

	return (
		<SelectWrapper width={width} maxWidth={maxWidth}>
			<AsyncSelect
				autoFocus={autoFocus}
				spacing="compact"
				isSearchable
				isClearable
				classNamePrefix="project-select"
				value={selectedProject || null}
				noOptionsMessage={noOptionsMessage}
				placeholder={formatMessage(messages.projectPickerPlaceholder)}
				defaultOptions={defaultOptions}
				loadOptions={onLoadOptions}
				onChange={handleChangeNext}
				onBlur={handleBlur}
				components={{
					Option: OptionToSelect,
					SingleValue: SelectedOption,
				}}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={isAttachedToBody ? document.body : undefined}
				styles={{
					// without properly set zIndex value dropdown with options is cut inside modal dialogs
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ignored via go/DSP-18766
					menuPortal: (base: any) => ({ ...base, zIndex: layers.modal }),
				}}
				onMenuOpen={onMenuOpen}
				onMenuClose={onMenuClose}
			/>
		</SelectWrapper>
	);
};

ProjectSelect.defaultProps = {
	isAttachedToBody: false,
};

type ProjectOption = {
	item: JiraProjectGetResponse;
	label: string;
	value: string;
};

const useFetchPreferredProject = (projectAri?: Ari) =>
	useMemo(
		() => (onFetched: (option: ProjectOption) => void) => {
			if (projectAri !== undefined) {
				const id = getAriConfig(projectAri).resourceId;
				return getLinkIssuesProjectPermissions(id)
					.then((projectPermissionsResponse) => {
						if (
							projectPermissionsResponse.permissions.BROWSE_PROJECTS.havePermission === true &&
							projectPermissionsResponse.permissions.LINK_ISSUES.havePermission === true
						) {
							getProject(id).then((response) => {
								const option = {
									label: response.name,
									value: `${response.id}`,
									item: response,
								};
								onFetched(option);
							});
						}
					})
					.catch(/* when the project cannot be accessed a 404 is returned - we just ignore the project in this case */);
			}
			return Promise.resolve();
		},
		[projectAri],
	);

const getGroupedOptions = (
	preferredOption: undefined | ProjectOption,
	options: Array<SelectOption>,
	formatMessage: IntlShape['formatMessage'],
	total: number,
) => {
	const searchResults = {
		label: formatMessage(messages.searchResultsGroupLabel, {
			maxResults: options.length,
			total,
		}),
		options,
	};
	if (preferredOption !== undefined) {
		return [
			{
				label: formatMessage(messages.preferredGroupLabel),
				options: [preferredOption],
			},
			searchResults,
		];
	}
	if (options.length > 0) {
		return [searchResults];
	}
	return [];
};

const useFetchProjects = (types: string[] = []) => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			(
				preferredProject: undefined | ProjectOption,
				search: undefined | string,
				onFetched: (newOptions: Array<{ label: string; options: Array<SelectOption> }>) => void,
			) =>
				searchProjects(search, types.join(',')).then((response) => {
					const newOptions = response.values.map((project) => ({
						label: project.name,
						value: `${project.id}`,
						item: project,
					}));
					const groupedOptions = getGroupedOptions(
						preferredProject,
						newOptions,
						formatMessage,
						response.total,
					);
					onFetched(groupedOptions);
				}),
		[formatMessage, types],
	);
};

export const ProjectPicker = ({
	onProjectSelected,
	includeProjectTypes,
	width,
	maxWidth,
	isAttachedToBody,
	selectedProject,
	autoFocus,
}: Props) => {
	const [searchString, setSearchString] = useState<string | undefined>(undefined);
	const [defaultOptions, setDefaultOptions] = useState<
		Array<{ label: string; options: SelectOption[] }>
	>([]);
	const fetchProjects = useFetchProjects(includeProjectTypes);
	const [selectedDeliveryProject] = useSelectedDeliveryProject();
	const fetchPreferredProject = useFetchPreferredProject(selectedDeliveryProject);
	const [preferredOption, setPreferredOption] = useState<ProjectOption | undefined>(undefined);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!initialized) {
			fetchPreferredProject((option) => {
				if (option !== undefined) {
					setPreferredOption(option);
					onProjectSelected(option);
				}
			}).catch();
			if (searchString === undefined) {
				fetchProjects(preferredOption, undefined, (newOptions) =>
					setDefaultOptions(newOptions),
				).catch();
			}
			setInitialized(true);
		}
	}, [
		fetchPreferredProject,
		fetchProjects,
		initialized,
		onProjectSelected,
		preferredOption,
		searchString,
	]);

	const loadOptions = (search?: string) => {
		setSearchString(search);
		return new Promise<Array<{ label: string; options: SelectOption[] }>>((resolve) => {
			if (search !== undefined) {
				fetchProjects(preferredOption, search, (newOptions) => {
					resolve(newOptions);
					setDefaultOptions(newOptions);
				}).catch();
			} else {
				resolve([]);
			}
		});
	};

	const handleProjectSelected = useCallback(
		(project?: SelectedProject | null) => {
			if (project === undefined || project === null) {
				setSearchString(undefined);
				onProjectSelected(undefined);
			} else {
				onProjectSelected(project);
			}
		},
		[onProjectSelected],
	);

	return (
		<ProjectSelect
			autoFocus={autoFocus}
			isAttachedToBody={isAttachedToBody}
			searchString={searchString}
			selectedProject={selectedProject}
			onProjectSelected={handleProjectSelected}
			onLoadOptions={loadOptions}
			defaultOptions={defaultOptions}
			width={width}
			maxWidth={maxWidth}
		/>
	);
};

ProjectPicker.defaultProps = {
	isAttachedToBody: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div<{
	width?: string | number;
	maxWidth?: string | number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => (width !== undefined ? width : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => (maxWidth !== undefined ? maxWidth : 'unset'),
	minWidth: '190px',
});

import isEqual from 'lodash/isEqual';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import messages from './messages.tsx';
import { DELIVERY_HAS_NO_TICKET, DELIVERY_HAS_TICKET, type UiFilter } from './types.tsx';

const HAS_TICKET_FILTER_VALUES: NumericFieldFilterValue[] = [
	{
		operator: 'GTE',
		numericValue: 1,
	},
];
export const deliveryHasTicketFilter: UiFilter = {
	id: DELIVERY_HAS_TICKET,
	isChecked: (values: NumericFieldFilterValue[]): boolean =>
		isEqual(values, HAS_TICKET_FILTER_VALUES),
	getValues: (): NumericFieldFilterValue[] | undefined => HAS_TICKET_FILTER_VALUES,
	label: messages.hasTicketFilterLabel,
	activeLabel: messages.hasTicketFilterActiveLabel,
};

const HAS_NO_TICKET_FILTER_VALUES: NumericFieldFilterValue[] = [
	{
		operator: 'LTE',
		numericValue: 0,
	},
];
export const deliveryHasNoTicketFilter: UiFilter = {
	id: DELIVERY_HAS_NO_TICKET,
	isChecked: (values: NumericFieldFilterValue[]): boolean =>
		isEqual(values, HAS_NO_TICKET_FILTER_VALUES),
	getValues: (): NumericFieldFilterValue[] => HAS_NO_TICKET_FILTER_VALUES,
	label: messages.hasNoTicketFilterLabel,
	activeLabel: messages.hasNoTicketFilterActiveLabel,
};

export const filters: UiFilter[] = [deliveryHasTicketFilter, deliveryHasNoTicketFilter];

import React, { useMemo } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import { useFieldCount } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useViewCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks.tsx';
import {
	useRankedIssueCount,
	useActiveIssueCount,
	useArchivedIssueCount,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';

export const CommonUfoData = () => {
	const viewCount = useViewCount();

	const issueCount = useRankedIssueCount();
	const issueCountActive = useActiveIssueCount();
	const issueCountArchived = useArchivedIssueCount();

	const fieldCount = useFieldCount();

	const data = useMemo(
		() => ({
			fieldCount,
			issueCount,
			issueCountActive,
			issueCountArchived,
			viewCount,
		}),
		[fieldCount, issueCount, issueCountActive, issueCountArchived, viewCount],
	);

	return <UFOCustomData data={data} />;
};

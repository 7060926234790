import { useMemo } from 'react';
import {
	useFieldEditable,
	useIsDeliveryFieldType,
	useIsReadOnlyFieldForGrouping,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useSortedIssueIds,
	useIsSorted,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import {
	useSelectedIssueIds,
	useCurrentViewContainsArchived,
	useCurrentViewVerticalGroupBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCanEditIssues,
	useCanRankIdeas,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

export const useVisibleSelectedIssueIds = (): LocalIssueId[] => {
	const sortedIssueIds = useSortedIssueIds();
	const selectedIds = useSelectedIssueIds();
	return useMemo(
		() => sortedIssueIds.filter((id) => selectedIds[id]),
		[selectedIds, sortedIssueIds],
	);
};

export const useIsEditableField = (fieldKey: FieldKey | undefined): boolean => {
	const [canEditIssues] = useCanEditIssues();

	const isEditable = useFieldEditable(fieldKey);
	const isDeliveryField = useIsDeliveryFieldType(fieldKey);

	return useMemo(
		() => canEditIssues && (isEditable || isDeliveryField),
		[isDeliveryField, isEditable, canEditIssues],
	);
};

export const useIsRankingAllowedDuringCreation = () => {
	const [canEditIssues] = useCanEditIssues();
	const canRankIdeas = useCanRankIdeas();
	const sorted = useIsSorted();
	const verticalGroupBy = useCurrentViewVerticalGroupBy();
	const isReadOnlyFieldForGrouping = useIsReadOnlyFieldForGrouping(verticalGroupBy?.key);
	const containsArchived = useCurrentViewContainsArchived();

	return (
		canEditIssues && canRankIdeas && !sorted && !isReadOnlyFieldForGrouping && !containsArchived
	);
};

import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import type { IconProps } from '@atlaskit/icon';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';

type FieldIconProps = IconProps & {
	fieldKey: FieldKey;
};
export const FieldIcon = ({ fieldKey, ...props }: FieldIconProps) => {
	const icon = useFieldTypeIcon(fieldKey, props);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	return emoji ? <Emoji emoji={emoji} fitToHeight={16} showTooltip /> : <>{icon}</>;
};

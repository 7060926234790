import type { SnippetData } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { loadPropertiesConfig } from '../load-propeties-config/index.tsx';

export const openDialog =
	({
		onConfigChanged,
		url,
		oauthClientId,
		data,
	}: {
		onConfigChanged?: () => void;
		url: string;
		oauthClientId: string;
		data: SnippetData;
	}): Action<State, Props> =>
	({ setState, dispatch }) => {
		setState({
			url,
			onConfigChanged: onConfigChanged || null,
			oauthClientId,
			snippetData: data,
		});
		dispatch(loadPropertiesConfig());
	};

import {
	createGetAllIssueIdsMatchingConnectionFieldFilters,
	createGetConnectionFieldIssueIds,
	createGetSelectedIssueConnectionFieldSortedIssueIds,
	createGetSelectedIssuesConnectionFieldIssuesCount,
	createIsIssueMatchingConnectionFieldFilter,
} from './connection.tsx';
import { useLocalIssueIdForJiraIssueId } from './issue-ids-hooks.tsx';
import { useIssueType, useSafeIssueKey, useSummary } from './properties/hooks.tsx';
import { useLinkedIssueDataByLinkedIssueId } from './properties/linked-issues/hooks.tsx';
import { createHigherLevelIssueHook2, createHigherLevelIssueHook } from './utils.tsx';

export const useConnectionFieldIssueIds = createHigherLevelIssueHook2(
	createGetConnectionFieldIssueIds,
);

export const useIsIssueMatchingConnectionFieldFilter = createHigherLevelIssueHook2(
	createIsIssueMatchingConnectionFieldFilter,
);
export const useAllIssueIdsMatchingConnectionFieldFilters = createHigherLevelIssueHook2(
	createGetAllIssueIdsMatchingConnectionFieldFilters,
);

export const useSelectedIssueConnectionFieldIssueIds = createHigherLevelIssueHook(
	createGetSelectedIssueConnectionFieldSortedIssueIds,
);
export const useSelectedIssuesConnectionFieldIssuesCount = createHigherLevelIssueHook(
	createGetSelectedIssuesConnectionFieldIssuesCount,
);

export const useConnectedIssueDataByIssueId = (jiraIssueId: string) => {
	const issueId = parseInt(jiraIssueId, 10);
	const localIssueId = useLocalIssueIdForJiraIssueId(jiraIssueId) ?? '';
	const summary = useSummary(localIssueId);
	const issueType = useIssueType(localIssueId);
	const issueKey = useSafeIssueKey(localIssueId) ?? '';
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(parseInt(jiraIssueId, 10));

	// in case of project issues we want to pull data from properties, so when summary or issueType change UI is updated
	if (localIssueId && issueType) {
		return {
			issueId,
			issueKey,
			summary,
			issueType,
		};
	}

	return linkedIssue;
};

import React, { type SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import findIndex from 'lodash/fp/findIndex';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_RENDER_LIST_VIEW_START,
	PAGE_LOAD_MARK_RENDER_LIST_VIEW_END,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project.tsx';
import {
	useIsSharedView,
	useIsCollectionView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useSortingAwareIssueRankingAction } from '@atlassian/jira-polaris-common/src/controllers/idea-ranking/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useCreatedIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/created-issues.tsx';
import { useIssueIdsConsideringArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks.tsx';
import {
	useLastUpdatedIssueIds,
	useLocalIssueIdToJiraIssueKeyMapper,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import {
	IssueCreateStatusInCreation,
	IssueCreateGroupTypeNoGroup,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewId,
	useCurrentViewSelectedIssueIdsAsList,
	useCurrentViewFields,
	useCurrentViewHideEmptyGroups,
	useCurrentViewContainsArchived,
	useHasSharedViewConfigError,
	useCurrentViewAccessLevel,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import {
	useHasNoProjectPermissions,
	useCanCreateAndEditIssues,
	useCanModifyReporter,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useProjectsKeysForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useViewExportActions } from '@atlassian/jira-polaris-component-view-export/src/controllers/main.tsx';
import {
	CREATOR_FIELDKEY,
	REPORTER_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import {
	fireCompoundAnalyticsEvent,
	useViewOpenedAnalytics,
} from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { List } from '@atlassian/jira-polaris-lib-list/src/main.tsx';
import type { Edge, ListApi } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import { ArchiveViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/archive-view/index.tsx';
import { CollectionViewNoProjectsEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/collection-view-no-projects/index.tsx';
import { ListViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/list-view/index.tsx';
import { PublishedViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/publish-view/index.tsx';
import {
	type AnalyticsEvent,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useDndActions } from '../../../controllers/dnd-v2/main.tsx';
import { useIsRankingAllowedDuringCreation, useVisibleSelectedIssueIds } from '../../utils.tsx';
import { useCurrentViewSuccessExperience } from '../common/utils/experience.tsx';
import { useRowGrouping } from '../common/utils/group-options.tsx';
import { Cell, GroupCell } from './cell/index.tsx';
import {
	useColumnIdsWithAdditionalColumns,
	useColumnMinWidths,
	useColumnWidthsWithDefaults,
	useEditableColumnsIds,
	useFixedColumns,
} from './column/index.tsx';
import { EmptyView } from './empty-view/index.tsx';
import { Header } from './header/index.tsx';
import { ListPortalProvider } from './portal/index.tsx';
import { RowGroup } from './row-group/index.tsx';
import { RowPinnedBottom } from './row-pinned-bottom/index.tsx';
import { DragHandleWithHighlight } from './row/drag-handle/index.tsx';
import { Row } from './row/index.tsx';
import {
	useCanCreateIdeasWhenGrouping,
	useCanCreateIdeasWhenNoGrouping,
} from './utils/can-create-ideas.tsx';
import { useColOps, useRowOps } from './utils/hooks.tsx';

const COMPONENTS = {
	Header,
	Cell,
	GroupCell,
	DragHandle: DragHandleWithHighlight,
	Row,
	RowGroup,
	RowPinnedBottom,
	EmptyView,
};

const useResetScrollPositionOnInlineCreation = (listApi: ListApi | undefined) => {
	const createdIssues = useCreatedIssues();

	useEffect(() => {
		// when List has enabled Group by and Sort or is just long and heavy, its inner table has issues with automatic resetting of the scroll position to 0
		// this hook manually resets the scroll position when a users activates Inline create mode
		if (!listApi) {
			return;
		}

		const keyIssueInCreation = Object.keys(createdIssues)
			// We need to reverse() here because Object.keys() returns
			// string keys in ascending chronological order of property creation
			// and we always want to scroll to the recently created input
			.reverse()
			.find((key) => createdIssues[key].status === IssueCreateStatusInCreation);

		const hasIssueInCreation = keyIssueInCreation !== undefined;

		if (!hasIssueInCreation) {
			return;
		}

		const rowIndex = listApi.getRowIndexById(keyIssueInCreation);
		if (rowIndex === 0) {
			listApi.scrollToRow(0);
			return;
		}

		if (rowIndex > 0) {
			listApi.ensureRowVisibleByIndex(rowIndex, {
				preferNextRowPinnedBottomVisibility: true,
				preferRowStickyVisibility: true,
			});
		}
	}, [createdIssues, listApi]);
};

type RowRankEndProps = {
	sourceId: string;
	targetId: string;
	dropEdge: Edge;
};

export const IdeaList = ({
	isExporting,
	isHidden,
	onReady,
}: {
	isExporting: boolean;
	isHidden: boolean;
	onReady: () => void;
}) => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_LIST_VIEW_START);
	});

	const isSharedView = useIsSharedView();
	const rowIds = useSortedIssueIds();
	const lastUpdatedIssueIds = useLastUpdatedIssueIds();
	const columnIds = useColumnIdsWithAdditionalColumns();
	const editableColumnIds = useEditableColumnsIds();
	const fixedColumns = useFixedColumns();
	const columnSizes = useColumnWidthsWithDefaults();
	const columnMinSizes = useColumnMinWidths();
	const keyMapper = useLocalIssueIdToJiraIssueKeyMapper();
	const moveIssue = useSortingAwareIssueRankingAction();
	const {
		cancelCreations,
		createIssue,
		execWithIssueAnalyticsData,
		resetLastUpdatedIssueIds,
		updateField,
	} = useIssueActions();
	const selectedIssue = useSelectedIssue();
	const selectedIssueIds = useCurrentViewSelectedIssueIdsAsList();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const viewAccessLevel = useCurrentViewAccessLevel();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();

	const issueIdsConsideringArchived = useIssueIdsConsideringArchived();
	const isArchiveView = useCurrentViewContainsArchived();
	const canCreateAndEditIssues = useCanCreateAndEditIssues();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();

	const onAddIdeaButtonClick = (_event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'createIdea', { isEmptyView: true });
		experience.listView.createIdeaInline.start();
		experience.listView.createIdeaInlineClickResponse.start();

		createIssue(0, undefined, {
			groupType: IssueCreateGroupTypeNoGroup,
			rankingAllowed: isRankingAllowedDuringCreation,
		});
	};
	const onLearnMoreAboutArchivingButtonClick = (
		_event: SyntheticEvent,
		analyticsEvent: AnalyticsEvent,
	) => {
		fireCompoundAnalyticsEvent.ListView.learnMoreAboutArchiveButtonClicked(analyticsEvent);
	};

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const currentViewId = useCurrentViewId();
	const {
		resizeFieldColumn,
		setSelection,
		setViewColumns,
		setHoveredFieldKeyForBulkEdit,
		setIsReadyToExport,
	} = useViewActions();
	const { setIsReadyToExportViewImage } = useViewExportActions();
	const selectedFields = useCurrentViewFields();
	const { openIssueView } = usePolarisRouter();

	const colOps = useColOps();
	const rowOps = useRowOps();

	const { setRowDraggingInfo } = useDndActions();

	const [verticalGroupByField, extendedOptions, rowGroups, groupedIds] = useRowGrouping();

	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const canCreateIdeasWhenNoGrouping = useCanCreateIdeasWhenNoGrouping(isExporting);
	const canCreateIdeasWhenGrouping = useCanCreateIdeasWhenGrouping({
		isExporting,
		fieldKey: verticalGroupByField?.key,
	});

	const [hasModifyReporterPermission] = useCanModifyReporter();
	const isMoveBetweenGroupsDisabled =
		(verticalGroupByField?.key === REPORTER_FIELDKEY && !hasModifyReporterPermission) ||
		verticalGroupByField?.key === CREATOR_FIELDKEY;

	const onReadyInner = useCallback(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_LIST_VIEW_END);
		onReady();
	}, [onReady]);

	useCurrentViewSuccessExperience();

	const analyticsCallback = useCallback(
		(accessLevel: string | undefined) => {
			fireCompoundAnalyticsEvent.ListView.opened(createAnalyticsEvent({}), accessLevel);
		},
		[createAnalyticsEvent],
	);

	useViewOpenedAnalytics({
		viewId: currentViewId,
		viewAccessLevel,
		isViewPermissionsEnabled,
		analyticsCallback,
		isSharedView,
	});

	const onItemRanked = useCallback(
		({ sourceId, targetId, dropEdge }: RowRankEndProps) => {
			execWithIssueAnalyticsData(sourceId, (issueAnalyticsData) => {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'dragged', actionSubject: 'listRow' }),
					'idea',
					issueAnalyticsData || {},
				);
			});

			moveIssue(
				sourceId,
				dropEdge === 'top' ? targetId : undefined,
				dropEdge === 'bottom' ? targetId : undefined,
			);
		},
		[createAnalyticsEvent, execWithIssueAnalyticsData, moveIssue],
	);

	const onOpenIdeaPreview = useCallback(
		(localIssueId: string) => {
			const issueKey = keyMapper(localIssueId);
			issueKey && openIssueView(issueKey, { layout: 'sidebar' });
		},
		[keyMapper, openIssueView],
	);

	const onItemGroupChanged = useCallback(
		({
			id,
			targetGroupId,
			sourceGroupId,
		}: {
			id: string;
			targetGroupId?: string;
			sourceGroupId?: string;
		}) => {
			if (verticalGroupByField !== undefined) {
				if (targetGroupId === undefined) {
					updateField(verticalGroupByField.key, false, id, undefined); // clear
					fireCompoundAnalyticsEvent.ListView.fieldValueUpdatedByDragBetweenSwimlanes(
						verticalGroupByField.type,
					);
				} else {
					const optionsByGroupIdentity = keyBy(
						extendedOptions,
						({ groupIdentity }) => groupIdentity,
					);

					// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'number | ExtendedOption<unknown> | (() => string) | (() => string) | (() => ExtendedOption<unknown> | undefined) | ((...items: ExtendedOption<unknown>[]) => number) | ... 29 more ... | ((index: number) => ExtendedOption<...> | undefined)'.
					const targetValue = optionsByGroupIdentity[targetGroupId].value;

					const sourceValue =
						sourceGroupId !== undefined
							? // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'number | ExtendedOption<unknown> | (() => string) | (() => string) | (() => ExtendedOption<unknown> | undefined) | ((...items: ExtendedOption<unknown>[]) => number) | ... 29 more ... | ((index: number) => ExtendedOption<...> | undefined)'.
								optionsByGroupIdentity[sourceGroupId].value
							: undefined;

					updateField(verticalGroupByField.key, true, id, targetValue, sourceValue);
					fireCompoundAnalyticsEvent.ListView.fieldValueUpdatedByDragBetweenSwimlanes(
						verticalGroupByField.type,
					);
				}
			}
		},
		[extendedOptions, updateField, verticalGroupByField],
	);

	const onColumnResize = useCallback(
		(columnId: string, width: number) => {
			resizeFieldColumn(currentViewId, columnId, width);
		},
		[currentViewId, resizeFieldColumn],
	);

	const onHoverActiveCellColumn = useCallback(
		(fieldKey?: FieldKey) => {
			!isEmpty(selectedIssueIds) && setHoveredFieldKeyForBulkEdit(fieldKey);
		},
		[selectedIssueIds, setHoveredFieldKeyForBulkEdit],
	);

	const onColumnRankEnd = useCallback(
		({ oldKey, newKey }: { oldKey: string; newKey: string }) => {
			const oldIndex = findIndex(['key', oldKey], selectedFields);
			const newIndex = findIndex(['key', newKey], selectedFields);

			const newFields = [...selectedFields];
			const [removed] = newFields.splice(oldIndex, 1);
			newFields.splice(newIndex, 0, removed);

			setViewColumns(newFields);
		},
		[selectedFields, setViewColumns],
	);

	const { rankingAllowed } = rowOps;

	const colRankingAllowed = colOps.columnReorderingAllowed;

	const onReadyToExport = useCallback(() => {
		if (fg('polaris_extract-view-export')) {
			setIsReadyToExportViewImage(true);
		} else {
			setIsReadyToExport(true);
		}
	}, [setIsReadyToExportViewImage, setIsReadyToExport]);

	const listApiRef = useRef<ListApi>();

	useResetScrollPositionOnInlineCreation(listApiRef.current);

	// cancel all issue creations that have not been confirmed yet when the component unmounts
	useEffect(() => () => cancelCreations(), [cancelCreations]);

	const isCollectionView = useIsCollectionView();
	const container = useEnvironmentContainer();
	const projectKeysForContainer = useProjectsKeysForContainer({ containerId: container?.id });

	if (isCollectionView && projectKeysForContainer.length === 0) {
		return <CollectionViewNoProjectsEmptyState />;
	}

	if (!isSharedView && issueIdsConsideringArchived.length === 0 && rowIds.length === 0) {
		if (isArchiveView) {
			return <ArchiveViewEmptyState onButtonClick={onLearnMoreAboutArchivingButtonClick} />;
		}

		return (
			<ListViewEmptyState
				onButtonClick={
					canCreateAndEditIssues && !isCollectionView ? onAddIdeaButtonClick : undefined
				}
				isCollectionView={isCollectionView}
			/>
		);
	}

	if (isSharedView && hasSharedViewConfigError) {
		return (
			<PublishedViewEmptyState
				permissionsLoaded={permissionsLoaded}
				hasProjectPermissions={!hasNoProjectPermissions}
			/>
		);
	}

	return (
		<UFOSegment name="jpd.list-view">
			<ExperienceFailErrorBoundary
				experience={experience.listView.pageSegmentLoad}
				metadata={{ isSharedView }}
			>
				<Wrapper
					data-testid="polaris-ideas.ui.view-content.idea-list.list-wrapper"
					hasBottomPadding={!isEmpty(visibleSelectedIssueIds)}
				>
					<ListPortalProvider>
						<List
							ref={listApiRef}
							key={`list-${currentViewId}`}
							rows={rowIds}
							rowGroups={rowGroups}
							groupedIds={groupedIds}
							updatedRows={lastUpdatedIssueIds}
							columns={columnIds}
							fixedColumns={fixedColumns}
							hoverableColumns={editableColumnIds}
							components={COMPONENTS}
							onItemRanked={rankingAllowed ? onItemRanked : undefined}
							onItemGroupChanged={rankingAllowed ? onItemGroupChanged : undefined}
							onGroupOrderChanged={undefined}
							onColumnResize={rowOps.columnResizeAllowed ? onColumnResize : undefined}
							onHoverActiveCellColumn={onHoverActiveCellColumn}
							onRowDragUpdate={setRowDraggingInfo}
							onColumnRankEnd={colRankingAllowed ? onColumnRankEnd : undefined}
							columnSizes={columnSizes}
							highlightedRow={selectedIssue}
							selectedRows={rowOps.selectionAllowed ? selectedIssueIds : undefined}
							onSelectionChanged={setSelection}
							columnMinSizes={columnMinSizes}
							onUpdatedRowsSeen={resetLastUpdatedIssueIds}
							onOpenIdeaPreview={onOpenIdeaPreview}
							isExporting={isExporting}
							hideEmptyGroups={hideEmptyGroups}
							isMoveBetweenGroupsDisabled={isMoveBetweenGroupsDisabled}
							onReadyToExport={onReadyToExport}
							onReady={onReadyInner}
							isHidden={isHidden}
							shouldRenderRowPinnedBottomForGroups={canCreateIdeasWhenGrouping}
							shouldRenderRowPinnedBottomForNoGroups={canCreateIdeasWhenNoGrouping}
						/>
					</ListPortalProvider>
				</Wrapper>
			</ExperienceFailErrorBoundary>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ hasBottomPadding?: boolean }>(
	{
		width: '100%',
		height: '100%',
		padding: '10px 0',
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasBottomPadding }) =>
		hasBottomPadding && {
			'.table__body > div': {
				paddingBottom: token('space.800', '64px'),
			},
		},
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useListIdeaCount } from './utils/idea-count';

import React from 'react';
import { useDateDecoration } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useIsIssueArchived,
	useStringValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { DateTimeField as CommonField } from '@atlassian/jira-polaris-common/src/ui/fields/datetime/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';

type DateFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
};

export const DateTimeField = ({ localIssueId, fieldKey }: DateFieldProps) => {
	const value = useStringValue(fieldKey, localIssueId);
	const decoration = useDateDecoration(fieldKey, value);
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	return (
		<CommonField
			value={value}
			decoration={decoration}
			isEditable={isEditable && !isIdeaArchived}
			onUpdate={onUpdate}
		/>
	);
};

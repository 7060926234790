import React, { memo, useRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { N100, B400 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { DEFAULT } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { ensureNonNullable } from '@atlassian/jira-polaris-lib-ts-utils/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	EMPTY_VALUE_ID,
	BOARD_COLUMN_GAP,
	BOARD_COLUMN_WIDTH,
	BOARD_COLUMN_PADDING,
	BOARD_COLUMN_BORDER_WIDTH,
} from '../../../constants.tsx';
import type { CustomComponents } from '../../../types/custom-components.tsx';
import { isDraggableCardData } from '../../../types/draggable.tsx';
import type { ColumnsHighlightColors } from '../../../types/ui.tsx';
import { useColumnDND } from '../../../utils/draggable.tsx';
import { CardList } from '../../card-list/index.tsx';
import { Footer } from '../../column/footer/index.tsx';

export type CellProps = {
	columnsHighlightColors: ColumnsHighlightColors;
	components: CustomComponents;
	droppableId: string;
	fieldValue: unknown;
	groupIdentity: string | undefined;
	hideEmptyColumns: boolean;
	ideaIds: string[];
	index: number;
	isCardDropDisabled: boolean;
	isEmptyColumn: boolean;
	isFooterVisible: boolean;
	isReadOnly: boolean;
	verticalFieldValue: unknown;
	verticalGroupIdentity: string | undefined;
};

export const Cell = memo(
	({
		columnsHighlightColors,
		components,
		droppableId,
		fieldValue,
		groupIdentity,
		hideEmptyColumns,
		ideaIds,
		index,
		isCardDropDisabled,
		isEmptyColumn,
		isFooterVisible,
		isReadOnly,
		verticalFieldValue,
		verticalGroupIdentity,
	}: CellProps) => {
		const cellContainerRef = useRef<HTMLDivElement | null>(null);

		const analyticAttributes = {
			columnNoValue: fieldValue === undefined,
			columnIndex: index,
			columnCardDropDisabled: isCardDropDisabled,
			columnCardCount: ideaIds.length,
		};

		const canDrop: Parameters<typeof useColumnDND>[0]['canDrop'] = ({ source }) =>
			isDraggableCardData(source.data) && !isCardDropDisabled;

		const { isCardBeingDraggedOver } = useColumnDND({
			draggableId: droppableId,
			droppableId,
			index,
			columnContainerRef: cellContainerRef,
			ideaIds,
			canDrop,
		});

		const { ColumnFooter } = components;

		return (
			<ContextualAnalyticsData attributes={analyticAttributes}>
				<CellContainer
					data-testid="polaris-lib-board.ui.swimlanes.cell.column"
					ref={cellContainerRef}
					backgroundColor={ensureNonNullable(columnsHighlightColors.get(groupIdentity))}
					isNoValueColumn={groupIdentity === undefined}
					isHidden={hideEmptyColumns && isEmptyColumn}
					isBeingDraggedOver={isCardBeingDraggedOver}
				>
					<CardList
						groupIdentity={verticalGroupIdentity}
						columnIdentity={`${verticalGroupIdentity || EMPTY_VALUE_ID}.${
							groupIdentity || EMPTY_VALUE_ID
						}`}
						droppableId={droppableId}
						isReadOnly={isReadOnly}
						components={components}
					/>
					{!isReadOnly && isFooterVisible && (
						<Footer
							groupIdentity={groupIdentity}
							fieldValue={fieldValue}
							fullWidth
							verticalFieldValue={verticalFieldValue}
							verticalGroupIdentity={verticalGroupIdentity}
							component={ColumnFooter}
						/>
					)}
				</CellContainer>
			</ContextualAnalyticsData>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CellContainer = styled.div<{
	isNoValueColumn: boolean;
	backgroundColor: string;
	isHidden: boolean;
	isBeingDraggedOver: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${BOARD_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${BOARD_COLUMN_WIDTH}px`,
	flex: '1 1 auto',
	minHeight: '100px',
	boxSizing: 'border-box',
	borderRadius: '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${BOARD_COLUMN_PADDING}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'flex'),
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-child)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginRight: `${BOARD_COLUMN_GAP}px`,
	},
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isNoValueColumn, backgroundColor }) =>
		isNoValueColumn
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.neutral', N100)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor === undefined || backgroundColor === DEFAULT.highlightColor
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.neutral', N100)
				: backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isBeingDraggedOver }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${BOARD_COLUMN_BORDER_WIDTH}px solid ${
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isBeingDraggedOver ? token('color.border.brand', B400) : 'transparent'
		}`,
	/* eslint-enable no-nested-ternary */
});

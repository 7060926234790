import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { MenuGroup, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import type { Watcher } from '@atlassian/jira-polaris-common/src/controllers/idea/types.tsx';
import { useCanManageWatchers } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { UserItem } from './user-item/index.tsx';

type Props = {
	users: Watcher[];
	headerText: string;
};

export const UsersList = ({ users, headerText }: Props) => {
	const { unWatchIssue } = useIdeaActions();
	const [canManageWatchers] = useCanManageWatchers();

	const handleDelete = useCallback(
		({ accountId }: Watcher) => {
			unWatchIssue(accountId);
		},
		[unWatchIssue],
	);

	return (
		<MenuGroup>
			<Section title={headerText}>
				<Container>
					{users.map((user) => (
						<UserItem
							key={user.accountId}
							userInfo={user}
							canDelete={canManageWatchers}
							onDelete={handleDelete}
						/>
					))}
				</Container>
			</Section>
		</MenuGroup>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"span[role='button']": {
		cursor: 'initial',
		padding: `${token('space.050', '4px')} ${token('space.150', '12px')} 5px`,
	},
});

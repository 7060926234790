import React, { type ReactElement, useMemo } from 'react';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { useIntl } from '@atlassian/jira-intl';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useAvailableProductsActions } from '../../../../../controllers/available-products/main.tsx';
import {
	useIsProductAvailableForSites,
	useIsAvailableProductsLoading,
	useAvailableProductsError,
} from '../../../../../controllers/available-products/selectors/available-products-hooks.tsx';
import { ProductType } from '../../../../../services/jira/available-products/types.tsx';
import messages from './messages.tsx';

type WithCheckProductAvailabilityProps = {
	aris: string[];
	children: (availableAri: string[], unavailableCloudIds: string[]) => ReactElement;
};

export const InternalWithCheckProductAvailability = ({
	aris,
	children,
}: WithCheckProductAvailabilityProps) => {
	const { formatMessage } = useIntl();
	const [, { loadAvailableProducts }] = useAvailableProductsActions();
	const [cloudsIds, ariToCloudIdMap] = useMemo(() => {
		const ids = new Set<string>();
		const map: {
			[key: string]: string;
		} = {};
		aris.forEach((v: string) => {
			try {
				const ari = getAriConfig(v);
				if (ari.cloudId) {
					ids.add(ari.cloudId);
					map[v] = ari.cloudId;
				}
			} catch {
				// do nothing
			}
		});
		return [Array.from(ids), map];
	}, [aris]);

	const [isProductAvailableForSites] = useIsProductAvailableForSites({
		productType: ProductType.ATLAS,
		cloudIds: cloudsIds,
	});

	const [isAvailableProductsLoading] = useIsAvailableProductsLoading();
	const [availableProductsError] = useAvailableProductsError();

	const [availableAri, unavailableCloudIds] = useMemo(() => {
		if (isAvailableProductsLoading) {
			return [[], []];
		}
		const available: string[] = [];
		const unavailable = new Set<string>();
		aris.forEach((ari: string) => {
			if (!ariToCloudIdMap[ari]) {
				return;
			}
			if (isProductAvailableForSites[ariToCloudIdMap[ari]]) {
				available.push(ari);
			} else {
				unavailable.add(ariToCloudIdMap[ari]);
			}
		});
		return [available, Array.from(unavailable)];
	}, [aris, ariToCloudIdMap, isProductAvailableForSites, isAvailableProductsLoading]);

	if (isAvailableProductsLoading) {
		return <Spinner />;
	}

	if (availableProductsError) {
		return <Button onClick={loadAvailableProducts}>{formatMessage(messages.refresh)}</Button>;
	}

	return children(availableAri, unavailableCloudIds);
};

export const WithCheckProductAvailability = ({
	aris,
	children,
}: WithCheckProductAvailabilityProps) => {
	const noClouds = useMemo(() => [], []);
	if (aris.length === 0) {
		return children(aris, noClouds);
	}
	return (
		<InternalWithCheckProductAvailability aris={aris}>
			{children}
		</InternalWithCheckProductAvailability>
	);
};

import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';

// throttle function, but only if the arguments are the same
export function memoizedLeadingDebounce<T extends (...args: Parameters<T>) => ReturnType<T>>(
	funcToThrottle: T,
	wait = 0,
): (...args: Parameters<T>) => ReturnType<T> | undefined {
	const memoizedFunc = memoize<(...args: Parameters<T>) => ReturnType<typeof debounce<T>>>(() => {
		return debounce<T>(funcToThrottle, wait, {
			leading: true,
			trailing: false,
		});
	});

	return (...args: Parameters<T>) => memoizedFunc(...args)(...args);
}

import React, {
	useCallback,
	useState,
	useMemo,
	type ChangeEvent,
	type SyntheticEvent,
} from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import LoadingButton from '@atlaskit/button/new';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Box } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { CopyToClipboard } from '@atlassian/jira-polaris-lib-copy-to-clipboard/src/ui/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import {
	ContextualAnalyticsData,
	MODAL,
	FireScreenAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { ModalTransitionUniversal } from '../../../../common/ui/modal-transition/index.tsx';
import { useFieldActions } from '../../../../controllers/field/main.tsx';
import {
	useFieldLabel,
	useIsGlobalCustomField,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useAllFieldValues } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useViewIdsConfiguredByField } from '../../../../controllers/views/selectors/view-hooks.tsx';
import { ViewsConfiguredByField } from '../views-configured-by-field/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	onClose: () => void;
	onDeleteConfirmed?: (() => void) | undefined;
};

export const DeleteFieldModal = ({ fieldKey, onClose, onDeleteConfirmed }: Props) => {
	const { formatMessage } = useIntl();
	const { deleteField } = useFieldActions();

	const viewIdsConfiguredByField = useViewIdsConfiguredByField(fieldKey);
	const allFieldValues = useAllFieldValues(fieldKey);

	const fieldLabel = useFieldLabel(fieldKey);

	const [confirmValue, setConfirmValue] = useState('');

	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);

	const issueFieldAnalyticsAttributes = useMemo(
		() => ({
			issueFieldKey: fieldKey,
			isGlobalField: isGlobalCustomField,
		}),
		[fieldKey, isGlobalCustomField],
	);

	const closeModal = useCallback(() => {
		experience.fieldsSidebar.fieldDelete.abort();
		onClose();
	}, [onClose]);

	const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

	const handleDeleteConfirm = useCallback(
		async (_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticEvent, isGlobalCustomField ? 'remove' : 'delete');
			if (fieldKey !== undefined) {
				setIsDeleteInProgress(true);
				onDeleteConfirmed?.();
				await deleteField(
					fieldKey,
					() => {
						experience.fieldsSidebar.fieldDelete.success();
					},
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(error?: any) => {
						if (isClientFetchError(error) || isPermissionError(error)) {
							experience.fieldsSidebar.fieldDelete.abort(error);
						} else {
							experience.fieldsSidebar.fieldDelete.failure(error);
						}
					},
				);
				setIsDeleteInProgress(false);
			}
			onClose();
		},
		[deleteField, isGlobalCustomField, fieldKey, onClose, onDeleteConfirmed],
	);

	const handleCancelClick = useCallback(
		(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticEvent, 'cancel');
			closeModal();
		},
		[closeModal],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const changeHandler = (newValue: any) => {
		setConfirmValue(newValue);
	};

	const getModalDescription = () => {
		const hasViews = !!viewIdsConfiguredByField.length;
		const hasValues = !!allFieldValues.length;

		if (isGlobalCustomField && hasViews) {
			return messages.descriptionGlobalFieldInUse;
		}

		if (isGlobalCustomField && !hasViews) {
			return messages.descriptionGlobalFieldNotInUse;
		}

		if (!hasValues && !hasViews) {
			return messages.descriptionNoValuesNoViews;
		}

		if (!hasValues && hasViews) {
			return messages.descriptionNoValuesHasViews;
		}

		if (hasValues && hasViews) {
			return messages.descriptionHasValuesHasViews;
		}

		return messages.descriptionHasValuesNoViews;
	};

	return (
		<ModalTransitionUniversal>
			{fieldKey !== undefined && (
				<ContextualAnalyticsData
					sourceName="confirmDeleteField"
					sourceType={MODAL}
					attributes={issueFieldAnalyticsAttributes}
				>
					<ShortcutScope>
						<ModalDialog onClose={closeModal}>
							<ModalHeader>
								<ModalTitle appearance="danger">
									{formatMessage(
										isGlobalCustomField ? messages.removeGlobalFieldHeader : messages.deleteHeader,
										{ fieldLabel },
									)}
								</ModalTitle>
							</ModalHeader>
							<ModalBody>
								<FireScreenAnalytics />
								<p>
									<FormattedMessage
										{...getModalDescription()}
										values={{
											viewsCount: viewIdsConfiguredByField.length,
											b: (chunks: React.ReactNode[]) => <BoldText>{chunks}</BoldText>,
										}}
									/>
								</p>
								<ViewsConfiguredByField fieldKey={fieldKey} />
								{!isGlobalCustomField && (
									<>
										<Box paddingBlockStart="space.150" paddingBlockEnd="space.100">
											{formatMessage(messages.confirmationMessage, {
												fieldName: <CopyToClipboard text={fieldLabel || ''} />,
											})}
										</Box>
										<TextField
											testId="polaris-common.ui.config.fields.delete-field.confirmation-input"
											maxLength={255}
											value={confirmValue}
											onChange={(event: ChangeEvent<HTMLInputElement>) =>
												changeHandler(event.target.value)
											}
											autoFocus
											isCompact
										/>
									</>
								)}
							</ModalBody>
							<ModalFooter>
								<Button onClick={handleCancelClick}>{formatMessage(messages.cancel)}</Button>
								<LoadingButton
									testId="polaris-common.ui.config.fields.delete-field.loading-button"
									onClick={handleDeleteConfirm}
									isDisabled={!isGlobalCustomField && confirmValue !== fieldLabel}
									appearance="danger"
									isLoading={isDeleteInProgress}
								>
									{formatMessage(isGlobalCustomField ? messages.remove : messages.delete)}
								</LoadingButton>
							</ModalFooter>
						</ModalDialog>
					</ShortcutScope>
				</ContextualAnalyticsData>
			)}
		</ModalTransitionUniversal>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldText = styled.span({
	fontWeight: token('font.weight.semibold'),
});

import React, { useCallback, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalTransition,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import type { OptionData } from '@atlaskit/user-picker';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { PermissionsSettingsProvider } from '../controllers/main.tsx';
import { CopyLinkButton } from './access-screen/copy-link-button/index.tsx';
import { AccessScreen, type AccessScreenProps } from './access-screen/index.tsx';
import { ErrorScreen } from './error-screen/index.tsx';
import { LoadingScreen } from './loading-screen/index.tsx';
import messages from './messages.tsx';

export type ViewAccessModalProps = {
	isOpen: boolean;
	isLoading: boolean;
	hasError: boolean;
	hideCopyLinkButton?: boolean;
	onClose: () => void;
	editingMode?: 'roadmap' | 'view';
	nonEditablePrincipalIds?: string[];
} & Omit<AccessScreenProps, 'userPickerOptions' | 'setUserPickerOptions'>;

export const ViewAccessModal = (props: ViewAccessModalProps) => {
	const {
		isOpen,
		isLoading,
		hasError,
		onClose,
		hideCopyLinkButton,
		onDuplicateView,
		editingMode = 'view',
		nonEditablePrincipalIds,
		...accessScreenProps
	} = props;
	const [userPickerOptions, setUserPickerOptions] = useState<OptionData[]>([]);
	const { formatMessage } = useIntl();

	const [shouldCloseOnEscapePress, setShouldCloseOnEscapePress] = useState(true);

	const handleCloseModal = useCallback(() => {
		onClose();
		setUserPickerOptions([]);
		setShouldCloseOnEscapePress(true);
	}, [onClose]);

	const handleMenuOpen = useCallback(() => {
		setShouldCloseOnEscapePress(false);
	}, []);

	const handleMenuClose = useCallback(() => {
		setShouldCloseOnEscapePress(true);
	}, []);

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<Modal
						onClose={handleCloseModal}
						autoFocus={false}
						shouldCloseOnEscapePress={shouldCloseOnEscapePress}
						width="large"
						height={570}
					>
						<PermissionsSettingsProvider
							editingMode={editingMode}
							nonEditablePrincipalIds={nonEditablePrincipalIds}
						>
							<ModalHeader>
								<ModalTitle>
									{formatMessage(
										editingMode === 'roadmap'
											? messages.roadmapRestrictionsLabel
											: messages.restrictionsLabel,
									)}
								</ModalTitle>
								<IconButton
									appearance="subtle"
									onClick={handleCloseModal}
									label={formatMessage(messages.modalCloseButtonLabel)}
									icon={CrossIcon}
								/>
							</ModalHeader>
							<ModalBody>
								<Box paddingBlockEnd="space.300" xcss={[modalBodyContentStyles]}>
									{isLoading && <LoadingScreen />}
									{hasError && !isLoading && <ErrorScreen />}
									{!isLoading && !hasError && (
										<AccessScreen
											{...accessScreenProps}
											onDuplicateView={onDuplicateView}
											userPickerOptions={userPickerOptions}
											setUserPickerOptions={setUserPickerOptions}
											onMenuOpen={handleMenuOpen}
											onMenuClose={handleMenuClose}
										/>
									)}
								</Box>
							</ModalBody>
							{!isLoading && !hasError && !hideCopyLinkButton && (
								<ModalFooter>
									<CopyLinkButton />
								</ModalFooter>
							)}
						</PermissionsSettingsProvider>
					</Modal>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};

const modalBodyContentStyles = xcss({
	height: '100%',
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionHeader: {
		id: 'polaris-component-view-sort-configuration.advanced.section-header',
		defaultMessage: 'More options',
		description: 'Header label for advanced sort settings',
	},
	viewRankIndicator: {
		id: 'polaris-component-view-sort-configuration.advanced.view-rank-indicator',
		defaultMessage: 'View rank',
		description: 'Text in the view rank indicator.',
	},
	noIssuesToRank: {
		id: 'polaris-component-view-sort-configuration.advanced.no-issues-to-rank',
		defaultMessage:
			'You can only rank ideas if there are at least 2 in the view. Add at least 2 ideas to enable manual ranking.',
		description: 'Text shown when there are not enough issues to rank.',
	},
	noPermissionsToRank: {
		id: 'polaris-component-view-sort-configuration.advanced.no-permissions-to-rank',
		defaultMessage:
			"You can't rank ideas because you don't have edit view permission. Contact your project admin to get the permission.",
		description: 'Text shown when there are no permissions to rank issues.',
	},
	noIssuesToRankAndNoPermissionToEdit: {
		id: 'polaris-component-view-sort-configuration.advanced.no-issues-to-rank-and-no-permission-to-edit',
		defaultMessage:
			'You need edit view permission to rank ideas, and there must be at least 2 ideas in the view.',
		description:
			'Text shown when there are not enough issues to rank and user has no permissions to edit.',
	},
});

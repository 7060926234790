import React, { useCallback, useMemo, type ChangeEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useCanPinIssueViewFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCanManageCurrentView } from '../../../../controllers/views/selectors/view-hooks.tsx';
import { type TogglePermissionType, TOGGLE_PERMISSIONS } from '../../constants.tsx';
import messages from './messages.tsx';

type FieldToggleProps = {
	fieldKey: FieldKey;
	isSelected: boolean;
	hasReachedViewFieldsLimit: string | null;
	testId: string;
	togglePermissionType: TogglePermissionType;
	onToggle: (arg1: FieldKey, arg2: boolean) => void;
};

type ToggleTooltipProps = {
	children: React.ReactNode;
	isSelected: boolean;
	hasReachedViewFieldsLimit: string | null;
	toggleFieldSelectionTooltipContent: string;
};

const ToggleTooltip = ({
	children,
	isSelected,
	hasReachedViewFieldsLimit,
	toggleFieldSelectionTooltipContent,
}: ToggleTooltipProps) => {
	if (!isSelected && hasReachedViewFieldsLimit) {
		return (
			<CreateEntityLimitTooltip message={hasReachedViewFieldsLimit}>
				{children}
			</CreateEntityLimitTooltip>
		);
	}

	return <Tooltip content={toggleFieldSelectionTooltipContent}>{children}</Tooltip>;
};

export const FieldToggle = ({
	fieldKey,
	isSelected,
	hasReachedViewFieldsLimit,
	testId,
	togglePermissionType,
	onToggle,
}: FieldToggleProps) => {
	const { formatMessage } = useIntl();
	const canManageCurrentView = useCanManageCurrentView();
	const canPinFields = useCanPinIssueViewFields();

	const canToggleFields =
		togglePermissionType === TOGGLE_PERMISSIONS.MANAGE_VIEWS ? canManageCurrentView : canPinFields;
	const isFieldHideable = fieldKey !== SUMMARY_FIELDKEY;

	const toggleFieldSelectionTooltipContent = useMemo(() => {
		if (!canToggleFields) {
			return togglePermissionType === TOGGLE_PERMISSIONS.PIN_FIELDS
				? formatMessage(messages.noPinIssueViewFieldsPermissions)
				: formatMessage(messages.noManageViewFieldsPermissions);
		}
		if (!isFieldHideable) {
			return formatMessage(messages.notRemoveable);
		}
		if (isSelected) {
			return formatMessage(messages.addField);
		}
		return formatMessage(messages.removeField);
	}, [canToggleFields, formatMessage, isFieldHideable, isSelected, togglePermissionType]);

	const handleToggle = useCallback(
		(event: ChangeEvent<HTMLInputElement>, analyticEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticEvent, 'issueField', {
				issueFieldUpdate: event.target.checked ? 'added' : 'removed',
				issueFieldKey: fieldKey,
			});
			onToggle(fieldKey, event.currentTarget.checked);
		},
		[fieldKey, onToggle],
	);

	return (
		<ToggleTooltip
			isSelected={isSelected}
			hasReachedViewFieldsLimit={hasReachedViewFieldsLimit}
			toggleFieldSelectionTooltipContent={toggleFieldSelectionTooltipContent}
		>
			<div data-interactive>
				<Toggle
					testId={testId}
					isChecked={isSelected}
					isDisabled={
						!isFieldHideable ||
						!canToggleFields ||
						(!isSelected && hasReachedViewFieldsLimit !== null)
					}
					onChange={handleToggle}
				/>
			</div>
		</ToggleTooltip>
	);
};

import React from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	onClear: () => void;
};

export const ClearFilterComponent = ({ onClear }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Button
			id="pendo.config-filters.clear-filters-button"
			appearance="subtle-link"
			onClick={() => onClear()}
		>
			{formatMessage(messages.clearAll)}
		</Button>
	);
};

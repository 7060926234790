import React, { type PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
	left?: number;
	right?: number;
}>;

export const CellPaddingAdjust = ({ children, left = 0, right = 0 }: Props) => {
	return (
		<div
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				width: `calc(100% + ${left + right}px)`,
				marginLeft: `-${left}px`,
				marginRight: `-${right}px`,
			}}
		>
			{children}
		</div>
	);
};

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LinkedIssue, Response, AggResponse } from './types.tsx';

const LOG_LOCATION = 'polaris.ideas.services.linked-issue-archived';

const AGG_QUERY = `query aggQuery($cloudId: ID! $jql: String! $limit: Int!) {
    jira {
        issueSearchStable(cloudId: $cloudId, issueSearchInput: { jql: $jql }, first: $limit) {
            edges {
              node {
                issueId
                fields {
                  edges {
                    node {
                      ... on JiraSingleSelectField {
                        __typename
                        id
                        name
                        fieldOption {
                          value
                        }
                      }
                      ... on JiraProjectField {
                        __typename
                        id
                        project {
                          projectType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }
}
`;

type AggVariables = {
	jql: string;
	cloudId: string;
	limit: number;
};

const getFetchOptions = (query: string, variables: AggVariables) => ({
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
		'X-ExperimentalApi': 'JiraIssueSearch',
	},
	body: JSON.stringify({
		query,
		variables,
	}),
});

const getData = <T extends Response>(graphQlResponse: AggResponse<T>) => {
	if (graphQlResponse.errors) {
		const firstError = graphQlResponse.errors[0];
		const validationError = new ValidationError(firstError.message);
		log.safeErrorWithoutCustomerData(
			LOG_LOCATION,
			'getGraphQlData: Error present in GraphQL response',
			validationError,
		);
		throw validationError;
	}
	return graphQlResponse.data;
};

export const fetchLinkedIssuesArchived = (
	jql: string,
	limit: number,
	cloudId: CloudId,
): Observable<LinkedIssue[]> =>
	fetchJson$('/gateway/api/graphql', getFetchOptions(AGG_QUERY, { jql, cloudId, limit }))
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter.
		.map((response) => getData(response))
		.flatMap((extractedData) => {
			if (!extractedData.jira?.issueSearchStable?.edges) {
				return Observable.throw(
					`unexpected shape of archived issue lookup with jql:[${jql}] and cloudId:[${cloudId}]`,
				);
			}

			const issues = extractedData.jira.issueSearchStable.edges.map((edge) => ({
				issueId: edge.node.issueId,
				fields: edge.node.fields.edges
					.map(({ node }) => {
						if (node.__typename === 'JiraProjectField') {
							return {
								__typename: 'ProjectField',
								id: node.id.substring(node.id.lastIndexOf('/') + 1),
								type: node.project.projectType.toLowerCase(),
							};
						}
						if (node.__typename === 'JiraSingleSelectField') {
							return {
								__typename: 'SelectCustomField',
								title: node.name,
								selectedValue: node.fieldOption,
							};
						}
						return undefined;
					})
					.filter((field) => field !== undefined),
			}));
			return Observable.of(issues);
		});

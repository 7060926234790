import React, { useState } from 'react';

import InlineDialog from '@atlaskit/inline-dialog';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import TextField from '@atlaskit/textfield';

import { useIntl } from '@atlassian/jira-intl';
import { MAX_NAME_LENGTH } from '../common/consts.tsx';
import { messages } from './messages.tsx';

type Props = {
	value: string;
	onChange: (value: string) => Promise<void>;
	isEditable: boolean;
};

export const InlineEditName = ({ value, onChange, isEditable }: Props) => {
	const [editValue, setEditValue] = useState(value);
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();

	const handleConfirm = (fieldValue: string) => {
		setEditValue(fieldValue);
		setIsLoading(true);
		onChange(fieldValue).finally(() => setIsLoading(false));
	};

	return (
		<InlineEdit
			isRequired
			defaultValue={value}
			label={formatMessage(messages.name)}
			editButtonLabel={editValue}
			validate={(fieldValue) => {
				const content = (fieldValue ?? '').trim();
				if (content.length === 0) {
					return formatMessage(messages.nameValidationErrorNoEmptyValue);
				}
			}}
			readViewFitContainerWidth
			hideActionButtons={!isEditable}
			editView={({ errorMessage, ...fieldProps }) => (
				<InlineDialog
					isOpen={fieldProps.isInvalid}
					content={<Box>{errorMessage}</Box>}
					placement="right"
				>
					<TextField
						{...fieldProps}
						autoFocus
						maxLength={MAX_NAME_LENGTH}
						isDisabled={!isEditable}
					/>
				</InlineDialog>
			)}
			readView={() => (
				<Box xcss={readViewContainerStyles}>
					<Text>{editValue}</Text>
					{isLoading && <Spinner size="small" label={formatMessage(messages.savingChanges)} />}
				</Box>
			)}
			onConfirm={handleConfirm}
		/>
	);
};

const readViewContainerStyles = xcss({
	font: 'font.body',
	paddingBlock: 'space.100',
	paddingInline: 'space.075',
	wordBreak: 'break-word',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 'space.100',
});

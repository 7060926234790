import React from 'react';
import { CheckboxField } from '../../checkbox/index.tsx';

type CheckboxNumberFieldProps = {
	value: number | undefined;
	onUpdate?: ((inputValue: number | undefined) => void) | undefined;
	isEditable?: boolean;
};

export const CheckboxNumberField = ({ value, onUpdate, isEditable }: CheckboxNumberFieldProps) => {
	const handleConfirm = (newValue: number) => {
		onUpdate?.(newValue);
	};

	return (
		<CheckboxField
			value={value}
			onChange={isEditable ? handleConfirm : undefined}
			isDisabled={isEditable === false}
		/>
	);
};

import React, { useState, useMemo, useCallback } from 'react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { Flex, Pressable, Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	PROJECT_RANK,
	VIEW_RANK,
	FIELD_SORT,
} from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import { FieldSortContent } from './field-sort/index.tsx';
import messages from './messages.tsx';
import { ProjectRankContent } from './project-rank/index.tsx';
import { ViewRankContent } from './view-rank/index.tsx';

export type AdvancedSortingPanelProps = {
	sortMode?: SortMode | undefined;
	activeIssueCount: number;
	canManageAdvancedSortConfig: boolean;
	setSortModeOfCurrentViewToViewRankAndAddCurrentSort: () => void;
	setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort: () => void;
	setSortModeOfCurrentViewToProjectRankAndCommitViewRank: () => void;
	setSortModeOfCurrentViewToProjectRank: (clearIssueRanking?: boolean | undefined) => void;
	setSortModeToViewRank: () => void;
};

export const AdvancedSortingPanel = ({
	sortMode,
	activeIssueCount,
	canManageAdvancedSortConfig,
	setSortModeOfCurrentViewToViewRankAndAddCurrentSort,
	setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort,
	setSortModeOfCurrentViewToProjectRankAndCommitViewRank,
	setSortModeOfCurrentViewToProjectRank,
	setSortModeToViewRank,
}: AdvancedSortingPanelProps) => {
	const { formatMessage } = useIntl();
	const [expanded, setExpanded] = useState<boolean>(false);

	const disabledButtonMessage = useMemo(() => {
		if (activeIssueCount < 2 && !canManageAdvancedSortConfig) {
			return formatMessage(messages.noIssuesToRankAndNoPermissionToEdit);
		}

		if (activeIssueCount < 2) {
			return formatMessage(messages.noIssuesToRank);
		}

		if (!canManageAdvancedSortConfig) {
			return formatMessage(messages.noPermissionsToRank);
		}

		return undefined;
	}, [activeIssueCount, canManageAdvancedSortConfig, formatMessage]);

	const handleChange = useCallback(() => {
		setExpanded((prev) => !prev);
	}, []);

	if (sortMode === undefined) {
		return null;
	}

	return (
		<Stack alignInline="center" xcss={panelWrapperStyles}>
			<Pressable
				id="polaris.ideas.src.ui.sidebar.settings.sort.advanced"
				onClick={handleChange}
				xcss={panelHeaderStyles}
			>
				<Flex alignItems="center">
					{expanded ? <ChevronDownIcon label="collapse" /> : <ChevronRightIcon label="expand" />}
					<Box xcss={headerTextStyles}>{formatMessage(messages.sectionHeader)}</Box>
				</Flex>
				{sortMode === VIEW_RANK && (
					<Box xcss={viewRankIndicatorStyles}>{formatMessage(messages.viewRankIndicator)}</Box>
				)}
			</Pressable>
			{expanded ? (
				<Box xcss={panelContentStlyes}>
					{sortMode === PROJECT_RANK && (
						<ProjectRankContent
							disabledButtonMessage={disabledButtonMessage}
							setSortModeToViewRank={setSortModeToViewRank}
						/>
					)}
					{sortMode === VIEW_RANK && (
						<ViewRankContent
							disabledButtonMessage={disabledButtonMessage}
							disabledButtonMessageWithCanEditIssues={disabledButtonMessage}
							setSortModeOfCurrentViewToProjectRank={setSortModeOfCurrentViewToProjectRank}
							setSortModeOfCurrentViewToProjectRankAndCommitViewRank={
								setSortModeOfCurrentViewToProjectRankAndCommitViewRank
							}
						/>
					)}
					{sortMode === FIELD_SORT && (
						<FieldSortContent
							disabledButtonMessage={disabledButtonMessage}
							disabledButtonMessageWithCanEditIssues={disabledButtonMessage}
							setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort={
								setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort
							}
							setSortModeOfCurrentViewToViewRankAndAddCurrentSort={
								setSortModeOfCurrentViewToViewRankAndAddCurrentSort
							}
						/>
					)}
				</Box>
			) : null}
		</Stack>
	);
};

const panelWrapperStyles = xcss({
	backgroundColor: 'color.background.input.hovered',
	minHeight: '48px',
});

const headerTextStyles = xcss({
	font: token('font.body.UNSAFE_small'),
	textTransform: 'uppercase',
	paddingInlineStart: 'space.100',
});

const panelContentStlyes = xcss({
	alignSelf: 'flex-start',
	paddingInlineStart: 'space.150',
});

const panelHeaderStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	cursor: 'pointer',
	width: '100%',
	height: '48px',

	':hover': {
		background: token('color.background.neutral.hovered'),
	},
});

const viewRankIndicatorStyles = xcss({
	color: 'color.text.discovery',
	paddingInlineEnd: 'space.150',
	font: token('font.body.UNSAFE_small'),
});

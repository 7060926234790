import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorBackgroundColorIcon from '@atlaskit/icon/glyph/editor/background-color';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import messages from './messages.tsx';

type Props = {
	label: string;
	isAutoFormattingOn: boolean;
	onChangeAutoFormatting: (value: boolean) => void;
	onClearFormattingForAllOptions: () => void;
};

export const FormattingAction = ({
	label,
	isAutoFormattingOn,
	onChangeAutoFormatting,
	onClearFormattingForAllOptions,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<DropdownMenu<HTMLButtonElement>
			trigger={({ triggerRef, ...props }) => (
				<IconButton
					{...props}
					onClick={(e) => {
						sendPendoTrackEvent({
							actionSubjectAndAction: 'button clicked',
							actionSubjectId: 'formatting',
							source: 'fieldOptionList',
						});
						props.onClick?.(e);
					}}
					testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.formatting-action.icon-button"
					spacing="compact"
					appearance="subtle"
					icon={EditorBackgroundColorIcon}
					label={label}
					ref={triggerRef}
				/>
			)}
		>
			<DropdownItemGroup>
				<DropdownItem
					onClick={() => {
						sendPendoTrackEvent({
							actionSubjectAndAction: 'dropdownItem clicked',
							actionSubjectId: 'changeAutoFormatting',
							source: 'fieldOptionList',
							attributes: {
								value: !isAutoFormattingOn,
							},
						});
						onChangeAutoFormatting(!isAutoFormattingOn);
					}}
					testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.formatting-action.toggle-auto-formatting"
				>
					{formatMessage(
						isAutoFormattingOn
							? messages.turnOffAutoFormattingLabel
							: messages.turnOnAutoFormattingLabel,
					)}
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						sendPendoTrackEvent({
							actionSubjectAndAction: 'dropdownItem clicked',
							actionSubjectId: 'clearFormattingForAllOptions',
							source: 'fieldOptionList',
						});
						onClearFormattingForAllOptions();
					}}
					testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.formatting-action.clear-formatting-for-all-options"
				>
					{formatMessage(messages.clearFormattingForAllOptionsLabel)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

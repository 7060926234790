import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useActions } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ExpandableSection } from './expandable-section.tsx';
import { IssueTypeCreationForm } from './issue-type-creation-form.tsx';
import { messages } from './messages.tsx';

type Props = {
	projectId: ProjectId;
};

export const IssueTypeCreateSection = ({ projectId }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();

	const { createIssueType } = useActions();

	if (isOpen === false) {
		return (
			<Box xcss={issueTypeCreateSectionStyles}>
				<Button iconBefore={() => <AddIcon label="" />} onClick={() => setIsOpen(true)}>
					{formatMessage(messages.createType)}
				</Button>
			</Box>
		);
	}

	return (
		<ExpandableSection
			expanded
			fixed
			label={<Text>{formatMessage(messages.newType)}</Text>}
			renderContent={() => (
				<IssueTypeCreationForm
					onIssueTypeReady={(data) => createIssueType(projectId, data).then(() => setIsOpen(false))}
					onCancel={() => setIsOpen(false)}
				/>
			)}
		/>
	);
};

const issueTypeCreateSectionStyles = xcss({
	paddingBlockStart: 'space.150',
});

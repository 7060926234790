import React, { type SyntheticEvent, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import UnlockFilledIcon from '@atlaskit/icon/glyph/unlock-filled';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	type AccessLevel,
	isPrivate,
	isLimited,
} from '@atlassian/jira-polaris-common/src/common/types/access/index.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useIsProjectAdmin } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getLocationHostname = () => window.location.hostname;

const OpenAccess = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingBlockEnd="space.100" xcss={bannerStyles}>
			<AccessTitle> {formatMessage(messages.openAccess)}: </AccessTitle>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<span>
				{formatMessage(messages.openAccessHint, {
					hostname: getLocationHostname(),
				})}
			</span>
		</Box>
	);
};

const PrivateAccess = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingBlockEnd="space.100" xcss={bannerStyles}>
			<AccessTitle> {formatMessage(messages.privateAccess)}: </AccessTitle>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<span>{formatMessage(messages.privateAccessHint)}</span>
		</Box>
	);
};

const LimitedAccess = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingBlockEnd="space.100" xcss={bannerStyles}>
			<AccessTitle> {formatMessage(messages.limitedAccess)}: </AccessTitle>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<span>
				{formatMessage(messages.openAccessHint, {
					hostname: getLocationHostname(),
				})}
			</span>
		</Box>
	);
};

type AccessBannerProps = {
	accessLevel: AccessLevel | undefined;
};

export const AccessBanner = ({ accessLevel }: AccessBannerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const [isProjectAdmin] = useIsProjectAdmin();
	const { routeTo } = usePolarisRouter();
	const onManage = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'manageAccess');
			routeTo({
				section: 'settings',
				resource: 'access',
			});
		},
		[routeTo],
	);

	let icon;
	let banner;
	switch (true) {
		case accessLevel === undefined:
			icon = null;
			banner = null;
			break;
		case accessLevel !== undefined && isPrivate(accessLevel):
			icon = <LockFilledIcon label="" />;
			banner = <PrivateAccess />;
			break;
		case accessLevel !== undefined && isLimited(accessLevel):
			icon = <PeopleIcon label="" />;
			banner = <LimitedAccess />;
			break;
		default:
			icon = <UnlockFilledIcon label="" />;
			banner = <OpenAccess />;
	}

	useEffect(() => {
		if (accessLevel?.type) {
			const analyticsAttributes = {
				accessLevel: accessLevel?.type,
				isProjectAdmin,
			};
			fireUIAnalytics(createAnalyticsEvent({}), 'section viewed', 'access', analyticsAttributes);
			sendPendoTrackEvent({
				source: 'shareModal',
				actionSubjectAndAction: 'section viewed',
				actionSubjectId: 'access',
				attributes: analyticsAttributes,
			});
		}
	}, [accessLevel?.type, createAnalyticsEvent, isProjectAdmin]);

	return (
		<Inline space="space.100" xcss={containerStyles}>
			{icon}
			<Box>
				{banner}
				<ButtonWithTooltip
					id="pendo.share-modal.manage-access-button"
					isDisabled={!isProjectAdmin}
					appearance="link"
					onClick={onManage}
					spacing="none"
					tooltipProps={{
						content: isProjectAdmin ? null : formatMessage(messages.manageAccessTooltip),
					}}
				>
					{formatMessage(messages.manageAccessButton)}
				</ButtonWithTooltip>
			</Box>
		</Inline>
	);
};

const containerStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	padding: 'space.100',
	borderRadius: 'border.radius',
});

const bannerStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	color: 'color.text',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AccessTitle = styled.span({
	fontWeight: token('font.weight.bold'),
});

import React, { useState } from 'react';

import InlineEdit from '@atlaskit/inline-edit';
import { Box, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import TextArea from '@atlaskit/textarea';

import { useIntl } from '@atlassian/jira-intl';
import { MAX_DESCRIPTION_LENGTH } from '../common/consts.tsx';
import { messages } from './messages.tsx';

type Props = {
	value: string;
	onChange: (value: string) => Promise<void>;
	isEditable: boolean;
};

export const InlineEditDescription = ({ value, onChange, isEditable }: Props) => {
	const [editValue, setEditValue] = useState(value);
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();

	const handleConfirm = (fieldValue: string) => {
		setEditValue(fieldValue);
		setIsLoading(true);
		onChange(fieldValue).finally(() => setIsLoading(false));
	};

	return (
		<Box xcss={containerStyles}>
			<InlineEdit
				defaultValue={value}
				label={formatMessage(messages.description)}
				editButtonLabel={editValue}
				readViewFitContainerWidth
				hideActionButtons={!isEditable}
				editView={({ errorMessage, ...fieldProps }, ref) => (
					// @ts-expect-error - textarea does not pass through ref as a prop
					<TextArea
						{...fieldProps}
						ref={ref}
						maxLength={MAX_DESCRIPTION_LENGTH}
						isDisabled={!isEditable}
					/>
				)}
				readView={() => (
					<Box xcss={readViewContainerStyles}>
						<Text>{editValue}</Text>
						{isLoading && <Spinner size="small" label={formatMessage(messages.savingChanges)} />}
					</Box>
				)}
				onConfirm={handleConfirm}
				keepEditViewOpenOnBlur
			/>
		</Box>
	);
};

const containerStyles = xcss({
	paddingBlockStart: 'space.100',
	paddingInlineEnd: 'space.100',
	paddingBlockEnd: 'space.600',
	width: '70%',
});

const readViewContainerStyles = xcss({
	font: 'font.body',
	minHeight: '4em',
	padding: 'space.075',
	wordBreak: 'break-word',
	display: 'flex',
	justifyContent: 'space-between',
	gap: 'space.100',
});

import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint, type LazyComponent } from '@atlassian/react-loosely-lazy';
import type { IdeaViewProps } from '../index.tsx';
import IdeaViewSkeleton from '../skeleton/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyIdeaView: LazyComponent<ComponentType<IdeaViewProps>> = lazyForPaint(
	() => import(/* webpackChunkName: "async-polaris-idea" */ '..'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: IdeaViewProps) => (
	<Placeholder name="polaris-idea" fallback={<IdeaViewSkeleton />}>
		<LazyIdeaView {...props} />
	</Placeholder>
);

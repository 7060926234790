import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { LabelsField } from '@atlassian/jira-polaris-common/src/ui/fields/labels/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldProps } from '../select/index.tsx';
import messages from './messages.tsx';

export const Labels = ({
	fieldKey,
	localIssueId,
	menuPortalTarget,
	onConfigRequested,
}: FieldProps) => {
	const { formatMessage } = useIntl();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	const onUpdate = useCallback(
		(newValue: string[]) => {
			updateFieldValue({
				fieldKey,
				localIssueIds: [localIssueId],
				newValue,
			});
		},
		[fieldKey, localIssueId, updateFieldValue],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<LabelsField
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			isEditable={canEditIssues && field.editable}
			onUpdate={onUpdate}
			placeholder={formatMessage(messages.noValue)}
			menuPortalTarget={menuPortalTarget}
			onConfigRequested={onConfigRequested}
		/>
	);
};

import React from 'react';
import { ConnectionField as ConnectionFieldComponent } from '@atlassian/jira-polaris-common/src/ui/fields/connection/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal/index.tsx';
import { CellPaddingAdjust } from '../utils/cell-padding-adjust.tsx';

type Props = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const ConnectionField = ({ fieldKey, localIssueId }: Props) => {
	const portalElement = useListPortalElement();

	return (
		<CellPaddingAdjust right={4}>
			<ConnectionFieldComponent
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				menuPortalTarget={portalElement}
			/>
		</CellPaddingAdjust>
	);
};

const DELIVERY_DATE_AGGREGATION_TYPE_LATEST = 'LATEST';
const DELIVERY_DATE_AGGREGATION_TYPE_EARLIEST = 'EARLIEST';

type DeliveryDateAggregationTypesMap = {
	LATEST: typeof DELIVERY_DATE_AGGREGATION_TYPE_LATEST;
	EARLIEST: typeof DELIVERY_DATE_AGGREGATION_TYPE_EARLIEST;
};

export const DELIVERY_DATE_AGGREGATION_TYPES: DeliveryDateAggregationTypesMap = {
	LATEST: 'LATEST',
	EARLIEST: 'EARLIEST',
};

export type DeliveryDateAggregationType =
	DeliveryDateAggregationTypesMap[keyof DeliveryDateAggregationTypesMap];

import React, { memo, useRef, useEffect, useState } from 'react';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import {
	IdeaViewEndMark,
	IdeaViewStartMark,
	MARKS,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/idea-view.tsx';
import { useIdeaNotFoundError } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import {
	useIsInitialized as useIsIssuesInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useIssueAnalitycsAttributes,
	useSelectedIssue,
	useSelectedIssueId,
	useSelectedIssueIssueType,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { FOOTER_BORDER_TOP } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils.tsx';
import {
	useProjectKeyUnsafe,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { AdfController } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { useMediaRemote } from '@atlassian/jira-polaris-remote-media/src/controllers/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { toIssueId, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { Description } from '../description/index.tsx';
import { FieldsThatRequireAllIdeasWarning } from '../fields/field/list/fields-that-require-all-ideas-warning/index.tsx';
import { Key } from '../key/index.tsx';
import { IdeaNotFoundView } from '../not-found-view/index.tsx';
import { Summary } from '../summary/index.tsx';
import { FieldEntry, ActionFields } from './field/index.tsx';
import { usePinnedFieldSections } from './utils.tsx';

const IdeaViewComponent = () => {
	const issueKey = useSelectedIssueKey();
	const id = useSelectedIssueId();
	const issueContainerRef = useRef<HTMLDivElement>(null);
	const isIssuesInitialized = useIsIssuesInitialized();

	const { actionPinnedFields, nonActionPinnedFields, hasFormulaFields } = usePinnedFieldSections();

	const issueId = id !== undefined ? toIssueId(id.toString()) : undefined;
	const [ideaContainerHeight, setIdeaContainerHeight] = useState(0);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			const [entry] = entries;
			if (entry) {
				const { scrollHeight } = entry.target;
				setIdeaContainerHeight(scrollHeight + 32);
			}
		});

		const issueContainer = issueContainerRef?.current;
		if (issueContainer) {
			resizeObserver.observe(issueContainer);
		}

		return () => {
			if (issueContainer) {
				resizeObserver.unobserve(issueContainer);
			}
		};
	}, []);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window.parent) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.parent.postMessage(
				JSON.stringify({
					method: 'resize',
					height: ideaContainerHeight,
				}),
				'*',
			);
		}
	}, [ideaContainerHeight]);

	if (!issueKey || !issueId) {
		return null;
	}

	return (
		<Flex direction="column" ref={issueContainerRef}>
			<IdeaViewEndMark mark={MARKS.MAIN_COMPONENT_LOADING} />
			<Box xcss={keyContainerStyles}>
				<Key embedded />
			</Box>
			<Box>
				<Summary />
			</Box>
			<Box xcss={ideaWrapperStyles}>
				<Box xcss={ideaViewContentStyles}>
					<Description isSmartLink />
					<Box xcss={fieldsSectionWrapperStyles}>
						{hasFormulaFields && !isIssuesInitialized && (
							<FieldsThatRequireAllIdeasWarning
								hasFormulaFields={hasFormulaFields}
								hasLabelFields={false}
							/>
						)}
						{nonActionPinnedFields?.length && (
							<Box xcss={fieldsContainerStyles}>
								{nonActionPinnedFields.map((fieldKey) => (
									<FieldEntry key={fieldKey} fieldKey={fieldKey} />
								))}
							</Box>
						)}
						{actionPinnedFields?.length ? <ActionFields fieldKeys={actionPinnedFields} /> : null}
					</Box>
				</Box>
			</Box>
		</Flex>
	);
};

export type IdeaViewProps = {
	issueKey: IssueKey | undefined;
	testId?: string;
};

export const SmartLinkIdeaView = memo<IdeaViewProps>(({ issueKey }: IdeaViewProps) => {
	const ideaNotFoundError = useIdeaNotFoundError();
	const localIssueId = useSelectedIssue();
	const isIssuesLoading = useIsIssuesLoading();
	const analyticsData = useIssueAnalitycsAttributes(localIssueId);
	const issueId = useSelectedIssueId();
	const issueType = useSelectedIssueIssueType();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const { fetchMediaContext } = useMediaRemote();

	if (ideaNotFoundError) {
		return <IdeaNotFoundView />;
	}

	if (
		issueKey === undefined ||
		(!isIssuesLoading && localIssueId === undefined) ||
		issueId === undefined
	) {
		return null;
	}

	return (
		<ExperienceFailErrorBoundary
			experience={[experience.ideaView.pageSegmentLoad, experience.ideaView.directPageSegmentLoad]}
		>
			<ContextualAnalyticsData
				sourceName="viewIssue"
				sourceType={SCREEN}
				objectId={analyticsData?.issueId}
				objectType="issue"
				attributes={analyticsData}
				containers={{
					issue: {
						id: issueId.toString(),
						type: issueType?.name.toLowerCase(),
					},
				}}
			>
				<Box paddingBlockStart="space.300" paddingBlockEnd="space.100" paddingInline="space.200">
					<IdeaViewStartMark mark={MARKS.SMART_CARD_PROVIDER} />
					<IdeaViewEndMark mark={MARKS.SMART_CARD_PROVIDER} />
					<IdeaViewStartMark mark={MARKS.ADF_CONTROLLER} />
					<AdfController
						issueKey={issueKey}
						projectId={projectId}
						projectKey={projectKey}
						getMediaContext={fetchMediaContext}
					>
						<IdeaViewEndMark mark={MARKS.ADF_CONTROLLER} />
						<IdeaViewComponent />
					</AdfController>
				</Box>
			</ContextualAnalyticsData>
		</ExperienceFailErrorBoundary>
	);
});

const fieldsContainerStyles = xcss({
	display: 'flex',
	flexWrap: 'wrap',
	columnGap: 'space.150',
	rowGap: 'space.050',
	padding: 'space.100',
});

const fieldsSectionWrapperStyles = xcss({
	paddingTop: 'space.100',
	borderTopStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopWidth: `${FOOTER_BORDER_TOP}px`,
	borderTopColor: 'color.border',
});

const ideaViewContentStyles = xcss({
	height: '100%',
	flex: '1 1 auto',
	paddingRight: 'space.150',
});

const ideaWrapperStyles = xcss({
	overflow: 'hidden',
	height: '100%',
});

const keyContainerStyles = xcss({
	marginTop: 'space.050',
	marginBottom: 'space.075',
});

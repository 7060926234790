import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const handleAuthFailure =
	(error: Error): Action<State, Props> =>
	({ setState }) => {
		setState({
			resolvedObject: undefined,
			error,
		});
	};

import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	createIssueRemote,
	createNotImplementedIssueRemote,
	createSharingIssueRemote,
	createJqlIssueRemote,
} from './controllers/index.tsx';
import type { IssuesRemote, SharingIssuesRemote } from './controllers/types.tsx';

const { RemoteContextProvider, useRemoteFromContext } = createRemoteContext<
	IssuesRemote | SharingIssuesRemote
>('issue');

export const createIssueRemoteProvider =
	(projectId: ProjectId): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();

		const issueRemote = useMemo(
			() => createIssueRemote(apolloClient, cloudId, projectId),
			[cloudId, apolloClient],
		);

		return <RemoteContextProvider remote={issueRemote}>{children}</RemoteContextProvider>;
	};

export const createSharingIssueRemoteProvider =
	(viewId: string): ChainableContextProvider =>
	({ children }) => {
		const { cloudId } = useTenantContext();
		const sharingIssueRemote = useMemo(() => createSharingIssueRemote(viewId, cloudId), [cloudId]);

		return <RemoteContextProvider remote={sharingIssueRemote}>{children}</RemoteContextProvider>;
	};

export const createJqlIssueRemoteProvider =
	(jql: string): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();

		const jqlIssueRemote = useMemo(
			() => createJqlIssueRemote(apolloClient, cloudId, jql),
			[apolloClient, cloudId],
		);

		return <RemoteContextProvider remote={jqlIssueRemote}>{children}</RemoteContextProvider>;
	};

export const createNotImplementedIssueRemoteProvider =
	(): ChainableContextProvider =>
	({ children }) => (
		<RemoteContextProvider remote={createNotImplementedIssueRemote()}>
			{children}
		</RemoteContextProvider>
	);

export const useIssueRemote = useRemoteFromContext;

import React, { useCallback, useState, useMemo } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { slackIcon } from '@atlassian/jira-polaris-common/src/common/ui/apps/index.tsx';
import { SlackHowToDialog } from '@atlassian/jira-polaris-common/src/common/ui/apps/modals/slack/index.tsx';
import type { AppPreset } from '../types.tsx';
import messages from './messages.tsx';

const SlackInfoAction = () => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const close = useCallback(() => setIsOpen(false), []);

	return (
		<>
			<Button spacing="none" appearance="link" onClick={() => setIsOpen(true)}>
				{formatMessage(messages.title)}
			</Button>
			<SlackHowToDialog isOpen={isOpen} onClose={close} />
		</>
	);
};

export const useSlackPreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: 'slack',
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: slackIcon,
			Action: SlackInfoAction,
		}),
		[formatMessage],
	);
};

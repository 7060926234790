import type { RecursiveFilter } from '../../filter/types.tsx';

export const COUNT_TEMPLATE = 'num_data_points';

/**
 * Formula for counting insights.
 */
export type DataPointCountFormula = {
	template: typeof COUNT_TEMPLATE;
	parameters?: {
		oauthClientId?: string | undefined;
		filter?: RecursiveFilter | undefined;
	};
};

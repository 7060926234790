import React, { useState } from 'react';
import { IconButton } from '@atlaskit/button/new';

import Popup from '@atlaskit/popup';
import { Inline } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';

import { AvatarPicker } from './avatar-picker.tsx';
import { IssueTypeAvatar } from './issue-type-avatar.tsx';
import { messages } from './messages.tsx';
import { useProjectId } from './project-id-provider.tsx';

type Props = {
	id: string;
	avatarId: string;
	onChange: (avatarId: string) => void;
	isDisabled: boolean;
};

export const IconField = ({ id, avatarId, onChange, isDisabled }: Props) => {
	const projectId = useProjectId();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Inline space="space.050">
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-start"
				content={() => (
					<AvatarPicker
						projectId={projectId}
						onSelect={(value) => {
							onChange(value);
							setIsOpen(false);
						}}
					/>
				)}
				trigger={(triggerProps) => (
					<IconButton
						id={id}
						label={formatMessage(messages.change)}
						{...triggerProps}
						isDisabled={isDisabled}
						icon={() => <IssueTypeAvatar avatarId={avatarId} size="xsmall" />}
						onClick={() => setIsOpen(!isOpen)}
					/>
				)}
			/>
		</Inline>
	);
};

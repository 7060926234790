import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import { createIdeaHook, createHigherLevelIdeaHook } from '../utils.tsx';
import { getSortedCommentIds, createGetComment, getAllMediaIds } from './index.tsx';

export const useSortedCommentIds: () => readonly string[] = createIdeaHook(getSortedCommentIds);

export const useComment: (arg1: string) => PolarisComment | undefined =
	createHigherLevelIdeaHook(createGetComment);

export const useCommentMediaIds: () => string[] = createIdeaHook(getAllMediaIds);

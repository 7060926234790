import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { createHook } from '@atlassian/react-sweet-state';
import { setIsFeedbackModalOpen } from './actions/set-is-feedback-modal-open.tsx';
import type { State } from './types.tsx';

const actions = { setIsFeedbackModalOpen };

type Actions = typeof actions;

const FeedbackModalStore = createPolarisStore<State, Actions>({
	initialState: {
		isFeedbackModalOpen: false,
	},
	actions,
	name: 'FeedbackModalStore',
});

export const useFeedbackModal = createHook(FeedbackModalStore);

import { type RefObject, useEffect, useState } from 'react';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { autoScroller } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll';
import {
	dropTargetForElements,
	monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { Input, DropTargetRecord } from '@atlaskit/pragmatic-drag-and-drop/types';
import { COLUMN_DROPPABLE_ID, ROW_DROPPABLE_ID } from '../constants.tsx';
import type { DraggedCard } from '../types/common.tsx';
import {
	type DraggableLocation,
	isDraggableCardData,
	isDraggableColumnHeaderData,
	isDraggableColumnData,
	isDraggableGroupData,
	type DraggableCardData,
} from '../types/draggable.tsx';

type IsBoardViewDraggableProps = {
	source: {
		element: HTMLElement;
		dragHandle: Element | null;
		data: Record<string, unknown>;
	};
};

export const isBoardViewDraggable = ({ source }: IsBoardViewDraggableProps) =>
	['CARD', 'GROUP', 'COLUMN_HEADER', 'STICKY_COLUMN_HEADER'].some(
		(type) => type === source.data.type,
	);

export type UseColumnDNDProps = {
	draggableId: string;
	droppableId: string;
	index: number;
	columnContainerRef: RefObject<HTMLElement | null>;
	ideaIds: string[];
	canDrop: Parameters<typeof dropTargetForElements>[0]['canDrop'];
};

export const useColumnDND = (props: UseColumnDNDProps) => {
	const { draggableId, droppableId, index, columnContainerRef, ideaIds, canDrop } = props;
	const [columnDropEdge, setColumnDropEdge] = useState<null | Edge>(null);
	const [isCardBeingDraggedOver, setIsCardBeingDraggedOver] = useState(false);

	useEffect(() => {
		const columnContainer = columnContainerRef.current;
		if (columnContainer === null) return undefined;

		return dropTargetForElements({
			element: columnContainer,
			canDrop,
			getData({ input, element }) {
				return attachClosestEdge(
					{ draggableId, droppableId, index, type: 'COLUMN', ideaIds },
					{
						input,
						element,
						allowedEdges: ['left', 'right'],
					},
				);
			},
			onDrag({ source, location, self }) {
				if (isDraggableCardData(source.data)) {
					const isHoveringCard =
						location.current.dropTargets.find((target) => target.data.type === 'CARD') !==
						undefined;
					const sourceDraggableId = source.data.draggableId;
					const isHoveringCurrentColumn = sourceDraggableId === draggableId;

					if (isHoveringCurrentColumn || isHoveringCard) {
						setIsCardBeingDraggedOver(false);
					} else {
						setIsCardBeingDraggedOver(true);
					}
				} else if (isDraggableColumnHeaderData(source.data)) {
					const sourceDraggableId = source.data.draggableId;
					const isHoveringCurrentColumn = sourceDraggableId === draggableId;
					if (isHoveringCurrentColumn) {
						return;
					}

					const dropEdge = extractClosestEdge(self.data);
					if (columnDropEdge !== dropEdge) {
						setColumnDropEdge(dropEdge);
					}
				}
			},
			onDragLeave() {
				setIsCardBeingDraggedOver(false);
				setColumnDropEdge(null);
			},
			onDrop() {
				setIsCardBeingDraggedOver(false);
				setColumnDropEdge(null);
			},
		});
	}, [draggableId, droppableId, index, columnContainerRef, ideaIds, columnDropEdge, canDrop]);

	return { isCardBeingDraggedOver, columnDropEdge };
};

type HandleBoardDropProps = {
	source: {
		element: HTMLElement;
		dragHandle: Element | null;
		data: Record<string, unknown>;
	};
	dropTargets: DropTargetRecord[];
	// eslint-disable-next-line jira/react/handler-naming
	shouldInterruptDnd?: ({
		source,
		destination,
	}: {
		source: DraggableLocation;
		destination: DraggableLocation;
	}) => boolean;
	onDrop: (
		source: DraggableLocation,
		destination?: DraggableLocation,
		afterItemIndex?: number,
		beforeItemIndex?: number,
	) => void;
};

const handleBoardDrop = (props: HandleBoardDropProps) => {
	const { source, dropTargets, shouldInterruptDnd = () => false, onDrop } = props;
	autoScroller.stop();

	if (dropTargets.length === 0) {
		return;
	}

	const target = dropTargets[0];

	if (isDraggableColumnHeaderData(source.data)) {
		// Column sticky header (in grouped board view) drop
		if (!isDraggableColumnHeaderData(target.data) && !isDraggableColumnData(target.data)) {
			return;
		}

		const dropEdge = extractClosestEdge(target.data);

		if (dropEdge === null) {
			return;
		}

		const isSamePosition =
			source.data.index === target.data.index ||
			(dropEdge === 'left' && target.data.index === source.data.index + 1) ||
			(dropEdge === 'right' && target.data.index === source.data.index - 1);
		if (isSamePosition) {
			return;
		}

		const isDraggingRight = target.data.index > source.data.index;
		let targetIndex = dropEdge === 'left' ? target.data.index : target.data.index + 1;
		if (isDraggingRight) {
			targetIndex = dropEdge === 'left' ? target.data.index - 1 : target.data.index;
		}

		onDrop(
			{
				droppableId: COLUMN_DROPPABLE_ID,
				index: source.data.index,
			},
			{
				droppableId: COLUMN_DROPPABLE_ID,
				index: targetIndex,
			},
		);
	} else if (isDraggableColumnData(target.data)) {
		// Column header (in non grouped board view) drop
		if (!isDraggableCardData(source.data)) {
			return;
		}

		const sourceData = source.data;
		const targetData = target.data;

		if (
			sourceData.draggableId === targetData.draggableId ||
			shouldInterruptDnd({
				source: sourceData,
				destination: targetData,
			})
		) {
			return;
		}
		onDrop(sourceData, {
			droppableId: targetData.draggableId,
			index: targetData.ideaIds.length,
		});
	} else if (isDraggableCardData(target.data)) {
		// Card drop
		if (!isDraggableCardData(source.data)) {
			return;
		}

		const sourceData = source.data;
		const targetData = target.data;

		if (
			sourceData.draggableId === targetData.draggableId ||
			shouldInterruptDnd({
				source: sourceData,
				destination: targetData,
			})
		) {
			return;
		}

		const dropEdge = extractClosestEdge(target.data);

		if (dropEdge === null) {
			return;
		}

		const isSameColumn = sourceData.droppableId === targetData.droppableId;
		const targetIndex = getTargetIndex(sourceData, targetData, isSameColumn, dropEdge);

		if (isSameColumn && sourceData.index === targetIndex) {
			return;
		}

		onDrop(sourceData, {
			...targetData,
			index: targetIndex,
		});
	} else if (isDraggableGroupData(target.data)) {
		// Group drop
		if (!isDraggableGroupData(source.data)) {
			return;
		}

		const isSameGroup = target.data.draggableId === source.data.draggableId;
		if (isSameGroup) {
			return;
		}

		const dropEdge = extractClosestEdge(target.data);
		if (dropEdge === null) {
			return;
		}

		let targetIndex;

		const isSourceJustBeforeTarget = source.data.index === target.data.index - 1;
		const isSourceJustAfterTarget = source.data.index === target.data.index + 1;
		const isDraggingDown = source.data.index < target.data.index;
		if (dropEdge === 'top') {
			if (isDraggingDown) {
				targetIndex = isSourceJustBeforeTarget ? source.data.index : target.data.index - 1;
			} else {
				targetIndex = target.data.index;
			}
		} else if (isDraggingDown) {
			targetIndex = target.data.index;
		} else {
			targetIndex = isSourceJustAfterTarget ? source.data.index : target.data.index + 1;
		}

		if (source.data.index === targetIndex) {
			return;
		}

		onDrop(
			{
				droppableId: ROW_DROPPABLE_ID,
				index: source.data.index,
			},
			{
				droppableId: ROW_DROPPABLE_ID,
				index: targetIndex,
			},
		);
	}
};

type HandleBoardDragProps = {
	input: Input;
	containerRect: DOMRect;
};

const handleBoardDrag = (props: HandleBoardDragProps) => {
	const { input, containerRect } = props;
	const exceedsLeft = input.clientX < containerRect.x;
	const exceedsRight = input.clientX > containerRect.x + containerRect.width;
	const exceedsTop = input.clientY < containerRect.y;
	const exceedsBottom = input.clientY > containerRect.y + containerRect.height;
	const isOutsideScrollContainer = exceedsLeft || exceedsRight || exceedsTop || exceedsBottom;

	let adjustedClientX = input.clientX;
	let adjustedClientY = input.clientY;

	// allows to keep scrolling even if the cursor is outside the scroll container
	// for a better user experience by acting like the cursor is still within the
	// scroll container on the edge
	if (isOutsideScrollContainer) {
		if (exceedsLeft) {
			adjustedClientX = containerRect.x;
		} else if (exceedsRight) {
			adjustedClientX = containerRect.x + containerRect.width - 1;
		}

		if (exceedsTop) {
			adjustedClientY = containerRect.y;
		} else if (exceedsBottom) {
			adjustedClientY = containerRect.y + containerRect.height - 1;
		}
	}

	autoScroller.updateInput({
		input: {
			...input,
			clientX: adjustedClientX,
			clientY: adjustedClientY,
		},
	});
};

export type UseBoardDNDProps = {
	boardContainerRef: RefObject<HTMLElement | null>;
	onDrag: (draggedCard: DraggedCard) => void;
} & Pick<HandleBoardDropProps, 'shouldInterruptDnd' | 'onDrop'>;

export const useBoardDND = (props: UseBoardDNDProps) => {
	const { boardContainerRef, onDrag, onDrop, shouldInterruptDnd = () => false } = props;
	const [draggedCard, setDraggedCard] = useState<DraggedCard>();

	useEffect(() => {
		const boardContainer = boardContainerRef.current;

		if (!boardContainer) {
			return undefined;
		}

		let containerRect = boardContainer.getBoundingClientRect();
		let isVerticalScrollLocked = false;
		let initialScrollTop = 0;

		/**
		 * Locking the input with lockInputVerticalAxis is not sufficient because this
		 * only affects pragmatic dnd scroll events, but not the native browser scroll events
		 * that are happening when dragging an element on the very edges of a scroll container.
		 * Therefore, we reset the y scroll position manually on every scroll event.
		 */
		const resetYScrollPosition = () => {
			boardContainer.scrollTop = initialScrollTop;
		};

		const lockInputVerticalAxis = (input: Input) => ({
			...input,
			clientY: isVerticalScrollLocked ? Math.floor(containerRect.height / 2) : input.clientY,
			pageY: isVerticalScrollLocked ? Math.floor(containerRect.height / 2) : input.pageY,
		});

		const cleanupDragAndDrop = monitorForElements({
			canMonitor: isBoardViewDraggable,
			onDrop: ({ source, location }) => {
				setDraggedCard(undefined);
				handleBoardDrop({
					source,
					dropTargets: location.current.dropTargets,
					shouldInterruptDnd,
					onDrop,
				});

				if (isVerticalScrollLocked) {
					boardContainer.removeEventListener('scroll', resetYScrollPosition);
				}

				isVerticalScrollLocked = false;
			},
			onDragStart: ({ source, location }) => {
				isVerticalScrollLocked = ['COLUMN_HEADER', 'STICKY_COLUMN_HEADER'].includes(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					source.data.type as string,
				);

				if (isVerticalScrollLocked) {
					boardContainer.addEventListener('scroll', resetYScrollPosition);
				}

				containerRect = boardContainer.getBoundingClientRect();
				initialScrollTop = boardContainer.scrollTop;

				autoScroller.start({
					input: lockInputVerticalAxis(location.current.input),
				});

				if (isDraggableCardData(source.data)) {
					onDrag(source.data);
					setDraggedCard(source.data);
				}
			},
			onDrag: ({ location }) =>
				handleBoardDrag({
					input: lockInputVerticalAxis(location.current.input),
					containerRect,
				}),
		});

		return () => {
			cleanupDragAndDrop();

			if (isVerticalScrollLocked) {
				boardContainer.removeEventListener('scroll', resetYScrollPosition);
			}
		};
	}, [onDrag, onDrop, shouldInterruptDnd, boardContainerRef]);

	return draggedCard;
};

const getTargetIndex = (
	sourceData: DraggableCardData,
	targetData: DraggableCardData,
	isSameColumn: boolean,
	dropEdge: Edge,
) => {
	let targetIndex;

	if (isSameColumn) {
		const isSourceJustBeforeTarget = sourceData.index + 1 === targetData.index;
		const isSourceJustAfterTarget = sourceData.index - 1 === targetData.index;
		const isDraggingDown = sourceData.index < targetData.index;
		if (dropEdge === 'top') {
			if (isDraggingDown) {
				targetIndex = isSourceJustBeforeTarget ? sourceData.index : targetData.index - 1;
			} else {
				targetIndex = targetData.index;
			}
		} else if (isDraggingDown) {
			targetIndex = targetData.index;
		} else {
			targetIndex = isSourceJustAfterTarget ? sourceData.index : targetData.index + 1;
		}
	} else if (dropEdge === 'top') {
		targetIndex = targetData.index;
	} else {
		targetIndex = targetData.index + 1;
	}

	return targetIndex;
};

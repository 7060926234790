import { useEffect } from 'react';
import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import { useSetViews } from '@atlassian/jira-polaris-component-view-id-mapping/src/index.tsx';
import type { ViewIdentifiers } from '@atlassian/jira-polaris-component-view-id-mapping/src/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createViewHook } from '../selectors/utils.tsx';
import type { State } from '../types.tsx';

const flattenViews = (viewSets: ViewSet[] | undefined): View[] => {
	if (viewSets === undefined) {
		return [];
	}

	return flatten(viewSets.map((viewSet) => [...viewSet.views, ...flattenViews(viewSet.viewSets)]));
};

const getFlatViews = createSelector((state: State) => state.viewSets, flattenViews);

const getViewsIds = createSelector(getFlatViews, (views): ViewIdentifiers[] =>
	views
		.map((view: View) => {
			if (view.viewLegacyId !== undefined && view.uuid !== undefined && view.viewId !== undefined) {
				return {
					viewPolarisApiId: String(view.viewLegacyId),
					viewUUID: view.uuid,
					viewAri: view.viewId,
				};
			}
			return undefined;
		})
		.filter(Boolean),
);

const useViewIds = createViewHook(getViewsIds);

export const ViewIdMappingSynchroniser = () => {
	const viewIds = useViewIds();
	const setViews = useSetViews();

	useEffect(() => {
		setViews(viewIds);
	}, [setViews, viewIds]);

	return null;
};

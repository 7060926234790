import React, { type ReactElement } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { SpotlightTypes } from '../../common/types.tsx';
import { Spotlight } from '../../common/ui/spotlight/index.tsx';
import { getSpotlightAnalyticsContextData } from '../../common/utils.tsx';
import { useActions } from '../../controllers/index.tsx';
import { useIsLoaded } from '../../controllers/selectors/remote-state-hooks.tsx';
import { useIsCreateInsightSpotlightShown } from '../../controllers/selectors/spotlights-hooks.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactElement;
	leftShift?: number;
};

export const CreateInsightSpotlight = ({ children, leftShift }: Props) => {
	const { formatMessage } = useIntl();

	const isShown = useIsCreateInsightSpotlightShown();
	const isLoaded = useIsLoaded();
	const { closeSpotlight } = useActions();

	return (
		<ContextualAnalyticsData {...getSpotlightAnalyticsContextData('createInsight')}>
			<Spotlight
				isActive={isLoaded && isShown}
				title={formatMessage(messages.title)}
				description={formatMessage(messages.description)}
				learnMoreText={formatMessage(messages.learnMoreText)}
				articleId="2iEf81yt5skdET9ZLhxwzb"
				onClose={() => closeSpotlight(SpotlightTypes.CREATE_INSIGHT)}
				mode="absolute"
				leftShift={leftShift}
				placement="bottom"
			>
				{children}
			</Spotlight>
		</ContextualAnalyticsData>
	);
};

import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/loading-button';
import EmailIcon from '@atlaskit/icon/glyph/email';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { borderRadius, fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { EmailAddress } from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-email-service/types.tsx';
import messages from './messages.tsx';
import type { EmailStatus } from './types.tsx';

export type CustomEmailCardProps = {
	isModalLoading?: boolean;
	currentEmail: EmailAddress;
	status?: EmailStatus;
	onEditButtonClicked: () => void;
};

const CustomEmailCard = (props: CustomEmailCardProps) => {
	const { formatMessage } = useIntl();
	const { currentEmail, onEditButtonClicked, status = 'success', isModalLoading = false } = props;

	const onEditButton = (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent): void => {
		onEditButtonClicked();
		fireUIAnalytics(analyticsEvent, 'editDomainButton');
	};
	const renderCardContent = () => (
		<CardContent>
			<EmailIcon label="email-icon" size="medium" />
			{/* @ts-expect-error - TS2741 - Property 'status' is missing in type '{ children: string[]; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { status: any; }, any>>'. */}
			<EmailAddressField> {currentEmail} </EmailAddressField>
		</CardContent>
	);
	const renderCardErrorContent = () => (
		<CardContent>
			<EmailIcon label="email-icon" size="medium" />
			<ToolTip content={formatMessage(messages.errorDescription)}>
				<TooltipContentWrapper>
					<EmailAddressField status="error"> {currentEmail} </EmailAddressField>
					<ErrorIconWrapper>
						<ErrorIcon size="medium" label="" />
					</ErrorIconWrapper>
				</TooltipContentWrapper>
			</ToolTip>
		</CardContent>
	);
	return (
		<Box xcss={cardWrapperStyles}>
			<Card>
				{status === 'success' ? renderCardContent() : renderCardErrorContent()}
				<CardActions>
					<Button
						testId="project-settings-apps-notifications-custom-email-card.ui.edit-button"
						onClick={onEditButton}
						isLoading={isModalLoading}
					>
						{formatMessage(messages.editButton)}
					</Button>
				</CardActions>
			</Card>
		</Box>
	);
};

export default CustomEmailCard;

const cardWrapperStyles = xcss({
	marginTop: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Card = styled.div({
	borderWidth: '1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderColor: `${colors.N30}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	padding: token('space.300', '24px'),
	display: 'grid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridTemplateColumns: `${gridSize}px auto max-content`,
	columnGap: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContent = styled.div({
	alignItems: 'center',
	display: 'flex',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	lineHeight: 1,
	flexFlow: 'row wrap',
	width: 'max-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmailAddressField = styled.div<{ status: string }>({
	marginLeft: token('space.200', '16px'),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props: any) => (props.status === 'error' ? R400 : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardActions = styled.div({
	justifySelf: 'end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentWrapper = styled.div({
	display: 'flex',
	marginTop: token('space.075', '6px'),
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	color: R400,
	marginTop: token('space.negative.050', '-4px'),
});

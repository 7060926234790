import React from 'react';
import Badge from '@atlaskit/badge';
import { Inline, Text } from '@atlaskit/primitives';
import {
	useFieldLabel,
	useIsGlobalCustomField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';

type Props = {
	fieldKey: string;
	issuesCount: number;
};

export const Header = ({ fieldKey, issuesCount }: Props) => {
	const label = useFieldLabel(fieldKey);
	const isGlobal = useIsGlobalCustomField(fieldKey);

	return (
		<Inline space="space.150" alignBlock="center">
			<Text maxLines={1}>{label}</Text>
			{isGlobal && <GlobalFieldMarker />}
			{issuesCount > 0 && <Badge>{issuesCount.toString()}</Badge>}
		</Inline>
	);
};

import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { User as UserType } from '@atlassian/jira-polaris-common/src/common/types/user/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { usePeopleTransformed } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { UsersField } from '@atlassian/jira-polaris-common/src/ui/fields/users/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	portalElement?: HTMLElement;
};

export const Users = ({ fieldKey, localIssueId, portalElement }: Props) => {
	const { formatMessage } = useIntl();
	const value = usePeopleTransformed(fieldKey, localIssueId);
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	if (field === undefined) {
		return null;
	}
	return (
		<UsersField
			value={value}
			fieldKey={fieldKey}
			interactive
			isEditable={canEditIssues && field.editable}
			portalElement={portalElement}
			onUpdate={(users: UserType[]) => {
				const displayUsers: UserFieldValue[] = users.map((user) => ({
					accountId: user.id,
					displayName: user.name || '',
					//  user picker response (https://developer.atlassian.com/cloud/jira/platform/rest/v2/api-group-user-search/#api-rest-api-2-user-picker-get) is returning one avatarUrl while in the user object we want have multiple avatarUrls for different resolutions (16x16, 24x24, 32x32, 48x48)
					avatarUrls: fg('polaris_user_picker_response_to_all_resolutions')
						? {
								'16x16': user.avatarUrl ?? '',
								'24x24': user.avatarUrl ?? '',
								'32x32': user.avatarUrl ?? '',
								'48x48': user.avatarUrl ?? '',
							}
						: {
								'16x16': undefined,
								'24x24': undefined,
								'32x32': undefined,
								'48x48': user.avatarUrl || '',
							},
				}));
				updateFieldValue({
					fieldKey,
					localIssueIds: [localIssueId],
					newValue: displayUsers,
				});
			}}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};

import React, { forwardRef, useCallback } from 'react';
import type { OptionData } from '@atlaskit/user-picker';
import type { ViewAccess } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { getUsersAndGroups } from '@atlassian/jira-polaris-lib-user-picker/src/services/get-user-and-groups/index.tsx';
import {
	UserPicker as JPDUserPicker,
	type UserPickerApi,
} from '@atlassian/jira-polaris-lib-user-picker/src/ui/index.tsx';

export type UserPickerProps = {
	isLoading: boolean;
	userPickerOptions: OptionData[];
	isDisabled?: boolean;
	setUserPickerOptions: (value: OptionData[]) => void;
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
} & ViewAccess['additionalAccess'];

export const UserPicker = forwardRef<UserPickerApi, UserPickerProps>(
	(
		{
			isLoading,
			groups,
			profiles,
			userPickerOptions,
			isDisabled = false,
			setUserPickerOptions,
			onMenuOpen,
			onMenuClose,
		},
		ref,
	) => {
		const handleUserFetch = useCallback(
			(query?: string) => {
				const userOptions = profiles.map((profile) => ({ ...profile, id: profile.accountId }));
				const groupOptions = groups.map((group) => ({ ...group, id: group.groupId }));

				return getUsersAndGroups({
					query,
					existingUsers: userOptions,
					existingGroups: groupOptions,
					allowEmail: false,
				});
			},
			[groups, profiles],
		);

		return (
			<JPDUserPicker
				ref={ref}
				testId="polaris-component-view-access.ui.access-screen.user-picker"
				value={userPickerOptions}
				onChange={setUserPickerOptions}
				onUserFetch={handleUserFetch}
				isLoading={isLoading}
				isReadOnly={isDisabled}
				analyticsSourceId="accessModal"
				onMenuOpen={onMenuOpen}
				onMenuClose={onMenuClose}
				maxPickerHeight={180}
			/>
		);
	},
);

import { createContainer, createStore, createActionsHook } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { Props, State } from './types.tsx';

export const NavigationStore = createStore<State, typeof actions, Props>({
	name: 'NavViewStore',
	initialState: { projects: {}, entityLimits: {} },
	actions,
});

export const NavigationContainer = createContainer<State, typeof actions, Props>(NavigationStore, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(actions.expandSectionForSelectedView());
			dispatch(actions.updateEntityLimits());
		},
	onUpdate:
		() =>
		({ dispatch }) => {
			dispatch(actions.expandSectionForSelectedView());
			dispatch(actions.updateEntityLimits());
		},
});

export const useNavigationActions = createActionsHook(NavigationStore);

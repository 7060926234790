import React from 'react';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { ProjectTypesScreen } from './project-types-screen.tsx';

export const PolarisProjectTypes = () => (
	<ContextualAnalyticsData sourceType={SCREEN} sourceName="typesPage">
		<FireScreenAnalytics />
		<ProjectTypesScreen />
	</ContextualAnalyticsData>
);

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_ProjectQuery_reduced_polarisProject_viewsets as ViewSetsTypeGQL } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/__generated_apollo__/jira_polaris_ProjectQuery_reduced';
import { fetchProject } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/index.tsx';
import { fetchView } from '../../../services/polaris-api/fetch-view/index.tsx';
import { transformViewSets, transformView } from './transform/index.tsx';

export const fetchAndTransformViews = (
	projectAri: Ari,
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	fields: FieldMap,
	currentViewSlug: string | undefined,
	checkIsViewCollapsed: (view: View[], viewSlug: string | undefined) => boolean,
): Promise<ViewSet[]> =>
	fetchProject(apolloClient, createAnalyticsEvent, projectAri)
		.then((result): ReadonlyArray<ViewSetsTypeGQL> => result.viewsets || [])
		.then((viewsets) => transformViewSets(viewsets, fields, currentViewSlug, checkIsViewCollapsed));

export const fetchAndTransformView = (
	apolloClient: PolarisApolloClient,
	viewId: Ari,
	fields: FieldMap,
) => fetchView(apolloClient, viewId).then((response) => transformView(response, fields));

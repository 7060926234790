import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';

export const handleError = (experience: JPDExperience, error?: Error) => {
	// Ignore network-like errors
	if (isClientFetchError(error)) {
		return;
	}

	// Ignore validation errors
	if (error && isValidationError(error)) {
		return;
	}

	experience.failure(error);
};

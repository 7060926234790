import React, { useCallback, useEffect } from 'react';
import { IconButton } from '@atlaskit/button/new';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useOpenRightSidebarMatrixXAxisOptions,
	useOpenRightSidebarMatrixYAxisOptions,
	useOpenRightSidebarMatrixZAxisOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingXAxisOptions,
	RightSidebarShowingYAxisOptions,
	RightSidebarShowingZAxisOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useHasMatrixXAxisUnsavedChanges,
	useHasMatrixYAxisUnsavedChanges,
	useHasMatrixZAxisUnsavedChanges,
	useCurrentViewZAxis,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { FieldItem } from '@atlassian/jira-polaris-common/src/ui/common/field-select/index.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ActionButton } from '../action-button/index.tsx';
import { messages } from './messages.tsx';

const LightweightFieldItem = ({ fieldKey }: { fieldKey: FieldKey | undefined }) => {
	const field = useField(fieldKey);

	if (field === undefined) {
		return null;
	}

	return (
		<Box xcss={fieldWrapperStyles}>
			<FieldItem field={field} />
		</Box>
	);
};

const FieldItemBadge = ({ fieldKey }: { fieldKey: FieldKey }) => {
	const field = useField(fieldKey);

	if (field === undefined) {
		return null;
	}

	return (
		<Box xcss={fieldWrapperStyles}>
			<FieldItem field={field} />
		</Box>
	);
};

type ConfigMatrixAxesProps = {
	matrixXAxis?: FieldKey;
	matrixYAxis?: FieldKey;
};

export const ConfigMatrixAxes = (props: ConfigMatrixAxesProps) => {
	const [sidebarShowing] = useRightSidebarShowing();

	const openXAxisOptions = useOpenRightSidebarMatrixXAxisOptions();
	const openYAxisOptions = useOpenRightSidebarMatrixYAxisOptions();
	const openZAxisOptions = useOpenRightSidebarMatrixZAxisOptions();
	const { formatMessage } = useIntl();
	const { setMatrixXAxis, setMatrixYAxis } = useViewActions();

	const { matrixXAxis: selectedXAxisField, matrixYAxis: selectedYAxisField } = props;
	const xAxisField = useField(selectedXAxisField);
	const yAxisField = useField(selectedYAxisField);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (xAxisField === undefined) {
			setMatrixXAxis(undefined);
		}
	}, [setMatrixXAxis, xAxisField]);

	useEffect(() => {
		if (yAxisField === undefined) {
			setMatrixYAxis(undefined);
		}
	}, [setMatrixYAxis, yAxisField]);

	const hasOnboarded = selectedXAxisField !== undefined && selectedYAxisField !== undefined;
	const selectedZAxisField = useCurrentViewZAxis();
	const { switchMatrixXAndYAxis } = useViewActions();

	const handleOpenXAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixXAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
		);
		openXAxisOptions();
	}, [createAnalyticsEvent, openXAxisOptions]);

	const handleOpenYAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixYAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
		);
		openYAxisOptions();
	}, [createAnalyticsEvent, openYAxisOptions]);

	const handleOpenZAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixZAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
		);
		openZAxisOptions();
	}, [createAnalyticsEvent, openZAxisOptions]);

	const hasMatrixXAxisUnsavedChanges = useHasMatrixXAxisUnsavedChanges();
	const hasMatrixYAxisUnsavedChanges = useHasMatrixYAxisUnsavedChanges();
	const hasMatrixZAxisUnsavedChanges = useHasMatrixZAxisUnsavedChanges();

	return (
		<div id="polaris-ideas.ui.view-control.config-columns.group-by-dropdown">
			<Flex alignItems="center" gap="space.075">
				{isVisualRefreshEnabled() ? (
					<ActionButton
						id="polaris.ideas.ui.view-controls.open-x-axis-sidebar"
						isSelected={sidebarShowing.mode === RightSidebarShowingXAxisOptions}
						isEmpty={selectedXAxisField === undefined}
						onClick={handleOpenXAxisOptions}
						hasUnsavedChanges={hasMatrixXAxisUnsavedChanges}
						elemAfter={selectedXAxisField && <FieldItemBadge fieldKey={selectedXAxisField} />}
					>
						{formatMessage(messages.xAxisLabel)}
					</ActionButton>
				) : (
					<ActionButton
						id="polaris.ideas.ui.view-controls.open-x-axis-sidebar"
						isSelected={sidebarShowing.mode === RightSidebarShowingXAxisOptions}
						isEmpty={selectedXAxisField === undefined}
						onClick={handleOpenXAxisOptions}
						hasUnsavedChanges={hasMatrixXAxisUnsavedChanges}
					>
						<Flex>
							{formatMessage(messages.xAxisLabel)}
							<LightweightFieldItem fieldKey={selectedXAxisField} />
						</Flex>
					</ActionButton>
				)}

				<IconButton
					label={formatMessage(messages.switchAxesLabel)}
					appearance="subtle"
					id="polaris.ideas.ui.view-controls.switchAxes"
					icon={(iconProps) => (
						<PolarisIcon name={PolarisIconType.Switch} size="small" {...iconProps} />
					)}
					onClick={switchMatrixXAndYAxis}
				/>

				{isVisualRefreshEnabled() ? (
					<ActionButton
						id="polaris.ideas.ui.view-controls.open-y-axis-sidebar"
						isSelected={sidebarShowing.mode === RightSidebarShowingYAxisOptions}
						isEmpty={selectedYAxisField === undefined}
						onClick={handleOpenYAxisOptions}
						hasUnsavedChanges={hasMatrixYAxisUnsavedChanges}
						elemAfter={selectedYAxisField && <FieldItemBadge fieldKey={selectedYAxisField} />}
					>
						{formatMessage(messages.yAxisLabel)}
					</ActionButton>
				) : (
					<ActionButton
						id="polaris.ideas.ui.view-controls.open-y-axis-sidebar"
						isSelected={sidebarShowing.mode === RightSidebarShowingYAxisOptions}
						isEmpty={selectedYAxisField === undefined}
						onClick={handleOpenYAxisOptions}
						hasUnsavedChanges={hasMatrixYAxisUnsavedChanges}
					>
						<Flex>
							{formatMessage(messages.yAxisLabel)}
							<LightweightFieldItem fieldKey={selectedYAxisField} />
						</Flex>
					</ActionButton>
				)}

				{hasOnboarded &&
					(isVisualRefreshEnabled() ? (
						<ActionButton
							id="polaris.ideas.ui.view-controls.open-z-axis-sidebar"
							isSelected={sidebarShowing.mode === RightSidebarShowingZAxisOptions}
							isEmpty={selectedZAxisField === undefined}
							onClick={handleOpenZAxisOptions}
							hasUnsavedChanges={hasMatrixZAxisUnsavedChanges}
							elemAfter={selectedZAxisField && <FieldItemBadge fieldKey={selectedZAxisField} />}
						>
							{formatMessage(messages.zAxisLabel)}
						</ActionButton>
					) : (
						<ActionButton
							id="polaris.ideas.ui.view-controls.open-z-axis-sidebar"
							isSelected={sidebarShowing.mode === RightSidebarShowingZAxisOptions}
							isEmpty={selectedZAxisField === undefined}
							onClick={handleOpenZAxisOptions}
							hasUnsavedChanges={hasMatrixZAxisUnsavedChanges}
						>
							<Flex>
								{formatMessage(messages.zAxisLabel)}
								<LightweightFieldItem fieldKey={selectedZAxisField} />
							</Flex>
						</ActionButton>
					))}
			</Flex>
		</div>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.075',
	maxWidth: 'clamp(50px, 10vw, 150px)',
});

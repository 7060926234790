import React from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import LockIcon from '@atlaskit/icon/glyph/lock';
import GlobalFieldIcon from '@atlaskit/icon/glyph/office-building-filled';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const PermissionsTooltipContent = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex direction="row">
			<Box xcss={tooltipIconStyles}>
				<LockIcon label={formatMessage(messages.lockIconLabel)} />
			</Box>
			<Box>
				<Box paddingBlockStart="space.050" paddingBlockEnd="space.050">
					<b>{formatMessage(messages.tooltipHeader)}</b>
				</Box>
				<Box>
					{formatMessage(messages.tooltipText, {
						icon: () => (
							<>
								<br />
								<GlobalFieldIcon
									label={formatMessage(messages.globalFieldIconLabel)}
									size="small"
								/>
							</>
						),
					})}
				</Box>
			</Box>
		</Flex>
	);
};

export const PermissionsIcon = () => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip component={TooltipContent} position="top" content={<PermissionsTooltipContent />}>
			<InfoIcon
				primaryColor={token('color.icon.discovery', '#8270db')}
				label={formatMessage(messages.infoIconLabel)}
			/>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	maxWidth: '350px',
	padding: token('space.200', '16px'),
	backgroundColor: token('elevation.surface.overlay', '#FFFFFF'),
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 0px 8px rgba(9, 30, 66, 0.16),0px 0px 1px rgba(9, 30, 66, 0.12)',
	),
	borderRadius: '3px',
});

const tooltipIconStyles = xcss({
	marginRight: 'space.100',
});

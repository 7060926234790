import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { shouldOpenInNewTab } from '../../../common/utils/events/index.tsx';
import { getIssueLink } from '../../../common/utils/issue-link/index.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import messages from './messages.tsx';

export const useOnSubmittedIssue = () => {
	const { success } = useNotifications();
	const { formatMessage } = useIntl();
	const { openIssueView } = usePolarisRouter();
	const projectKey = useProjectKeyUnsafe();

	return useCallback(
		(issueKey?: IssueKey, isFiltered?: boolean) => {
			const dismissFunction = success({
				title: formatMessage(messages.submitSuccess),
				description: isFiltered
					? formatMessage(messages.submitSuccessInvisibleDescription)
					: formatMessage(messages.submitSuccessDescription),
				actions: issueKey
					? [
							{
								content: formatMessage(messages.goToIdeaButton),
								onClick: (event) => {
									if (shouldOpenInNewTab(event)) {
										const linkUrl = getIssueLink(projectKey, issueKey);

										// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
										window.open(linkUrl, '_blank');
										return;
									}

									openIssueView(issueKey);
									dismissFunction();
								},
							},
						]
					: [],
			});
		},
		[formatMessage, openIssueView, projectKey, success],
	);
};

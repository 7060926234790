import { KEY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useSafeIssueKey } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useIdeaCardContext } from '../utils.tsx';

export const useVisibleIssueKey = (issueId: LocalIssueId): string | undefined => {
	const { allFieldsByKey } = useIdeaCardContext();
	const issueKey = useSafeIssueKey(issueId);
	if (!issueKey || !allFieldsByKey[KEY_FIELDKEY]) {
		return undefined;
	}
	return issueKey;
};

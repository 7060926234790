/** @jsx jsx */
import { css, jsx } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import {
	useFieldTypeIcon,
	useFieldEmoji,
	useFieldLabel,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';

type LabelItemProps = {
	amount: number;
	fieldKey: FieldKey;
	showFieldLabel?: boolean;
};

export const LabelItem = ({ amount, fieldKey, showFieldLabel }: LabelItemProps) => {
	const fieldLabel = useFieldLabel(fieldKey);

	const icon = useFieldTypeIcon(fieldKey, undefined);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	return (
		<div data-component-selector="content-wrapper-aw1" css={contentWrapperStyles}>
			<Box xcss={iconContainerStyles}>
				{emoji ? <Emoji emoji={emoji} fitToHeight={16} showTooltip /> : icon}
			</Box>
			{fieldLabel && showFieldLabel && <Box xcss={labelWrapperStyles}>{fieldLabel}</Box>}

			<Box data-component-selector="amount-wrapper-aw1" xcss={amountWrapperStyles}>
				{amount}
			</Box>
		</div>
	);
};

const iconContainerStyles = xcss({
	marginRight: 'space.050',
	display: 'flex',
	alignItems: 'center',
});

const labelWrapperStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
	overflow: 'hidden',
	marginRight: 'space.050',
});

// Hover state styles for this component are created in: src/packages/polaris/apps/common/src/ui/idea-card-v2/main.tsx

const amountWrapperStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	paddingInline: 'space.050',
	borderRadius: 'border.radius.050',
	whiteSpace: 'nowrap',
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
});

const contentWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: token('color.background.accent.gray.subtlest'),
	paddingInline: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBlock: '3px',
	borderRadius: token('border.radius.100'),
	justifySelf: 'flex-start',
	gridColumn: '-1/1',
});

import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldConfiguration } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraField } from '../../types.tsx';

export const createField = (
	projectId: string,
	issueTypeId: IssueTypeId,
	name: string,
	fieldType: FieldType,
	configuration?: FieldConfiguration,
): Promise<JiraField> => {
	return performPostRequest(`/rest/polaris/v2/projects/${projectId}/fields`, {
		body: JSON.stringify({ issueTypeId, name, fieldType, configuration }),
	});
};

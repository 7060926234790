import {
	getError,
	getIsLoading,
	getIssueId,
	getIssueKey,
	getProviders,
	getProjectId,
	getIsRateLimitError,
} from './meta.tsx';
import { createInsightsHook } from './utils.tsx';

/**
 * @deprecated use explicitly named hook useIsInsightsLoading instead
 */
export const useIsLoading = createInsightsHook(getIsLoading);
export const useIsInsightsLoading = createInsightsHook(getIsLoading);
export const useInsightsLoadingError = createInsightsHook(getError);
export const useIsInsightsLoadingRateLimitError = createInsightsHook(getIsRateLimitError);
export const useSnippetProviders = createInsightsHook(getProviders);
export const useIssueId = createInsightsHook(getIssueId);
export const useIssueKey = createInsightsHook(getIssueKey);
export const useProjectId = createInsightsHook(getProjectId);

import React from 'react';
import { timestampAggDateToInterval } from '@atlassian/jira-polaris-domain-delivery/src/dates/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { FieldName } from '../../../common/ui/field/styled.tsx';
import { useIntervalDateDecoration } from '../../../controllers/field/selectors/decoration/hooks.tsx';
import {
	useFieldOfType,
	useFieldLabel,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useAllExternalReferenceEntities } from '../../../controllers/issue/selectors/external-reference-hooks.tsx';
import { useField } from '../../../controllers/issue/selectors/fields-hooks.tsx';
import {
	useSingleIssueDeliveryDate,
	useExternalReferenceValue,
	useStringValue,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useCurrentUserTimezone } from '../../../controllers/user/index.tsx';
import { DecoratedDate } from '../../decoration/date/index.tsx';
import { TooltipFieldWithLabelContainer } from '../styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
};

const useFieldDate = (fieldKey: string, issueId: string) => {
	const value = useStringValue(fieldKey, issueId);

	if (!value) {
		return undefined;
	}

	try {
		return JSON.parse(value);
	} catch (_err) {
		return undefined;
	}
};

const useAtlasOverrideDate = (issueId: LocalIssueId, atlasProjectFieldKey: string) => {
	const allExternalReferenceEntities = useAllExternalReferenceEntities();
	const externalReferenceValue = useExternalReferenceValue(atlasProjectFieldKey, issueId);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const date = allExternalReferenceEntities[externalReferenceValue as string]?.dueDate?.dateRange;

	if (
		typeof externalReferenceValue !== 'string' ||
		!date?.start ||
		!date?.end ||
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		allExternalReferenceEntities[externalReferenceValue as string]?.state?.value === 'pending'
	) {
		return undefined;
	}

	return date;
};

const useDeliveryOverrideDate = (issueId: LocalIssueId, deliveryDateFieldKey: string) => {
	const field = useField(deliveryDateFieldKey);
	const { locale } = useTenantContext();
	const currentUserTimeZone = useCurrentUserTimezone();
	const dateRaw = useSingleIssueDeliveryDate(
		issueId,
		field?.configuration?.customfieldKey,
		field?.configuration?.aggregationType,
	);

	const date = timestampAggDateToInterval(dateRaw, locale, currentUserTimeZone);

	if (!date?.start || !date?.end) {
		return undefined;
	}

	return date;
};

const DateEntry = (props: {
	label: string;
	decoration: ValueDecoration | undefined;
	datetime: string | undefined;
}) => (
	<TooltipFieldWithLabelContainer>
		<FieldName>{props.label}</FieldName>
		<DecoratedDate datetime={props.datetime} decoration={props.decoration} />
	</TooltipFieldWithLabelContainer>
);

type IntervalDateEntryProps = Props & {
	label: string;
};

const IntervalDateEntry = ({ issueId, fieldKey, label }: IntervalDateEntryProps) => {
	const { locale } = useTenantContext();
	const date = useFieldDate(fieldKey, issueId);
	const decoration = useIntervalDateDecoration(fieldKey, date, locale);

	if (date === undefined) {
		return null;
	}

	return (
		<DateEntry
			key={fieldKey}
			label={label}
			decoration={decoration}
			datetime={renderDateString(date)}
		/>
	);
};

type AtlasDateEntryProps = Props & {
	label: string;
	atlasProjectFieldKey: string;
};

const AtlasDateEntry = ({
	issueId,
	fieldKey,
	label,
	atlasProjectFieldKey,
}: AtlasDateEntryProps) => {
	const { locale } = useTenantContext();

	const atalsDate = useAtlasOverrideDate(issueId, atlasProjectFieldKey);
	const originalDate = useFieldDate(fieldKey, issueId);

	const date = atalsDate || originalDate;

	const decoration = useIntervalDateDecoration(fieldKey, date, locale);
	if (date === undefined) {
		return null;
	}

	return (
		<DateEntry
			key={fieldKey}
			label={label}
			decoration={decoration}
			datetime={renderDateString(date)}
		/>
	);
};

type DeliveryDateEntryProps = Props & {
	label: string;
};

const DeliveryDateEntry = ({ issueId, fieldKey, label }: DeliveryDateEntryProps) => {
	const { locale } = useTenantContext();

	const deliveryDate = useDeliveryOverrideDate(issueId, fieldKey);
	const originalDate = useFieldDate(fieldKey, issueId);

	const date = deliveryDate || originalDate;

	const decoration = useIntervalDateDecoration(fieldKey, date, locale);

	if (date === undefined) {
		return null;
	}

	return (
		<DateEntry
			key={fieldKey}
			label={label}
			decoration={decoration}
			datetime={renderDateString(date)}
		/>
	);
};

export const IntervalField = ({ issueId, fieldKey }: Props) => {
	const label = useFieldLabel(fieldKey);
	const field = useField(fieldKey);
	const atlasProjectField = useFieldOfType(FIELD_TYPES.ATLAS_PROJECT);

	if (label === undefined) {
		return null;
	}

	const hasAtlasOverride =
		field?.configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE &&
		atlasProjectField !== undefined;

	const hasDeliveryDateOverride =
		field?.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE;

	if (hasDeliveryDateOverride) {
		return <DeliveryDateEntry issueId={issueId} fieldKey={fieldKey} label={label} />;
	}

	if (hasAtlasOverride) {
		return (
			<AtlasDateEntry
				issueId={issueId}
				fieldKey={fieldKey}
				label={label}
				atlasProjectFieldKey={atlasProjectField.key}
			/>
		);
	}

	return <IntervalDateEntry issueId={issueId} fieldKey={fieldKey} label={label} />;
};

import { updateAxisOptions, updateAxisState } from './axis/index.tsx';
import { updateComponentsState } from './components/index.tsx';
import { setMatrixContainerDimensions } from './container/index.tsx';
import { updateItemState, moveItems } from './items/index.tsx';
import {
	setHoveredItems,
	setHighlightedItem,
	setSelectedItems,
	setSelectedItemsWithCollision,
	setCurrentViewId,
	setDragValue,
	clearDragValue,
} from './ui/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	setMatrixContainerDimensions,
	setCurrentViewId,
	updateItemState,
	updateAxisState,
	updateAxisOptions,
	updateComponentsState,
	setHoveredItems,
	setHighlightedItem,
	setSelectedItems,
	setSelectedItemsWithCollision,
	moveItems,
	setDragValue,
	clearDragValue,
};

import { useThemeObserver } from '@atlaskit/tokens';
import { useValuesDecorationPalette } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import type { ExtendedOption } from '@atlassian/jira-polaris-lib-board/src/types/common.tsx';

export type UseColumnsHighlightColorsOptions = {
	fieldKey: string;
	extendedOptions: ExtendedOption<unknown>[];
};

export const useColumnsHighlightColors = ({
	fieldKey,
	extendedOptions,
}: UseColumnsHighlightColorsOptions): Map<string | undefined, string> => {
	const { colorMode } = useThemeObserver();
	const theme = colorMode === 'dark' ? 'dark' : 'light';

	const groupIdentities = extendedOptions.map((option) => option.groupIdentity);
	const mapPalette = useValuesDecorationPalette(fieldKey, groupIdentities, theme);

	const highlightMap = new Map<string | undefined, string>();

	mapPalette.forEach((value, key) => {
		highlightMap.set(key, value.highlightColor);
	});

	return highlightMap;
};

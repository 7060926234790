import type { Action } from '@atlassian/react-sweet-state';
import type { GlobalFieldsTourSteps, State } from '../../../types.tsx';

export const setGlobalFieldsTourSpotlightStep =
	(step: GlobalFieldsTourSteps | null): Action<State> =>
	({ getState, setState }) => {
		const state = getState();

		setState({
			...state,
			globalFields: {
				...state.globalFields,
				tourSpotlightStep: step,
			},
		});
	};

import React from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FieldName } from '../../../common/ui/field/styled.tsx';
import { useDateDecoration } from '../../../controllers/field/selectors/decoration/hooks.tsx';
import { useFieldLabel } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useStringValue } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { DecoratedDateTime } from '../../decoration/datetime/index.tsx';
import { TooltipFieldWithLabelContainer } from '../styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const DateTimeField = ({ issueId, fieldKey }: Props) => {
	const label = useFieldLabel(fieldKey);
	const value = useStringValue(fieldKey, issueId);
	const decoration = useDateDecoration(fieldKey, value);
	return label !== undefined && value !== undefined ? (
		<TooltipFieldWithLabelContainer key={fieldKey}>
			<FieldName>{label}</FieldName>
			<DecoratedDateTime datetime={value} decoration={decoration} />
		</TooltipFieldWithLabelContainer>
	) : null;
};

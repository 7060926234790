import React, { useState, useMemo, memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import head from 'lodash/head';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, xcss } from '@atlaskit/primitives';
import { CreatableSelect } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSearchableLocalIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { useLocalIssueIdToJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useKeys,
	useSummaries,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIdeaIssueTypes } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { AlignedShortLabelContext } from '@atlassian/jira-polaris-common/src/ui/issue-short-label/index.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeIdForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { CustomTooltip as WarningTooltip } from '@atlassian/jira-polaris-lib-create-issue-input/src/ui/validation/index.tsx';
import { useOnCreatedOrMovedIssueFiltered } from '../../../../common/on-created-filtered-issue/index.tsx';
import messages from './messages.tsx';
import { Selectable } from './selectable/index.tsx';

type Props<TValue> = {
	fieldKey: FieldKey;
	fieldValue: TValue;
	groupIdentity: string | undefined;
	verticalFieldKey?: FieldKey;
	verticalFieldValue?: TValue;
	verticalGroupIdentity?: string;
	onBlur: () => void;
	onMoveIssueToColumn: (arg1: LocalIssueId) => void;
};

const MAX_CHARS_LENGTH = 255;

const ValidationIcon = () => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip component={WarningTooltip} content={formatMessage(messages.maxLengthError)}>
			<Box xcss={errorIconContainerStyles}>
				<ErrorIcon
					color={token('color.icon.danger')}
					label={formatMessage(messages.errorIcon)}
					LEGACY_primaryColor={colors.R400}
				/>
			</Box>
		</Tooltip>
	);
};

export const AddCard = memo<Props<unknown>>(
	({
		groupIdentity,
		onBlur,
		onMoveIssueToColumn,
		fieldKey,
		fieldValue,
		verticalFieldKey,
		verticalFieldValue,
		verticalGroupIdentity,
	}: Props<unknown>) => {
		const { formatMessage } = useIntl();
		const summaries = useSummaries();
		const keys = useKeys();
		const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
		const issueIds = useSearchableLocalIssueIds(
			fieldKey,
			groupIdentity,
			verticalFieldKey,
			verticalGroupIdentity,
		);
		const projectId = useProjectId();
		const [ideaTypesOld] = useIdeaIssueTypes();
		const issueTypeNew = useIssueTypeIdForProject({ projectId });
		const issueTypeId = fg('jpd_extracted_issue_type_store')
			? issueTypeNew
			: head(ideaTypesOld.map((type) => type.jiraIssueTypeId));

		const [value, setValue] = useState<unknown>(undefined);
		const [inputValue, setInputValue] = useState('');
		const [menuIsOpen, setMenuIsOpen] = useState(false);
		const { createAndUpdate } = useIssueActions();
		const { updateFieldValues } = useIssueActions();
		const groupIdentityFieldType = useFieldType(fieldKey);
		const options = useMemo(() => {
			const transformed = issueIds.map((issueId) => ({
				label: <Selectable issueId={issueId} />,
				value: keys[issueId] + summaries[issueId],
				issueId,
			}));

			if (fg('jpd_issues_relationships')) {
				if (groupIdentityFieldType === FIELD_TYPES.CONNECTION) {
					return transformed.filter(
						({ issueId }) => localIssueIdToJiraId[issueId] !== groupIdentity,
					);
				}
			}

			return transformed;
		}, [groupIdentity, groupIdentityFieldType, issueIds, keys, localIssueIdToJiraId, summaries]);

		const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
		const isInvalid = inputValue.length > MAX_CHARS_LENGTH;

		const onCreateIssue = useCallback(
			(summary: string) => {
				if (isInvalid || issueTypeId === undefined) {
					return;
				}

				const fieldsToUpdate = {
					[fieldKey]: fieldValue,
				};

				if (verticalFieldKey !== undefined) {
					fieldsToUpdate[verticalFieldKey] = verticalFieldValue;
				}

				createAndUpdate(
					issueTypeId,
					summary,
					fieldsToUpdate,
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					() => {},
					(id) => {
						const { filtered } = updateFieldValues({
							localIssueIds: [id],
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							fields: Object.entries(fieldsToUpdate).reduce<Record<string, any>>(
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								(acc, [keyToUpdate, valueToUpdate]: [any, any]) => {
									acc[keyToUpdate] = {
										newValue: valueToUpdate,
										appendMultiValues: true,
									};

									return acc;
								},
								{},
							),
						});

						if (filtered === true) {
							onCreatedIssueFiltered(id);
						}
					},
				);
			},
			[
				createAndUpdate,
				fieldKey,
				fieldValue,
				issueTypeId,
				isInvalid,
				onCreatedIssueFiltered,
				updateFieldValues,
				verticalFieldKey,
				verticalFieldValue,
			],
		);

		const onSelectIssue = useCallback(
			// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type.
			(option) => {
				onMoveIssueToColumn(option.issueId);
				setValue('');
			},
			[onMoveIssueToColumn],
		);

		const handleBlur = () => {
			if (!isInvalid && inputValue) {
				onCreateIssue(inputValue);
			}

			onBlur();
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const controlStyles = (base: any) => {
			const borderColor = token('color.border.danger', R400);

			return {
				...base,
				...(isInvalid && {
					borderColor,
					':hover': {
						borderColor,
					},
				}),
			};
		};

		if (issueTypeId === undefined) {
			return null;
		}

		return (
			<Container data-testid="polaris-ideas.ui.view-content.idea-board.column.footer.search-and-create.creatable-select">
				<AlignedShortLabelContext>
					<CreatableSelect
						components={isInvalid ? { DropdownIndicator: ValidationIcon } : undefined}
						onMenuOpen={() => setMenuIsOpen(true)}
						onMenuClose={() => !isInvalid && setMenuIsOpen(false)}
						menuIsOpen={!isInvalid && menuIsOpen}
						styles={{ control: controlStyles }}
						inputValue={inputValue}
						value={value}
						spacing="compact"
						menuPlacement="auto"
						autoFocus
						placeholder={formatMessage(messages.placeholder)}
						onInputChange={setInputValue}
						onChange={onSelectIssue}
						onBlur={handleBlur}
						options={options}
						openMenuOnFocus
						openMenuOnClick={false}
						onCreateOption={onCreateIssue}
					/>
				</AlignedShortLabelContext>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flexGrow: 1,
	/* NOTE: z-index and position are set here to get the dropdown menu working with AK AvatarGroups. These set the */
	/* z-index of avatars when stacked so that they can end up over the dropdown menu. */
	zIndex: 100,
	position: 'relative',
	maxWidth: '100%',
});

const errorIconContainerStyles = xcss({
	paddingLeft: 'space.050',
});

import React, { useRef, useMemo, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorMediaWrapLeftIcon from '@atlaskit/icon/glyph/editor/media-wrap-left';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import {
	useIsIssueStateCallbackHandled,
	useIsLoading,
	useIsSingleIssueLoaded,
	usePreloadedDescription,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIsIssuesLoading } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useHasCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import { isEmpty } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import messages from './messages.tsx';
import { DraftIndicator } from './styled.tsx';

export type ReadViewProps = {
	isEditingAllowed: boolean;
	hasDraft?: boolean;
	isSmartLink?: boolean;
	description?: DocumentFieldValue;
	onClick?: () => void;
	onTemplatesClick?: () => void;
};

export const ReadViewComponent = (props: ReadViewProps) => {
	const { description, onTemplatesClick, isEditingAllowed, onClick, hasDraft, isSmartLink } = props;
	const { formatMessage } = useIntl();
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const isLoadingIdeaData = useIsLoading();
	const preloadedDescription = usePreloadedDescription();
	const isIssuesLoading = useIsIssuesLoading();
	const isViewsLoading = !useHasCurrentView();
	const isArchived = useIsSelectedIssueArchived();

	const issueStateCallbackCircleComplete = useIsIssueStateCallbackHandled();
	const isSingleIssueLoaded = useIsSingleIssueLoaded();

	const safeDescription = useMemo(() => {
		if (description !== undefined) {
			return prepareForRender(description);
		}
		return prepareForRender(preloadedDescription);
	}, [preloadedDescription, description]);

	const clickHandler = useCallback(() => {
		// ensure we don't allow switching to edit mode before all loading is complete
		if (
			!(isIssuesLoading || isViewsLoading || isLoadingIdeaData) &&
			(issueStateCallbackCircleComplete || isSingleIssueLoaded)
		) {
			onClick?.();
		}
	}, [
		isIssuesLoading,
		isViewsLoading,
		isLoadingIdeaData,
		issueStateCallbackCircleComplete,
		isSingleIssueLoaded,
		onClick,
	]);

	const wrapperClickHandler = useCallback(
		// we want to only handle wrapper click here,
		// since AkRenderer component is already handling other clicks (i.e. clicking on image)
		// and unhandled events are handled as edit trigger
		(event: MouseEvent) => {
			if (event.target === wrapperRef.current) {
				clickHandler();
			}
		},
		[clickHandler],
	);

	const isDescriptionEmpty = safeDescription === undefined || isEmpty(safeDescription);

	if (isArchived && isDescriptionEmpty) return null;

	return (
		<ReadViewContainer>
			{hasDraft && <DraftIndicator>{formatMessage(messages.draftIndicator)}</DraftIndicator>}
			<ReadViewWrapper
				data-testid="polaris-ideas.ui.idea-view.description.content.read.ak-renderer"
				ref={wrapperRef}
				onClick={wrapperClickHandler}
				isEditingAllowed={isEditingAllowed}
			>
				<WaitForAdfConsumerProps forceLoadingView={false}>
					{({ akRendererProps }) => (
						<>
							{isLoadingIdeaData ? (
								<DescriptionSkeletonContainer data-testid="polaris-ideas.ui.idea-view.description.content.read.skeleton">
									<AdfSkeleton />
								</DescriptionSkeletonContainer>
							) : (
								<>
									{isDescriptionEmpty ? (
										<PlaceholderContainer onClick={clickHandler}>
											{isSmartLink
												? formatMessage(messages.emptyDescriptionPlaceholder)
												: formatMessage(messages.descriptionPlaceholder)}
											{isEditingAllowed && (
												<TemplatesLinkBtn
													appearance="link"
													onClick={(e) => {
														e.stopPropagation();
														onTemplatesClick?.();
													}}
												>
													{formatMessage(messages.descriptionTemplatePlaceholder)}
												</TemplatesLinkBtn>
											)}
										</PlaceholderContainer>
									) : (
										<AkRenderer
											appearance={
												expVal('platform_editor_support_table_in_comment_jira', 'isEnabled', false)
													? 'comment'
													: undefined
											}
											{...akRendererProps}
											eventHandlers={{
												onUnhandledClick: clickHandler,
												smartCard: { onClick: onLinkClick },
												link: { onClick: onLinkClick },
											}}
											document={safeDescription}
											analyticsEventSeverityTracking={{
												enabled: true,
												severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
												severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
											}}
											unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
											useSpecBasedValidator
											disableHeadingIDs
											allowAltTextOnImages
											featureFlags={{
												codeBidiWarnings: true,
												'code-bidi-warnings': true,
												'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
											}}
											smartLinks={smartLinksDefault}
											UNSTABLE_allowTableResizing={expVal(
												'platform_editor_support_table_in_comment_jira',
												'isEnabled',
												false,
											)}
											UNSTABLE_allowTableAlignment={expVal(
												'platform_editor_support_table_in_comment_jira',
												'isEnabled',
												false,
											)}
										/>
									)}
								</>
							)}
						</>
					)}
				</WaitForAdfConsumerProps>
			</ReadViewWrapper>
			{!isArchived && !isDescriptionEmpty && isEditingAllowed && (
				<TemplatesBtnContainer>
					<Button
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						iconBefore={<EditorMediaWrapLeftIcon label="Editor templates" />}
						onClick={onTemplatesClick}
					>
						{formatMessage(messages.templatesButton)}
					</Button>
				</TemplatesBtnContainer>
			)}
		</ReadViewContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewWrapper = styled.div<{ isEditingAllowed: boolean }>({
	margin: `0 ${token('space.negative.100', '-8px')}`,
	padding: token('space.100', '8px'),
	borderRadius: '3px',
	transition: '0.3s',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isEditingAllowed }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isEditingAllowed ? token('color.background.neutral.hovered', colors.N30) : 'transparent',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplatesBtnContainer = styled.div({
	alignSelf: 'end',
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplatesLinkBtn = styled(Button)({
	marginBottom: '3px',
	padding: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionSkeletonContainer = styled.div({
	padding: `0 ${token('space.100', '8px')} ${token('space.100', '8px')}`,
	marginBottom: token('space.200', '16px'),
});

import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { useIntl } from '@atlassian/jira-intl';
import { useAllExternalReferencePropertiesOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/external-reference-property-hooks.tsx';
import { ExternalReferencePropertyField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference-property/main.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const ExternalReferencePropertyColumnHeader = ({
	fieldKey,
	groupIdentity,
}: ColumnHeaderComponentProps) => {
	const allExternalReferencePropertiesOnIssues = useAllExternalReferencePropertiesOnIssues();

	return (
		<ExternalReferencePropertyField
			fieldKey={fieldKey}
			value={allExternalReferencePropertiesOnIssues[groupIdentity]}
		/>
	);
};

const EmptyExternalReferencePropertyColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const externalReferencePropertyColumnHeaderConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: ExternalReferencePropertyColumnHeader,
	EmptyComponent: EmptyExternalReferencePropertyColumnHeader,
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoValueContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N100,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - TS7016: Could not find a declaration file for module "querystring"
import querystring from 'querystring';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { GetIssueResponse } from './types.tsx';

const getIssueApiUrl = (issueIdOrKey: string): string => `/rest/api/3/issue/${issueIdOrKey}`;

export const getIssue = (issueIdOrKey: string, fields?: string[]): Promise<GetIssueResponse> => {
	const query = fields?.length
		? querystring.stringify({
				fields: Array.from(new Set([...fields, 'issuetype', 'project', 'comment', 'issuelinks'])),
			})
		: undefined;
	const url = getIssueApiUrl(issueIdOrKey);
	const urlWithQuery = url + (query ? `?${query}` : '');
	return fetchJson(
		// don't send query longer than 4000 characters (Request header is too large error)
		query && urlWithQuery.length < 4000 ? urlWithQuery : url,
	);
};

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isArray from 'lodash/isArray';
import deepEquals from 'lodash/isEqual';
import uuid from 'uuid';
import Select from '@atlaskit/select';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { useStateWithRef } from '../../../../../common/utils/react/hooks.tsx';
import { useFieldOptionsWithAriResolved } from '../../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useDecoratableSelectCustomComponentsForOptions } from '../../../../common/decoration/decoration/select/index.tsx';
import { createOptionsGroups } from '../../../../fields/select/edit/utils.tsx';
import { stylesConfig } from './styled.tsx';

type Props = {
	isMulti: boolean;
	isMenuOpen: boolean;
	onOpenMenu: () => void;
	selectedOptions: OptionProperty[];
	fieldKey: FieldKey;
	onUpdate: (options: OptionProperty[], allowClose: boolean) => void;
	onCloseRequested: () => void;
};

const EMPTY_STRING = '';

export const SelectEdit = memo<Props>(
	({
		fieldKey,
		isMulti,
		selectedOptions = [],
		onUpdate,
		onOpenMenu,
		onCloseRequested,
		isMenuOpen = true,
	}: Props) => {
		const [selectMenuId] = useState(uuid());
		const [inputValue, setInputValue] = useState(EMPTY_STRING);

		const allFieldOptions: OptionProperty[] = useFieldOptionsWithAriResolved(fieldKey);

		const selectedOptionsVerified = useMemo(
			() => selectedOptions.filter(({ id }) => allFieldOptions.some((opt) => opt.id === id)),
			[selectedOptions, allFieldOptions],
		);

		const [value, setValue, valueRef] = useStateWithRef(selectedOptionsVerified);

		const [localOptions, setLocalOptions] = useState(
			createOptionsGroups(value, allFieldOptions, isMulti),
		);

		useEffect(() => {
			if (!isShallowEqual(value, selectedOptionsVerified)) {
				setValue(selectedOptionsVerified);
			}
		}, [selectedOptionsVerified, setValue, value]);

		useEffect(() => {
			setLocalOptions(createOptionsGroups(value, allFieldOptions, isMulti));
		}, [value, allFieldOptions, isMulti]);

		const onChange = useCallback(
			(newValue: OptionProperty, allowClose = true) => {
				if (!newValue) {
					if (valueRef.current !== undefined) {
						onUpdate([], true);
					}
					return;
				}
				const newValueArray = isArray(newValue) ? newValue : [newValue];
				if (valueRef.current !== undefined) {
					const currentValueIdArray = (
						isArray(valueRef.current) ? valueRef.current : [valueRef.current]
					).map(({ id }) => id);
					const valueForUpdateIdArray = newValueArray.map(({ id }) => id);
					if (deepEquals(currentValueIdArray, valueForUpdateIdArray)) {
						return;
					}
				}
				onUpdate(newValueArray, allowClose);
				setInputValue(EMPTY_STRING);
			},
			[onUpdate, valueRef],
		);

		const customComponents = useDecoratableSelectCustomComponentsForOptions({
			isMulti,
			fieldKey,
			onCloseRequested,
			menuId: selectMenuId,
			configOnly: false,
			hideInput: false,
		});

		return (
			<Select
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onKeyDown={(event: any) => {
					switch (event.key) {
						case 'Esc': // IE/Edge specific value
						case 'Escape':
							onCloseRequested();
							break;
						default:
					}
				}}
				defaultMenuIsOpen
				onMenuOpen={onOpenMenu}
				menuIsOpen={isMenuOpen}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onChange={(newValue: any) => {
					onChange(newValue);
					if (!isMulti) {
						onCloseRequested();
					}
				}}
				isMulti={isMulti}
				hideSelectedOptions={false}
				isSearchable
				isClearable
				backspaceRemovesValue={false}
				inputValue={inputValue}
				onInputChange={setInputValue}
				value={value}
				options={localOptions}
				components={customComponents}
				getOptionLabel={({ value: label }: OptionProperty) => label}
				getOptionValue={({ id }: OptionProperty) => id}
				enableAnimation={false}
				menuPosition="fixed"
				maxMenuHeight={320}
				openMenuOnClick
				closeMenuOnScroll={() => true}
				styles={stylesConfig}
			/>
		);
	},
);

import React from 'react';
import {
	useSelectedIssue,
	useStringValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIdeaDescriptionFieldKey } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { parseAdfString } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { DescriptionContent } from '../content/index.tsx';

const StringFieldInner = ({ fieldKey }: { fieldKey: FieldKey }) => {
	const selectedIssue = useSelectedIssue();
	const value = useStringValue(fieldKey, selectedIssue);

	if (!value) {
		return null;
	}

	return <DescriptionContent description={parseAdfString(value)} isReadOnly />;
};

export const StringField = () => {
	const ideaDescriptionFieldKey = useIdeaDescriptionFieldKey();

	if (!ideaDescriptionFieldKey) {
		return null;
	}

	return <StringFieldInner fieldKey={ideaDescriptionFieldKey} />;
};

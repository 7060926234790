import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	opGreaterThan: {
		id: 'polaris-common.filters.filter-component.num-data-points.op-greater-than',
		defaultMessage: 'greater than {value}',
		description: 'Numeric > operator',
	},
	opLessThan: {
		id: 'polaris-common.filters.filter-component.num-data-points.op-less-than',
		defaultMessage: 'less than {value}',
		description: 'Numeric < operator',
	},
	opBetween: {
		id: 'polaris-common.filters.filter-component.num-data-points.op-between',
		defaultMessage: 'between {value1} and {value2}',
		description: 'Numeric range between two values operator',
	},
	opEmpty: {
		id: 'polaris-common.filters.filter-component.num-data-points.op-empty',
		defaultMessage: 'is empty',
		description: 'Numeric empty value operator',
	},
	opCreatedWithLastDays: {
		id: 'polaris-common.filters.filter-component.num-data-points.op-created-with-last-days',
		defaultMessage: 'created within the last {value, plural, =0 {day} one {# day} other {# days}}',
		description: 'Numeric value of last $ days',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manualRankDescription: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.manual-rank-description',
		defaultMessage: 'Ideas are ranked manually',
		description: 'Indicator that ideas can be ranked by the user',
	},
	projectRankHeader: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.project-rank-header',
		defaultMessage: 'Project rank',
		description: 'Header of the project rank description',
	},
	projectRankDescription: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.project-rank-description',
		defaultMessage: 'The rank of ideas is shared across views in this project',
		description: 'the project rank description',
	},
	changeToViewRankMode: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.change-to-view-rank-mode',
		defaultMessage: 'Change to view rank',
		description: 'caption on button to change sort mode to view rank',
	},
	changeToViewRankModeTooltip: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.change-to-view-rank-mode-tooltip',
		defaultMessage: 'The rank of ideas will be specific to this view only',
		description: 'tooltip on button to change sort mode to view rank',
	},
	switchedSortModeHeader: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.switched-sort-mode-header',
		defaultMessage: 'Rank was updated',
		description: 'header shown for flag indicating that the view sort mode was switched',
	},
	switchedSortModeToViewRank: {
		id: 'polaris-component-view-sort-configuration.advanced.project-rank.switched-sort-mode-to-view-rank',
		defaultMessage: 'This view has a view rank now',
		description:
			'message indicating that the sort mode of the view has been switched to view rank.',
	},
});

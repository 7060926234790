import React, { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useIntl } from '@atlassian/jira-intl';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { DescriptionReadView } from '../read-view/index.tsx';
import messages from './messages.tsx';

const MAX_CONTENT_SIZE = 3000;

type EditViewDescriptionOverlayProps = {
	description: ADF | undefined;
	onSave: (description: ADF) => void;
	onCancel: () => void;
	onDirty: (type: boolean) => void;
};

export const EditViewDescriptionOverlay = ({
	description,
	onCancel,
	onSave,
	onDirty,
}: EditViewDescriptionOverlayProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isEditorAiEnabled = useEditorAiEnabled();

	// we need to clone the ADF object before passing it into the editor
	// as it seems the value gets somehow manipulated by the editor component
	const content = cloneDeep(description);

	const handleSave = useCallback(
		(value: ADF) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'saved',
					actionSubject: 'descriptionEditor',
				}),
				'saveDescription',
			);
			onDirty(false);
			onSave(value);
		},
		[createAnalyticsEvent, onDirty, onSave],
	);

	const handleCancel = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'discarded',
				actionSubject: 'descriptionEditor',
			}),
			'discardDescription',
		);
		onCancel();
	}, [createAnalyticsEvent, onCancel]);

	return (
		<WaitForAdfConsumerProps>
			{({ akEditorProps }) => (
				<EditorBoundary
					fallback={<DescriptionReadView description={description} isEditingAllowed />}
				>
					<Editor
						{...akEditorProps}
						withDebouncedOnChange={false}
						shouldFocus
						maxHeight={500}
						defaultValue={content}
						placeholder={formatMessage(messages.descriptionPlaceholder)}
						maxContentSize={MAX_CONTENT_SIZE}
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						popupsMountPoint={document.body || undefined}
						allowFullEditorCapabilities
						onChange={() => onDirty(true)}
						onSave={handleSave}
						onCancel={handleCancel}
						isAiEnabled={isEditorAiEnabled}
						saveButtonLabel={formatMessage(messages.save)}
						cancelButtonLabel={formatMessage(messages.cancel)}
					/>
				</EditorBoundary>
			)}
		</WaitForAdfConsumerProps>
	);
};

import React from 'react';
import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useHasReachedViewsLimit } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';

type ActionItemProps = Omit<DropdownItemProps, 'children'> & {
	message: MessageDescriptor;
};

export const ActionItem = (props: ActionItemProps) => {
	const { message, onClick } = props;
	const { formatMessage } = useIntl();

	if (!onClick) {
		return null;
	}

	return (
		<DropdownItem
			{...props}
			onClick={(e) => {
				e.stopPropagation();
				onClick(e);
			}}
		>
			{formatMessage(message)}
		</DropdownItem>
	);
};

type ActionItemTooltipProps = {
	children: React.ReactNode;
	entityLimitMessage?: React.ReactNode;
};

const ActionItemTooltip = ({ children, entityLimitMessage }: ActionItemTooltipProps) => {
	if (entityLimitMessage) {
		return (
			<CreateEntityLimitTooltip message={entityLimitMessage}>{children}</CreateEntityLimitTooltip>
		);
	}

	return <>{children}</>;
};

export const SaveAsNewViewActionItem = (props: ActionItemProps) => {
	const [limitType, limitValue] = useHasReachedViewsLimit();
	const getEntityLimitMessage = useEntityLimitMessage();

	return (
		<ActionItemTooltip
			entityLimitMessage={limitType && getEntityLimitMessage(limitType, limitValue)}
		>
			<ActionItem {...props} isDisabled={!!limitType} />
		</ActionItemTooltip>
	);
};

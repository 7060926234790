import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	chooseOption: {
		id: 'polaris-common.filters.filter-component.interval.content.choose-option',
		defaultMessage: 'Choose an option',
		description: 'Placeholder for the Select list displayed for filter intervals',
	},
	specificDates: {
		id: 'polaris-common.filters.filter-component.interval.content.specific-dates',
		defaultMessage: 'Specific dates',
		description: 'Option for the Select list displayed for filter intervals',
	},
	rollingDates: {
		id: 'polaris-common.filters.filter-component.interval.content.rolling-dates',
		defaultMessage: 'Rolling dates',
		description: 'Option for the Select list displayed for filter intervals',
	},
	isEmpty: {
		id: 'polaris-common.filters.filter-component.interval.content.is-empty',
		defaultMessage: 'Is empty',
		description: 'Option for the Select list displayed for filter intervals',
	},
	isNotEmpty: {
		id: 'polaris-common.filters.filter-component.interval.content.is-not-empty',
		defaultMessage: 'Is not empty',
		description: 'Option for the Select list displayed for filter intervals',
	},
});

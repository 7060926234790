import { createSelector } from 'reselect';
import type { ColumnId, RowId } from '../../common/types/index.tsx';
import { getHoveredColumn } from './columns.tsx';
import { getHoveredRow } from './rows.tsx';

export const createIsCellHovered = (rowId: RowId, columnId: ColumnId) =>
	createSelector(
		getHoveredRow,
		getHoveredColumn,
		(hoveredRowId, hoveredColumnId) => hoveredRowId === rowId && hoveredColumnId === columnId,
	);

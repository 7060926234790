import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import { IssueViewModalHeaderSkeleton } from '../header/issue-view-modal-header-skeleton/index.tsx';

export const IssueViewModalSkeleton = (props: {
	onClose: () => void;
	renderFeedback?: () => ReactNode;
	issueKey: IssueKey;
}) => (
	<>
		<IssueViewModalHeaderSkeleton {...props} />
		<Box xcss={modalBodyWrapperStyles}>
			<IssueViewSkeleton isModalView />
		</Box>
	</>
);

const modalBodyWrapperStyles = xcss({
	paddingBottom: 'space.400',
});

import { useInsightsCount as useProjectInsightsCount } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/insights-hooks.tsx';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { EntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import type { PolarisInsightAnalyticsData } from '../utils/analytics.tsx';
import {
	createGetInsight,
	createGetInsightAccountId,
	createGetInsightDescription,
	createGetInsightSnippets,
	createGetInsightsAttachments,
	createGetPolarisInsightSnippet,
	createGetRealInsightSnippetIds,
	createGetInsightsMediaIds,
	createGetSnippet,
	createGetPlayInsights,
	getInsightsCount,
	getSelectedInsightsCount,
	getInsightIds,
	type PolarisInsightDescription,
	createGetRealInsightSnippets,
	createGetInsightAnalyticsData,
} from './insights.tsx';
import {
	createHigherLevelInsightsHook,
	createHigherLevelInsightsHook2,
	createInsightsHook,
} from './utils.tsx';

export const useInsightIds = createInsightsHook(getInsightIds);

export const usePlayInsights = createInsightsHook(createGetPlayInsights);

export const useInsightDescription = createHigherLevelInsightsHook<
	string,
	PolarisInsightDescription
>(createGetInsightDescription);

export const useInsightSnippets = createHigherLevelInsightsHook<string, Snippet[]>(
	createGetInsightSnippets,
);

export const useInsightsAttachments = createInsightsHook(createGetInsightsAttachments);

export const useInsightsMediaIds = createInsightsHook(createGetInsightsMediaIds);

export const useGetRealInsightSnippetIds = createHigherLevelInsightsHook<string, string[]>(
	createGetRealInsightSnippetIds,
);

export const usePolarisSnippet = createHigherLevelInsightsHook<string, Snippet | undefined>(
	createGetPolarisInsightSnippet,
);

export const useRealSnippet = createHigherLevelInsightsHook(createGetRealInsightSnippets);

export const useInsightAccountId = createHigherLevelInsightsHook<string, string | undefined>(
	createGetInsightAccountId,
);

export const useInsight = createHigherLevelInsightsHook<string, Insight | undefined>(
	createGetInsight,
);

export const useSnippet = createHigherLevelInsightsHook2<string, string, Snippet | undefined>(
	createGetSnippet,
);

export const useInsightsCount = createInsightsHook<number>(getInsightsCount);

export const useSelectedInsightsCount = createInsightsHook<number>(getSelectedInsightsCount);

export const useInsightAnalyticsData = createHigherLevelInsightsHook<
	string,
	PolarisInsightAnalyticsData | undefined
>(createGetInsightAnalyticsData);

// Returns the limit type and value if reached, otherwise returns null
export const useHasReachedInsightsLimit = (): [EntityLimitType | null, number] => {
	const ideaInsightsCount = useInsightsCount();
	const insightsPerIdeaLimit = useEntityLimitsByType(ENTITY_LIMIT_TYPE.INSIGHTS_PER_IDEA);

	const projectInsightsCount = useProjectInsightsCount();
	const insightsPerProjectLimit = useEntityLimitsByType(ENTITY_LIMIT_TYPE.INSIGHTS_PER_PROJECT);

	if (insightsPerIdeaLimit && ideaInsightsCount >= insightsPerIdeaLimit) {
		return [ENTITY_LIMIT_TYPE.INSIGHTS_PER_IDEA, insightsPerIdeaLimit];
	}

	if (insightsPerProjectLimit && projectInsightsCount >= insightsPerProjectLimit) {
		return [ENTITY_LIMIT_TYPE.INSIGHTS_PER_PROJECT, insightsPerProjectLimit];
	}

	return [null, 0];
};

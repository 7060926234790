export type DraggableCardData = {
	ideaId: string;
	draggableId: string;
	droppableId: string;
	index: number;
	type: 'CARD';
};

export const isDraggableCardData = (data: unknown): data is DraggableCardData =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	typeof data === 'object' && (data as DraggableCardData).type === 'CARD';

export type DraggableColumnHeaderData = {
	draggableId: string;
	index: number;
	type: 'COLUMN_HEADER' | 'STICKY_COLUMN_HEADER';
};

export const isDraggableColumnHeaderData = (data: unknown): data is DraggableColumnHeaderData =>
	typeof data === 'object' &&
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	['COLUMN_HEADER', 'STICKY_COLUMN_HEADER'].includes((data as DraggableColumnHeaderData).type);

type DraggableColumnData = {
	draggableId: string;
	droppableId: string;
	index: number;
	ideaIds: string[];
	type: 'COLUMN';
};

export const isDraggableColumnData = (data: unknown): data is DraggableColumnData =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	typeof data === 'object' && (data as DraggableColumnData).type === 'COLUMN';

export type DraggableGroupData = {
	draggableId: string;
	index: number;
	type: 'GROUP';
};

export const isDraggableGroupData = (data: unknown): data is DraggableGroupData =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	typeof data === 'object' && (data as DraggableGroupData).type === 'GROUP';

export type DragState = 'idle' | 'preview' | 'dragging';

export type DraggableLocation = {
	droppableId: string;
	index: number;
};

export type DropResult = {
	source?: DraggableLocation;
	destination?: DraggableLocation;
};
